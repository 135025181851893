//GetCollaborator
export const SET_COLLABORATOR_DATA = "collaboratorData@SET_COLLABORATOR_DATA";
export const GET_COLLABORATOR_DATA = "collaboratorData@GET_COLLABORATOR_DATA";

export const getCollaboratorData = () => {
  return { type: GET_COLLABORATOR_DATA };
};

export const setCollaboratorData = (data) => {
  return { type: SET_COLLABORATOR_DATA, data };
};

//Personal Data
export const SET_PERSONAL_DATA = "personalData@SET_PERSONAL_DATA";
export const GET_PERSONAL_DATA = "personalData@GET_PERSONAL_DATA";

export const GET_PERSONALDATADROPDOWN =
  "personalDataDropdown@GET_PERSONALDATADROPDOWN";
export const SET_PERSONALDATADROPDOWN =
  "personalDataDropdown@SET_PERSONALDATADROPDOWN";

export const UPDATE_PERSONAL_DATA = "personalData@UPDATE_PERSONAL_DATA";
export const DONE_UPDATE_PERSONAL_DATA =
  "personalData@DONE_UPDATE_PERSONAL_DATA";

export const CREATE_PERSONAL_DATA = "personalData@CREATE_PERSONAL_DATA";
export const DONE_CREATE_PERSONAL_DATA =
  "personalData@DONE_CREATE_PERSONAL_DATA";

export const SET_PERSONALDATA_ERROR = "personalData@SET_PERSONALDATA_ERROR";

export const getPersonalData = (id, companyId) => {
  return { type: GET_PERSONAL_DATA, id, companyId };
};

export const setPersonalData = (data) => {
  return { type: SET_PERSONAL_DATA, data };
};

export const getPersonalDataDropdown = () => {
  return { type: GET_PERSONALDATADROPDOWN };
};

export const setPersonalDataDropdown = (data) => {
  return { type: SET_PERSONALDATADROPDOWN, data };
};

export const updatePersonalData = (data) => {
  return { type: UPDATE_PERSONAL_DATA, data };
};

export const doneUpdatePersonalData = (data) => {
  return { type: DONE_UPDATE_PERSONAL_DATA, data };
};

export const createPersonalData = (data) => {
  return { type: CREATE_PERSONAL_DATA, data };
};

export const doneCreatePersonalData = (data) => {
  return { type: DONE_CREATE_PERSONAL_DATA, data };
};

export const setPersonalDataError = (boollean) => {
  return { type: SET_PERSONALDATA_ERROR, boollean };
};

//Adress
export const GET_ADDRESS = "address@GET_ADDRESS";
export const SET_ADDRESS = "address@SET_ADDRESS";

export const UPDATE_ADDRESS = "address@UPDATE_ADDRESS";
export const DONE_UPDATE_ADDRESS = "address@DONE_UPDATE_ADDRESS";

export const UPDATE_NOT_MAIN_ADDRESS = "address@UPDATE_NOT_MAIN_ADDRESS";
export const DONE_UPDATE_NOT_MAIN_ADDRESS =
  "address@DONE_UPDATE_NOT_MAIN_ADDRESS";

export const CREATE_ADDRESS = "address@CREATE_ADDRESS";
export const DONE_CREATE_ADDRESS = "address@DONE_CREATE_ADDRESS";

export const getAddress = (id, companyId) => {
  return { type: GET_ADDRESS, id, companyId };
};

export const setAddress = (data) => {
  return { type: SET_ADDRESS, data };
};

export const updateAddress = (data) => {
  return { type: UPDATE_ADDRESS, data };
};

export const doneUpdateAddress = (data) => {
  return { type: DONE_UPDATE_ADDRESS, data };
};

export const updateNotMainAddress = (data) => {
  return { type: UPDATE_NOT_MAIN_ADDRESS, data };
};

export const doneUpdateNotMainAddress = (data) => {
  return { type: DONE_UPDATE_NOT_MAIN_ADDRESS, data };
};

export const createAddress = (data) => {
  return { type: CREATE_ADDRESS, data };
};

export const doneCreateAddress = (data) => {
  return { type: DONE_CREATE_ADDRESS, data };
};
//AcademicQualifications
export const GET_ACADEMICQUALIFICATIONS =
  "academicQualifications@GET_ACADEMICQUALIFICATIONS";
export const SET_ACADEMICQUALIFICATIONS =
  "academicQualifications@SET_ACADEMICQUALIFICATIONS";

export const GET_ACADEMICDROPDOWN = "academicDropdown@GET_ACADEMICDROPDOWN";
export const SET_ACADEMICDROPDOWN = "academicDropdown@SET_ACADEMICDROPDOWN";

export const UPDATE_ACADEMICQUALIFICATIONS =
  "academicQualifications@UPDATE_ACADEMICQUALIFICATIONS";
export const DONE_UPDATE_ACADEMICQUALIFICATIONS =
  "academicQualifications@DONE_UPDATE_ACADEMICQUALIFICATIONS";

export const UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS =
  "academicQualifications@UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS";
export const DONE_UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS =
  "academicQualifications@DONE_UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS";

export const CREATE_ACADEMICQUALIFICATIONS =
  "academicQualifications@CREATE_ACADEMICQUALIFICATIONS";
export const DONE_CREATE_ACADEMICQUALIFICATIONS =
  "academicQualifications@DONE_CREATE_ACADEMICQUALIFICATIONS";

export const getAcademicQualifications = (id, companyId) => {
  return { type: GET_ACADEMICQUALIFICATIONS, id, companyId };
};

export const setAcademicQualifications = (data) => {
  return { type: SET_ACADEMICQUALIFICATIONS, data };
};

export const getAcademicDropdown = () => {
  return { type: GET_ACADEMICDROPDOWN };
};

export const setAcademicDropdown = (data) => {
  return { type: SET_ACADEMICDROPDOWN, data };
};

export const updateAcademicQualifications = (data) => {
  return { type: UPDATE_ACADEMICQUALIFICATIONS, data };
};

export const doneUpdateAcademicQualifications = (data) => {
  return { type: DONE_UPDATE_ACADEMICQUALIFICATIONS, data };
};

export const updateNotMainAcademicQualifications = (data) => {
  return { type: UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS, data };
};

export const doneUpdateNotMainAcademicQualifications = (data) => {
  return { type: DONE_UPDATE_NOT_MAIN_ACADEMICQUALIFICATIONS, data };
};

export const createAcademicQualifications = (data) => {
  return { type: CREATE_ACADEMICQUALIFICATIONS, data };
};

export const doneCreateAcademicQualifications = (data) => {
  return { type: DONE_CREATE_ACADEMICQUALIFICATIONS, data };
};

//TAX DATA
export const GET_TAXDATA = "taxData@GET_TAXDATA";
export const SET_TAXDATA = "taxData@SET_TAXDATA";
export const GET_TAXDATA_DROPDOWN = "taxDataDropdown@GET_TAXDATA_DROPDOWN";
export const SET_TAXDATA_DROPDOWN = "taxDataDropdown@SET_TAXDATA_DROPDOWN";

export const UPDATE_TAXDATA = "taxData@UPDATE_TAXDATA";
export const DONE_UPDATE_TAXDATA = "taxData@DONE_UPDATE_TAXDATA";

export const CREATE_TAXDATA = "taxData@CREATE_TAXDATA";
export const DONE_CREATE_TAXDATA = "taxData@DONE_CREATE_TAXDATA";

export const getTaxData = (id, companyId) => {
  return { type: GET_TAXDATA, id, companyId };
};

export const setTaxData = (data) => {
  return { type: SET_TAXDATA, data };
};

export const getTaxDataDropdown = () => {
  return { type: GET_TAXDATA_DROPDOWN };
};

export const setTaxDataDropdown = (data) => {
  return { type: SET_TAXDATA_DROPDOWN, data };
};

export const updateTaxData = (effectiveDate, data) => {
  return { type: UPDATE_TAXDATA, effectiveDate, data };
};

export const doneUpdateTaxData = (data) => {
  return { type: DONE_UPDATE_TAXDATA, data };
};

export const createTaxData = (data) => {
  return { type: CREATE_TAXDATA, data };
};

export const doneCreateTaxData = (data) => {
  return { type: DONE_CREATE_TAXDATA, data };
};

//HOUSE HOLD

export const GET_HOUSEHOLD = "household@GET_HOUSEHOLD";
export const SET_HOUSEHOLD = "household@SET_HOUSEHOLD";
export const GET_HOUSEHOLD_DROPDOWN =
  "houseHoldDropdown@GET_HOUSEHOLD_DROPDOWN";
export const SET_HOUSEHOLD_DROPDOWN =
  "houseHoldDropdown@SET_HOUSEHOLD_DROPDOWN";
export const UPDATE_HOUSEHOLD = "household@UPDATE_HOUSEHOLD";
export const DONE_UPDATE_HOUSEHOLD = "household@DONE_UPDATE_HOUSEHOLD";

export const CREATE_HOUSEHOLD = "household@CREATE_HOUSEHOLD";
export const DONE_CREATE_HOUSEHOLD = "household@DONE_CREATE_HOUSEHOLD";

export const getHouseHold = (id, companyId) => {
  return { type: GET_HOUSEHOLD, id, companyId };
};

export const setHouseHold = (data) => {
  return { type: SET_HOUSEHOLD, data };
};

export const getHouseHoldDropdown = () => {
  return { type: GET_HOUSEHOLD_DROPDOWN };
};

export const setHouseHoldDropdown = (data) => {
  return { type: SET_HOUSEHOLD_DROPDOWN, data };
};

export const updateHousehold = (data) => {
  return { type: UPDATE_HOUSEHOLD, data };
};

export const doneUpdateHousehold = (data) => {
  return { type: DONE_UPDATE_HOUSEHOLD, data };
};

export const createHousehold = (data) => {
  return { type: CREATE_HOUSEHOLD, data };
};

export const doneCreateHousehold = (data) => {
  return { type: DONE_CREATE_HOUSEHOLD, data };
};
//HOUSEHOLD AGF-CODE-LAST
export const GET_HOUSEHOLD_AGFCODE_LAST =
  "household@GET_HOUSEHOLD_AGFCODE_LAST";
export const SET_HOUSEHOLD_AGFCODE_LAST =
  "household@SET_HOUSEHOLD_AGFCODE_LAST";

export const getHouseHoldAgfCodeLast = () => {
  return { type: GET_HOUSEHOLD_AGFCODE_LAST };
};

export const setHouseHoldAgfCodeLast = (data) => {
  return { type: SET_HOUSEHOLD_AGFCODE_LAST, data };
};

// PAYMENTS DATA
export const GET_PAYMENTSDATA = "paymentsData@GET_PAYMENTSDATA";
export const SET_PAYMENTSDATA = "paymentsData@SET_PAYMENTSDATA";
export const GET_PAYMENTS_DROPDOWN =
  "paymentsDataDropdown@GET_PAYMENTS_DROPDOWN";
export const SET_PAYMENTS_DROPDOWN =
  "paymentsDataDropdown@SET_PAYMENTS_DROPDOWN";

export const UPDATE_PAYMENTSDATA = "paymentsData@UPDATE_PAYMENTSDATA";
export const DONE_UPDATE_PAYMENTSDATA = "paymentsData@DONE_UPDATE_PAYMENTSDATA";

export const CREATE_PAYMENTSDATA = "paymentsData@CREATE_PAYMENTSDATA";
export const DONE_CREATE_PAYMENTSDATA = "paymentsData@DONE_CREATE_PAYMENTSDATA";

export const getPaymentsData = (id, companyId) => {
  return { type: GET_PAYMENTSDATA, id, companyId };
};

export const setPaymentsData = (data) => {
  return { type: SET_PAYMENTSDATA, data };
};

export const getPaymentsDropdown = () => {
  return { type: GET_PAYMENTS_DROPDOWN };
};

export const setPaymentsDropdown = (data) => {
  return { type: SET_PAYMENTS_DROPDOWN, data };
};

export const updatePaymentsData = (data) => {
  return { type: UPDATE_PAYMENTSDATA, data };
};

export const doneUpdatePaymentsData = (data) => {
  return { type: DONE_UPDATE_PAYMENTSDATA, data };
};

export const createPaymentsData = (data) => {
  return { type: CREATE_PAYMENTSDATA, data };
};

export const doneCreatePaymentsData = (data) => {
  return { type: DONE_CREATE_PAYMENTSDATA, data };
};

//TRAINING SHEET
export const GET_TRAINING = "training@GET_TRAINING";
export const SET_TRAINING = "training@SET_TRAINING";
export const GET_TRAINING_DROPDOWN = "trainingDropdown@GET_TRAINING_DROPDOWN";
export const SET_TRAINING_DROPDOWN = "trainingDropdown@SET_TRAINING_DROPDOWN";

export const UPDATE_TRAINING = "training@UPDATE_TRAINING";
export const DONE_UPDATE_TRAINING = "training@DONE_UPDATE_PAYMENTSDATA";

export const CREATE_TRAINING = "training@CREATE_TRAINING";
export const DONE_CREATE_TRAINING = "training@DONE_CREATE_TRAINING";

export const getTraining = (id, companyId) => {
  return { type: GET_TRAINING, id, companyId };
};

export const setTraining = (data) => {
  return { type: SET_TRAINING, data };
};

export const getTrainingDropdown = () => {
  return { type: GET_TRAINING_DROPDOWN };
};

export const setTrainingDropdown = (data) => {
  return { type: SET_TRAINING_DROPDOWN, data };
};

export const updateTraining = (data) => {
  return { type: UPDATE_TRAINING, data };
};

export const doneUpdateTraining = (data) => {
  return { type: DONE_UPDATE_TRAINING };
};

export const createTraining = (data) => {
  return { type: CREATE_TRAINING, data };
};

export const doneCreateTraining = (data) => {
  return { type: DONE_CREATE_TRAINING };
};
//Performance Evaluation Sheet
export const GET_PER_EV_FORM = "perEvForm@GET_PER_EV_FORM";
export const SET_PER_EV_FORM = "perEvForm@SET_PER_EV_FORM";
export const GET_PER_EV_FORM_DROPDOWN =
  "perEvFormDropdown@GET_PER_EV_FORM_DROPDOWN";
export const SET_PER_EV_FORM_DROPDOWN =
  "perEvFormDropdown@SET_PER_EV_FORM_DROPDOWN";

export const UPDATE_PER_EV_FORM = "perEvForm@UPDATE_PER_EV_FORM";
export const DONE_UPDATE_PER_EV_FORM = "perEvForm@DONE_UPDATE_PER_EV_FORM";

export const CREATE_PER_EV_FORM = "perEvForm@CREATE_PER_EV_FORM";
export const DONE_CREATE_PER_EV_FORM = "perEvForm@DONE_CREATE_PER_EV_FORM";

export const getPerEvForm = (id, companyId) => {
  return { type: GET_PER_EV_FORM, id, companyId };
};

export const setPerEvForm = (data) => {
  return { type: SET_PER_EV_FORM, data };
};

export const getPerEvFormDropdown = () => {
  return { type: GET_PER_EV_FORM_DROPDOWN };
};

export const setPerEvFormDropdown = (data) => {
  return { type: SET_PER_EV_FORM_DROPDOWN, data };
};

export const updatePerEvForm = (data) => {
  return { type: UPDATE_PER_EV_FORM, data };
};

export const doneUpdatePerEvForm = (data) => {
  return { type: DONE_UPDATE_PER_EV_FORM };
};

export const createPerEvForm = (data) => {
  return { type: CREATE_PER_EV_FORM, data };
};

export const doneCreatePerEvForm = (data) => {
  return { type: DONE_CREATE_PER_EV_FORM };
};
//Medical Record

export const GET_MEDICALRECORD = "medicalRecord@GET_MEDICALRECORD";
export const SET_MEDICALRECORD = "medicalRecord@SET_MEDICALRECORD";

export const UPDATE_MEDICALRECORD = "medicalRecord@UPDATE_MEDICALRECORD";
export const DONE_UPDATE_MEDICALRECORD =
  "medicalRecord@DONE_UPDATE_MEDICALRECORD";

export const CREATE_MEDICALRECORD = "medicalRecord@CREATE_MEDICALRECORD";
export const DONE_CREATE_MEDICALRECORD =
  "medicalRecord@DONE_CREATE_MEDICALRECORD";

export const getMedicalRecord = (id, companyId) => {
  return { type: GET_MEDICALRECORD, id, companyId };
};

export const setMedicalRecord = (data) => {
  return { type: SET_MEDICALRECORD, data };
};

export const updateMedicalRecord = (data) => {
  return { type: UPDATE_MEDICALRECORD, data };
};

export const doneUpdateMedicalRecord = (data) => {
  return { type: DONE_UPDATE_MEDICALRECORD, data };
};

export const createMedicalRecord = (data) => {
  return { type: CREATE_MEDICALRECORD, data };
};

export const doneCreateMedicalRecord = (data) => {
  return { type: DONE_CREATE_MEDICALRECORD, data };
};
