import React from "react";
import uuid from "uuid/v1";
import "../assets/scss/_title.scss";

function Title({ text, type}) {
  return (
    <div className="liftworld-title-wrapper">
      <div className={`${"liftworld-title"} ` + type} key={uuid()}>
        {text}
      </div>
    </div>
  );
}

export default Title;
