import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const RhTableList = ({ data, columns, onClickedRow, tableId }) => {
  const rowEventEditMod = {
    onClick: (e, row, rowIndex) => {
      onClickedRow(row);
    },
  };
  return (
    <div className="tableList1">
      {data?.length || 0 > 1 ? (
        <div className="custom-table custom-table-small cursor-pointer ">
          <ToolkitProvider
            keyField={tableId}
            data={data}
            columns={columns}
            search
          >
            {(props) => (
              <div className="tableList">
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  keyField={tableId}
                  striped
                  hover
                  condensed
                  bootstrap4
                  rowEvents={rowEventEditMod}
                  noDataIndication="Data not found"
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      ) : null}
    </div>
  );
};

export default RhTableList;
