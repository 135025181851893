import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import '~/assets/css/styles.css';
import '~/assets/css/icons.css';
import { timeConversion } from '~/components/Tasks/utils';

const PreviewPopover = props => {

  const { t, task } = props;

  const buildSLAColumn = (SLA) => {
    if (SLA) {
      const toDate = (dateStr) => {
        const day = dateStr.slice(0, 2)
        const month = dateStr.slice(2, 4)
        const year = dateStr.slice(4, 8)
        const hour = dateStr.slice(8, 10)
        const min = dateStr.slice(10, 12)
        const sec = dateStr.slice(12, 14)
        return new Date(year, month - 1, day, hour, min, sec)
      }
      const millis = toDate(SLA) - Date.now();
      return millis ? timeConversion(millis) : SLA
    }
    return ""
  };

  const renderLines = () => {
    if (task && task.metadata.length === 0)
      return (
        <div>Dados não encontrados</div>
      )
    else return task && task.metadata.map((data, index) => {
      if (data.value !== undefined && data.value !== null && data.value !== "") {
        if (data.name === "SLA")
          data.value = buildSLAColumn(data.value);
        return (
          <Row key={index} >
            <Col sm="6">
              <strong> {t(`taskPage.general.${data.name}`)}:</strong>
            </Col>
            <Col sm="6">
              <span>{data.value}</span>
            </Col>
          </Row >
        )
      }
    })
  }
  return renderLines() || null
}

export default withNamespaces()(PreviewPopover);
