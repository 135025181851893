import React from "react";
import { useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import Button from "~/components/Buttons/DownloadButton";

import { pagination } from "~/components/pagination/currentSizePerPage/8";

import { withNamespaces } from "react-i18next";
import Spinner from "~/components/Spinner";

import { exportCSV } from "~/utils/exportCSV";

const UsageReport = (props) => {
  const { t } = props;

  const { usageReport, isLoadingUsageReport } = useSelector(
    (state) => state.reportReducer
  );

  /*Entidade, user ou email, APP name, numero de docs, periodo (semanal)*/
  const columns = [
    {
      dataField: "organization",
      text: t("admin.report.organization"),
      sort: true,
    },
    {
      dataField: "user",
      text: t("admin.report.user"),
      sort: true,
    },
    {
      dataField: "application",
      text: t("admin.report.application"),
      sort: true,
    },
    {
      dataField: "submittedDocs",
      text: t("admin.report.numDocs"),
      sort: true,
    },
    {
      dataField: "week",
      text: t("admin.report.week"),
      sort: true,
    },
  ];

  const usageReport_ = usageReport.map((item, index) => {
    item.id = index;
    return item;
  });

  return (
    <div className="custom-table custom-small">
      <ToolkitProvider keyField="id" data={usageReport_} columns={columns}>
        {(props) => (
          <div>
            <Spinner spinning={isLoadingUsageReport} />
            {!isLoadingUsageReport && (
              <>
                <div style={{ paddingBottom: "10px" }}>
                  <Button text="Export CSV" />
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(pagination)}
                  bootstrap4
                  noDataIndication={t("admin.report.emptyTable")}
                  striped
                  hover
                />
              </>
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withNamespaces()(UsageReport);
