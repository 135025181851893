import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import uuid from "uuid/v1";
import Spinner from "~/components/Spinner";
import { loadMenuOnlyApps } from "~/store/ducks/application/actionTypes";
import { TabsKey } from '../../utils/TabsEnum';
import ApplicationLogoItem from "./ApplicationLogoItem";




const ApplicationLogo = ({ tabKey }) => {

  const dispatch = useDispatch();

  const {
    menuOnlyApps,
    isLoadingMenuOnlyApplications
  } = useSelector(state => state.applicationReducer);

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATION_LOGO) {
      dispatch(loadMenuOnlyApps());
    }
  }, [tabKey]);

  return (
    <Row>
      {
        isLoadingMenuOnlyApplications &&
        <Spinner spinning={isLoadingMenuOnlyApplications} wrapper />
      }
      {
        !isLoadingMenuOnlyApplications && menuOnlyApps.map(application =>
          <ApplicationLogoItem key={uuid()} application={application} />
        )
      }
    </Row>
  )
}

export default ApplicationLogo;
