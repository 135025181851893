export const SET_NOTIFICATION_UPDATED =
  "notifications@SET_NOTIFICATION_UPDATED";
export const MARK_AS_SEEN = "notifications@MARK_AS_SEEN";
export const MARK_ALL_AS_SEEN = "notifications@MARK_ALL_AS_SEEN";
export const DELETE_NOTIFICATION = "notifications@DELETE_NOTIFICATION";
export const SET_DELETED_NOTIFICATION =
  "notifications@SET_DELETED_NOTIFICATION";

export const GET_USER_PORTALRH_NOTIFICATIONS =
  "notifications@GET_USER_PORTALRH_NOTIFICATIONS";
export const SET_USER_PORTALRH_NOTIFICATIONS =
  "notifications@SET_USER_PORTALRH_NOTIFICATIONS";
export const GET_USER_BPM_NOTIFICATIONS =
  "notifications@GET_USER_BPM_NOTIFICATIONS";
export const SET_USER_BPM_NOTIFICATIONS =
  "notifications@SET_USER_BPM_NOTIFICATIONS";

export const SAVE_NOTIFICATION = "notifications@SAVE_NOTIFICATION";

export const setNotificationUpdated = (updated) => ({
  type: SET_NOTIFICATION_UPDATED,
  updated,
});
export const markAsSeen = (id) => ({ type: MARK_AS_SEEN, id });
export const markAllAsSeen = () => ({ type: MARK_ALL_AS_SEEN });
export const deleteNotification = (id) => ({ type: DELETE_NOTIFICATION, id });
export const setDeletedNotification = () => ({
  type: SET_DELETED_NOTIFICATION,
});

export const getUserPortalRHNotifications = (appId, user) => ({
  type: GET_USER_PORTALRH_NOTIFICATIONS,
  appId,
  user,
});
export const setUserPortalRHNotifications = (portalRHNotifications) => ({
  type: SET_USER_PORTALRH_NOTIFICATIONS,
  portalRHNotifications,
});
export const getUserBPMNotifications = (appId, user) => ({
  type: GET_USER_BPM_NOTIFICATIONS,
  appId,
  user,
});
export const setUserBPMNotifications = (bpmNotifications) => ({
  type: SET_USER_BPM_NOTIFICATIONS,
  bpmNotifications,
});

export const saveNotification = (notification) => ({
  type: SAVE_NOTIFICATION,
  notification,
});
