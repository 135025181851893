import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../../../i18n";
import "../assets/scss/_replegmodal.scss";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";
import CustomButton from "./Button";
import Input from "./Input";
import Link from "./Link";
import Modal from "./Modal";
import RadioButtonItem from "./RadioButtonItem";
import Text from "./Text";

function AddBenefModalContent({ title, isModalShowing, setIsModalShowing, t }) {
  var contents;

  const [activeTypeTab, setActiveTypeTab] = useState(
    t("bpm.onboarding.backoffice.user.labelIndividual")
  );
  const [activeDocTypeTab, setActiveDocTypeTab] = useState(
    t("bpm.onboarding.backoffice.user.labelCitizenCard")
  );

  useEffect(() => {
    if (document.dados["accordion_benef_tipo"]) {
      document.dados["accordion_benef_tipo"].value = activeTypeTab;
    }
    if (document.dados["accordion_benef_doctipo"]) {
      if (
        document.dados["accordion_benef_tipo"].value ===
        t("bpm.onboarding.backoffice.user.labelIndividual")
      )
        document.dados["accordion_benef_doctipo"].value = activeDocTypeTab;
      else {
        document.dados["accordion_benef_doctipo"].value = "";
        document.dados["accordion_benef_numdocumento"].value = "";
      }
    }
  }, [activeTypeTab, activeDocTypeTab]);

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='addBenef';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function buildInputField(text, variable) {
    return {
      value: "",
      obligatory: false,
      variable: variable,
      maxlenght: 9,
      text: text,
    };
  }

  function createBody() {
    return (
      <div className="easypay-benef-modal-body-wrapper">
        <div>
          <div className="easypay-benef-modal-body-subtitle">
            {t(`bpm.onboarding.backoffice.user.modalTypeOfBenefLabel`)}
          </div>
          <div className="easypay-accordion-radio-button-wrapper" key={uuid()}>
            <BenefModalRadioButtonWrapper
              key={uuid()}
              variant={"easypay-radio-button-row"}
              activeTab={activeTypeTab}
              setActivetab={setActiveTypeTab}
            >
              <RadioButtonItem
                key={uuid()}
                text={t("bpm.onboarding.backoffice.user.labelIndividual")}
                index={0}
                uniqueName={"benefType"}
                name={t("bpm.onboarding.backoffice.user.labelIndividual")}
                subHeader={""}
              ></RadioButtonItem>
              <RadioButtonItem
                key={uuid()}
                text={t("bpm.onboarding.backoffice.user.labelCompany")}
                uniqueName={"benefType"}
                index={1}
                name={t("bpm.onboarding.backoffice.user.labelCompany")}
                subHeader={""}
              ></RadioButtonItem>
            </BenefModalRadioButtonWrapper>
          </div>
        </div>
        <div style={{ width: "500px" }}>
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelName`),
              "accordion_benef_nome"
            )}
            key={"accordion_benef_nome"}
          ></Input>
        </div>
        <div style={{ width: "500px" }}>
          {activeTypeTab === "Empresa" ? (
            <Input
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelNIPC`),
                "accordion_benef_nif"
              )}
              key={"accordion_benef_nif"}
            ></Input>
          ) : (
            <Input
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelNIF`),
                "accordion_benef_nif"
              )}
              key={"accordion_benef_nif"}
            ></Input>
          )}
        </div>
        <div style={{ width: "180px" }}>
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelDirectCapital`),
              "accordion_benef_capital"
            )}
            key={"accordion_benef_capital"}
          ></Input>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "500px",
          }}
        >
          <div style={{ width: "200px", paddingRight: "20px" }}>
            <Input
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelIndirectCapital`),
                "accordion_benef_capitalind"
              )}
              key={"accordion_benef_capitalind"}
            ></Input>
          </div>
          <div style={{ width: "300px" }}>
            <Input
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelCompany`),
                "accordion_benef_empresa"
              )}
              key={"accordion_benef_empresa"}
            ></Input>
          </div>
        </div>
        <div style={{ width: "500px" }}>
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelAddress`),
              "accordion_benef_morada"
            )}
            key={"accordion_benef_morada"}
          ></Input>
        </div>
        {activeTypeTab ===
          t("bpm.onboarding.backoffice.user.labelIndividual") && (
          <div>
            <Text
              text={
                t(`bpm.onboarding.backoffice.user.labelIDDocument`) +
                "::card-text-no-padding"
              }
            />
            <div
              className="easypay-accordion-radio-button-wrapper"
              key={uuid()}
            >
              <BenefModalRadioButtonWrapper
                key={uuid()}
                variant={"easypay-radio-button-column"}
                activeTab={activeDocTypeTab}
                setActivetab={setActiveDocTypeTab}
                displayType={"column"}
              >
                <RadioButtonItem
                  key={uuid()}
                  uniqueName={"docType"}
                  text={t(`bpm.onboarding.backoffice.user.labelCitizenCard`)}
                  index={4}
                  name={t(`bpm.onboarding.backoffice.user.labelCitizenCard`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
                <RadioButtonItem
                  key={uuid()}
                  uniqueName={"docType"}
                  text={t(`bpm.onboarding.backoffice.user.labelPassport`)}
                  index={5}
                  name={t(`bpm.onboarding.backoffice.user.labelPassport`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
                <RadioButtonItem
                  key={uuid()}
                  uniqueName={"docType"}
                  text={t(`bpm.onboarding.backoffice.user.labelIdentityTicket`)}
                  index={6}
                  name={t(`bpm.onboarding.backoffice.user.labelIdentityTicket`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
                <RadioButtonItem
                  key={uuid()}
                  text={t(`bpm.onboarding.backoffice.user.labelResidenceTitle`)}
                  index={7}
                  uniqueName={"docTypeAccordion"}
                  name={t(`bpm.onboarding.backoffice.user.labelResidenceTitle`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
                <RadioButtonItem
                  key={uuid()}
                  text={t(`bpm.onboarding.backoffice.user.labelResidenceCard`)}
                  index={8}
                  uniqueName={"docTypeAccordion"}
                  name={t(`bpm.onboarding.backoffice.user.labelResidenceCard`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
              </BenefModalRadioButtonWrapper>
            </div>
            <div style={{ width: "500px" }}>
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.user.labelDocumentNumber`),
                  "accordion_benef_numdocumento"
                )}
                key={"accordion_benef_numdocumento"}
              ></Input>
            </div>
          </div>
        )}
        <div
          className="easypay-repleg-modal-footer-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <div
            className="easypay-repleg-modal-link-wrapper"
            style={{ paddingTop: "8px" }}
            onClick={(event) => {
              event.preventDefault();
              setIsModalShowing(false);
            }}
          >
            <Link
              text={t("bpm.onboarding.backoffice.user.buttonCancel")}
              disabledColored={false}
              disabled={false}
              link=""
              key={uuid()}
            />
          </div>
          <div
            className="easypay-repleg-modal-button-wrapper"
            style={{ paddingLeft: "25px" }}
          >
            <CustomButton
              text={t("bpm.onboarding.backoffice.user.buttonAdd")}
              variant={"easypay-button-primary"}
              onclick={eval(addButtonField.onclick)}
              key={uuid()}
              id={addButtonField.id}
              className="card-button"
              align={addButtonField.align}
              disabledColored={false}
              disabled={false}
              field={addButtonField}
            />
          </div>
        </div>
      </div>
    );
  }

  contents = {
    modalTitle: title,
    modalBody: createBody(),
  };

  return (
    <Modal
      className="easypay-modal"
      showModal={isModalShowing}
      setShowModal={setIsModalShowing}
      headerContent={contents.modalTitle}
      bodyContent={contents.modalBody}
      key={uuid()}
    ></Modal>
  );
}

export default withNamespaces()(AddBenefModalContent);
