import React, { useState, useEffect } from "react";
import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Spinner from "~/components/Spinner";
import Error404 from "~/components/Exception/Error404";

import { validateOrgAdmin } from "~/store/ducks/registerOrgAdmin/actionTypes";

const ValidationLoading = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [validatedParams, setValidatedParams] = useState(true);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { isLoadingValidateOrgAdmin, successValidationUser } = useSelector(
    (state) => state.registerOrgAdminReducer
  );

  useEffect(() => {
    if (!queryParams.hasOwnProperty("username" && "code")) {
      setValidatedParams(false);
      backToLogin();
    } else {
      dispatch(
        validateOrgAdmin({
          username: queryParams.username,
          code: queryParams.code,
        }, true)
      );
    }
  }, []);

  useEffect(() => {
    successValidationUser && backToLogin();
  }, [successValidationUser]);

  const backToLogin = () => {
    const payload = {
      pathname: `/login`,
      state: { name: "Login" },
    };
    history.replace(payload);
  };

  return validatedParams ? (
    <Spinner spinning={validatedParams || isLoadingValidateOrgAdmin} wrapper />
  ) : (
    <Error404 />
  );
};
export default withNamespaces()(ValidationLoading);
