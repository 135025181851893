import React, { useEffect, useState } from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import CustomDropdown from "../CustomDropdown";
import FlowModal from "~/components/FlowModal";

import { findIflowTasksByFilters } from "~/store/ducks/tasks/actionTypes";
import { getTTLVariant } from "~/components/Tasks/columnFormatter";
import { updateUserWidget } from "~/pages/User/actions";

import uuid from "uuid/v1";
import oauth from "~/utils/oauth";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import "./latestTasks.scss";
import "~/assets/css/icons.css";

const LatestTasksWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  index,
  ...props
}) => {
  const { t } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);
  const { tasks } = useSelector((state) => state.tasksReducer);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6",
        },
      };
      dispatch(updateUserWidget(payload));
    }
    if (widget) {
      const getUsername = (legacyUsers) => {
        let userBPM = legacyUsers.find(
          (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
        );
        return userBPM ? userBPM.username : null;
      };
      const userBPM = user.legacyUsers ? getUsername(user.legacyUsers) : null;
      if (userBPM) {
        const payload = {
          username: userBPM,
          application: BPM_MIDDLEWARE.context,
        };
        dispatch(findIflowTasksByFilters(payload));
      }
    }
  }, [widget]);

  const renderRow = (tasks) => {
    let cards = [];
    //const borders = ["#dc3545", "#ffc107", "#007bff"];
    tasks.forEach((task) => {
      //let borderIndex = Math.floor(Math.random() * Math.floor(3))
      const variant = getTTLVariant(task);
      cards.push(
        <Card
          bsPrefix="card-mini"
          key={uuid()}
          //style={{ boxShadow: `2px 5px 8px ${borders[borderIndex]}` }}
          style={{ boxShadow: `2px 5px 8px var(--${variant})` }}
        >
          <Card.Header>
            <h6>{task && task.flowname}</h6>
          </Card.Header>
          <Card.Body style={{ padding: "3px" }}>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <div
                  className="card-sub"
                  onClick={() => {
                    handleOpenFlowModal(task);
                  }}
                >
                  Nº Processo &nbsp;{task && task.pnumber}
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="task-description">
                  {task && task.description && task.description.length > 35
                    ? task.description.slice(0, 35) + "..."
                    : task.description}
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card.Body>
        </Card>
      );
    });
    return (
      <div className="tasks-row" key={uuid()}>
        {cards}
      </div>
    );
  };

  const renderRows = () => {
    if (window.innerWidth < 992)
      return [
        renderRow([...tasks.slice(0, 2)]),
        renderRow([...tasks.slice(2, 4)]),
      ];
    else
      return [
        renderRow([...tasks.slice(0, 3)]),
        renderRow([...tasks.slice(3, 6)]),
      ];
  };

  const [selectedTask, setSelectedTask] = useState(undefined);
  const [showFlowModal, setShowFlowModal] = useState(false);
  const src = selectedTask
    ? "iFlow/" + selectedTask.url + "&Authorization=" + oauth.getAccessToken()
    : "";

  const handleCloseFlowModal = () => {
    setShowFlowModal(false);
  };
  const handleOpenFlowModal = (task) => {
    setShowFlowModal(true);
    setSelectedTask(task);
  };

  return (
    <>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-bell mr-2" />
              {t("LatestTasksWidget.title")}
            </h6>
            <h6>
              {textMove && t("widgets.resizeMove")}
              {textClick && t("widgets.resizeClick")}
            </h6>
            <CustomDropdown
              apagarWidget={apagarWidget}
              handleMouseDown={handleMouseDown}
            />
          </Card.Header>
          {!isResizing && (
            <Card.Body style={{ padding: "10px" }}>
              <div className="tasks-row-wrapper">{renderRows()}</div>
            </Card.Body>
          )}
        </Card>
      </div>
      <FlowModal
        showModal={showFlowModal}
        closeModal={handleCloseFlowModal}
        processNumber={selectedTask && selectedTask.pnumber}
        process={selectedTask}
        src={src}
        app={"bpm"}
      />
    </>
  );
};
export default withNamespaces()(LatestTasksWidget);
