import React, { useState } from 'react';

import ChatList from './ChatList';
import ChatRoom from './ChatRoom';

const Chat = ({ showChat }) => {

  const [showChatRoom, setShowChatRoom] = useState(false);
  const [receiver, setReceiver] = useState(undefined);

  return (
    <div className={showChat ? 'right-sidebar-expand' : ''}>
      {
        showChat &&
        <ChatList setShowChatRoom={setShowChatRoom} setReceiver={setReceiver} />
      }
      {
        showChatRoom &&
        <ChatRoom
          showChatRoom={showChatRoom}
          setShowChatRoom={setShowChatRoom}
          receiver={receiver}
          setReceiver={setReceiver}
        />
      }
    </div>
  );

}

export default Chat;