import React, { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Form, Row, Badge } from "react-bootstrap";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { updateUserWidget } from "~/pages/User/actions";
import { getMenusByApplication } from "~/store/ducks/applicationMenu/actionTypes";

import Dropdown from "~/components/Dropdown";

import "~/assets/css/icons.css";
import { padStart } from "lodash";

const ReportFilters = ({ ...props }) => {
  //widget
  const { t, widget, executeGraph } = props;
  const dispatch = useDispatch();
  let { path } = useRouteMatch();

  const { rawMenus } = useSelector((state) => state.applicationMenuReducer);
  const { user } = useSelector((state) => state.globalReducer);

  const [nFlowId, setFlowId] = useState("");
  const [endAt, setEndAt] = useState("");
  const [startAt, setStartAt] = useState("");

  const [showDateInputs, setShowDateInputs] = useState(false);

  const [variantThisMonth, setVariantThisMonth] = useState("light");
  const [variantThisYear, setVariantThisYear] = useState("light");
  const [variantLastMonth, setVariantLastMonth] = useState("light");
  const [variantLastYear, setVariantLastYear] = useState("light");

  useEffect(() => {
    resetDateButtonsVariant();
    changeDateControl({ startAt: startAt, endAt: endAt });
  }, []);

  useEffect(() => {
    if (
      variantThisMonth === "secondary" ||
      variantThisYear === "secondary" ||
      variantLastMonth === "secondary" ||
      variantLastYear === "secondary"
    )
      setShowDateInputs(false);
    else {
      setShowDateInputs(true);
    }

    resetDateButtonsVariant();
    changeDateControl({ startAt: startAt, endAt: endAt });
  }, [
    variantThisMonth,
    variantThisYear,
    variantLastMonth,
    variantLastYear,
    startAt,
    endAt,
  ]);

  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      //get menus by path
      const app = path.split("/uniksystem/")[1];
      dispatch(
        getMenusByApplication(username, app ? app : BPM_MIDDLEWARE.context)
      );
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (widget && widget.userConfiguration) {
      const config = widget.userConfiguration;
      if (config.startAt) {
        setStartAt(config.startAt.slice(0, 10));
      } else {
        setStartAt("");
      }
      if (config.endAt) {
        setEndAt(config.endAt.slice(0, 10));
      } else {
        setEndAt("");
      }
      if (config.flowId) {
        setFlowId(config.flowId);
      }
      executeGraph(
        config.startAt ? config.startAt.slice(0, 10) : "",
        config.endAt ? config.endAt.slice(0, 10) : "",
        config.flowId
      );
    } else {
      executeGraph("", "", null);
    }
  }, [widget.userConfiguration]);

  const handleUserSettings = (flowId_, endAt_, startAt_) => {
    const payload = {
      id: props.widget.id,
      widgetId: props.widget.widgetId,
      userConfiguration: {
        type: ".BPMReportConfiguration",
        configurationId: null,
        flowId: flowId_,
        endAt: endAt_,
        startAt: startAt_,
      },
    };
    dispatch(updateUserWidget(payload));
  };
  const handleEndDate = (e) => {
    setEndAt(e);
    resetDateButtonsVariant();
    changeDateControl({ startAt: startAt, endAt: e });
    handleUserSettings(nFlowId, e, startAt);
  };

  const handleStartDate = (e) => {
    setStartAt(e);
    resetDateButtonsVariant();
    changeDateControl({ startAt: e, endAt: endAt });
    handleUserSettings(nFlowId, endAt, e);
  };

  const handleFlowId = (e) => {
    setFlowId(e);
    handleUserSettings(e, endAt, startAt);
  };

  const renderFlowOptions = () =>
    rawMenus.map((menu) => {
      return {
        label: menu.label,
        value: menu.url.split("flowid=")[1].toString(),
        selected: false,
      };
    });

  const handleLastMonth = () => {
    resetDateButtonsVariant();
    setVariantLastMonth("secondary");

    setStartAt(getLastMonth().startAt);
    setEndAt(getLastMonth().endAt);
    handleUserSettings(nFlowId, getLastMonth().endAt, getLastMonth().startAt);
  };

  const handleLastYear = () => {
    resetDateButtonsVariant();
    setVariantLastYear("secondary");

    setStartAt(getLastYear().startAt);
    setEndAt(getLastYear().endAt);
    handleUserSettings(nFlowId, getLastYear().endAt, getLastYear().startAt);
  };

  function getLastYear() {
    const date = new Date();
    const year = date.getFullYear();
    const startAt = year - 1 + "-" + "01-01";
    const endAt = year - 1 + "-" + "12-31";
    return { startAt: startAt, endAt: endAt };
  }

  function getThisYear() {
    const date = new Date();
    const year = date.getFullYear();
    const localStartAt = year + "-" + "01-01";
    const localEndAt = year + "-" + "12-31";
    return { startAt: localStartAt, endAt: localEndAt };
  }

  function getLastMonth() {
    let date;
    date = new Date();
    const month =
      date.getMonth() >= 10 ? date.getMonth() : "0" + date.getMonth();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    const year = date.getFullYear();
    const startAt = year + "-" + month + "-01";
    let calcLastDay = date;
    calcLastDay.setMonth(calcLastDay.getMonth() + 1);
    calcLastDay.setDate(0);
    let lastDay = calcLastDay.getDate();
    const endAt =
      calcLastDay.getFullYear() +
      "-" +
      padStart(calcLastDay.getMonth() + 1, 2, "0") +
      "-" +
      lastDay;

    return { startAt: startAt, endAt: endAt };
  }

  const handleThisYear = () => {
    resetDateButtonsVariant();
    setVariantThisYear("secondary");

    setStartAt(getThisYear().startAt);
    setEndAt(getThisYear().endAt);
    handleUserSettings(nFlowId, getThisYear().endAt, getThisYear().startAt);
  };

  const handleThisMonth = () => {
    resetDateButtonsVariant();
    setVariantThisMonth("secondary");

    setStartAt(getThisMonth().startAt);
    setEndAt(getThisMonth().endAt);
    handleUserSettings(nFlowId, getThisMonth().endAt, getThisMonth().startAt);
  };

  function getThisMonth() {
    const date = new Date();
    const year = date.getFullYear();
    let calcLastDay = new Date();
    calcLastDay.setMonth(calcLastDay.getMonth() + 1);
    calcLastDay.setDate(0);
    let lastDay = calcLastDay.getDate();
    const startAt = year + "-" + padStart(date.getMonth() + 1, 2, "0") + "-01";
    const endAt =
      year + "-" + padStart(date.getMonth() + 1, 2, "0") + "-" + lastDay;
    return { startAt: startAt, endAt: endAt };
  }

  const changeDateControl = (value) => {
    if (
      value.startAt === getThisYear().startAt &&
      value.endAt === getThisYear().endAt
    ) {
      setVariantThisYear("secondary");
    } else if (
      value.startAt === getLastYear().startAt &&
      value.endAt === getLastYear().endAt
    ) {
      setVariantLastYear("secondary");
    } else if (
      value.startAt === getLastMonth().startAt &&
      value.endAt === getLastMonth().endAt
    ) {
      setVariantLastMonth("secondary");
    } else if (
      value.startAt === getThisMonth().startAt &&
      value.endAt === getThisMonth().endAt
    ) {
      setVariantThisMonth("secondary");
    }
  };

  const resetDateButtonsVariant = () => {
    setVariantThisMonth("light");
    setVariantThisYear("light");
    setVariantLastMonth("light");
    setVariantLastYear("light");
  };

  /**Styles */
  const badgeStyle = {
    cursor: "pointer",
    height: "100%",
    display: "flex",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  };

  const periodWrapperStyle = {
    display: "flex",
    flexWrap: "nowrap",
    margin: "5px 0px",
    height: "35px",
  };

  return (
    <>
      <Form.Row style={{ marginTop: "0", height: "49px" }}>
        <Dropdown
          list={renderFlowOptions()}
          handleSubmit={handleFlowId}
          emptyDefault={true}
        />
      </Form.Row>
      {!showDateInputs && (
        <>
          <Row style={periodWrapperStyle}>
            <Badge
              variant="light"
              style={{ ...badgeStyle, width: "100%" }}
              onClick={() => setShowDateInputs(true)}
            >
              {t("SlaReportWidget.dataRegisto")}
              <i className="icon-down" />
            </Badge>
          </Row>

          <div
            style={{
              height: "93px",
              paddingBottom: "5px",
              marginBottom: "5px",
            }}
          >
            <Row
              style={{
                display: "flex",
                flexWrap: "nowrap",
                margin: "0px",
                height: "50%",
              }}
            >
              <Badge
                variant={variantThisMonth}
                style={{ ...badgeStyle, marginRight: "5px" }}
                onClick={() => handleThisMonth()}
              >
                {t("SlaReportWidget.thismonth")}
              </Badge>
              <Badge
                variant={variantThisYear}
                style={badgeStyle}
                onClick={() => handleThisYear()}
              >
                {t("SlaReportWidget.thisyear")}
              </Badge>
            </Row>
            <Row
              style={{
                display: "flex",
                flexWrap: "nowrap",
                margin: "5px 0 0 0",
                height: "50%",
              }}
            >
              <Badge
                variant={variantLastMonth}
                style={{ ...badgeStyle, marginRight: "5px" }}
                onClick={() => handleLastMonth()}
              >
                {t("SlaReportWidget.lastmonth")}
              </Badge>
              <Badge
                variant={variantLastYear}
                style={badgeStyle}
                onClick={() => handleLastYear()}
              >
                {t("SlaReportWidget.lastyear")}
              </Badge>
            </Row>
          </div>
        </>
      )}
      {showDateInputs && (
        <>
          <Row style={periodWrapperStyle}>
            <Badge
              variant="light"
              style={{ ...badgeStyle, width: "100%" }}
              onClick={() => setShowDateInputs(false)}
            >
              {t("SlaReportWidget.dataRegisto")}
              <i className="icon-up" />
            </Badge>
          </Row>

          <div style={{ marginBottom: "5px", height: "93px" }}>
            <Form.Row style={{ padding: "0 0 5px 0", margin: "0px" }}>
              <Form.Control
                type="date"
                value={startAt}
                style={{ fontSize: "0.8rem" }}
                onChange={(e) => handleStartDate(e)}
              />
            </Form.Row>
            <Form.Row style={{ margin: "0px" }}>
              <Form.Control
                type="date"
                value={endAt}
                style={{ fontSize: "0.8rem" }}
                onChange={(e) => handleEndDate(e)}
              />
            </Form.Row>
          </div>
        </>
      )}
    </>
  );
};
export default withNamespaces()(ReportFilters);
