import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import OrganizationTable from "./components/OrganizationTable";
import ProtectedPage from "~/components/security/ProtectedPage";

import { Card } from "react-bootstrap";
import {
  getOrganizations,
  clearOrganizations,
} from "~/store/ducks/organizations/actionTypes";

import { hasRoles } from "~/components/security/utils";

import { ROLE_ADMIN } from "~/utils/constants/roles";

const Organization = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector((state) => state.globalReducer);
  const { organizations } = useSelector((state) => state.organizationsReducer);

  useEffect(() => {
    dispatch(getOrganizations());
    return () => {
      dispatch(clearOrganizations());
    };
  }, [dispatch]);

  const persistOrganization = () => {
    history.push("/uniksystem/admin/organization/create");
  };

  const filteredOrganizations = (organizations) =>
    Array.isArray(organizations) &&
    organizations.length > 1 &&
    organizations.filter(
      (listOrganization) =>
        listOrganization?.id === user?.organization?.id &&
        listOrganization?.description
    );

  const filterOrganizationsByRoles = () => {
    return hasRoles(user, ROLE_ADMIN)
      ? organizations
      : filteredOrganizations(organizations);
  };

  return (
    <ProtectedPage roles={[ROLE_ADMIN]}>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>{t("admin.organization.list.cardTitle")}</h6>
          </Card.Header>

          <Card.Body>
            <OrganizationTable
              organizations={filterOrganizationsByRoles()}
              persistOrganization={persistOrganization}
            />
          </Card.Body>
        </Card>
      </div>
    </ProtectedPage>
  );
};

export default withNamespaces()(Organization);
