import React, { useRef, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import iconCalendarActive from "../assets/img/icons/date-icon-active.png";
import iconCalendarDefault from "../assets/img/icons/date-icon-default.png";
import iconCalendarDisabled from "../assets/img/icons/date-icon-disabled.png";
import DateRange from "./DateRange";

const InputDate = ({
  placeholder,
  disabled,
  text,
  handleSubmit,
  defaultValue = ["", ""],
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dates, setDates] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const calendarId = useRef(uuid());

  useEffect(() => {

    
    if (defaultValue[0] === "" || defaultValue[1] === "") {
      setFrom("dd/mm/yyyy")
      setTo("dd/mm/yyyy")
      return;
    }

    var string1 = `${defaultValue[0].getFullYear()}-${
      defaultValue[0].getMonth().toString().length === 1
        ? "0" + (defaultValue[0].getMonth() + 1)
        : defaultValue[0].getMonth() + 1
    }-${
      defaultValue[0].getDate().toString().length === 1
        ? "0" + defaultValue[0].getDate()
        : defaultValue[0].getDate()
    }`;

    const dateInfo1 = string1.split("-");

    var string2 = `${defaultValue[1].getFullYear()}-${
      defaultValue[0].getMonth().toString().length === 1
        ? "0" + (defaultValue[1].getMonth() + 1)
        : defaultValue[1].getMonth() + 1
    }-${
      defaultValue[1].getDate().toString().length === 1
        ? "0" + defaultValue[1].getDate()
        : defaultValue[1].getDate()
    }`;

    const dateInfo2 = string2.split("-");

    setFrom(
      dateInfo1 != null && dateInfo1 != undefined && dateInfo1?.length > 0 && dateInfo2[0] != "NaN"
        ? dateInfo1[2] + "/" + dateInfo1[1] + "/" + dateInfo1[0] 
        : "dd/mm/yyyy"
    );
    setTo(
      dateInfo2 != null && dateInfo2 != undefined && dateInfo2?.length > 0
        && dateInfo2[0] != "NaN" ? dateInfo2[2] + "/" + dateInfo2[1] + "/" + dateInfo2[0]
        : "dd/mm/yyyy"
    );
  }, []);

  const setInputDate = () => {
    var string1 = `${dates[0].getFullYear()}-${
      dates[0].getMonth().toString().length === 1
        ? "0" + (dates[0].getMonth() + 1)
        : dates[0].getMonth() + 1
    }-${
      dates[0].getDate().toString().length === 1
        ? "0" + dates[0].getDate()
        : dates[0].getDate()
    }`;

    const dateInfo1 = string1.split("-");

    var string2 = `${dates[1].getFullYear()}-${
      dates[0].getMonth().toString().length === 1
        ? "0" + (dates[1].getMonth() + 1)
        : dates[1].getMonth() + 1
    }-${
      dates[1].getDate().toString().length === 1
        ? "0" + dates[1].getDate()
        : dates[1].getDate()
    }`;

    const dateInfo2 = string2.split("-");

    setFrom(dateInfo1[2] + "/" + dateInfo1[1] + "/" + dateInfo1[0]);
    setTo(dateInfo2[2] + "/" + dateInfo2[1] + "/" + dateInfo2[0]);

    if (handleSubmit) {
      handleSubmit([
        new Date(new Date(dates[0]).toISOString().split("T")).getTime(),
        new Date(new Date(dates[1]).toISOString().split("T")).getTime(),
      ]);
    }
  };

  const clearHandler = () => {
    setDates("");
    setFrom("");
    setTo("");
    setShowCalendar(false);
    handleSubmit("");
  };

  const submitHandler = () => {
    if (dates.length > 0) {
      setInputDate();
    }
    setShowCalendar(false);
  };

  return (
    <>
      <div className="easypay-input-label">{text}</div>
      <Form.Group controlId={`formText-field`} style={{ margin: "0px" }}>
        <div
          id={`input-date-${calendarId}`}
          disabled={disabled}
          className={`easypay-input-wrapper-date  
        ${showCalendar ? "active" : ""}
       `}
        >
          <input
            className="easypay-input-field-date"
            placeholder={placeholder ? placeholder : ""}
            readOnly={true}
            disabled={disabled}
            type="text"
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            value={from}
          />
          <div className="easypay-date-separator-wrap">
            <div disabled={disabled} className="easypay-date-separator"></div>
          </div>
          <input
            className="easypay-input-field-date right"
            placeholder={placeholder ? placeholder : ""}
            readOnly={true}
            disabled={disabled}
            type="text"
            onChange={(e) => {
              setTo(e.target.value);
            }}
            value={to}
          />
          <div
            className={`easypay-input-calendar-icon-wrapper ${
              disabled ? "disabled" : ""
            }`}
          >
            <img
              className="easypay-input-calendar-icon"
              src={
                disabled
                  ? iconCalendarDisabled
                  : showCalendar
                  ? iconCalendarActive
                  : iconCalendarDefault
              }
              alt="Icon calendar"
              onClick={() => {
                !disabled && setShowCalendar(true);
              }}
            />
          </div>
          {showCalendar && (
            <DateRange
              id={calendarId}
              dates={dates}
              setDates={setDates}
              setShowCalendar={setShowCalendar}
              submit={submitHandler}
              clear={clearHandler}
            />
          )}
        </div>
      </Form.Group>
    </>
  );
};

export default withNamespaces()(InputDate);
