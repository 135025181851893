import React from "react";
import { Trans } from "react-i18next";
import "../../../i18n";
import { withNamespaces } from "react-i18next";

function TextWithLinks({ t}) {

  return (
    <div style={{ width: "100%" }}>
      <Trans i18nKey="optionalKey">
        <span style={{ color: "#F92DD3" }}>
          {t(`bpm.onboarding.user.labelTextLink1`)}
        </span>
        <a
          className={`easypay-link-primary`}
          href={"https://www.easypay.pt/atividades-proibidas/"}
        >
          {"www.easypay.pt/atividades-proibidas/"}
        </a>
        <span style={{ color: "#F92DD3" }}>{t(`bpm.onboarding.user.labelTextLink2`)}</span>
        <strong style={{ color: "#F92DD3" }}>{t(`bpm.onboarding.user.labelTextLink3`)}</strong>
      </Trans>
    </div>
  );
}

export default withNamespaces()(TextWithLinks);
