import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import PersonalAreaRequestTable from "./PersonalAreaRequestTable";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import FlowModal from "~/components/FlowModal";
import NavTabs from "../../NavTabs";
import Spinner from "~/components/Spinner";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import "~/assets/css/icons.css";

const PersonalAreaRequestPage = ({
  t,
  showFlowModal,
  handleOpenFlowModal,
  handleCloseFlowModal,
  selectedProcess,
  searchFilter,
  sectionName,
  portalRhMenus,
  activeSelected,
  designacao,
}) => {
  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMenus({});
    /*close full screen iframes*/
    closeIframesScreens();
    setContainMenu(false);
    setMenus(
      generateRequestMenu(portalRhMenus, designacao, setContainMenu)
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner spinning={isLoading} transparent={false} />
      ) : (
        <>
          <div className="main-card-v2" style={{ width: "100%" }}>
            <Card
              bsPrefix="card-flat"
              style={{ minHeight: "80vh", overflow: "auto" }}
            >
              <Card.Header className="justify-content-between">
                <NavTabs
                  titleMenu={t(`${sectionName}`)}
                  menus={menus}
                  activeSelected={activeSelected}
                  containMenu={containMenu}
                  setIsLoading={setIsLoading}
                  t={t}
                />
              </Card.Header>
              <Card.Body>
                <PersonalAreaRequestTable
                  handleOpenFlowModal={handleOpenFlowModal}
                  searchFilter={searchFilter}
                />
              </Card.Body>
            </Card>
          </div>
          <FlowModal
            showModal={showFlowModal}
            closeModal={handleCloseFlowModal}
            process={selectedProcess}
            isPortal={true}
          />
        </>
      )}
    </>
  );
};
export default withNamespaces()(PersonalAreaRequestPage);
