import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";
import { map, find, some } from "lodash";
import {
  GLOBAL_SOLVER_MIDDLEWARE,
  RH_MIDDLEWARE,
  PORTALRH_MIDDLEWARE
} from '~/utils/constants';

export const getPuzzleAccess=(user, label, legacyUsers)=>{
  if (some(user.roles, { label: ROLE_ADMIN })) return true;
  if (some(user.roles, { label: ROLE_ORG_ADMIN })) return true;
  else {
    if (label === GLOBAL_SOLVER_MIDDLEWARE.context)
      return true;
    if (label === RH_MIDDLEWARE &&
      legacyUsers.some(user => user.legacyApplication.label === PORTALRH_MIDDLEWARE.context))
      return true;
    const permission = "app-" + label.toLowerCase();
    let userPermissions = map(user.roles, 'permissions').flat();
    let foundPermission = find(userPermissions, { label: permission });
    return foundPermission;
  }
};