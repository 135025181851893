import * as actions from "../actions";
import update from "react-addons-update";
import { findIndex, sortBy, find } from "lodash";

const initialState = {
  roles: [],
  isLoadingRoles: false,
  role: [],
  isLoadingLegacyRoles: false,
  isLoadingLegacyApplications: false,
  isLoadingRole: false,
  isPersisting: false,
  isDeleting: false,
  defaultRoles: [],
  isUpdatedDefaultRoles: false,
  legacyApps: [],
  legacyRoles: [],

  users: [],
  roleUsers: [],
  isLoadingUsers: false,
};

export const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_LEGACY_APPS:
      return {
        ...state, isLoadingLegacyApplications: true
      };
    case actions.SET_LEGACY_APPS:
      return { ...state, legacyApps: action.data, isLoadingLegacyApplications: false };

    case actions.LOAD_LEGACY_ROLES:
      return { ...state, isLoadingLegacyRoles: true };
    case actions.SET_LEGACY_ROLES:
      return {
        ...state,
        legacyRoles: action.data,
        isLoadingLegacyRoles: false
      };

    case actions.GET_ROLES:
      return { ...state, isLoadingRoles: true };
    case actions.SET_ROLES:
      return {
        ...state,
        roles: action.roles,
        isLoadingRoles: false,
      };
    case actions.PERSIST_ROLE:
      return { ...state, isPersisting: true };
    case actions.PERSIST_ROLE_DONE:
      return { ...state, isPersisting: false };
    case actions.GET_ROLE_BY_ID:
      return { ...state, isLoadingRole: true };
    case actions.SET_ROLE:
      return {
        ...state,
        role: action.role,
        isLoadingRole: false,
      };
    case actions.CLEAR_ROLES:
      return { ...state, roles: [] };
    case actions.CLEAR_ROLE:
      return { ...state, role: [] };
    case actions.DELETE_ROLE:
      return { ...state, isDeleting: true };
    case actions.DELETE_ROLE_DONE:
      return { ...state, isDeleting: false };
    case actions.ADD_ROLE_ITEM:
      if (!find(state.roles, { id: action.role.id })) {
        const roles_ = update(state.roles, { $push: [action.role] });
        const sortedRoles = sortBy(roles_, (role) => role.id);
        return { ...state, roles: sortedRoles };
      } else return state;
    case actions.REMOVE_ROLE_ITEM:
      const { role } = action;
      const index = findIndex(state.roles, { id: role.id });
      const updatedRoles = update(state.roles, { $splice: [[index, 1]] });
      return { ...state, roles: updatedRoles };

    case actions.FIND_USERS_BY_ROLE:
      return { ...state, isLoadingUsers: true };
    case actions.SET_FIND_USERS_BY_ROLE:
      return {
        ...state,
        isLoadingUsers: false,
        users: action.users,
      };
    case actions.ALL_USERS_BY_ROLE:
      return { ...state, isLoadingUsers: true };
    case actions.ALL_USERS_BY_ROLE_DONE:
      return { ...state, isLoadingUsers: false, roleUsers: action.roleUsers };
    case actions.UPDATE_UNIK_USERS:
      return { ...state, isPersisting: true };
    case actions.UPDATE_UNIK_USERS_DONE:
      return { ...state, isPersisting: false };

    case actions.SET_DEFAULT_ROLES:
      return { ...state, defaultRoles: action.defaultRoles, isUpdatingDefaultRoles: false };
    case actions.SET_UPDATED_DEFAULT_ROLES:
      return { ...state, isUpdatedDefaultRoles: action.updated };
    default:
      return state;
  }
};
