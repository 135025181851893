import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import Spinner from "~/components/Spinner";
import { getIntranetLogin, checkIsIntranetLogin } from "../Login/actions/index";

const IntranetLogin = () => {
  const dispatch = useDispatch();
  const { isLoadingIntranet } = useSelector((state) => state.loginReducer);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      console.log(window.location.hash);

      let bothTokensUrl = window.location.hash?.split(
        "intranet-integration?"
      )[1];
      let access_tokenAux = bothTokensUrl?.split("&")[0];
      let refresh_tokenAux = bothTokensUrl?.split("&")[1];

      let access_token = access_tokenAux?.split("access_token=")[1];
      let refresh_token = refresh_tokenAux?.split("refresh_token=")[1];
      let expires_in = 86400;

      let obj = {
        access_token,
        refresh_token,
        expires_in,
      };

      dispatch(checkIsIntranetLogin());
      dispatch(getIntranetLogin(obj));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingIntranet) {
      setIsLoading(false);
      redirectToUnikPage();
    }
  }, [isLoadingIntranet]);

  const redirectToUnikPage = () => {
    const payload = {
      pathname: "/",
      state: { name: "Uniksystem" },
    };
    history.push(payload);
  };

  return isLoading ? (
    <>
      <Spinner wrapper spinning={isLoadingIntranet} />
    </>
  ) : null;
};

export default withRouter(IntranetLogin);
