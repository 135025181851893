import { takeLatest } from "redux-saga/effects";

import {
  GET_API_SETTINGS,
  PERSIST_API_SETTINGS
} from "../ducks/onboardingSettings/actionTypes";
import {
  getAPISettings,
  persistAPISettings
} from "../ducks/onboardingSettings/sagas";

export const onboardingSettingsSagas = [
  takeLatest(GET_API_SETTINGS, getAPISettings),
  takeLatest(PERSIST_API_SETTINGS, persistAPISettings),
];
