import React from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_card.scss";
import "../assets/scss/_radioButtonWrapper.scss";

const BenefModalRadioButtonWrapper = ({ variant, children,activeTab, setActivetab,displayType }) => {

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      activeTab,
      child,
      index,
      setTheActiveTab: (name) => {
        setActivetab(name);
      },
      index,
    });
  });


  return (
    <div className={`easypay-radio-button-wrapper ${displayType}`}>
      <div className={`${variant}`}>
        {childrenWithProps}
      </div>
    </div>
  );
};

export default withNamespaces()(BenefModalRadioButtonWrapper);
