import React from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/css/styles.css";
import "../assets/scss/_backofficeRegistrySection.scss";
import Input from "./Input";
import Dropdown from "./InputDropdown";
import RadioButtonItem from "./RadioButtonItem";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";

const BackofficeRegistrySection = ({ t, data }) => {
  const partnerList = [
    { label: "N/a", value: "N/a", selected: false },
    { label: "Unik", value: "Unik", selected: false },
    { label: "Novo Banco", value: "Novo Banco", selected: false },
  ];
  const mobileList = [
    { label: "PT(+351)", value: "PT(+351)", selected: true },
    { label: "DE(+050)", value: "DE(+050)", selected: false },
    { label: "ES(+092)", value: "ES(+092)", selected: false },
  ];

  const updateUser = (value, variable) => {
    const variableName = variable?.split("-");
    if (variableName.length === 2) {
      data[variableName[0]][variableName[1]] = value;
    }
  };

  const dateFormatter = (date) => {
    if (!date) {
      return date;
    }
    const dateAux = new Date(date);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  function handleSelectChange(e,name) {
    if(name === "parceiro")
    {
      data.client.parceiro = e
    }
  }

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  return (
    <div className="easypay-backoffice-sections-main-wrapper">
      <div className="easypay-backoffice-registry-section-header">
        {t("bpm.onboarding.backoffice.administration.registryButton")}
      </div>
      <div className="easypay-backoffice-registry-section-content-wrapper">
        <div className="easypay-backoffice-registry-section-column-content">
          <div className="easypay-backoffcie-registry-section-inputs-wrapper">
            <div className="easypay-backoffice-registry-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.tableIdLead") +
                    "::disabled",
                  data?.account?.pid
                )}
                name="lead-id"
              />
            </div>
            <div className="easypay-backoffice-registry-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(
                    "bpm.onboarding.backoffice.administration.tableCreationDate"
                  ) + "::disabled",
                  dateFormatter(data?.account?.createdDate)
                )}
                name="lead-creation-date"
              />
            </div>
          </div>
          <Dropdown
            text={t("bpm.onboarding.backoffice.administration.tablePartner")}
            required={true}
            list={partnerList}
            handleSubmit={(e) => handleSelectChange(e,"parceiro")}
            name="lead-drop-partner"
            isColor = {false}
            initialValue = {data?.client?.parceiro}
          />
          <div className="easypay-backoffcie-registry-section-inputs-wrapper">
            <div className="easypay-backoffice-registry-section-small-input-wrapper">
              <Dropdown
                text={t(
                  "bpm.onboarding.backoffice.administration.headerCountry"
                )}
                required={true}
                placeholder="Escolha um país"
                list={mobileList}
                handleSubmit={(e) => handleSelectChange(e)}
                name="lead-drop-country"
              />
            </div>
            <div className="easypay-backoffice-registry-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.tablePhone"),
                  data?.client?.numTelemovel
                )}
                name="client-numTelemovel"
                onChange={updateUser}
              />
            </div>
          </div>
        </div>
        <div className="easypay-backoffice-registry-section-column-content">
          <div className="easypay-backoffice-registry-radio-button-header">
            {t("bpm.onboarding.backoffice.administration.headerLanguage")}
          </div>
          <div className="easypay-backoffice-registry-radio-button-wrapper">
            <BenefModalRadioButtonWrapper
              key={uuid()}
              variant={"easypay-radio-button-row"}
            >
              <RadioButtonItem
                key={uuid()}
                text="PT"
                uniqueName="backofficeRadioLanguage"
                index={0}
                name="lead-radio-country"
                checked
                disabled
              />
              <RadioButtonItem
                key={uuid()}
                text="EN"
                uniqueName="backofficeRadioLanguage"
                index={1}
                name="lead-radio-country"
                disabled
              />
              <RadioButtonItem
                key={uuid()}
                text="ES"
                index={2}
                uniqueName="backofficeRadioLanguage"
                name="lead-radio-country"
                disabled
              />
            </BenefModalRadioButtonWrapper>
            <div className="easypay-backoffice-registry-section-name-input-wrapper">
              <Input
                field={buildInputField(
                  t("bpm.onboarding.backoffice.administration.tableName"),
                  data?.client?.nome
                )}
                name="client-nome"
                onChange={updateUser}
              />
            </div>
            <Input
              field={buildInputField(
                t("bpm.onboarding.backoffice.administration.tableEmail"),
                data?.client?.email
              )}
              name="client-email"
              onChange={updateUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(BackofficeRegistrySection);
