import React from "react";
import { Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import "~/assets/css/icons.css";
import Messages from "~/components/Messages";
import Spinner from "~/components/Spinner";
import BatchTable from "./components/BatchTable";

const RevisaoDespesas = ({ t }) => {
  const { isLoadingBatchs, isLoadingUpdateBatch, isLoadingDeleteBatch } =
    useSelector((state) => state.dataCaptureReducer);

  return (
    <>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat" style={{ minHeight: "90vh" }}>
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-tarefas2 mr-2" />
              {t("DataCaptureWidget.title")}
            </h6>
          </Card.Header>
          <Card.Body>
            <BatchTable />
          </Card.Body>
        </Card>
      </div>

      <Spinner
        spinning={
          isLoadingBatchs || isLoadingDeleteBatch || isLoadingUpdateBatch
        }
        wrapper
      />
      <Messages />
    </>
  );
};
export default withNamespaces()(RevisaoDespesas);
