import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getRHBolsaHorasConfiguration,
  persistRHBolsaHorasConfiguration,
} from "~/store/ducks/rhadminConfiguration/actionTypes";

const BolsaHorasConfiguration = (props) => {
  const { t, organizationId } = props;
  const dispatch = useDispatch();
  const { rhbolsaHorasConfiguration, successPersistRHBolsaHorasConfiguration } =
    useSelector((state) => state.rhadminConfigurationReducer);

  const [flowId, setFlowId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [bolsasFlowId, setbolsasFlowId] = useState([])

  useEffect(() => {
    if (organizationId) dispatch(getRHBolsaHorasConfiguration(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if(rhbolsaHorasConfiguration.length > 0)
    setbolsasFlowId(rhbolsaHorasConfiguration)
  }, [rhbolsaHorasConfiguration]);

  useEffect(() => {
    if (successPersistRHBolsaHorasConfiguration) setIsEditing(false);
  }, [successPersistRHBolsaHorasConfiguration]);

  const handleSave = () => {
    const payload = bolsasFlowId;
    dispatch(persistRHBolsaHorasConfiguration(payload));
  };

  return (
    <Form.Row style={{ margin: "20px" }}>
      <Col sm="12" md="12" lg="12">
        <Form.Group controlId="validationCustom">
          <Form.Label style={{ marginTop: "20px" }}>
            <h5>
              {t("admin.organization.settings.rhbolsaHorasConfiguration")}
            </h5>
          </Form.Label>
          <br />
          <br />
          {bolsasFlowId.map((entry, index) => (
            <Form.Group
              key={entry.id}
              controlId={`flowId-${entry.id}`}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Form.Label>{t(`admin.organization.settings.flowId`+ entry.description)}</Form.Label>
              <Form.Control
                type="text"
                style={{ marginLeft: "15px", width: "200px" }}
                value={entry.flowId}
                onChange={(e) => {
                  const updatedFlowId = [...bolsasFlowId];
                  updatedFlowId[index].flowId = e.target.value;
                  setbolsasFlowId(updatedFlowId);
                  setIsEditing(true)
                }}
                autoComplete="off"
              />
            </Form.Group>
          ))}

          {isEditing && (
            <Button className="card-button" onClick={() => handleSave()}>
              {t("admin.organization.settings.saveConfig")}
            </Button>
          )}
        </Form.Group>
      </Col>
    </Form.Row>
  );
};

export default withNamespaces()(BolsaHorasConfiguration);
