import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CreateForm from "~/components/CreateForm";

const NativeProcessesWrapper = (props) => {
  const { isModal, className, handleHeaderName, handleClose, formFromProcess } =
    props;
  const { formdata } = useSelector((state) => state.processesReducer);
  const myRef = useRef(null);
  const { focusElement } = useSelector((state) => state.iflowReducer);
  const [formtosendJsonData, setformtosendJsonData] = useState({});

  useEffect(() => {
    if (formFromProcess) {
      if (!focusElement) {
        myRef.current.scrollIntoView();
      }
      setformtosendJsonData(formFromProcess);
    } else {
      if (formdata) {
        if (!focusElement) {
          myRef.current.scrollIntoView();
        }
        setformtosendJsonData(formdata);
      }
    }
  }, [formdata, formFromProcess, focusElement]);
console.log(formdata,formFromProcess, handleHeaderName)
  return (
    <div
      ref={myRef}
      style={{
        // display: "flex",
        // flexDirection: "column",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <CreateForm
        jsonData={formtosendJsonData}
        isModal={isModal}
        className={className}
        handleHeaderName={handleHeaderName}
        handleClose={handleClose}
      />
    </div>
  );
};
export default NativeProcessesWrapper;
