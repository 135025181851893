import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Badge } from "react-bootstrap";
import "../../assets/css/icons.css";
import CustomDropdown from "./CustomDropdown";
import { withNamespaces } from "react-i18next";
import { updateUserWidget } from '~/pages/User/actions';

const NextTask = ({ apagarWidget,widget,handleMouseDown,isResizing,textMove, textClick, ...props }) => {
  const { t } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (widget.userConfiguration == null) {
       const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6"
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-tarefas2 mr-2" /> {t("NextTask.title")}
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown apagarWidget={apagarWidget} handleMouseDown={handleMouseDown} />
        </Card.Header>
        { !isResizing && (<Card.Body>
          <div className="d-flex">
            <div className="flex-grow-1">
              <h5 className="font-weight-bold">IVA</h5>
            </div>
            <div>
              <a>
                <i className="icon-attach"></i>
              </a>
              <button type="button" className="btn btn-generic btn-sm">
                <i className="icon-check mr-2"></i> Completar Tarefa
              </button>
            </div>
          </div>
          <hr />
          <div>
            <p style={{ margin: "0px" }}>
              <b>Prazo:</b> 15 outubro (faltam ? dias)
              <i className="icon-circle small c-urgent"></i>
            </p>
            <p style={{ margin: "0px" }}>
              <b>Coima:</b> 65.000€ em caso de dolo, 45.000€ em caso de
              negligência
            </p>
            <p style={{ margin: "0px" }}>
              <b>Caráter:</b> Trimestral
            </p>
          </div>
          <div className="float-right">
            <div className="row">
              <h4>
                {" "}
                Estado: <Badge variant="warning">Urgente</Badge>
              </h4>
            </div>
          </div>
        </Card.Body>)}
      </Card>
    </div>
  );
};
export default withNamespaces()(NextTask);
