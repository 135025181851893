export const FIND_ONLINE_USERS = 'FIND_ONLINE_USERS';
export const SET_ONLINE_USERS = 'SET_ONLINE_USERS';

export const FIND_CHAT = 'FIND_CHAT';
export const INIT_CHAT = 'INIT_CHAT';
export const SET_CHAT = 'SET_CHAT';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SAVED_MESSAGE = 'SAVED_MESSAGE';
export const CLEAR_SAVED_MESSAGE = 'CLEAR_SAVED_MESSAGE';

export const findOnlineUsers = () => {
  return { type: FIND_ONLINE_USERS }
}

export const setOnlineUsers = (onlineUsers) => {
  return { type: SET_ONLINE_USERS, onlineUsers }
}

export const findChat = (payload) => {
  return { type: FIND_CHAT, payload }
}

export const initChat = (chat) => {
  return { type: INIT_CHAT, chat }
}

export const setChat = (chat) => {
  return { type: SET_CHAT, chat }
}

export const sendMessage = (chat) => {
  return { type: SEND_MESSAGE, chat }
}

export const savedMessage = (saved) => {
  return { type: SAVED_MESSAGE, saved }
}

export const clearSavedMessage = () => {
  return { type: CLEAR_SAVED_MESSAGE }
}