import { takeLatest } from 'redux-saga/effects';

import {
  GET_EASYPAY_FORMDATA,
  POST_EASYPAY_FORMDATA
} from "../ducks/easypay/actionTypes";

import {
  getEasypayFormdata,
  postEasypayFormData
} from "../ducks/easypay/sagas";

export const easypaySagas = [
  takeLatest(GET_EASYPAY_FORMDATA, getEasypayFormdata),
  takeLatest(POST_EASYPAY_FORMDATA, postEasypayFormData)
]
