import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import { pagination } from "~/components/pagination/currentSizePerPage/8";
import {
  clearBatchs,
  deleteBatch,
  getIflowConfiguration,
  updateBatch,
} from "~/pages/DataCapture/actions";
import "~/pages/DataCapture/scss/datacapture.scss";
import {
  getColorByStatusBatch,
  translateStatusBatch,
} from "~/pages/DataCapture/utils";
import {
  BatchStatus,
  DocumentStatus,
} from "~/pages/DataCapture/utils/DataCaptureEnum";
import { toLocaleString } from "~/utils";
import oauth from "~/utils/oauth";
import BatchFilters from "./BatchFilters";
import DocumentTable from "./DocumentTable";

const BatchTable = ({ t }) => {
  const dispatch = useDispatch();

  const [selectedBatch, setSelectedBatch] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [iFlowUrl, setIflowUrl] = useState(undefined);

  const [modalClassName, setModalClassName] = useState("modal-datacapture-80w");
  const [iframeClassName, setIframeClassName] = useState(
    "iframe-datacapture-80w"
  );
  const [iconClassName, setIconClassName] = useState("icon-maximize mr-4");

  const handleCloseSubmitModel = () => {
    setShowSubmitModal(false);
    setSelectedBatch(undefined);
  };

  const handleClick = () => {
    modalClassName === "modal-datacapture-80w"
      ? setModalClassName("modal-datacapture-100w")
      : setModalClassName("modal-datacapture-80w");
    iframeClassName === "iframe-datacapture-80w"
      ? setIframeClassName("iframe-datacapture-100w")
      : setIframeClassName("iframe-datacapture-80w");
    iconClassName === "icon-maximize mr-4"
      ? setIconClassName("icon-restore mr-4")
      : setIconClassName("icon-maximize mr-4");
  };

  const handleClose = () => {
    setModalClassName("modal-datacapture-80w");
    setIframeClassName("iframe-datacapture-80w");
    setIconClassName("icon-maximize mr-4");
    handleCloseSubmitModel();
  };

  const { batchs, iflowConfiguration } = useSelector(
    (state) => state.dataCaptureReducer
  );
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  useEffect(() => {
    return () => dispatch(clearBatchs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getIflowConfiguration("datacapture"));
  }, [dispatch]);

  const handleSubmitToiFlow = (batch) => {
    let canSubmit = false;
    let erro;
    const finalStatuses = [
      DocumentStatus.REVISED,
      DocumentStatus.REVIEWED,
      DocumentStatus.REJECTED,
    ];
    if (batch.documents.length !== 0) {
      if (iflowConfiguration) {
        if (
          batch.status === BatchStatus.WAITING_SUBMISSION ||
          (batch.status === BatchStatus.SUBMITTED &&
            iflowConfiguration &&
            iflowConfiguration.allowresubmit === true)
        ) {
          if (
            iflowConfiguration &&
            iflowConfiguration.allowunrevisedsubmit === true
          )
            canSubmit = true;
          else if (
            iflowConfiguration &&
            iflowConfiguration.allowunrevisedsubmit === false
          ) {
            canSubmit = true;
            batch.documents.forEach((document) => {
              if (!finalStatuses.some((item) => item === document.statusV1)) {
                canSubmit = false;
                erro =
                  "Antes de submeter o lote por favor garanta que todos os documentos já estão tratados.";
              }
            });
          }
        } else {
          erro = "O lote já foi submetido.";
        }
      } else {
        //dont have iflow configuration
        if (batch.status === BatchStatus.WAITING_SUBMISSION) {
          canSubmit = true;
          batch.documents.forEach((document) => {
            if (!finalStatuses.some((item) => item === document.statusV1)) {
              canSubmit = false;
              erro =
                "Antes de submeter o lote por favor garanta que todos os documentos já estão tratados.";
            }
          });
        } else if (batch.status === BatchStatus.SUBMITTED) {
          erro = "O lote já foi submetido.";
        }
      }
    } else {
      erro = "Não é possível submeter. O lote não tem documentos.";
    }
    if (canSubmit) {
      const token = oauth.getAccessToken();
      const authtoken = "&Authorization=" + token;
      setSelectedBatch(batch);

      const menuItem = menu.find((item) => item.id === batch.menuId);
      const extraConfiguration_ = JSON.parse(menuItem.extraConfiguration);
      const flowId =
        menuItem && extraConfiguration_
          ? extraConfiguration_.flowId
          : undefined;

      if (flowId) {
        //const url_ = `/iFlow/inicio_flow.jsp?flowid=${flowId}&attr_lote=${batch.id}${authtoken}`;
        const url_ = `/iFlow/inicio_flow.jsp?flowid=${flowId}&attr_lote=${batch.id}&attr_Authorization=${token}${authtoken}`;
        setIflowUrl(url_);
        setShowSubmitModal(true);
        const payload = {
          status: BatchStatus.SUBMITTED,
          id: batch.id,
        };
        dispatch(updateBatch(payload));
      } else {
        handleOpenSubmitErrorModel(
          "De momento não é possível submeter documentos por motivo de problema técnico. Por favor contactar o suporte."
        );
      }
    } else {
      handleOpenSubmitErrorModel(erro);
    }
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }
  const allowEditar = (row) => {
    if (iflowConfiguration) {
      if (
        row.status === BatchStatus.WAITING_SUBMISSION ||
        (row.status === BatchStatus.SUBMITTED &&
          iflowConfiguration &&
          iflowConfiguration.allowresubmit === true)
      )
        return true;
    } else return false;
  };

  const buildActionsColumn = (row) => {
    return (
      <>
        {/*TODO fix this when DocDigitizer is used
        {allowEditar(row) &&
          <>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t('datacapture.general.editBatch'))}
            >
              <i
                className="icon-edit table-action-icon"
                onClick={() => history.push({ pathname: `${url}/invoice/update/${row.id}` })}
              />
            </OverlayTrigger>
        */}
        {allowEditar(row) && (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(t("datacapture.general.deleteBatch"))}
          >
            <i
              className="icon-trash table-action-icon"
              onClick={() => handleOpenDeleteModal(row)}
            />
          </OverlayTrigger>
        )}
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("datacapture.general.submitBatch"))}
        >
          <i
            className="icon-submit table-action-icon"
            onClick={() => handleSubmitToiFlow(row)}
          />
        </OverlayTrigger>
      </>
    );
  };

  const buildFlowColumn = (_, row, {}) => {
    const menuItem = menu.find((item) => item.id === row.menuId);
    if (menuItem && menuItem.name) {
      return t(menuItem.name).toUpperCase();
    } else return "INDETERMINADO";
  };

  const buildStatusColumn = (_, row, {}) => {
    const variant = getColorByStatusBatch(row.status);
    const translatedStatus = translateStatusBatch(row.status);
    return (
      <Badge pill variant={variant}>
        {t(translatedStatus)}
      </Badge>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: t("datacapture.general.batchId"),
      sort: true,
    },
    {
      dataField: "createdDate",
      text: t("datacapture.general.created"),
      formatter: (_, row, {}) => toLocaleString(row.createdDate),
      sort: true,
    },
    {
      dataField: "menuId",
      text: t("datacapture.general.flow"),
      formatter: buildFlowColumn,
      sort: true,
    },
    {
      dataField: "status",
      text: t("datacapture.general.status"),
      formatter: buildStatusColumn,
      sort: true,
    },
    {
      dataField: "actions",
      text: t("datacapture.general.actions"),
      formatter: (_, row, {}) => buildActionsColumn(row),
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <DocumentTable documents={row.documents} menuId={row.menuId} />
    ),
    onlyOneExpanding: true,
    parentClassName: "parent-expand-datacapture",
    className: "expanding-datacapture-row",
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => <></>,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <i className="icon-up" />;
      }
      return <i className="icon-down" />;
    },
  };

  const handleCloseDeleteModel = () => {
    setSelectedBatch(undefined);
    setShowDeleteModal(false);
  };

  const handleOpenDeleteModal = (batch) => {
    setSelectedBatch(batch);
    setShowDeleteModal(true);
  };

  const handleDeleteBatch = () => {
    if (selectedBatch) {
      dispatch(deleteBatch(selectedBatch.id));
    }
    handleCloseDeleteModel();
  };

  const [showSubmitErrorModal, setShowSubmitErrorModal] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const handleCloseSubmitErrorModel = () => {
    setShowSubmitErrorModal(false);
    setSubmitErrorMessage("");
  };
  const handleOpenSubmitErrorModel = (erro) => {
    setSubmitErrorMessage(erro);
    setShowSubmitErrorModal(true);
  };

  const batchs_ = batchs.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  );
  return (
    <>
      <BatchFilters />

      <Form.Row style={{ marginTop: 30 }}>
        <Col lg="12">
          <h5>{t("datacapture.general.batchs")}</h5>
          <hr />
        </Col>
      </Form.Row>

      <div className="custom-table">
        <ToolkitProvider
          keyField="id"
          data={batchs_}
          columns={columns}
          search={{
            defaultSearch: "",
          }}
        >
          {(props) => (
            <BootstrapTable
              {...props.baseProps}
              id="batch-table"
              bootstrap4
              pagination={paginationFactory(pagination)}
              noDataIndication={t("datacapture.general.dataNotFound")}
              expandRow={expandRow}
              striped
            />
          )}
        </ToolkitProvider>
      </div>

      <Modal show={showDeleteModal} onHide={() => handleCloseDeleteModel()}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        {selectedBatch !== undefined ? (
          <Modal.Body>
            {`${t("datacapture.general.deleteBatch")}: ${selectedBatch.id} ?`}
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseDeleteModel()}>
            {t("general.back")}
          </Button>
          <Button variant="danger" onClick={() => handleDeleteBatch()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSubmitErrorModal}
        onHide={() => handleCloseSubmitErrorModel()}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>{submitErrorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseSubmitErrorModel()}
          >
            {t("general.back")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSubmitModal}
        onHide={() => handleCloseSubmitModel()}
        dialogClassName={modalClassName}
        backdrop="static"
      >
        <Modal.Header className="justify-content-between">
          <Modal.Title>{t("datacapture.general.submitBatch")}</Modal.Title>
          <div>
            <i className={iconClassName} onClick={() => handleClick()} />
            <i className="icon-remove mr-4" onClick={() => handleClose()} />
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <iframe
            id="datacapture-task"
            title="datacapture-task"
            src={iFlowUrl ? iFlowUrl : ""}
            className={iframeClassName}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default withNamespaces()(BatchTable);
