import React, { useState, useEffect } from "react";

const CreateTableTextInput = (props) => {
  const { id, name, value, linha, onBlur, fontSize } = props;

  const [inputValue, setValue] = useState("");
  const [isValueSet, setIsValueSet] = useState(false);

  useEffect(() => {
    if (value) {
      setValue(value);
      setIsValueSet(true);
    }
  }, [value]);

  useEffect(() => {
    if (isValueSet) {
      onBlur(linha, 64, 72);
    }
  }, [isValueSet]);

  return (
    <td id={id}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <input
          className="form-control"
          name={name}
          type="text"
          size={5}
          style={{ width: "50%", maxHeight: "42px", fontSize: `${fontSize}px` }}
          maxLength={10}
          value={inputValue}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            onBlur(linha, 64, 72);
          }}
        />
      </div>
    </td>
  );
};
export default CreateTableTextInput;
