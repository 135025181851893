import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { withNamespaces } from "react-i18next";
import Link from "~/pages/Easypay/components/Link";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import "./dataRange.scss";
import "../../pages/Liftworld/assets/scss/_dateRangePicker.scss";

/**
 * @param rangeName[] \{ name: string, onClick: () => { start: Date, end: Date } }
 */
const DateRangeSmall = ({
  id,
  dates,
  setDates,
  setShowCalendar,
  clear,
  submit,
  rangeName,
  isRange,
  t,
}) => {
  const [state, setState] = useState({
    items: {
      startDate: dates[0],
      endDate: dates[1],
      key: "selection",
    },
    done: false,
  });

  //stuff for getting language
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const language = userSettings.language;

  useEffect(() => {
    document.addEventListener("click", (e) => {
      handleCloseCalendar(e);
    });

    return () => {
      document.removeEventListener("click", (e) => {
        handleCloseCalendar(e);
      });
    };
  }, []);

  const handleCloseCalendar = (e) => {
    const calendar = document.getElementById(`date-${id}`);
    const input = document.getElementById(`input-date-${id}`);
    if (calendar) {
      if (!calendar.contains(e.target) && !input.contains(e.target)) {
        setShowCalendar(false);
      }
    }
  };

  useEffect(
    () => setDates([state.items.startDate, state.items.endDate]),
    [state.items]
  );

  useEffect(() => {
    if (!isRange && state.done) {
      submit();
      setShowCalendar(false);
    }
  }, [dates]);

  function printDates() {
    return (
      (state.items.startDate
        ? ("0" + state.items.startDate.getDate()).slice(-2) +
          "-" +
          (state.items.startDate.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          state.items.startDate.getFullYear()
        : "dd-mm-aaaa") +
      (isRange
        ? " - " +
          (state.items.endDate
            ? ("0" + state.items.endDate.getDate()).slice(-2) +
              "-" +
              (state.items.endDate.getMonth() + 1).toString().padStart(2, "0") +
              "-" +
              state.items.endDate.getFullYear()
            : "dd-mm-aaaa")
        : "")
    );
  }

  const handleRange = (e) => {
    const date = rangeName[e].onClick();
    setState({
      items: {
        startDate: dateISOFix(date.start),
        endDate: dateISOFix(date.end),
        key: "selection",
      },
    });
  };

  const dateISOFix = (date) => {
    //console.log(date)
    date.setHours(-date.getTimezoneOffset() / 60, 0, 0, 0);

    console.log(date);
    console.log(date.toISOString());
    return date;
  };

  return (
    <div className="element-input-date-range" id={"date-" + id}>
      <div className="element-input-date-top">
        {rangeName && rangeName.length != 0 ? (
          <div className="element-input-date-predefined-ranges">
            {rangeName.map((r, i) => (
              <table key={i}>
                <tbody>
                  <tr className="element-input-date-predefined-options">
                    <td className="element-input-date-predefined-option">
                      <button
                        className="element-input-date-predefined-button"
                        value={r.name}
                        onClick={() => handleRange(i)}
                      >
                        {r.name}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>
        ) : (
          <></>
        )}
        <div className="element-input-date-main-calendar">
          <DateRange
            locale={locales[language]}
            editableDateInputs={true}
            onChange={(item) => {
              item.selection &&
                setState(
                  isRange
                    ? {
                        items: {
                          startDate: dateISOFix(item.selection.startDate),
                          endDate: dateISOFix(item.selection.endDate),
                          key: "selection",
                        },
                      }
                    : {
                        items: {
                          startDate: dateISOFix(item.selection.startDate),
                          endDate: dateISOFix(item.selection.startDate),
                          key: "selection",
                        },
                        done: true,
                      }
                );
            }}
            moveRangeOnFirstSelection={false}
            dragSelectionEnabled={isRange}
            ranges={[state.items]}
          />
        </div>
      </div>
      <div className="element-input-date-bottom">
        <div className="element-input-selected-dates">
          <span className="element-input-span-selected-dates">
            {printDates()}
          </span>
        </div>
        <div className="element-input-date-buttons">
          <div className="element-input-date-link">
            <Link text={"Cancelar"} onClick={() => clear()} />
          </div>
          <div className="liftworld-date-select-button">
            <SimpleButton
              variant={"liftworld-button-primary"}
              text={"Selecionar"}
              onClick={() => submit()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(DateRangeSmall);
