import React from "react";
import uuid from "uuid/v1";

const CaptionElement = (props) => {
  const { captionHeaders,translations, language } = props;

  const transformHeaderName = (name) => {
    let text = undefined;
    if (name.includes("::t::")){
      let lingua = language.trim();
      let splittext = name.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if(translations){
        text = translations?.[lingua][texttoshow] + texttoadd
      }
    } else if (name && name.includes("::")) {
      text = name.split("::")[0];
    } else {
      text = name || ""
    }
    return text;
  };
  const renderRowElements = (header) => {
    const arr = Array.isArray(header) ? header : [header];
    return arr.map((e) => {
      return (
        <div
          className="caption-element"
          key={uuid()}
          style={{ flexGrow: e.colspan ? e.colspan : 1 }}
        >
          {transformHeaderName(e.value)}
        </div>
      );
    });
  };

  const renderCaption = () => {
    return captionHeaders.map((h, i) => {
      return (
        <div style={{ display: "flex", flexWrap: "nowrap" }} key={i}>
          {renderRowElements(h)}
        </div>
      );
    });
  };
  return <div style={{ marginTop: "1rem" }}>{renderCaption()}</div>;
};

export default CaptionElement;
