import React from "react";
import { Button } from "react-bootstrap";
import addIcon from "~/assets/img/icons/icon-add.png";

/*TODO remove it, used by Budgetmgr only*/
const AddButton = ({ disabled, text, onClick, type }) => {
  return (
    <Button
      disabled={disabled}
      variant="outline-primary"
      onClick={onClick}
      className="custom-add-button"
      type={type ? type : "button"}
    >
      <img src={addIcon} alt="add" />
      {text}
    </Button>
  );
};

export default AddButton;
