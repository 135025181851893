import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { openIframesScreens } from "~/containers/SidebarContainer/util";

export default function NavTabs({
  titleMenu,
  menus,
  activeSelected,
  containMenu,
  setIsLoading,
  t
}) {
  const history = useHistory();
  const [tabKey, initTabKey] = useState(titleMenu);

  const handleClickTab = (e) => {
    setIsLoading(true);
    var iframe = document.getElementById("portalrh");
    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    var elements = iframeDoc.getElementsByTagName("li");
    for (var i = 0; i < elements.length; i++) {
      var value = elements[i].firstElementChild.attributes[1].value;
      if (value.includes(e)) {
        elements[i].firstElementChild.click();
      } else {
        const href = elements[i].firstElementChild.getAttribute("href");
        const link = iframeDoc.querySelector('a[href="' + href + '"]');
        if (href.includes(e)) {
          history.goBack();
          setTimeout(() => {
            link.click();
            openIframesScreens();
          }, 400);
          setIsLoading(false);
          return;
        }
      }
    }
    // THIS LOADING DONT APPEAR BUT MAKES THE MOVEMENT MORE SMOOTH
    setTimeout(() => {
      openIframesScreens();
      setIsLoading(false);
    }, 400);
    history.goBack();
  };

  return (
    <>
      <div className="navbarTabs">
        <div className="title-navbarTabs">{titleMenu}</div>
        <div className="nav title-menuTabs" style={{ gap: "0.2rem" }}>
          {containMenu && Object.keys(menus).length > 0 ? (
            <>
              {Object.keys(menus).map((key) => (
                <Tabs
                  key={menus[key][0].id}
                  defaultActiveKey={activeSelected}
                  onSelect={(e) => handleClickTab(e)}
                >
                  <Tab
                    key={menus[key][0].id} 
                    eventKey={menus[key][0].accao}
                    title={t(`portalrh.NavBar.${menus[key][0].designacao}`)}
                  />
                </Tabs>
              ))}{" "}
            </>
          ) : (
            <Tabs activeKey={tabKey} onSelect={(e) => initTabKey(e)}>
              <Tab eventKey={tabKey} title={titleMenu} />
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
}
