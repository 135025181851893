export const msalConfig = {
  auth: {
    clientId: "8225420b-68b3-4152-99c5-028d3f9d1168",
    //redirectUri: "http://localhost:3000",
    //redirectUri: "https://unikint.uniksystem.pt",
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All", "user.read", "mail.read", "email"]
};

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
export const tokenRequest = {
  scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All", "user.read", "mail.read", "email"]
};

export const msalConfigNB = {
  auth: {
    clientId: "fd6c635a-4b53-4968-9f97-db5178b06116",
    //redirectUri: "http://localhost:3000",
    //redirectUri: "https://unikint.uniksystem.pt",
    //redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};