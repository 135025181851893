import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../../../i18n";
import "../assets/scss/_replegmodal.scss";
import CustomButton from "./Button";
import Link from "./Link";
import Modal from "./Modal";
import SimpleInput from "./SimpleInput";
import { useDispatch } from "react-redux";
import { updateUser } from "~/store/ducks/onboarding/actionTypes";

function AddRepLegModalContent({
  title,
  isModalShowing,
  setIsModalShowing,
  t,
  user,
  pid
}) {
  var contents;
  const dispatch = useDispatch();

  var newRepleg = {
    assina: 0,
    assinarPDA: 0,
    assinarProc: 0,
    bef: "false",
    cargo: "",
    createdDate: "",
    dataEmissaoComprovativo: null,
    dataExpiracao: null,
    dunResultadoPep: 0,
    estadoAssinatura: null,
    id: 0,
    idEntidade: 0,
    metodoAssinatura: null,
    modifiedDate: "",
    morada: null,
    moradaFiscal: null,
    nif: "",
    nome: "",
    numDocumento: null,
    numTelemovel: null,
    pdaLink: null,
    pep: null,
    pepDescricao: null,
    percentagem: "0.0",
    procNif: null,
    procNome: null,
    procPep: null,
    pvCreatedDate: null,
    pvDataExpiracao: null,
    pvDataNascimento: null,
    pvEstado: 0,
    pvMetodo: null,
    pvNacionalidade: null,
    pvNif: null,
    pvNome: null,
    pvNumDocumento: null,
    pvTipoDocumento: null,
    tipoDocumento: null,
  };

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='addRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const [inputArrayLenghts, setInputArrayLenghts] = useState([0, 0, 0]);
  const [replegName, setReplegName] = useState("");
  const [replegNif, setReplegNif] = useState("");
  const [replegCargo, setRepLegCargo] = useState("");

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  function handleBlurInputs(name, e) {
    if (name === "name") {
      setReplegName(e.target.value);
    } else if (name === "nif") {
      setReplegNif(e.target.value);
    } else {
      setRepLegCargo(e.target.value);
    }
  }

  function addRepLeg()
  {
    newRepleg.cargo = replegCargo
    newRepleg.nif = replegNif
    newRepleg.nome = replegName
    newRepleg.createdDate = new Date();
    newRepleg.modifiedDate = new Date();
    newRepleg.idEntidade = user.onboardingEntity.id

    user.legalRepresentation.push(newRepleg)

    user.documents.push({
      createdDate: null,
      deleted: 0,
      descDocumento: replegName,
      easypayId: null,
      emitted: null,
      estadoDocumento: 0,
      expired: null,
      ibanMorada: null,
      ibanNome: user.client.nome,
      ibanNumero: null,
      ibanSwift: null,
      idCliente: user.account.id,
      idCoresystem: null,
      isPDA: 0,
      mimeType: null,
      modifiedDate: null,
      nomeCompleto: "Documento de identificação",
      nomeDocumento: null,
      submited: "0",
      tipoDocumento: null
    })

    user.documents.push({
      createdDate: null,
      deleted: 0,
      descDocumento: replegName,
      easypayId: null,
      emitted: null,
      estadoDocumento: 0,
      expired: null,
      ibanMorada: null,
      ibanNome: user.client.nome,
      ibanNumero: null,
      ibanSwift: null,
      idCliente: user.account.id,
      idCoresystem: null,
      isPDA: 0,
      mimeType: null,
      modifiedDate: null,
      nomeCompleto: "Comprovativo de residência",
      nomeDocumento: null,
      submited: "0",
      tipoDocumento: null
    })

    if(user.account.estadoLead != "DOC")
    {
      user.account.estadoLead = "RL"
    }

    dispatch(updateUser({user : user, pid : pid}));

    setIsModalShowing(false)

  }

  function createBody() {
    return (
      <div className="easypay-repleg-modal-body-wrapper">
        <div style={{ width: "420px" }}>
          <SimpleInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelName`),
              replegName
            )}
            key={"accordion_repleg_nome"}
            setInputArrayLenghts={setInputArrayLenghts}
            inputArrayLenghts={inputArrayLenghts}
            index={0}
            handleOnBlur={(e) => handleBlurInputs("name", e)}
          ></SimpleInput>
        </div>
        <div style={{ width: "240px" }}>
          <SimpleInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelNIF`),
              replegNif
            )}
            key={"accordion_repleg_nif"}
            setInputArrayLenghts={setInputArrayLenghts}
            inputArrayLenghts={inputArrayLenghts}
            index={1}
            handleOnBlur={(e) => handleBlurInputs("nif", e)}
          ></SimpleInput>
        </div>
        <div style={{ width: "420px" }}>
          <SimpleInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelRole`),
              replegCargo
            )}
            key={"accordion_repleg_cargo"}
            setInputArrayLenghts={setInputArrayLenghts}
            inputArrayLenghts={inputArrayLenghts}
            index={2}
            handleOnBlur={(e) => handleBlurInputs("cargo", e)}
          ></SimpleInput>
        </div>
        <div
          className="easypay-repleg-modal-footer-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            textAlign: "right",
          }}
        >
          <div
            className="easypay-repleg-modal-link-wrapper"
            style={{ paddingTop: "8px", textAlign: "right" }}
            onClick={(event) => {
              event.preventDefault();
              setIsModalShowing(false);
            }}
          >
            <Link
              text={t(`bpm.onboarding.backoffice.user.buttonCancel`)}
              disabledColored={false}
              disabled={false}
              link=""
              key={uuid()}
            />
          </div>
          <div
            className="easypay-repleg-modal-button-wrapper"
            style={{ paddingLeft: "25px" }}
          >
            <CustomButton
              text={t(`bpm.onboarding.backoffice.user.buttonAdd`)}
              variant={"easypay-button-primary"}
              onclick={addRepLeg}
              key={uuid()}
              id={addButtonField.id}
              className="card-button"
              align={addButtonField.align}
              disabledColored={false}
              disabled={false}
              field={addButtonField}
            />
          </div>
        </div>
      </div>
    );
  }

  contents = {
    modalTitle: title,
    modalBody: createBody(),
  };

  return (
    <Modal
      className="easypay-modal"
      showModal={isModalShowing}
      setShowModal={setIsModalShowing}
      headerContent={contents.modalTitle}
      bodyContent={contents.modalBody}
      key={uuid()}
    ></Modal>
  );
}

export default withNamespaces()(AddRepLegModalContent);
