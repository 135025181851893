import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  persistBudgetmgrConfiguration,
  getBudgetmgrConfiguration,
} from "~/store/ducks/budgetmgrConfiguration/actionTypes";

const BudgetmgrConfiguration = (props) => {
  const { t, organizationId } = props;
  const dispatch = useDispatch();
  const {
    budgetmgrConfiguration: reduxConfig,
    successPersistBudgetmgrConfiguration,
  } = useSelector((state) => state.budgetmgrConfigurationReducer);

  const [flowId, setFlowId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (organizationId) dispatch(getBudgetmgrConfiguration(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (reduxConfig && reduxConfig.flowId) {
      setFlowId(reduxConfig.flowId);
    }
  }, [reduxConfig]);

  useEffect(() => {
    if (successPersistBudgetmgrConfiguration) setIsEditing(false);
  }, [successPersistBudgetmgrConfiguration]);

  const handleSave = () => {
    const payload = {
      id: reduxConfig && reduxConfig.id ? reduxConfig.id : null,
      flowId: flowId,
      organization_id: organizationId,
    };
    dispatch(persistBudgetmgrConfiguration(payload));
  };
  return (
    <Form.Row style={{ margin: "20px" }}>
      <Col sm="12" md="12" lg="12">
        <Form.Group controlId="validationCustom">
          <Form.Label style={{ marginTop: "20px" }}>
            <h5>{t("admin.organization.settings.budgetmgrConfiguration")}</h5>
          </Form.Label>
          <br />
          <br />
          <Form.Group
            controlId="flowId"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Form.Label>{t("admin.organization.settings.flowId")}</Form.Label>
            <Form.Control
              type="text"
              style={{ marginLeft: "15px", width: "200px" }}
              value={flowId}
              onChange={(e) => {
                setFlowId(e.target.value);
                setIsEditing(true);
              }}
              autoComplete="off"
            />
          </Form.Group>

          {isEditing && (
            <Button className="card-button" onClick={() => handleSave()}>
              {t("admin.organization.settings.saveConfig")}
            </Button>
          )}
        </Form.Group>
      </Col>
    </Form.Row>
  );
};

export default withNamespaces()(BudgetmgrConfiguration);
