import React, { useEffect } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import ToolsComponent from "../ToolsComponent";
import Simulator from "../Simulator/Simulator";
import { useSelector } from "react-redux";
import { PERMISSION_TOOLS } from "~/utils/constants/permissions";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import { hasRoles } from "~/components/security/utils";
import Syncronization from "~/pages/Admin/SystemSettings/components/Syncronization"
import { ROLE_ADMIN } from "~/utils/constants/roles";

const Index = ({ t }) => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
  }, []);

  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const isProductionMode = configuration ? configuration.productionMode : true;
  const { user } = useSelector((state) => state.globalReducer);

  return (
    <ProtectedComponent permissions={[{ label: PERMISSION_TOOLS }]}>
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Body>
            <Tabs
              defaultActiveKey="signdPDF"
              className="custom-tabs"
              id="adminSettingsTab"
            >
              <Tab eventKey="signdPDF" title="SignPDF">
                <ToolsComponent />
              </Tab>
              {!isProductionMode && (
                <Tab
                  eventKey="Simulator"
                  title={t(`toolsUnik.simulatorCompensationTermination`)}
                >
                  <Simulator />
                </Tab>
              )}
              {hasRoles(user, [ROLE_ADMIN], true) && (
                <Tab eventKey="Encriptação" title="Encriptação">
                  <br />
                  <Syncronization tabKey={"Encriptação"} encr={true} />
                </Tab>
              )}
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </ProtectedComponent>
  );
};
export default withNamespaces()(Index);
