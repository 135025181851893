import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Button, Accordion } from "react-bootstrap";

import { withNamespaces } from "react-i18next";
import { findIflowTasksByFilters } from "~/store/ducks/tasks/actionTypes";
import { BPM_MIDDLEWARE } from "~/utils/constants";

import FilterToggle from "~/components/Tasks/FilterToggle";

import DatePicker from "~/components/DatePicker";
import { subDays } from "date-fns";
import "~/assets/css/icons.css";
import { ADocumentStatus } from "../utils/ADatacaptureEnum";

import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { Search } from "react-bootstrap-table2-toolkit";
import {
  getColorByStatusDocument,
  translateStatusDocument,
  translateApprovedDocument,
  getColorByApprovedDocument,
} from "../utils";

const AutomatedDocumentTableCustomFilterZone = (props) => {
  const {
    startAt,
    startPaymentAt,
    endAt,
    endPaymentAt,
    handleEndAt,
    handleEndPaymentAt,
    handleStartAt,
    endAtCreated,
    startAtCreated,
    handleEndAtCreated,
    handleStartAtCreated,
    handleStartPaymentAt,
    handleFornecedor,
    fornecedor,
    dateRadioValue,
    setDateRadioValue,
    approvedRadioValue,
    setApprovedRadioValue,
    setStatusRadioValue,
    forceFiltersReset,
    checkSwitch,
    setCheckSwitch,
    t,
    handleApplySearchTable,
    handleApplyResetTable,
    filterActive
  } = props;
  const isMobile = window.innerWidth < 992 ? true : false;

  const [toggleIcon, setToggleIcon] = useState("icon-down");
  const [toggleIconMobile, setToggleIconMobile] = useState("icon-down");
  const [activeKey, setActiveKey] = useState(null);
  const [activeKeyMobile, setActiveKeyMobile] = useState(null);
  const [filterToggle, setFilterToggle] = useState(false); //default hide
  const [filterToggleMobile, setFilterToggleMobile] = useState(true); //default mobile hide
  const [paidDate, setPaidDate] = useState(false);
  const [notPaidDate, setNotPaidDate] = useState(false);

  const approvedRadios = [
    {
      name: t("datacapture.filter.notApproved"),
      value: "1",
      variant: "danger",
    },
    { name: t("datacapture.filter.aproved"), value: "2", variant: "success" },
    { name: t("datacapture.filter.payed"), value: "3", variant: "warning" },
  ];

  const statusRadios = [
    { name: ADocumentStatus.PROCESSED, value: ADocumentStatus.PROCESSED },
    { name: ADocumentStatus.PROCESSING, value: ADocumentStatus.PROCESSING },
    { name: ADocumentStatus.DUPLICATED, value: ADocumentStatus.DUPLICATED },
  ];

  const setStatusRadioValueAux = (e) => {
    setStatusRadioValue(e);
  };

  const setApprovedRadioValueAux = (e) => {
    setApprovedRadioValue(e);
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});
    useEffect(() => {
      setActiveKeyMobile(filterToggleMobile === false ? "0" : null);
      setToggleIconMobile(
        filterToggleMobile === false ? "icon-up" : "icon-down"
      );
      setActiveKey(filterToggle === false ? null : "0");
      setToggleIcon(filterToggle === false ? "icon-up" : "icon-down");
    }, [filterToggle]);

    return (
      <Col lg="12" onClick={decoratedOnClick} style={{ padding: "0px" }}>
        <h5>{t("general.filters")}</h5>
        <hr />
        {children}
      </Col>
    );
  };
  const { SearchBar } = Search;
  return (
    <Accordion activeKey={activeKey}>
      <FilterToggle
        eventKey="0"
        setToggleIcon={setToggleIcon}
        setActiveKey={setActiveKey}
        toggleIcon={toggleIcon}
        name="general.filters"
        filterActive={filterActive}
      />
      <Accordion.Collapse eventKey="0">
        <Form>
        <Form.Row>
            <Col lg="4" sm="12">
              <Form.Label className="dataCapture_labels">
                {t("datacapture.filter.createdDate")}
              </Form.Label>
              <DatePicker
                value={startAtCreated}
                onChange={(e) => handleStartAtCreated(e)}
                format={"dd/MM/yyyy"}
                style={{ flexGrow: "1", flexBasis: "0" }}
              />
            </Col>
            <Col lg="4" sm="12">
              <div className="dataCapture_inputs">
                <DatePicker
                  value={endAtCreated}
                  onChange={(e) => handleEndAtCreated(e)}
                  format={"dd/MM/yyyy"}
                  style={{ flexGrow: "1", flexBasis: "0" }}
                />
              </div>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg="4" sm="12">
              <Form.Label className="dataCapture_labels">
                {t("datacapture.filter.emissionDate")}
              </Form.Label>
              <DatePicker
                value={startAt}
                onChange={(e) => handleStartAt(e)}
                format={"dd/MM/yyyy"}
                style={{ flexGrow: "1", flexBasis: "0" }}
              />
            </Col>
            <Col lg="4" sm="12">
              <div className="dataCapture_inputs">
                <DatePicker
                  value={endAt}
                  onChange={(e) => handleEndAt(e)}
                  format={"dd/MM/yyyy"}
                  style={{ flexGrow: "1", flexBasis: "0" }}
                />
              </div>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg="4">
              <Form.Label>{t("datacapture.filter.paymentDate")}</Form.Label>
              <DatePicker
                value={startPaymentAt}
                onChange={(e) => handleStartPaymentAt(e)}
                format={"dd/MM/yyyy"}
                style={{ flexGrow: "1", flexBasis: "0" }}
              />
            </Col>
            <Col lg="4">
              <div className="dataCapture_inputs">
                <DatePicker
                  value={endPaymentAt}
                  onChange={(e) => handleEndPaymentAt(e)}
                  format={"dd/MM/yyyy"}
                  style={{ flexGrow: "1", flexBasis: "0" }}
                />
              </div>
            </Col>
          </Form.Row>
          <br></br>
          {/* <Form.Row> */}
          {/* <Form.Label style={{ paddingLeft: "5px" }}> */}{" "}
          <div className="dataCapture_title">
            <h6>{t("datacapture.filter.approvalStatus")}</h6>
          </div>
          {/* </Form.Label> */}
          {/* </Form.Row> */}
          <div className="dataCapture_groupButtons">
            <Form.Row>
              <ButtonGroup>
                {approvedRadios.map((radio, idx) => (
                  <ToggleButton
                    style={
                      idx === 0
                        ? { color: "#fff" }
                        : { marginLeft: "15px", color: "#fff" }
                    }
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={radio.variant}
                    name="radio"
                    value={radio.value}
                    checked={approvedRadioValue === radio.value}
                    onChange={(e) =>
                      setApprovedRadioValueAux(e.currentTarget.value)
                    }
                  >
                    <span style={{ padding: "10px" }}>{radio.name}</span>
                  </ToggleButton>
                ))}
              </ButtonGroup>
              <div
                className="dataCapture_groupButtons"
                style={{ marginLeft: "15px" }}
              >
                <Button
                  className="card-button"
                  onClick={handleApplySearchTable}
                >
                  {t("general.search")}
                </Button>
                <Button
                  className="card-button"
                  onClick={handleApplyResetTable}
                  style={{ marginLeft: "15px" }}
                >
                  Reset
                </Button>
              </div>
            </Form.Row>
          </div>
        </Form>
      </Accordion.Collapse>
    </Accordion>
  );
};
export default withNamespaces()(AutomatedDocumentTableCustomFilterZone);
