import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Spinner } from "react-bootstrap";
import SearchableDropDown from "~/components/SearchableDropDown";
import { withNamespaces } from "react-i18next";
import { getCollaboratorData } from "~/store/ducks/simulator/actionTypes";

const ModalCollaborators = (props) => {
  const [list, setList] = useState([]);
  const [collaboratorId, setCollaboratorId] = useState("");
  const [collaboratorContent, setCollaboratorContent] = useState();
  const [collaboratorProperties, setCollaboratorProperties] = useState();
  const [isEmpty, setIsEmpty] = useState(false);

  const dispatch = useDispatch();

  const { collaborators } = useSelector((state) => state.portalrhReducer);
  const { collaboratorDatas } = useSelector((state) => state.simulatorReducer);

  useEffect(() => {
    if (collaborators && collaborators.length > 0) {
      const optionList = collaborators.map((option) => ({
        label: option.nome,
        value: option.id,
        selected: collaboratorId && collaboratorId == option.id ? true : false,
      }));

      if (optionList) {
        setList(optionList);
      }
    }

    if (collaborators && collaboratorId) {
      for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].id == collaboratorId) {
          setCollaboratorContent(collaborators[i]);
          dispatch(
            getCollaboratorData(
              collaborators[i]?.funcionarioNum,
              collaborators[i]?.codEntidade
            )
          );
        }
      }
    }
  }, [collaboratorId, collaborators, dispatch]);
  console.log(collaboratorDatas, "collaboratorDatas");
  useEffect(() => {
    let newObjectCollaborator = {};
    if (collaboratorContent && collaboratorDatas) {
      let collaboratorDataArr = [collaboratorDatas];
      for (let i = 0; i < collaboratorDataArr.length; i++) {
        if (
          collaboratorDataArr[i]?.initialDates?.[i]?.id ==
          collaboratorContent.funcionarioNum
        ) {
          newObjectCollaborator = {
            ...collaboratorContent,
            ...collaboratorDataArr[i]?.initialDates[i],
            ...collaboratorDataArr[i]?.trainingHours[i],
            ...collaboratorDataArr[i]?.valuePerHours[i],
            ...collaboratorDataArr[i]?.diuturnites[i],
            ...collaboratorDataArr[i]?.typeOfContracts[i],
            ...collaboratorDataArr[i]?.baseSalaries[i],
          };
          setCollaboratorProperties(newObjectCollaborator);
        }
      }
    } else {
      setCollaboratorProperties();
    }
  }, [collaboratorContent, collaboratorDatas]);

  //lifting up the saved data to the parent's component
  props.onSaveCollaboratorData(collaboratorContent, collaboratorProperties);
  props.onSaveCollaboratorContent(collaboratorContent);
  const handleOnClick = () => {
    props.onStartSimulator(true);
    props.onHide();
  };

  setTimeout(() => {
    setIsEmpty(true);
  }, 5500);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.t(`simulatorMainPage.modalCollaborators.select`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchableDropDown
            list={list}
            handleSubmit={(value) => setCollaboratorId(value)}
            enableSearchEmptyValue={true}
            placeholder={props.t(
              `simulatorMainPage.modalCollaborators.placeHolder`
            )}
            required
          />
        </Modal.Body>

        <Modal.Footer>
          {collaboratorProperties === undefined &&
          collaboratorContent === undefined ? null : collaboratorProperties ===
              undefined && !isEmpty ? (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <Button className="card-button" onClick={handleOnClick}>
              {props.t(`simulatorMainPage.modalCollaborators.continue`)}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withNamespaces()(ModalCollaborators);
