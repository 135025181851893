import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import oauth from "~/utils/oauth";

import Header from "~/pages/Easypay/components/Header";
import CreateForm from "~/pages/Easypay/CreateForm";
import "~/pages/Easypay/assets/css/styles.css";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";

import { getEasypayFormdata } from "~/store/ducks/easypay/actionTypes";
import { findMenuByApplicationName } from "~/store/ducks/applicationMenu/actionTypes";
import { easyPaySSO } from "~/store/ducks/udw/actionTypes";

const OnboardingUser = ({ application }) => {
  const dispatch = useDispatch();

  const { easypayFormdata } = useSelector((state) => state.easypayReducer);
  const { ssoInfo } = useSelector((state) => state.udwReducer);

  useEffect(() => {
    if (!application) {
      dispatch(findMenuByApplicationName("onboarding"));
    }
  }, []);

  function getAttribute(type) {
    console.log(ssoInfo)
    if (type === "name" && ssoInfo?.name != undefined) {
      return ssoInfo?.name;
    } else if (type === "email" && ssoInfo?.email != undefined) {
      return ssoInfo?.email;
    } else if (type === "phone" && ssoInfo?.phone != undefined) {
      return ssoInfo?.phone;
    } else if (type === "language" && ssoInfo?.language != undefined) {
      return ssoInfo?.language;
    }
    return "";
  }

  useEffect(() => {
    if (application) {
      dispatch(easyPaySSO("12345789"));
      const pid = window.localStorage.getItem("pid");
      let payload = "";
      if (pid) {
        payload = {
          url: `/Forward/forward.jsp?flowid=${
            application.flowid
          }&pid=${pid}&subpid=1&Authorization=${oauth.getAccessToken()}`,
        };
      } else {
        //console.log("afonso")
        payload = {
          url:
            "/inicio_flow.jsp?" +
            "flowid=" +
            application.flowid +
            "&attr_nome=" +
            getAttribute("name") +
            "&attr_email=" +
            getAttribute("email") +
            "&attr_phoneNumber=" +
            getAttribute("phone") +
            "&attr_language=" +
            getAttribute("language") +
            "&Authorization=" +
            oauth.getAccessToken(),
        };
      }
      dispatch(getEasypayFormdata(payload));
    }
  }, [application]);

  useEffect(() => {
    console.log(ssoInfo)
  }, [ssoInfo]);

  return (
    <div style={{ overflowY: "scroll", width: "100%", minHeight: "100%" }}>
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
      />
      <div className="easypay-content">
        <CreateForm jsonData={easypayFormdata} flowid={application?.flowid} />
      </div>
    </div>
  );
};
export default withNamespaces()(OnboardingUser);
