import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

const ButtonsUpdate = ({
  OnTakingBolean,
  updateData,
  addressPage,
  personalDataPage,
  academicQualificatonsPage,
  taxDataPage,
  houseHoldPage,
  paymentsDataPage,
  trainingSheetPage,
  performancePage,
  medicalRecordPage,
  handleUpdateButtonClicked,
  isClicked,
  setIsClicked,
  getValidation,
  t,
}) => {
  const handleButton = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsClicked(!isClicked);
    OnTakingBolean(isClicked);
    handleUpdateButtonClicked(isClicked);
  };

  /*
  const handleSaveButton = () => {
    if (addressPage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }

    if (personalDataPage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
    if (academicQualificatonsPage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
    if (taxDataPage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
    if (houseHoldPage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
    if (paymentsDataPage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
    if (trainingSheetPage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
    if (performancePage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
    if (medicalRecordPage) {
      updateData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };*/

  return (
    <>
      {isClicked ? (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            type="submit"
            className="card-button"
            //onClick={handleSaveButton}
            style={{ marginLeft: 15 }}
          >
            {t(`portalrh.NavBar.ButtonSave`)}
          </Button>

          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={(e) => handleButton(e)}
          >
            {t(`portalrh.NavBar.ButtonCancel`)}
          </Button>
        </div>
      ) : (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={(e) => handleButton(e)}
          >
            {t(`portalrh.NavBar.ButtonChange`)}
          </Button>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(ButtonsUpdate);
