import {
  FIND_ONLINE_USERS,
  SET_ONLINE_USERS,
  FIND_CHAT,
  SET_CHAT,
  SAVED_MESSAGE,
  CLEAR_SAVED_MESSAGE
} from "../actions";

const initialState = {
  onlineUsers: [],
  isLoadingUsers: false,
  chat: {},
  isLoadingChat: false,
  savedMessage: false,
}

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_ONLINE_USERS:
      return { ...state, isLoadingUsers: true };
    case SET_ONLINE_USERS:
      return {
        ...state,
        onlineUsers: action.onlineUsers,
        isLoadingUsers: false
      };
    case FIND_CHAT:
      return { ...state, isLoadingChat: true };
    case SET_CHAT:
      return {
        ...state,
        isLoadingChat: false,
        chat: action.chat
      };
    case SAVED_MESSAGE:
      return {
        ...state,
        savedMessage: action.saved
      };
    case CLEAR_SAVED_MESSAGE:
      return {
        ...state,
        savedMessage: false
      };
    default:
      return state;
  }
}
