import { takeLatest } from "redux-saga/effects";

import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_BY_ID,
  PERSIST_ORGANIZATION,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  REMOVE_ORGANIZATION_PICTURE,
} from "../ducks/organizations/actionTypes";
import {
  getOrganizations,
  getOrganizationById,
  persistOrganization,
  deleteOrganization,
  updateOrganization,
  removeOrganizationPicture,
} from "../ducks/organizations/sagas";

export const organizationSagas = [
  takeLatest(GET_ORGANIZATIONS, getOrganizations),
  takeLatest(GET_ORGANIZATION_BY_ID, getOrganizationById),
  takeLatest(PERSIST_ORGANIZATION, persistOrganization),
  takeLatest(DELETE_ORGANIZATION, deleteOrganization),
  takeLatest(UPDATE_ORGANIZATION, updateOrganization),
  takeLatest(REMOVE_ORGANIZATION_PICTURE, removeOrganizationPicture),
];
