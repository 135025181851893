import {
  SET_PROCESSES,
  SET_PROCESSES_HISTORY,
  SET_PROCESSES_COUNT,
  SET_PROCESSES_COUNT_TOTAL,
  FIND_IFLOW_PROCESSES_BY_FILTERS,
  FIND_IFLOW_PROCESSES_HISTORY,
  SET_IFLOW_FORMDATA,
  SET_IFLOW_PID,
  GET_IFLOW_FORMDATA,
  POST_IFLOW_FORMDATA,
  POST_IFLOW_FORM,
  SET_IFLOW_DOCUMENTS,
  GET_IFLOW_DOCUMENT,
  SET_IFLOW_TRIGGER_LOADING,
  SET_SELECTED_TOSIGN,
  RESET_IFLOW_DOCUMENTS,
  SET_IFLOW_METADATA_PREVIEW,
  GET_IFLOW_METADATA_PREVIEW,
  SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE,
  GET_BUTTONSAVE_TRIGGER,
  SET_BUTTONSAVE_TRIGGER,
  CLEAR_IFLOW_FORMDATA
} from "./actionTypes";

const initialState = {
  processes: [],
  isLoadingProcesses: false,
  history: [],
  numbercount: 0,
  numbercountTotal: 0,
  formdata: null,
  isLoadingFormdata: false,

  formdata: null,
  isLoadingFormdata: false,

  iflowDocument: [],
  isLoadingIflowDocument: false,
  selectedToSign: [],

  iflowTriggerLoading: null,

  metadataPreview: null,
  isLoadingPreview: false,

  isSubstitutingIflowDocument: false,

  buttonSaveTrigger: false,
  iflowpid:{}
};

export function processesReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_IFLOW_PROCESSES_BY_FILTERS:
      return { ...state, isLoadingProcesses: true };
    case FIND_IFLOW_PROCESSES_HISTORY:
      return { ...state, isLoadingProcesses: true };
    case SET_IFLOW_TRIGGER_LOADING:
      return { ...state, iflowTriggerLoading: action.iflowTriggerLoading };
    case SET_PROCESSES:
      return { ...state, processes: action.data };
    case SET_PROCESSES_HISTORY:
      return { ...state, history: action.data, isLoadingProcesses: false };
    case SET_PROCESSES_COUNT:
      return { ...state, numbercount: action.data, isLoadingProcesses: false };
    case SET_PROCESSES_COUNT_TOTAL:
      return {
        ...state,
        numbercountTotal: action.data,
      };
    case GET_IFLOW_FORMDATA:
      return { ...state, isLoadingFormdata: true };
    case SET_IFLOW_FORMDATA:
      return { ...state, formdata: action.formdata, isLoadingFormdata: false };
      case SET_IFLOW_PID:
        return { ...state, iflowpid: action.data };
    case POST_IFLOW_FORMDATA:
      return { ...state, isLoadingFormdata: true };
    case POST_IFLOW_FORM:
      return { ...state };
    case GET_IFLOW_DOCUMENT:
      return { ...state, isLoadingIflowDocument: true };
    case SET_SELECTED_TOSIGN:
      return { ...state, selectedToSign: action.data };

    case RESET_IFLOW_DOCUMENTS:
      return { ...state, iflowDocument: [], isLoadingIflowDocument: false };

    case SET_IFLOW_DOCUMENTS:
      let newIflowDocument = Array.isArray(state.iflowDocument)
        ? state.iflowDocument
        : [];
      if (
        Array.isArray(state.iflowDocument) &&
        state.iflowDocument.some((d) => d.docid === action.docid)
      ) {
        newIflowDocument = state.iflowDocument.filter(
          (d) => d.docid != action.docid
        );
        newIflowDocument.push({
          docid: action.docid,
          file: action.document,
          filename: action.filename,
        });
      } else {
        if (action.docid) {
          newIflowDocument.push({
            docid: action.docid,
            file: action.document,
            filename: action.filename,
          });
        }
      }

      return {
        ...state,
        isLoadingIflowDocument: false,
        iflowDocument: newIflowDocument,
      };

    case GET_IFLOW_METADATA_PREVIEW:
      return {
        ...state,
        isLoadingPreview: true,
      };

    case SET_IFLOW_METADATA_PREVIEW:
      return {
        ...state,
        metadataPreview: action.data,
        isLoadingPreview: false,
      };

    case SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE:
      return {
        ...state,
        isSubstitutingIflowDocument: action.data,
      };

    case GET_BUTTONSAVE_TRIGGER:
      return {
        ...state,
        buttonSaveTrigger: true,
      };
    case SET_BUTTONSAVE_TRIGGER:
      return {
        ...state,
        buttonSaveTrigger: false,
      };
    case CLEAR_IFLOW_FORMDATA:
      return { ...state, formdata: initialState.formdata }

    default:
      return state;
  }
}
