import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner, Card, Tabs, Tab, Badge } from "react-bootstrap";

import "../portal.scss";
import "~/assets/css/scrollbar.css";

import { getRequestsByUsername } from "~/store/ducks/portalrh/actionTypes";
import { RequestState } from "../TabsEnum";

import RequestItem from "../RequestWebTimeItem";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

import { withNamespaces } from "react-i18next";
import CustomDropdown from "~/components/widgets/CustomDropdown";
import { updateUserWidget } from "~/pages/User/actions";

import {
  getAusenciasByEmail,
  getFeriasByEmail,
  getLoginsByUsername,
} from "~/store/ducks/webTimeWidget/actionTypes";

const MyRequestsWebTimeWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;

  const { allMenus } = useSelector((state) => state.portalRhMenuReducer);

  const { user } = useSelector((state) => state.globalReducer);

  const usernamePortalRh = user?.legacyUsers?.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  ).username;

  const [requestState, setRequestState] = useState(RequestState.PENDING);

  const [pendingHolidayCount, setPendingHolidayCount] = useState(0);
  const [nPendingHolidayCount, setNPendingHolidayCount] = useState(0);
  const [pendingHolidaysRequest, setPendingHolidayRequest] = useState([]);
  const [nPendingHolidayRequest, setNPendingHolidayRequest] = useState([]);

  const [pendingAbsenceCount, setPendingAbsenceCount] = useState(0);
  const [nPendingAbsenceCount, setNPendingAbsenceCount] = useState(0);
  const [pendingAbsenceRequest, setPendingAbsenceRequest] = useState([]);
  const [nPendingAbsenceRequest, setNPendingAbsenceRequest] = useState([]);

  const [pendingLabels, setPendingLabels] = useState([]);
  const [nPendingLabels, setNPendingLabels] = useState([]);

  const [dataHolidays, setDataHolidays] = useState([]);
  const [dataAbsences, setDataAbsences] = useState([]);

  let pendingRequestValues = useState([]);
  let approvedRequestValues = useState([]);

  let pendingRequests = useState();
  let approvedRequests = useState();

  const dispatch = useDispatch();

  const { ausenciasByEmail, feriasByEmail, isLoadingAusenciasByEmail } =
    useSelector((state) => state.webTimeDataReducer);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "4",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    dispatch(getRequestsByUsername(usernamePortalRh));
  }, []);

  useEffect(() => {
    dispatch(getAusenciasByEmail(usernamePortalRh));
    dispatch(getFeriasByEmail(usernamePortalRh));
  }, []);

  useEffect(() => {
    if (ausenciasByEmail) {
      setDataHolidays(ausenciasByEmail);
    }

    if (feriasByEmail) {
      setDataAbsences(feriasByEmail);
    }
  }, [ausenciasByEmail, feriasByEmail]);

  useEffect(() => {
    if (dataHolidays.length > 0) {
      const nPedending = dataHolidays.filter((e) => e.pendente == 0);
      const pending = dataHolidays.filter((e) => e.pendente == 1);
      setNPendingHolidayRequest(nPedending);
      setPendingHolidayRequest(pending);
      setPendingHolidayCount(pending.length);
      setNPendingHolidayCount(nPedending.length);
    }
  }, [dataHolidays]);

  useEffect(() => {
    if (dataAbsences.length > 0) {
      const nPedending = dataAbsences.filter((e) => e.pendente == 0);
      const pending = dataAbsences.filter((e) => e.pendente == 1);
      setNPendingAbsenceRequest(nPedending);
      setPendingAbsenceRequest(pending);
      setPendingAbsenceCount(pending.length);
      setNPendingAbsenceCount(nPedending.length);
    }
  }, [dataAbsences]);

  useEffect(() => {
    let labels = [{}];
    if (pendingHolidaysRequest.length > 0 || pendingAbsenceRequest.length > 0) {
      if (pendingHolidaysRequest.length > 0) {
        labels[0][t("portalrh.requests.descriptions.vacationsManagement")] =
          pendingHolidaysRequest.length;
      }
      if (pendingAbsenceRequest.length > 0) {
        labels[0][t("portalrh.requests.descriptions.absenceManaegement")] =
          pendingAbsenceRequest.length;
      }

      setPendingLabels(labels);
    }
  }, [pendingAbsenceRequest.length, pendingHolidaysRequest.length, t]);

  useEffect(() => {
    let labels = [{}];
    if (
      nPendingHolidayRequest.length > 0 ||
      nPendingAbsenceRequest.length > 0
    ) {
      if (nPendingHolidayRequest.length > 0) {
        labels[0][t("portalrh.requests.descriptions.vacationsManagement")] =
          nPendingHolidayRequest.length;
      }
      if (nPendingAbsenceRequest.length > 0) {
        labels[0][t("portalrh.requests.descriptions.absenceManaegement")] =
          nPendingAbsenceRequest.length;
      }

      setNPendingLabels(labels);
    }
  }, [nPendingAbsenceRequest.length, nPendingHolidayRequest.length, t]);

  const getRequestItems = (requestValues, textColor, type, state, userType) => {
    return (
      <div>
        {Object.keys(requestValues).map((description, index) => (
          <RequestItem
            key={description}
            description={description}
            num={Object.values(requestValues)[index]}
            textColor={textColor}
            allMenus={allMenus}
            type={type}
            t={t}
            state={state}
            userType={userType}
            // link={logins || ""}
          />
        ))}
      </div>
    );
  };

  if (pendingLabels.length > 0 || nPendingLabels.length > 0) {
    if (pendingLabels.length > 0) {
      pendingRequestValues = pendingLabels;
    }
    if (nPendingLabels.length > 0) {
      approvedRequestValues = nPendingLabels;
    }

    pendingRequests = getRequestItems(
      pendingRequestValues[0],
      "text-primary",
      "ped_ac_",
      RequestState.PENDING,
      "func"
    );
    approvedRequests = getRequestItems(
      approvedRequestValues[0],
      "text-secondary",
      "ped_ac_",
      RequestState.APPROVED,
      "func"
    );
  }

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat" style={{ padding: "25px 30px 10px 30px" }}>
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-bell mr-2" />
            {t("MyRequestsWebTimeWidget.title")}
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>

        {!isResizing && (
          <Card.Body>
            {isLoadingAusenciasByEmail === "true" ? (
              <Spinner spinning={isLoadingAusenciasByEmail === "true"} />
            ) : (
              <Tabs
                className="custom-tabs"
                id="requestsTab"
                defaultActiveKey="first"
                activeKey={requestState}
                onSelect={(k) => setRequestState(k)}
              >
                <Tab
                  mountOnEnter={true}
                  className="scrollable"
                  title={
                    <React.Fragment>
                      {t("portalrh.requests.penddinRequest") + " "}
                      <Badge pill className="text-white  bg-primary">
                        {pendingHolidayCount + pendingAbsenceCount > 0
                          ? pendingHolidayCount + pendingAbsenceCount
                          : ""}
                      </Badge>
                    </React.Fragment>
                  }
                  eventKey={RequestState.PENDING}
                >
                  {pendingRequests}
                </Tab>

                <Tab
                  mountOnEnter={true}
                  className="scrollable"
                  title={
                    <React.Fragment>
                      {t("portalrh.requests.finishedRequest") + " "}
                      <Badge pill className="text-white  bg-primary">
                        {nPendingAbsenceCount + nPendingHolidayCount > 0
                          ? nPendingAbsenceCount + nPendingHolidayCount
                          : ""}
                      </Badge>
                    </React.Fragment>
                  }
                  eventKey={RequestState.APPROVED}
                >
                  {approvedRequests}
                </Tab>
              </Tabs>
            )}
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default withNamespaces()(MyRequestsWebTimeWidget);
