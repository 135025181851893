import React from "react";
import iconGreenCheckmark from "../assets/img/icons/green-checkmark.png";
import iconError from "../assets/img/icons/icon-error-filled.png";
import infoIcon from "../assets/img/icons/icon-info-fill.png";
import iconWarning from "../assets/img/icons/icon-warning.png";
import "../assets/scss/_feedbackMessage.scss";



function FeedbackMessage({ text, variant }) {
  return (
    <>
      <div className={variant}>
        <img
          className="easypay-feedback-img"
          src={
            String(variant).includes("success")
              ? iconGreenCheckmark
              : String(variant).includes("info")
              ? infoIcon
              : String(variant).includes("warning")
              ? iconWarning
              : iconError
          }
        ></img>
        <div className="easypay-feedback-text">{text}</div>
      </div>
    </>
  );
}

export default FeedbackMessage;
