import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_input.scss";
import { useDispatch, useSelector } from "react-redux";
import { setAllWorkplans, setTriggerLoading } from "~/store/ducks/budgetManagement/actionTypes";

const Input = ({
  field,
  type,
  t,
  name,
  onChange,
  color,
  size,
  itemIndex,
  activityIndex,
  optionIndex,
  workplanIndex,
  setNewClientName,
  setNewClientAddress,
  setNewClientPayment,
  setNewClientPrazo,
  setNewClientNif,
  number
}) => {
  const [textInputValue, setTextInputValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {

    if (field) {
      if (field.value) setTextInputValue(field.value);
    }
  }, [field]);

  const { workplans } = useSelector((state) => state.budgetManagementReducer);

  function updateState() {
    if (name === "" || name === null) return;
    if (name === "clientNameAdd") {
      setNewClientName(textInputValue);
      return;
    } else if (name === "clientAddressAdd") {
      setNewClientAddress(textInputValue);
      return;
    }
    else if (name === "clientPaymentAdd") {
      setNewClientPayment(textInputValue);
      return;
    }
    else if (name === "clientPrazoAdd") {
      setNewClientPrazo(textInputValue);
      return;
    }
    else if (name === "clientNifAdd") {
      setNewClientNif(textInputValue);
      return;
    }

    if (
      itemIndex != null &&
      activityIndex != null &&
      optionIndex != null &&
      workplanIndex != null
    ) {
      if(name === "nunidadeDeMedia")
      {
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex][name] = parseFloat(textInputValue);
      }
      else
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex][name] = (textInputValue);
      //dispatch(setAllWorkplans(workplans));
    } else if (
      itemIndex != null &&
      activityIndex != null &&
      workplanIndex != null
    ) {
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex][
        name
      ] = textInputValue;
      //dispatch(setAllWorkplans(workplans));
    } else if (itemIndex != null && workplanIndex != null) {
      workplans[workplanIndex].items[itemIndex][name] = textInputValue;
      //dispatch(setAllWorkplans(workplans));
    }
    else
    {
      if(textInputValue === "")
      {
        workplans[workplanIndex].simulador.valorLimite = "0"
      }
      else
      {
        workplans[workplanIndex].simulador.valorLimite = textInputValue
      }
      dispatch(setAllWorkplans(workplans))
      dispatch(setTriggerLoading(true))
    }
  }
  return (
    <div className="liftworld-input-wrapper-wrapper">
      <div
        className={`liftworld-input-wrapper ${
          field.maxlength && "liftworld-input-size-" + field.maxlength
        }  `}
        tabIndex="1"
      >
        <div className="liftworld-input-label">{`${field.text}`}</div>
        <div
          className={`${"liftworld-input-field-wrapper"} ` + color + " " + size}
        >
          <input
            className={`liftworld-input-field ${number}`}
            type={type && type}
            name={name !== undefined ? name : field.variable}
            value={type === "password" ? "palavra-pass" : textInputValue}
            maxLength={field.maxlength}
            onChange={(e) => {
              setTextInputValue(e.target.value);
              onChange && onChange(e.target.value, name);
            }}
            disabled={false}
            onBlur={updateState}
          />
        </div>
        <Form.Control.Feedback type="invalid">
          {`O campo é de preenchimento obrigatório`}
        </Form.Control.Feedback>
      </div>
    </div>
  );
};
export default withNamespaces()(Input);
