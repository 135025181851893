import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import "~/assets/css/icons.css";
import LogsTable from "./LogsTable";
import NewSearchTable from "./NewSearchTable";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Toast from "react-bootstrap/Toast";
import { useSelector } from "react-redux";

const ExportAbsence = ({ t, manager }) => {
  useEffect(() => {
    /*close full screen iframes*/
    closeIframesScreens();
  });
  const { successAdded } = useSelector((state) => state.portalrhReducer);
  const [activeTable, setActiveTable] = useState("1");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (successAdded) setShowToast(true);
    else setShowToast(false);
  }, [successAdded]);

  useEffect(()=>{
    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  },[showToast])

  const toastResult = () => {
    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        className="bg-dark position-fixed"
        style={{ zIndex:'100' }}
      >
        <Toast>
          <Toast.Body className="bg-success" style={{color:'white'}}>Exported with Success</Toast.Body>
        </Toast>
      </div>
    );
  };

  return (
    <>
      <div className="main-card-v2">
        {showToast && toastResult()}
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          <Card.Header className="justify-content-between"></Card.Header>
          <Card.Body>
            <div style={{ display: "flex", paddingLeft: "15px" }}>
              <SimpleButton
                text={"Histórico Extrações"}
                variant={"liftworld-button-primary"}
                onClick={() => setActiveTable("1")}
              />
              <div style={{ paddingLeft: "15px" }}>
                <SimpleButton
                  text={"Nova Pesquisa"}
                  variant={"liftworld-button-primary"}
                  onClick={() => setActiveTable("2")}
                />
              </div>
            </div>
            {activeTable === "1" ? <LogsTable /> : <NewSearchTable />}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withNamespaces()(ExportAbsence);
