import React from "react";
import CustomTotal from "~/components/pagination/customTotal";

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <div className="btn-group" role="group">
    {options.map((option) => {
      const isSelect = currSizePerPage === `${option.page}`;
      return (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${isSelect ? "btn-primary" : "btn-link"}`}
          style={{ minHeight: "0px", padding: "0px" }}
        >
          {option.text}
        </button>
      );
    })}
  </div>
);

export const pagination = {
  paginationSize: 4,
  pageStartIndex: 0,
  // alwaysShowAllBtns: true, // Always show next and previous button
  // withFirstAndLast: false, // Hide the going to First and Last page button
  // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

  //firstPageText: 'First',
  //prePageText: 'Back',
  //nextPageText: 'Next',
  //lastPageText: 'Last',
  //nextPageTitle: 'First page',
  //prePageTitle: 'Pre page',
  //firstPageTitle: 'Next page',
  //lastPageTitle: 'Last page',
  showTotal: true,
  paginationTotalRenderer: CustomTotal,
  disablePageTitle: true,
  sizePerPageRenderer,
  sizePerPageList: [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
  ], // A numeric array is also available. the purpose of above example is custom the text
};
