import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uuid from "uuid/v1";
import { Col, Row } from "react-bootstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  getDatacaptureConfiguration,
  clearDatacaptureConfiguration,
} from "~/store/ducks/datacaptureConfiguration/actionTypes";
import "~/assets/css/icons.css";
import "~pages/Anacom/assets/scss/_anacomTable.scss";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import Spinner from "~/pages/Easypay/components/Spinner";
import {
  exportClaims,
  getSubClaims,
  setSubClaims,
} from "~/store/ducks/claimsManagement/actionTypes";

import { getMetadataDocumentFilename } from "~/pages/DataCapture/actions";
import "~/pages/DataCapture/scss/datacapture.scss";
import "~/pages/ADataCapture/components/AutomatedDatacapture/AutomatedDataCapture.css";
import Modal from "~/pages/Easypay/components/Modal";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import paginationFactory from "react-bootstrap-table2-paginator";
import PopupTable from "./PopupTable";

const NFinancialDoc = ({ t, category }) => {
  const { metadata, isLoadingMetadata, previewDocument } = useSelector(
    (state) => state.dataCaptureReducer
  );

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);

  const { datacaptureConfiguration } = useSelector(
    (state) => state.datacaptureConfigurationReducer
  );
  const { SearchBar } = Search;
  const { isLoadingGetComplaints, subClaims } = useSelector(
    (state) => state.claimsManagementReducer
  );

  const [isModalWaiting, setIsModalWaiting] = useState(false);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [isModalPreviewShowing, setIsModalPreviewShowing] = useState(false);
  const [currentAnacom, setCurrentAnacom] = useState([]);
  const [currentUnik, setCurrentUnik] = useState([]);
  const [currentAssuntos, setCurrentAssuntos] = useState([]);
  const [unikList, setUnikList] = useState([]);
  const [anacomList, setAnacomList] = useState([]);
  const [assuntosList, setAssuntosList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { complaints } = useSelector((state) => state.claimsManagementReducer);

  const handleOpenMetadataModal = (row) => {
    setButtonDisabled(true);
    dispatch(getSubClaims(category, 0, 50000, row?.claimId));
    setIsModalWaiting(true);
    dispatch(getMetadataDocumentFilename(row?.numeroProcesso));
  };

  useEffect(() => {
    if (subClaims) {
      setIsModalWaiting(false);
      if (subClaims === null || subClaims === undefined) return;
      if (subClaims?.length === 0) return;
      if (complaints?.idsValues === null || complaints?.idsValues === undefined)
        return;
      if (complaints?.idsValues?.length === 0) return;

      const currentUnikArray = subClaims?.unikClassifications;

      const currentAnacomArray = subClaims?.anacomClassifications;

      const currentAssuntosArray = subClaims?.assuntosClassifications;

      for (var i = 0; i < currentUnikArray?.length; i++) {
        if (
          currentAnacomArray.filter(
            (elem) =>
              elem.motivoIndicio === currentUnikArray[i]?.motivoIndicioId
          )[0] != null &&
          currentAnacomArray.filter(
            (elem) =>
              elem.motivoIndicio === currentUnikArray[i]?.motivoIndicioId
          )[0] != undefined
        ) {
          currentUnikArray[i].check = "t";
        } else {
          currentUnikArray[i].check = "f";
        }
      }

      const unikSize = Math.ceil(currentUnikArray.length / 5);
      const anacomSize = Math.ceil(currentAnacomArray.length / 5);
      const assuntosSize = Math.ceil(currentAssuntosArray.length / 5);

      var currentUnikPagination = [];
      var currentAnacomPagination = [];
      var currentAssuntosPagination = [];

      for (var i = 1; i <= unikSize; i++) {
        currentUnikPagination.push(i);
      }

      for (var i = 1; i <= anacomSize; i++) {
        currentAnacomPagination.push(i);
      }

      for (var i = 1; i <= assuntosSize; i++) {
        currentAssuntosPagination.push(i);
      }
      setUnikList(currentUnikPagination);
      setAnacomList(currentAnacomPagination);
      setAssuntosList(currentAssuntosPagination);
      setCurrentAnacom(currentAnacomArray);
      setCurrentUnik(currentUnikArray);
      setCurrentAssuntos(currentAssuntosArray);
      setIsModalErrorShowing(true);
    } else {
      setIsModalErrorShowing(false);
    }
  }, [subClaims]);

  useEffect(() => {
    if (user && user.organization && user.organization.id)
      dispatch(getDatacaptureConfiguration(user.organization.id));
    return () => dispatch(clearDatacaptureConfiguration());
  }, [user && user.organization && user.organization.id]);

  useEffect(() => {
    if (previewDocument) {
      setButtonDisabled(false);
    }
  }, [previewDocument]);

  useEffect(() => {
    if (isModalErrorShowing === false && isModalPreviewShowing === false) {
      dispatch(setSubClaims(null));
    }
  }, [isModalErrorShowing, isModalPreviewShowing]);

  const buildActionsColumn = (_, row, {}) => (
    <>
      {
        <i
          className="icon-detail table-action-icon"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOpenMetadataModal(row, false);
          }}
        />
      }
    </>
  );

  const nullValueFormatter = (value, text) => (value ? value : text);

  const getLabel = (value, parentLabel) => {
    if (datacaptureConfiguration && previewDocument) {
      const foundConfig = datacaptureConfiguration.find(
        (config) => config.documentType === "anacom-folha-de-reclamacao"
      );
      if (datacaptureConfiguration && foundConfig && foundConfig.metadata) {
        if (parentLabel) {
          const foundNestedMetadata = foundConfig.metadata.find(
            (d) => d.value === parentLabel
          );
          if (foundNestedMetadata && foundNestedMetadata.children) {
            const foundChild = foundNestedMetadata.children.find(
              (d) => d.value === value
            );
            return foundChild ? foundChild.label : value;
          } else return value;
        } else {
          const foundMetadata = foundConfig.metadata.find(
            (d) => d.value === value
          );
          if (foundMetadata) {
            return foundMetadata.label;
          } else return value;
        }
      }
    }
    return value;
  };

  const renderMetadata = () => {
    return (
      metadata &&
      Object.keys(metadata).length > 0 &&
      Object.keys(metadata).map((key, index) => {
        let dados = [];
        const createItem = (label, value, index, parentLabel) => {
          return (
            <div key={index} className="adatacapture-metadata-item-row">
              <div className="adatacapture-metadata-item-label">
                <strong style={{ whiteSpace: "nowrap" }}>
                  {getLabel(label, parentLabel)}
                </strong>
              </div>
              <div className="adatacapture-metadata-item-value">
                <div>
                  {Array.isArray(value) && value.length > 0 ? (
                    <div className="adatacapture-metadata-nesteditems">
                      {value.map((item, i) => {
                        return (
                          <div
                            className="adatacapture-metadata-nesteditem"
                            key={i}
                          >
                            {Object.keys(item).map((key, i_) => {
                              return createItem(key, item[key], i_, label);
                            })}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    typeof value !== "object" && (
                      <small>{nullValueFormatter(value, "")}</small>
                    )
                  )}
                </div>
              </div>
            </div>
          );
        };
        metadata[key] && dados.push(createItem(key, metadata[key], index));
        return dados;
      })
    );
  };

  const generateImageView = (doc) =>
    doc ? "data:image/jpg;base64, " + doc.data : "";
  const generatePdfView = (doc) => {
    if (doc && doc.data !== "") {
      function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1];
        try {
          var bstr = atob(arr[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        } catch (e) {
          return new Blob(["No data found"], { type: "text/html" });
        }
      }
      //convert to blob because of Data protocol URL size limitations
      let blob = dataURLtoBlob("data:application/pdf;base64, " + doc.data);
      const temp_url = window.URL.createObjectURL(blob);
      return temp_url;
    }
  };

  function createBodyWaiting() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Por favor aguarde." status="waiting" />
      </div>
    );
  }

  function createBodySmall() {
    return (
      <div>
        <Row>
          <>
            <Col lg="7">
              <PopupTable
                naoclass={false}
                isNaoClassificadas={false}
                anacomList={currentAnacom}
                assuntosList={currentAssuntos}
                unikList={currentUnik}
                anacomPagination={anacomList}
                assuntosPagination={assuntosList}
                unikPagination={unikList}
              />
            </Col>

            <Col lg="5">
              <div style={{ paddingTop: "30px", paddingLeft: "25px" }}>
                {renderMetadata()}
              </div>
            </Col>
          </>
        </Row>
        <div style={{ paddingTop: "10px" }}>
          <SimpleButton
            variant={"liftworld-button-primary"}
            text={"Preview DOC"}
            onClick={() => {
              setIsModalPreviewShowing(true);
              setIsModalErrorShowing(false);
            }}
            align={"left"}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    );
  }

  function dataFormatter(cell) {
    let aux = cell;
    if (cell !== null) {
      aux = cell?.split("T")[0];
    }
    return <div>{aux}</div>;
  }

  // function idFormatter(cell) {
  //   return <div>{cell}</div>;
  // }

  const columns = [
    {
      dataField: "numeroProcesso",
      text: "Número do Documento",
      sort: false,
      headerStyle: { width: "150px" },
      // formatter: idFormatter,
    },
    {
      dataField: "data",
      text: "Data da reclamação",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: dataFormatter,
    },
    {
      dataField: "nomeFornecedor",
      text: "Nome do Fornecedor",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "localidadeFornecedor",
      text: "Nome da Localidade",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "nomeReclamante",
      text: "Nome do Reclamante",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "telefoneReclamante",
      text: "Telemóvel",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "assinado",
      text: "Assinado",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "id",
      text: "Ações",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: buildActionsColumn,
    },
  ];

  function createBodyPreview() {
    return (
      <div>
        {previewDocument && previewDocument?.filetype === "application/pdf" && (
          <>
            <iframe
              id="pdf-document"
              title="pdf-document"
              src={generatePdfView(previewDocument)}
              style={{
                height: "100%",
                width: "100%",
                minHeight: "100vh",
                minWidth: "100vh",
              }}
            />
          </>
        )}
        {previewDocument && previewDocument?.filetype !== "application/pdf" && (
          <img
            src={generateImageView(previewDocument)}
            className="document-preview"
            alt="document-preview"
          />
        )}
      </div>
    );
  }

  const downloadXls = () => {
    //1 = EXCEL
    dispatch(exportClaims(1, category, null));
  };

  return (
    <div>
      <div className="main-card-v2">
        <div className="custom-table">
          <ToolkitProvider
            keyField="id"
            data={complaints?.claims ? complaints?.claims : []}
            columns={columns}
            search={{ searchFormatted: true }}
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    {true && (
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("general.search")}
                      />
                    )}
                    <div style={{ paddingLeft: "15px" }} />
                    {/* <SimpleButton
                      variant={"liftworld-button-primary"}
                      disabled={
                        isLoadingGetComplaints ||
                        complaints?.claims?.length === 0
                      }
                      onClick={() => {
                        downloadXls();
                      }}
                      text={"Exportar"}
                    /> */}
                  </div>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  id="document-table"
                  responsive
                  bootstrap4
                  pagination={paginationFactory({
                    sizePerPage: 5,
                    sizePerPageList: [],
                  })}
                  noDataIndication={t("datacapture.general.dataNotFound")}
                  //rowEvents={rowEvents}
                  pageSize={5}
                  hover
                  striped
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Modal
          className="easypay-modal"
          showModal={isModalErrorShowing}
          setShowModal={setIsModalErrorShowing}
          headerContent={"Classificação"}
          bodyContent={createBodySmall()}
          key={uuid()}
          nomax={true}
        ></Modal>
        <Modal
          className="easypay-modal"
          showModal={isModalPreviewShowing}
          setShowModal={setIsModalPreviewShowing}
          headerContent={"Classificação"}
          bodyContent={createBodyPreview()}
          setShowPreviousModal={setIsModalErrorShowing}
          showPrevious={true}
          key={uuid()}
          nomax={true}
        ></Modal>
        <Modal
          className="easypay-modal"
          showModal={isModalWaiting}
          setShowModal={setIsModalWaiting}
          headerContent={"Classificação"}
          bodyContent={createBodyWaiting()}
          key={uuid()}
        ></Modal>
      </div>
      <Spinner spinning={isLoadingGetComplaints} />
    </div>
  );
};
export default withNamespaces()(NFinancialDoc);
