import {
  GET_ALL_CATEGORIES,
  DONE_GET_ALL_CATEGORIES,
  SET_ALL_CATEGORIES,
  GET_MANUAL_CLAIM,
  SET_MANUAL_CLAIM,
  GET_COMPLAINTS,
  DONE_GET_COMPLAINTS,
  SET_COMPLAINTS,
  EXPORT_CLAIMS,
  GET_ALL_RESULTS_CATEGORIES,
  DONE_ALL_RESULTS_CATEGORIES,
  SET_ALL_RESULTS_CATEGORIES,
  GET_DATA_RESULTS,
  DONE_DATA_RESULTS,
  SET_DATA_RESULTS,
  GET_COLUMNS_RESULTS,
  DONE_COLUMNS_RESULTS,
  SET_COLUMNS_RESULTS,
  GET_SUBDATA_RESULTS,
  DONE_SUBDATA_RESULTS,
  SET_SUBDATA_RESULTS,
  GET_SUBCOLUMNS_RESULTS,
  DONE_SUBCOLUMNS_RESULTS,
  SET_SUBCOLUMNS_RESULTS,
  GET_SUBCLAIMS,
  SET_SUBCLAIMS,
  DONE_GET_SUBCLAIMS,
  SET_UNIKPAGE,
  GET_DATA_TOPS,
  DONE_DATA_TOPS,
  SET_DATA_TOPS,
} from "./actionTypes";

const initialState = {
  categories: [],
  isLoadingGetAllCategories: false,
  claims: undefined,
  result: [],
  complaints: [],
  isLoadingGetComplaints: false,
  isLoadingAllResultsCategories: false,
  isLoadingDataResults: false,
  isLoadingColumnsResults: false,
  isLoadingSubDataResults: false,
  isLoadingSubColumnsResults: false,
  isLoadingGetSubClaims: false,
  subClaims: [],
  resultsCategories: [],
  unikPag: 1,
  subColumns: [
    {
      dataField: "getNome",
      text: "Assunto",
      sort: false,
      headerStyle: { width: "150px" },
    },
    {
      dataField: "codigo",
      text: "Código",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "amostra",
      text: "Amostra",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "percent",
      text: "% Revelantes",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "relevante",
      text: "Relevante",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "value1",
      text: "1",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "value2",
      text: "2",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
  ],
  subData: [
    {
      getNome: "Atraso na entrega de objetos postais",
      codigo: "2323",
      amostra: "34",
      percent: "12",
      relevante: "sim",
      value1: 23,
      value2: 44,
      value3: 23,
      valuer4: 223231,
    },
  ],
  columns: [
    {
      dataField: "assunto",
      text: "Assuntos",
      sort: false,
      headerStyle: { width: "150px" },
    },
    {
      dataField: "amostra",
      text: "Amostra",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "percent",
      text: "% Revelantes",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "relevante",
      text: "Relevante",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "value1",
      text: "1",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "value2",
      text: "2",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
  ],
  data: {
    categoryId: 1,
    headers: [
      {
        id: 1,
        dataField: "getAssunto",
        text: "Assuntos",
      },
      {
        id: 2,
        dataField: "getAmostra",
        text: "Amostra",
      },

      {
        id: 3,
        dataField: "getPercentagem",
        text: "%  Relevantes",
      },

      {
        id: 4,
        dataField: "getRelevante",
        text: "Relevante",
      },

      {
        id: 5,
        dataField: "getValue1",
        text: "1º",
      },

      {
        id: 6,
        dataField: "getValue2",
        text: "2º",
      },

      {
        id: 7,
        dataField: "getValue3",
        text: "3º",
      },
    ],
    rows: [
      {
        getAssunto: "Alteração de morada ou retenção",
        getAmostra: "2323",
        getPercentagem: "23",
        getRelevante: "sim",
        getValue1: "54",
        getValue2: "5657",
        getValue3: "942",
      },
      {
        getAssunto: "Contratação de serviços",
        getAmostra: "8623",
        getPercentagem: "32",
        getRelevante: "sim",
        getValue1: "994",
        getValue2: "5237",
        getValue3: "887",
      },
    ],
    subTable: {
      categoryId: 1,
      headers: [
        {
          id: 1,
          dataField: "getAssunto",
          text: "Assuntos",
        },
        {
          id: 2,
          dataField: "getCodigo",
          text: "Código",
        },
        {
          id: 3,
          dataField: "getMotivos",
          text: "Motivos",
        },
        {
          id: 4,
          dataField: "getAmostra",
          text: "Amostra",
        },

        {
          id: 5,
          dataField: "getPercentagem",
          text: "%  Relevantes",
        },

        {
          id: 6,
          dataField: "getRelevante",
          text: "Relevante",
        },

        {
          id: 7,
          dataField: "getValue1",
          text: "1º",
        },

        {
          id: 8,
          dataField: "getValue2",
          text: "2º",
        },

        {
          id: 9,
          dataField: "getValue3",
          text: "3º",
        },
      ],
      rows: [
        {
          getAssunto: "Alteração de morada ou retenção",
          getCodigo: "666",
          getMotivos: "motivo x",
          getAmostra: "2323",
          getPercentagem: "23",
          getRelevante: "sim",
          getValue1: "54",
          getValue2: "5657",
          getValue3: "942",
        },
        {
          getAssunto: "Contratação de serviços",
          getCodigo: "662",
          getMotivos: "motivo y",
          getAmostra: "8623",
          getPercentagem: "32",
          getRelevante: "sim",
          getValue1: "994",
          getValue2: "5237",
          getValue3: "887",
        },
      ],
    },
  },
  data: {},
  dataTops: [],
  subData: [],
  columns: [],
};

export const claimsManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_CLAIMS:
      return {
        ...state,
        exportTypeId: action.exportTypeId,
        categoryId: action.categoryId,
        claim: action.claim,
      };
    case GET_MANUAL_CLAIM:
      return { ...state, claims: action.claims };
    case SET_MANUAL_CLAIM:
      return { ...state, result: action.result };
    case GET_ALL_CATEGORIES:
      return { ...state, isLoadingGetAllCategories: true };
    case DONE_GET_ALL_CATEGORIES:
      return { ...state, isLoadingGetAllCategories: false };
    case SET_ALL_CATEGORIES:
      return { ...state, categories: action.categories };
    case GET_COMPLAINTS:
      return { ...state, isLoadingGetComplaints: true };
    case DONE_GET_COMPLAINTS:
      return { ...state, isLoadingGetComplaints: false };
    case SET_COMPLAINTS:
      return { ...state, complaints: action.complaints };

    case GET_SUBCLAIMS:
      return { ...state, isLoadingGetSubClaims: true };
    case DONE_GET_SUBCLAIMS:
      return { ...state, isLoadingGetSubClaims: false };
    case SET_SUBCLAIMS:
      return { ...state, subClaims: action.subClaims };

    case SET_UNIKPAGE:
      return { ...state, unikPag: action.unikPag };

    case GET_ALL_RESULTS_CATEGORIES:
      return { ...state, isLoadingAllResultsCategories: true };
    case DONE_ALL_RESULTS_CATEGORIES:
      return { ...state, isLoadingAllResultsCategories: false };
    case SET_ALL_RESULTS_CATEGORIES:
      return { ...state, resultsCategories: action.resultsCategories };

    case GET_DATA_RESULTS:
      return { ...state, isLoadingDataResults: true };
    case DONE_DATA_RESULTS:
      return { ...state, isLoadingDataResults: false };
    case SET_DATA_RESULTS:
      return { ...state, data: action.data };

    case GET_COLUMNS_RESULTS:
      return { ...state, isLoadingColumnsResults: true };
    case DONE_COLUMNS_RESULTS:
      return { ...state, isLoadingColumnsResults: false };
    case SET_COLUMNS_RESULTS:
      return { ...state, columns: action.columns };

    case GET_SUBDATA_RESULTS:
      return { ...state, isLoadingSubDataResults: true };
    case DONE_SUBDATA_RESULTS:
      return { ...state, isLoadingSubDataResults: false };
    case SET_SUBDATA_RESULTS:
      return { ...state, subData: action.subData };

    case GET_DATA_TOPS:
      return { ...state, obj: action.obj };
    case DONE_DATA_TOPS:
      return { ...state, isLoadingSubColumnsResults: false };
    case SET_DATA_TOPS:
      return { ...state, dataTops: action.dataTops };

    default:
      return state;
  }
};
