import http from "~/utils/config/http";
import { put } from "redux-saga/effects";
import { setUserData, setCollaboratorData } from "./actionTypes";
import { RH_MIDDLEWARE } from "~/utils/constants";

export function* saveUserData({ data }) {
  console.log("saveUserData");
  try {
    const resp = yield http.post("/api/mongo/simulators", data);
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

export function* getUserData() {
  try {
    const resp = yield http.get("/api/mongo/simulators");
    yield put(setUserData(resp.data));
  } catch (error) {
    console.log(error);
  }
}

export function* upDateUserData({ id, data }) {
  try {
    const resp = yield http.put(`/api/mongo/simulators/${id}`, data);
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

export function* deleteUserData({ id }) {
  try {
    const resp = yield http.delete(`/api/mongo/simulators/${id}`);
    yield put(setUserData(resp.data));
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

export function* getCollaboratorData({ id, companyId }) {
  try {
    const resp = yield http.get(
      `/api/simulator/${id}/${companyId}`,
      RH_MIDDLEWARE
    );
    console.log(resp.data, "sagaaaData");
    yield put(setCollaboratorData(resp.data));
  } catch (error) {
    console.log(error);
  }
}
