import React from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_wizard.scss";

const Wizard = ({ list, t }) => {
  const textList = list.map((state) => {
    return state.value.split("::")[0];
  });

  const wizardStates = list.map((state) => {
    return state.value.split("::")[1];
  });

  return (
    <>
      <div className="easypay-wizard-wrapper">
        <div className="easypay-wizard-badge-wrapper">
          <div
            className={`easypay-wizard-badge ${
              wizardStates && wizardStates[1] === "active"
                ? "easypay-active"
                : ""
            }`}
            disabled={wizardStates && wizardStates[1] === "disabled"}
          >
            1
          </div>
          <div
            className={`easypay-wizard-text ${
              wizardStates && wizardStates[1] === "active"
                ? "easypay-wizard-active-text"
                : ""
            }`}
          >
            {t(textList[1])}
          </div>
        </div>
        <div className="easypay-wizard-separator-wrapper">
          <div className="easypay-wizard-separator"></div>
        </div>
        <div className="easypay-wizard-badge-wrapper">
          <div
            className={`easypay-wizard-badge ${
              wizardStates && wizardStates[2] === "active"
                ? "easypay-active"
                : ""
            }`}
            disabled={wizardStates && wizardStates[2] === "disabled"}
          >
            2
          </div>
          <div
            className={`easypay-wizard-text ${
              wizardStates && wizardStates[2] === "active"
                ? "easypay-wizard-active-text"
                : ""
            }`}
          >
            {t(textList[2])}
          </div>
        </div>
        <div className="easypay-wizard-separator-wrapper">
          <div className="easypay-wizard-separator"></div>
        </div>
        <div className="easypay-wizard-badge-wrapper">
          <div
            className={`easypay-wizard-badge ${
              wizardStates && wizardStates[3] === "active"
                ? "easypay-active"
                : ""
            }`}
            disabled={wizardStates && wizardStates[3] === "disabled"}
          >
            3
          </div>
          <div
            className={`easypay-wizard-text ${
              wizardStates && wizardStates[3] === "active"
                ? "easypay-wizard-active-text"
                : ""
            }`}
          >
            {t(textList[3])}
          </div>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(Wizard);
