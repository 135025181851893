import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
//import { jsonData } from "./forms/jsonData"; //custom button
//import { jsonData } from "./forms/1/jsonData"; //Custo consulta
//import { jsonData } from "./forms/2/jsonData"; //Comparativo consulta
//import { jsonData } from "./forms/3/jsonData"; //Comparativo
//import { jsonData } from "./forms/4/jsonData"; //Prospeçao
//import { jsonData } from "./forms/5/jsonData"; //Rendimentos
//import { jsonData } from "./forms/6/jsonData"; //Prospeçao consulta
//import { jsonData } from "./forms/7/jsonData"; //Custo
//import { jsonData } from "./forms/8/jsonData"; //Rendimentos consulta
//import { jsonData } from "./forms/9/jsonData"; //forwardpage
//import { jsonData } from "./forms/17/jsonData";
import RenderForm from "./components/RenderForm";
import CreateForwardPage from "./components/CreateForwardPage";
import CreateEndPage from "./components/CreateEndPage";
import CreateErrorPage from "./components/CreateErrorPage";
import Spinner from "~/components/Spinner";
import { getHeaderName } from "./utils";
import { useRouteMatch } from "react-router-dom";
import { resetIflowDocuments } from "~/store/ducks/processes/actionTypes";
import {
  resetIflowDocumentsLinkComponent,
  setSelectedTosignLinkComponent,
} from "~/store/ducks/processesSignature/actionTypes";
import { setSignedPdf } from "~/store/ducks/onboarding/actionTypes";

const CreateForm = (props) => {
  const {
    jsonData,
    isModal,
    className,
    handleHeaderName = () => {},
    isExternalPage = false,
    handleClose,
  } = props;
  const { isLoadingFormdata } = useSelector((state) => state.processesReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  
  const { userSettings } = useSelector((state) => state.userSettingsReducer);

  let language = userSettings?.language ? userSettings.language : "pt";

  let { path } = useRouteMatch();

  const [styleSheet, setFormStyleSheet] = useState("");
  const [headerName, setHeaderName] = useState("");
  const [translations, setTranslations] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (jsonData) {
      const translationStylesheet = gettranslations();
      setHeaderName(headerTextName(getHeaderName(jsonData), translationStylesheet));
      handleHeaderName(headerTextName(getHeaderName(jsonData), translationStylesheet));
    }

    return () => {
      setHeaderName("");
      dispatch(resetIflowDocumentsLinkComponent());
      dispatch(setSelectedTosignLinkComponent([]));
      dispatch(resetIflowDocuments());
      dispatch(setSignedPdf(null));
    };
  }, []);

  const gettranslations = ()=>{
    if(menu){
      let app = "";
      if (isExternalPage) {
        app = "bpm";
      } else {
        //find app from url
        const arr1 =
          window.location.href.includes("/uniksystem/") &&
          window.location.href.split("/uniksystem/");
        if (arr1.length > 1) {
          const arr2 = arr1[1];
          const arr3 = arr2.split("/");
          app = arr3[0];
        }
      }
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        return extraConfiguration_?.translations
      }
    }
  }

  const headerTextName = (textHeader, translationStylesheet)=>{
    let text = undefined;
    if (textHeader?.includes("::t::")){
      let lingua = language.trim();
      let splittext = textHeader.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if(translationStylesheet){
        text = translationStylesheet?.[lingua][texttoshow] + texttoadd
      }
    } else {
      text = textHeader
    }
    return text
  }

  /*Get extraconfiguration*/
  useEffect(() => {
    if (menu) {
      let app = "";
      if (isExternalPage) {
        app = "bpm";
      } else {
        //find app from url
        const arr1 =
          window.location.href.includes("/uniksystem/") &&
          window.location.href.split("/uniksystem/");
        if (arr1.length > 1) {
          const arr2 = arr1[1];
          const arr3 = arr2.split("/");
          app = arr3[0];
        }
      }

      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const styleSheet_ =
          extraConfiguration_ && extraConfiguration_.styleSheet;
        //console.log(styleSheet_)
        setFormStyleSheet(styleSheet_);
        setTranslations(extraConfiguration_?.translations)
      }
    }
  }, [menu]);

  const renderResponse = (styleSheet) => {
    if (jsonData) {
      if (jsonData?.url?.includes("proc_info.jsp")) {
        return (
          <CreateForwardPage
            jsonData={jsonData}
            styleSheet={styleSheet}
            isModal={isModal}
            handleClose={handleClose}
          />
        );
      }
      if (jsonData?.url?.includes("end.jsp")) {
        return (
          <CreateEndPage
            jsonData={jsonData}
            isModal={isModal}
            handleClose={handleClose}
            styleSheet={styleSheet}
          />
        );
      }
      if (
        jsonData?.url?.includes("flow_error.jsp") ||
        jsonData?.url?.includes("error.jsp") ||
        jsonData?.url?.includes("nopriv.jsp") ||
        jsonData?.url?.includes("nop.jsp") ||
        (jsonData?.url?.includes("form.jsp") &&
          jsonData?.responseMessage.includes('<div class="error_msg">'))
      ) {
        return (
          <CreateErrorPage
            jsonData={jsonData}
            isModal={isModal}
            handleClose={handleClose}
            styleSheet={styleSheet}
          />
        );
      }

      if (jsonData.responseMessage) {
        const substrings = ["<div>", "<table>", "p"];
        if (new RegExp(substrings.join("|")).test(jsonData.responseMessage)) {
          // At least one match
          return <div></div>;
          {
            /* <div
              dangerouslySetInnerHTML={{ __html: jsonData.responseMessage }}
          />*/
          }
        } else return <div>{jsonData.responseMessage}</div>;
      } else if (jsonData.form){
        return (
          <RenderForm
            data={jsonData}
            styleSheet={styleSheet}
            isModal={isModal}
            translations={translations}
          />
        );}
    } else return <div></div>;
  };

  const isMobile = window.innerWidth < 992 ? true : false;
  return !isLoadingFormdata ? (
    <div
      className={
        className
          ? `${className} scrollBar-visible`
          : "main-card-v2"
      }
    >
      <Card
        bsPrefix="card-flat"
        style={
          (jsonData?.url?.includes("flow_error.jsp") ||
            jsonData?.url?.includes("error.jsp") ||
            jsonData?.url?.includes("end.jsp")) &&
          styleSheet?.badgesMessage
            ? { overflowY: "visible", height: "300px" }
            : { overflowY: "visible" }
        }
      >
        {(path.includes("/tasks") || path.includes("/tarefas")) &&
        styleSheet?.hideFormHeader ? (
          <div></div>
        ) : (
          <Card.Header
            style={{ padding: "15px 40px" }}
            className="justify-content-between"
          >
            {!isLoadingFormdata && (
              <div className="custom-title">{headerName}</div>
            )}
          </Card.Header>
        )}
        <Card.Body
          style={{
            overflowY: isModal ? "none" : "visible",
            padding: isMobile ? "20px 0px" : "20px",
          }}
        >
          {renderResponse(styleSheet)}
        </Card.Body>
      </Card>
    </div>
  ) : (
    <Spinner spinning={isLoadingFormdata} wrapper />
  );
};

export default CreateForm;
