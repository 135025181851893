import React from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { transformFormData } from "~/components/CreateForm/utils";
import { postEasypayFormData } from "~/store/ducks/easypay/actionTypes";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import "../assets/scss/_documentsTab.scss";
import Text from "./Text";
import oauth from "~/utils/oauth";

function SingleDocument({
  name,
  variant,
}) {

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);

  const field = {
    type: "link",
    text: "text",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='removeIbanDoc';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  }

  function handleRemoveFile() {

    if (field.href.includes("javascript:")) {
      if (field.href.includes("document.dados.op.value='")) {
        const array = field.href.split("document.dados.op.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.op.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.buttonResult.value='")) {
        const array = field.href.split("document.dados.buttonResult.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.buttonResult.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.acção.value='")) {
        const array = field.href.split("document.dados.acção.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.acção.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.voltar.value='")) {
        const array = field.href.split("document.dados.voltar.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.voltar.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.trocarDeContaTink.value='")) {
        const array = field.href.split(
          "document.dados.trocarDeContaTink.value='"
        );
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.trocarDeContaTink.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.botao_accao.value='")) {
        const array = field.href.split("document.dados.botao_accao.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.botao_accao.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.mostrardoc.value='")) {
        const array = field.href.split("document.dados.mostrardoc.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.mostrardoc.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.cancelardoc.value='")) {
        const array = field.href.split("document.dados.cancelardoc.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.cancelardoc.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.toggleCodigoCertidao.value='")) {
        const array = field.href.split(
          "document.dados.toggleCodigoCertidao.value='"
        );
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.toggleCodigoCertidao.value = array2[0];
          }
        }
      }
      if (field.href.includes("document.dados.botao.value='")) {
        const array = field.href.split("document.dados.botao.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.botao.value = array2[0];
          }
        }
      }

      if (document.dados?.cmdPath && document.dados?.cmdHost) {
        if (document.dados?.acção.value === "iniciarChaveMovelDigital") {
          document.dados.cmdHost.value = window.location.origin;
          document.dados.cmdPath.value = window.location.hash.slice(1); //To remove # that causes iflow some problems
        }
      }

      if (field.href.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postEasypayFormData(formdata, url));
      }
    }
  }

  return (
    <div className="easypay-document-submitted-wrapper-wrapper">
      <div
        className={`easypay-document-submitted-wrapper ${
          variant ? variant : ""
        }`}
      >
        <div className="easypay-documen-icon-wrapper">
          <div className="easypay-text-icon">
            <img src={iconDocument} alt="Icon Check" />
          </div>
          <div className="easypay-document-text-centered">
            <Text text={name + "::card-text-blue"} />
          </div>
        </div>
        <div className="easypay-x-icon" onClick={() => handleRemoveFile()}>
          X
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(SingleDocument);
