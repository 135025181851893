import React from 'react';

import logo from '~/assets/img/logos/unik-logo.png';
import "~/assets/css/styles.css"

const AccessDenied = () => {

  const style = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    backgroundImage: 'radial-gradient(#ffffff, #d6d6d6)',
    justifyContent: 'center',
    overflow: 'scroll'
  }

  return (
    <div style={style}>
      <div style={{ textAlign: 'center' }}>
        <img
          className="img-fluid unik-logo-500"
          src={logo}
          alt="Uniksystem"
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '150px', marginBottom: -60 }}>403</div>
        <div style={{ fontSize: '50px', fontWeight: 700, color: 'gray' }}>access denied</div>
        <small style={{ color: 'gray' }}>You don't have permission to view this page</small>
      </div>
    </div>
  )
}

export default AccessDenied;
