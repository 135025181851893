import { takeLatest } from 'redux-saga/effects';

import {
  FIND_MENU_BY_APP,
  FIND_MENU_BY_APP_NAME,
  FIND_SETTINGS_MENU_BY_APP,
  SAVE_APPLICATION_MENU,
  GET_MENUS_BY_APPLICATION,
  GET_MENUS_BY_FLOW,
  EDIT_APP_MENU,
  DELETE_APP_MENU
} from "../ducks/applicationMenu/actionTypes";

import {
  findMenuByApplication,
  findMenuByApplicationName,
  findSettingsMenuByApplication,
  saveApplicationMenu,
  getMenusByApplication,
  getMenusByFlows,
  editApplicationMenu,
  deleteApplicationMenu
} from "../ducks/applicationMenu/sagas";

export const applicationMenuSagas = [
  takeLatest(FIND_MENU_BY_APP, findMenuByApplication),
  takeLatest(FIND_MENU_BY_APP_NAME, findMenuByApplicationName),
  takeLatest(FIND_SETTINGS_MENU_BY_APP, findSettingsMenuByApplication),
  takeLatest(SAVE_APPLICATION_MENU, saveApplicationMenu),
  takeLatest(GET_MENUS_BY_APPLICATION, getMenusByApplication),
  takeLatest(GET_MENUS_BY_FLOW, getMenusByFlows),
  takeLatest(EDIT_APP_MENU, editApplicationMenu),
  takeLatest(DELETE_APP_MENU, deleteApplicationMenu)
]
