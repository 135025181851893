import React, { useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uuid from "uuid/v1";
import pagination10 from "~/components/pagination/currentSizePerPage/10";
import infoIcon from "~/assets/img/icons/info-icon.png";
import {
  pagination5,
  pagination6,
  pagination7,
  pagination8,
  pagination9,
} from "~/components/pagination/currentSizePerPage";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";
import oauth from "~/utils/oauth";
import CreateTableCheckBoxInput from "../CreateTableCheckBoxInput";
import CreateTablePicture from "../CreateTablePicture";
import CreateTableDocumentLink from "./CreateTableDocumentLink";
import CaptionElement from "./CaptionElement";
import CreateTableRadioInput from "../CreateTableRadioInput";
import CreateTableFile from "./CreateTableFile";
import CreateTableSignCheck from "./CreateTableSignCheck";
import CreateFileAsLink from "./CreateFileAsLink";
import Spinner from "~/components/Spinner";
import CreateTableRichText from "./CreateTableRichText";
import Modal from "~/pages/Easypay/components/Modal";
import {
  hasNestedValue,
  inputFormatter,
  selectFormatter,
  numberFormatter,
} from "./util";

const CreateBootstrapTable = (props) => {
  const { t, field, signRef, styleSheet, translations, language } = props;

  const [isSearchVisible, setIsSearchVisible] = React.useState(false);
  const [tableField, setTableField] = React.useState(field);
  const [tablePagination, setTablePagination] = React.useState("10");
  const [tableData, setTableData] = React.useState({
    data: [],
    headers: [],
    rows: [],
    columns: [],
    captionHeaders: [],
  });
  const [isPageChange, setIsPageChange] = React.useState(false);
  const tableDataRef = React.useRef();
  tableDataRef.current = tableData;
  const dispatch = useDispatch();

  const { isLoadingGetSignedPdf } = useSelector(
    (state) => state.onboardingReducer
  );

  const { isLoadingIflowDocumentLinkComponent, tableFileSignClicked } =
    useSelector((state) => state.processesSignatureReducer);

  const { isLoadingIflowDocument, isSubstitutingIflowDocument } = useSelector(
    (state) => state.processesReducer
  );

  const focusDiv = useRef();
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [currentInfo, setCurrentInfo] = useState("");

  useEffect(() => {
    setTableField(Object.assign(tableField, tableData));
  }, [field]);

  useEffect(() => {
    if (isPageChange) {
      focusDiv.current.focus();
      setIsPageChange(false);
    }
  }, [isPageChange]);

  const makeLink = (a, linkIsImage, toSign, cell, rowIndex) => {
    const findArgValue = (arg) => {
      return a.arg && a.arg.find((argument) => argument.name === arg).value;
    };

    const handleLinkClick = () => {
      if (a.disabled !== "true" && a.arg) {
        let url = "";
        if (a.href?.includes("jsp")) {
          url =
            "/Form/" +
            a.href +
            "?flowid=" +
            findArgValue("flowid") +
            "&pid=" +
            findArgValue("pid") +
            "&subpid=" +
            findArgValue("subpid") +
            "&Authorization=" +
            oauth.getAccessToken();
        } else {
          url =
            "/Form/form.jsp?" +
            "flowid=" +
            findArgValue("flowid") +
            "&pid=" +
            findArgValue("pid") +
            "&subpid=" +
            findArgValue("subpid") +
            "&Authorization=" +
            oauth.getAccessToken();
        }
        let formdata = new FormData();
        a.arg &&
          a.arg.map((ar) => {
            formdata.append(ar.name, ar.value);
          });
        /*for (var pair of formdata.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }*/
        const isNewForm = true
        dispatch(postIflowFormData(formdata, url, isNewForm));
      }
    };

    if (a.text && a.text === "Cancelar")
      return (
        <i
          className="icon-trash table-action-icon"
          onClick={() => handleLinkClick()}
        />
      );
    else if (a.disabled === "true") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            wordBreak: "break-all",
            color: "grey",
          }}
        >
          {a.text}
        </div>
      );
    } else if (a.href && a.href.includes("/iFlow/document")) {
      return linkIsImage ? (
        <CreateTablePicture field={a} />
      ) : toSign ? (
        <CreateTableDocumentLink
          field={a}
          cell={cell}
          rowIndex={rowIndex}
          styleSheet={styleSheet}
        />
      ) : (
        <a
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            wordBreak: "break-all",
          }}
          download="sample.pdf"
          href={a.href + "&Authorization=" + oauth.getAccessToken()}
        >
          {transformText(a.text) || "file"}
        </a>
      );
    } else
      return (
        <Button
          style={{ fontSize: "inherit", padding: "0px", display: "flex" }}
          variant="link"
          onClick={() => handleLinkClick()}
        >
          {transformText(a.text)}
        </Button>
      );
  };

  const linkFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        key={uuid()}
        style={{
          textAlign: "left",
          display: formatExtraData.toSign ? "" : "flex",
        }}
      >
        {formatExtraData &&
          row[formatExtraData.index].a &&
          makeLink(
            row[formatExtraData.index].a,
            formatExtraData.linkIsImage,
            formatExtraData.toSign,
            formatExtraData.index,
            rowIndex
          )}
      </div>
    );
  };

  const inputRadioFormatter = (cell, row, rowIndex, formatExtraData) => {
    let tableInput;
    if (row[formatExtraData.index].input.type === "radio")
      tableInput = (
        <CreateTableRadioInput field={row[formatExtraData.index].input} />
      );

    return <div key={uuid()}>{tableInput}</div>;
  };

  const fileFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <CreateTableFile
        field={row[formatExtraData.index]?.field}
        signRef={signRef}
        styleSheet={styleSheet}
        cell={cell}
        rowIndex={rowIndex}
      />
    );
  };

  const fileAsLinkFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <CreateFileAsLink field={row[formatExtraData.index]?.field} />;
  };

  const signCheckFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <CreateTableSignCheck
        field={row[formatExtraData.index]?.field}
        signRef={signRef}
      />
    );
  };

  const tableDialogFormatter = (cell, row, rowIndex, formatExtraData, str) => {
    let string = cell?.input?.event
      ?.substring(10)
      ?.split(",")[0]
      ?.replace("'", "");
    string = string?.replace("'", "");
    //TODO implement preview alert

    return (
      <>
        {string != "" && string != null && string != undefined ? (
          <div
            key={uuid()}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              style={{ cursor: "pointer", width:'20px' }}
              className="info-icon"
              onClick={() => {
                setIsModalShowing(true);
                setCurrentInfo(string);
              }}
              src={infoIcon}
            ></img>
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  };

  const inputCheckBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
    let tableInput;
    if (row[formatExtraData.index].input.type === "checkbox")
      tableInput = (
        <CreateTableCheckBoxInput
          styleSheet={styleSheet}
          field={row[formatExtraData.index].input}
          selected={
            row[formatExtraData.index].input.selected
              ? row[formatExtraData.index].input.selected
              : row[formatExtraData.index].input.checked
          }
          onSelect={(v) => {
            if (!row[formatExtraData.index].input) return;
            var index = tableDataRef.current.data.indexOf(row);
            row[formatExtraData.index].input.selected = v;
            tableDataRef.current.data[index] = row;
            setTableData(tableDataRef.current);
          }}
        />
      );
    return <div key={uuid()}>{tableInput}</div>;
  };

  const richTextFormatter = (cell, row, rowIndex, formatExtraData) => {
    const hValue = formatExtraData.hValue;
    if (hValue.length > 1) {
      const checkForHide = hValue.split("::hideTextLongerThan=");
      let hideTextLongerThanLenght;
      if(checkForHide[1].includes("::")){
        hideTextLongerThanLenght = checkForHide[1].split("::")[0]
      } else {
        hideTextLongerThanLenght = checkForHide[1]
      }
      const rowLength = parseInt(hideTextLongerThanLenght, 10);

      if (!isNaN(rowLength) && row[formatExtraData.index].length > rowLength) {
        const textToTruncate = row[formatExtraData.index];
        const spaceIndex = textToTruncate.indexOf(" ", rowLength);
        if (spaceIndex !== -1) {
          let truncatedText = textToTruncate.substring(0, spaceIndex);
          return <CreateTableRichText field={truncatedText + "..."} alltext={row[formatExtraData.index]} header={hValue.split("::hideTextLongerThan=")[0]} moreinfo={true} />;
        }
      }
    }
    return <CreateTableRichText field={row[formatExtraData.index]} alltext={row[formatExtraData.index]} header={[0]} />;
  };

  const transformText = (name)=>{
    let text = undefined
    if (name.includes("::t::")){
      let lingua = language.trim();
      let splittext = name.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if(translations){
        text = translations?.[lingua][texttoshow] + texttoadd
      }
    } else {
      if (name && name.includes("::")) {
        text = name.split("::")[0];
      } else{
        text = name || ""
      }
    }
    return text;
  }

  useEffect(() => {
    /*Define columns and data for react-bootstrap-table-next */
    const rows = tableField.row
      ? Array.isArray(tableField.row)
        ? tableField.row
        : [tableField.row]
      : [];
    let headers = [];
    let columns = [];
    let data = [];
    //let captionText = "";
    let captionHeaders = [];
    let pagination = "10";

    rows.forEach((row) => {
      const cols = Array.isArray(row.col) ? row.col : [row.col];
      if (cols.some((col) => col.header === "true")) {
        let header = [];
        cols.forEach((col) => header.push(col));
        headers.push(header);
      } else {
        let rowObject = {};
        cols.forEach((col, index) => {
          rowObject.id = uuid();
          if (col?.value?.a) {
            rowObject[index] = {
              a: { ...col.value.a, variable: col.variable },
            };
          } else rowObject[index] = col.value;
        });
        data.push(rowObject);
      }
    });

    const transformHeaderName = (name) => {
      let text = transformText(name)
      return text;
    };
    //get last header
    const lastHeader = headers[headers.length - 1];
    if (!lastHeader) {
      data.forEach((d, index) => {
        columns.push({
          dataField: index.toString(),
          text: "",
        });
      });
    } else {
      //make columns with formatters
      lastHeader.forEach((h_, index) => {
        const hasLink = data.find((d) => d[index].a);
        const linkIsImage = h_.value.includes("::image") ? true : false;
        const linkToSign = h_.value.includes("::toSign") ? true : false;
        const isNumberFormat = h_.value.includes("::number") ? true : false;
        const columnWidth = h_.value.includes("::columnWidth") ? true : false;
        const hasPagination = h_.value.includes("::pagination") ? true : false;
        if (hasPagination) {
          let myStr = h_.value.split("::pagination=")[1];
          let numPages = myStr.split("::")[0];
          if (numPages.match(/^[0-9]+$/)) {
            pagination = numPages;
          }
        }

        const hasRichText = data.find(
          (d) =>
            d[index] &&
            typeof d[index] === "string" &&
            (d[index].includes("</") || d[index].includes("&"))
        );
        const hasInputText = data.find(
          (d) => d[index].input && d[index].input.type === "tabletext"
        );
        const hasInputRadio = data.find(
          (d) => d[index].input && d[index].input.type === "radio"
        );
        const hasSelection = data.find(
          (d) => d[index].input && d[index].input.type === "tableselection"
        );
        const hasTableDialog = data.find(
          (d) => d[index].input && d[index].input.type === "tabledialog"
        );
        const hasCheckBox = data.find(
          (d) => d[index].input && d[index].input.type === "checkbox"
        );
        const hasFile = data.find(
          (d) => d[index].field && d[index].field.type === "file"
        );

        const columnWidthFormater = () => {
          let myStr = h_.value.split("::columnWidth")[1];

          let width = myStr.substring(
            myStr.indexOf("=") + 1,
            myStr.lastIndexOf("%")
          );
          if (width.match(/^[0-9]+$/)) {
            return `${width}%`;
          }
        };

        if (hasLink) {
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value),
            formatter: linkFormatter,
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            formatExtraData: {
              index: index,
              linkIsImage: linkIsImage,
              toSign: linkToSign,
            },
          });
        } else if (hasInputText) {
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value) || "",
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            formatter: inputFormatter,
            formatExtraData: {
              index: index,
            },
          });
        } else if (hasSelection) {
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value) || "",
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            formatter: selectFormatter,
            formatExtraData: {
              index: index,
            },
          });
        } else if (hasInputRadio) {
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value) || "",
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            formatter: inputRadioFormatter,
            formatExtraData: {
              index: index,
            },
          });
        } else if (hasCheckBox) {
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value) || "",
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            formatter: inputCheckBoxFormatter,
            formatExtraData: {
              index: index,
            },
          });
        } else if (hasTableDialog) {
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value) || "",
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            formatter: tableDialogFormatter,
            formatExtraData: {
              index: index,
            },
          });
        } else if (hasFile) {
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value) || "",

            formatter: fileAsLinkFormatter,
            formatExtraData: {
              index: index,
            },
            style: () => {
              return { wordBreak: "break" };
            },
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
          });
          columns.push({
            dataField: "signed",
            text: "Documento assinado",
            formatter: signCheckFormatter,
            formatExtraData: {
              index: index,
            },
            headerStyle:()=>{
              return {
                width: '90px',
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
          });
          columns.push({
            dataField: "toSign",
            text: "Assinar",
            formatter: fileFormatter,
            formatExtraData: {
              index: index,
            },
            headerStyle:()=>{
              return {
                width: "80px", zIndex: "101",
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
          });
        } else if (isNumberFormat) {
          columns.push({
            dataField: index.toString(),
            text: h_.value.split("::number")[0] || h_.value,
            type: "number",
            sort: true /*sort numbers*/,
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            formatExtraData: {
              index: index,
            },
            sortValue: (cell, row) => numberFormatter(cell, row),
            sortCaret: (order, column) => {
              if (h_.value !== "") {
                if (!order) return <span className="order-4"></span>;
                else if (order === "asc")
                  return <span className="caret-4-asc"></span>;
                else if (order === "desc")
                  return <span className="caret-4-desc"></span>;
                return null;
              } else return null;
            },
          });
        } else if (hasRichText) {
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value),
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            formatter: richTextFormatter,
            formatExtraData: {
              index: index,
              hValue: h_.value,
            },
          });
        } else
          columns.push({
            dataField: index.toString(),
            text: transformHeaderName(h_.value),
            sort: true /*sort strings*/,
            headerStyle:()=>{
              return {
                width: columnWidth ? columnWidthFormater() : {},
                fontSize: styleSheet?.tableFontSize ? `${styleSheet?.tableFontSize}px` : '',
            }
          },
            sortCaret: (order, column) => {
              if (h_.value !== "") {
                if (!order) return <span className="order-4"></span>;
                else if (order === "asc")
                  return <span className="caret-4-asc"></span>;
                else if (order === "desc")
                  return <span className="caret-4-desc"></span>;
                return null;
              } else return null;
            },
          });
      });
    }

    //create caption if exists
    if (headers.length > 1) {
      captionHeaders = headers.filter((h, i) => i !== headers.length - 1);
    }

    setTableData({ data, rows, headers, columns, captionHeaders });
    setTablePagination(pagination);
  }, [tableField]);

  const onPageChange = (page, sizePerPage) => {
    if (focusDiv && focusDiv.current) {
      setIsPageChange(true);
    }
  };

  const getPagination = () => {
    let result = { ...pagination10, onPageChange: onPageChange };
    switch (tablePagination) {
      case "0":
        result = null;
        break;
      case "5":
        result = { ...pagination5, onPageChange: onPageChange };
        break;
      case "6":
        result = { ...pagination6, onPageChange: onPageChange };
        break;
      case "7":
        result = { ...pagination7, onPageChange: onPageChange };
        break;
      case "8":
        result = { ...pagination8, onPageChange: onPageChange };
        break;
      case "9":
        result = { ...pagination9, onPageChange: onPageChange };
        break;
      case "10":
        result = { ...pagination10, onPageChange: onPageChange };
        break;
      default:
        result = { ...pagination10, onPageChange: onPageChange };
        break;
    }
    return result ? paginationFactory(result) : null;
  };

  if (
    tableData.rows &&
    Array.isArray(tableData.data) &&
    tableData.columns.length > 0
  ) {
    const { SearchBar } = Search;
    const autoWidth = isFullWidth()
      ? false
      : Array.isArray(tableData.headers) &&
        (tableData.headers.length == 0 ||
          tableData.headers[tableData.headers.length - 1]?.length < 4)
      ? true
      : false;

    function isFullWidth() {
      if (
        field?.row[0]?.col[0]?.value?.split("::")?.length > 1 ||
        field?.row[0]?.col?.value?.split("::")?.length > 1
      ) {
        if (
          field?.row[0]?.col[0]?.value?.includes("::full-width") ||
          field?.row[0]?.col.value?.includes("::full-width")
        ) {
          return true;
        }
        return false;
      } else return false;
    }

    function createBody() {
      return (
        <div className="lift-modal-body-wrapper-30">
          <div>
            <div style={{ fontWeight: "bold" }}>{currentInfo}</div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div
          ref={focusDiv}
          tabIndex={"-1"}
          className={`iflow-table ${autoWidth ? "autoWidth" : ""} 
        ${
          hasNestedValue(field, "type", "checkbox") &&
          tableData.data.length > 5 &&
          "fitContent"
        } 
        ${
          styleSheet?.tableFontSize
            ? `tableFont${styleSheet?.tableFontSize}`
            : `tableFont12`
        }
        custom-table`}
          style={{
            overflow: "auto",
            paddingBottom: styleSheet?.compact ? "0px" : "20px",
          }}
        >
          <Modal
            className="easypay-modal"
            showModal={isModalShowing}
            setShowModal={setIsModalShowing}
            headerContent={"Info"}
            bodyContent={createBody()}
            key={uuid()}
          ></Modal>
          <Spinner
            wrapper
            spinning={
              isLoadingGetSignedPdf ||
              isLoadingIflowDocumentLinkComponent ||
              isLoadingIflowDocument ||
              tableFileSignClicked ||
              isSubstitutingIflowDocument
            }
          />
          <ToolkitProvider
            keyField="id"
            data={tableData.data}
            columns={tableData.columns}
            search
          >
            {(props) => (
              <div>
                {tableData.data.length > 5 &&
                  (styleSheet?.bootstrapTableSearch ? (
                    <div className="searchbar-float-left">
                      <SearchBar
                        style={{ float: "left" }}
                        {...props.searchProps}
                        placeholder={t("general.search")}
                      />
                    </div>
                  ) : (
                    <div className="searchbar-float-right">
                      <SearchBar
                        {...props.searchProps}
                        className={
                          !isSearchVisible ? "hidden-searchbar-fix" : ""
                        }
                        style={
                          !isSearchVisible
                            ? {
                                width: 0,
                                padding: "0px!important",
                                border: "none",
                              }
                            : { transition: "ease-in-out 0.3s all" }
                        }
                        placeholder={t("general.search")}
                      />

                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip`}>Pesquisar Tabela</Tooltip>
                        }
                      >
                        <i
                          style={{ paddingRight: "5px", cursor: "pointer" }}
                          onClick={() => setIsSearchVisible(!isSearchVisible)}
                          className="icon-search"
                        ></i>
                      </OverlayTrigger>
                    </div>
                  ))}
                <div
                  className={
                    hasNestedValue(field, "type", "checkbox") &&
                    tableData.data.length > 5
                      ? styleSheet?.compact
                        ? "checkbox-fix"
                        : ""
                      : ""
                  }
                >
                  <BootstrapTable
                    {...props.baseProps}
                    caption={
                      tableData.captionHeaders.length > 0 && (
                        <CaptionElement
                          captionHeaders={tableData.captionHeaders}
                          translations={translations}
                          language={language}
                        />
                      )
                    }
                    noDataIndication={" "}
                    pagination={
                      tableData.data.length > 10
                        ? hasNestedValue(field, "type", "checkbox") &&
                          styleSheet?.compact
                          ? null
                          : getPagination()
                        : null
                    }
                    striped
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  } else return null;
};

export default withNamespaces()(CreateBootstrapTable);
