import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import widgetplaceholder from '../../assets/img/widgets/widget-placeholder.jpg';
import '../../assets/css/styles.css';
import '../../assets/css/icons.css';
import { withNamespaces } from 'react-i18next';

const NoAccessModal = (props) => {

  const {
    t,
    selectedApp,
    show,
    handleClose
  } = props;

  return (
    <Modal show={show} onHide={handleClose}  centered>
      <Modal.Header closeButton>
        <Modal.Title> {t('puzzle.noaccess')} {selectedApp && selectedApp.application.description} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <img className="rounded img-fluid float-left" src={widgetplaceholder} alt="Widget" />
          </div>
          <div className="col-6">
            <small className="text-justify">Por favor contacte <a href="mailto:suporte@uniksystem.pt">suporte@uniksystem.pt</a></small>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancelar
          </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default withNamespaces()(NoAccessModal)