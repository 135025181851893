import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
import CustomDropdown from "./CustomDropdown";
import "../../assets/css/icons.css";
import "../../assets/css/styles.css";
import { updateUserWidget } from '~/pages/User/actions';

import { withNamespaces } from "react-i18next";

const CardEarningMonthly = ({ apagarWidget,widget,handleMouseDown,isResizing,textMove, textClick, ...props }) => {
  const { t } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (widget.userConfiguration == null) {
       const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6"
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-bell mr-2" /> {t("CardEarningMonthly.title")}
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown apagarWidget={apagarWidget} handleMouseDown={handleMouseDown} />
        </Card.Header>
        {!isResizing && (<Card.Body>
          <Card.Text>$40,000</Card.Text>
        </Card.Body>)}
      </Card>
    </div>
  );
};
export default withNamespaces()(CardEarningMonthly);
