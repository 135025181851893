import React from "react";
import { useSelector } from "react-redux";

import AccessDenied from "./components/AccessDenied";
import { hasRoles, hasPermissions } from "./utils";

/*
  Usage example:

  <ProtectedPage
    roles={[...]}
    permissions={[...]}
  >
    <>{...}</>
  </ProtectedPage>
*/

const ProtectedPage = (props) => {

  const { user, isLoadingUser } = useSelector(state => state.globalReducer);

  const {
    children,
    roles = [],
    permissions = []
  } = props;

  return (
    <>
      {
        (hasRoles(user, roles) && hasPermissions(user, permissions)) &&
        { ...children }
      }
      {
        (!hasRoles(user, roles) || !hasPermissions(user, permissions)) &&
        !isLoadingUser &&
        <AccessDenied />
      }
    </>
  )

}

export default ProtectedPage;
