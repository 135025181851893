import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import uuid from "uuid/v1";
import "../assets/css/styles.css";
import "../assets/scss/_backOfficeRequestDocumentSection.scss";
import BackOfficeCheckbox from "./BackOfficeCheckbox";
import Breadcrumb from "./Breadcrumb";
import Button from "./Button";
import Input from "./Input";
import InputSearch from "./InputSearch";
import InputSearchChips from "./InputSearchChips";
import { getAllUsers ,getUser,updateUser} from "~/store/ducks/onboarding/actionTypes";
import SimpleInput from "./SimpleInput";

const BackOfficeRequestDocumentSection = ({ id, t }) => {

  const dispatch = useDispatch();

  const { user , users} = useSelector((state) => state.onboardingReducer);
  const [docToAdd, setDocToAdd] = useState("");
  const [leadsList, setLeadsList] = useState([]);
  const [namesList, setNamesList] = useState([]);
  const [singleName, setSingleName] = useState([]);
  const history = useHistory();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [test, setTest] = useState(true);
  const [newUser, setNewUser] = useState(false);
  const [checkboxList, setCheckboxList] = useState([]);
  const [chipsArrays, setChipsArrays] = useState([[]]);
  const [showAddDocument, setShowAddDocument] = useState(false);
  function getBackgorundColor(index) {
    if (index % 2 === 0) {
      return "#F6F6F6";
    } else {
      return "white";
    }
  }

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    console.log(user)
    if(newUser && user)
    {
      setNewUser(false)
      var newArray = [];
      if(user.legalRepresentation)
      {
        for (var i = 0; i < user.legalRepresentation.length; i++) {
          newArray.push({
            label: user.legalRepresentation[i].nome,
            value: user.legalRepresentation[i].nome,
            type : "RL",
            selected: false,
          });
        }
        for (var i = 0; i < user.effectiveBeneficiary.length; i++) {
          var canAdd = true
          for(var k = 0; k < user.legalRepresentation.length;k++)
          {
            if(user.effectiveBeneficiary[i].nif === user.legalRepresentation[k].nif)
              canAdd = false
          }
          if(canAdd)
          newArray.push({
            label: user.effectiveBeneficiary[i].nome,
            value: user.effectiveBeneficiary[i].nome,
            type : "BE",
            selected: false,
          });
          
        }
      }
      console.log(user)
      const singleArray = []
      if(user.client.nome != "")
      singleArray.push({
        label: user.client.nome,
        value: user.client.nome,
        type : "SL",
        selected: false,
      });
      setSingleName(singleArray)
      setNamesList(newArray)
    }
  }, [user]);

  useEffect(() => {
    var newUsersArray = [];
    for (var i = 0; i < users.length; i++) {
      if(users[i].account.id)
      newUsersArray.push({
        label: users[i].account?.pid?.toString(),
        value: users[i].account?.pid?.toString(),
        selected: false,
      });
    }
    setLeadsList(newUsersArray)
  },[users]);


  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const breadcrumbItems = [
    {
      text: t(`bpm.onboarding.backoffice.user.labelGoBack`),
      link: `uniksystem/onboarding/backoffic-organization/onboarding/${id}`,
    },
    {
      text: t(`bpm.onboarding.backoffice.user.labelRequestNewDoc`),
      link: "",
    },
  ];

  function handleSelectChange(e,type, index) {
    var currentType = ""

    if(type === undefined || type === null)
      return
    if(type != "SL")
      currentType = type
    var newArray = chipsArrays[index];
    console.log(type)
    newArray = [...newArray, {value : e, type : currentType}];
    const array = chipsArrays;
    if (chipsArrays[index]?.value?.includes(e) != true) {
      array[index] = newArray;
      setChipsArrays(array);
      setTest(!test);
    }
  }

  function handleSelectChangeMain(value) {
    dispatch(getUser(parseInt(value)));
    setNewUser(true)
  }

  const [docsList, setDocsList] = useState([
    "Cartão de Cidadão",
    "Passaporte",
    "Comprovativo IBAN",
    "Comprovativo de Morada",
    "Declaração de Beneficiários",
    "Comprovativo Abertura de Atividade",
    "Informação Empresarial Simplificada",
    "Cópia de Estatutos",
  ]);

  useEffect(() => {
    if (isFirstTime) {
      var array = new Array(docsList.length).fill(0);
      setCheckboxList(array);
      setIsFirstTime(false);
      array = new Array(docsList.length).fill([]);
      setChipsArrays(array);
    }
  });

  function checkboxChecked(index) {
    var newArray = checkboxList;
    if (newArray[index] === 0) {
      newArray[index] = 1;
    } else {
      newArray[index] = 0;
    }

    setCheckboxList(newArray);
    setTest(!test);
  }

  function removeChipFromArray(text, index) {
    var newArray = chipsArrays[index];
    var newIndex = newArray.indexOf(text);
    if (newIndex !== -1) {
      newArray.splice(newIndex, 1);
      var array = chipsArrays;
      array[index] = newArray;
      setChipsArrays(array);
      setTest(!test);
    }
  }

  let isEmpty = (a) => Array.isArray(a) && a.every(isEmpty);

  function addDocs() {
    const newEmptyArray = []

    for(var i = 0; i < chipsArrays.length; i++)
    {
      newEmptyArray.push([])
      if(chipsArrays[i].length > 0)
      {
        for(var k = 0; k < chipsArrays[i].length; k++)
        {
          user.documents.push({
            createdDate: null,
            deleted: 0,
            descDocumento: chipsArrays[i][k].value,
            easypayId: null,
            emitted: null,
            estadoDocumento: 0,
            expired: null,
            ibanMorada: null,
            ibanNome: user.client.nome,
            ibanNumero: null,
            ibanSwift: null,
            idCliente: user.account.id,
            idCoresystem: null,
            isPDA: 0,
            mimeType: null,
            modifiedDate: null,
            nomeCompleto: docsList[i],
            nomeDocumento: null,
            submited: "0",
            tipoDocumento: chipsArrays[i][k].type
          })
        }
      }
    }

    for(var i = 0; i < user.documents.length;i++)
    {
      if(user.documents[i].nomeDocumento === "" || user.documents[i].nomeDocumento === null || user.documents[i].nomeDocumento === undefined)
      {
        if(user.documents[i].tipoDocumento === "" || user.documents[i].tipoDocumento === null || user.documents[i].tipoDocumento === undefined)
        {
          user.account.estadoLead = "DOC"
          break;
        } else if(user.documents[i].tipoDocumento === "RL" && user.account.estadoLead != "DOC")
        {
          user.account.estadoLead = "RL"
          break;
        } else if(user.documents[i].tipoDocumento === "BE" && user.account.estadoLead != "RL" && user.account.estadoLead != "DOC")    
        {
          user.account.estadoLead = "BE"
          break;
        }
      }
    }


    dispatch(updateUser({user : user, pid : user.account.pid}));
    setChipsArrays(newEmptyArray) 
  }

  function handleBlurInputs(name, e) {
    setDocToAdd(e.target.value)
  }

  function addNewDocType()
  {
    var newDocNameArray = docsList
    newDocNameArray = [...newDocNameArray,docToAdd]

    var newChipsArray = chipsArrays
    newChipsArray = [...newChipsArray,""]

    var newCheckboxList = checkboxList
    newCheckboxList = [...newCheckboxList,0]

    setDocsList(newDocNameArray)
    setChipsArrays(newChipsArray)
    setCheckboxList(newCheckboxList)

  }

  return (
    <div className="easypay-backoffice-document-request-section">
      <div className="easypay-backoffice-document-request-header">
        <div>
          <Breadcrumb breadcrumbStates={breadcrumbItems}></Breadcrumb>
          <div className="easypay-backoffice-text-header-title">
            {t(`bpm.onboarding.backoffice.user.labelRequestDocuments`)}
          </div>
          <InputSearch
            field={{ text: "inputLabel" }}
            required={true}
            text={t(`bpm.onboarding.backoffice.user.labelIDLeadClient`)}
            placeholder={t(`bpm.onboarding.backoffice.user.labelIndicateID`)}
            list={leadsList}
            handleSubmit={(value) => handleSelectChangeMain(value)}
          />
        </div>
      </div>
      <div className="easypay-backoffice-request-document-section-content-wrapper">
        <div className="easypay-backoffice-header">
          {t(`bpm.onboarding.backoffice.user.labelChooseDocumentsToRequest`)}
        </div>
        {docsList &&
          Object.keys(docsList).length !== 0 &&
          docsList.map((doc, index) => {
            return (
              <div
                className="easypay-backoffice-request-document-table-item-wrapper"
                style={{ background: getBackgorundColor(index) }}
              >
                <BackOfficeCheckbox
                  name={doc}
                  id={index}
                  value={doc}
                  onclick={() => {
                    checkboxChecked(index);
                  }}
                />
                {checkboxList[index] === 1 && (
                  <div className="easypay-backoffice-small-input-wrapper-fixed">
                    <InputSearchChips
                      field={{ text: "inputLabel" }}
                      required={true}
                      chipsArray={chipsArrays[index]}
                      handleRemoveChip={removeChipFromArray}
                      text={t(`bpm.onboarding.backoffice.user.labelNames`)}
                      index={index}
                      placeholder={t(
                        `bpm.onboarding.backoffice.user.labelChooseNames`
                      )}
                      list={index === 0 || index === 1 || index === 3 ? namesList : singleName}
                      handleSubmit={(value,type) => handleSelectChange(value,type, index)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        {showAddDocument && (
          <div className="easypay-backoffice-requst-document-add-document-wrapper">
            <div className="easypay-backoffice-request-document-input-wrapper">
              <SimpleInput
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.user.labelDocumentName`),
                  ""
                )}
                handleOnBlur={(e) => handleBlurInputs("name", e)}
              ></SimpleInput>
            </div>
            {false && <div className="easypay-backoffice-request-document-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.user.labelAdittionalInfo`),
                  ""
                )}
              ></Input>
            </div>}
            <Button
              text={t(`bpm.onboarding.backoffice.user.buttonAdd`)}
              variant={"easypay-button-primary"}
              onclick={() => {
                addNewDocType()
                setShowAddDocument(false);
              }}
              value="Go to Google"
              key={uuid()}
              id={addButtonField.id}
              className="card-button"
              align={"right"}
              disabledColored={false}
              disabled={false}
              field={addButtonField}
            ></Button>
          </div>
        )}
        <div className="easypay-backoffice-request-document-buttons-wrapper">
          <Button
            text={t(`bpm.onboarding.backoffice.user.buttonAdd`)}
            variant={"easypay-button-add"}
            onclick={() => {
              setShowAddDocument(true);
            }}
            key={uuid()}
            id={addButtonField.id}
            className="card-button"
            align={"right"}
            disabledColored={false}
            disabled={false}
            field={addButtonField}
          ></Button>
          <div className="easypay-backoffice-button-wrapper">
            <Button
              text={t(`bpm.onboarding.backoffice.user.labelRequest`)}
              variant={"easypay-button-primary"}
              value="Go to OnboaridngSection"
              onclick={() =>
                addDocs()
              }
              key={uuid()}
              id={addButtonField.id}
              className="card-button"
              align={"right"}
              disabledColored={false}
              disabled={!checkboxList.includes(1) || isEmpty(chipsArrays)}
              field={addButtonField}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(BackOfficeRequestDocumentSection);
