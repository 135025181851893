export const getIcon = (label) => {
  switch (label) {
    case "uniksystem":
      return "icon-dashboard puzzle-item";
    case "rh":
      return "icon-hr puzzle-item";
    case "bpm":
      return "icon-bpm puzzle-item";
    case "compliance":
      return "icon-compliance puzzle-item";
    case "gdpr":
      return "icon-gdpr puzzle-item";
    case "financeira":
      return "icon-finance puzzle-item";
    case "gai":
      return "icon-gai puzzle-item";
    case "globalsolver":
      return "icon-global-solver puzzle-item";
    case "datacapture":
      return "icon-data-capture puzzle-item";
    case "adatacapture":
      return "icon-data-capture puzzle-item";
    case "validprocs":
      return "icon-data-capture puzzle-item";
    case "portalrh":
      return "icon-hr puzzle-item";
    case "onboarding":
      return "icon-hr puzzle-item";
    case "easypay":
      return "icon-finance puzzle-item";
    case "budgetmgr":
      return "icon-finance puzzle-item";
    case "claimsmgt":
      return "icon-finance puzzle-item";
    default:
      return "";
  }
};

export const getLocation = (label) => {
  switch (label) {
    case "uniksystem":
      return {
        pathname: "/uniksystem",
        state: { name: "Uniksystem", icon: "icon-unik3 mr-2" },
      };
    case "rh":
      return {
        pathname: "/uniksystem/rh",
        state: { name: "Dashboard", icon: "icon-hr mr-2" },
      };
    case "bpm":
      return {
        pathname: "/uniksystem/bpm",
        state: { name: "Dashboard", icon: "icon-bpm mr-2" },
      };
    case "compliance":
      return {
        pathname: "/uniksystem/compliance",
        state: { name: "Dashboard", icon: "icon-compliance mr-2" },
      };
    case "gdpr":
      return {
        pathname: "/uniksystem/gdpr",
        state: { name: "Dashboard", icon: "icon-gdpr mr-2" },
      };
    case "financeira":
      return {
        pathname: "/uniksystem/financeira",
        state: { name: "Dashboard", icon: "icon-finance mr-2" },
      };
    case "gai":
      return {
        pathname: "/uniksystem/gai",
        state: { name: "Dashboard", icon: "icon-gai mr-2" },
      };
    case "globalsolver":
      return {
        pathname: "/uniksystem/globalsolver",
        state: { name: "Global solver", icon: "icon-global-solver mr-2" },
      };
    case "datacapture":
      return {
        pathname: "/uniksystem/datacapture",
        state: { name: "DataCapture", icon: "icon-data-capture mr-2" },
      };
    case "adatacapture":
      return {
        pathname: "/uniksystem/adatacapture",
        state: {
          name: "Automated DataCapture",
          icon: "icon-data-capture mr-2",
        },
      };
    case "validprocs":
      return {
        pathname: "/uniksystem/validprocs",
        state: {
          name: "Validação de Processos",
          icon: "icon-data-capture mr-2",
        },
      };
    case "portalrh":
      return {
        pathname: "/uniksystem/portalrh",
        state: { name: "Portal RH", icon: "icon-hr mr-2" },
      };
    case "onboarding":
      return {
        pathname: "/uniksystem/onboarding",
        state: { name: "Onboarding", icon: "icon-hr mr-2" },
      };
    case "easypay":
      return {
        pathname: "/uniksystem/easypay",
        state: { name: "Easypay", icon: "icon-finance mr-2" },
      };
    case "budgetmgr":
      return {
        pathname: "/uniksystem/budgetmgr",
        state: { name: "Budget Management", icon: "icon-finance mr-2" },
      };
    case "claimsmgt":
      return {
        pathname: "/uniksystem/claimsmgt",
        state: { name: "Claims Management", icon: "icon-finance mr-2" },
      };
    default:
      return "";
  }
};

export const stubApp = {
  application: {
    label: "",
    description: "",
  },
  icon: "",
  active: true,
  hasAccess: true,
};

export const addToggle = () => {
  return {
    application: {
      label: "toggle",
      description: "Expandir",
    },
    icon: "icon-ellipsis puzzle-item",
    location: {
      pathname: "/uniksystem",
      state: { name: "Uniksystem", icon: "icon-unik3 mr-2" },
    },
    active: true,
    hasAccess: true,
  };
};

export const addStubApps = (apps_, isExpand) => {
  const remainder = (apps_.length + 1) % 4;
  let nrDisplayIcons_;
  if (isExpand) {
    if (apps_.length > 3 && remainder !== 0)
      //nrDisplayIcons_ = (apps.length) + (4 - remainder) + 4;
      nrDisplayIcons_ = apps_.length + (4 - remainder);
    //nrDisplayIcons_ = (apps.length) + (4 - remainder);
    else nrDisplayIcons_ = apps_.length;
  } else {
    if (remainder === 0) nrDisplayIcons_ = apps_.length;
    else nrDisplayIcons_ = apps_.length + (4 - remainder);
  }

  let items = [];
  for (let index = 0; index < nrDisplayIcons_ - apps_.length; index++) {
    items.push(stubApp);
  }
  return items;
};
