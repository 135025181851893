import React from 'react';
import oauth from '~/utils/oauth';
import uuid from 'uuid/v1';
import { useSelector } from 'react-redux';

const ProcessosIframe = (props) => {

    const { className, urlFormData } = props;

    const style = {
        frameborder: "0px",
        width: "100%",
        border: "none",
        display: "block",
        margin: className ? 0 : "10px"
    }
    const { currentFormUrl } = useSelector(state => state.globalReducer);
    const token = oauth.getAccessToken();
    const authtoken = "&Authorization=" + token;
    let url;
    if (urlFormData !== undefined) {
        if(urlFormData.includes('/iFlow/')){
      const startIndex = urlFormData.indexOf('/iFlow/');
      if (startIndex !== -1) {
        url = '/iFlow/' + urlFormData.substring(startIndex + 7) + authtoken;
      }
    } else  url = '/iFlow/' + urlFormData + authtoken;
    } else {
      url = '/iFlow/' + currentFormUrl + authtoken;
    }
    console.log(url)
    return (
        currentFormUrl ? <iframe
            key={uuid()}
            id="processos"
            title="processos"
            src={url}
            style={style} />
            :
            <div></div>
    )
}
export default ProcessosIframe