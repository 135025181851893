import { reject } from "q";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";

import {
  setBudgetmgrConfiguration,
  clearBudgetmgrConfiguration,
  sucessesPersistBudgetmgrConfiguration,
} from "./actionTypes";

export function* getBudgetmgrConfiguration({ organizationId }) {
  try {
    yield put(clearBudgetmgrConfiguration());
    const resp = yield http.get(
      `/api/budgetmgr-configuration/find-by-organization/${organizationId}`
    );
    yield put(setBudgetmgrConfiguration(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistBudgetmgrConfiguration({ configuration }) {
  try {
    yield put(clearBudgetmgrConfiguration());
    if (configuration && configuration.id) {
      yield http.put("/api/budgetmgr-configuration/update", configuration);
    } else {
      yield http.post("/api/budgetmgr-configuration/create", configuration);
    }
    yield put(sucessesPersistBudgetmgrConfiguration());
  } catch (error) {
    reject(error);
  }
}

export function* deleteBudgetmgrConfiguration({ configurationId }) {
  try {
    yield http.delete(
      `/api/dbudgetmgr-configuration/delete/${configurationId}`
    );
    yield put(sucessesPersistBudgetmgrConfiguration());
  } catch (error) {
    reject(error);
  }
}
