import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import CreateForm from "~/components/CreateForm";
import { findMenuByApplicationName } from "~/store/ducks/applicationMenu/actionTypes";
import { getIflowFormdata } from "~/store/ducks/processes/actionTypes";
import oauth from "~/utils/oauth";

import "~/assets/css/styles.css";
import "~/assets/css/icons.css";

const ProcessDetailExternal = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const { formdata } = useSelector((state) => state.processesReducer);

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
  }, []);

  useEffect(() => {
    /*example:
    https://unikint.uniksystem.pt/procdetail/cma/iFlow/Form/detail.jsp?
    flowid=328&pid=8256&subpid=1&procStatus=0&fwSearch=true&
    Authorization=1f92dfe1-957f-457d-aa59-2dd0f7bbc0e9*/
    if (window.location.href.includes("iFlow/Form/detail.jsp")) {
      const str = window.location.href.split("procdetail/")[1];
      const arr = str.split("/");
      if (arr[0] && arr[0] !== "iFlow") {
        console.log(arr[0]);
        document.getElementsByTagName("body")[0].className = arr[0];
      }
      const srcStr = str.split("iFlow/")[1];
      if (srcStr) {
        dispatch(getIflowFormdata({ url: srcStr }));
      }
    }

    if (window.location.href.includes("Authorization")) {
      const str = window.location.href.split("Authorization=")[1];
      const access_token = str.split("&")[0];
      if (access_token) {
        oauth.setToken(
          {
            access_token,
            refresh_token: "",
            expires_in: "",
          },
          true
        );
      }
    }

    dispatch(findMenuByApplicationName("bpm"));
  }, [window.location.href]);

  useEffect(() => {
    if (formdata) myRef.current.scrollIntoView();
  }, [formdata]);

  return (
    <div
      ref={myRef}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <CreateForm
        jsonData={formdata}
        isModal={false}
        className="main-card-v2"
        isExternalPage={true}
      />
    </div>
  );
};
export default withNamespaces()(ProcessDetailExternal);
