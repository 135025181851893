import React, { useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { useSelector } from "react-redux";
import { generateTreeMenu } from "~/containers/Menus_v2/utils";
import { NavLink } from "react-router-dom";

const Sidebar = (props) => {
  const { menuSideBar, path } = props;
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const treeMenu = generateTreeMenu(menu);
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const handleMenuItemClick = (index,child) => {
    console.log(child)
    setActiveMenuItem(index);
  };

  return (
    <>
      {menuSideBar?.children && (
        <div style={{ display: "flex", overflow: "scroll initial" }}>
           <CDBSidebar
            textColor="#1e0363"
            backgroundColor="#e7e7e7"
            style={{ width: "200px", minWidth: "200px" }}
          >
            <CDBSidebarHeader>
              <a
                className="text-decoration-none"
                style={{ color: "inherit", all: "unset" }}
              >
                {menuSideBar?.designacao}
              </a>
            </CDBSidebarHeader>

            <CDBSidebarContent className="sidebar-content">
              <CDBSidebarMenu>
                {menuSideBar?.children?.map((child, index) => (
                  // <NavLink
                  //   exact
                  //   to={`${path}${child.route}`}
                  //   key={index}
                  //   onClick={() => handleMenuItemClick(index)}
                  // >
                  <NavLink
                  exact
                  to={`${path}/personalArea/myInformations`} 
                  key={index}
                  onClick={() => handleMenuItemClick(index, child)}
                >
                    <CDBSidebarMenuItem
                      className={`sidebarClass ${activeMenuItem === index ? "active" : ""}`}
                    >
                      {child.designacao}
                    </CDBSidebarMenuItem>
                  </NavLink>
                ))}
              </CDBSidebarMenu>
            </CDBSidebarContent>
          </CDBSidebar> 
        </div>
      )}
    </>
  );
};

export default Sidebar;
