import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ButtonDownload from "~/components/Buttons/DownloadButton";

import { toLocaleString } from "~/utils";
import { withNamespaces } from "react-i18next";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { getColumnFormatter } from "./columnFormatter";
import CreateActionColumn from "./CreateActionColumn";
import pagination from "~/components/pagination/currentSizePerPage/10";
import { findUDWUsers } from "~/store/ducks/udw/actionTypes";
import "~/assets/css/icons.css";

const ProcessTable = (props) => {
  const { t, handleOpenFlowModal, flowId, app } = props;

  const dispatch = useDispatch();

  const { processes } = useSelector((state) => state.processesReducer);
  const { udwUsers } = useSelector((state) => state.udwReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const [field, setField] = useState(undefined);
  const [order, setOrder] = useState(undefined);

  const handleStatusSort = (field) => {
    setOrder((order) => (order === "desc" ? "asc" : "desc"));
    setField(field);
  };

  const [flowColumns, setFlowColumns] = useState(undefined);
  const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);
  /*Get flow Columns defined in Extra Configuration*/
  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/pesquisar` || item.route === `${app}/search`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        let flowColumns_;
        let hiddenDefaults_;
        if (flowId && extraConfiguration_[flowId]) {
          flowColumns_ = extraConfiguration_[flowId].columns; //columns for a specific flow
          hiddenDefaults_ = extraConfiguration_[flowId].hiddenDefaults; //hidden default columns for a specific flow
        } else {
          flowColumns_ = extraConfiguration_.defaultExtraColumns; //extra columns for all flows
          hiddenDefaults_ = extraConfiguration_.hiddenDefaults; //hidden default columns for all flows
        }
        setFlowColumns(flowColumns_ ? flowColumns_ : undefined);
        setHiddenDeafultColumns(hiddenDefaults_ ? hiddenDefaults_ : undefined);
      }
    }
  }, [menu, flowId]);

  const getColumns = (columns) => {
    const getCSVFormatter = (cell, row, rowIndex, formatExtraData) => {
      if (formatExtraData.value === "SLA")
        return getColumnFormatter(cell, row, rowIndex, formatExtraData).replace(
          /,/g,
          ""
        );
      else
        return row[formatExtraData.value] !== null &&
          row[formatExtraData.value] !== undefined
          ? row[formatExtraData.value]
          : "";
    };
    return columns.map((column) => {
      if (column === "PROCESS_STATUS")
        return {
          dataField: "status",
          text: t(`taskPage.general.${column}`),
          sort: true,
          formatter: getColumnFormatter,
          formatExtraData: {
            value: column,
          },
          headerStyle: () => {
            return { width: "190px" };
          },
          onSort: () => handleStatusSort("PROCESS_STATUS"),
          csvFormatter: (cell, row, rowIndex) => {
            return row.PROCESS_STATUS !== null &&
              row.PROCESS_STATUS !== undefined
              ? row.PROCESS_STATUS
              : "";
          },
        };
      else if (column === "TTL") {
        return {
          dataField: column,
          text: t(`taskPage.general.${column}`),
          sort: true,
          formatter: getColumnFormatter,
          formatExtraData: {
            value: column,
          },
          style: {
            overflowWrap: "break-word",
          },
          onSort: () => handleStatusSort("created"),
        };
      } else
        return {
          dataField: column,
          text: t(`taskPage.general.${column}`),
          sort: true,
          formatter: getColumnFormatter,
          formatExtraData: {
            value: column,
          },
          style: {
            overflowWrap: "break-word",
          },
          csvFormatter: getCSVFormatter,
        };
    });
  };

  const isHidden = (column) => {
    if (hiddenDefaultColumns) {
      return hiddenDefaultColumns.some((c) => c === column) ? true : false;
    } else return false;
  };


  const columns = [
    {
      dataField: "initials",
      text: t("taskPage.general.flow"),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "initials",
      },
      onSort: () => handleStatusSort("initials"),
      hidden:isHidden("initials"),
      headerStyle: () => {
        return {
          width: "80px",
        };
      },
      style: {
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "pnumber",
      text: t("taskPage.general.process"),
      sort: true,
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "pnumber",
      },
      hidden: isHidden("pnumber"),
      csvExport: !isHidden("pnumber"),
      //csvType: Number,
      headerStyle: () => {
        return { width: "160px", };
      },
    },
    {
      dataField: "description",
      text: t("taskPage.general.description"),
      formatter: getColumnFormatter,
      formatExtraData: {
        value: "description",
      },
      sort: true,
      onSort: () => handleStatusSort("description"),
      hidden: isHidden("description"),
      headerStyle: () => {
        return {
          width: "auto",
        };
      },
      style: {
        maxWidth: "1px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    {
      dataField: "created",
      text: t("validprocs.general.sendDate"),
      sort: true,
      hidden: isHidden("created"),
      csvExport: !isHidden("created"),
      formatter: (_, row, {}) => toLocaleString(row.created),
      csvText: "Data de entrada",
      csvFormatter: (cell, row, rowIndex) =>
        toLocaleString(row.created).replace(/,/g, ""),
      headerStyle: () => {
        return { width: "160px", };
      },
    },
    {
      dataField: "updated",
      text: t("validprocs.general.ultima_acao"),
      sort: true,
      hidden: isHidden("updated"),
      csvExport: !isHidden("updated"),
      formatter: (_, row, {}) => row && toLocaleString(row.updated),
      csvText: "Data última ação",
      csvFormatter: (cell, row, rowIndex) =>
        toLocaleString(row.updated).replace(/,/g, ""),
      headerStyle: () => {
        return { width: "160px", };
      },
    },
    {
      dataField: "PROCESS_STATUS",
      text: "process_status",
      sort: true,
      hidden: true,
      csvExport: false,
    },
  ];
  if (flowColumns) {
    getColumns(flowColumns).forEach((column, index) =>
      columns.splice(1 + index, 0, column)
    );
  }

  /*Find UDW Users for column udwUser*/
  useEffect(() => {
    if (flowColumns && flowColumns.some((column) => column === "from")) {
      if (processes) {
        let payload = [];
        processes.forEach((process) => {
          if (processes.from !== "" && processes.from !== undefined)
            if (!payload.some((item) => item.username === process.from))
              if (process.from !== null)
                payload.push({
                  username: process.from,
                  legacyApplication: {
                    label: BPM_MIDDLEWARE.context,
                  },
                });
        });
        if (payload.length !== 0) dispatch(findUDWUsers(payload));
      }
    }
  }, [processes, dispatch, flowColumns]);

  const [processesToRender, setProcessesToRender] = useState([]);
  useEffect(() => {
    let processes_ = [];
    if (processes) {
      processes_ = processes.map((process) => {
        process.metadata.map((item) => {
          process[item.name] = item.value;
          return undefined;
        });
        return process;
      });
    }
    if (udwUsers) {
      processes_.forEach((p) => {
        const udwUser = udwUsers.find(
          (user) => user.legacyUser.username === p.from
        );
        if (udwUser) {
          p.from = udwUser.username;
        }
      });
    }
    setProcessesToRender(processes_);
  }, [processes, udwUsers]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div style={{ paddingLeft: "15px" }}>
        <ButtonDownload
          text={t("taskPage.general.exportCSV")}
          onClick={handleClick}
        />
      </div>
    );
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();
      e.preventDefault();
      handleOpenFlowModal(row);
    },
  };

  const { SearchBar } = Search;
  return (
    <div className="custom-table">
      <ToolkitProvider
        keyField="pid"
        data={processesToRender}
        columns={columns}
        search
        exportCSV={{
          fileName: "process.csv",
          separator: ";",
          noAutoBOM: true,
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <>
            <div style={{ display: "flex" }}>
              <SearchBar {...props.searchProps} />
              <MyExportCSV {...props.csvProps} />
            </div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              pagination={paginationFactory(pagination)}
              noDataIndication={t("taskPage.general.processesNotFound")}
              sort={{
                dataField: field,
                order: order,
              }}
              rowEvents={rowEvents}
              striped
              hover
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};
export default withNamespaces()(ProcessTable);
