
import { findIndex } from "lodash";

import {
  LOAD_MENU_ONLY_APPS,
  SET_MENU_ONLY_APPS,
  SET_SELECTED_APPLICATION,
  CHANGE_LOGO_APPLICATION,
  SET_LOGO_APPLICATION,
  RESET_SAVED_LOGO
} from "./actionTypes";

const initialState = {
  menuOnlyApps: [],
  isLoadingMenuOnlyApplications: false,
  selectedApplication: undefined,
  savedLogo: undefined
}

export const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MENU_ONLY_APPS:
      return {
        ...state, isLoadingMenuOnlyApplications: true
      };
    case SET_MENU_ONLY_APPS:
      return {
        ...state,
        menuOnlyApps: action.menuOnlyApps,
        isLoadingMenuOnlyApplications: false
      };
    case SET_SELECTED_APPLICATION:
      return {
        ...state,
        selectedApplication: action.application
      };
    case CHANGE_LOGO_APPLICATION:
      let applications = state.menuOnlyApps;
      var index = findIndex(applications, { id: action.payload?.application?.id });
      let application = applications[index];
      application.isChanging = true;
      applications.splice(index, 1, application);
      return { ...state, menuOnlyApps: applications };
    case SET_LOGO_APPLICATION:
      let applications_ = state.menuOnlyApps;
      var index = findIndex(applications_, { id: action.application.id });
      let application_ = action.application;
      application_.isChanging = false;
      applications_.splice(index, 1, application_);
      return { ...state, menuOnlyApps: applications_, savedLogo: true };
    case RESET_SAVED_LOGO:
      return { ...state, savedLogo: initialState.savedLogo };
    default:
      return state;
  }
}
