import React from "react";
import { Col, Row } from "react-bootstrap";
import BPMComponent from "./BPMComponent";
import ComplianceComponent from "./ComplianceComponent";
import FinanceiraComponent from "./FinanceiraComponent";
import GAIComponent from "./GAIComponent";
import GDPRComponent from "./GDPRComponent";
import GlobalSolverComponent from "./GlobalSolverComponent";
import PortalRHComponent from "./PortalRHComponent";



const Applications = ({ tabKey }) => {
  return (
    <Row gutter={12}>
      <Col xs="12" sm="6" md="4" lg="3" x="3" style={{ marginTop: 15 }}>
        <BPMComponent tabKey={tabKey} />
      </Col>
      <Col xs="12" sm="6" md="4" lg="3" x="3" style={{ marginTop: 15 }}>
        <FinanceiraComponent tabKey={tabKey} />
      </Col>
      <Col xs="12" sm="6" md="4" lg="3" x="3" style={{ marginTop: 15 }}>
        <GlobalSolverComponent tabKey={tabKey} />
      </Col>
      <Col xs="12" sm="6" md="4" lg="3" x="3" style={{ marginTop: 15 }}>
        <GDPRComponent tabKey={tabKey} />
      </Col>
      <Col xs="12" sm="6" md="4" lg="3" x="3" style={{ marginTop: 15 }}>
        <ComplianceComponent tabKey={tabKey} />
      </Col>
      <Col xs="12" sm="6" md="4" lg="3" x="3" style={{ marginTop: 15 }}>
        <GAIComponent tabKey={tabKey} />
      </Col>
      <Col xs="12" sm="6" md="4" lg="3" x="3" style={{ marginTop: 15 }}>
        <PortalRHComponent tabKey={tabKey} />
      </Col>
    </Row>
  );
};

export default Applications;
