import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import ProtectedPage from "~/components/security/ProtectedPage";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import BackofficeUser from "~/pages/Onboarding/components/BackofficeUser";
import LeadsPage from "~/pages/Onboarding/components/BackofficeOrganization/Leads";
import OnboardingPage from "~/pages/Onboarding/components/BackofficeOrganization/Onboarding";
import DocumentsPage from "~/pages/Onboarding/components/BackofficeOrganization/Documents";
import OnboardingUser from "~/pages/Onboarding/components/OnboardingUser";
import OnboardingMainPage from "~/pages/Onboarding/components/OnboardingMainPage";
import IndividualLead from "~/pages/Onboarding/components/BackofficeOrganization/Leads/IndividualLead";
import IndividualOnboarding from "~/pages/Onboarding/components/BackofficeOrganization/Onboarding/IndividualOnboarding";
import AddNewDocument from "~/pages/Onboarding/components/BackofficeOrganization/AddNewDocument";
import DocumentPreview from "~/pages/Onboarding/components/BackofficeOrganization/DocumentPreview";

const Onboarding = () => {
  let { path } = useRouteMatch();

  const history = useHistory();
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const [application, setApplication] = useState(undefined);

  useEffect(() => {
    if (window.location.hash === "#/uniksystem/onboarding") {
      history.push("onboarding/integrations");
    }
  }, []);

  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) => item.route === "onboarding/integrations"
      );
      if (menuItem) {
        const extraConfiguration = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const flowid = extraConfiguration.flowid;
        const applicationName = extraConfiguration.applicationName;
        setApplication({ flowid, applicationName });
      }
    }
  }, [menu]);

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <ApplicationMenu applicationName="onboarding" />
          <ProtectedPage
            roles={[
              "ADMIN",
              "ONBOARDINGUser",
              "ONBOARDINGCompliance",
              "ONBOARDINGComercial",
            ]}
          >
            <div></div>
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/integrations`}>
          <ApplicationMenu applicationName="onboarding" />
          <ProtectedPage
            roles={[
              "ADMIN",
              "ONBOARDINGUser",
              "ONBOARDINGCompliance",
              "ONBOARDINGComercial",
            ]}
          >
            <OnboardingMainPage />
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/user`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "ONBOARDINGUser",
              "ONBOARDINGCompliance",
              "ONBOARDINGComercial",
            ]}
          >
            <OnboardingUser application={application} />
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/backoffic-user`}>
          <ProtectedPage
            application={application}
            roles={[
              "ADMIN",
              "ONBOARDINGUser",
              "ONBOARDINGCompliance",
              "ONBOARDINGComercial",
            ]}
          >
            <BackofficeUser application={application} />
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/backoffic-organization/leads`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "ONBOARDINGUser",
              "ONBOARDINGCompliance",
              "ONBOARDINGComercial",
            ]}
          >
            <LeadsPage application={application} />
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/backoffic-organization/onboarding`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "ONBOARDINGUser",
              "ONBOARDINGCompliance",
              "ONBOARDINGComercial",
            ]}
          >
            <OnboardingPage application={application} />
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/backoffic-organization/documents`}>
          <ProtectedPage
            roles={[
              "ADMIN",
              "ONBOARDINGUser",
              "ONBOARDINGCompliance",
              "ONBOARDINGComercial",
            ]}
          >
            <DocumentsPage application={application} />
          </ProtectedPage>
        </Route>

        <Route
          exact
          path={`${path}/backoffic-organization/onboarding/:pid`}
          component={IndividualOnboarding}
          application={application}
        />

        <Route
          exact
          path={`${path}/backoffic-organization/leads/:pid`}
          component={IndividualLead}
          application={application}
        />

        <Route
          exact
          path={`${path}/backoffic-organization/document-preview`}
          application={application}
        >
          <DocumentPreview />
        </Route>

        <Route
          exact
          path={`${path}/backoffic-organization/request-new-document`}
          application={application}
        >
          <AddNewDocument />
        </Route>
      </Switch>
    </>
  );
};

export default Onboarding;
