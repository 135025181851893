import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import Toast from "~/components/Toast";
import { Card, Button, Form, Col } from "react-bootstrap";
import logo from "~/assets/img/logos/unik-logo.png";

import {
  validateAdminCode,
  clearAdminCodeStatus,
} from "~/store/ducks/resetPassword/actionTypes";
import { useHistory } from "react-router-dom";

const InsertCode = (props) => {
  const { t } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [code, setCode] = useState("");
  const [validated, setValidated] = useState(false);

  const { adminCodeStatus, isLoadingValidateAdminCode } = useSelector(
    (state) => state.resetPasswordReducer
  );

  useEffect(() => {
    adminCodeStatus && history.push(`/reset-admin-password/${code}`);
    return () => {
      dispatch(clearAdminCodeStatus());
    };
  }, [adminCodeStatus]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      dispatch(validateAdminCode(code));
    }
  };

  return (
    <>
      <div className="overlay">
        <div className="row">
          <div className="col-12 mt-3 mb-2 text-center">
            <img className="img-fluid unik-logo" src={logo} alt="Uniksystem" />
          </div>
        </div>

        <Form.Row style={{ paddingTop: 20, color: "gray" }}>
          <Col lg="12" style={{ textAlign: "center" }}>
            <h1 className="display-2">{t("login.insertCode")}</h1>
          </Col>
        </Form.Row>
        <hr />

        <Card className="card-login">
          <Card.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <Form.Row>
                <Col lg="12">
                  <Form.Group controlId="insertCodeAdmin">
                    <h1 className="label-credentials">{t("login.code")}</h1>
                    <Form.Control
                      type="text"
                      required
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("login.requiredCode")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col lg="12">
                  <div className="buttons">
                    <Button
                      type="submit"
                      variant="outline-light"
                      className="login-button"
                      style={{ backgroundColor: "#33058d" }}
                    >
                      {isLoadingValidateAdminCode
                        ? t("login.loading")
                        : t("register.confirmEmail.confirm")}
                    </Button>
                  </div>
                </Col>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>
        <Toast />
      </div>
    </>
  );
};

export default withNamespaces()(InsertCode);
