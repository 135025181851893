import { takeLatest } from "redux-saga/effects";

import {
  MARK_AS_SEEN,
  MARK_ALL_AS_SEEN,
  DELETE_NOTIFICATION,
  GET_USER_PORTALRH_NOTIFICATIONS,
  GET_USER_BPM_NOTIFICATIONS,
  SAVE_NOTIFICATION,
} from "../ducks/notifications/actionTypes";

import {
  markAsSeen,
  markAllAsSeen,
  deleteNotification,
  getUserPortalRHNotifications,
  getUserBPMNotifications,
  saveNotification,
} from "../ducks/notifications/sagas";

export const notificationsSagas = [
  takeLatest(MARK_AS_SEEN, markAsSeen),
  takeLatest(MARK_ALL_AS_SEEN, markAllAsSeen),
  takeLatest(DELETE_NOTIFICATION, deleteNotification),
  takeLatest(GET_USER_PORTALRH_NOTIFICATIONS, getUserPortalRHNotifications),
  takeLatest(GET_USER_BPM_NOTIFICATIONS, getUserBPMNotifications),
  takeLatest(SAVE_NOTIFICATION, saveNotification),
];
