import React from "react";
import { useDispatch } from "react-redux";
import { Row, Form } from "react-bootstrap";
import { transformFormData } from "../utils";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";

import oauth from "~/utils/oauth";

const CreateLink = (props) => {
  const { field, isChild, children, styleSheet, translations, language } =
    props;
  const dispatch = useDispatch();

  const target = field.newwindow === "true" ? "_blank" : "_self";
  const windowName = field.newwindowname ? field.newwindowname : "Uniksystem";

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (field.href.includes("javascript:")) {
      /*handle custom links*/
      let str = field.href;
      str = str
        .replaceAll('value="', "value='")
        .replaceAll("value=", "value='")
        .replaceAll("''", "'");

      const allOpsArray = str.split("document.dados.");
      if (allOpsArray.length > 0) {
        const start = ".value='";
        const end = ['"', "'", ";"];
        allOpsArray.forEach((el, i) => {
          if (el.includes(start) && end.some((el_) => el.includes(el_))) {
            const part = el.split(start);
            const variable = part[0];
            let value = "";
            end.every((str) => {
              if (part[1].includes(str)) {
                value = part[1].split(str)[0];
                return false;
              }
              return true;
            });
            if (variable && value) {
              document.dados[variable].value = value;
            }
          }
        });
      }

      if (field.href.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postIflowFormData(formdata, url));
      }
    } else {
      //regular link
      let mw = window.open(field.href, target);
      mw.addEventListener("load", function () {
        mw.document.title = windowName;
      });
    }
  };

  let align = "center";
  if (field.align === "right") align = "flex-end";
  else if (field.align === "left") align = "flex-start";
  else if (field.align === "center") align = "center";
  const textButton = () => {
    let text = undefined;
    const textfield = field.text;
    if (textfield.b) {
      text = textfield.b;
    } else if (textfield.includes("::t::")) {
      let lingua = language.trim();
      let splittext = textfield.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if (translations) {
        text = translations?.[lingua][texttoshow] + texttoadd;
      }
    } else {
      text = textfield;
    }
    return text;
  };

  return (
    <>
      {styleSheet?.linkAsButtons ? (
        <button
          className="custom-button btn btn-outline-primary"
          style={{
            margin: "10px",
            padding: "0 5px 0 5px",
            minHeight: "30px",
          }}
          onClick={(e) => handleLinkClick(e)}
        >
          {textButton()}
        </button>
      ) : (
        <Form.Group as={Row} onClick={(e) => e.preventDefault()}>
          <Form.Label
            column
            className="iflow-form-label-fix"
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginLeft: isChild ? "10px" : "0px",
            }}
          >
            <button className="link" onClick={(e) => handleLinkClick(e)}>
              {textButton()}
            </button>
          </Form.Label>
          {children && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                flexGrow: "1",
              }}
            >
              {children}
            </div>
          )}
        </Form.Group>
      )}
    </>
  );
};
export default CreateLink;
