import React from "react";
import { useForm } from "react-hook-form";
import { withNamespaces } from "react-i18next";
import InputDate from "~/pages/Easypay/components/InputDate";
import InputSimpleSearch from "~/pages/Easypay/components/InputSimpleSearch";
import "../assets/scss/_backOfficeInput.scss";
import Dropdown from "../components/InputDropdown";

const BackOfficeInput = ({
  tableData,
  firstDropdownLabel,
  firstDropdownFilter,
  filterDate,
  filterCreatedBy,
  filterDepartment,
  filterPhase,
  filterResult,
  filterColor,
  onSetFilters,
  savedFilters,
  disabled,
  t,
}) => {
  const { register, formState, watch } = useForm();

  var timer;

  const dateDefaultValue = () => {
    if (!(savedFilters[2].value === "")) {
      return savedFilters[2].value;
    } else return ["", ""];
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  function numberToColorName(number)
  {
    if(number === "1")
    {
      return "Red"
    }
    else if(number === "2"){
      return "Yellow"
    }
    else{
      return "Green"
    }
  }

  //creates a list for a given input dropdown given a type and default input using tableData and the current filter settings
  const createInputList = (
    typeNames,
    tableData,
    filterNum,
    defaultInputLabel
  ) => {
    let result = [];
    let hasSelected = false;
    typeNames.forEach((typeName) => {
      tableData.forEach((dataRow) => {
        if (typeName?.split(".").length > 1) {
          const firstParameter = typeName?.split(".")[0];
          const secondParameter = typeName?.split(".")[1];
          if (
            dataRow[firstParameter][secondParameter] &&
            !result.some(
              (element) =>
                element.value.toString().toUpperCase() ===
                dataRow[firstParameter][secondParameter].toString().toUpperCase()
            )
          ) {
            if (
              dataRow[firstParameter][secondParameter].toString().toUpperCase() ===
              savedFilters[filterNum].value.toString().toUpperCase()
            ) {
              if(typeNames[0] != filterColor)
              {
                result.push({
                  label: capitalizeFirstLetter(
                    dataRow[firstParameter][secondParameter].toString()
                  ),
                  value: dataRow[firstParameter][secondParameter],
                  selected: true
                });
              }
              else
              {
                result.push({
                  label: numberToColorName(capitalizeFirstLetter(
                    dataRow[firstParameter][secondParameter].toString()
                  )),
                  value: dataRow[firstParameter][secondParameter],
                  selected: true,
                });
              }
              hasSelected = true;
            } else {
              if(typeNames[0] === filterColor)
              {
                result.push({
                  label: numberToColorName(capitalizeFirstLetter(
                    dataRow[firstParameter][secondParameter].toString()
                  )),
                  value: dataRow[firstParameter][secondParameter],
                });
              }
              else
              {
                result.push({
                  label: capitalizeFirstLetter(
                    dataRow[firstParameter][secondParameter].toString()
                  ),
                  value: dataRow[firstParameter][secondParameter],
                });
              }
            }
          }
        }
      });
    });
    if (!hasSelected) {
      result.push({ label: defaultInputLabel, value: "", selected: true });
    } else {
      result.push({ label: defaultInputLabel, value: "" });
    }
    return result;
  };

  const handleSubmitSearchWithDelay = (submitValue, delay) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      onSetFilters(
        {
          type: ["client.pid", "client.nome", "client.parceiro"],
          value: submitValue,
        }, // all types must be in an array
        0
      );
    }, delay);
  };

  return (
    <div className="input-grid">
      <div className="grid-search">
        <InputSimpleSearch
          field={{ text: "inputLabel" }}
          required={true}
          text={t("bpm.onboarding.backoffice.administration.inputSearch")}
          placeholder={t(
            "bpm.onboarding.backoffice.administration.inputSearchPlaceholder"
          )}
          defaultInput={savedFilters[0].value}
          handleSubmit={(submitValue) =>
            handleSubmitSearchWithDelay(submitValue, 500)
          }
          disabled={disabled}
          noBorder={true}
        />
      </div>
      <Dropdown
        noBorder={true}
        text={firstDropdownLabel}
        required={true}
        placeholder="Select Dropdown Option"
        list={createInputList(
          [firstDropdownFilter],
          tableData,
          1,
          t("bpm.onboarding.backoffice.administration.inputFemaleAll")
        )}
        handleSubmit={(submitValue) =>
          onSetFilters({ type: [firstDropdownFilter], value: submitValue }, 1)
        }
        disabled={disabled}
        isColor = {false}
      />
      <div>
        <InputDate
          text={t("bpm.onboarding.backoffice.administration.inputDate")}
          register={register}
          formState={formState}
          errorMessage="This field is required"
          watch={watch}
          type="created_at"
          placeholder=""
          defaultValue={dateDefaultValue()}
          handleSubmit={(dates) =>
            onSetFilters({ type: [filterDate], value: dates }, 2)
          }
          disabled={disabled}
        />
      </div>
      <Dropdown
        noBorder={true}
        text={t("bpm.onboarding.backoffice.administration.tableCreatedBy")}
        required={true}
        placeholder="Select Dropdown Option"
        list={createInputList(
          [filterCreatedBy],
          tableData,
          3,
          t("bpm.onboarding.backoffice.administration.inputMaleAll")
        )}
        handleSubmit={(submitValue) =>
          onSetFilters({ type: [filterCreatedBy], value: submitValue }, 3)
        }
        disabled={disabled}
        isColor = {false}
      />
      <Dropdown
        noBorder={true}
        text={t("bpm.onboarding.backoffice.administration.tableDepartment")}
        required={true}
        placeholder="Select Dropdown Option"
        list={createInputList(
          [filterDepartment],
          tableData,
          4,
          t("bpm.onboarding.backoffice.administration.inputMaleAll")
        )}
        handleSubmit={(submitValue) =>
          onSetFilters({ type: [filterDepartment], value: submitValue }, 4)
        }
        disabled={disabled}
        isColor = {false}
      />
      <Dropdown
        noBorder={true}
        text={t("bpm.onboarding.backoffice.administration.tableStage")}
        required={true}
        placeholder="Select Dropdown Option"
        list={createInputList(
          [filterPhase],
          tableData,
          5,
          t("bpm.onboarding.backoffice.administration.inputMaleAll")
        )}
        handleSubmit={(submitValue) =>
          onSetFilters({ type: [filterPhase], value: submitValue }, 5)
        }
        disabled={disabled}
        isColor = {false}
      />
      <Dropdown
        noBorder={true}
        text={t("bpm.onboarding.backoffice.administration.tableResult")}
        required={true}
        placeholder="Select Dropdown Option"
        list={createInputList(
          [filterResult],
          tableData,
          6,
          t("bpm.onboarding.backoffice.administration.inputMaleAll")
        )}
        handleSubmit={(submitValue) =>
          onSetFilters({ type: [filterResult], value: submitValue }, 6)
        }
        disabled={disabled}
        isColor = {false}
      />
      <Dropdown
        noBorder={true}
        text={t("bpm.onboarding.backoffice.administration.inputColor")}
        required={true}
        placeholder="Select Dropdown Option"
        list={createInputList(
          [filterColor],
          tableData,
          7,
          t("bpm.onboarding.backoffice.administration.inputMaleAll")
        )}
        isColor = {true}
        handleSubmit={(submitValue) =>
          onSetFilters({ type: [filterColor], value: submitValue }, 7)
        }
        disabled={disabled}
      />
    </div>
  );
};

export default withNamespaces()(BackOfficeInput);
