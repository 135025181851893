import { set } from "lodash";
import React, { useRef, useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

import "./fileInput.scss";

const FileInput = (props) => {
  const { t } = props;

  const [state, setState] = React.useState([]);

  const fileInput = useRef(null);

  // Function to handle uploading the selected files
  const handleUploadFiles = (files) => {
    // Create a copy of the existing state
    const uploaded = [...state];
    // Check if the file is already uploaded
    // If not, add it to the uploaded files array
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    if (uploaded.length > 0) {
      let fileList = document.getElementById(props.id);
      const dataTransfer = new DataTransfer();
      uploaded.forEach((file) => dataTransfer.items.add(file));
      fileList.files = dataTransfer.files;
    }
    setState(uploaded);
    if (props.onChange) props.onChange(uploaded);
  };

  // Function to handle the file input change event
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    if (props?.styleSheet?.inputFileShowFileList) {
      handleUploadFiles(chosenFiles);
    } else {
      setState(e.target.files);
    }
  };

  // Function to handle removing files from the selected files list
  const handleRemoveFiles = (file) => {
    if (file) {
      if (state.length > 0) {
        let fileListFiletered = Array.from(state).filter(
          (e) => e.name !== file
        );
        document.getElementById(props.id).value = "";

        if (fileListFiletered.length > 0) {
          let fileList = document.getElementById(props.id);
          const dataTransfer = new DataTransfer();
          fileListFiletered.forEach((file) => dataTransfer.items.add(file));
          fileList.files = dataTransfer.files;
        }

        setState(fileListFiletered);
        if (props.onChange) props.onChange(fileListFiletered);
      }
    }
  };

  return (
    <div
      className={
        props.disabled
          ? "custom-element-file-input-disabled"
          : props?.styleSheet?.inputFileShowFileList && state.length > 2
          ? "custom-element-file-input-fileList"
          : "custom-element-file-input"
      }
    >
      <input
        id={props.id}
        ref={fileInput}
        onChange={handleFileEvent}
        type="file"
        multiple={props.multiple}
        name={props.name}
        required={props.required}
      />
      <span
        className="custom-button btn btn-outline-primary"
        onClick={() => fileInput.current.click()}
      >
        {t(props.btnText ? props.btnText : "fileInput.button")}
      </span>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {props?.styleSheet?.inputFileShowFileList ? (
          state?.length > 0 ? (
            Array.from(state).map((e) => (
              <div>
                <span style={{ marginLeft: "15px" }} key={e.name}>
                  {e.name}
                </span>
                <i
                  className="icon-remove mt-2"
                  onClick={() => handleRemoveFiles(e.name)}
                ></i>
              </div>
            ))
          ) : (
            <span style={{ marginLeft: "15px" }}>
              Sem ficheiros selecionados
            </span>
          )
        ) : (
          <span style={{ marginLeft: "5px" }}>
            {state.length +
              " " +
              t(props.text ? props.text : "fileInput.selected")}
          </span>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(FileInput);
