import React from "react";
import { Button } from "react-bootstrap";
import downloadIcon from "~/assets/img/icons/icon-download.png";

const DownloadButton = ({ disabled, text, onClick, style }) => {
  return (
    <Button
    style={{marginLeft: style ?'10px': ""}}
      disabled={disabled}
      variant="outline-primary"
      onClick={onClick}
      className="custom-button"
    >
      {text}
      <img src={downloadIcon} style={{ marginLeft: "10px" }} alt="download" />
    </Button>
  );
};

export default DownloadButton;
