export const TasksStatus = {
  NOVO: 'Novo',
  OCR: 'OCR',
  VALIDAÇÃO_DOCUMENTAL: 'Validação Documental',
  DOCUMENTAÇÃO_INCOMPLETA: 'Documentação Incompleta',
  CONTROLO_DE_QUALIDADE: 'Controlo de Qualidade',
  VALIDAÇÃO_CONTRATUAL: 'Validação Contratual',
  APROVADO: 'Aprovado',
  REJEITADO: 'Rejeitado',
  VALIDAÇÃO_EM_CURSO: 'Validação em Curso',
  REVISÃO_EM_CURSO: 'Revisão em Curso',

  REGISTO_DE_UTILIZADOR: "Registo de Utilizador",
  REGISTO_INICIAL: "Registo Inicial"
}
