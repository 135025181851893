import React from "react";
import { withNamespaces } from "react-i18next";
import iconSuccess from "~/pages/Easypay/assets/img/icons/icon-checkmark.png";
import iconError from "~/pages/Easypay/assets/img/icons/icon-failed.png";
import iconWaiting from "~/pages/Easypay/assets/img/icons/icon-waiting.png";
import "../assets/scss/_provaDeVida.scss";
import "../assets/scss/_typography.scss";

function ProvaDeVida({ status, text, t }) {
  const handleStatus = () => {
    switch (status) {
      case "waiting":
        return (
          <div className="easypay-prova-de-vida-wrapper">
            <div className="easypay-prova-de-vida-icon">
              <img src={iconWaiting} alt="icon waiting" />
            </div>
            <div className="easypay-prova-de-vida-text">
              <div className="easypay-sub-header-3-warning">{t(text)}</div>
            </div>
          </div>
        );
      case "error":
        return (
          <div className="easypay-prova-de-vida-wrapper">
            <div className="easypay-prova-de-vida-icon">
              <img src={iconError} alt="icon error" />
            </div>
            <div className="easypay-prova-de-vida-text">
              <div className="easypay-sub-header-3-error">{t(text)}</div>
            </div>
          </div>
        );
      case "success":
        return (
          <div className="easypay-prova-de-vida-wrapper">
            <div className="easypay-prova-de-vida-icon">
              <img src={iconSuccess} alt="icon success" />
            </div>
            <div className="easypay-prova-de-vida-text">
              <div className="easypay-sub-header-3-success">{t(text)}</div>
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  return handleStatus();
}

export default withNamespaces()(ProvaDeVida);
