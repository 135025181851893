import {
  FIND_MFILES_BY_FILTERS,
  SET_MFILES,
} from '../actions';

const initialState = {
  mfiles: [],
  isLoadingMfiles: false,
}

export const mfilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_MFILES_BY_FILTERS:
      return { ...state, isLoadingMFiles: true }
    case SET_MFILES:
      return { ...state, isLoadingMFiles: false, mfiles: action.mfiles }
    default:
      return state
  }
}
