import React from "react";
import "../assets/scss/_initialDataSection.scss";
import "../assets/scss/_contentContainer.scss";
import CompanyDataAccordion from "./CompanyDataAaccordion";
import ClientDataAccordion from "./ClientDataAccordion";
import ProposalDataAccordion from "./ProposalDataAccordion";

const InitalDataSection = ({ items, workplanIndex }) => {

  return (
    <div className="liftworld-content-container">
      <div className="liftworld-data-section-wrapper">
        <CompanyDataAccordion items={items} workplanIndex={workplanIndex} />
        <ClientDataAccordion items={items} workplanIndex={workplanIndex} />
        <ProposalDataAccordion items={items} workplanIndex={workplanIndex} />
      </div>
    </div>
  );
};
export default InitalDataSection;
