import { truncate } from "lodash";
import {
  GET_ORGANIZATIONS,
  SET_ORGANIZATIONS,
  PERSIST_ORGANIZATION,
  PERSIST_ORGANIZATION_DONE,
  GET_ORGANIZATION_BY_ID,
  SET_ORGANIZATION,
  CLEAR_ORGANIZATIONS,
  CLEAR_ORGANIZATION,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_DONE,
} from "./actionTypes";

const initialState = {
  organizations: [],
  isLoadingOrganizations: false,
  organization: {},
  isLoadingOrganization: false,
  isPersisting: false,
  isDeleting: false,
};

export const organizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATIONS:
      return { ...state, isLoadingOrganizations: true };
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.organizations,
        isLoadingOrganizations: false,
      };
    case PERSIST_ORGANIZATION:
      return { ...state, isPersisting: true };
    case PERSIST_ORGANIZATION_DONE:
      return { ...state, isPersisting: false };
    case GET_ORGANIZATION_BY_ID:
      return { ...state, isLoadingOrganization: true };
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
        isLoadingOrganization: false,
      };
    case CLEAR_ORGANIZATIONS:
      return { ...state, organizations: initialState.organizations };
    case CLEAR_ORGANIZATION:
      return { ...state, organization: initialState.organization };
    case DELETE_ORGANIZATION:
      return { ...state, isDeleting: true };
    case DELETE_ORGANIZATION_DONE:
      return { ...state, isDeleting: false };
    default:
      return state;
  }
};
