import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../../../i18n";
import "../assets/scss/_replegmodal.scss";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";
import CustomButton from "./Button";
import Input from "./Input";
import Link from "./Link";
import Modal from "./Modal";
import RadioButtonItem from "./RadioButtonItem";
import Text from "./Text";
import SimpleInput from "./SimpleInput";
import { useDispatch } from "react-redux";
import { updateUser } from "~/store/ducks/onboarding/actionTypes";

function AddBenefModalContent({ title, isModalShowing, setIsModalShowing, t,user ,pid}) {
  var contents;
  const dispatch = useDispatch();
  const [activeTypeTab, setActiveTypeTab] = useState(
    t("bpm.onboarding.backoffice.user.labelIndividual")
  );
  const [activeDocTypeTab, setActiveDocTypeTab] = useState(
    t("bpm.onboarding.backoffice.user.labelCitizenCard")
  );

  useEffect(() => {}, [activeTypeTab, activeDocTypeTab]);

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='addBenef';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  const [benefName, setBenefName] = useState("");
  const [benefNif, setBenefNif] = useState("");
  const [capitalIndireto, setCapitalIndireto] = useState("");
  const [benefEmpresa, setBenefEmpresa] = useState("");
  const [benefMorada, setBenefMorada] = useState("");
  const [benefNumDoc, setBenefNumDoc] = useState("");
  const [capitalDireto, setCapitalDireto] = useState("");

  var newBenef = {
    idEntidade: null,
    nome: "",
    nif: "",
    morada: null,
    percentagem: "",
    pep: 0,
    tipoDocumento: null,
    numDocumento: null,
    cargo: "",
    createdDate: "",
    modifiedDate: "",
    capitalIndireto: null,
    empresa: null,
    pepDescricao: null,
  };

  function handleBlurInputs(name, e) {
    if (name === "name") {
      setBenefName(e.target.value);
    } else if (name === "nif") {
      setBenefNif(e.target.value);
    }  else if (name === "morada") {
      setBenefMorada(e.target.value);
    } 
    else if (name === "empresa") {
      setBenefEmpresa(e.target.value);
    } 
    else if (name === "capitalInd") {
      setCapitalIndireto(e.target.value);
    } 
    else if (name === "numDoc") {
      setBenefNumDoc(e.target.value);
    } 
    else if (name === "percentagem") {
      setCapitalDireto(e.target.value);
    } 
  }

  function addBenef()
  {
    newBenef.morada = benefMorada
    newBenef.nif = benefNif
    newBenef.nome = benefName
    newBenef.createdDate = new Date();
    newBenef.modifiedDate = new Date();
    newBenef.idEntidade = user.onboardingEntity.id
    newBenef.capitalIndireto = capitalIndireto
    newBenef.empresa = benefEmpresa
    newBenef.numDocumento = benefNumDoc
    newBenef.tipoDocumento = activeDocTypeTab
    newBenef.percentagem = capitalDireto

    user.documents.push({
      createdDate: null,
      deleted: 0,
      descDocumento: benefName,
      easypayId: null,
      emitted: null,
      estadoDocumento: 0,
      expired: null,
      ibanMorada: null,
      ibanNome: user.client.nome,
      ibanNumero: null,
      ibanSwift: null,
      idCliente: user.account.id,
      idCoresystem: null,
      isPDA: 0,
      mimeType: null,
      modifiedDate: null,
      nomeCompleto: "Documento de identificação",
      nomeDocumento: null,
      submited: "0",
      tipoDocumento: null
    })

    user.documents.push({
      createdDate: null,
      deleted: 0,
      descDocumento: benefName,
      easypayId: null,
      emitted: null,
      estadoDocumento: 0,
      expired: null,
      ibanMorada: null,
      ibanNome: user.client.nome,
      ibanNumero: null,
      ibanSwift: null,
      idCliente: user.account.id,
      idCoresystem: null,
      isPDA: 0,
      mimeType: null,
      modifiedDate: null,
      nomeCompleto: "Comprovativo de residência",
      nomeDocumento: null,
      submited: "0",
      tipoDocumento: null
    })

    if(user.account.estadoLead != "DOC" && user.account.estadoLead != "RL")
    {
      user.account.estadoLead = "BE"
    }

    user.effectiveBeneficiary.push(newBenef)

    dispatch(updateUser({user : user, pid : pid}));

    setIsModalShowing(false)

  }


  function createBody() {
    return (
      <div className="easypay-benef-modal-body-wrapper">
        <div>
          <div className="easypay-benef-modal-body-subtitle">
            {t(`bpm.onboarding.backoffice.user.modalTypeOfBenefLabel`)}
          </div>
          <div className="easypay-accordion-radio-button-wrapper" key={uuid()}>
            <BenefModalRadioButtonWrapper
              key={uuid()}
              variant={"easypay-radio-button-row"}
              activeTab={activeTypeTab}
              setActivetab={setActiveTypeTab}
            >
              <RadioButtonItem
                key={uuid()}
                text={t("bpm.onboarding.backoffice.user.labelIndividual")}
                index={0}
                uniqueName={"benefType"}
                name={t("bpm.onboarding.backoffice.user.labelIndividual")}
                subHeader={""}
              ></RadioButtonItem>
              <RadioButtonItem
                key={uuid()}
                text={t("bpm.onboarding.backoffice.user.labelCompany")}
                uniqueName={"benefType"}
                index={1}
                name={t("bpm.onboarding.backoffice.user.labelCompany")}
                subHeader={""}
              ></RadioButtonItem>
            </BenefModalRadioButtonWrapper>
          </div>
        </div>
        <div style={{ width: "500px" }}>
          <SimpleInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelName`),
              benefName
            )}
            key={"accordion_benef_nome"}
            handleOnBlur={(e) => handleBlurInputs("name", e)}
          ></SimpleInput>
        </div>
        <div style={{ width: "500px" }}>
          {activeTypeTab === "Empresa" ? (
            <SimpleInput
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelNIPC`),
                benefNif
              )}
              key={"accordion_benef_nif"}
              handleOnBlur={(e) => handleBlurInputs("nif", e)}
            ></SimpleInput>
          ) : (
            <SimpleInput
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelNIF`),
               benefNif
              )}
              key={"accordion_benef_nif"}
              handleOnBlur={(e) => handleBlurInputs("nif", e)}
            ></SimpleInput>
          )}
        </div>
        <div style={{ width: "180px" }}>
          <SimpleInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelDirectCapital`),
              capitalDireto
            )}
            key={"accordion_benef_capital"}
            handleOnBlur={(e) => handleBlurInputs("percentagem", e)}
          ></SimpleInput>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "500px",
          }}
        >
          <div style={{ width: "200px", paddingRight: "20px" }}>
            <SimpleInput
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelIndirectCapital`),
                capitalIndireto
              )}
              key={"accordion_benef_capitalind"}
              handleOnBlur={(e) => handleBlurInputs("capitalInd", e)}
            ></SimpleInput>
          </div>
          <div style={{ width: "300px" }}>
            <SimpleInput
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelCompany`),
                benefEmpresa
              )}
              key={"accordion_benef_empresa"}
              handleOnBlur={(e) => handleBlurInputs("empresa", e)}
            ></SimpleInput>
          </div>
        </div>
        <div style={{ width: "500px" }}>
          <SimpleInput
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelAddress`),
              benefMorada
            )}
            key={"accordion_benef_morada"}
            handleOnBlur={(e) => handleBlurInputs("morada", e)}
          ></SimpleInput>
        </div>
        {activeTypeTab ===
          t("bpm.onboarding.backoffice.user.labelIndividual") && (
          <div>
            <Text
              text={
                t(`bpm.onboarding.backoffice.user.labelIDDocument`) +
                "::card-text-no-padding"
              }
            />
            <div
              className="easypay-accordion-radio-button-wrapper"
              key={uuid()}
            >
              <BenefModalRadioButtonWrapper
                key={uuid()}
                variant={"easypay-radio-button-column"}
                activeTab={activeDocTypeTab}
                setActivetab={setActiveDocTypeTab}
                displayType={"column"}
              >
                <RadioButtonItem
                  key={uuid()}
                  uniqueName={"docType"}
                  text={t(`bpm.onboarding.backoffice.user.labelCitizenCard`)}
                  index={4}
                  name={t(`bpm.onboarding.backoffice.user.labelCitizenCard`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
                <RadioButtonItem
                  key={uuid()}
                  uniqueName={"docType"}
                  text={t(`bpm.onboarding.backoffice.user.labelPassport`)}
                  index={5}
                  name={t(`bpm.onboarding.backoffice.user.labelPassport`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
                <RadioButtonItem
                  key={uuid()}
                  uniqueName={"docType"}
                  text={t(`bpm.onboarding.backoffice.user.labelIdentityTicket`)}
                  index={6}
                  name={t(`bpm.onboarding.backoffice.user.labelIdentityTicket`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
                <RadioButtonItem
                  key={uuid()}
                  text={t(`bpm.onboarding.backoffice.user.labelResidenceTitle`)}
                  index={7}
                  uniqueName={"docTypeAccordion"}
                  name={t(`bpm.onboarding.backoffice.user.labelResidenceTitle`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
                <RadioButtonItem
                  key={uuid()}
                  text={t(`bpm.onboarding.backoffice.user.labelResidenceCard`)}
                  index={8}
                  uniqueName={"docTypeAccordion"}
                  name={t(`bpm.onboarding.backoffice.user.labelResidenceCard`)}
                  subHeader={""}
                  displayType={"column"}
                ></RadioButtonItem>
              </BenefModalRadioButtonWrapper>
            </div>
            <div style={{ width: "500px" }}>
              <SimpleInput
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.user.labelDocumentNumber`),
                  benefNumDoc
                )}
                key={"accordion_benef_numdocumento"}
                handleOnBlur={(e) => handleBlurInputs("numDoc", e)}
              ></SimpleInput>
            </div>
          </div>
        )}
        <div
          className="easypay-repleg-modal-footer-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <div
            className="easypay-repleg-modal-link-wrapper"
            style={{ paddingTop: "8px" }}
            onClick={(event) => {
              event.preventDefault();
              setIsModalShowing(false);
            }}
          >
            <Link
              text={t("bpm.onboarding.backoffice.user.buttonCancel")}
              disabledColored={false}
              disabled={false}
              link=""
              key={uuid()}
            />
          </div>
          <div
            className="easypay-repleg-modal-button-wrapper"
            style={{ paddingLeft: "25px" }}
          >
            <CustomButton
              text={t("bpm.onboarding.backoffice.user.buttonAdd")}
              variant={"easypay-button-primary"}
              onclick={addBenef}
              key={uuid()}
              id={addButtonField.id}
              className="card-button"
              align={addButtonField.align}
              disabledColored={false}
              disabled={false}
              field={addButtonField}
            />
          </div>
        </div>
      </div>
    );
  }

  contents = {
    modalTitle: title,
    modalBody: createBody(),
  };

  return (
    <Modal
      className="easypay-modal"
      showModal={isModalShowing}
      setShowModal={setIsModalShowing}
      headerContent={contents.modalTitle}
      bodyContent={contents.modalBody}
      key={uuid()}
    ></Modal>
  );
}

export default withNamespaces()(AddBenefModalContent);
