import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { updateUnikUserSettings } from "~/pages/User/actions";

const LanguageContainer = () => {
  const { unikUserSettings } = useSelector(
    (state) => state.userSettingsReducer
  );
  const dispatch = useDispatch();

  const [selectedLanguage, setSelectedlang] = useState(null);

  useEffect(() => {
    if (unikUserSettings && unikUserSettings.language)
      setSelectedlang(unikUserSettings.language);
  }, [unikUserSettings]);

  const handleSaveLanguage = (selectedlang) => {
    setSelectedlang(selectedlang);
    const payload = unikUserSettings;
    payload.language = selectedlang;
    dispatch(updateUnikUserSettings(payload));
  };

  const handleChecked = (language) => {
    return language === selectedLanguage ? true : false;
  };

  return (
    <div style={{ display: "flex", flexWrap: "nowrap" }}>
      <Form.Check
        type="switch"
        id="custom-switch-en"
        label="EN"
        checked={handleChecked("en")}
        onChange={() => handleSaveLanguage("en")}
      />
      <Form.Check
        type="switch"
        id="custom-switch-pt"
        label="PT"
        style={{ marginLeft: "20px" }}
        checked={handleChecked("pt")}
        onChange={() => handleSaveLanguage("pt")}
      />
      <Form.Check
        type="switch"
        id="custom-switch-es"
        label="ES"
        style={{ marginLeft: "20px" }}
        checked={handleChecked("es")}
        onChange={() => handleSaveLanguage("es")}
      />
    </div>
  );
};
export default withNamespaces()(LanguageContainer);
