import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import {
  getExportAbsenceLogs,
  getExportAbsenceData,
} from "~/store/ducks/portalrh/actionTypes";

const LogsTable = ({ t, manager }) => {
  const [dataBase64, setDataBase64] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExportAbsenceLogs());
    dispatch(getExportAbsenceData());
  }, []);

  const { absenceLogs, absenceData, isLoadingGetExportAbsenceData } =
    useSelector((state) => state.portalrhReducer);

  function dataFormatter(cell) {
    let aux = cell;
    if (cell !== null) {
      aux = cell?.split("T")[0];
    }
    return <div>{aux}</div>;
  }

  function hourFormatter(cell) {
    let aux = cell;
    if (cell !== null) {
      aux = cell + "h";
    }
    return <div>{aux}</div>;
  }

  const { SearchBar } = Search;

  const createALink = (cell, row, rowIndex, formatExtraData) => {
    const handleLink = () => {
      let data;
      for (let i = 0; i < absenceData.length; i++) {
        if (row?.idDocument === absenceData[i].id) {
          data = absenceData[i]?.data;
        }
      }

      if (data) {
        const text = base64ToText(data);
        const file = textToFile(text);
        downloadFile(file);
      }
    };
    let cellFormatted
if(cell != undefined){
  cellFormatted = cell.trim()
}
    return (
      <button className="link" onClick={handleLink}>
        {cellFormatted}
      </button>
    );
  };

  const base64ToText = (base64) => {
    return Buffer.from(base64, "base64");
  };

  const textToFile = (text) => {
    const data = new Blob([text], { type: "text/plain" });
    return data;
  };

  const downloadFile = (data) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(data);
    link.download = "arquivo.txt";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const resolveAfter4Seconds = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 4000);
    });
  };

  const columns = [
    {
      dataField: "data",
      text: "Data",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: dataFormatter,
    },
    {
      dataField: "hour",
      text: "Hora",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: hourFormatter,
    },
    {
      dataField: "documentDetail",
      text: "Detalhe",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: createALink,
      // formatExtraData: {
      //   value: dataBase64,
      // },
    },
  ];

  return (
    <>
      {/* <div className="main-card-v2"> */}
      <div className="custom-table">
        <ToolkitProvider
          keyField="id"
          data={absenceLogs ? absenceLogs : []}
          columns={columns}
          search={{ searchFormatted: true }}
        >
          {(props) => (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <div
                  style={{
                    marginBottom: "0.5rem",
                    marginLeft: "10px",
                    display: "flex",
                    paddingTop: "32px",
                    paddingLeft: "5px",
                  }}
                >
                  {true && (
                    <SearchBar
                      {...props.searchProps}
                      placeholder={t("general.search")}
                    />
                  )}
                  <div style={{ paddingLeft: "15px" }} />
                  {/* <SimpleButton
                      variant={"liftworld-button-primary"}
                      disabled={
                        isLoadingGetComplaints ||
                        complaints?.claims?.length === 0
                      }
                      onClick={() => {
                        downloadXls();
                      }}
                      text={"Exportar"}
                    /> */}
                </div>
              </div>
              <BootstrapTable
                {...props.baseProps}
                id="document-table"
                responsive
                bootstrap4
                pagination={paginationFactory({
                  sizePerPage: 5,
                  sizePerPageList: [],
                })}
                noDataIndication={t("datacapture.general.dataNotFound")}
                //rowEvents={rowEvents}
                pageSize={5}
                hover
                striped
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      {/* </div> */}
    </>
  );
};
export default withNamespaces()(LogsTable);
