import React from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_toggle.scss";

const Toggle = ({ disabled = false, onClick, id , disabledColored}) => {
  return (
    <>
      <div className={"easypay-custom-checkbox"} disabled={disabled}>
        <input
          className="easypay-toggle-status"
          id={id}
          type="checkbox"
          disabled={disabled}
          checked = {disabledColored ? true : null}
          name={id}
          onClick={onClick}
        />
        <label htmlFor={id}>
          <div
            className="easypay-status-switch"
            disabled={disabled}
            data-unchecked="X"
            data-checked="✓"
          ></div>
        </label>
      </div>
    </>
  );
};

export default withNamespaces()(Toggle);
