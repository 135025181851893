import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_textAreaInput.scss";
import { useDispatch, useSelector } from "react-redux";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";

const TextAreaInput = ({
  field,
  type,
  t,
  name,
  onChange,
  size,
  position,
  itemIndex,
  activityIndex,
  optionIndex,
  workplanIndex,
  color
}) => {
  const [textInputValue, setTextInputValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (field) {
      if (field.value) setTextInputValue(field.value);
    }
  }, [field]);

  const { workplans } = useSelector((state) => state.budgetManagementReducer);

  function updateState() {
    if (name === "" || name === null) return;

    if (itemIndex != null && activityIndex != null && optionIndex != null) {
      if (Array.isArray(workplans)) {
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[optionIndex][name] =
          textInputValue;
        //dispatch(setAllWorkplans(workplans));
      } else {
        workplans.atividades[activityIndex].opcoes[optionIndex] = textInputValue;
        //dispatch(setAllWorkplans(workplans));
      }
    } else if (itemIndex != null && activityIndex != null) {
      if (Array.isArray(workplans)) {
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex][name] = textInputValue;
        //dispatch(setAllWorkplans(workplans));
      } else {
        workplans.atividades[activityIndex][name] = textInputValue;
        //dispatch(setAllWorkplans(workplans));
      }
    } else if(itemIndex != null){
      if (Array.isArray(workplans)) {
        workplans[workplanIndex].items[itemIndex][name] = textInputValue;
        //dispatch(setAllWorkplans(workplans));
      } else {
        workplans[name] = textInputValue;
        //dispatch(setAllWorkplans(workplans));
      }
    }
    else
    {
      if (Array.isArray(workplans)) {
        workplans[workplanIndex][name] = textInputValue;

        dispatch(setAllWorkplans(workplans));
      } else {
        workplans[name] = textInputValue;
        //dispatch(setAllWorkplans(workplans));
      }
    }
  }

  return (
    <Form.Group
      controlId={`formText-${field.variable}`}
      style={{ margin: "0px" }}
    >
      <div className={`${"liftworld-textarea-input-wrapper-wrapper"} ` + size}>
        <div
          className={`liftworld-textarea-input-wrapper ${
            field.maxlength &&
            "liftworld-textarea-input-size-" + field.maxlength
          } ${size} `}
          tabIndex="1"
        >
          <div className={`${"liftworld-textarea-input-label"} `}>
            {field.text}
          </div>
          <div
            className={
              `${"liftworld-textarea-input-field-wrapper"} ${size}`
            }
          >
            <textarea
              className={`liftworld-textarea-input-field ${color} `}
              type={"textarea"}
              name={name !== undefined ? name : field.variable}
              value={type === "password" ? "palavra-pass" : textInputValue}
              maxLength={field.maxlength}
              onChange={(e) => {
                setTextInputValue(e.target.value);
                onChange && onChange(e.target.value, name);
              }}
              onBlur={updateState}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            {`O campo é de preenchimento obrigatório`}
          </Form.Control.Feedback>
        </div>
      </div>
    </Form.Group>
  );
};
export default withNamespaces()(TextAreaInput);
