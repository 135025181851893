import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import '~/assets/css/styles.css';
import '~/assets/css/icons.css';

const PreviewModal = props => {

  const { t, showModal, closeModal, task } = props;

  return (
    <Modal

      show={showModal}
      onHide={() => closeModal()}
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('TasksWidget.processNumber')} &nbsp;{task && task.pnumber}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <hr />
      </Modal.Body >
    </Modal >
  )

}

export default withNamespaces()(PreviewModal);
