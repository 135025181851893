import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Alert, Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import {useSelector, useDispatch } from "react-redux";
import Messages from "~/components/Messages";
import FilePreviewCard from "~/pages/Onboarding/components/DigitalSignature/FilePreviewCard";
import { addMindee } from "~/store/ducks/onboarding/actionTypes";

const SUPPORTED_FILES_TYPES = ["application/pdf"];

//TODO: pegar na resposta e apresentar mensagem a dizer o que aconteceu

const FileUpload = ({ tabKey, t }) => {
  const [warningMessage, setWarningMessage] = useState("");
  const [onDragOver, setOnDragOver] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [documents, setDocuments] = useState([]);
  const inputFile = useRef(null);
  const [currentBase64, setCurrentBase64] = useState();

  const dispatch = useDispatch();

  const {
    mindeeIban, mindeeAddress
  } = useSelector((state) => state.onboardingReducer);

  const maxFileSize = 1024 * 1024 * 15; //15Mb
  const maxFiles = 100;

  useEffect(()=>{
    if(documents.length > 0)
    getBase64(documents[0].file)
  }, [documents])

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    const files_ = event.dataTransfer.files;
    const files = [];
    for (let i = 0; i < files_.length; i++) {
      files.push(files_.item(i));
    }
    handleMultipleFiles(files);
    event.preventDefault();
  };

  const handleOnChoose = (event) => {
    if (event.target.multiple === true) {
      const files_ = event.target.files;
      const files = [];
      for (let i = 0; i < files_.length; i++) {
        files.push(files_.item(i));
      }
      handleMultipleFiles(files);
    } else {
      handleMultipleFiles([event.target.files[0]]);
    }
  };

  const handleMultipleFiles = (files) => {
    if (files.length <= maxFiles) {
      if (files.some((file) => !SUPPORTED_FILES_TYPES.includes(file.type))) {
        setWarningMessage(t("portalrh.documentManagement.checkFileType"));
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), 10000);
      } else {
        let totalfilesize = files.reduce(
          (accumulator, currentValue) => accumulator + currentValue.size,
          0
        );

        let savedFilesSize = documents.reduce(
          (accumulator, currentValue) => accumulator + currentValue.file.size,
          0
        );
        if (totalfilesize + savedFilesSize > maxFileSize) {
          setWarningMessage(
            t("portalrh.documentManagement.exceedFileSize") +
              t("portalrh.documentManagement.currentFileSize") +
              (savedFilesSize / (1024 * 1024)).toFixed(2) +
              " Mb."
          );
          setShowWarning(true);
          setTimeout(() => setShowWarning(false), 10000);
        } else {
          let documents_ = [...documents];
          files.forEach((f) => documents_.push({ file: f, fileName: f.name }));
          //reassign ids
          setDocuments(
            documents_.map((d, i) => {
              return {
                file: d.file,
                fileName: d.fileName,
                id: i,
              };
            })
          );
        }
      }
    } else {
      setWarningMessage(t("portalrh.documentManagement.tooManyFiles"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const clearStates = () => {
    setWarningMessage("");
  };

  const handleDeleteDocument = (id) => {
    setDocuments(documents.filter((d) => d.id !== id));
  };

  const handleSaveDocument = () => {
    dispatch(addMindee(currentBase64, tabKey, "61798db3a0ac1d6257017e87" ))
    setDocuments([])
  };

  function getBase64(file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64Data = event.target.result;
      setCurrentBase64(base64Data);
    };
    reader.readAsDataURL(file);
  }

  return (
    <>
      <div style={{ padding: "20px", display: "flex" }}>
        <Col lg={true}>
          {showWarning && (
            <Alert
              variant="warning"
              onClose={() => setShowWarning(false)}
              dismissible
            >
              <strong>Alert: </strong>
              {warningMessage}
            </Alert>
          )}

          <Row className="justify-content-md-center">
            <Col sm={8} className="uploadArea">
              <div
                style={{
                  paddingBottom: "10px",
                  alignSelf: "flex-start",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              ></div>
              <div
                className="dropAreaMindee"
                onDrop={(e) => handleOnDrop(e)}
                onClick={() => handleClick()}
                onDragEnter={() => setOnDragOver(true)}
                onDragLeave={() => setOnDragOver(false)}
                style={
                  onDragOver
                    ? { background: "#fff" }
                    : { background: "#efefef" }
                }
              >
                <span>
                  <i
                    className={"icon-submit portal-image-upload-icon-active"}
                  />
                  <div className={"portal-label-main-active"}>
                    {t("portalrh.documentManagement.dropToUpload")}
                  </div>
                </span>
                <input
                  multiple={maxFiles > 1 ? true : false}
                  id="file"
                  type="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={(e) => handleOnChoose(e)}
                />
              </div>
              <div className="dc-label">
                {t("signature.totalFileSize") +
                  t("portalrh.documentManagement.maxFiles") +
                  " " +
                  maxFiles}
              </div>
              <small>{t("signature.PDFonly")}</small>

              {documents.length > 0 && (
                <>
                  <div
                    style={{
                      maxWidth: "50vw",
                      width: "fit-content",
                      caretColor: "transparent",
                      display: "flex",
                      flexWrap: "wrap",
                      paddingTop: "10px",
                    }}
                  >
                    {documents.map((doc, i) => {
                      return (
                        <FilePreviewCard
                          key={i}
                          document={doc}
                          handleDeleteDocument={handleDeleteDocument}
                          mindee={true}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </Col>
          </Row>
          <Messages
            afterHideSuccess={clearStates}
            afterHideError={clearStates}
          />
        </Col>
      </div>
      <div style={{ marginTop: "20px", textAlign: "-webkit-right" }}>
        <Button
          disabled={!Object.keys(documents).length > 0}
          className="card-button onboarding-button"
          onClick={() => {
            handleSaveDocument();
          }}
        >
          SEND
        </Button>
      </div>
    </>
  );
};
export default withNamespaces()(FileUpload);
