import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";

import Spinner from "~/components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { useInterval } from "~/components/Notifications/utils/useInterval.js";

import {
  getRequestsByUsername,
  setGenericAuthorizationState,
} from "~/store/ducks/portalrh/actionTypes";

import { Tabs, Tab } from "react-bootstrap";

import {
  AuthorizerApproves,
  RequestState,
} from "~/components/widgets/Requests/TabsEnum.js";

const MyTeamRequestTable = ({ t, handleOpenFlowModal, searchFilter }) => {
  const { genericAuthorizationState, requests, isLoadingRequests } =
    useSelector((state) => state.portalrhReducer);

  const { user } = useSelector((state) => state.globalReducer);

  const dispatch = useDispatch();
  const usernamePortalRh = user.legacyUsers.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  ).username;

  const checkValidateProc = () => {
    switch (searchFilter) {
      case "absenceRecord":
        return false;
      case "extraTime":
        return false;
      case "insertPresence":
        return false;
      case "pagamentosBolsa":
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    let validateProcGeneric = checkValidateProc();
    if (validateProcGeneric) {
      dispatch(getRequestsByUsername(usernamePortalRh, 1));
    } else {
      dispatch(getRequestsByUsername(usernamePortalRh));
    }
  }, []);

  useInterval(() => {
    let validateProcGeneric = checkValidateProc();
    if (validateProcGeneric) {
      dispatch(getRequestsByUsername(usernamePortalRh, 1));
    } else {
      dispatch(getRequestsByUsername(usernamePortalRh));
    }
  }, 1000 * 60);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: t("portalrh.requestTable.requestId"),
      sort: true,
    },
    {
      dataField: "username",
      text: t("portalrh.requestTable.user"),
      sort: true,
    },
    {
      dataField: "employee_num",
      text: t("portalrh.requestTable.employeeNum"),
      sort: true,
    },
    {
      dataField: "auth_designation",
      text: t("portalrh.requestTable.description"),
      sort: true,
    },
    {
      dataField: "creation_date",
      text: t("portalrh.requestTable.date"),
      sort: true,
    },
    {
      dataField: "change_type",
      text: t("portalrh.requestTable.type"),
      sort: true,
    },
    {
      dataField: "state",
      text: t("portalrh.requestTable.state"),
      sort: true,
    },
  ];

  const rowEvents = {
    onClick: (_, row) => {
      handleOpenFlowModal(row);
    },
  };

  const filteredRequests = requests.filter((request) => {
    if (searchFilter != undefined) {
      if (
        (genericAuthorizationState === AuthorizerApproves.YES ||
          +genericAuthorizationState === AuthorizerApproves.YES) &&
        request.description === searchFilter
      ) {
        return request.authorizer_approves === 1;
      } else if (
        (+genericAuthorizationState === AuthorizerApproves.NO ||
          genericAuthorizationState === AuthorizerApproves.NO) &&
        request.description === searchFilter
      ) {
        return request.authorizer_approves === 0;
      } else if (
        genericAuthorizationState === RequestState.APPROVED &&
        request.description === searchFilter
      ) {
        return request.state === "Aprovado";
      } else if (
        genericAuthorizationState === RequestState.REJECTED &&
        request.description === searchFilter
      ) {
        return request.state === "Recusado";
      }
    } else {
      return request.description === searchFilter;
    }
  });

  return (
    <>
      <div className="custom-table custom-table-small cursor-pointer">
        <ToolkitProvider
          keyField="id"
          data={filteredRequests.sort(function (a, b) {
            if (a.id > b.id) {
              return -1;
            }
            if (a.id < b.id) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  display: "flex",
                }}
              >
                <SearchBar {...props.searchProps} placeholder={t("Search")} />
              </div>

              <Tabs
                id="authorizationTabs"
                defaultActiveKey="first"
                className="custom-tabs"
                activeKey={genericAuthorizationState}
                onSelect={(k) => dispatch(setGenericAuthorizationState(k))}
              >
                <Tab
                  eventKey={AuthorizerApproves.YES}
                  title={t("portalrh.requests.authorizationsToApprove")}
                ></Tab>
                <Tab
                  eventKey={AuthorizerApproves.NO}
                  title={t("portalrh.requests.otherAuthorizations")}
                ></Tab>
                <Tab
                  eventKey={RequestState.APPROVED}
                  title={t("portalrh.requests.approvedRequests")}
                ></Tab>
                <Tab
                  eventKey={RequestState.REJECTED}
                  title={t("portalrh.requests.refusedRequests")}
                ></Tab>
              </Tabs>

              <Spinner spinning={isLoadingRequests} />
              {!isLoadingRequests && (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("No team requests available")}
                  rowEvents={rowEvents}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default withNamespaces()(MyTeamRequestTable);
