import React, { useEffect } from "react";
import { Tabs } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Tab from "~/pages/Easypay/components/Tab";
import {
  aproveDelegation,
  getAprovalDelegation,
  getDelegacoes,
  setAprovalDelegacoes,
  setDelegacoes,
  deleteDelegacao,
  setSuccess,
  getDelegacoesByUserid,
} from "~/store/ducks/delegacoes/actionTypes";
import Spinner from "~/components/Spinner";

const GerirPage = ({ t }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((selectorState) => selectorState.globalReducer);
  const {
    delegacoes,
    aprovalDelegacoes,
    success,
    isLoadingDelegacoes,
    isLoadingAprovalDelegacoes,
    isLoadingSuccess,
    delegacoesByUserid,
    isLoadingDelegacoesByUserid,
  } = useSelector((selectorState) => selectorState.delegacoesReducer);
  const [state, setState] = React.useState({ rows: [] });

  const Terminate = (v) => {
    dispatch(deleteDelegacao(v));
  };

  const OnTerminate = (e) => {
    Terminate([
      {
        userid: e.userid,
        id: e.hierarchyid,
        ownerid: e.ownerid,
        flowids: [e.flow.flowid],
      },
    ]);
    setState({ ...state, rows: [] });
  };

  const endMulti = () => {
    Terminate(
      state.rows.map((e) => {
        return {
          userid: delegacoes[e]?.userid,
          id: delegacoes[e]?.hierarchyid,
          ownerid: delegacoes[e]?.ownerid,
          flowids: [delegacoes[e]?.flow?.flowid],
        };
      })
    );
    setState({ ...state, rows: [] });
  };

  const OnSetDelegation = (del, accepted) => {
    dispatch(
      aproveDelegation({ delegationId: [del.hierarchyid], pending: accepted })
    );
    setState({ ...state, rows: [] });
  };

  const onSelMulti = (accepted) => {
    dispatch(
      aproveDelegation({
        delegationId: state.rows.map((e) => aprovalDelegacoes[e].hierarchyid),
        pending: accepted,
      })
    );
    setState({ ...state, rows: [] });
  };

  const getLegacyUser = () => {
    if (!user || !user.id) return;
    return user.legacyUsers.find((u) => u.legacyApplication.label == "bpm");
  };

  const UpdateTables = () => {
    dispatch(getDelegacoes({ id: getLegacyUser().username }));
    dispatch(getAprovalDelegation({ userid: getLegacyUser().username }));
    dispatch(getDelegacoesByUserid({ id: getLegacyUser().username }));
  };

  useEffect(() => {
    if (user && user.id) UpdateTables();
  }, [user, isLoadingSuccess]);

  useEffect(() => {
    if (
      success &&
      success.value &&
      (success.caller == "aproveDelegation" ||
        success.caller == "deleteDelegacao")
    )
      UpdateTables();
    dispatch(setSuccess(undefined));
  }, [success]);

  useEffect(() => {
    if (
      !delegacoes ||
      delegacoes.length == 0 ||
      delegacoes[0].action != undefined
    )
      return;
    let newDeleg = delegacoes.map((e, i) => {
      return {
        ...e,
        id: i,
        status:
          e.pending === 1
            ? t("bpm.delegacoes.form.peding")
            : t("bpm.delegacoes.form.accepted"),
        action: (
          <span className="btn-link" onClick={() => OnTerminate(e)}>
            {t("bpm.delegacoes.table.action.terminate")}
          </span>
        ),
      };
    });
    dispatch(setDelegacoes(newDeleg));
  }, [delegacoes]);

  useEffect(() => {
    if (
      !aprovalDelegacoes ||
      aprovalDelegacoes.length == 0 ||
      aprovalDelegacoes[0].aceitar != undefined
    )
      return;
    let newDeleg = aprovalDelegacoes.map((e, i) => {
      return {
        ...e,
        id: i,
        expires: new Date(e.expires).toLocaleDateString("pt-PT"),
        aceitar: (
          <span className="btn-link" onClick={() => OnSetDelegation(e, true)}>
            {t("bpm.delegacoes.table.action.accept")}
          </span>
        ),
        recusar: (
          <span className="btn-link" onClick={() => OnSetDelegation(e, false)}>
            {t("bpm.delegacoes.table.action.refuse")}
          </span>
        ),
      };
    });
    dispatch(setAprovalDelegacoes(newDeleg));
  }, [aprovalDelegacoes]);

  const columnActive = [
    {
      dataField: "ownerid",
      text: t("bpm.delegacoes.table.owner"),
    },
    {
      dataField: "flow.flowname",
      text: t("bpm.delegacoes.table.flow"),
    },
    {
      dataField: "userid",
      text: t("bpm.delegacoes.table.colegue"),
    },
    {
      dataField: "status",
      text: t("bpm.delegacoes.table.state"),
    },
    {
      dataField: "action",
      text: "",
    },
  ];

  const columnPending = [
    {
      dataField: "ownerid",
      text: t("bpm.delegacoes.table.owner"),
    },
    {
      dataField: "flow.flowname",
      text: t("bpm.delegacoes.table.flow"),
    },
    {
      dataField: "expires",
      text: t("bpm.delegacoes.table.endDate"),
    },
    {
      dataField: "aceitar",
      text: "",
    },
    {
      dataField: "recusar",
      text: "",
    },
  ];

  const columnMydelegations = [
    {
      dataField: "ownerid",
      text: "Atribuída por",
    },
    {
      dataField: "flow.flowname",
      text: t("bpm.delegacoes.table.flow"),
    },
  ];

  const onRowSelect = (row, isSelect) => {
    if (isSelect) setState({ ...state, rows: [...state.rows, row.id] });
    else setState({ ...state, rows: state.rows.filter((x) => x !== row.id) });
  };

  const onRowSelectAll = (isSelect, rows, e) => {
    const newRows = isSelect ? rows.map((e) => e.id) : [];
    setState({ ...state, rows: newRows });
    return rows;
  };

  const selectRow = {
    mode: "checkbox",
    selected: state.rows,
    onSelect: onRowSelect,
    onSelectAll: onRowSelectAll,
    clickToSelect: true,
  };

  return (
    <div className="custom-table">
      <Tabs
        defaultActiveKey="aprov"
        onSelect={() => setState({ ...state, rows: [] })}
        id="adminSettingsTab"
        className="custom-tabs"
      >
        <Tab eventKey="aprov" title={t("bpm.delegacoes.manage.approve")}>
          <Spinner spinning={isLoadingAprovalDelegacoes} />
          <BootstrapTable
            classes="table table-striped table-hover table-bordered"
            keyField="id"
            data={aprovalDelegacoes ? aprovalDelegacoes : []}
            columns={columnPending}
            selectRow={selectRow}
            noDataIndication={t("bpm.delegacoes.form.noDelegations")}
          />
          <div style={{ display: "flex", gap: 10 }}>
            <button
              className="card-button btn btn-primary"
              onClick={() => {
                if (state.rows.length == 0) return;
                onSelMulti(true);
              }}
            >
              {t("bpm.delegacoes.table.action.acceptSelected")}
            </button>
            <button
              className="card-button btn btn-primary"
              onClick={() => {
                if (state.rows.length == 0) return;
                onSelMulti(false);
              }}
            >
              {t("bpm.delegacoes.table.action.refuseSelected")}
            </button>
          </div>
        </Tab>
        <Tab eventKey="active" title={t("bpm.delegacoes.manage.active")}>
          <Spinner spinning={isLoadingDelegacoes} />
          <BootstrapTable
            classes="table table-striped table-hover table-bordered"
            keyField="id"
            data={delegacoes ? delegacoes : []}
            columns={columnActive}
            selectRow={selectRow}
            noDataIndication={t("bpm.delegacoes.form.noDelegations")}
          />
          <button
            className="card-button btn btn-primary"
            onClick={() => {
              if (state.rows.length == 0) return;
              endMulti();
            }}
          >
            {t("bpm.delegacoes.table.action.endSelected")}
          </button>
        </Tab>
        <Tab eventKey="myDelegations" title="Atribuídas a mim">
          <Spinner spinning={isLoadingDelegacoesByUserid} />
          <BootstrapTable
            classes="table table-striped table-hover table-bordered"
            keyField="id"
            data={delegacoesByUserid ? delegacoesByUserid : []}
            columns={columnMydelegations}
            noDataIndication={t("bpm.delegacoes.form.noDelegations")}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default withNamespaces()(GerirPage);
