import { replace, xor } from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/styles.css";
import "~/components/chat/scss/_badge.scss";
import {
  getUserBPMNotifications,
  getUserPortalRHNotifications,
  markAllAsSeen,
  markAsSeen,
  saveNotification,
} from "~/store/ducks/notifications/actionTypes";
import { BPM_MIDDLEWARE, PORTALRH_MIDDLEWARE } from "~/utils/constants";
import CustomToggle from "../../CustomToggle";
import NotificationModal from "../NotificationModal";
import "../notifications.scss";
import { useInterval } from "../utils/useInterval.js";
import NotificationDropdownItem from "./NotificationDropdownItem";
import { BsBellFill } from "react-icons/bs";
import { setNotification } from "~/store/ducks/delegacoes/actionTypes";

const Notifications = ({ t, styleSheet }) => {
  const dispatch = useDispatch();
  const {
    notificationUpdated,
    isDeletingNotification,
    portalRHNotifications,
    bpmNotifications,
  } = useSelector((state) => state.notificationsReducer);

  const { user } = useSelector((state) => state.globalReducer);
  const { notification } = useSelector((state) => state.delegacoesReducer);

  const [myJson, setMyJson] = useState("");

  let usernamePortalRH = null;
  let appIdPortalRH = null;
  let usernameBPM = null;
  let appIdBPM = null;

  if (Object.keys(user).length > 0) {
    usernamePortalRH = user.legacyUsers.find(
      (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
    )?.username;
    appIdPortalRH = user.legacyUsers.find(
      (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
    )?.legacyApplication?.id;
    usernameBPM = user.legacyUsers.find(
      (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
    )?.username;
    appIdBPM = user.legacyUsers.find(
      (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
    )?.legacyApplication?.id;
  }

  let legacyNotifications = null;
  if (
    Object.keys(portalRHNotifications).length > 0 &&
    Object.keys(bpmNotifications).length > 0
  ) {
    legacyNotifications = { ...portalRHNotifications, ...bpmNotifications };
  } else if (Object.keys(portalRHNotifications).length > 0) {
    legacyNotifications = portalRHNotifications;
  } else if (Object.keys(bpmNotifications).length > 0) {
    legacyNotifications = bpmNotifications;
  }

  useEffect(() => {
    if (notification.length > 0) {
      if (Array.isArray(notification)) {
        for (let i = 0; i < notification.length; i++) {
          notification[i]["message"] = t(notification[i].message);
          notification[i]["subject"] = t(notification[i].subject);

          let replaceMessage = notification[i]?.message
            .replace(/\{0}/g, notification[i]?.flowName)
            .replace(/\{1}/g, notification[i]?.delegatedUser);
          notification[i]["message"] = replaceMessage;

          let replaceSubject = notification[i]?.subject
            .replace(/\{0}/g, notification[i]?.flowName)
            .replace(/\{1}/g, notification[i]?.delegatedUser);
          notification[i]["subject"] = replaceSubject;
        }
        dispatch(saveNotification(notification));
        dispatch(setNotification([]));
      }
    }
  }, [dispatch, notification]);

  useEffect(() => {
    updateLegacyNotifications();
  }, [dispatch, user, notificationUpdated, isDeletingNotification]);

  useInterval(() => {
    updateLegacyNotifications();
  }, 1000 * 30);

  const updateLegacyNotifications = () => {
    if (usernamePortalRH != null && appIdPortalRH != null) {
      dispatch(getUserPortalRHNotifications(appIdPortalRH, usernamePortalRH));
    }

    if (usernameBPM != null && appIdBPM != null) {
      dispatch(getUserBPMNotifications(appIdBPM, user?.username));
    }
  };

  const renderNotificationsBadge = () => {
    let totalUnreadNotifications = Array.isArray(legacyNotifications)
      ? legacyNotifications.filter((item) => item.seen === false).length
      : 0;
    return totalUnreadNotifications > 0 && totalUnreadNotifications < 100 ? (
      <span className="chat-badge-unread btn-danger">
        <div className="chat-badger-count">{totalUnreadNotifications}</div>
      </span>
    ) : totalUnreadNotifications > 100 ? (
      <span
        className="chat-badge-unread btn-danger"
        style={{
          height: "1.6em",
          width: "1.6em",
          paddingTop: "4px",
        }}
      >
        <div className="chat-badger-count">{totalUnreadNotifications}</div>
      </span>
    ) : undefined;
  };

  const markAllNotificationsAsSeen = () => {
    if (
      Array.isArray(legacyNotifications) &&
      legacyNotifications.some((item) => item.seen === false)
    ) {
      return (
        <div
          className="mark-as-read"
          style={{ float: "right" }}
          onClick={() => handleMarkAll()}
        >
          {t("notifications.markAll")}
        </div>
      );
    }
  };

  const renderNotifications = () => {
    if (legacyNotifications != null && Array.isArray(legacyNotifications)) {
      legacyNotifications.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
      );
      let count = 0;
      return legacyNotifications.map((item, index) => {
        if (count < 4) {
          if (!item.seen) {
            count++;
            return (
              <NotificationDropdownItem
                key={index}
                item={item}
                handleSeen={handleSeen}
                styleSheet={styleSheet}
                countItemNotSeen={count}
              />
            );
          }
        }
      });
    }
  };

  const handleMarkAll = () => {
    dispatch(markAllAsSeen());
  };
  const handleSeen = (item) => {
    dispatch(markAsSeen(item.id));
  };

  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const handleCloseNotificationModal = () => {
    setShowNotificationModal(false);
  };

  return (
    <>
      <Dropdown alignRight>
        <Dropdown.Toggle as={CustomToggle} style={{ padding: "0px" }}>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 0 }}
            overlay={
              <Tooltip id="favoritos_tooltip">
                {t("header.notifications")}
              </Tooltip>
            }
          >
            <>
              <div className="nav-item-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  height="30"
                >
                  <a className="topbar-btn">
                    <path
                      d="M89.9,81.9v3.8H38.1v-3.8l6.5-5.9V55.4c0-8.6,5.9-15.7,14.6-17.8v-3.2c0-2.2,2.2-3.8,4.9-3.8
                    c2.7,0,4.9,1.6,4.9,3.8v3.2c8.1,2.2,14.6,9.2,14.6,17.8V76L89.9,81.9z M64,95.4c3.8,0,6.5-2.7,6.5-6.5h-13
                    C57.5,92.7,60.2,95.4,64,95.4z"
                      fill="#FFFFFF"
                    />
                  </a>
                </svg>
              </div>
              {renderNotificationsBadge()}
            </>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="dropdown-menu-inner" style={{ width: "350px" }}>
            {styleSheet?.notificationsCma ? (
              <Dropdown.Header style={{ color: "#24579e", fontWeight: "500" }}>
                <BsBellFill
                  style={{ marginRight: "10px", color: "#24579e" }}
                  size={20}
                />

                {t("notifications.title")}
              </Dropdown.Header>
            ) : (
              <Dropdown.Header>
                {t("notifications.title")}
                {markAllNotificationsAsSeen()}
              </Dropdown.Header>
            )}

            <Dropdown.Divider />
            {renderNotifications()}
            {legacyNotifications && legacyNotifications.length !== 0 && (
              <Dropdown.Item
                style={{
                  display: "flex",
                  padding: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="show-all-notifications"
                  onClick={() => setShowNotificationModal(true)}
                >
                  <b>{t("notifications.showAll")}</b>
                </div>
              </Dropdown.Item>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <NotificationModal
        showModal={showNotificationModal}
        closeModal={handleCloseNotificationModal}
        legacyNotifications={legacyNotifications}
        styleSheet={styleSheet}
      />
    </>
  );
};
export default withNamespaces()(Notifications);
