import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/css/styles.css";
import "../assets/scss/_backofficeEasypaySubheader.scss";
import Badge from "./Badge";
import Breadcrumb from "./Breadcrumb";
import Button from "./Button";
import Dropdown from "./InputDropdown";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import Tag from "./Tag";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser,sendEmailEndPoint } from "~/store/ducks/onboarding/actionTypes";

const BackOfficeEasypaySubHeader = ({
  data,
  breadcrumbItems,
  t,
  isLead,
  pid,
  setIsModalShowing,
  setIsModalErrorShowing,
}) => {
  const dispatch = useDispatch();

  const dropdownList1 = [
    { label: "Red", value: "Red", selected: false },
    { label: "Green", value: "Green", selected: false },
    { label: "Yellow", value: "Yellow", selected: false },
  ];
  const dropdownList2 = [
    { label: "Compliance", value: "Compliance", selected: true },
    { label: "Comercial", value: "Comercial", selected: false },
  ];

  const { user } = useSelector((state) => state.onboardingReducer);

  function handleSelectChange(e) {
    console.log(e)
    user.client.departamento = e
    //dispatch(updateUser({user : user, pid : pid}));
  }
  
  function handleSelectChangeColor(e) {
    console.log(e);
    if (e === "Red") {
      user.client.cor = 1;
      user.account.cor = 1;
    } else if (e === "Yellow") {
      user.client.cor = 2;
      user.account.cor = 2;
    } else {
      user.client.cor = 3;
      user.account.cor = 3;
    }
    dispatch(updateUser({ user: user, pid: pid }));
  }

  function approveReproveUser(operation) {
    if (operation === 0) {
      setIsModalShowing(true);
      user.account.aprovado = 1;
      user.account.idLead = 0;
      user.account.lead = 0;
    } else {
      setIsModalErrorShowing(true);
      user.account.idLead = -1;
      const payload = {template : "ep-account-disapproved" ,data : `{\"type\":\"email\",\"emails\":[\"${user.client.email}\"],\"subject\":\"Teste email\",\"extra\":{\"teste\":\"Hey\",\"Year\":\"2021\"},\"metadata\":{\"user_id\":4214}}`}
      dispatch(sendEmailEndPoint(payload, "61798db3a0ac1d6257017e87"))
    }

    dispatch(updateUser({ user: user, pid: pid }));
  }

  return (
    <div
      className="easypay-backoffice-subheader-wrapper"
      id="easypay-backoffice-subheader"
    >
      <div className="easypay-backoffice-subheader-left-content">
        <Breadcrumb breadcrumbStates={breadcrumbItems} />
        <div className="easypay-backoffice-subheader-name">
          {data?.client?.nome}
        </div>
        <div className="easypay-backoffice-subheader-labels-wrapper">
          <Tag
            variant="easypay-tag-label"
            text={data?.account?.id}
            type="easypay-evaluation"
          />
          <div className="easypay-backoffice-onboarding-step-label-wrapper">
            <Tag
              variant="easypay-onboarding-step"
              text={
                t("bpm.onboarding.backoffice.administration.tableStage") +
                ": " +
                data?.account?.estadoLead
              }
            />
          </div>
          <Badge char="P"></Badge>
        </div>
      </div>
      <div className="easypay-backoffice-subheader-right-content">
        <div className="easypay-backoffice-subheader-right-buttons-wrapper">
          <ProtectedComponent
            roles={["ADMIN", "ONBOARDINGUser", "ONBOARDINGCompliance"]}
          >
            <>
              <Button
                variant={"easypay-button-primary easypay-error"}
                text={t(`bpm.onboarding.backoffice.administration.buttonFail`)}
                onclick={() => {
                  approveReproveUser(-1);
                }}
              />
              <div className="easypay-backoffice-subheader-right-button-wrapper">
                <Button
                  variant={"easypay-button-primary easypay-success"}
                  text={t(
                    `bpm.onboarding.backoffice.administration.buttonApprove`
                  )}
                  onclick={() => {
                    approveReproveUser(0);
                  }}
                />
              </div>
            </>
          </ProtectedComponent>
        </div>

        <div className="easypay-backoffice-subheader-right-dropdown-wrapper">
          <Dropdown
            text={t("bpm.onboarding.backoffice.administration.inputColor")}
            required={true}
            placeholder="Escolher"
            list={dropdownList1}
            handleSubmit={(e) => handleSelectChangeColor(e)}
          />
          <div className="easypay-backoffice-subheader-right-dropdown-wrapper">
            <Dropdown
              text={t(
                "bpm.onboarding.backoffice.administration.tableDepartment"
              )}
              required={true}
              placeholder="Compliance"
              list={dropdownList2}
              handleSubmit={(e) => handleSelectChange(e)}
              initialValue = {data?.client?.departamento}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(BackOfficeEasypaySubHeader);
