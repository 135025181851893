import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import widgetplaceholder from "../../../assets/img/widgets/widget-placeholder.jpg";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import { isEmpty } from "lodash";

import "~/assets/css/icons.css";
import "~/assets/css/styles.css";
// import "~/components/FlowModal/scss/flowModal.scss";

import { getBaseWidgets } from "~/pages/Admin/SystemSettings/actions";
import { saveUserWidget, updateUserSettings } from "~/pages/User/actions";

const styles = {
  img: {
    borderRadius: "3%",
    width: "100%",
    height: "auto",
    float: "left",
  },
  buttonsuccess: {
    float: "right",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
};

const AddWidgetComponent = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const { baseWidgets, isUpdated, configuration } = useSelector(
    (state) => state.adminConfigReducer
  );
  const { userSettings, lastAddedWidget } = useSelector(
    (state) => state.userSettingsReducer
  );

  const isProductionMode = configuration ? configuration.productionMode : true;

  useEffect(() => {
    dispatch(getBaseWidgets());
  }, [dispatch, isUpdated]);

  const ref = useRef(null);
  let { path } = useRouteMatch();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const adicionarWidget = (item) => {
    const payload = {
      widgetId: item.id,
    };
    dispatch(saveUserWidget(payload));
    handleClose();
  };

  useEffect(() => {
    if (lastAddedWidget) {
      //update userSettings to modify placements
      const destinationListIndex = 0;
      const widgetId = lastAddedWidget !== null ? lastAddedWidget.id : "";
      const dashboard = userSettings.dashboards.find(
        (item) => item.application === path
      );
      let placements;
      if (dashboard === undefined) placements = [[]];
      else {
        placements = dashboard.placements ? dashboard.placements : [[]];
      }
      const length = placements[destinationListIndex].length;
      const newIndex = length;
      const newDestinationList = Array.from(placements[destinationListIndex]);
      newDestinationList.splice(newIndex, 0, widgetId);
      const newPlacements = Array.from(placements);
      newPlacements.splice(destinationListIndex, 1);
      newPlacements.splice(destinationListIndex, 0, newDestinationList);

      let payload_;
      let newDashboards;
      if (userSettings.dashboards.some((item) => item.application === path)) {
        newDashboards = userSettings.dashboards.map((item) => {
          if (item.application === path) {
            item.placements = newPlacements;
            return item;
          } else return item;
        });
        payload_ = {
          ...userSettings,
          dashboards: newDashboards,
        };
      } else {
        newDashboards = userSettings.dashboards;
        newDashboards.push({ application: path, placements: newPlacements });
        payload_ = {
          ...userSettings,
          dashboards: newDashboards,
        };
      }
      dispatch(updateUserSettings(payload_));
    }
  }, [dispatch, lastAddedWidget]);

  let widgets;
  isProductionMode
    ? (widgets =
        !isEmpty(baseWidgets) &&
        baseWidgets.map((item, index) => {
          let widget;
          if (item.production) {
            widget = item.component;
            return (
              <div key={index}>
                <hr />
                <div className="row position-relative">
                  <div className="col-md-4">
                    <img
                      style={styles.img}
                      src={widgetplaceholder}
                      alt="Widget"
                    />
                  </div>
                  <div className="col-md-8 position-static">
                    <h6 className="font-weight-bold">
                      {" "}
                      {t(`${widget}.title`)}
                    </h6>
                    <button
                      type="button"
                      className="btn float-right text-success"
                      onClick={() => adicionarWidget(item)}
                    >
                      <i className="icon-add"></i>
                    </button>
                  </div>
                </div>
              </div>
            );
          }
          return undefined;
        }))
    : (widgets =
        !isEmpty(baseWidgets) &&
        baseWidgets.map((item, index) => {
          let widget = item.component;
          return (
            <div key={index}>
              <hr />
              <div className="row position-relative">
                <div className="col-md-4">
                  <img
                    style={styles.img}
                    src={widgetplaceholder}
                    alt="Widget"
                  />
                </div>
                <div className="col-md-8 position-static">
                  <h6 className="font-weight-bold"> {t(`${widget}.title`)}</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <button
                    type="button"
                    className="btn float-right text-success"
                    onClick={() => adicionarWidget(item)}
                  >
                    <i className="icon-add"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        }));

  return (
    <div className="widget-list-wrapper-add" ref={ref} tabIndex="-1">
      <a onClick={handleShow} className="add-widget-img">
        <i className="icon-add" style={{fontSize:'50px', verticalAlign:'text-top'}} />
      </a>

      <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title> {t("AddWidgetComponent.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{widgets}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            {t("general.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default withNamespaces()(AddWidgetComponent);
