import qs from "qs";
import { put, call } from "redux-saga/effects";
import http from "~/utils/config/http";
import axios from "axios";
import oauth from "~/utils/oauth";
import * as actions from "../actions";
import {
  toastActiveError,
  toastActiveWarning,
} from "~/components/Toast/actions";
import { SIGN_PDF_LOCAL_APP_URL } from "~/utils/constants";

import { setTemporaryUser } from "~/store/ducks/registerOrgAdmin/actionTypes";

import {
  GLOBAL_SOLVER_MIDDLEWARE,
  GRANT_TYPE_PASSWORD,
  IFLOW_APP,
} from "~/utils/constants";

/*Uniksystem*/
export function* requestLogin({ data, checkAdminConfigFile }) {
  //Cleaning localStorage
  oauth.clearAppStorage();

  //Check for missing config file and thus starting the reset password process
  if (checkAdminConfigFile) {
    try {
      yield http.get("api/open/confirm-admin");
    } catch (e) {
      //Process is only started if the file config file is missing - response status === 400
      const { response } = e;
      if (response.status === 400) {
        yield put(setTemporaryUser({ email: "admin" }));
        yield put(actions.failCredentials());
        window.location.href = `/#/insert-code`;
        return;
      }
    }
  }

  try {
    const resp = yield http.post("/oauth/token", qs.stringify(data));
    const { access_token, refresh_token, expires_in } = resp.data;
    oauth.setToken(
      {
        access_token,
        refresh_token,
        expires_in,
      },
      true
    );
    yield put(actions.successLogin());
  } catch (exception) {
    const { response } = exception;
    if (response && response.data.error === "invalid_grant") {
      yield put(actions.failCredentials());
      yield put(toastActiveWarning("login.error.badCredentials"));
    } else if (
      response &&
      (response.status === 428 || response.status === 417)
    ) {
      yield put(actions.failCredentials());
      yield put(toastActiveWarning("login.error.badCredentials"));
    } else {
      yield put(actions.failRemoteServer());
      yield put(toastActiveError("login.error.connectionRemoteServer"));
    }
  }
}

/*Global Solver*/
export function* requestLoginGlobalSolver({ data }) {
  try {
    yield put(actions.setGlobalSolverAuthenticated(false));
    const resp = yield http.post(
      "/api/open/authenticate",
      data,
      GLOBAL_SOLVER_MIDDLEWARE
    );
    const access_token_global_solver = resp.data;
    oauth.setGlobalSolverToken(access_token_global_solver, true);
    yield put(actions.setGlobalSolverAuthenticated(true));
  } catch (exception) {
    const { response } = exception;
    if (!response) {
      yield put(actions.failRemoteServer());
    }
    if (exception.message === "Network Error") {
      yield put(actions.failRemoteServer());
      yield put(toastActiveError("login.error.connectionRemoteServer"));
    } else if (response && response.data.error === "invalid_grant") {
      yield put(actions.failCredentials());
      yield put(toastActiveWarning("login.error.badCredentials"));
    }
  }
}

/*Google*/
export function* requestGoogleSignIn({ response }) {
  try {
    const googleResponse = yield http.post(
      "/api/open/auth/google/signin",
      response.tokenId
    );
    googleResponse.data.grant_type = GRANT_TYPE_PASSWORD;
    yield call(doGenericLogin, googleResponse);
  } catch (exception) {
    yield put(actions.failCredentials());
    yield call(throwsException, exception);
  }
}

/*Microsoft*/
export function* requestMicrosoftSignIn({ response }) {
  try {
    const microsoftResponse = yield http.post(
      "/api/open/auth/microsoft/signin",
      response.idToken
    );
    microsoftResponse.data.grant_type = GRANT_TYPE_PASSWORD;
    yield call(doGenericLogin, microsoftResponse);
  } catch (exception) {
    yield put(actions.failCredentials());
    yield call(throwsException, exception);
  }
}

/*LDAP*/
export function* requestLDAPSignIn({ authentication }) {
  try {
    const adResponse = yield http.post(
      "/api/open/auth/ldap/signin",
      authentication
    );
    console.log("adResponse", adResponse);
    adResponse.data.grant_type = GRANT_TYPE_PASSWORD;
    yield put(actions.setLDAPSignIn(adResponse));
    yield call(doGenericLogin, adResponse);
  } catch (exception) {
    yield put(actions.failCredentialsLDPA());
    yield put(actions.setLDAPSignIn(exception));
    yield call(throwsException, exception, true);
  }
}

/*SAML - BEGIN*/
export function* getSAMLRequestURL() {
  try {
    let response = yield http.get("/api/open/auth/saml/request/0");
    yield put(actions.setSAMLRequestURL(response.data));
  } catch (exception) {
    yield call(throwsException, exception);
  }
}

export function* signInSAML({ payload }) {
  try {
    let samlResponse = yield http.post("/api/open/auth/saml/signin", payload);
    samlResponse.data.grant_type = GRANT_TYPE_PASSWORD;
    yield call(doGenericLogin, samlResponse);
    yield put(actions.signedInSAML(true));
  } catch (exception) {
    yield put(actions.signedInSAML(false));
    yield call(throwsException, exception);
  }
}

export function* doGenericLoginFromIntranet({ data }) {
  oauth.clearAppStorage();
  try {
    console.log(data);

    let access_token = data?.access_token;
    let refresh_token = data?.refresh_token;
    let expires_in = data?.expires_in;

    document.cookie = "Bearer=" + access_token + ";path=/";

    oauth.setToken(
      {
        access_token,
        refresh_token,
        expires_in,
      },
      true
    );
    yield put(actions.setIntranetLogin());
  } catch (exception) {}
}

/*SAML - END*/

export function* doGenericLogin({ data }) {
  oauth.clearAppStorage();
  try {
    const resp = yield http.post("/oauth/token", qs.stringify(data));
    const { access_token, refresh_token, expires_in } = resp.data;

    document.cookie = "Bearer=" + access_token + ";path=/";

    oauth.setToken(
      {
        access_token,
        refresh_token,
        expires_in,
      },
      true
    );
    yield put(actions.successLogin());
  } catch (exception) {
    yield put(actions.failCredentials());
  }
}

//KEYCLOAK AUTH
export function* doKeycloakSignIn({ data }) {
  try {
    let response = yield http.get("/api/open/config/keycloak-auth");
    if (response.data.keycloakAuth) {
      yield put(actions.setAuthKeycloakAuthConfig(true));
    } else {
      yield put(actions.setAuthKeycloakAuthConfig(false));
    }
  } catch (exception) {
    yield put(actions.setAuthKeycloakAuthConfig(false));
  }
}

//WINDOWS AUTH
export function* doWindowsSignIn({ data }) {
  try {
    let login = data.login;
    let uuid = data.uuid;

    if (login !== undefined && login !== null) {
      let responseAux = "";

      if (data.platform === "windows") {
        let obj = {
          username: login,
        };
        responseAux = yield http.post(
          "/api/open/config/" + data.platform + "-auth/" + uuid,
          obj
        );
      } else {
        responseAux = yield http.get(
          "/api/open/config/" + data.platform + "-auth/" + login + "/" + uuid
        );
      }

      responseAux.data.grant_type = GRANT_TYPE_PASSWORD;
      if (data.platform === "windows") {
        window.history.pushState({}, "Uniksystem", window.location.pathname);
        yield put(actions.setAuthWindowsAuthConfig(false));
      } else if (data.platform === "keycloak") {
        window.history.pushState({}, "Uniksystem", window.location.pathname);
        yield put(actions.setAuthKeycloakAuthConfig(false));
      }
      yield call(doGenericLogin, responseAux);
    } else {
      yield put(actions.setAuthWindowsAuthConfig(false));
      yield put(actions.setAuthKeycloakAuthConfig(false));
      yield put(actions.failCredentials());
    }
  } catch (exception) {
    yield put(actions.setAuthKeycloakAuthConfig(false));
    yield put(actions.setAuthWindowsAuthConfig(false));
    yield put(actions.failCredentials());
  }
}

export function* doWindowsSignInIFlow({ data }) {
  try {
    let response = yield http.get("/api/open/config/windows-auth");
    if (response.data.windowsAuth) {
      let response1 = yield http.get(
        `/AuthenticationServlet?windows-platform=true`,
        IFLOW_APP
      );

      let payload = {
        isToSkipConfigs: true,
        login: response1.headers.esliflow,
        uuid: "xpto456",
        history: data.history,
        platform: "windows",
      };
      yield call(doWindowsSignIn, { data: payload });

      yield put(actions.setAuthWindowsAuthConfig(true));
    } else {
      yield put(actions.setAuthWindowsAuthConfig(false));
    }
  } catch (exception) {
    yield put(actions.setAuthWindowsAuthConfig(false));
  }
}

export function* doWindowsSignInSignPdf({ data }) {
  try {
    let response = yield http.get("/api/open/config/windows-auth");
    if (response.data.windowsAuth) {
      let token = "xpto456";

      let response1 = yield axios.get(
        `${SIGN_PDF_LOCAL_APP_URL}/get-user-from-windows/${token}`
      );

      let body = {
        username: response1?.data?.username,
      };

      let response2 = yield http.post(
        `/api/open/config/windows-auth/verify-user-with-domain/xpto456`,
        body
      );

      if (response2?.data?.validUser) {
        let payload = {
          isToSkipConfigs: true,
          login: response2?.data?.encryptedUsername,
          uuid: "xpto456",
          history: data.history,
          platform: "windows",
        };
        yield call(doWindowsSignIn, { data: payload });

        yield put(actions.setAuthWindowsAuthConfig(true));
      } else {
        yield put(actions.setAuthWindowsAuthConfig(false));
        yield put(actions.failCredentials());
      }
    } else {
      yield put(actions.setAuthWindowsAuthConfig(false));
    }
  } catch (exception) {
    yield put(actions.setAuthWindowsAuthConfig(false));
    yield put(actions.failCredentials());
  }
}

export function* throwsException(exception, isldpa) {
  const { response } = exception;
  console.log("throwsException", response);
  if (!response) {
    yield put(actions.failRemoteServer());
    if(isldpa == undefined || isldpa == false)
    yield put(toastActiveError("login.error.connectionRemoteServer"));
  } else if (exception.message === "Network Error") {
    yield put(actions.failRemoteServer());
    if(isldpa == undefined || isldpa == false)
    yield put(toastActiveError("login.error.connectionRemoteServer"));
  } else if (response && response.data.error === "invalid_grant") {
    yield put(actions.failCredentials());
    if(isldpa == undefined || isldpa == false)
    yield put(toastActiveWarning("login.error.badCredentials"));
  } else if (response && (response.status === 428 || response.status === 417)) {
    yield put(actions.failCredentials());
    if(isldpa == undefined || isldpa == false)
    yield put(toastActiveWarning("login.error.badCredentials"));
  } else if (response && response.status === 412) {
    yield put(actions.failRemoteServer());
    if(isldpa == undefined || isldpa == false)
    yield put(toastActiveError("login.error.connectionRemoteServer"));
  }
}

export function* requestLogout(data) {
  //yield http.get('api/auth/logout');
  yield http.get("/api/auth/logout/" + data.username);
  yield put(actions.setAuthWindowsAuthConfig(false));
  oauth.logout();
}
