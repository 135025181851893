import { put } from 'redux-saga/effects';
import http from '../../../utils/config/http';

import {
  setOnlineUsers,
  setChat,
  savedMessage
} from '../actions';

export function* findOnlineUsers() {
  const resp = yield http.get('/api/mongo/chat/online-users');
  yield put(setOnlineUsers(resp.data));
}

export function* findChat({ payload }) {
  const sender = `sender=${payload.sender}`;
  const receiver = `receiver=${payload.receiver}`;
  try {
    const url = `/api/mongo/chat/read?${sender}&${receiver}`;
    const resp = yield http.get(url);
    yield put(setChat(resp.data));
  } catch (e) {
    yield put(setChat({}));
  }
}

export function* initChat({ chat }) {
  try {
    const resp = yield http.put('/api/mongo/chat/send-message', chat);
    yield put(setChat(resp.data));
  } catch (e) {
    yield put(savedMessage(false));
  }
}

export function* sendMessage({ chat }) {
  try {
    const resp = yield http.put('/api/mongo/chat/send-message', chat);
    yield put(savedMessage(true));
  } catch (e) {
    yield put(savedMessage(false));
  }
}