export const adaptListForOperations = (operations, label) => {
  const dropdownList = operations.map((op) => {
    return { label: op.name, value: op.id.toString(), selected: false };
  });

  dropdownList.push({
    label: "",
    value: "",
    selected: false,
  });

  dropdownList.unshift({
    label,
    value: "",
    selected: false,
  });

  return dropdownList;
};

export const adaptListForDocType = (operations, selectedOperation, label) => {
  const selectedOperation_ = operations.find(
    (op) => op.id == selectedOperation
  );

  if (selectedOperation_ && selectedOperation_.documentClassList) {
    const dropdownList = selectedOperation_.documentClassList.map((doc) => {
      return { label: doc.name, value: doc.id.toString(), selected: false };
    });

    dropdownList.push({
      label: "",
      value: "",
      selected: false,
    });

    dropdownList.unshift({
      label,
      value: "",
      selected: false,
    });

    return dropdownList;
  }
};

export const adaptListFoEntities = (entities) => {
  console.log(entities, "entities1");
  const dropdownList = entities.map((ent) => {
    return { label: ent.nome, value: ent.id.toString(), selected: false };
  });

  return dropdownList;
};
