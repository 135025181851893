import React from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_radioButton.scss";

const RadioButton = ({
  disabled = false,
  name,
  value,
  id,
  checked,
  onClick,
  subHeader,
  label,
  t,
  displayType
}) => {
  return (
    <div className={`easypay-radio-button-wrapper ${displayType}`}>
      <div className="easypay-radio-button">
        <input
          className="easypay-radio-button-circle"
          disabled={disabled}
          type="radio"
          id={id}
          name={name}
          value={value}
          defaultChecked={checked}
          onClick={() => (onClick ? onClick() : "")}
        />
        <label
          className="easypay-radio-button-label"
          disabled={disabled}
          htmlFor={id}
        >
          {label ? `${t(label)}` : `${t(value)}`}
        </label>
      </div>
      {subHeader !== "" && (
        <div
          className={`easypay-radio-button-subheader easypay-${
            subHeader?.split("::")[1]
          }`}
        >
          {t(subHeader?.split("::")[0])}
        </div>
      )}
    </div>
  );
};
export default withNamespaces()(RadioButton);
