import React, { useState, useRef } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Overlay,
  Popover,
  Card,
} from "react-bootstrap";
import PreviewPopover from "./PreviewPopover";
import { withNamespaces } from "react-i18next";

const CreateActionsColumn = (props) => {
  const { t, row, handleOpenFlowModal } = props;

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleMouseEnter = (event) => {
    setShow(true);
    setTarget(event.target);
  };
  const handleMouseLeave = (event) => {
    setShow(false);
    setTarget(null);
  };

  return (
    <div style={{ display: "flex" }}>
      <div ref={ref}>
        <i
          className="icon-detail table-action-icon"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <Overlay
          show={show}
          target={target}
          placement="left"
          container={ref.current}
          containerPadding={20}
        >
          <Popover id="popover-contained" bsPrefix="tasks-popover">
            <Card style={{ width: "300px" }}>
              <Card.Header>
                {t("taskPage.general.process")} &nbsp;{row && row.pnumber}
              </Card.Header>
              <Card.Body>
                <PreviewPopover task={row} />
              </Card.Body>
            </Card>
          </Popover>
        </Overlay>
      </div>

      <div>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("taskPage.general.enterProcess"))}
        >
          <i
            className="icon-edit table-action-icon"
            onClick={() => handleOpenFlowModal(row)}
          />
        </OverlayTrigger>
      </div>
    </div>
  );
};
export default withNamespaces()(CreateActionsColumn);
