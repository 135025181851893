import React, { useState } from "react";
import { Jumbotron, Col, Form, Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { templates } from "./templates";
import { isEmpty } from "lodash";
import Dropdown from "~/components/Dropdown";
import RemoveButton from "~/components/Buttons/RemoveButton";

const DatacaptureConfiguration = (props) => {
  const {
    t,
    docIndex = "",
    docType = "",
    label = "",
    maxNumFiles = 1,
    flowId = "",
    firstMetadataLabel = "",
    firstMetadataValue = "",
    metadata = [],
    setDocType,
    setLabel,
    setMaxNumFiles,
    setFlowId,
    setFirstMetadataLabel,
    setFirstMetadataValue,
    setMetadata,
    deleteDocType,
    deprecated = false,
    setDeprecated,
    focus,
  } = props;

  const [showMetadataLines, setShowMetadataLines] = useState(false);

  const renderTemplateOptions = () => {
    return templates
      ? templates.map((templ) => {
          return { value: templ.label, label: templ.label, selected: false };
        })
      : { value: "", label: "", selected: false };
  };

  const handleTemplate = (template_) => {
    const foundTemplate = templates.find((t) => t.label === template_);
    //clone template
    const template = JSON.parse(JSON.stringify(foundTemplate));
    if (template && template.items && template.items.length > 0) {
      setFirstMetadataLabel(docIndex, template.items[0].label);
      setFirstMetadataValue(docIndex, template.items[0].value);
      setMetadata(
        docIndex,
        template.items.filter((t, i) => i != 0)
      );
    }
  };

  const formGroupStyle = {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    margin: "0.25rem",
  };

  const addMetadata = (e, label, value) => {
    e.stopPropagation();
    e.preventDefault();
    if (!metadata.some((data) => data.value === value)) {
      const newMetadata = [
        ...metadata,
        {
          label: label,
          value: value,
        },
      ];
      setMetadata(docIndex, newMetadata);
    }
  };

  const renderMetadataLines = () => {
    const handleDeleteMetadata = (e, data) => {
      e.stopPropagation();
      e.preventDefault();
      const newMetadata = metadata.filter((d) => d.value !== data.value);
      setMetadata(docIndex, newMetadata);
    };

    const updateMetadata = (index, value, metadata, propname) => {
      return metadata.map((d, i) => {
        if (i === index) {
          d[propname] = value;
        }
        return d;
      });
    };

    const handleLabelChange = (index, label) => {
      setMetadata(updateMetadata(index, label, metadata, "label"));
    };

    const handleValueChange = (index, value) => {
      setMetadata(updateMetadata(index, value, metadata, "value"));
    };

    const handleChildLabelChange = (index, childindex, childlabel) => {
      const foundItem = metadata.find((d, i) => i === index);
      if (foundItem) {
        let newArray = [];
        if (foundItem.children) {
          newArray = foundItem.children.map((c, i) => {
            if (i === childindex) {
              c.label = childlabel;
            }
            return c;
          });
        } else {
          newArray = [
            {
              label: childindex,
              value: "",
            },
          ];
        }
        setMetadata(updateMetadata(index, newArray, metadata, "children"));
      }
    };

    const handleChildValueChange = (index, childindex, childvalue) => {
      const foundItem = metadata.find((d, i) => i === index);
      if (foundItem) {
        let newArray = [];
        if (foundItem.children) {
          newArray = foundItem.children.map((c, i) => {
            if (i === childindex) {
              c.value = childvalue;
            }
            return c;
          });
        } else {
          newArray = [
            {
              label: "",
              value: childvalue,
            },
          ];
        }
        setMetadata(updateMetadata(index, newArray, metadata, "children"));
      }
    };

    const handleDeleteMetadataChild = (e, index, childindex) => {
      e.stopPropagation();
      e.preventDefault();
      const foundItem = metadata.find((d, i) => i === index);
      if (foundItem) {
        if (foundItem.children) {
          let newArray = [];
          newArray = foundItem.children.filter((c, i) => i !== childindex);
          setMetadata(updateMetadata(index, newArray, metadata, "children"));
        }
      }
    };

    const handleAddNestedMetadata = (e, index) => {
      e.stopPropagation();
      e.preventDefault();
      const foundItem = metadata.find((d, i) => i === index);
      let newArray = [];
      if (foundItem) {
        if (foundItem.children) newArray = [...foundItem.children];
        newArray.push({
          label: "",
          value: "",
        });
        setMetadata(updateMetadata(index, newArray, metadata, "children"));
      }
    };

    return (
      metadata &&
      metadata.map((data, index) => {
        return (
          !data.parentId && (
            <>
              <Form.Row
                style={{
                  flexWrap: "nowrap",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
                key={index}
              >
                <Form.Group controlId="metadatalabel" style={formGroupStyle}>
                  <Form.Control
                    type="text"
                    value={data.label}
                    onChange={(e) => handleLabelChange(index, e.target.value)}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group controlId="metadatavalue" style={formGroupStyle}>
                  <Form.Control
                    type="text"
                    value={data.value}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    autoComplete="off"
                  />
                </Form.Group>
                <i
                  className="fas fa-list-ul mr-2 table-action-icon"
                  style={{ marginBottom: "10px" }}
                  onClick={(e) => handleAddNestedMetadata(e, index)}
                ></i>
                <i
                  className="icon-trash mr-2 table-action-red-icon"
                  style={{ marginBottom: "10px" }}
                  onClick={(e) => handleDeleteMetadata(e, data)}
                ></i>
              </Form.Row>
              {!isEmpty(data.children) && (
                <Form.Row
                  style={{
                    flexWrap: "nowrap",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    backgroundColor: "#c3c3c3",
                    borderRadius: "23px",
                  }}
                >
                  <Form.Label>Nested fields for {data.label}</Form.Label>
                  <Form.Row style={{ flexWrap: "nowrap" }}>
                    <Form.Group
                      controlId="metadatalabel1"
                      style={formGroupStyle}
                    >
                      <Form.Label>
                        {t("admin.organization.settings.metadatalabel")}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group
                      controlId="metadatavalue1"
                      style={formGroupStyle}
                    >
                      <Form.Label>
                        {t("admin.organization.settings.metadatavalue")}
                      </Form.Label>
                    </Form.Group>
                    <i
                      className="icon-trash mr-2 table-action-icon"
                      style={{ opacity: 0 }}
                    />
                  </Form.Row>
                  {data.children.map((child, childindex) => {
                    return (
                      <Form.Row
                        style={{
                          flexWrap: "nowrap",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                        key={childindex}
                      >
                        <Form.Group
                          controlId="metadatalabel"
                          style={formGroupStyle}
                        >
                          <Form.Control
                            type="text"
                            value={child.label}
                            onChange={(e) =>
                              handleChildLabelChange(
                                index,
                                childindex,
                                e.target.value
                              )
                            }
                            autoComplete="off"
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="metadatavalue"
                          style={formGroupStyle}
                        >
                          <Form.Control
                            type="text"
                            value={child.value}
                            onChange={(e) =>
                              handleChildValueChange(
                                index,
                                childindex,
                                e.target.value
                              )
                            }
                            autoComplete="off"
                          />
                        </Form.Group>
                        <i
                          className="icon-trash mr-2 table-action-red-icon"
                          style={{ marginBottom: "10px" }}
                          onClick={(e) =>
                            handleDeleteMetadataChild(e, index, childindex)
                          }
                        ></i>
                      </Form.Row>
                    );
                  })}
                </Form.Row>
              )}
            </>
          )
        );
      })
    );
  };

  return (
    <Col lg="12" style={{ padding: "2rem 0" }}>
      <Jumbotron
        style={{
          padding: "2.5rem",
          marginBottom: "1rem",
          display: "flex",
          borderRadius: "23px",
        }}
      >
        <Col lg="9" style={{ padding: "1rem 0" }}>
          <Form.Row
            style={{
              flexWrap: "nowrap",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Form.Group controlId="doctype" style={formGroupStyle}>
              <Form.Label>
                {t("admin.organization.settings.docType")}
              </Form.Label>
              <Form.Control
                type="text"
                autoFocus={focus ? true : false}
                value={docType}
                onChange={(e) => setDocType(docIndex, e.target.value)}
                required
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {t("admin.organization.settings.requiredDocType")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="doctype" style={formGroupStyle}>
              <Form.Label>{t("admin.organization.settings.label")}</Form.Label>
              <Form.Control
                type="text"
                value={label}
                onChange={(e) => setLabel(docIndex, e.target.value)}
                required
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {t("admin.organization.settings.requiredLabel")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="flowId" style={formGroupStyle}>
              <Form.Label>{t("admin.organization.settings.flowId")}</Form.Label>
              <Form.Control
                type="text"
                value={flowId}
                onChange={(e) => setFlowId(docIndex, e.target.value)}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {t("admin.organization.settings.requiredFlowId")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="maxNumberFiles" style={formGroupStyle}>
              <Form.Label>
                {t("admin.organization.settings.maxNumFiles")}
              </Form.Label>
              <Form.Control
                type="text"
                value={maxNumFiles}
                onChange={(e) => setMaxNumFiles(docIndex, e.target.value)}
                required
                autoComplete="off"
              />
            </Form.Group>
            <i
              className="icon-trash mr-2 table-action-icon"
              style={{ opacity: 0 }}
            />
          </Form.Row>

          <Form.Label
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h5>Metadata</h5>
          </Form.Label>
          <Form.Row style={{ flexWrap: "nowrap" }}>
            <Form.Group controlId="metadatalabel1" style={formGroupStyle}>
              <Form.Label style={{ marginRight: "10px", flexWrap: "nowrap" }}>
                Select template to fill in fields
              </Form.Label>

              <Dropdown
                list={renderTemplateOptions()}
                handleSubmit={handleTemplate}
                emptyDefault={true}
              />
            </Form.Group>
            <i
              className="icon-trash mr-2 table-action-icon"
              style={{ opacity: 0 }}
            />
          </Form.Row>
          <Form.Row style={{ flexWrap: "nowrap" }}>
            <Form.Group controlId="metadatalabel1" style={formGroupStyle}>
              <Form.Label style={{ marginRight: "10px", flexWrap: "nowrap" }}>
                or insert fields manually
              </Form.Label>
            </Form.Group>
            <i
              className="icon-trash mr-2 table-action-icon"
              style={{ opacity: 0 }}
            />
          </Form.Row>

          <Form.Row style={{ flexWrap: "nowrap" }}>
            <Form.Group controlId="metadatalabel1" style={formGroupStyle}>
              <Form.Label>
                {t("admin.organization.settings.metadatalabel")}
              </Form.Label>
              <Form.Control
                type="text"
                value={firstMetadataLabel}
                onChange={(e) =>
                  setFirstMetadataLabel(docIndex, e.target.value)
                }
                required
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="metadatavalue1" style={formGroupStyle}>
              <Form.Label>
                {t("admin.organization.settings.metadatavalue")}
              </Form.Label>
              <Form.Control
                type="text"
                value={firstMetadataValue}
                onChange={(e) =>
                  setFirstMetadataValue(docIndex, e.target.value)
                }
                required
                autoComplete="off"
              />
            </Form.Group>
            <i
              className="icon-trash mr-2 table-action-icon"
              style={{ opacity: 0 }}
            />
          </Form.Row>
          {metadata.length > 0 && (
            <Form.Label
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setShowMetadataLines((show) => !show);
                }}
                variant="link"
              >
                {showMetadataLines ? "Show less" : "Show more"}
              </Button>
            </Form.Label>
          )}
          {metadata.length > 0 && showMetadataLines && renderMetadataLines()}
          {firstMetadataValue !== "" && firstMetadataLabel !== "" && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addMetadata(e, "", "");
                metadata.length > 0 && setShowMetadataLines(true);
              }}
              style={{ marginTop: "10px" }}
              className="icon-button"
            >
              <i className="icon-add text-success" />
            </button>
          )}
        </Col>
        <Col
          style={{
            padding: "1rem 0.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <RemoveButton
            text="Delete document type"
            onClick={() => deleteDocType(docIndex)}
          />
          <br />
          <div style={{ display: "flex" }}>
            <Form.Check
              id={`toggle-deprecate_${docIndex}`}
              type="switch"
              label=""
              checked={deprecated}
              onChange={() => {
                setDeprecated(docIndex, !deprecated);
              }}
            />
            <div className="error-message" style={{ fontWeight: "600" }}>
              Deprecated document type
            </div>
          </div>
        </Col>
      </Jumbotron>
    </Col>
  );
};
export default withNamespaces()(DatacaptureConfiguration);
