import {
  SET_FOLDERS,
  FIND_FOLDERS,
  SET_UPDATE_FOLDER,
  SET_SELECTED_ROW,
  SET_IS_EDITING_ACTIVITY_FOLDER,
} from "./actionTypes";

const initialState = {
  folders: [],
  isLoadingFolders: false,
  successUpdate: false,
  isEditActivityFolder: false,

  selectedRow: null,
};

export function taskFoldersReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_FOLDERS:
      return { ...state, isLoadingFolders: true };
    case SET_FOLDERS:
      return { ...state, folders: action.data, isLoadingFolders: false };

    case SET_UPDATE_FOLDER:
      return { ...state, successUpdate: action.data };

    case SET_SELECTED_ROW:
      return { ...state, selectedRow: action.data };

    case SET_IS_EDITING_ACTIVITY_FOLDER:
      return { ...state, isEditActivityFolder: action.data };
    default:
      return state;
  }
}
