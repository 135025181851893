import React, { useEffect, useState } from 'react';
import WidgetContainer from '~/components/WidgetContainer';
import IframeHandler from '~/components/IframeHandler';
import AllIframesComponent from '~/components/AllIframesComponent';
import ProtectedPage from '~/components/security/ProtectedPage';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import ApplicationMenu from '../Menus/components/ApplicationMenu';
import { GDPR_MIDDLEWARE } from "~/utils/constants";

const GDPR = (props) => {
  const { isMongoOn } = props;
  const [showMenu, setShowMenu] = useState(false);
  let { path } = useRouteMatch();

  useEffect(() => {
    function handleSUEvent(e) {
      console.log(e.detail);
      console.log("constructing menu...")
      setShowMenu(true);
    }

    function handleWaitAppResponse(e) {
      console.log("constructing menu from response...")
      setShowMenu(true);
    }

    window.addEventListener("SUEvent", handleSUEvent);
    window.addEventListener("WaitAppResponse", handleWaitAppResponse);
    /*return () => {
      window.removeEventListener("SUEvent",handleSUEvent, true);
      window.removeEventListener("WaitAppResponse", handleWaitAppResponse, true);
    }*/
  }, [])

  useEffect(() => {
    if (document.getElementById("gdpr")) {
      if (document.getElementById("gdpr").contentWindow) {
        document.getElementById("gdpr").contentWindow.dispatchEvent(new CustomEvent("GetAppResponse", { detail: 'from: gdpr' }))
        console.log("after disptaching GetAppResponse to iframe gdpr")
      }
    }
  })

  return (
    <>
      <AllIframesComponent name="gdpr" />
      <Switch>
        <Route path={`${path}/tarefas`}>
          <ProtectedPage roles={["ADMIN", "GDPRUser"]}>
            <IframeHandler name="gdpr" />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos`}>
          <ProtectedPage roles={["ADMIN", "GDPRUser"]}>
            <IframeHandler name="gdpr" />
          </ProtectedPage>
        </Route>
        <Route path={`${path}/pesquisar`}>
          <ProtectedPage roles={["ADMIN", "GDPRUser"]}>
            <IframeHandler name="gdpr" />
          </ProtectedPage>
        </Route>
        <Route path={path}>
          <ProtectedPage roles={["ADMIN", "GDPRUser"]}>
            <>
              <ApplicationMenu
                applicationName={GDPR_MIDDLEWARE.context}
                showMenu={showMenu}
              />
              {isMongoOn &&
                <WidgetContainer />
              }
            </>
          </ProtectedPage>
        </Route>
      </Switch>
    </>
  )
}
export default GDPR;
