import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/scss/_headerTitle.scss";
import "../assets/scss/_liftworldPage.scss";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import {
  saveClientsData,
  saveProvidersData,
  deleteClientData,
  deleteProviderData,
  getAllCompanies,
  getAllPaymentTypes,
} from "~/store/ducks/budgetManagement/actionTypes";

import Table from "../components/AuxTable";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import AddButton from "~/components/Buttons/AddButton";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import ModalEasyPay from "~/pages/Easypay/components/Modal";
import uuid from "uuid/v1";
import ModalClient from "./ModalNewClient";
import ModalProvider from "./ModalNewProvider";
import ModalUpdateClient from "./ModalUpdateClient";
import Messages from "~/components/Messages";
import ModalUpdateProvider from "./ModalUpdateProvider";
import Modal from "~/pages/Easypay/components/Modal";
import {
  getClients,
  getProviders,
} from "~/store/ducks/budgetManagement/actionTypes";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";

const AuxTablesPage = () => {
  const {
    allClients,
    allProviders,
    isLoadingEditProviders,
    isLoadingEditClient,
    stopDeletingClients,
    stopDeletingProviders,
    isLoadingSaveClients,
    isLoadingSaveProviders,
    companies,
    paymentTypes,
  } = useSelector((state) => state.budgetManagementReducer);

  let { activeSuccess, activeError } = useSelector(
    (state) => state.messagesReducer
  );

  const [isModalUpdateClientShowing, setIsModalUpdateClientShowing] =
    useState(false);
  const [isModalUpdateProviderShowing, setIsModalUpdateProviderShowing] =
    useState(false);
  const [isModalDeleteClientShowing, setIsModalDeleteClientShowing] =
    useState(false);
  const [isModalDeleteProviderShowing, setIsModalDeleteProviderShowing] =
    useState(false);
  const [isModalNewClientShowing, setIsModalNewClientShowing] = useState(false);
  const [isModalNewProviderShowing, setIsModalNewProviderShowing] =
    useState(false);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [isModalSucessShowing, setIsModalSucessShowing] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [clientObject, setClientObject] = useState({});
  const [providerObject, setProviderObject] = useState({});
  const [tableSize, setTableSize] = useState(3);
  const [newClient, setNewClient] = useState({});

  const [newProvider, setNewProvider] = useState({});
  const [companiesList, setCompaniesList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const dispatch = useDispatch();

  const clientTypeList = [
    { label: "Cliente", value: "Cliente" },
    { label: "Contacto", value: "Contacto" },
  ];

  useEffect(() => {
    dispatch(getClients());
  }, [isLoadingEditClient, stopDeletingClients, isLoadingSaveClients]);

  useEffect(() => {
    dispatch(getProviders());
  }, [isLoadingEditProviders, stopDeletingProviders, isLoadingSaveProviders]);

  useEffect(() => {
    dispatch(getAllCompanies());
    dispatch(getAllPaymentTypes());
  }, []);

  const handleDeleteClient = () => {
    const clientId = clientObject.idCliente;

    if (clientId !== null && clientId !== undefined) {
      dispatch(deleteClientData(clientId));
      setIsModalDeleteClientShowing(false);
    }
  };

  const handleDeleteProvider = () => {
    const providerId = providerObject.idFornecedor;

    if (providerId !== null && providerId !== undefined) {
      dispatch(deleteProviderData(providerId));
      setIsModalDeleteProviderShowing(false);
    }
  };

  const takeNewClient = (data) => {
    setNewClient(data);
  };

  const takeNewProvider = (data) => {
    setNewProvider(data);
  };

  useEffect(() => {
    if (Object.keys(newClient).length > 0) {
      dispatch(saveClientsData(newClient));
    }
  }, [newClient]);

  useEffect(() => {
    if (Object.keys(newProvider).length > 0) {
      dispatch(saveProvidersData(newProvider));
    }
  }, [newProvider]);

  useEffect(() => {
    if (Object.keys(companies).length > 0) {
      const listFiltered = companies.filter((e) => e.organizationId);

      const newArray = listFiltered.map(function (e) {
        return {
          label: e.codigoEmpresa,
          value: e.organizationId.toString(),
        };
      });
      setCompaniesList(newArray);
    }
  }, [companies]);

  useEffect(() => {
    if (Object.keys(paymentTypes).length > 0) {
      const listFiltered = paymentTypes.filter((e) => e.idTipoPagamento);

      const newArray = listFiltered.map(function (e) {
        return {
          label: e.nome,
          value: e.idTipoPagamento.toString(),
        };
      });
      setPaymentTypeList(newArray);
    }
  }, [paymentTypes]);

  useEffect(() => {
    if (activeSuccess) {
      setIsModalSucessShowing(true);
      setTimeout(() => {
        setIsModalSucessShowing(false);
      }, 5000);
    }
    if (activeError) {
      setIsModalErrorShowing(true);
      setTimeout(() => {
        setIsModalErrorShowing(false);
      }, 5000);
    }

    // if (!activeSuccess) {
    //   setIsModalSucessShowing(false);
    // }

    // if (!activeError) {
    //   setIsModalErrorShowing(false);
    // }
  }, [
    activeSuccess,
    activeError,
    isLoadingSaveClients,
    isLoadingEditClient,
    isLoadingEditProviders,
    isLoadingSaveProviders,
  ]);

  function createBodyDeleteClient() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <div style={{ fontWeight: "bold" }}>
            Tem a certeza que deseja apagar ?
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              paddingTop: "15px",
            }}
          >
            <div style={{ marginRight: "30px" }}>
              <SimpleButton
                text={"Sim"}
                variant={"liftworld-button-primary"}
                onClick={() => handleDeleteClient()}
              />
            </div>{" "}
            <div>
              <SimpleButton
                text={"Não"}
                variant={"liftworld-button-primary"}
                onClick={() => setIsModalDeleteClientShowing(false)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function createBodyDeleteProvider() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <div style={{ fontWeight: "bold" }}>
            Tem a certeza que deseja apagar?
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              paddingTop: "15px",
            }}
          >
            <div style={{ marginRight: "30px" }}>
              <SimpleButton
                text={"Sim"}
                variant={"liftworld-button-primary"}
                onClick={() => handleDeleteProvider()}
              />
            </div>{" "}
            <div>
              <SimpleButton
                text={"Não"}
                variant={"liftworld-button-primary"}
                onClick={() => setIsModalDeleteProviderShowing(false)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const takeClientObject = (data) => {
    setClientObject(data);
  };

  const takeProviderObject = (data) => {
    setProviderObject(data);
  };

  const handleModalDeleteClient = () => {
    setIsModalDeleteClientShowing(true);
    setIsEdit(true);
    setIsSelected(false);
  };

  const handleModalDeleteProvider = () => {
    setIsModalDeleteProviderShowing(true);
    setIsEdit(true);
    setIsSelected(false);
  };

  //new Client

  const makeActiveFormatterClient = (cell, row, rowIndex, formatExtraData) => {
    return (
      <ProtectedComponent
        permissions={[{ label: "app-budgetmgr-workplan-update" }]}
      >
        <SimpleButton
          text={"Alterar"}
          variant={"easypay-button-link"}
          onClick={() => setIsModalUpdateClientShowing(true)}
        />
      </ProtectedComponent>
    );
  };
  const makeActiveFormatterProvider = (
    cell,
    row,
    rowIndex,
    formatExtraData
  ) => {
    return (
      <ProtectedComponent
        permissions={[{ label: "app-budgetmgr-workplan-update" }]}
      >
        <SimpleButton
          text={"Alterar"}
          variant={"easypay-button-link"}
          onClick={() => setIsModalUpdateProviderShowing(true)}
        />
      </ProtectedComponent>
    );
  };

  // const deleteWorkplanFormatterClient = (
  //   cell,
  //   row,
  //   rowIndex,
  //   formatExtraData
  // ) => {
  //   return (
  //     <ProtectedComponent
  //       permissions={[{ label: "app-budgetmgr-workplan-delete" }]}
  //     >
  //       <SimpleButton
  //         text={"Apagar"}
  //         variant={"easypay-button-link"}
  //         onClick={handleModalDeleteClient}
  //       />
  //     </ProtectedComponent>
  //   );
  // };

  // const deleteWorkplanFormatterProvider = (
  //   cell,
  //   row,
  //   rowIndex,
  //   formatExtraData
  // ) => {
  //   return (
  //     <ProtectedComponent
  //       permissions={[{ label: "app-budgetmgr-workplan-delete" }]}
  //     >
  //       <SimpleButton
  //         text={"Apagar"}
  //         variant={"easypay-button-link"}
  //         onClick={handleModalDeleteProvider}
  //       />
  //     </ProtectedComponent>
  //   );
  // };

  const modalIsClosedClient = (bollean) => {
    setIsModalNewClientShowing(bollean);
  };

  const modalIsClosedProvider = (bollean) => {
    setIsModalNewProviderShowing(bollean);
  };

  const modalUpdateIsClosedClient = (bollean) => {
    setIsModalUpdateClientShowing(bollean);
  };

  const modalUpdateIsClosedProvider = (bollean) => {
    setIsModalUpdateProviderShowing(bollean);
  };

  const clientColumns = [
    {
      dataField: "year",
      text: "Ano",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "nome",
      text: "Nome",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "morada",
      text: "Morada",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "nif",
      text: "NIF",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "empresa",
      text: "Empresa",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "formaPagamento",
      text: "Forma de Pagamento",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "prazoPagamento",
      text: "Prazo de Pagamento",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "tipoCliente",
      text: "Tipo de Cliente",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "numCliente",
      text: "Nº Cliente",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "editAction",
      text: "",
      sort: false,
      formatter: makeActiveFormatterClient,
      headerStyle: { width: "fit-content" },
      editable: false,
    },
    {
      dataField: "deleteAction",
      text: "",
      sort: false,
      // formatter: deleteWorkplanFormatterClient,
      headerStyle: { width: "fit-content" },
      editable: false,
    },
  ];

  const providerColumns = [
    {
      dataField: "year",
      text: "Ano",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "nome",
      text: "Nome",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "morada",
      text: "Morada",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "empresa",
      text: "Empresa",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "prazoPagamento",
      text: "Prazo de Pagamento",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "numFornecedor",
      text: "Nº Fornecedor",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "editAction",
      text: "",
      sort: false,
      formatter: makeActiveFormatterProvider,
      headerStyle: { width: "fit-content" },
      editable: false,
    },
    {
      dataField: "deleteAction",
      text: "",
      sort: false,
      // formatter: deleteWorkplanFormatterProvider,
      headerStyle: { width: "fit-content" },
      editable: false,
    },
  ];

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div>
          <ProvaDeVida text="Erro ao Submeter" status="error" />
        </div>
      </div>
    );
  }

  function createBodySave() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Alterações guardadas" status="success" />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          paddingLeft: "60px",
          paddingRight: "60px",
          paddingTop: "60px",
          paddingBottom: "60px",
          background: "white",
        }}
      >
        <ModalEasyPay
          className="easypay-modal"
          showModal={isModalDeleteClientShowing}
          setShowModal={setIsModalDeleteClientShowing}
          headerContent={"Confirmação"}
          bodyContent={createBodyDeleteClient()}
          key={uuid()}
        ></ModalEasyPay>

        <ModalEasyPay
          className="easypay-modal"
          showModal={isModalDeleteProviderShowing}
          setShowModal={setIsModalDeleteProviderShowing}
          headerContent={"Confirmação"}
          bodyContent={createBodyDeleteProvider()}
          key={uuid()}
        ></ModalEasyPay>

        <div className="liftworld-header">TABELAS AUXILIARES</div>
        <TabSeparatorWrapper>
          <TabSeparator
            text="Clientes"
            name="tab1"
            variant={"liftworld-tab-wrapper"}
          >
            <Table
              docs={allClients}
              columns={clientColumns}
              tableSize={tableSize}
              rowSelected={isSelected}
              setTableSize={setTableSize}
              takeObject={takeClientObject}
              idClient="idCliente"
            />
            <div style={{ padding: "50px" }}>
              <ProtectedComponent
                permissions={[{ label: "app-budgetmgr-workplan-create" }]}
              >
                <AddButton
                  text={"Adicionar Cliente"}
                  onClick={() => setIsModalNewClientShowing(true)}
                />
              </ProtectedComponent>
            </div>
          </TabSeparator>
          <TabSeparator
            text="Fornecedores"
            name="tab2"
            variant={"liftworld-tab-wrapper"}
          >
            <Table
              docs={allProviders}
              columns={providerColumns}
              // editCell={true}
              // editMod={isEdit}
              rowSelected={isSelected}
              tableSize={tableSize}
              setTableSize={setTableSize}
              takeObject={takeProviderObject}
              idProvider="idFornecedor"
            />
            <div style={{ padding: "50px" }}>
              <ProtectedComponent
                permissions={[{ label: "app-budgetmgr-workplan-create" }]}
              >
                <AddButton
                  text={"Adicionar Fornecedor"}
                  onClick={() => setIsModalNewProviderShowing(true)}
                />
              </ProtectedComponent>
            </div>
          </TabSeparator>
        </TabSeparatorWrapper>
        <ModalClient
          isClicked={isModalNewClientShowing}
          isClosed={modalIsClosedClient}
          takeNewClient={takeNewClient}
          companiesList={companiesList}
          paymentTypeList={paymentTypeList}
          clientTypeList={clientTypeList}
        />

        <ModalProvider
          isClicked={isModalNewProviderShowing}
          isClosed={modalIsClosedProvider}
          takeNewProvider={takeNewProvider}
          companiesList={companiesList}
        />

        <ModalUpdateClient
          isClicked={isModalUpdateClientShowing}
          isClosed={modalUpdateIsClosedClient}
          companiesList={companiesList}
          rowObject={clientObject}
          paymentTypeList={paymentTypeList}
          clientTypeList={clientTypeList}
        />

        <ModalUpdateProvider
          isClicked={isModalUpdateProviderShowing}
          isClosed={modalUpdateIsClosedProvider}
          companiesList={companiesList}
          rowObject={providerObject}
        />

        <Modal
          className="easypay-modal"
          showModal={isModalErrorShowing}
          setShowModal={setIsModalErrorShowing}
          headerContent={"Erro"}
          bodyContent={createBodyError()}
          key={uuid()}
        ></Modal>

        <Modal
          className="easypay-modal"
          showModal={isModalSucessShowing}
          setShowModal={setIsModalSucessShowing}
          headerContent={"Sucesso"}
          bodyContent={createBodySave()}
          key={uuid()}
        ></Modal>
      </div>
    </>
  );
};
export default AuxTablesPage;
