import {
  GET_API_SETTINGS,
  SET_API_SETTINGS,
  DONE_GET_API_SETTINGS,
  PERSIST_API_SETTINGS,
  DONE_PERSIST_API_SETTINGS,
} from "./actionTypes";

const initialState = {
  apiSettings: {},
  isLoadingGetApiSettings: false,
  isLoadingPersistApiSettings: false,
};


export const onboardingSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case GET_API_SETTINGS:
      return { ...state, isLoadingGetApiSettings: true };
    case SET_API_SETTINGS:
      return { ...state, apiSettings: action.settings };
    case DONE_GET_API_SETTINGS:
      return { ...state, isLoadingGetApiSettings: false };
    case PERSIST_API_SETTINGS:
      return { ...state, isLoadingPersistApiSettings: true };
    case DONE_PERSIST_API_SETTINGS:
      return {...state, isLoadingPersistApiSettings: false};

    default:
      return state;
  }
};
