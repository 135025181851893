import { reject } from "q";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";

import {
  setOrganizations,
  setOrganization,
  persistOrganizationDone,
  deleteOrganizationDone,
} from "./actionTypes";

import {
  successRequestUser,
  setUserOrganization,
} from "~/containers/Layout/actions";

import { activeSuccess, activeError } from "~/components/Messages/actions";

export function* getOrganizations() {
  try {
    const resp = yield http.get("/api/organization/all");
    yield put(setOrganizations(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getOrganizationById({ id }) {
  try {
    const resp = yield http.get(`/api/organization/find-by-id/${id}`);
    yield put(setOrganization(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistOrganization({ organization, datacaptureConfigPayload }) {
  try {
    const data = new FormData();
    data.append("picture", organization.picture);
    delete organization.picture;
    data.append(
      "organization",
      new Blob([JSON.stringify(organization)], { type: "application/json" })
    );
    const resp = yield http.post("/api/organization/save", data);
    if (resp.status === 200) {
      const requestUserResp = yield http.get("/api/user/me");
      yield put(successRequestUser(requestUserResp.data));
    }
    if (resp.data && resp.data.id) {
      datacaptureConfigPayload.forEach(payload => {
        payload.organization_id = resp.data.id
        http.post("/api/datacapture-configuration/create", payload);
      })
    }
    yield put(activeSuccess("admin.organization.persist.successMessage"));
    yield put(persistOrganizationDone());
  } catch (error) {
    yield put(activeError("admin.organization.persist.errorMessage"));
    yield put(persistOrganizationDone());
  }
}

export function* updateOrganization({ organization, datacaptureConfigPayload }) {
  const data = new FormData();
  data.append("picture", organization.picture);
  delete organization.picture;
  data.append(
    "organization",
    new Blob([JSON.stringify(organization)], {
      type: "application/json",
    })
  );
  try {
    const resp = yield http.put("/api/organization/update", data);
    if (resp.status === 200) {
      const requestUserResp = yield http.get("/api/user/me");
      yield put(successRequestUser(requestUserResp.data));
      const organizationId = requestUserResp.data?.organization?.id;
      if (organizationId) {
        const requestOrgByIdResp = yield http.get(`/api/organization/find-by-id/${organizationId}`);
        yield put(setUserOrganization(requestOrgByIdResp.data));
      }
    }
    if (resp.data && resp.data.id) {
      datacaptureConfigPayload.forEach(payload => {
        payload.organization_id = resp.data.id
        http.post("/api/datacapture-configuration/create", payload);
      })
    }
    yield put(activeSuccess("admin.organization.persist.successMessage"));
    yield put(persistOrganizationDone());
  } catch (error) {
    yield put(activeError("admin.organization.persist.errorMessage"));
    yield put(persistOrganizationDone());
  }
}

export function* deleteOrganization({ id }) {
  try {
    yield http.delete(`/api/organization/delete/${id}`);
    yield put(activeSuccess("admin.organization.delete.successMessage"));
    yield put(deleteOrganizationDone());
  } catch (error) {
    yield put(activeError("admin.organization.delete.errorMessage"));
    yield put(deleteOrganizationDone());
  }
}

export function* removeOrganizationPicture({ id }) {
  try {
    let resp = yield http.post(`/api/organization/remove-picture/${id}`);
    yield put(activeSuccess("admin.organization.persist.pictureMessage"));
    if (resp.status === 200) {
      resp = yield http.get("/api/user/me");
      yield put(successRequestUser(resp.data));
      const organizationId = resp.data?.organization?.id;
      if (organizationId) {
        resp = yield http.get(`/api/organization/find-by-id/${organizationId}`);
        yield put(setUserOrganization(resp.data));
      }
    }
  } catch (error) {
    yield put(activeError("admin.organization.persist.errorPicture"));
  }
}
