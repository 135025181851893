import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EditActivityFolder from "./EditActivityFolder";
import uuid from "uuid/v1";
import { invertColor } from "./utils";
import { withNamespaces } from "react-i18next";
import {
  setSelectedRow,
  setIsEditingActivityFolder,
} from "~/store/ducks/taskFolders/actionTypes";

const PnumberColumn = (props) => {
  const {
    t,
    row,
    rowIndex,
    userBPM,
    showCategories,
    styleSheetReadFlag,
    styleSheetToolTipMoreOptions,
  } = props;

  const { selectedRow, folders, isEditActivityFolder } = useSelector(
    (state) => state.taskFoldersReducer
  );

  const [showEdit, setShowEdit] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowEdit(false);
    dispatch(setSelectedRow(null));
    dispatch(setIsEditingActivityFolder(false));
  };

  useEffect(() => {
    if (!isEditActivityFolder) {
      setShowEdit(false);
      dispatch(setSelectedRow(null));
    }
  }, [isEditActivityFolder]);

  useEffect(() => {
    if (
      selectedRow &&
      selectedRow.pnumber === row.pnumber &&
      row.pnumber !== undefined
    ) {
      setShowEdit(true);
      dispatch(setIsEditingActivityFolder(true));
    } else {
      setShowEdit(false);
    }
  }, [selectedRow]);

  const findColor = (id) => {
    const folder = folders.find((f) => f.id === id);
    return folder && folder.color ? folder.color : "inherit";
  };

  const abbreviatePnumber = (text, rowIndex, numLetters) => {
    return (
      <OverlayTrigger
        key={rowIndex}
        placement="bottom"
        overlay={
          <Tooltip
            id={`tooltip-${rowIndex}`}
            style={{
              display: text && text.length > numLetters ? "block" : "none",
            }}
          >
            {text}
          </Tooltip>
        }
      >
        <span className="tasks_id">
          {text && text.length > numLetters
            ? text.slice(0, numLetters - 1) + "..."
            : text}
        </span>
      </OverlayTrigger>
    );
  };
  const renderNumber = () => {
    if (row.folderid && showCategories && row.pnumber) {
      return (
        <div
          key={`row.pnumber_${uuid()}`}
          className="tasks-table-badge"
          style={{
            backgroundColor: findColor(row.folderid),
            color: invertColor(findColor(row.folderid)),
          }}
        >
          <div className="tasks-badge-content">
            {abbreviatePnumber(row.pnumber, rowIndex, 6)}
          </div>
        </div>
      );
    } else if (styleSheetToolTipMoreOptions) {
      return (
        <OverlayTrigger
          key={rowIndex}
          placement="right"
          overlay={
            <Tooltip id={`tooltip-${rowIndex}`}>
              {t("taskPage.general.toolTipprocessNumber")}
            </Tooltip>
          }
        >
          <div className="tasks-badge-content">
            {abbreviatePnumber(row.pnumber, rowIndex, 6)}
          </div>
        </OverlayTrigger>
      );
    } else return abbreviatePnumber(row.pnumber, rowIndex, 6);
  };

  return (
    <div
      className="tasks-pnumber-wrapper"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        selectedRow && selectedRow.pnumber === row.pnumber && showCategories
          ? handleClose()
          : dispatch(setSelectedRow(row));
      }}
    >
      {renderNumber()}
      {showEdit && showCategories && (
        <EditActivityFolder
          folders={folders}
          handleClose={handleClose}
          row={row}
          userBPM={userBPM}
          show={showEdit}
          styleSheetReadFlag={styleSheetReadFlag}
        />
      )}
    </div>
  );
};

export default withNamespaces()(PnumberColumn);
