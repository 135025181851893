export const FIND_MENU_BY_APP = 'applicationMenu@FIND_MENU_BY_APP';
export const SET_MENU_APP = 'applicationMenu@SET_MENU_APP';
export const FIND_MENU_BY_APP_NAME = 'applicationMenu@FIND_MENU_BY_APP_NAME';
export const SET_MENU_APP_NAME = 'applicationMenu@SET_MENU_APP_NAME';
export const FIND_SETTINGS_MENU_BY_APP = 'applicationMenu@FIND_SETTINGS_MENU_BY_APP';
export const SET_SETTINGS_MENU_APP = 'applicationMenu@SET_SETTINGS_MENU_APP';
export const CLEAR_SETTINGS_MENU_APP = 'applicationMenu@CLEAR_SETTINGS_MENU_APP';
export const SAVE_APPLICATION_MENU = 'applicationMenu@SAVE_APPLICATION_MENU';
export const SET_SAVE_APPLICATION_MENU = 'applicationMenu@SET_SAVE_APPLICATION_MENU';
export const ADD_ITEMS_TO_MENU = 'applicationMenu@ADD_ITEMS_TO_MENU';
export const EDIT_APP_MENU = 'applicationMenu@EDIT_APP_MENU';
export const DONE_EDIT_APP_MENU = 'applicationMenu@DONE_EDIT_APP_MENU';
export const DELETE_APP_MENU = 'applicationMenu@DELETE_APP_MENU';
export const DONE_DELETE_APP_MENU = 'applicationMenu@DONE_DELETE_APP_MENU';
export const UPDATE_REDUX_MENU ='application@UPDATE_REDUX_MENU';

export const findMenuByApplication = application => ({ type: FIND_MENU_BY_APP, application });
export const setMenuApplication = menu => ({ type: SET_MENU_APP, menu });

export const findMenuByApplicationName = applicationName => ({ type: FIND_MENU_BY_APP_NAME, applicationName });
export const setMenuApplicationName = menu => ({ type: SET_MENU_APP_NAME, menu });

export const findSettingsMenuByApplication = application => ({ type: FIND_SETTINGS_MENU_BY_APP, application });
export const setSettingsMenuApplication = settingsMenu => ({ type: SET_SETTINGS_MENU_APP, settingsMenu });

export const clearSettingsMenuApplication = () => ({ type: CLEAR_SETTINGS_MENU_APP });

export const addItemsToMenu = items => ({ type: ADD_ITEMS_TO_MENU, items });

export const saveApplicationMenu = applicationMenu => ({ type: SAVE_APPLICATION_MENU, applicationMenu });
export const setSaveApplicationMenu = applicationMenus => ({ type: SET_SAVE_APPLICATION_MENU, applicationMenus });

export const editApplicationMenu = applicationMenu => ({ type: EDIT_APP_MENU, applicationMenu });
export const doneEditApplicationMenu = successEdit => ({ type: DONE_EDIT_APP_MENU, successEdit });

export const deleteApplicationMenu = id => ({ type: DELETE_APP_MENU, id });
export const doneDeleteApplicationMenu = successDelete => ({ type: DONE_DELETE_APP_MENU, successDelete });

export const updateReduxMenu = menu => ({ type: UPDATE_REDUX_MENU, menu });

//OLD
export const GET_MENUS_BY_APPLICATION = 'GET_MENUS_BY_APPLICATION';
export const GET_MENUS_BY_FLOW = 'GET_MENUS_BY_FLOW';
export const SET_RAW_MENUS = 'SET_RAW_MENUS';
export const SET_RAW_FLOWS = 'SET_RAW_FLOWS';
export const SET_MENUS = 'SET_MENUS';
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";
export const CLOSE_SIDE_BAR = "CLOSE_SIDE_BAR";
export const OPEN_SIDE_BAR = "OPEN_SIDE_BAR";

export function getMenusByApplication(username, application) {
  return { type: GET_MENUS_BY_APPLICATION, data: { username, application } }
}

export function getMenusByFlows(username, application) {
  return { type: GET_MENUS_BY_FLOW, data: { username, application } }
}

export function setRawMenus(menus) {
  return { type: SET_RAW_MENUS, data: menus }
}
export function setRawFlows(menus) {
  return { type: SET_RAW_FLOWS, data: menus }
}

export function setMenus(payload) {
  return {
    type: SET_MENUS,
    data: payload,
  }
}

export const toggleSideBar = () => {
  return { type: TOGGLE_SIDE_BAR }
}

export const closeSideBar = () => {
  return { type: CLOSE_SIDE_BAR }
}

export const openSideBar = () => {
  return { type: OPEN_SIDE_BAR }
}
