import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Input from "~/pages/Easypay/components/Input";
import Dropdown from "~/pages/Easypay/components/InputDropdown";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";
import oauth from "~/utils/oauth";
import { transformFormData } from "../utils";
import uuid from "uuid/v1";

const CreateInputDropdown = ({ field, order, handleSubmitWrapper,inputTextExtra, inputField }) => {
  const dispatch = useDispatch();

  const [required, setRequired] = useState(false);
  const [list, setList] = useState([]);
  const [showTextInput, setShowTextInput] = useState(false);

  useEffect(() => {
    if (field) {
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
      if (field.option) {
        if (!Array.isArray(field.option)) {
          field.option = [field.option];
        }
        const optionList = field.option.map((option) => ({
          label: option.text,
          value: option.value,
          selected: option.selected === "yes" ? true : false,
        }));

        if (optionList) {
          setList(optionList);
        }
      }
    }
  }, [field]);

  const handleSelectChange = () => {
    if (field.onchange_submit !== "") {
      if (field.onchange_submit.includes("document.dados.op.value='")) {
        const array = field.onchange_submit.split("document.dados.op.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.op.value = array2[0];
          }
        }
      }
      if (field.onchange_submit.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );

        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postIflowFormData(formdata, url));
      }
    }
  };

  return (
    <Form.Group controlId={`formSelect-${field.variable}`} className={`${showTextInput ? "easypay-no-margin-bottom":""}`}>
      <Dropdown
        name={field.variable }
        required={required}
        placeholder={
          field.text.split("::").length === 3 ? field.text.split("::")[2] : ""
        }
        list={list}
        handleSubmit={(e) => handleSelectChange(e)}
        text={field.text.split("::")[0]}
        order={order}
        setShowTextInput={setShowTextInput}
        inputTextExtra={inputTextExtra}
      />
      {showTextInput && (
        <Input
          field={inputField}
          key={uuid()}
          handleSubmitWrapper={handleSubmitWrapper}
        />
      )}
    </Form.Group>
  );
};
export default CreateInputDropdown;
