import React from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_breadcrumb.scss";
import Link from "./Link";

const Breadcrumb = ({ breadcrumbStates }) => {
  return (
    <>
      <div className="easypay-breadcrumb-wrapper">
        {breadcrumbStates &&
          Object.keys(breadcrumbStates).length !== 0 &&
          breadcrumbStates.map((item, i) => {
            return (
              <div className="easypay-breadcrumb-item-wrapper">
                <Link
                  text={item.text}
                  link={window.location.origin + "/#/" + item.link}
                  disabled={i === breadcrumbStates.length - 1 ? true : false}
                  prevPath={item.prevPath}
                />
                {i === breadcrumbStates.length - 1 ? (
                  ""
                ) : (
                  <hr className="easypay-breadcrumb-separator" />
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};
export default withNamespaces()(Breadcrumb);
