import React from "react";

export default function Hamburguer({ isOpen }) {
  return (
    <>
      <div
        className="hamburguer"
        style={{
          width: "2rem",
          height: "2rem",
          display: "flex",
          justifyContent: "space-around",
          flexFlow: "column nowrap",
          zIndex: 10,
        }}
      >
        <div
          className="burguer burguer1"
          style={{ transform: isOpen ? "rotate(45deg)" : "rotate(0)" }}
        />
        <div
          className="burguer burguer2"
          style={{
            transform: isOpen ? "translateX(100%)" : "translateX(0)",
            opacity: isOpen ? 0 : 1,
          }}
        />
        <div
          className="burguer burguer3"
          style={{ transform: isOpen ? "rotate(-45deg)" : "rotate(0)" }}
        />
      </div>
    </>
  );
}
