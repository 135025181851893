export const SET_PROCESSES = "processes@SET_PROCESSES";
export const SET_PROCESSES_HISTORY = "processes@SET_PROCESSES_HISTORY";
export const SET_PROCESSES_COUNT = "processes@SET_PROCESSES_COUNT";
export const SET_PROCESSES_COUNT_TOTAL = "processes@SET_PROCESSES_COUNT_TOTAL";
export const FIND_IFLOW_PROCESSES_BY_FILTERS =
  "processes@FIND_IFLOW_PROCESSES_BY_FILTERS";
  export const FIND_IFLOW_PROCESSES_HISTORY =
  "processes@FIND_IFLOW_PROCESSES_HISTORY";
  export const FIND_IFLOW_PROCESSES_COUNT =
  "processes@FIND_IFLOW_PROCESSES_COUNT";

export const GET_IFLOW_FORMDATA = "processes@GET_IFLOW_FORMDATA";
export const SET_IFLOW_FORMDATA = "processes@SET_IFLOW_FORMDATA";
export const POST_IFLOW_FORMDATA = "processes@POST_IFLOW_FORMDATA";
export const POST_IFLOW_FORM = "processes@POST_IFLOW_FORM";
export const SET_IFLOW_PID = "processes@SET_IFLOW_PID";

export const GET_IFLOW_DOCUMENT = "processes@GET_IFLOW_DOCUMENT";
export const SET_IFLOW_DOCUMENTS = "processes@SET_IFLOW_DOCUMENTS";
export const SET_SELECTED_TOSIGN = "processes@SET_SELECTED_TOSIGN";
export const RESET_IFLOW_DOCUMENTS = "processes@RESET_IFLOW_DOCUMENTS";

export const SET_IFLOW_TRIGGER_LOADING = "onboarding@SET_IFLOW_TRIGGER_LOADING";

export const SUBSTITUTE_IFLOW_DOCUMENT = "processes@SUBSTITUTE_IFLOW_DOCUMENT";

export const GET_BUTTONSAVE_TRIGGER = "processes@GET_BUTTONSAVE_TRIGGER";
export const SET_BUTTONSAVE_TRIGGER = "processes@SET_BUTTONSAVE_TRIGGER";
export const CLEAR_IFLOW_FORMDATA = "processes@CLEAR_IFLOW_FORMDATA";

export const setProcesses = (processes) => {
  return { type: SET_PROCESSES, data: processes };
};
export const findIflowProcessesByFilters = (payload) => ({
  type: FIND_IFLOW_PROCESSES_BY_FILTERS,
  payload,
});
export const setProcessesHistory = (processes) => {
  return { type: SET_PROCESSES_HISTORY, data: processes };
};
export const setProcessesCountTotal = (processes) => {
  return { type: SET_PROCESSES_COUNT_TOTAL, data: processes };
};
export const setProcessesCount = (processes) => {
  return { type: SET_PROCESSES_COUNT, data: processes };
};
export const findIflowProcessesHistory = (payload) => ({
  type: FIND_IFLOW_PROCESSES_HISTORY,
  payload,
});
export const findIflowProcessesCount = (payload) => ({
  type: FIND_IFLOW_PROCESSES_COUNT,
  payload,
});

export const getIflowFormdata = (payload, isNewForm) => ({
  type: GET_IFLOW_FORMDATA,
  data: payload,
  isNewForm: isNewForm
});

export const cleariflowFormadata = () => ({ type: CLEAR_IFLOW_FORMDATA });

export const setIflowFormdata = (formdata) => ({
  type: SET_IFLOW_FORMDATA,
  formdata,
});

export const setIflowPidScheduler = (pid) => ({
  type: SET_IFLOW_PID,
  data: pid,
});

export const postIflowFormData = (formdata, url, isNewForm) => ({
  type: POST_IFLOW_FORMDATA,
  formdata,
  url,
  isNewForm
});

export const postIflowForm = (payload, url) => ({
  type: POST_IFLOW_FORM,
  payload,
  url
});

export const getIflowDocument = (payload) => ({
  type: GET_IFLOW_DOCUMENT,
  data: payload,
});

export const setSelectedTosign = (data) => ({
  type: SET_SELECTED_TOSIGN,
  data,
});

export const setIflowDocuments = (document, docid, filename) => ({
  type: SET_IFLOW_DOCUMENTS,
  document,
  docid,
  filename,
});

export const resetIflowDocuments = () => ({
  type: RESET_IFLOW_DOCUMENTS,
});

export const substituteIflowDocument = (url, data, updateCoords) => ({
  type: SUBSTITUTE_IFLOW_DOCUMENT,
  url,
  data,
  updateCoords,
});

export const setIflowTriggerLoading = (iflowTriggerLoading) => ({
  type: SET_IFLOW_TRIGGER_LOADING,
  iflowTriggerLoading,
});

export const SET_IFLOW_METADATA_PREVIEW =
  "processes@SET_IFLOW_METADATA_PREVIEW";
export const GET_IFLOW_METADATA_PREVIEW =
  "processes@GET_IFLOW_METADATA_PREVIEW";
export const getIflowMetadataPreview = (payload) => ({
  type: GET_IFLOW_METADATA_PREVIEW,
  data: payload,
});
export const setIflowMetadataPreview = (data) => ({
  type: SET_IFLOW_METADATA_PREVIEW,
  data,
});

export const SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE =
  "processes@SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE";

export const setIsSubstitutingIflowDocumentDone = (data) => ({
  type: SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE,
  data,
});

export const getButtonSaveTrigger = () => {
  return { type: GET_BUTTONSAVE_TRIGGER };
};

export const setButtonSaveTrigger = () => {
  return { type: SET_BUTTONSAVE_TRIGGER };
};
