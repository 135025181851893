import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { findMFilesByFilters } from './actions';
import { Form, Col, Button, Accordion } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import '~/assets/css/icons.css';

const MfilesFilters = ({ t }) => {

  const dispatch = useDispatch();

  const defaultEndAt = new Date().toISOString().slice(0, 10);

  const defaultStartAt = () => {
    let currentDate = new Date();
    let pastDate = new Date(currentDate);
    pastDate.setDate(pastDate.getDate() - 8);
    return pastDate.toISOString().slice(0, 10)
  }

  const [keywords, setKeywords] = useState("");
  const [startAt, setStartAt] = useState(defaultStartAt);
  const [endAt, setEndAt] = useState(defaultEndAt);

  const handleEndDate = e => {
    setEndAt(e.target.value || defaultEndAt);
  }
  const handleStartDate = e => {
    setStartAt(e.target.value || defaultStartAt);
  }

  const handleKeywords = e => {
    setKeywords(e.target.value || "");
  }

  const handleSearch = () => {
    dispatch(findMFilesByFilters({ startAt, endAt, keywords }));
  }

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => { });
    return (
      <Col lg="12" onClick={decoratedOnClick} style={{ padding: "0px" }}>
        <h5>{t('general.filters')}</h5>
        <hr />
        {children}
      </Col>
    )
  };

  return (
    <Accordion defaultActiveKey="0">
      <CustomToggle eventKey="0" />
      <Accordion.Collapse eventKey="0">
        <Form>
          <Form.Row>
            <Col lg="3">
              <Form.Label>Data de registo entre: </Form.Label>
              <Form.Control
                type="date"
                value={startAt}
                onChange={e => handleStartDate(e)}
              />
            </Col>
            <Col lg="3">
              <Form.Label> &nbsp;</Form.Label>
              <Form.Control
                type="date"
                value={endAt}
                onChange={e => handleEndDate(e)}
              />
            </Col>
            <Col lg="3">
              <Form.Label>Keywords</Form.Label>
              <Form.Control
                type="text"
                value={keywords}
                onChange={e => handleKeywords(e)}
              />
            </Col>
          </Form.Row>
          <br />
          <Button
            className="card-button"
            onClick={() => handleSearch()}
          >
            Pesquisar
      </Button>
        </Form >
      </Accordion.Collapse>
    </Accordion>
  )
}

export default withNamespaces()(MfilesFilters);
