import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useLabelWidth } from "../utils/useLabelWidth";
import { useLabelFormated } from "../utils/useLabelFormated";
import ReactHtmlParser from "react-html-parser";

const CreateTextArea = (props) => {
  const { field, styleSheet, isChild,fontSize,setIsModalShowing,
    setCurrentInfo, translations } = props; 
  const [textAreaValue, setTextAreaValue] = useState("");
  const [required, setRequired] = useState(false);

  const { labelWidthClass, labelWidth, labelAlign, labelFlexGrow } = useLabelWidth(field);
  const { labelFormated, imageFormated, textFormated } =
    useLabelFormated(field, translations);
  useEffect(() => {
    if (field) {
      if (field.value) {
        /*parse rich text editor value, replace udwaspas with "*/
        const v = field.value.replaceAll("udwaspas", '"');

        if (v.includes('"blocks":')) {
          const keyarr = v.split("key");
          const start = '"text":"';
          const end = '","type"';
          let simpleText = "";

          for (let i = 0; i < keyarr.length; i++) {
            if (keyarr[i].includes(start)) {
              const arr = keyarr[i].split(start);
              if (arr[1].includes(end)) {
                const arr2 = arr[1].split(end);
                simpleText += " " + arr2[0];
              }
            }
          }
          setTextAreaValue(simpleText.trim());
        } else setTextAreaValue(v);
      }
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
    }
  }, [field]);

  return (
    <>
      <Form.Group
        controlId={`formTextarea-${field.variable}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          flexGrow: "1",
        }}
      >
        <Form.Label
          column
          className={labelWidthClass ? labelWidthClass : "iflow-form-label-fix"}
          style={
            labelWidth
              ? {
                    width: labelWidth,
                    minWidth: labelWidth,
                    flexBasis: labelWidth,
                  }
              : {}
          }
        >
          {ReactHtmlParser(
                labelFormated
                  ? labelFormated
                  : field.text && field.text.b
                  ? field.text.b
                  : field.text
              )}
              {imageFormated && (
              <img
                style={{ width: "20px", height: "20px", cursor: "pointer", marginLeft:'10px' }}
                src={imageFormated.props.src}
                className={imageFormated.props.className}
                alt="..."
                onClick={() => {
                  setIsModalShowing(true);
                  setCurrentInfo(textFormated);
                }}
              />
            )}
        </Form.Label>
        <Col
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            flexGrow: "2",
          }}
        >
          {field.disabled === "true" ? (
            <Form.Label
              column
              style={{
                fontWeight: "500",
                paddingRight: "0px",
                paddingLeft: "0px",
              }}
            >
              {" "}
              {textAreaValue} {field.suffix ? field.suffix : ""}
            </Form.Label>
          ) : (
            <>
              <textarea
                className="form-control scrollBar-visible"
                type="textarea"
                name={field.variable}
                rows={field.rows ? field.rows : 3}
                cols={field.cols ? field.cols : 50}
                required={required}
                style={{ flexGrow: "1", flexBasis: "0", cursor: "auto",fontSize: `${fontSize}px` }}
                value={textAreaValue}
                onChange={(e) =>
                  setTextAreaValue(e.target.value.replaceAll('"', "'"))
                }
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${field.text} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </>
          )}
        </Col>
      </Form.Group>
      {styleSheet?.highLightRows && !isChild && <hr />}
    </>
  );
};
export default CreateTextArea;
