export const SEND_EMAIL = "onboarding@SEND_EMAIL";
export const DONE_SEND_EMAIL = "onboarding@DONE_SEND_EMAIL";
export const SET_SEND_EMAIL_RESPONSE = "onboarding@SET_SEND_EMAIL_RESPONSE";

export const SEND_EMAIL_ENDPOINT = "onboarding@SEND_EMAIL_ENDPOINT";
export const DONE_SEND_EMAIL_ENDPOINT = "onboarding@DONE_SEND_EMAIL_ENDPOINT";
export const SET_SEND_EMAIL_ENDPOINT_RESPONSE =
  "onboarding@SET_SEND_EMAIL_ENDPOINT_RESPONSE";

export const SEND_SMS = "onboarding@SEND_SMS";
export const DONE_SEND_SMS = "onboarding@DONE_SEND_SMS";
export const SET_SEND_SMS_RESPONSE = "onboarding@SET_SEND_SMS_RESPONSE";

export const SEND_SMS_OTP = "onboarding@SEND_SMS_OTP";
export const DONE_SEND_SMS_OTP = "onboarding@DONE_SEND_SMS_OTP";
export const SET_SEND_SMS_OTP_RESPONSE = "onboarding@SET_SEND_SMS_OTP_RESPONSE";

export const POST_INQUIRY = "onboarding@POST_INQUIRY";
export const DONE_POST_INQUIRY = "onboarding@DONE_POST_INQUIRY";
export const SET_POST_INQUIRY_RESPONSE = "onboarding@SET_POST_INQUIRY_RESPONSE";
export const GET_INQUIRY = "onboarding@GET_INQUIRY";
export const DONE_GET_INQUIRY = "onboarding@DONE_GET_INQUIRY";
export const SET_GET_INQUIRY_RESPONSE = "onboarding@SET_GET_INQUIRY_RESPONSE";

export const GET_CRC_DOCUMENT = "onboarding@GET_CRC_DOCUMENT";
export const DONE_GET_CRC_DOCUMENT = "onboarding@DONE_GET_CRC_DOCUMENT";
export const SET_GET_CRC_DOCUMENT_RESPONSE =
  "onboarding@SET_GET_CRC_DOCUMENT_RESPONSE";

export const GET_IES_DOCUMENT = "onboarding@GET_IES_DOCUMENT";
export const DONE_GET_IES_DOCUMENT = "onboarding@DONE_GET_IES_DOCUMENT";
export const SET_GET_IES_DOCUMENT_RESPONSE =
  "onboarding@SET_GET_IES_DOCUMENT_RESPONSE";

export const CREATE_ALICE_USER = "onboarding@CREATE_ALICE_USER";
export const DONE_CREATE_ALICE_USER = "onboarding@DONE_CREATE_ALICE_USER";
export const SET_ALICE_USER_TOKEN = "onboarding@SET_ALICE_USER_TOKEN";
export const SET_ALICE_USER_ID = "onboarding@SET_ALICE_USER_ID";
export const GET_ALICE_REPORT = "onboarding@GET_ALICE_REPORT";
export const DONE_GET_ALICE_REPORT = "onboarding@DONE_GET_ALICE_REPORT";
export const SET_ALICE_REPORT = "onboarding@SET_ALICE_REPORT";
export const GET_ALICE_TOKEN = "onboarding@GET_ALICE_TOKEN";
export const PROCESS_ALICE_REPORT = "onboarding@PROCESS_ALICE_REPORT";
export const GENERATE_ALICE_LINK = "onboarding@GENERATE_ALICE_LINK";
export const SET_ALICE_LINK = "onboarding@SET_ALICE_LINK";
export const GET_ALICE_PROCESS = "onboarding@GET_ALICE_PROCESS";
export const SET_ALICE_PROCESS = "onboarding@SET_ALICE_PROCESS";

export const CREATE_MERCHANT = "onboarding@CREATE_MERCHANT";
export const SET_MERCHANT = "onboarding@SET_MERCHANT";
export const DONE_CREATE_MERCHANT = "onboarding@DONE_CREATE_MERCHANT";
export const GET_MERCHANT = "onboarding@GET_MERCHANT";
export const DONE_GET_MERCHANT = "onboarding@DONE_GET_MERCHANT";
export const ADD_DOCUMENT_TO_MERCHANT = "onboarding@ADD_DOCUMENT_TO_MERCHANT";
export const DONE_ADD_DOCUMENT_TO_MERCHANT =
  "onboarding@DONE_ADD_DOCUMENT_TO_MERCHANT";

export const GET_TINK_ID = "onboarding@GET_TINK_ID";
export const SET_TINK_ID = "onboarding@SET_TINK_ID";
export const DONE_GET_TINK_ID = "onboarding@DONE_GET_TINK_ID";
export const GET_TINK_TOKEN = "onboarding@GET_TINK_TOKEN";
export const SET_TINK_TOKEN = "onboarding@SET_TINK_TOKEN";
export const DONE_GET_TINK_TOKEN = "onboarding@DONE_GET_TINK_TOKEN";
export const GET_TINK_REPORT = "onboarding@GET_TINK_REPORT";
export const DONE_GET_TINK_REPORT = "onboarding@DONE_GET_TINK_REPORT";
export const SET_TINK_REPORT = "onboarding@SET_TINK_REPORT";

export const GET_SIGNED_PDF = "onboarding@GET_SIGNED_PDF";
export const DONE_GET_SIGNED_PDF = "onboarding@DONE_GET_SIGNED_PDF";
export const SET_SIGNED_PDF = "onboarding@SET_SIGNED_PDF";
export const SET_DIGITAL_SIGNATURE_OUTPUT =
  "onboarding@SET_DIGITAL_SIGNATURE_OUTPUT";
export const GET_VERSION_SIGNPDF = "onboarding@GET_VERSION_SIGNPDF";
export const SET_VERSION_SIGNPDF = "onboarding@SET_VERSION_SIGNPDF";

export const GET_CARD_INFORMATION = "onboarding@GET_CARD_INFORMATION";
export const DONE_GET_CARD_INFORMATION = "onboarding@DONE_GET_CARD_INFORMATION";
export const SET_CARD_INFORMATION = "onboarding@SET_CARD_INFORMATION";

export const GET_SAML = "onboarding@GET_SAML";
export const DONE_GET_SAML = "onboarding@DONE_GET_SAML";
export const CLEAR_GET_SAML_RESPONSE = "onboarding@CLEAR_GET_SAML_RESPONSE";
export const SET_GET_SAML_RESPONSE = "onboarding@SET_GET_SAML_RESPONSE";
export const GET_ATRIBUTES = "onboarding@GET_ATRIBUTES";
export const DONE_GET_ATRIBUTES = "onboarding@DONE_GET_ATRIBUTES";
export const SET_ATRIBUTES = "onboarding@SET_ATRIBUTES";

export const SET_TRIGGER_LOADING = "onboarding@SET_TRIGGER_LOADING";

export const GET_ALL_DOCUMENTS = "onboarding@GET_ALL_DOCUMENTS";
export const DONE_GET_ALL_DOCUMENTS = "onboarding@DONE_GET_ALL_DOCUMENTS";
export const SET_ALL_DOCUMENTS = "onboarding@SET_ALL_DOCUMENTS";

export const GET_ALL_USERS = "onboarding@GET_ALL_USERS";
export const DONE_GET_ALL_USERS = "onboarding@DONE_GET_ALL_USERS";
export const SET_ALL_USERS = "onboarding@SET_ALL_USERS";

export const GET_USER = "onboarding@GET_USER";
export const DONE_GET_USER = "onboarding@DONE_GET_USER";
export const SET_USER = "onboarding@SET_USER";

export const SET_DOCUMENT = "onboarding@SET_DOCUMENT";

export const UPDATE_USER = "onboarding@UPDATE_USER";
export const DONE_UPDATE_USER = "onboarding@DONE_UPDATE_USER";

export const UPDATE_DOCUMENT = "onboarding@UPDATE_DOCUMENT";
export const DONE_UPDATE_DOCUMENT = "onboarding@DONE_UPDATE_DOCUMENT";

export const UPDATE_WORKPLAN = "onboarding@UPDATE_WORKPLAN";
export const DONE_UPDATE_WORKPLAN = "onboarding@DONE_UPDATE_WORKPLAN";
export const SET_WORKPLAN = "onboarding@SET_WORKPLAN";

export const GET_DOCUMENT_METADATA = "onboarding@GET_DOCUMENT_METADATA";
export const DONE_GET_DOCUMENT_METADATA =
  "onboarding@DONE_GET_DOCUMENT_METADATA";
export const SET_DOCUMENT_METADATA = "onboarding@SET_DOCUMENT_METADATA";

export const GET_DOCUMENT_PDA_INFO = "onboarding@GET_DOCUMENT_PDA_INFO ";
export const DONE_GET_DOCUMENT_PDA_INFO =
  "onboarding@DONE_GET_DOCUMENT_PDA_INFO ";
export const SET_DOCUMENT_PDA_INFO = "onboarding@SET_DOCUMENT_PDA_INFO ";

export const SET_CURRENT_VERTICAL = "onboarding@SET_CURRENT_VERTICAL";

export const SET_CURRENT_SIGNATURE = "onboarding@SET_CURRENT_SIGNATURE";

export const CREATE_DOCUMENT_CORESYSTEM =
  "onboarding@CREATE_DOCUMENT_CORESYSTEM";
export const SET_CREATE_DOCUMENT_ID_CORESYSTEM =
  "onboarding@SET_CREATE_DOCUMENT_ID_CORESYSTEM";
export const ADD_DOCUMENT_CORESYSTEM = "onboarding@ADD_DOCUMENT_CORESYSTEM";
export const ADD_DOCUMENT_CORESYSTEM_DONE =
  "onboarding@ADD_DOCUMENT_CORESYSTEM_DONE";
export const GET_DOCUMENT_CORESYSTEM = "onboarding@GET_DOCUMENT_CORESYSTEM";
export const SET_DOCUMENT_CORESYSTEM = "onboarding@SET_DOCUMENT_CORESYSTEM";
export const POST_MINDEE = "onboarding@POST_MINDEE";
export const SET_MINDEE_IBAN = "onboarding@SET_MINDEE_IBAN";
export const SET_MINDEE_ADDRESS = "onboarding@SET_MINDEE_ADDRESS";

export const getDocumentByPid = (queryParams) => ({
  type: GET_DOCUMENT_PDA_INFO,
  queryParams,
});
export const doneGetDocumentPdaInfo = () => ({
  type: DONE_GET_DOCUMENT_PDA_INFO,
});
export const setDocumentPdaInfo = (documentPdaInfo) => ({
  type: SET_DOCUMENT_PDA_INFO,
  documentPdaInfo,
});

export const sendEmail = (payload, apiSettingsId) => ({
  type: SEND_EMAIL,
  payload,
  apiSettingsId,
});
export const doneSendEmail = () => ({ type: DONE_SEND_EMAIL });
export const setSendEmailResponse = (response) => ({
  type: SET_SEND_EMAIL_RESPONSE,
  response,
});

export const sendEmailEndPoint = (payload, apiSettingsId) => ({
  type: SEND_EMAIL_ENDPOINT,
  payload,
  apiSettingsId,
});
export const doneSendEmailEndPoint = () => ({ type: DONE_SEND_EMAIL_ENDPOINT });
export const setSendEmailEndPointResponse = (response) => ({
  type: SET_SEND_EMAIL_ENDPOINT_RESPONSE,
  response,
});

export const sendSms = (payload, apiSettingsId) => ({
  type: SEND_SMS,
  payload,
  apiSettingsId,
});
export const doneSendSms = () => ({ type: DONE_SEND_SMS });
export const setSendSmsResponse = (response) => ({
  type: SET_SEND_SMS_RESPONSE,
  response,
});

export const setTriggerLoading = (triggerLoading) => ({
  type: SET_TRIGGER_LOADING,
  triggerLoading,
});

export const sendSmsOTP = (payload, apiSettingsId) => ({
  type: SEND_SMS_OTP,
  payload,
  apiSettingsId,
});
export const doneSendSmsOTP = () => ({ type: DONE_SEND_SMS_OTP });
export const setSendSmsOTPResponse = (response) => ({
  type: SET_SEND_SMS_OTP_RESPONSE,
  response,
});

export const postInquiry = (payload, apiSettingsId) => ({
  type: POST_INQUIRY,
  payload,
  apiSettingsId,
});
export const donePostInquiry = () => ({ type: DONE_POST_INQUIRY });
export const setPostInquiryResponse = (response) => ({
  type: SET_POST_INQUIRY_RESPONSE,
  response,
});
export const setCurrentVertical = (currentVertical) => ({
  type: SET_CURRENT_VERTICAL,
  currentVertical,
});
export const setCurrentSignature = (currentSignature) => ({
  type: SET_CURRENT_SIGNATURE,
  currentSignature,
});
export const getInquiry = (inquiryId) => ({ type: GET_INQUIRY, inquiryId });
export const doneGetInquiry = () => ({ type: DONE_GET_INQUIRY });
export const setGetInquiryResponse = (response) => ({
  type: SET_GET_INQUIRY_RESPONSE,
  response,
});

export const getCrcDocument = (payload, apiSettingsId, token) => ({
  type: GET_CRC_DOCUMENT,
  payload,
  apiSettingsId,
  token,
});
export const doneGetCrcDocument = () => ({ type: DONE_GET_CRC_DOCUMENT });
export const setGetCrcDocumentResponse = (response) => ({
  type: SET_GET_CRC_DOCUMENT_RESPONSE,
  response,
});

export const getIesDocument = (payload, apiSettingsId, token) => ({
  type: GET_IES_DOCUMENT,
  payload,
  apiSettingsId,
  token,
});
export const doneGetIesDocument = () => ({ type: DONE_GET_IES_DOCUMENT });
export const setGetIesDocumentResponse = (response) => ({
  type: SET_GET_IES_DOCUMENT_RESPONSE,
  response,
});

export const createAliceUser = (payload, apiSettingsId) => ({
  type: CREATE_ALICE_USER,
  payload,
  apiSettingsId,
});
export const doneCreateAliceUser = () => ({ type: DONE_CREATE_ALICE_USER });
export const setAliceUserToken = (payload) => ({
  type: SET_ALICE_USER_TOKEN,
  payload,
});
export const setAliceUserID = (payload) => ({
  type: SET_ALICE_USER_ID,
  payload,
});
export const getAliceReport = (payload) => ({
  type: GET_ALICE_REPORT,
  payload,
});
export const setAliceReport = (data) => ({ type: SET_ALICE_REPORT, data });
export const doneGetAliceReport = () => ({ type: DONE_GET_ALICE_REPORT });
export const getAliceToken = (id, orgId) => ({
  type: GET_ALICE_TOKEN,
  id,
  orgId,
});
export const processAliceReport = (aliceUserID, orgId, id) => ({
  type: PROCESS_ALICE_REPORT,
  aliceUserID,
  orgId,
  id,
});
export const generateAliceLink = (pid, orgId) => {
  return {
    type: GENERATE_ALICE_LINK,
    pid,
    orgId,
  };
};
export const setAliceLink = (link) => {
  return {
    type: SET_ALICE_LINK,
    data: link,
  };
};
export const getAliceProcess = (pid) => {
  return {
    type: GET_ALICE_PROCESS,
    pid,
  };
};
export const setAliceProcess = (process) => {
  return {
    type: SET_ALICE_PROCESS,
    data: process,
  };
};

export const createMerchant = (payload, apiSettingsId) => ({
  type: CREATE_MERCHANT,
  payload,
  apiSettingsId,
});
export const setMerchant = (data) => ({ type: SET_MERCHANT, data });
export const doneCreateMerchant = () => ({ type: DONE_CREATE_MERCHANT });
export const getMerchant = (payload, apiSettingsId) => ({
  type: GET_MERCHANT,
  payload,
  apiSettingsId,
});
export const doneGetMerchant = () => ({ type: DONE_GET_MERCHANT });
export const addDocumentToMerchant = () => ({ type: ADD_DOCUMENT_TO_MERCHANT });
export const doneAddDocumentToMerchant = () => ({
  type: DONE_ADD_DOCUMENT_TO_MERCHANT,
});

export const getTinkId = () => ({ type: GET_TINK_ID });
export const setTinkId = () => ({ type: SET_TINK_ID });
export const doneGetTinkId = () => ({ type: DONE_GET_TINK_ID });
export const getTinkToken = (apiSettingsId) => ({
  type: GET_TINK_TOKEN,
  apiSettingsId,
});
export const setTinkToken = () => ({ type: SET_TINK_TOKEN });
export const doneGetTinkToken = () => ({ type: DONE_GET_TINK_TOKEN });
export const getTinkReport = (apiSettingsId, userId) => ({
  type: GET_TINK_REPORT,
  apiSettingsId,
  userId,
});
export const setTinkReport = (report) => ({ type: SET_TINK_REPORT, report });
export const doneGetTinkReport = () => ({ type: DONE_GET_TINK_REPORT });

export const setDigitalSignatureOutput = (response) => ({
  type: SET_DIGITAL_SIGNATURE_OUTPUT,
  response,
});
export const doneGetSignedPdf = () => ({ type: DONE_GET_SIGNED_PDF });
export const setSignedPdf = (response, reduxData) => ({
  type: SET_SIGNED_PDF,
  response,
  reduxData,
});
export const getSignedPdfs = (payload, queryParams, reduxData) => ({
  type: GET_SIGNED_PDF,
  payload,
  queryParams,
  reduxData,
});

export const getVersionSignPdf = () => ({
  type: GET_VERSION_SIGNPDF,
});

export const setVersionSignPdf = (response) => ({
  type: SET_VERSION_SIGNPDF,
  response,
});

export const getCardInformation = () => ({ type: GET_CARD_INFORMATION });
export const doneGetCardInformation = () => ({
  type: DONE_GET_CARD_INFORMATION,
});
export const setCardInformation = (data) => ({
  type: SET_CARD_INFORMATION,
  data,
});

export const getSaml = (payload) => ({ type: GET_SAML, payload });
export const doneGetSaml = () => ({ type: DONE_GET_SAML });
export const clearGetSamlResponse = () => ({ type: CLEAR_GET_SAML_RESPONSE });
export const setGetSamlReponse = (response) => ({
  type: SET_GET_SAML_RESPONSE,
  response,
});
export const getAtributes = (queryParams) => ({
  type: GET_ATRIBUTES,
  queryParams,
});
export const doneGetAtributes = () => ({ type: DONE_GET_ATRIBUTES });
export const setAtributes = (response) => ({ type: SET_ATRIBUTES, response });

export const getAllUsers = () => ({ type: GET_ALL_USERS });
export const doneGetAllUsers = () => ({ type: DONE_GET_ALL_USERS });
export const setAllUsers = (users) => ({ type: SET_ALL_USERS, users });

export const getAllDocuments = () => ({ type: GET_ALL_DOCUMENTS });
export const doneGetAllDocuments = () => ({ type: DONE_GET_ALL_DOCUMENTS });
export const setAllDocuments = (documents) => ({ type: SET_ALL_DOCUMENTS, documents });

export const getUser = (id) => ({ type: GET_USER, id });
export const doneGetUser = () => ({ type: DONE_GET_USER });
export const setUser = (user) => ({ type: SET_USER, user });

export const setDocument = (document) => ({ type: SET_DOCUMENT, document });

export const updateUser = (user) => ({ type: UPDATE_USER, user });
export const doneUpdateUser = () => ({ type: DONE_UPDATE_USER });

export const updateDocument = (document) => ({ type: UPDATE_DOCUMENT, document });
export const doneUpdateDocument = () => ({ type: DONE_UPDATE_DOCUMENT });

export const getDocumentMetadata = (id) => ({
  type: GET_DOCUMENT_METADATA,
  id,
});
export const doneGetDocumentMetadata = () => ({
  type: DONE_GET_DOCUMENT_METADATA,
});
export const setDocumentMetadata = (document) => ({
  type: SET_DOCUMENT_METADATA,
  document,
});

/*Coresystem start*/
export const createDocumentCoresystem = (payload, apiSettingsId) => {
  return {
    type: CREATE_DOCUMENT_CORESYSTEM,
    payload,
    apiSettingsId,
  };
};

export const setCreateDocumentCoresystem = (id) => {
  return {
    type: SET_CREATE_DOCUMENT_ID_CORESYSTEM,
    data: id,
  };
};

export const addDocumentCoresystem = (document, apiSettingsId) => {
  return {
    type: ADD_DOCUMENT_CORESYSTEM,
    document,
    apiSettingsId,
  };
};

export const addDocumentCoresystemDone = (addDocumentCoresystemDone) => {
  return {
    type: ADD_DOCUMENT_CORESYSTEM_DONE,
    data: addDocumentCoresystemDone,
  };
};

export const getDocumentCoresystem = (id, apiSettingsId) => {
  return {
    type: GET_DOCUMENT_CORESYSTEM,
    id,
    apiSettingsId,
  };
};

export const setDocumentCoresystem = (documentCoresystem) => {
  return { type: SET_DOCUMENT_CORESYSTEM, data: documentCoresystem };
};
/*Coresystem end*/

export const addMindee = (document, tabKey, apiSettingsId) => {
  return {
    type: POST_MINDEE,
    document,
    tabKey,
    apiSettingsId
  };
};

export const setMindeeIban = (document) => ({ type: SET_MINDEE_IBAN, document });
export const setMindeeAddress = (document) => ({ type: SET_MINDEE_ADDRESS, document });