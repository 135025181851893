import { setTasks, setupdateReadFlag, setTasksCountTotal, setTasksCount } from "./actionTypes";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import { activeError } from "~/components/Messages/actions";
import { BPM_MIDDLEWARE } from "~/utils/constants";

export function* findIflowTasksByFilters({ payload }) {
  try {
    const resp = yield http.post("/fin/activity", payload, BPM_MIDDLEWARE);
    yield put(setTasks(resp.data));
    yield put(setTasksCount(resp.data.length));
  } catch (e) {
    yield put(setTasks([]));
    yield put(activeError("taskPage.error.bpm_off"));
  }
}

export function* updateReadFlag({ data }) {
  try {
    const resp = yield http.post("/fin/update-readFlag", data, BPM_MIDDLEWARE);
    yield put(setupdateReadFlag(resp.data));
  } catch (e) {
    yield put(setupdateReadFlag([]));
  }
} 

export function* findIflowTasksCount({ payload }) {
  try {
    const resp = yield http.post(
      "/fin/tasks-count",
      payload,
      BPM_MIDDLEWARE
    );
    yield put(setTasksCountTotal(resp.data));
  } catch (e) {
    yield put(activeError("messages.error"));
  }
}
