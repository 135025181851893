import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Card, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Messages from "~/components/Messages";

import EmailEndPointConfig from "./EmailEndpoint/Config";
import EmailEndPointTest from "./EmailEndpoint/Test";
/* import EmailConfig from "./Email/Config";
import EmailTest from "./Email/Test";
import SmsEndpointConfig from "./SmsEndpoint/Config";
import SmsEndpointTest from "./SmsEndpoint/Test"; */
import SmsOTPConfig from "./SmsOTP/Config";
import SmsOTPTest from "./SmsOTP/Test";
import DBTest from "./Dun&Bradstreet/Test";
import DBConfig from "./Dun&Bradstreet/Config";

import CrcDocumentTest from "./CrcDocument/Test";
import CrcDocumentConfig from "./CrcDocument/Config";
import IesEmpresasTest from "./IesEmpresas/Test";
import IesEmpresasConfig from "./IesEmpresas/Config";
import FormWrapper from "./FormWrapper/index.js";
import CoreSystemNifConfig from "./CoreSystemNIF/Config";
import CoreSystemNifTest from "./CoreSystemNIF/Test";
import CoreSystemClientCreateConfig from "./CoreSystemClientCreate/Config";
import CoreSystemClientCreateTest from "./CoreSystemClientCreate/Test";
import CoreSystemClientConsultConfig from "./CoreSystemClientConsult/Config";
import CoreSystemClientConsultTest from "./CoreSystemClientConsult/Test";
import CoreSystemDocumentsConfig from "./CoreSystemDocuments/Config";
import CoreSystemDocumentsTest from "./CoreSystemDocuments/Test";
import IbanVerificationConfig from "./IbanVerification/Config";
import IbanVerificationTest from "./IbanVerification/Test";
import SSOConfig from "./SSO/Config";
import SSOTest from "./SSO/Test";
import AliceBiometricsConfig from "./AliceBiometrics/Config";
import AliceBiometricsTest from "./AliceBiometrics/Test";
import ChaveMovelDigitalTest from "./ChaveMovelDigital/Test";
import FileUploadMindee from "./Mindee/FileUpload";

 //import FileUpload from "./DigitalSignature/FileUpload";
/*import DataExtractionTest from "./DataExtraction/Test.js"; */

import "~/assets/css/icons.css";
import "./OnboardingMainPage.css";

import { getTinkReport } from "~/store/ducks/onboarding/actionTypes";
import { getAPISettings } from "~/store/ducks/onboardingSettings/actionTypes";

const OnboardingMainPage = ({ t }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  useEffect(() => {
    user?.organization?.id && dispatch(getAPISettings(apiSettings));
    if (window.location.href.includes("?cmd-user-id=")) {
      setKey("chaveMovelDigital");
    }
  }, []);

  useEffect(() => {
    if (apiSettings.id) {
      if (window.location.href.includes("account_verification_report_id")) {
        dispatch(
          getTinkReport(apiSettings.id, {
            information: window.location.href.split(
              "account_verification_report_id="
            )[1],
          })
        );
      }
    }
  }, [apiSettings]);

  const [key, setKey] = useState("ibanVerification");

  const ControlledTabs = () => {
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        unmountOnExit={true}
        className="custom-tabs"
      >
        <Tab eventKey="emailEndPoint" title="E-Mail">
          <FormWrapper Config={EmailEndPointConfig} Test={EmailEndPointTest} />
        </Tab>

        <Tab eventKey="smsOTP" title="SMS ">
          <FormWrapper Config={SmsOTPConfig} Test={SmsOTPTest} />
        </Tab>

        {/*  <Tab eventKey="email" title="E-Mail (SMTP)">
          <FormWrapper Config={EmailConfig} Test={EmailTest} />
        </Tab> */}

        {/*  <Tab eventKey="smsEndpoint" title="SMS (uSendit)">
          <FormWrapper Config={SmsEndpointConfig} Test={SmsEndpointTest} />
        </Tab> */}

        <Tab eventKey="coresystemNif" title="CoreSystem - NIF">
          <FormWrapper Config={CoreSystemNifConfig} Test={CoreSystemNifTest} />
        </Tab>

        <Tab
          eventKey="coresystemClientCreate"
          title="CoreSystem - Create Client"
        >
          <FormWrapper
            Config={CoreSystemClientCreateConfig}
            Test={CoreSystemClientCreateTest}
          />
        </Tab>

        <Tab
          eventKey="coresystemClientConsult"
          title="CoreSystem - Consult Client"
        >
          <FormWrapper
            Config={CoreSystemClientConsultConfig}
            Test={CoreSystemClientConsultTest}
          />
        </Tab>

        <Tab eventKey="coresystemDocuments" title="CoreSystem - Documents">
          <FormWrapper
            Config={CoreSystemDocumentsConfig}
            Test={CoreSystemDocumentsTest}
          />
        </Tab>

        <Tab eventKey="dunAndBradstreet" title="Dun&Bradstreet">
          <FormWrapper Test={DBTest} Config={DBConfig}/>
        </Tab>

        <Tab eventKey="crcDocument" title="CRC Document">
          <FormWrapper Config={CrcDocumentConfig} Test={CrcDocumentTest} />
        </Tab>

        <Tab eventKey="iesEmpresas" title="IES Empresas">
          <FormWrapper Config={IesEmpresasConfig} Test={IesEmpresasTest} />
        </Tab>

        <Tab eventKey="sso" title="SSO (Authentication)">
          <FormWrapper Config={SSOConfig} Test={SSOTest} />
        </Tab>

        <Tab eventKey="ibanVerification" title="IBAN - Tink">
          <FormWrapper
            Config={IbanVerificationConfig}
            Test={IbanVerificationTest}
          />
        </Tab>

        <Tab eventKey="aliceBiometrics" title="Alice Biometrics ">
          <FormWrapper
            Config={AliceBiometricsConfig}
            Test={AliceBiometricsTest}
          />
        </Tab>

        <Tab eventKey="chaveMovelDigital" title="Chave Móvel Digital ">
          <FormWrapper Test={ChaveMovelDigitalTest} />
        </Tab>

        <Tab eventKey="mindeeIban" title="Mindee - IBAN">
          <FileUploadMindee tabKey="mindeeIban" />
        </Tab>
        
        <Tab eventKey="mindeeMorada" title="Mindee - Morada">
          <FileUploadMindee tabKey="mindeeMorada" />
        </Tab>

        {/* <Tab eventKey="digitalSignature" title="AMA - Digital Signature">
          <FileUpload />
        </Tab> 

        {/* <Tab eventKey="dataExtraction" title="AMA - Data Extraction">
            <FormWrapper Test={DataExtractionTest} />
        </Tab> */}
      </Tabs>
    );
  };

  return (
    <>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <h5 style={{ paddingBottom: "20px" }}>
            {t("onboarding.general.onboarding")}
          </h5>

          <Card.Body>
            <ControlledTabs />
          </Card.Body>
        </Card>
      </div>
      <Messages />
    </>
  );
};

export default withNamespaces()(OnboardingMainPage);
