import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import '../../../i18n';
import '../assets/scss/_pepCheckbox.scss';
import Checkbox from "./Checkbox";
import Input from "./Input";
import Link from "./Link";

const PEPCheckbox = ({checked, input,variable, t}) => {

  const [checkboxChecked,setCheckboxChecked] = useState(input.value != "")

  const field = {value : "" , obligatory : true , variable : variable, maxlenght : 9 , text : t(`bpm.onboarding.backoffice.user.inputPEP`)}

  return (
    <div className="easypay-pepcheckbox-wrapper">
      <div className="easypay-pepcheckbox-top wrapper">
        <Checkbox
          name={"checkbox1"}
          id={uuid()}
          value={t(`bpm.onboarding.backoffice.user.labelPEP`)}
          setCheckboxChecked = {setCheckboxChecked}
          checkboxChecked = {checkboxChecked}
          disabledColored = {input.value != ""}
          checked = {input.value != ""}
        ></Checkbox>
        <div className = "easypay-pepcheckbox-link-wrapper">
            <Link
            text= {t(`bpm.onboarding.backoffice.user.linkPEP`)}
            disabledColored={false}
            disabled={false}
            link=""
            key={uuid()}
            />
        </div>
      </div>
      {checkboxChecked && input.value === "" && <Input field={field} key={field.variable} />}
    </div>
  );
};

export default withNamespaces()(PEPCheckbox);
