import React from "react";
import { withNamespaces } from "react-i18next";
import {Button} from "react-bootstrap";
import { useDispatch } from "react-redux";
import {findDocumentsByFilters} from "~/store/ducks/portalrh/actionTypes";

const SearchButtonFilters = (props) => {

  const {
    expirationDateStart,
    expirationDateEnd,
    uploadedDateStart,
    uploadedDateEnd,
    selectedOperation,
    docType,
    numFuncionario,
    nome,
    fileName,
    uploadedBy,
    collaborators,
    children,
    seenStatus,
  }=props;

  const dispatch = useDispatch();


  const handleSearch = () => {
    let userId;
    let foundCollaborator;
    if(numFuncionario && nome){
      foundCollaborator= collaborators.find(c=>c.funcionarioNum===numFuncionario && c.nome===nome);
    }else if(nome){
      foundCollaborator= collaborators.find(c=>c.nome===nome);
    }else if(numFuncionario){
      foundCollaborator= collaborators.find(c=>c.funcionarioNum===numFuncionario);
    }
    if(foundCollaborator)
        userId=foundCollaborator.id;
    else{
      //user doesnt exist
      if(numFuncionario || nome)
        userId=1000000
    }
    
    let seen;
    if(seenStatus === "seen"){
      seen = true;
    }else if(seenStatus === "unseen"){
      seen = false;
    }
        
    let payload = {};
      const payloadArray = [
        {
          name: "expirationStartDate",
          value: expirationDateStart && expirationDateStart.getTime()
        },
        {
          name: "expirationEndDate",
          value: expirationDateEnd && expirationDateEnd.getTime()
        },
        {
          name: "uploadStartDate",
          value: uploadedDateStart && uploadedDateStart.getTime()
        },
        {
          name: "uploadEndDate",
          value: uploadedDateEnd && uploadedDateEnd.getTime()
        },
        {
          name: "uploadedBy",
          value: uploadedBy
        },
        {
          name: "classId",
          value: docType
        },
        {
          name: "operationId",
          value: selectedOperation
        },
        {
          name: "fileName",
          value: fileName
        },
        {
          name: "userId",
          value: userId
        },
        {
          name: "seen",
          value: seen
        }
      ]
      const payload_ = payloadArray.filter(item => item.value !== "" && item.value !== undefined && item.value !== null)
      payload_.forEach(item => {
        payload[item.name] = item.value
      })
      dispatch(findDocumentsByFilters(payload));
  };

  return(
    <Button
      className="card-button"
      onClick={() => handleSearch()}
    >
      {children}
    </Button>
  )

}
export default withNamespaces()(SearchButtonFilters);