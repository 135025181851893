import React, { useEffect, useState, useRef } from "react";
import uuid from "uuid/v1";
import "../assets/scss/_documentUploadList.scss";
import ButtonUpload from "~/pages/Easypay/components/ButtonUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  saveDocument,
  setAllWorkplans,
  getDocument,
  setDocument,
  deleteDocument
} from "~/store/ducks/budgetManagement/actionTypes";
import iconDocument from "~/pages/Liftworld/assets/img/icon-contract.png";
import Text from "~/pages/Easypay/components/Text";
import Table from "~/pages/Easypay/components/Table";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";

function DocumentUploadList({ workplanIndex, text, type }) {
  const dispatch = useDispatch();

  const {
    workplans,
    documents,
    currentDocument
  } = useSelector((state) => state.budgetManagementReducer);

  const inputFile = useRef(null);

  const [tableSize, setTableSize] = useState(3);

  useEffect(() => {
    if (documents != null && documents != undefined) {
      if (documents.length > 0) {
        workplans[workplanIndex].files = documents;
        dispatch(setAllWorkplans(workplans));
      }
    }
  }, [documents]);

  useEffect(() => {
    if(currentDocument != null)
    {
      const filename = currentDocument.filename;
      const fileContent = currentDocument.data;
  
      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${fileContent}`;
      a.download = filename;
      a.click();
      dispatch(setDocument(null))
    }
  }, [currentDocument]);

  function onButtonClick() {
    // `current` points to the mounted file input element
    inputFile.current.click();
  }

  function getBase64(file, index) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      //console.log(base64String)
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];
    //setFile(currentFile);

    dispatch(
      saveDocument({
        file: currentFile,
        workplanId: workplans[workplanIndex].id,
      })
    );
    getBase64(currentFile);
  };

  function getDate(date) {
    var currentDate = date.toString();
    if (currentDate.length > 10) {
      return currentDate.slice(0, 10);
    }
    return "";
  }

  function getIndexFromId(id) {
    if (workplans[workplanIndex].files != null) {
      return workplans[workplanIndex].files
        .map(function (x) {
          return x.id;
        })
        .indexOf(id);
    }
  }

  function downloadDocument(index)
  {
    dispatch(getDocument({id : workplans[workplanIndex].files[index].id}))
  }

  const fileNameFormatter = (cell) => {
    var name
    const currentIndex = getIndexFromId(cell)
    if(currentIndex < 0)
      return
    name = workplans[workplanIndex].files[currentIndex].filename


    return (
      <div className="liftworld-document-submitted-wrapper" onClick = {() => downloadDocument(currentIndex)}>
      <div className="easypay-text-icon">
        <img src={iconDocument} alt="Icon Check" />
      </div>
      <div className = {"liftworld-blue-text"}>{name}</div>
    </div>
    );
  };

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    if(cell === null || cell === "" || cell === undefined)
    return ""
    var currentStringDate = cell
    if(currentStringDate.length > 10)
    {
      currentStringDate = currentStringDate.slice(0,10)
    }
    const dateInfo = currentStringDate.split("-")
    const str =  dateInfo[2]+"/"+dateInfo[1]+"/"+dateInfo[0]

    return (
      <div style={{ display: "flex", alignItems: "center", paddingTop: "8px" }}>
        {str}
      </div>
    );
  };

  const textFormatter = (cell) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", paddingTop: "12px" }}
      >
        {cell}
      </div>
    );
  };

  function deleteSelectedDocument(id)
  {
    dispatch(deleteDocument({id : id, workplanId : workplans[workplanIndex].id}))
  }

  const deleteDocumentFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
        <SimpleButton
          text={"Apagar"}
          variant={"easypay-button-link"}
          onClick={() => deleteSelectedDocument(cell)}
        />
    );
  };


  const columns = [
    {
      dataField: "id",
      text: "Nome do ficheiro",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: fileNameFormatter,
    },
    {
      dataField: "createdBy",
      text: "Criado Por",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: textFormatter,
    },
    {
      dataField: "createdDate",
      text: "Data de upload",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: dateFormatter,
    },
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: deleteDocumentFormatter,
      headerStyle: { width: "fit-content" },
    },
  ];

  return (
    <div className="liftworld-documents-list-wrapper">
      <div
        className={"liftworld-documents-name-wraper"}
        style={{ display: "flex", flexDirection: "column" }}
        key={uuid()}
      >
        <Table
          sort={{ dataField: "client.pid", order: "desc" }}
          docs={workplans[workplanIndex].files}
          columns={columns}
          tableSize={tableSize}
          setTableSize={setTableSize}
          key="leads-table"
        />
      </div>
      <ButtonUpload
        variant={"easypay-button-secondary-icon-medium"}
        text={"Briefings Upload"}
        onclick={() => onButtonClick()}
        onChange={handleChange}
        key={uuid()}
        inputFile={inputFile}
        id={"id1"}
        className="card-button"
        align={"right"}
        disabledColored={false}
        disabled={false}
      />
    </div>
  );
}

export default DocumentUploadList;
