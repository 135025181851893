import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";

const CreateTableTextarea = (props) => {
  const { field } = props;
  const [textInputValue, setTextInputValue] = useState("");
  const [required, setRequired] = useState(false);
  const [rows, setRows] = useState(2);

  useEffect(() => {
    if (field) {
      if (field.value) setTextInputValue(field.value);
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }

      if (field.maxlength && field.size) {
        const rows = parseInt(field.maxlength / field.size);
        if (!isNaN(rows)) setRows(rows + 1);
      }
    }
  }, [field]);

  return (
    <Form.Group
      controlId={`formText-${field.variable}`}
      key={field.variable}
      style={{ marginBottom: "0px" }}
      className={"create-table-text-input"}
    >
      <textarea
        className="form-control form-table"
        type="textarea"
        autoComplete="off"
        placeholder=""
        name={field.variable}
        //rows={rows} //auto rows
        cols={field.size ? field.size : ""} //cols auto
        style={{
          fontSize: "0.9rem",
          paddingTop: "0px",
          paddingBottom: "0px",
          lineHeight: "1",
          overflow: "hidden", //Resize will not work if the overflow value is set to “visible”
          //resize: "none",
        }}
        value={textInputValue}
        required={required}
        maxLength={field.maxlength}
        onChange={(e) => setTextInputValue(e.target.value)}
        disabled={field.disabled === "true" || field.readonly === "true"}
      />
    </Form.Group>
  );
};

export default CreateTableTextarea;
