import _uniqueId from "lodash/uniqueId";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import arrowDown from "~/pages/Easypay/assets/img/icons/arrow-down-grey.png";
import arrowUp from "~/pages/Easypay/assets/img/icons/arrow-up-blue.png";
import "~/pages/Easypay/assets/scss/_dropdown2.scss";
import "../assets/scss/_inputDropdown.scss";

/*
    Props explanation:
        - t (used when needing to translate any 'general' words) **REQUIRED**
        - list (list of options the dropdown is going to use) **REQUIRED**
        - handleSubmit={(value) => handleSelectChange(value)} **REQUIRED**
        - placeholder **DEFAULT is "option"**
        - required={required} **DEFAULT is false**
        - disabled (used when user wants the input to be disabled)
*/

/* IMPORTANT */
/* When using list make sure you have it in the correct layout. Example:
  
        list = [
            {"label": "label here",
            "value": "value here"}
                ]

    If you have any option in the list that you want to make as default input 
    just add in the property "selected" :true to the list option
*/

const InputDropdown = (props) => {
  const {
    t,
    list = [{ label: t("general.dataNotFound") }],
    required = false,
    handleSubmit = false,
    placeholder = "Selecionar",
    disabled,
    name,
    position,
    size,
    initialSelected,
  } = props;

  /*
        Sorts an array by name, or number (both ascending or descending)
    */
  const sortBy = {
    nameAscending: (prop) =>
      prop.sort((a, b) => a.label.localeCompare(b.label)),
    nameDescending: (prop) =>
      prop.sort((a, b) => b.label.localeCompare(a.label)),
    numberAscending: (prop) => prop.sort((a, b) => a - b),
    numberDescending: (prop) => prop.sort((a, b) => b - a),
    noOrder: (prop) => prop,
  };

  function repeat() {
    var arr = [];
    for (var i = 0; i < optionList.length; i++) {
      arr.push("");
    }
    return arr;
  }

  //Sorts list
  const sortedList = sortBy.nameAscending(list);

  //Translates labels
  const optionList = sortedList.map((i) => {
    return { label: t(i.label), value: i.value, selected: i.selected };
  });
  const emptyArray = repeat();
  const [selectedValue, setSelectedValue] = useState("");
  const [label, setLabel] = useState("");
  const [displayList, setDisplayList] = useState([optionList]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedElements, setSelectedElements] = useState(
    initialSelected?.length > 0 ? initialSelected : emptyArray
  );

  //Randomly creates an id for dropdown and input
  const id = useRef(_uniqueId(""));

  useEffect(() => {
    if (initialSelected) {
      var labelString = "";
      if (
        initialSelected &&
        initialSelected != undefined &&
        initialSelected?.length > 0
      ) {
        for (var i = 0; i < initialSelected.length; i++) {
          if (
            initialSelected[i] != "" &&
            initialSelected[i] != undefined &&
            initialSelected[i]?.length > 0
          ) {
            labelString = labelString + initialSelected[i] + ",";
          }
        }
        setLabel(labelString.slice(0, -1));
      } else {
        setLabel(initialSelected);
      }
      setSelectedValue(initialSelected);
    }
  }, [list]);

  /*
    Function that runs when clicking any option
  */
  const handleSelect = (i) => {
    var remove = false;
    var newArray = initialSelected;
    if (initialSelected?.includes(optionList[i].value)) {
      remove = true;
      newArray[i] = "";
      setSelectedElements(newArray);
    } else {
      var newArray = initialSelected;
      newArray[i] = optionList[i]?.value;
      setSelectedElements(newArray);
    }
    if (!handleSubmit) {
      const array = newArray;
      var labelString = "";
      for (var i = 0; i < array.length; i++) {
        if (array[i] != "") {
          labelString = labelString + array[i] + ",";
        }
      }
      setLabel(labelString.slice(0, -1));
      return;
    }
    handleSubmit({
      selected: optionList[i],
      list: newArray,
      remove: remove,
    });
  };

  /* 
    Sorts list to display selected element in first place. Also deletes it from the displayed list
  */
  const sortListAfterSelect = (list, selected) => {
    var newList = list.map((item) => {
      if (selected.includes(item.value)) {
        return { ...item, selected: true };
      } else {
        return item;
      }
    });
    setDisplayList(newList);
    return newList;
  };

  /*
    Toggles dropdown list everytime user clicks in input
    If first element is selected liftworld-shows it highlighted
  */
  const toggleList = () => {
    document
      .getElementById(`dropDown-${id.current}`)
      .classList.toggle("liftworld-show");

    if (
      document
        .getElementById(`dropDown-${id.current}`)
        .className.includes("liftworld-show")
    ) {
      let newList = sortListAfterSelect(optionList, selectedValue);

      if (newList[0] === undefined) {
        setDisplayList(optionList);
      } else {
        setDisplayList(sortListAfterSelect(optionList, selectedValue));
      }
    } else {
    }
  };

  const hideDropDown = (desiredId) => {
    desiredId === undefined
      ? document
          .getElementById(`dropDown-${id.current}`)
          .classList.remove("liftworld-show")
      : document
          .getElementById(`dropDown-${desiredId}`)
          .classList.remove("liftworld-show");
  };

  /*<
     Function that runs whenever the input get out of focus
  */
  const outOfFocus = (e) => {
    hideDropDown(parseInt(e.target.id.match(/\d+/)[0]));
  };

  function getDefaultChecked(i) {
    return displayList[i].selected == true;
  }

  return (
    <div>
      <Form.Group controlId={`formText-field`} style={{ margin: "0px" }}>
        <div className={"liftworld-input-dropdown-wrapper"}>
          <div
            className={`liftworld-input-dropdown-field-wrapper ${position} ${size}`}
          >
            <input
              disabled={disabled}
              autoComplete="off"
              onFocus={() => {
                setShowDropdown(true);
              }}
              className={`liftworld-input-dropdown-field ${
                label ? "liftworld-filled" : ""
              } `}
              placeholder={`${placeholder}`}
              id={`formInput-${id.current}`}
              value={label}
              required={required}
              name={name}
              readOnly
              onMouseDown={() => {
                toggleList();
                setShowDropdown(!showDropdown);
              }}
            />
            <div
              className="liftworld-input-dropdown-icon-wrapper"
              onMouseDown={() => {
                toggleList();
                setShowDropdown(!showDropdown);
              }}
            >
              <img
                className="liftworld-input-dropdown-icon"
                src={showDropdown ? arrowUp : arrowDown}
                alt="search-icon"
              />
            </div>
          </div>

         {<div
            id={`dropDown-${id.current}`}
            className="liftworld-dropdown"
          >
            {displayList.map((option, i) => {
              return option?.label === t("general.dataNotFound") ? (
                <option id="liftworld-dropdown-no-result" key={i}>
                  {t("general.dataNotFound")}
                </option>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <option
                    id={`selected-${id.current}`}
                    key={i}
                    value={option.value}
                    className="liftworld-dropdown-option"
                  >
                    {option.label}
                  </option>
                  <div className="liftworld-dropdown-checkbox-wrapper">
                    <input
                      name="isGoing"
                      type="checkbox"
                      onChange={(e) => handleSelect(i)}
                      className="liftworld-checkbox-input"
                      defaultChecked={getDefaultChecked(i)}
                    />
                  </div>
                </div>
              );
            })}
          </div>}
        </div>
        <input type="hidden" name={name} value={selectedValue} />
        <Form.Control.Feedback type="invalid">
          {`O campo field é de preenchimento obrigatório`}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

export default withNamespaces()(InputDropdown);
