import qs from "qs";
import { reject } from "q";
import { put } from "redux-saga/effects";
import http from "../../../../utils/config/http";

import {
  setRoles,
  setRole,
  persistRoleDone,
  deleteRoleDone,
  setFindByRole,
  allUsersByRoleDone,
  updateUnikUsersDone,
  setDefaultRoles,
  setUpdatedDefaultRoles,
  setLegacyApps,
  setLegacyRoles
} from "../actions";

import {
  activeSuccess,
  activeError,
} from "../../../../components/Messages/actions";

export function* loadLegacyApps() {
  try {
    const resp = yield http.get('/api/legacy-application/read');
    yield put(setLegacyApps(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRoles() {
  try {
    const resp = yield http.get("/api/role/all");
    yield put(setRoles(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRoleById({ id }) {
  try {
    const resp = yield http.get(`/api/role/find-by-id/${id}`);
    yield put(setRole(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistRole({ role }) {
  try {
    yield http.post("/api/role/persist", role);
    yield put(activeSuccess("admin.role.persist.successMessage"));
    yield put(persistRoleDone());
  } catch (error) {
    yield put(activeError("admin.role.persist.errorMessage"));
    yield put(persistRoleDone());
  }
}

export function* deleteRole({ id }) {
  try {
    yield http.delete(`/api/role/delete/${id}`);
    yield put(activeSuccess("admin.role.delete.successMessage"));
    yield put(deleteRoleDone());
  } catch (error) {
    yield put(activeError("admin.role.delete.errorMessage"));
    yield put(deleteRoleDone());
  }
}

export function* findUsersByRole({ id }) {
  try {
    yield put(setFindByRole([]));
    const resp = yield http.get(
      `/api/user/find-by-role?${qs.stringify({ id })}`
    );
    yield put(setFindByRole(resp.data));
  } catch (error) {
    yield put(setFindByRole([]));
  }
}

export function* allUsersByRole({ roleId }) {
  try {
    const resp = yield http.get(`/api/users/by-role/${roleId}`);
    yield put(allUsersByRoleDone(resp.data));
  } catch (error) {
    yield put(allUsersByRoleDone([]));
  }
}

export function* updateUnikUsers({ roleUsers }) {
  try {
    yield http.post("/api/role/update/unik-users", roleUsers);
    yield put(activeSuccess("admin.role.persist.successMessage"));
    yield put(updateUnikUsersDone());
  } catch (error) {
    yield put(activeError("admin.role.persist.errorMessage"));
    yield put(updateUnikUsersDone());
  }
}

export function* addDefaultRole({ roleId }) {
  try {
    yield put(setUpdatedDefaultRoles(false));
    yield http.put(`api/role/default/${roleId}`);
    yield put(setUpdatedDefaultRoles(true));
  } catch (error) {
    yield put(activeError("messages.error"));
  }
}

export function* deleteDefaultRole({ roleId }) {
  try {
    yield put(setUpdatedDefaultRoles(false));
    yield http.delete(`api/role/default/${roleId}`);
    yield put(setUpdatedDefaultRoles(true));
  } catch (error) {
    yield put(activeError("messages.error"));
  }
}

export function* findDefaultRoles() {
  try {
    const resp = yield http.get("api/role/default");
    yield put(setDefaultRoles(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* loadLegacyRoles({ data }) {
  try {
    const resp = yield http.get(`/api/legacy-role/get-by-app?app=${data}`);
    yield put(setLegacyRoles(resp.data));
  } catch (error) {
    reject(error);
  }
}
