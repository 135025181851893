import {
  SET_LEGACY_PERMISSIONS
} from "./actionTypes";

const initialState = {
  legacyPermissions: []
}

export const legacyPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEGACY_PERMISSIONS:
      return { ...state, legacyPermissions: action.legacyPermissions }
    default:
      return state;
  }
}