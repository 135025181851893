import React from 'react';
import oauth from '~/utils/oauth';
import { GLOBAL_SOLVER_MIDDLEWARE } from '~/utils/constants';
import * as authDomain from "~pages/Login/utils/AuthDomain";

const GlobalSolver = () => {

  const token = oauth.getGlobalSolverToken();
  const authtoken = "?token=" + token;
  const src = GLOBAL_SOLVER_MIDDLEWARE.iframe + authtoken;

  return (
    <>
      {authDomain.isGlobalSolver() ?
        <iframe
          id={GLOBAL_SOLVER_MIDDLEWARE.context
          } title={GLOBAL_SOLVER_MIDDLEWARE.context}
          src={src}
          style={{
            frameborder: "0px",
            width: "100%",
            height: 'auto',
            minHeight: '92vh',
            border: "none",
            display: 'none'
          }
          } />
        : <div>
        </div>
      }
    </>
  )
}
export default GlobalSolver
