import React from "react";
import { withNamespaces } from "react-i18next";
import { Col, Row, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";

import ProtectedPage from "~/components/security/ProtectedPage";

import "../OnboardingMainPage.css";

const FormWrapper = ({ Config, Test }) => {
  const { user } = useSelector((state) => state.globalReducer);

  return (
    <>
      <Alert
        show={!user?.organization?.id}
        variant="warning"
        transition={false}
      >
        <Alert.Heading>Cannot Save or Test!</Alert.Heading>
        <p>
          Please associate an Organization to the current user. Otherwise you
          won't be able to save the configurations and test the services!
        </p>
      </Alert>
      <div style={{ padding: "20px" }}>
        <Row>
          <>
            {Config && (
              <ProtectedPage roles={["ADMIN"]}>
                <Col lg={true}>
                  {" "}
                  <Config />
                </Col>
              </ProtectedPage>
            )}
            {Test && (
              <ProtectedPage roles={["ADMIN", "ONBOARDINGUser"]}>
                <Col lg={true}>{Test && <Test />}</Col>
              </ProtectedPage>
            )}
          </>
        </Row>
      </div>
    </>
  );
};
export default withNamespaces()(FormWrapper);
