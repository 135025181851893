import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Output from "./Output";
import SignPdfModal from "./SignPdfModal";
import ButtonDownload from "~/components/Buttons/DownloadButton";

import { setSignedPdf } from "~/store/ducks/onboarding/actionTypes";

import "~/assets/css/icons.css";
import {
  getSignedPdfs,
  getVersionSignPdf,
} from "~/store/ducks/onboarding/actionTypes";

/**
 * Digital Signature
 *
 * This service tests digital signature of a Pdf documents
 *
 */
const Test = ({ t, documents, clearDocuments = {} }) => {
  const {
    isLoadingGetSignedPdf,
    signedPdf,
    resultVersionSignPdf,
    isLoadingSignPdfVersion,
  } = useSelector((state) => state.onboardingReducer);

  const dispatch = useDispatch();

  const [documentBase64, setDocumentBase64] = useState("");
  const [output, setOutput] = useState("");
  const [showSignModal, setShowSignModal] = useState(false);

  useEffect(() => {
    if (signedPdf) {
      if (Object.keys(signedPdf).length > 0) {
        setOutput(signedPdf);
      }
    }
  }, [signedPdf]);

  useEffect(() => {
    documents.forEach((d, i) => {
      fileToBase64(d.file, (err, result) =>
        setDocumentBase64([
          ...documentBase64,
          { id: i, url: result ? result : "" },
        ])
      );
    });
  }, [documents]);

  async function testSignPdf(
    coordinates,
    pageNumber,
    lastPage,
    reduced,
    givenLocation,
    givenReason
  ) {
    const payload = [];
    const promises = documents.map(async (d) => {
      const result = await fileToBase64(d.file).catch((e) => Error(e));
      return {
        file: result,
        fileName: d.fileName,
      };
    });
    const results = await Promise.all(promises);

    if (results) {
      results.forEach((r) => {
        const part = r.file && r.file.split("data:application/pdf;base64,")[1];
        if (part)
          payload.push({
            file: part,
            fileName: r.fileName.replace(/\.[^/.]+$/, ""), //without extension
          });
      });
    }

    if (payload.length > 0) {
      const queryParams = {
        posX: coordinates.x ? coordinates.x : 0.1,
        posY: coordinates.y ? coordinates.y : 0.1,
        pagina: pageNumber ? pageNumber : 1,
        ultima: lastPage !== undefined ? lastPage : false,
        visivel: true,
        reduzido: reduced !== undefined ? reduced : true,
        givenLocation,
        givenReason,
      };
      dispatch(getSignedPdfs(payload, queryParams));
    }
  }

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const downloadPdf = () => {
    if (output.fileList) {
      output.fileList.forEach((d) => {
        const a = document.createElement("a");
        a.href = `data:application/pdf;base64,${d.file}`;
        a.download = `${d.name}.pdf`;
        a.click();
      });
    }
  };

  return (
    <>
      <div className="onboarding-execute-form">
        <Form
          noValidate
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          encType="multipart/form-data"
        >
          <h3 className="onboarding-form-header">
            {t("onboarding.general.test")}
          </h3>
          <div style={{ display: "flex" }}>
            <Button
              disabled={!Object.keys(documents).length > 0}
              className="card-button onboarding-button"
              onClick={() => {
                dispatch(getVersionSignPdf());
                setShowSignModal(true);
              }}
            >
              {isLoadingGetSignedPdf ? (
                <>
                  {t("onboarding.general.loading")}
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                t("signature.signPdf")
              )}
            </Button>

            <div style={{ marginLeft: "20px" }}>
              <ButtonDownload
                text={t("signature.downloadSignPdf")}
                onClick={() => downloadPdf()}
                disabled={output && output.fileList ? false : true}
              />
            </div>

            <Button
              style={{ marginLeft: "20px" }}
              variant="danger"
              onClick={() => {
                {
                  clearDocuments([]);
                  dispatch(setSignedPdf({}));
                  setOutput("");
                }
              }}
            >
              {t("signature.clearAll")}
            </Button>
          </div>
        </Form>
        <Output output={output.status || ""} />
      </div>
      <SignPdfModal
        showSignModal={showSignModal}
        handleCloseSignModal={setShowSignModal}
        testSignPdf={testSignPdf}
        documents={documents}
        resultVersionSignPdf={resultVersionSignPdf}
        isLoadingSignPdfVersion={isLoadingSignPdfVersion}
      />
    </>
  );
};
export default withNamespaces()(Test);
