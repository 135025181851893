import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import Link from "~/pages/Easypay/components/Link";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import "../assets/scss/_dateRangePicker.scss";

const DateRangeSmall = ({
  id,
  dates,
  setDates,
  setShowCalendar,
  clear,
  submit,
  t,
}) => {
  const [state, setState] = useState([
    {
      startDate: dates[0] ? new Date(dates[0]) : new Date(),
      endDate: dates[1] ? new Date(dates[1]) : new Date(),
      key: "selection",
    },
  ]);

  //stuff for getting language
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const language = userSettings.language;

  useEffect(() => {
    document.addEventListener("click", (e) => {
      handleCloseCalendar(e);
    });

    return () => {
      document.removeEventListener("click", (e) => {
        handleCloseCalendar(e);
      });
    };
  }, []);

  const handleCloseCalendar = (e) => {
    const calendar = document.getElementById(`date-${id}`);
    const input = document.getElementById(`input-date-${id}`);
    if (calendar) {
      if (!calendar.contains(e.target) && !input.contains(e.target)) {
        setShowCalendar(false);
      }
    }
  };

  useEffect(() => {
    setDates([state[0].startDate, state[0].endDate]);
  }, [state[0].startDate, state[0].endDate]);

  function printDates() {
    return (
      ("0" + state[0].startDate.getDate()).slice(-2) +
      "-" +
      (state[0].startDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      state[0].startDate.getFullYear() +
      " - " +
      ("0" + state[0].endDate.getDate()).slice(-2) +
      "-" +
      (state[0].endDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      state[0].endDate.getFullYear()
    );
  }

  const fixedRange = [
    t("bpm.onboarding.backoffice.administration.today"),
    t("bpm.onboarding.backoffice.administration.yesterday"),
    t("bpm.onboarding.backoffice.administration.last7Days"),
    t("bpm.onboarding.backoffice.administration.last30Days"),
    t("bpm.onboarding.backoffice.administration.thisMonth"),
    t("bpm.onboarding.backoffice.administration.lastMonth"),
    t("bpm.onboarding.backoffice.administration.personalised"),
  ];
  const handleRange = (e) => {
    switch (e) {
      case 0:
        setState([
          { startDate: new Date(), endDate: new Date(), key: "selection" },
        ]);

        break;
      case 1:
        var d = new Date();
        d.setDate(d.getDate() - 1);
        setState([
          {
            startDate: d,
            endDate: d,
            key: "selection",
          },
        ]);
        break;
      case 2:
        var d = new Date();
        d.setDate(d.getDate() - 7);
        setState([
          {
            startDate: d,
            endDate: new Date(),
            key: "selection",
          },
        ]);
        break;
      case 3:
        var d = new Date();
        d.setDate(d.getDate() - 30);
        setState([
          {
            startDate: d,
            endDate: new Date(),
            key: "selection",
          },
        ]);
        break;
      case 4:
        var d = new Date();
        setState([
          {
            startDate: new Date(d.getFullYear(), d.getMonth(), 1),
            endDate: new Date(d.getFullYear(), d.getMonth() + 1, 0),
            key: "selection",
          },
        ]);
        break;
      case 5:
        var d = new Date();
        setState([
          {
            startDate: new Date(d.getFullYear(), d.getMonth() - 1, 1),
            endDate: new Date(d.getFullYear(), d.getMonth(), 0),
            key: "selection",
          },
        ]);
        break;
      case 6:
        var d = new Date();
        setState([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        break;
    }
  };

  return (
    <div className="easypay-date-range" id={"date-" + id}>
      <div className="easypay-date-top">
        <div className="easypay-date-predefined-ranges">
          {fixedRange.map((range, i) => (
            <table key={i}>
              <tbody>
                <tr className="easypay-date-predefined-options">
                  <td className="easypay-date-predefined-option">
                    <button
                      className="easypay-date-predefined-button"
                      value={range}
                      onClick={() => handleRange(i)}
                    >
                      {range}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
        <div className="easypay-date-main-calendar">
          <DateRange
            locale={locales[language]}
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </div>
      </div>
      <div className="easypay-date-bottom">
        <div className="easypay-selected-dates">
          <span className="easypay-span-selected-dates">{printDates()}</span>
        </div>
        <div className="easypay-date-buttons">
          <div className="easypay-date-link">
            <Link
              text={t("bpm.onboarding.backoffice.administration.cancel")}
              onClick={() => clear()}
            />
          </div>
          <div className="easypay-date-select-button">
            <SimpleButton
              variant={"easypay-button-primary"}
              text={t("bpm.onboarding.backoffice.administration.select")}
              onClick={() => submit()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(DateRangeSmall);
