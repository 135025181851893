import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { find } from "lodash";

import WidgetContainer from "~/components/WidgetContainer";
import ProcessosFormHandler from "~/components/ProcessosFormHandler";
import ProtectedPage from "~/components/security/ProtectedPage";

import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import { FIN_MIDDLEWARE, BPM_MIDDLEWARE } from "~/utils/constants";

import {
  getMenusByApplication,
  addItemsToMenu,
} from "~/store/ducks/applicationMenu/actionTypes";
import ContentContainer from "~/containers/ContentContainer";

const Financeira = (props) => {
  const { isMongoOn } = props;
  const financeiraContext = FIN_MIDDLEWARE.context;

  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { rawMenus, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );

  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      dispatch(getMenusByApplication(username, "FIN"));
    }
  }, [user, dispatch]);

  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.fin.processos" });
    const items = rawMenus.map((item) => ({
      name: item.label,
      route: `${financeiraContext}/processos/form/${item.label}`,
      menuParent: processosMenu ? processosMenu.id : null,
    }));
    dispatch(addItemsToMenu(items));
  }, [rawMenus, menu, dispatch]);

  return (
    <>
      <ApplicationMenu applicationName={financeiraContext} />
      <Switch>
        <Route exact path={`${path}/processos`}>
          <ProtectedPage roles={["ADMIN", "FinanceiraUser", "FINANCEIRAUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/submenu`}>
          <ProtectedPage roles={["ADMIN", "FinanceiraUser", "FINANCEIRAUser"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/form`}>
          <ProtectedPage roles={["FinanceiraUser", "FINANCEIRAUser"]}>
            <ProcessosFormHandler
              app={financeiraContext}
              className="main-card-v2"
            />
          </ProtectedPage>
        </Route>

        <Route path={path}>
          {isMongoOn && (
            <ProtectedPage
              roles={[
                "ADMIN",
                "FinanceiraUser",
                "FINANCEIRAUser",
                "FinanceiraBackoffice",
              ]}
            >
              <ContentContainer>
                <WidgetContainer />
              </ContentContainer>
            </ProtectedPage>
          )}
        </Route>
      </Switch>
    </>
  );
};
export default Financeira;
