import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Alert } from "react-bootstrap";

const CreateAlert = ({ field, index, variant, translations, language }) => {
  const translateText = (textField) => {
    let text = undefined;
    if (textField.includes("::t::")) {
      let lingua = language.trim();
      let splittext = textField.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if (translations) {
        text = translations?.[lingua][texttoshow] + texttoadd;
      }
    } else {
      text = textField;
    }
    return text;
  };

  return field && field.text && field.cssclass ? (
    <Alert
      key={index}
      variant={variant || "info"}
      style={{
        backgroundColor:
          field.cssclass === "background:transparent" ? "transparent" : "",
        border:
          field.cssclass === "background:transparent" ? "transparent" : "",
        color: field.cssclass === "background:transparent" ? "#4c4b4b" : "",
      }}
    >
    {ReactHtmlParser(translateText(field.text))}
    </Alert>
  ) : field && field.text ? (
    <Alert key={index} variant={variant || "info"}>
      {ReactHtmlParser(translateText(field.text))}
    </Alert>
  ) : null;
};
export default CreateAlert;
