
import { put } from 'redux-saga/effects';
import http from '~/utils/config/http';
import { BPM_MIDDLEWARE } from '~/utils/constants';

import {
  setReportPerformance,
  setReportSla,
  setReportProcessStatistics,
  setReportProcessesStatus
} from './actionTypes';

import { activeError } from '~/components/Messages/actions';

export function* findReportPerformance({ data }) {
  let widgetId = data.widgetId
  try {
    const resp = yield http.post('/api/legacy-report/performance', data.payload, BPM_MIDDLEWARE);
    const payload = {
      id: widgetId,
      report: resp.data
    }
    yield put(setReportPerformance(payload));
  } catch (e) {
    yield put(activeError(e));
  }
}

export function* findReportSla({ data }) {
  let widgetId = data.widgetId
  try {
    const resp = yield http.post(`/api/legacy-report/findSla`, data.payload, BPM_MIDDLEWARE);
    const payload = {
      id: widgetId,
      report: resp.data
    }
    yield put(setReportSla(payload));
  } catch (e) {
    yield put(activeError(e));
  }
}

export function* findReportProcessStatistics({ data }) {
  let widgetId = data.widgetId
  try {
    const resp = yield http.post('/api/legacy-report/findProcessStatistics', data.payload, BPM_MIDDLEWARE);
    const payload = {
      id: widgetId,
      report: resp.data
    }
    yield put(setReportProcessStatistics(payload));
  } catch (e) {
    yield put(activeError(e));
  }
}

export function* findReportProcessesStatus({ data }) {
  let widgetId = data.widgetId
  try {
    const resp = yield http.post('/api/legacy-report/findProcessStatus', data.payload, BPM_MIDDLEWARE);
    /*const testData=[
      {total_pid: 52, process_status: "Contabilidade"},
      {total_pid: 19, process_status: "Expedição"},
      {total_pid: 2, process_status: "Margem"},
      {total_pid: 125, process_status: "Aaaaaaa"},
      {total_pid: 41, process_status: "Bbbbbbbb"},
      {total_pid: 92, process_status: "Ccccccc"},
      {total_pid: 56, process_status: "Ddddddd"},
      {total_pid: 48, process_status: "Eeeeeee"},
      {total_pid: 74, process_status: "Ffffffff"},
      {total_pid: 30, process_status: "Ggggggg"},
    ]*/

    const payload = {
      id: widgetId,
      report: resp.data
      //report: testData
    }
    yield put(setReportProcessesStatus(payload));
} catch (e) {
    yield put(activeError(e));
  }
}
