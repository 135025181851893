import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import { PORTALRH_MIDDLEWARE, RH_MIDDLEWARE } from "~/utils/constants";

import {
  setCheck,
  setDocuments,
  setDocument,
  setSuccessDelete,
  redirectUserTo,
  setOperations,
  setPreviewBinary,
  setCollaborators,
  setScheduler,
  clearCheck,
  setRequestsByUsername,
  setUserPayData,
  setUserInformations,
  setUserSynchronizationLog,
  setObjectives,
  setObjectiveByID,
  setEvaluationSystems,
  setEvaluationPeriods,
  setDocsDisclaimers,
  stopEditingDocsDisclaimers,
  stopUploadSchedule,
  setEntities,
  setLogs,
  stopUploadAttendance,
  setEntitiesAttendance,
  setAttendanceLogs,
  setExportLogs,
  setExportAbsenceLogs,
  setExportAbsenceData,
  setGroupUsers,
  setRegistoFaltas,
  successAdded,
  setTipoAusencias,
  setTipoAusenciasIDONIC,
} from "./actionTypes";

import { activeError, activeSuccess } from "~/components/Messages/actions";

export function* getCheck() {
  try {
    const resp = yield http.get(
      "/api/timesheet/ischeckinorout",
      PORTALRH_MIDDLEWARE
    );
    yield put(setCheck(resp.data.response));
  } catch (e) {
    yield put(clearCheck());
  }
}

export function* toggleCheck() {
  const path = "personalarea/frame/Registo%20Presenças";
  try {
    yield http.get("/api/timesheet/checkinout", PORTALRH_MIDDLEWARE);
    const response = yield http.get(
      "/api/timesheet/ischeckinorout",
      PORTALRH_MIDDLEWARE
    );
    yield put(setCheck(response.data.response));
    yield put(redirectUserTo(path));
  } catch (e) {
    yield put(activeError("general.error"));
    yield put(clearCheck());
  }
}

export function* getDocuments() {
  try {
    const resp = yield http.get(
      "/api/document-management/document/all",
      PORTALRH_MIDDLEWARE
    );
    Array.isArray(resp.data)
      ? yield put(setDocuments(resp.data))
      : yield put(setDocuments([]));
  } catch (e) {
    yield put(setDocuments([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
export function* getDocumentsbyUsername(obj) {
  try {
    const resp = yield http.get(
      `/api/document-management/document/all/${obj.username}`,
      PORTALRH_MIDDLEWARE
    );
    Array.isArray(resp.data)
      ? yield put(setDocuments(resp.data))
      : yield put(setDocuments([]));
  } catch (e) {
    yield put(setDocuments([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* saveDocumentList({ docList, uploadEmMassa, isForAll }) {
  let resp;
  try {
    const data = new FormData();
    for (let i = 0; i < docList.length; i++) {
      data.append("file", docList[i].file);
    }
    data.append(
      "document",
      new Blob([JSON.stringify(docList)], { type: "application/json" })
    );

    data.append(
      "isMassUpload",
      new Blob([JSON.stringify(uploadEmMassa)], { type: "application/json" })
    );
    data.append("isForAll",  new Blob([JSON.stringify(isForAll)], { type: "application/json" }));
    resp = yield http.post(
      "/api/document-management/document/save",
      data,
      PORTALRH_MIDDLEWARE
    );

    if (resp) {
      yield put(
        activeSuccess("portalrh.documentManagement.saveDocumentSuccessMessage")
      );
    }
  } catch (e) {
    const { response } = e;
    yield put(
      activeError(
        response?.data?.response
          ? `notranslate:${response?.data?.response}`
          : "portalrh.documentManagement.saveErrorMessage"
      )
    );
  }
}
export function* saveDocumentListWithUsername({
  docList,
  uploadEmMassa,
  username,
  isForAll
}) {
  let resp;
  try {
    const data = new FormData();
    for (let i = 0; i < docList.length; i++) {
      data.append("file", docList[i].file);
    }
    data.append(
      "document",
      new Blob([JSON.stringify(docList)], { type: "application/json" })
    );

    data.append(
      "isMassUpload",
      new Blob([JSON.stringify(uploadEmMassa)], { type: "application/json" })
    );
    data.append("username", username);
    data.append("isForAll",  new Blob([JSON.stringify(isForAll)], { type: "application/json" }));
    resp = yield http.post(
      "/api/document-management/document/save/username",
      data,
      PORTALRH_MIDDLEWARE
    );

    if (resp) {
      yield put(
        activeSuccess("portalrh.documentManagement.saveDocumentSuccessMessage")
      );
    }
  } catch (e) {
    const { response } = e;
    yield put(
      activeError(
        response?.data?.response
          ? `notranslate:${response?.data?.response}`
          : "portalrh.documentManagement.saveErrorMessage"
      )
    );
  }
}

export function* deleteDocument({ docId }) {
  try {
    const resp = yield http.delete(
      `/api/document-management/document/delete/${docId}`,
      PORTALRH_MIDDLEWARE
    );
    if (resp.status == 200) {
      yield put(setSuccessDelete());
    }
  } catch (e) {
    yield put(activeError("portalrh.documentManagement.deleteErrorMessage"));
  }
}

export function* getDocumentBinary({ id }) {
  try {
    const resp = yield http.get(
      `api/document-management/document/content/${id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setDocument(resp.data));
  } catch (e) {
    yield put(setDocument(null));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getOperations({ t }) {
  try {
    const resp = yield http.get(`api/operation/all`, PORTALRH_MIDDLEWARE);
    resp.data.map((el) => (el.name = t("portalrh.operations." + el.name)));
    if (Array.isArray(resp.data)) yield put(setOperations(resp.data));
  } catch (e) {
    yield put(setOperations([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getPreviewBinary({ id }) {
  try {
    const resp = yield http.get(
      `api/document-management/document/content/${id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setPreviewBinary(resp.data));
  } catch (e) {
    yield put(setPreviewBinary(null));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* updateDocument({ document }) {
  try {
    const data = new FormData();
    data.append(
      "document",
      new Blob([JSON.stringify(document)], { type: "application/json" })
    );
    const resp = yield http.post(
      "/api/document-management/document/update",
      data,
      PORTALRH_MIDDLEWARE
    );
    if (resp && !document.seen)
      yield put(
        activeSuccess(
          "portalrh.documentManagement.updateDocumentSuccessMessage"
        )
      );
  } catch (e) {
    yield put(
      activeError("portalrh.documentManagement.updateDocumentErrorMessage")
    );
  }
}

export function* getCollaborators() {
  try {
    const resp = yield http.get(`api/user/colaboradores`, PORTALRH_MIDDLEWARE);
    if (Array.isArray(resp.data)) yield put(setCollaborators(resp.data));
  } catch (e) {
    yield put(setCollaborators([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getScheduler({payload}) {
  try {
    const resp = yield http.post(`api/timesheet/folha-de-ponto`, payload, PORTALRH_MIDDLEWARE);
      yield put(setScheduler(resp.data));
  } catch (e) {
    yield put(setScheduler([]));
  } 
}

export function* getDocumentsAdmin() {
  try {
    const resp = yield http.get(
      "/api/document-management/document/find/rhadmin",
      PORTALRH_MIDDLEWARE
    );
    Array.isArray(resp.data)
      ? yield put(setDocuments(resp.data))
      : yield put(setDocuments([]));
  } catch (e) {
    yield put(setDocuments([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* findDocumentsByFilters({ filters }) {
  try {
    const resp = yield http.post(
      "/api/document-management/document/find/rhadmin",
      filters,
      PORTALRH_MIDDLEWARE
    );
    Array.isArray(resp.data)
      ? yield put(setDocuments(resp.data))
      : yield put(setDocuments([]));
  } catch (e) {
    yield put(setDocuments([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getRequestsByUsername({ username, procGeneric }) {
  try {
    if (procGeneric) {
      const resp = yield http.get(
        `api/request/all-by-username?username=${username}?procGeneric=${1}`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setRequestsByUsername(resp.data));
    } else {
      const resp = yield http.get(
        `api/request/all-by-username?username=${username}?procGeneric=${0}`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setRequestsByUsername(resp.data));
    }
  } catch (e) {
    yield put(setRequestsByUsername([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* checkUpdateByUsername({ username }) {
  try {
    const resp = yield http.get(
      `api/user/check-update-by-username?username=${username}`,
      PORTALRH_MIDDLEWARE
    );
  } catch (e) {
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getUserPayData({ funcionarioNum }) {
  try {
    const resp = yield http.get(
      `api/open/paymentdata/find-by-funcionarionum?funcionarioNum=${funcionarioNum}`,
      RH_MIDDLEWARE
    );

    yield put(setUserPayData(resp.data));
  } catch (e) {
    yield put(setUserPayData([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getByUsername({ username }) {
  try {
    const resp = yield http.get(
      `api/user/find-user-informations-by-username?username=${username}`,
      PORTALRH_MIDDLEWARE
    );

    yield put(setUserInformations(resp.data));
  } catch (e) {
    yield put(setUserInformations([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
export function* getUserSynchronizationLog() {
  try {
    const resp = yield http.get(
      `api/get/user-synchronization-log`,
      RH_MIDDLEWARE
    );
    yield put(setUserSynchronizationLog(resp.data));
  } catch (e) {
    yield put(setUserSynchronizationLog([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//Used in Performance/Evaluator/Objective
export function* getObjectives() {
  try {
    const resp = yield http.get(
      `api/performance/evaluator/objectives`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setObjectives(resp.data));
  } catch (e) {
    yield put(setObjectives([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//Used in Performance/Evaluator/Objective
export function* getObjectiveByID({ obj_id }) {
  try {
    const resp = yield http.get(
      `api/performance/evaluator/objective-by-id?obj_id=${obj_id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setObjectiveByID(resp.data));
  } catch (e) {
    yield put(setObjectiveByID([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//Used in Performance/Evaluator/Objective
export function* getEvaluationSystems() {
  try {
    const resp = yield http.get(
      `api/performance/evaluator/evaluationSystems`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setEvaluationSystems(resp.data));
  } catch (e) {
    yield put(setEvaluationSystems([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//Used in Performance/Evaluator/Objective
export function* getEvaluationPeriods({ sis_id }) {
  try {
    const resp = yield http.get(
      `api/performance/evaluator/evaluationPeriods?sis_id=${sis_id}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setEvaluationPeriods(resp.data));
  } catch (e) {
    yield put(setEvaluationPeriods([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

//OPERATION ANNEX DOCS #####################################
export function* updateOperationStates({ operations }) {
  try {
    yield http.put(
      `api/operation/update-annex-docs`,
      JSON.stringify(operations),
      PORTALRH_MIDDLEWARE
    );

    yield put(
      activeSuccess("portalrh.annexDocs.documentsAnnexationUpdatedSuccessfully")
    );
  } catch (e) {
    yield put(
      activeError("portalrh.annexDocs.errorUpdatingDocumentsAnnexation")
    );
  }
}

//DOCS DISCLAIMERS #########################################
export function* getDocsDisclaimers() {
  try {
    const resp = yield http.get(`api/docs-disclaimer/all`, PORTALRH_MIDDLEWARE);

    yield put(setDocsDisclaimers(resp.data));
  } catch (e) {
    yield put(setDocsDisclaimers([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* updateDocsDisclaimer({ docsDisclaimer }) {
  try {
    yield http.put(
      `api/docs-disclaimer/`,
      JSON.stringify(docsDisclaimer),
      PORTALRH_MIDDLEWARE
    );

    yield put(
      activeSuccess(
        "portalrh.docsDisclaimer.documentsDisclaimerUpdatedSuccessfully"
      )
    );
  } catch (e) {
    yield put(
      activeError("portalrh.docsDisclaimer.errorUpdatingDocumentsDisclaimer")
    );
  }
  yield put(stopEditingDocsDisclaimers());
}

//Export Schedule service for IHG web time integration
export function* uploadSchedule({ uploadData, selectedEntityId }) {
  try {
    yield http.post(
      `/api/innux/escalas?idEntidade=${selectedEntityId}`,
      uploadData,
      PORTALRH_MIDDLEWARE
    );

    yield put(activeSuccess("portalrh.exportSchedule.successWarnning"));
  } catch (e) {
    yield put(activeError("portalrh.exportSchedule.errorWarnning"));
  }
  yield put(stopUploadSchedule());
}

export function* uploadAttendance({ uploadData, selectedEntityId }) {
  try {
    yield http.post(
      `/api/importAttendance?idEntidade=${selectedEntityId}`,
      uploadData,
      RH_MIDDLEWARE
    );

    yield put(activeSuccess("portalrh.importAttendance.successWarnning"));
  } catch (e) {
    yield put(activeError("portalrh.importAttendance.errorWarning"));
  }
  yield put(stopUploadAttendance());
}

export function* getEntities() {
  try {
    const resp = yield http.get(`api/innux/entidades/all`, PORTALRH_MIDDLEWARE);

    yield put(setEntities(resp.data));
  } catch (e) {
    yield put(setEntities([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getEntitiesAttendance() {
  try {
    const resp = yield http.get(`api/attendance/entities`, PORTALRH_MIDDLEWARE);

    yield put(setEntitiesAttendance(resp.data));
  } catch (e) {
    yield put(setEntitiesAttendance([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getLogs() {
  try {
    const resp = yield http.get("api/innux/get-logs", PORTALRH_MIDDLEWARE);
    yield put(setLogs(resp.data));
  } catch (e) {
    yield put(setLogs([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getAttendanceLogs() {
  try {
    const resp = yield http.get("api/attendanceLogs", RH_MIDDLEWARE);
    yield put(setAttendanceLogs(resp.data));
  } catch (e) {
    yield put(setAttendanceLogs([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getExportLogs({ logs }) {
  try {
    const resp = yield http.post(`/api/export-logs`, logs, RH_MIDDLEWARE);
    yield put(setExportLogs(resp.data));
  } catch (e) {
    yield put(setExportLogs([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getExportAbsenceLogs() {
  try {
    const resp = yield http.get("api/export-logs-txt", PORTALRH_MIDDLEWARE);
    yield put(setExportAbsenceLogs(resp.data));
    yield put(successAdded(false));
  } catch (e) {
    yield put(successAdded(false));
    yield put(setExportAbsenceLogs([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
export function* getExportAbsenceData() {
  try {
    const resp = yield http.get(`api/export-data-txt`, PORTALRH_MIDDLEWARE);
    yield put(setExportAbsenceData(resp.data));
    yield put(successAdded(false));
  } catch (e) {
    yield put(setExportAbsenceData([]));
    yield put(successAdded(false));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* exportTxt({ payload }) {
  yield put(successAdded(false));
  try {
    const resp = yield http.post(
      `api/export-txt`,
      payload,
      PORTALRH_MIDDLEWARE
    );

    //yield put(setRegistoFaltas(resp?.data));
    yield put(successAdded(true));
  } catch (e) {
    yield put(successAdded(false));
    //yield put(setRegistoFaltas([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
export function* getGroupUsers() {
  try {
    const resp = yield http.get(`api/get-group-users`, PORTALRH_MIDDLEWARE);

    yield put(setGroupUsers(resp?.data));
  } catch (e) {
    yield put(setGroupUsers([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getRegistoFaltas({ payload }) {
  let url = undefined;
  if (payload.tipo == "Faltas") {
    url = `api/registo-faltas/by-date`;
  } else if (payload.tipo == "Férias") {
    url = `api/registo-faltas/by-date`;
  } else {
    url = `api/registo-faltas/by-date`;
  }

  if (url != undefined) {
    yield callRegistoFaltas(payload, url);
  }
}

function* callRegistoFaltas(payload, url) {
  try {
    const resp = yield http.put(url, payload, RH_MIDDLEWARE);

    yield put(setRegistoFaltas(resp?.data));
  } catch (e) {
    yield put(setRegistoFaltas([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getTipoAusencias() {
  try {
    const resp = yield http.get(`api/tipo-ausencia/all`, RH_MIDDLEWARE);

    yield put(setTipoAusencias(resp?.data));
  } catch (e) {
    yield put(setTipoAusencias([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}

export function* getTipoAusenciasIDONIC() {
  try {
    const resp = yield http.get(`api/tipo-ausencia/idonic/all`, RH_MIDDLEWARE);

    yield put(setTipoAusenciasIDONIC(resp?.data));
  } catch (e) {
    yield put(setTipoAusenciasIDONIC([]));
    yield put(activeError("portalrh.documentManagement.errorMessage"));
  }
}
