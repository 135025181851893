import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import { Modal, Button, Row, Col } from "react-bootstrap";

import { getRoles, clearRoles } from "../../Role/actions";

import Spinner from "~/components/Spinner";
import RoleTable from "./RoleTable";

const RoleModal = ({ t, showModal, setShowModal, action }) => {
  const dispatch = useDispatch();
  const { roles, isLoadingRoles } = useSelector((state) => state.roleReducer);

  useEffect(() => {
    dispatch(getRoles());
    return () => {
      dispatch(clearRoles());
    };
  }, [dispatch]);

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      backdrop="static"
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("admin.userManagement.list.modalRolesTitle")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Spinner spinning={isLoadingRoles} />
        {!isLoadingRoles && (
          <Row noGutters="true">
            <Col>
              <RoleTable
                roles={roles}
                isLoading={isLoadingRoles}
                showAddButton={false}
                icon="icon-add"
                action={action}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={() => setShowModal(false)}>
          {t("general.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withNamespaces()(RoleModal);
