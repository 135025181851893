import {
  CHECK_TOKEN,
  DONE_CHECK_TOKEN,
  CLEAR_CHECK_TOKEN
} from "./actionTypes";

const initialState = {
  isCheckingToken: undefined,
  isValidToken: undefined
}

export const oauthReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_TOKEN: return { ...state, isCheckingToken: true }
    case DONE_CHECK_TOKEN:
      return { ...state, isCheckingToken: false, isValidToken: action.isValidToken }
    case CLEAR_CHECK_TOKEN:
      return { ...state, isValidToken: initialState.isValidToken }
    default:
      return state;
  }
}
