import _uniqueId from "lodash/uniqueId";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import arrowDown from "~/pages/Easypay/assets/img/icons/arrow-down-grey.png";
import arrowUp from "~/pages/Easypay/assets/img/icons/arrow-up-blue.png";
import "~/pages/Liftworld/assets/scss/_dropdown2.scss";
import "~/pages/Liftworld/assets/scss/_inputDropdownNormal.scss";

/*
    Props explanation:
        - t (used when needing to translate any 'general' words) **REQUIRED**
        - list (list of options the dropdown is going to use) **REQUIRED**
        - handleSubmit={(value) => handleSelectChange(value)} **REQUIRED**
        - placeholder **DEFAULT is "option"**
        - required={required} **DEFAULT is false**
        - disabled (used when user wants the input to be disabled)
*/

/* IMPORTANT */
/* When using list make sure you have it in the correct layout. Example:
  
        list = [
            {"label": "label here",
            "value": "value here"}
                ]

    If you have any option in the list that you want to make as default input 
    just add in the property "selected" :true to the list option
*/

const InputDropdown = (props) => {
  const {
    t,
    list = [{ label: t("general.dataNotFound") }],
    required = false,
    handleSubmit = false,
    placeholder = "Selecionar opção",
    disabled,
    text,
    name,
    emptyDefault,
    noBorder,
    order,
    setShowTextInput = false,
    inputTextExtra = false,
  } = props;
  /*
        Sorts an array by name, or number (both ascending or descending)
    */
  const sortBy = {
    nameAscending: (prop) =>
      prop.sort((a, b) => a.label.localeCompare(b.label)),
    nameDescending: (prop) =>
      prop.sort((a, b) => b.label.localeCompare(a.label)),
    numberAscending: (prop) => prop.sort((a, b) => a - b),
    numberDescending: (prop) => prop.sort((a, b) => b - a),
    noOrder: (prop) => prop,
  };

  //Sorts list
  const sortedList = order ? sortBy.nameAscending(list) : list;

  //Translates labels
  const optionList = sortedList.map((i) => {
    return { label: t(i.label), value: i.value, selected: i.selected };
  });

  const [selectedValue, setSelectedValue] = useState("");
  const [label, setLabel] = useState("");
  const [displayList, setDisplayList] = useState([optionList]);
  const [showDropdown, setShowDropdown] = useState(false);

  //Randomly creates an id for dropdown and input
  const id = useRef(_uniqueId(""));

  useEffect(()=>{
    if (inputTextExtra) {
      if (label === "Sim") {
        setShowTextInput(true);
      } else {
        setShowTextInput(false);
      }
    }
  },[label])

  useEffect(() => {
    const defaultItem = optionList.find((item) => item.selected === true);
    if (defaultItem) {
      setLabel(defaultItem.label);
      setSelectedValue(defaultItem.value);
    } else if (!emptyDefault) {
      if (label === "" && list.length > 0) {
        setLabel(t(list[0].label));
        setSelectedValue(list[0].value);
      }
    }
  }, [list]);

  useEffect(() => {
    if (
      document.getElementById(`formInput-${id.current}`).value === "" &&
      document.getElementById(`selected-${id.current}`) != null
    ) {
      document.getElementById(`selected-${id.current}`).style.backgroundColor =
        "#f6f6f6";
      document.getElementById(`selected-${id.current}`).style.color = "black";
    }

    if (
      document
        .getElementById(`dropDown-${id.current}`)
        .className.includes("liftworld-show") &&
      document.getElementById(`selected-${id.current}`)
    ) {
      document.getElementById(`selected-${id.current}`).style.backgroundColor =
        "var(--primary)";
      document.getElementById(`selected-${id.current}`).style.color = "white";
    }
  }, [displayList]);

  /*
    Function that runs when clicking any option
  */
  const handleSelect = (e) => {
    //Changes input to the selected option
    setSelectedValue(e.target.value);
    const element = optionList.find(
      (element) => element.value === e.target.value
    );

    if (element) {
      setLabel(element.label);
    }
    //Sorts list to display selected first
    sortListAfterSelect(optionList, e.target.value);
    //Hides dropdown after selecting any option
    hideDropDown();
    const defaultItem = optionList.find((item) => item.selected === true);

    if (!handleSubmit) {
      setSelectedValue(e.target.value);
      setLabel(e.target.label);
      return;
    }

    if (defaultItem) {
      if (defaultItem.value !== e.target.value) {
        handleSubmit(e.target.value);
      }
    } else {
      handleSubmit(e.target.value);
    }
  };

  /* 
    Sorts list to display selected element in first place. Also deletes it from the displayed list
  */
  const sortListAfterSelect = (list, selected) => {
    let newList = [];
    newList[0] = list.filter((e) => e.value == selected)[0];
    newList = newList.concat(list.filter((e) => e.value != selected));
    setDisplayList(newList);
    return newList;
  };

  /*
    Clears out the input whenever the option isn't in list
  */
  const handleClose = () => {
    const inputValue = document
      .getElementById(`formInput-${id.current}`)
      .value.trim();

    if (
      optionList.some(
        (option) => option.label.toUpperCase() === inputValue.toUpperCase()
      )
    ) {
      const option = optionList.find(
        (option) => option.label.toUpperCase() === inputValue.toUpperCase()
      );
      setSelectedValue(option.value);
      setLabel(option.label);
      if (!handleSubmit) {
        return;
      }
      handleSubmit(option.value);

      const defaultItem = optionList.find((item) => item.selected === true);
      if (defaultItem && defaultItem.value !== option.value) {
        handleSubmit(option.value);
      }
    } else {
      const defaultItem = optionList.find((item) => item.selected === true);
      if (defaultItem) {
        setSelectedValue(defaultItem.value ? defaultItem.value : "");
        setLabel(defaultItem.label ? defaultItem.label : "");
      } else {
        setSelectedValue("");
        setLabel("");
      }
    }
  };

  /*
    Toggles dropdown list everytime user clicks in input
    If first element is selected liftworld-shows it highlighted
  */
  const toggleList = () => {
    document
      .getElementById(`dropDown-${id.current}`)
      .classList.toggle("liftworld-show");

    if (
      document
        .getElementById(`dropDown-${id.current}`)
        .className.includes("liftworld-show")
    ) {
      let newList = sortListAfterSelect(optionList, selectedValue);

      if (newList[0] === undefined) {
        setDisplayList(optionList);
      } else {
        setDisplayList(sortListAfterSelect(optionList, selectedValue));
      }
    } else {
      handleClose();
    }
  };

  const hideDropDown = (desiredId) => {
    desiredId === undefined
      ? document
          .getElementById(`dropDown-${id.current}`)
          .classList.remove("liftworld-show")
      : document
          .getElementById(`dropDown-${desiredId}`)
          .classList.remove("liftworld-show");
  };

  /*<
     Function that runs whenever the input get out of focus
  */
  const outOfFocus = (e) => {
    handleClose();
    hideDropDown(parseInt(e.target.id.match(/\d+/)[0]));
  };

  return (
    <>
      <Form.Group controlId={`formText-field`} style = {{padding : "0px"}}>
        <div className={"liftworld-input-dropdown-normal-wrapper"}>
          <div className="liftworld-input-label">{t(text)}</div>
          <div
            className={`liftworld-input-dropdown-normal-field-wrapper  ${
              noBorder ? "" : label ? "liftworld-filled" : ""
            }`}
            disabled={disabled}
          >
            <input
              disabled={disabled}
              autoComplete="off"
              onFocus={() => {
                setShowDropdown(true);
              }}
              className={`liftworld-input-dropdown-normal-field ${
                label ? "liftworld-filled" : ""
              } `}
              placeholder={`${placeholder}`}
              id={`formInput-${id.current}`}
              onMouseDown={() => {
                toggleList();
                setShowDropdown(!showDropdown);
              }}
              value={label}
              required={required}
              onBlur={(e) => {
                outOfFocus(e);
                setShowDropdown(false);
              }}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              name={name}
              readOnly
            />
            <div className="liftworld-input-dropdown-normal-icon-wrapper">
              <img
                className="liftworld-input-dropdown-normal-icon"
                src={showDropdown ? arrowUp : arrowDown}
                alt="search-icon"
                onMouseDown={() => {
                  if (!disabled) {
                    toggleList();
                    setShowDropdown(!showDropdown);
                  }
                }}
              />
            </div>
          </div>

          <div id={`dropDown-${id.current}`} className="liftworld-dropdown-normal">
            {displayList.map((option, i) => {
              return option.label === t("general.dataNotFound") ? (
                <option id="liftworld-dropdown-normal-no-result" key={i}>
                  {t("general.dataNotFound")}
                </option>
              ) : i === 0 ? (
                <option
                  id={`selected-${id.current}`}
                  key={i}
                  onMouseDown={(e) => handleSelect(e)}
                  value={option.value}
                  className="liftworld-dropdown-normal-option"
                >
                  {option.label}
                </option>
              ) : (
                <option
                  className="liftworld-dropdown-normal-option"
                  key={i}
                  onMouseDown={(e) => handleSelect(e)}
                  value={option.value}
                >
                  {option.label}
                </option>
              );
            })}
          </div>
        </div>
        <input type="hidden" name={name} value={selectedValue} />
        <Form.Control.Feedback type="invalid">
          {`O campo field é de preenchimento obrigatório`}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default withNamespaces()(InputDropdown);
