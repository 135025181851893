export const SET_IS_EDIT_ANNOTATION =
  "processAnnotations@SET_IS_EDIT_ANNOTATION";

export const SET_SELECTED_ROW = "processAnnotations@SET_SELECTED_ROW";
export const UPDATE_ANNOTATION = "processAnnotations@UPDATE_ANNOTATION";
export const SET_UPDATE_ANNOTATION = "processAnnotations@SET_UPDATE_ANNOTATION";

export const FIND_COMMENT_HISTORY = "processAnnotations@FIND_COMMENT_HISTORY";
export const SET_COMMENT_HISTORY = "processAnnotations@SET_COMMENT_HISTORY";

export const setIsEditingAnnotation = (isEdit) => ({
  type: SET_IS_EDIT_ANNOTATION,
  data: isEdit,
});

export const setSelectedRow = (row) => ({
  type: SET_SELECTED_ROW,
  data: row,
});

export const updateAnnotation = (annotation, username) => ({
  type: UPDATE_ANNOTATION,
  annotation,
  username,
});

export const setUpdateAnnotation = (result) => ({
  type: SET_UPDATE_ANNOTATION,
  data: result,
});

export const findCommentHistory = (payload) => ({
  type: FIND_COMMENT_HISTORY,
  data: payload,
});

export const setCommentHistory = (data) => ({
  type: SET_COMMENT_HISTORY,
  data,
});
