import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { pagination } from "../pagination/currentSizePerPage/5";
import "~/assets/css/icons.css";
import { Button } from "react-bootstrap";

const TaskTablePagDefault = (props) => {
  const {
    t,
    tasksToRender,
    columns,
    field,
    order,
    isMobile,
    rowEvents,
    handleSearch,
  } = props;

  const { SearchBar } = Search;
  return (
    <>
      <div className="custom-table task-table">
        <ToolkitProvider
          keyField="pnumber"
          data={tasksToRender}
          columns={columns}
          search
        >
          {(props) => (
            <div style={{ paddingBottom: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <SearchBar
                  {...props.searchProps}
                  style={{ paddingLeft: "20px" }}
                  placeholder={t("general.search")}
                />
                <Button
                  style={{ marginBottom: "8px" }}
                  className="card-button"
                  onClick={() => handleSearch()}
                >
                  {t(`taskPage.general.update`)}{" "}
                </Button>
              </div>
              <div>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("taskPage.general.tasksNotFound")}
                  sort={{
                    dataField: field,
                    order: order,
                  }}
                  striped={isMobile ? false : true}
                  hover={isMobile ? false : true}
                  rowEvents={rowEvents}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default withNamespaces()(TaskTablePagDefault);
