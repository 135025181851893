import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Card, Row, Col, Button, Alert } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import Test from "./Test";
import Toast from "~/components/Toast";

import Messages from "~/components/Messages";
import FilePreviewCard from "~/pages/Onboarding/components/DigitalSignature/FilePreviewCard";
import { toastActiveWarning } from "~/components/Toast/actions";

const SUPPORTED_FILES_TYPES = ["application/pdf"];

const FileUpload = ({ t }) => {
  const dispatch = useDispatch();

  const [warningMessage, setWarningMessage] = useState("");
  const [onDragOver, setOnDragOver] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [documents, setDocuments] = useState([]);
  const inputFile = useRef(null);

  const maxFileSize = 1024 * 1024 * 15; //15Mb
  const maxFiles = 100;

  useEffect(() => {
    showWarning && dispatch(toastActiveWarning(warningMessage));
    showWarning && setShowWarning(false);
  }, [showWarning]);

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    const files_ = event.dataTransfer.files;
    const files = [];
    for (let i = 0; i < files_.length; i++) {
      files.push(files_.item(i));
    }
    handleMultipleFiles(files);
    event.preventDefault();
  };

  const handleOnChoose = (event) => {
    if (event.target.multiple === true) {
      const files_ = event.target.files;
      const files = [];
      for (let i = 0; i < files_.length; i++) {
        files.push(files_.item(i));
      }
      handleMultipleFiles(files);
    } else {
      handleMultipleFiles([event.target.files[0]]);
    }
  };

  const handleMultipleFiles = (files) => {
    if (files.length <= maxFiles) {
      if (files.some((file) => !SUPPORTED_FILES_TYPES.includes(file.type))) {
        setWarningMessage(t("portalrh.documentManagement.checkFileType"));
        setShowWarning(true);
      } else {
        let totalfilesize = files.reduce(
          (accumulator, currentValue) => accumulator + currentValue.size,
          0
        );

        let savedFilesSize = documents.reduce(
          (accumulator, currentValue) => accumulator + currentValue.file.size,
          0
        );
        if (totalfilesize + savedFilesSize > maxFileSize) {
          setWarningMessage(
            t("portalrh.documentManagement.exceedFileSize") +
              t("portalrh.documentManagement.currentFileSize") +
              (savedFilesSize / (1024 * 1024)).toFixed(2) +
              " Mb."
          );
          setShowWarning(true);
        } else {
          let documents_ = [...documents];
          files.forEach((f) => documents_.push({ file: f, fileName: f.name }));
          //reassign ids
          setDocuments(
            documents_.map((d, i) => {
              return {
                file: d.file,
                fileName: d.fileName,
                id: i,
              };
            })
          );
        }
      }
    } else {
      setWarningMessage(t("portalrh.documentManagement.tooManyFiles"));
      setShowWarning(true);
    }
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const clearStates = () => {
    setWarningMessage("");
  };

  const handleDeleteDocument = (id) => {
    setDocuments(documents.filter((d) => d.id !== id));
  };

  return (
    <div style={{ display: "flex" }}>
      <Col lg={true}>
        <div style={{ display: "flex" }}>
          <Card
            bsPrefix="card-flat"
            style={{ boxShadow: "none", padding: "0px", borderRadius: "0" }}
          >
            <h5 className="onboarding-form-header">
              {t("signature.fileUpload")}
            </h5>
            <Card.Body style={{ display: "flex", flexDirection: "column" }}>
              <Row className="justify-content-md-center">
                <Col sm={8} className="uploadArea">
                  <div
                    style={{
                      paddingBottom: "10px",
                      alignSelf: "flex-start",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  ></div>
                  <div
                    className="dropArea"
                    onDrop={(e) => handleOnDrop(e)}
                    onClick={() => handleClick()}
                    onDragEnter={() => setOnDragOver(true)}
                    onDragLeave={() => setOnDragOver(false)}
                    onDragOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    style={
                      onDragOver
                        ? { background: "#fff" }
                        : { background: "#efefef" }
                    }
                  >
                    <span>
                      <i
                        className={
                          "icon-submit portal-image-upload-icon-active"
                        }
                      />
                      <div className={"portal-label-main-active"}>
                        {t("portalrh.documentManagement.dropToUpload")}
                      </div>
                    </span>
                    <input
                      multiple={maxFiles > 1 ? true : false}
                      id="file"
                      type="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      onChange={(e) => handleOnChoose(e)}
                    />
                  </div>
                  <div className="dc-label">
                    {t("signature.totalFileSize") +
                      t("portalrh.documentManagement.maxFiles") +
                      " " +
                      maxFiles}
                  </div>
                  <small>{t("signature.PDFonly")}</small>

                  {documents.length > 0 && (
                    <div
                      style={{
                        maxWidth: "50vw",
                        width: "fit-content",
                        caretColor: "transparent",
                        display: "flex",
                        flexWrap: "wrap",
                        paddingTop: "10px",
                      }}
                    >
                      {documents.map((doc, i) => {
                        return (
                          <FilePreviewCard
                            key={i}
                            document={doc}
                            handleDeleteDocument={handleDeleteDocument}
                          />
                        );
                      })}
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Messages
            afterHideSuccess={clearStates}
            afterHideError={clearStates}
          />
        </div>
      </Col>
      <Col lg={true}>
        <Test documents={documents} clearDocuments={setDocuments} />
      </Col>
      <Toast />
    </div>
  );
};
export default withNamespaces()(FileUpload);
