import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import "../assets/scss/_verticalNav.scss";

const BackOfficeVerticalButtons = ({ disabled, navItems, handleClick, t }) => {
  const listOfIds = navItems.map((_i, i) => `backoffice-${i}`);
  const [active, setActive] = useState(listOfIds[0]);

  const offSet =
    document?.getElementById("easypay-header")?.offsetHeight +
    document?.getElementById("easypay-backoffice-subheader")?.offsetHeight;

  let current = "";

  const getActiveIds = (ids) =>
    ids.map((item) => document.getElementById(item));

  window.onscroll = () => {
    getActiveIds(listOfIds).forEach((section) => {
      const sectionTop = section?.offsetTop;
      if (window.pageYOffset + offSet + 200 >= sectionTop) {
        current = section.getAttribute("id");
      }
    });
    if (current !== active) {
      setActive(current);
    }
  };

  const ProtectedComponentWrapper = (props) => {
    const { children } = props;
    return (
      <ProtectedComponent
        permissions={[{ label: "app-onboarding-business-read" }]}
      >
        {children}
      </ProtectedComponent>
    );
  };

  const RenderItem = (props) => {
    const { item, index: i } = props;

    return (
      <div>
        <button
          id={`${item.name}`}
          key={i}
          className={`easypay-vertical-nav-item ${
            active === `backoffice-${i}` ? "easypay-active" : ""
          }`}
          onClick={() => {
            setActive(`backoffice-${i}`);
            handleClick(`backoffice-${i}`);
          }}
          disabled={disabled}
          type="button"
        >
          {t(item.text)}
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="easypay-vertical-nav vertical-nav easypay-backoffice-vertical">
        {navItems &&
          Object.keys(navItems).length !== 0 &&
          navItems.map((item, i) => {
            return item.name === "Business" || item.name === "Negócio" ? (
              <ProtectedComponentWrapper>
                <RenderItem item={item} index={i} />
              </ProtectedComponentWrapper>
            ) : (
              <RenderItem item={item} index={i} />
            );
          })}
      </div>
    </>
  );
};

export default withNamespaces()(BackOfficeVerticalButtons);
