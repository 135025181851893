import React from "react";
import { withNamespaces } from "react-i18next";
import iconEdit from "../assets/img/icons/icon-edit.png";
import iconLess from "../assets/img/icons/icon-less.png";
import "../assets/scss/_links.scss";

const Link = ({
  variant,
  text,
  disabled,
  link,
  disabledColored,
  onClick,
  prevPath,
  t,
}) => {

  return (
    <a
      className={`easypay-link-primary ${variant} ${
        disabledColored ? "easypay-link-disabledColored" : ""
      }`}
      disabled={disabled || disabledColored}
      href={link}
      onClick={onClick}
    >
      {variant === "easypay-remove" && (
        <img className="easypay-icon-less" src={iconLess} alt="Less Icon" />
      )}
      {variant === "easypay-edit" && (
        <img className="easypay-icon-less" src={iconEdit} alt="Edit Icon" />
      )}
      {t(text)}
    </a>
  );
};

export default withNamespaces()(Link);
