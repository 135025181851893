import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "../assets/scss/_backOfficeRepLegTable.scss";
import BackofficeAddBenefModal from "./BackofficeAddBenefModal";
import Button from "./Button";
import Table from "~/pages/Easypay/components/Table";

const BackOfficeBenefSection = ({ t, data , pid}) => {
  const [isAddModalShowing, setIsAddModalShowing] = useState(false);

  const addButtonField = {
    type: "link",
    text: "",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='submitMain';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function handleSetAddModal() {
    setIsAddModalShowing(true);
  }

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    const dateAux = new Date(cell);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  const idFormatterOnboarding = (cell) => {
    return cell;
  };

  const statusFormatter = () => {
    //return <img src={iconWarning} />;
  };

  const resultFormatter = (cell) => {
    return cell; // <Tag text={cell} variant="easypay-tag-label" type="easypay-approved" />
  };

  const calcDays = (cell) => {
    if (!cell) {
      return "";
    }
    const modifiedDate = new Date(cell);
    const currentDate = new Date();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = currentDate.getTime() - modifiedDate.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  const stageFormatter = (cell) => {
    return cell;
  };

  const capitalizeText = (cell) =>
    cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : "";

  const pepFormatter = (cell) => {
    return cell === 1 ? "PEP" : "-";
  };

  const columns = [
    {
      dataField: "nome",
      text: t(`bpm.onboarding.backoffice.user.labelName`),
      sort: true,
      style: {
        "white-space": "nowrap",
      },
    },
    {
      dataField: "pep",
      text: "PEP",
      sort: false,
      headerStyle: { width: "150px" },
      formatter: pepFormatter,
      style: {
        "font-weight": "bold",
      },
    },
    {
      dataField: "percentagem",
      text: t("bpm.onboarding.backoffice.user.labelDirectCapital"),
      sort: false,
    },
    {
      dataField: "nif",
      text: t("bpm.onboarding.backoffice.user.labelCivNumber"),
      sort: true,
    },
    {
      dataField: "morada",
      text: t("bpm.onboarding.backoffice.administration.headerAddress"),
      sort: false,
    },
  ];

  return (
    <div className="easypay-backoffice-sections">
      <BackofficeAddBenefModal
        title={"Adicionar"}
        isModalShowing={isAddModalShowing}
        setIsModalShowing={setIsAddModalShowing}
        user = {data}
        pid = {pid}
      />
      <div className="easypay-backoffice-registry-section-header">
        {t(`bpm.onboarding.backoffice.user.verticalTabBenef`)}
      </div>
      <Table
        sort={{ dataField: "client.pid", order: "desc" }}
        docs={data?.effectiveBeneficiary ? data?.effectiveBeneficiary : []}
        columns={columns}
        key="onboarding-table"
        hidePagination={true}
      />
      <Button
        text={t(`bpm.onboarding.backoffice.user.modalHeaderAdd`)}
        variant={"easypay-button-add"}
        onclick={handleSetAddModal}
        key={uuid()}
        id={addButtonField.id}
        className="card-button"
        align={"left"}
        disabledColored={false}
        disabled={false}
        field={addButtonField}
      />
    </div>
  );
};

export default withNamespaces()(BackOfficeBenefSection);
