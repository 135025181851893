export const RESET_IFLOW_DOCUMENTS_LINK_COMPONENT =
  "processes@RESET_IFLOW_DOCUMENTS_LINK_COMPONENT";

export const GET_IFLOW_DOCUMENT_LINK_COMPONENT =
  "processes@GET_IFLOW_DOCUMENT_LINK_COMPONENT";

export const SET_IFLOW_DOCUMENTS_LINK_COMPONENT =
  "processes@SET_IFLOW_DOCUMENTS_LINK_COMPONENT";

export const SET_SELECTED_TOSIGN_LINK_COMPONENT =
  "processes@SET_SELECTED_TOSIGN_LINK_COMPONENT";

export const UPDATE_SELECTED_TOSIGN_LINK_COMPONENT =
  "processes@UPDATE_SELECTED_TOSIGN_LINK_COMPONENT";

export const UPDATE_ISUSEEFFECT_RUN = "processes@UPDATE_ISUSEEFFECT_RUN";

export const getIflowDocumentLinkComponent = (payload) => ({
  type: GET_IFLOW_DOCUMENT_LINK_COMPONENT,
  data: payload,
});

export const resetIflowDocumentsLinkComponent = () => ({
  type: RESET_IFLOW_DOCUMENTS_LINK_COMPONENT,
});

export const setIflowDocumentsLinkComponent = (document, href, filename) => ({
  type: SET_IFLOW_DOCUMENTS_LINK_COMPONENT,
  document,
  href,
  filename,
});

export const setSelectedTosignLinkComponent = (data) => ({
  type: SET_SELECTED_TOSIGN_LINK_COMPONENT,
  data,
});

export const updateSelectedTosignLinkComponent = (data, checked) => ({
  type: UPDATE_SELECTED_TOSIGN_LINK_COMPONENT,
  data,
  checked,
});

export const updateIsuseeffectRun = () => ({
  type: UPDATE_ISUSEEFFECT_RUN,
});

export const FIND_USERSETTINGS_STAMP = "processes@FIND_USERSETTINGS_STAMP";
export const UPDATE_USERSETTINGS_STAMP = "processes@UPDATE_USERSETTINGS_STAMP";
export const SET_USERSETTINGS_STAMP = "processes@SET_USERSETTINGS_STAMP";

export const findUserSettingsStamp = (username) => ({
  type: FIND_USERSETTINGS_STAMP,
  username: username,
});
export const updateUserSettingsStamp = (username, stamp) => ({
  type: UPDATE_USERSETTINGS_STAMP,
  username,
  stamp,
});

export const setUserSettingsStamp = (userSettings) => ({
  type: SET_USERSETTINGS_STAMP,
  data: userSettings,
});

export const GET_SIGNATURE_COORDINATES = "processes@GET_SIGNATURE_COORDINATES";
export const SET_SIGNATURE_COORDINATES = "processes@SET_SIGNATURE_COORDINATES";

export const setSignatureCoordinates = (signatureCoordinates, docid) => ({
  type: SET_SIGNATURE_COORDINATES,
  data: signatureCoordinates,
  docid: docid,
});

export const getSignatureCoordinates = (payload) => ({
  type: GET_SIGNATURE_COORDINATES,
  data: payload,
});

export const UPDATE_SELECTED_TOSIGN_TABLEFILE_COMPONENT =
  "processes@UPDATE_SELECTED_TOSIGN_TABLEFILE_COMPONENT";

export const updateSelectedTosignTableFileComponent = (data, checked) => ({
  type: UPDATE_SELECTED_TOSIGN_TABLEFILE_COMPONENT,
  data,
  checked,
});

export const SET_SELECTED_TOSIGN_TABLEFILE_COMPONENT =
  "processes@SET_SELECTED_TOSIGN_TABLEFILE_COMPONENT";

export const setSelectedTosignTableFileComponent = (data) => ({
  type: SET_SELECTED_TOSIGN_TABLEFILE_COMPONENT,
  data,
});

export const SET_TABLEFILE_SIGNCLICKED = "processes@SET_TABLEFILE_SIGNCLICKED";

export const setTableFileSignClicked = (data) => ({
  type: SET_TABLEFILE_SIGNCLICKED,
  data,
});

export const GET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT =
  "processesSignature@GET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT";
export const getIflowDocumentTableFileComponent = (payload) => ({
  type: GET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT,
  data: payload,
});

export const SET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT =
  "processesSignature@SET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT";
export const setIflowDocumentTableFileComponent = (
  document,
  docid,
  filename
) => ({
  type: SET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT,
  document,
  docid,
  filename,
});

export const RESET_IFLOW_DOCUMENTS_TABLE_FILE_COMPONENT =
  "processesSignature@RESET_IFLOW_DOCUMENTS_TABLE_FILE_COMPONENT";
export const resetIflowDocumentTableFileComponent = () => ({
  type: RESET_IFLOW_DOCUMENTS_TABLE_FILE_COMPONENT,
});
