export const templates = [
  {
    label: "select template",
    items: [],
  },
  {
    label: "invoice template DEPRECATED",
    items: [
      {
        label: "Document class",
        value: "class",
      },
      {
        label: "Subclass",
        value: "subclass",
      },
      {
        label: "Receive date",
        value: "receive_date",
      },
      {
        label: "Vendor name",
        value: "vendor_name",
      },
      {
        label: "Vat amount",
        value: "vat_amount",
      },
      {
        label: "Data de vencimento",
        value: "data-de-vencimento",
      },
      {
        label: "Reference number",
        value: "reference_number",
      },
      {
        label: "Invoice number",
        value: "invoice_number",
      },
      {
        label: "Line items",
        value: "lineitems",
      },
      {
        label: "PO-number",
        value: "PO-number",
      },
      {
        label: "Shipment date",
        value: "shipment_date",
      },
      {
        label: "Vendor tax id",
        value: "vendor_tax_id",
      },
      {
        label: "Contract number",
        value: "contract_number",
      },
      {
        label: "Vendor phone",
        value: "vendor_phone",
      },
      {
        label: "Reception date",
        value: "reception_date",
      },
      {
        label: "Total amount",
        value: "total_amount",
      },
      {
        label: "Client tax id",
        value: "client_tax_id",
      },
      {
        label: "Client address",
        value: "client_address",
      },
      {
        label: "Currency",
        value: "currency",
      },
      {
        label: "Vendor address",
        value: "vendor_address",
      },
      {
        label: "Base amount",
        value: "base_amount",
      },
      {
        label: "Emission date",
        value: "emission_date",
      },
      {
        label: "Line items full",
        value: "lineitemsfull",
      },
      {
        label: "Due date",
        value: "due_date",
      },
      {
        label: "Client name",
        value: "client_name",
      },
      {
        label: "Line items",
        value: "line_items",
        children: [
          {
            label: "Amount Base",
            value: "item_base_amount",
          },
          {
            label: "Order Number",
            value: "item_order_number",
          },
          {
            label: "Item Rate",
            value: "item_rate",
          },
          {
            label: "Amount",
            value: "item_amount",
          },
          {
            label: "Item Code/Id",
            value: "item_code",
          },
          {
            label: "Item Quantity",
            value: "item_quantity",
          },
          {
            label: "Amount Total",
            value: "item_amount_total",
          },
          {
            label: "Item Description",
            value: "item_description",
          },
          {
            label: "Amount Total Base",
            value: "item_base_total_amount_base",
          },
          {
            label: "Item Unit of Measure",
            value: "item_unit",
          },
          {
            label: "Item Tax",
            value: "item_tax",
          },
        ],
      },
      {
        label: "Total liquido isento",
        value: "total-liquido-isento",
      },
      {
        label: "Delivery attn",
        value: "invoice:delivery_attn",
      },
      {
        label: "Delivery attn",
        value: "delivery_attn",
      },
      {
        label: "Tax lines",
        value: "tax_lines",
        children: [
          {
            label: "Tax lines: tax amount",
            value: "tax_amount",
          },
          {
            label: "Tax lines: tax base amount",
            value: "tax_base_amount",
          },
          {
            label: "Tax lines: taxrate",
            value: "tax_rate",
          },
        ],
      },
      {
        label: "Invoice terms",
        value: "invoice_terms",
      },
      {
        label: "Client phone",
        value: "client_phone",
      },
    ],
  },
  {
    label: "financial-document template",
    items: [
      {
        label: "Document class",
        value: "class",
      },
      {
        label: "Subclass",
        value: "subclass",
      },
      {
        label: "Date Due",
        value: "due_date",
      },
      {
        label: "Issue Date",
        value: "issue_date",
      },
      {
        label: "Document Identifier",
        value: "document_identifier",
      },
      {
        label: "Order Number",
        value: "order_number",
      },
      {
        label: "Recipient Tax Number",
        value: "recipient_tax_number",
      },
      {
        label: "Recipient Name",
        value: "recipient_name",
      },
      {
        label: "Supplier Tax Number",
        value: "supplier_tax_number",
      },
      {
        label: "Supplier Name",
        value: "supplier_name",
      },
      {
        label: "Document type",
        value: "document_type",
      },
      {
        label: "Currency",
        value: "currency",
      },
      {
        label: "Tax Total",
        value: "amount_tax_total",
      },
      {
        label: "Tax Base Total",
        value: "amout_base_total",
      },
      {
        label: "Amount Paid",
        value: "amount_paid",
      },
      {
        label: "Total Amount",
        value: "amount_total",
      },
      {
        label: "Amount Rounding",
        value: "amount_rounding",
      },
      {
        label: "Amount Due",
        value: "amount_due",
      },
      {
        label: "Line items",
        value: "line_items",
        children: [
          {
            label: "Order Number",
            value: "item_order_number",
          },
          {
            label: "Amount Total",
            value: "item_amount_total",
          },
          {
            label: "Amount Total Base",
            value: "item_base_total_amount_base",
          },
          {
            label: "Amount",
            value: "item_amount",
          },
          {
            label: "Amount Base",
            value: "item_base_amount",
          },
          {
            label: "Item Tax",
            value: "item_tax",
          },
          {
            label: "Item Rate",
            value: "item_rate",
          },
          {
            label: "Item Unit of Measure",
            value: "item_unit",
          },
          {
            label: "Item Quantity",
            value: "item_quantity",
          },
          {
            label: "Item Description",
            value: "item_description",
          },
          {
            label: "Item Code/Id",
            value: "item_code",
          },
        ],
      },
      {
        label: "Tax Breakdown",
        value: "tax_breakdown",
        children: [
          {
            label: "Tax Code",
            value: "tax_code",
          },
          {
            label: "Tax Total",
            value: "tax_total",
          },
          {
            label: "Tax Amount",
            value: "tax_amount",
          },
          {
            label: "Tax Rate",
            value: "tax_rate",
          },
          {
            label: "Tax Base",
            value: "tax_base",
          },
        ],
      },
    ],
  },
];
