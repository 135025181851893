import React from 'react';
import { useSelector } from 'react-redux';

const CustomTotal = (from, to, size) => {

const {userSettings} = useSelector(state => state.userSettingsReducer);
const getUserLanguage = () => {
  return userSettings.language ? userSettings.language : 'en';
}

const translateLine=() =>{
  const language= getUserLanguage();
  if (language==="es"){
    return  `${from} a ${to} de ${size} registros`
  }else if (language==="pt"){
    return `${from} a ${to} de ${size} registos`
  }else 
    return `${from} to ${to} of ${size} records`
}
  return (
    <span className="react-bootstrap-table-pagination-total">
      {translateLine()}
    </span>
  );
}
export default CustomTotal