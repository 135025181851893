import { GrActions } from "react-icons/gr";
import {
  SET_CHECK,
  GET_CHECK,
  TOGGLE_CHECK,
  SET_DOCUMENTS,
  GET_DOCUMENTS,
  CLEAR_DOCUMENTS,
  CLEAR_DOCUMENT,
  SET_DOCUMENT,
  SUCCESS_DELETE,
  DELETE_DOCUMENT,
  REDIRECT_USER_TO,
  SET_OPERATIONS,
  GET_OPERATIONS,
  SET_PREVIEW,
  GET_PREVIEW,
  SET_COLLABORATORS,
  GET_COLLABORATORS,
  SET_SCHEDULER,
  GET_SCHEDULER,
  SET_DOCUMENTS_ADMIN,
  GET_DOCUMENTS_ADMIN,
  CLEAR_CHECK,
  FIND_DOCUMENTS_BY_FILTERS,
  GET_REQUESTS_BY_USERNAME,
  SET_REQUESTS_BY_USERNAME,
  SET_GENERIC_REQUEST_STATE,
  SET_GENERIC_AUTHORIZATION_STATE,
  SET_USER_PAYDATA,
  GET_USER_PAYDATA,
  SET_USER_INFORMATIONS,
  GET_USER_INFORMATIONS,
  GET_USER_SYNCHRONIZATION_LOG,
  SET_USER_SYNCHRONIZATION_LOG,
  GET_OBJECTIVES,
  SET_OBJECTIVES,
  GET_OBJECTIVE_BY_ID,
  SET_OBJECTIVE_BY_ID,
  GET_EVALUATION_SYSTEMS,
  SET_EVALUATION_SYSTEMS,
  GET_EVALUATION_PERIODS,
  SET_EVALUATION_PERIODS,
  UPDATE_OPERATION_STATES,
  GET_DOCS_DISCLAIMERS,
  SET_DOCS_DISCLAIMERS,
  UPDATE_DOCS_DISCLAIMER,
  STOP_EDITING_DOCS_DISCLAIMERS,
  UPLOAD_SCHEDULE,
  STOP_UPLOAD_SCHEDULE,
  UPLOAD_ATTENDANCE,
  STOP_UPLOAD_ATTENDANCE,
  GET_ENTITIES,
  SET_ENTITIES,
  GET_LOGS,
  SET_LOGS,
  GET_ENTITIES_ATTENDANCE,
  SET_ENTITIES_ATTENDANCE,
  GET_ATTENDANCE_LOGS,
  SET_ATTENDANCE_LOGS,
  GET_EXPORT_LOGS,
  SET_EXPORT_LOGS,
  GET_EXPORT_ABSENCE_LOGS,
  SET_EXPORT_ABSENCE_LOGS,
  GET_EXPORT_ABSENCE_DATA,
  SET_EXPORT_ABSENCE_DATA,
  EXPORT_TXT,
  GET_GROUP_USERS,
  SET_GROUP_USERS,
  GET_REGISTO_FALTAS,
  SET_REGISTO_FALTAS,
  SET_SUCCESS_ADD,
  GET_TIPO_AUSENCIA,
  SET_TIPO_AUSENCIA,
  GET_TIPO_AUSENCIA_IDONIC,
  SET_TIPO_AUSENCIA_IDONIC,

} from "./actionTypes";

const initialState = {
  isCheckedIn: false,
  isLoadingCheck: false,
  reachedMaximumChecks: true,
  pathToRedirect: "",

  documents: [],
  isLoadingDocuments: false,
  document: null,

  operations: [],
  isLoadingOperations: false,

  preview: null,
  isLoadingPreview: false,

  collaborators: [],
  isLoadingCollaborators: false,

  scheduler:{},
  isLoadingScheduler:false,

  requests: [],
  isLoadingRequests: false,

  genericRequestState: "Pendente",
  genericAuthorizationState: 1,

  userPayData: [],
  isLoadingUserPayData: false,

  userInformations: null,
  isLoadingUserInformations: false,
  userSynchronizationLog: [],
  isLoadingUserSynchronizationLog: false,

  objectives: [],
  isLoadingObjectives: false,

  objective: null,
  isLoadingObjective: false,

  evaluationSystems: [],
  isLoadingEvaluationSystems: false,

  evaluationPeriods: [],
  isLoadingEvaluationPeriods: false,

  docsDisclaimers: [],
  isLoadingDocsDisclaimers: false,
  editingDocsDisclaimers: false,

  uploadingSchedule: false,

  uploadingAttendance: false,

  entities: [],
  isLoadingEntities: false,

  entitiesAttendance: [],
  isLoadingEntitiesAttendance: false,

  logs: [],
  isLoadingLogs: false,

  attendanceLogs: [],
  isLoadingAttendanceLogs: false,

  stringBase64: [],

  absenceLogs: [],
  isLodaingGetExportAbsenceLogs: false,
  absenceData: [],
  isLoadingGetExportAbsenceData: false,
  groupUsers: [],
  isLoadingGetGroupUsers: false,
  registoFaltas: [],
  successAdded:false,
  isLoadingGetRegistoFaltas: false,
  tipoAusencias: [],
  isLoadingGetTipoAusencias: false,
  tipoAusenciasIDONIC: [],
  isLoadingGetTipoAusenciaIDONIC: false,

};

export function portalrhReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHECK:
      let checked = false;
      let maxChecks = false;
      if (action.data === "out") {
        checked = true;
      } else if (action.data === "in") {
        checked = false;
      } else if (action.data === "none") {
        maxChecks = true;
      }
      return {
        ...state,
        isCheckedIn: checked,
        isLoadingCheck: false,
        reachedMaximumChecks: maxChecks,
      };

    case CLEAR_CHECK:
      return { ...state, isLoadingCheck: false };
    case GET_CHECK:
      return { ...state, isLoadingCheck: true };
    case TOGGLE_CHECK:
      return { ...state, isLoadingCheck: true };
    case REDIRECT_USER_TO:
      return { ...state, pathToRedirect: action.path };

    case GET_DOCUMENTS:
      return { ...state, isLoadingDocuments: true };

    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
        isLoadingDocuments: false,
      };

    case SET_DOCUMENT:
      return { ...state, document: action.document };

    case CLEAR_DOCUMENT:
      return { ...state, document: null };

    case CLEAR_DOCUMENTS:
      return { ...state, documents: [] };

    case DELETE_DOCUMENT:
      return { ...state, isDeletingDocument: true };

    case SUCCESS_DELETE:
      return { ...state, isDeletingDocument: false };

    case GET_OPERATIONS:
      return { ...state, isLoadingOperations: true };

    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.operations,
        isLoadingOperations: false,
      };

    case GET_PREVIEW:
      return { ...state, isLoadingPreview: true };

    case SET_PREVIEW:
      return { ...state, preview: action.preview, isLoadingPreview: false };

    case GET_COLLABORATORS:
      return { ...state, isLoadingCollaborators: true };

      case GET_SCHEDULER:
        return { ...state, isLoadingScheduler: true };

    case SET_COLLABORATORS:
      return {
        ...state,
        collaborators: action.users,
        isLoadingCollaborators: false,
      };

      case SET_SCHEDULER:
        return {
          ...state,
          scheduler: action.scheduler,
          isLoadingScheduler: false,
        };

    case GET_DOCUMENTS_ADMIN:
      return { ...state, isLoadingDocuments: true };

    case SET_DOCUMENTS_ADMIN:
      return {
        ...state,
        documents: action.documents,
        isLoadingDocuments: false,
      };

    case FIND_DOCUMENTS_BY_FILTERS:
      return { ...state, isLoadingDocuments: true };

    case GET_REQUESTS_BY_USERNAME:
      return { ...state, isLoadingRequests: true };

    case SET_REQUESTS_BY_USERNAME:
      return { ...state, requests: action.requests, isLoadingRequests: false };

    case SET_GENERIC_REQUEST_STATE:
      return { ...state, genericRequestState: action.genericRequestState };

    case SET_GENERIC_AUTHORIZATION_STATE:
      return {
        ...state,
        genericAuthorizationState: action.genericAuthorizationState,
      };

    case GET_USER_PAYDATA:
      return { ...state, isLoadingUserPayData: true };

    case SET_USER_PAYDATA:
      return {
        ...state,
        userPayData: action.userPayData,
        isLoadingUserPayData: false,
      };

    case GET_USER_INFORMATIONS:
      return { ...state, isLoadingUserInformations: true };

    case SET_USER_INFORMATIONS:
      return {
        ...state,
        userInformations: action.userInformations,
        isLoadingUserInformations: false,
      };

    case GET_USER_SYNCHRONIZATION_LOG:
      return { ...state, isLoadingUserSynchronizationLog: true };

    case SET_USER_SYNCHRONIZATION_LOG:
      return {
        ...state,
        userSynchronizationLog: action.userSynchronizationLog,
        isLoadingUserSynchronizationLog: false,
      };

    case GET_OBJECTIVES:
      return { ...state, isLoadingObjectives: true };

    case SET_OBJECTIVES:
      return {
        ...state,
        objectives: action.objectives,
        isLoadingObjectives: false,
      };

    case GET_OBJECTIVE_BY_ID:
      return { ...state, isLoadingObjective: true };

    case SET_OBJECTIVE_BY_ID:
      return {
        ...state,
        objective: action.objective,
        isLoadingObjective: false,
      };

    case GET_EVALUATION_SYSTEMS:
      return { ...state, isLoadingEvaluationSystems: true };

    case SET_EVALUATION_SYSTEMS:
      return {
        ...state,
        evaluationSystems: action.evaluationSystems,
        isLoadingEvaluationSystems: false,
      };

    case GET_EVALUATION_PERIODS:
      return { ...state, isLoadingEvaluationPeriods: true };

    case SET_EVALUATION_PERIODS:
      return {
        ...state,
        evaluationPeriods: action.evaluationPeriods,
        isLoadingEvaluationPeriods: false,
      };

    case UPDATE_OPERATION_STATES:
      return { ...state };

    case GET_DOCS_DISCLAIMERS:
      return { ...state, isLoadingDocsDisclaimers: true };

    case SET_DOCS_DISCLAIMERS:
      return {
        ...state,
        docsDisclaimers: action.docsDisclaimers,
        isLoadingDocsDisclaimers: false,
      };

    case UPDATE_DOCS_DISCLAIMER:
      return { ...state, editingDocsDisclaimers: true };

    case STOP_EDITING_DOCS_DISCLAIMERS:
      return { ...state, editingDocsDisclaimers: false };

    case UPLOAD_SCHEDULE:
      return { ...state, uploadingSchedule: true };

    case STOP_UPLOAD_SCHEDULE:
      return { ...state, uploadingSchedule: false };

    case UPLOAD_ATTENDANCE:
      return { ...state, uploadingAttendance: true };

    case STOP_UPLOAD_ATTENDANCE:
      return { ...state, uploadingAttendance: false };

    case GET_ENTITIES:
      return { ...state, isLoadingEntities: true };

    case SET_ENTITIES:
      return { ...state, entities: action.entities, isLoadingEntities: false };

    case GET_ENTITIES_ATTENDANCE:
      return { ...state, isLoadingEntitiesAttendance: true };

    case SET_ENTITIES_ATTENDANCE:
      return {
        ...state,
        entitiesAttendance: action.entities,
        isLoadingEntitiesAttendance: false,
      };

    case GET_LOGS:
      return { ...state, isLoadingLogs: true };

    case SET_LOGS:
      return { ...state, logs: action.logs, isLoadingLogs: false };

    case GET_ATTENDANCE_LOGS:
      return { ...state, isLoadingAttendanceLogs: true };

    case SET_ATTENDANCE_LOGS:
      return {
        ...state,
        attendanceLogs: action.logs,
        isLoadingAttendanceLogs: false,
      };
    case GET_EXPORT_LOGS:
      return { ...state };
    case SET_EXPORT_LOGS:
      return {
        ...state,
        stringBase64: action.base64,
      };
    case GET_EXPORT_ABSENCE_LOGS:
      return { ...state, isLodaingGetExportAbsenceLogs: true };

    case SET_EXPORT_ABSENCE_LOGS:
      return {
        ...state,
        absenceLogs: action.absenceLogs,
        isLodaingGetExportAbsenceLogs: false,
      };

    case GET_EXPORT_ABSENCE_DATA:
      return { ...state, isLoadingGetExportAbsenceData: true };

    case SET_EXPORT_ABSENCE_DATA:
      return {
        ...state,
        absenceData: action.absenceData,
        isLoadingGetExportAbsenceData: false,
      };
    case EXPORT_TXT:
      return { ...state };

    case GET_GROUP_USERS:
      return { ...state, isLoadingGetGroupUsers: true };
    case SET_GROUP_USERS:
      return {
        ...state,
        groupUsers: action.groupUsers,
        isLoadingGetGroupUsers: false,
      };
    case GET_REGISTO_FALTAS:
      return { ...state, isLoadingGetRegistoFaltas: true };

    case SET_REGISTO_FALTAS:
      return {
        ...state,
        registoFaltas: action.registoFaltas,
        isLoadingGetRegistoFaltas: false,
      };
      case SET_SUCCESS_ADD:
        return {
          ...state,
          successAdded: action.boolean
        };

    case GET_TIPO_AUSENCIA:
      return { ...state, isLoadingGetTipoAusencias: true };

    case SET_TIPO_AUSENCIA:
      return {
        ...state,
        tipoAusencias: action.tipoAusencias,
        isLoadingGetTipoAusencias: false,
      };
    case GET_TIPO_AUSENCIA_IDONIC:
      return { ...state, isLoadingGetTipoAusencias: true };

    case SET_TIPO_AUSENCIA_IDONIC:
      return {
        ...state,
        tipoAusenciasIDONIC: action.tipoAusenciasIDONIC,
        isLoadingGetTipoAusenciaIDONIC: false,
      };

    default:
      return state;
  }
}
