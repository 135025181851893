import React, { useState } from "react";
import greenCheckmark from "../assets/img/icons/green-checkmark.png";
import iconDelete from "../assets/img/icons/icon-close.png";
import iconError from "../assets/img/icons/icon-error-filled.png";
import iconUser from "../assets/img/icons/icon-user.png";
import "../assets/scss/_tag.scss";

function Tag({ variant, text, type }) {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      {isActive && (
        <div className={`${variant} ` + type}>
          {variant === "easypay-tag-label" && (
            <img
              className="easypay-label-img"
              src={
                type.trim() === "easypay-approved"
                  ? greenCheckmark
                  : type === "easypay-denied"
                  ? iconError
                  : iconUser
              }
              height="24px"
              width="24px"
            />
          )}
          <section
            className={
              variant === "easypay-chip"
                ? "easypay-chip-text"
                : variant === "easypay-tag-label"
                ? `${"easypay-tag-label-text"} ` + type
                : variant === "easypay-onboarding-step"
                ? "easypay-onboarding-step-text"
                : `${"easypay-text-section"} ` + type
            }
          >
            {text}
          </section>
          {variant === "easypay-chip" && (
            <section
              className="easypay-chip-img-section"
              onClick={() => {
                setIsActive(false);
              }}
            >
              <img
                className="easypay-chip-img"
                src={iconDelete}
                height="14px"
                width="14px"
              />
            </section>
          )}
        </div>
      )}
    </>
  );
}

export default Tag;
