import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import FileUpload from "./FileUpload";
import { clearSavedBatch } from '../actions';

const Document = ({ docType, batchId, menuId }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearSavedBatch());
    }
  }, [dispatch]);

  return (
    <FileUpload docType={docType} batchId={batchId} menuId={menuId} />
  )
}

export default Document;
