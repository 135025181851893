import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import RhTableList from "./RhTableList";
import Dropdown from "~/components/Dropdown";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaymentsData,
  getPaymentsDropdown,
  updatePaymentsData,
  createPaymentsData,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import ButtonsUpdate from "./ButtonsUpdate";
import UploadDocument from "../UploadDocuments";
import ButtonsCreate from "./ButtonsCreate";

const RhPaymentsData = ({ rowCollaborator, t }) => {
  const [formOfPaymentList, setFormOfPaymentList] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState("");
  const [iban, setIban] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [paymentsDataPage, setPaymentsDataPage] = useState(false);
  const [pagCode, setPageCode] = useState("");
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);
  const [newButtonClicked, setNewButtonClicked] = useState(false);

  const dispatch = useDispatch();

  const { paymentsData, paymentsDropdown, isLoadingUpdatePaymentsData } =
    useSelector((state) => state.personalReducer);

  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getPaymentsDropdown());
      dispatch(
        getPaymentsData(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
      setPaymentsDataPage(true);
    }
  }, [rowCollaborator, isLoadingUpdatePaymentsData]);

  const handleFormOfPayment = (e) => {
    setPaymentSelected(e);
  };

  const handleIban = (e) => {
    setIban(e.target.value);
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (paymentsDropdown && Object.keys(paymentsDropdown).length > 0) {
        paymentsDropdown.formOfPayment.push({ label: "...", value: "0" });
        setFormOfPaymentList(paymentsDropdown.formOfPayment);
      }
      if (data?.length > 0) {
        const checkEmptyData = (data, setState) => {
          if (data === null || data === undefined) {
            data = "0";
            setState(data);
          } else {
            setState(data);
          }
        };

        checkEmptyData(data[0].formOfPayment, setPaymentSelected);

        setIban(data[0].iban);
        setPageCode(data[0].pagCode);
      } else {
        setPaymentSelected("");
        setIban("");
        setPageCode("");
      }
    };

    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(paymentsData);
    }
  }, [paymentsData, paymentsDropdown, rowClicked]);

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  useEffect(() => {
    if (paymentSelected) {
      if (paymentSelected === "2") {
        setPageCode("0001");
      } else if (paymentSelected === "3") {
        setPageCode("0002");
      } else if (paymentSelected === "1") {
        setPageCode("0003");
      }
    }
  }, [paymentSelected]);

  const columns = [
    {
      dataField: "formOfPaymentDesing",
      text: t(`portalrh.paymentsData.FormofPayment`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "iban",
      text: t(`portalrh.paymentsData.Iban`),
      sort: true,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  const houseHoldObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    pagCode: pagCode,
    iban: iban,
  };

  const updateData = (bollean) => {
    setPaymentsDataPage(bollean);
    dispatch(updatePaymentsData(houseHoldObject));
  };

  const createNewData = (bollean) => {
    setPaymentsDataPage(bollean);
    dispatch(createPaymentsData(houseHoldObject));
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };

  const handleNewButtonClicked = (bollean) => {
    if (!bollean) {
      // cleanInputs();
      setNewButtonClicked(true);
    }
    if (bollean) {
      setNewButtonClicked(false);
    }
  };

  return (
    <div className="collaboratoRH__view">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="buttons__rhAdmin">
          {!updateButtonClicked ? (
            <ButtonsCreate
              createNewData={createNewData}
              OnTakingBolean={OnTakingBolean}
              handleNewButtonClicked={handleNewButtonClicked}
              paymentsDataPage={paymentsDataPage}
            />
          ) : null}
          {!newButtonClicked ? (
            <ButtonsUpdate
              OnTakingBolean={OnTakingBolean}
              updateData={updateData}
              paymentsDataPage={paymentsDataPage}
              handleUpdateButtonClicked={handleUpdateButtonClicked}
              isClicked={isClickedBtnUpdate}
              setIsClicked={setIsClickedBtnUpdate}
            />
          ) : null}
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.paymentsData.FormofPayment`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={formOfPaymentList}
              handleSubmit={handleFormOfPayment}
              defaultValue={paymentSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="6">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.paymentsData.FormofPayment`)}
            </Form.Label>
            <Form.Control
              value={iban == null ? "" : iban}
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              type="text"
              onChange={handleIban}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div>
          <UploadDocument
            isClicked={isClicked}
            uploadFile={paymentsData}
            rowCollaborator={rowCollaborator}
            id={11}
          />
        </div>
        <RhTableList
          tableId={"35"}
          columns={columns}
          data={paymentsData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhPaymentsData);
