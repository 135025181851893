import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import uuid from "uuid/v1";
import "~/pages/Easypay/assets/css/styles.css";
import iconGreenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import "~/pages/Easypay/assets/scss/_backOfficePreviewDocumentPageSection.scss";
import Breadcrumb from "~/pages/Easypay/components/Breadcrumb";
import Button from "~/pages/Easypay/components/Button";
import DocumentNavigation from "~/pages/Easypay/components/DocumentNavigation";
import Input from "./Input";
import Link from "~/pages/Easypay/components/Link";
import { useDispatch, useSelector } from "react-redux";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";

import {
  getAllUsers,
  getDocumentCoresystem,
  getUser,
  setDocumentCoresystem,
  getAllDocuments,
  updateUser
} from "~/store/ducks/onboarding/actionTypes";

const BackOfficePreviewDocumentPageSection = ({ location, t, id }) => {
  const dispatch = useDispatch();

  const {
    documentCoresystem,
    createDocumentCoresystemId,
    user,
    users,
    documents,
  } = useSelector((state) => state.onboardingReducer);

  const history = useHistory();
  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  useEffect(() => {
    if (
      documents === null ||
      documents === undefined ||
      documents?.length === 0
    ) {
      return;
    }
    var index = -1;
    var userId;
    if (documents && documents?.length > 0) {
      for (var i = 0; i < documents.length; i++) {
        if (documents[i]?.id === parseInt(id)) {
          userId = documents[i]?.idCliente;
          break;
        }
      }
    }
    if (users && users?.length > 0) {
      for (var i = 0; i < users.length; i++) {
        if (users[i].account?.id === parseInt(userId)) {
          index = i;
          break;
        }
      }
      console.log(users[index].account?.pid);
      setIdHeader(users[index].account?.pid);
      dispatch(getUser(users[index].account?.pid));
    }
  }, [users]);

  useEffect(() => {
    if (user) {
      var newArray = [];
      for (var i = 0; i < user?.documents?.length; i++) {
        if (
          user.documents[i].nomeDocumento != "" &&
          user.documents[i].nomeDocumento != undefined &&
          user.documents[i].nomeCompleto != ""
        ) {
          if (user.documents[i].id === parseInt(id)) {
            dispatch(
              getDocumentCoresystem(
                user.documents[i].idCoresystem,
                "61798db3a0ac1d6257017e87"
              )
            );

            setDocumentSelected({
              documentName: user.documents[i].nomeCompleto,
              holderName: user.documents[i].descDocumento,
              documentId: user.documents[i].id,
            });

            setDocNameHeader(user.documents[i].nomeCompleto);
          }
          newArray.push({
            documentName: user.documents[i].nomeCompleto,
            holderName: user.documents[i].descDocumento,
            documentId: user.documents[i].id,
          });
        }
      }

      setDocumentsList(newArray);
    }
  }, [user]);

  const [isModalSaveShowing, setIsModalSaveShowing] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [documents]);
  useEffect(() => {
    if (
      documents === null ||
      documents === undefined ||
      documents?.length === 0
    ) {
      dispatch(getAllDocuments());
    }
  }, []);

  function createBodySave() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Alterações guardadas" status="success" />
      </div>
    );
  }

  function changeFile(doc) {
    var index = -1;
    for (var i = 0; i < user?.documents?.length; i++) {
      if (user?.documents[i]?.id === doc.documentId) {
        index = i;
        break;
      }
    }
    dispatch(
      getDocumentCoresystem(
        user?.documents[index].idCoresystem,
        "61798db3a0ac1d6257017e87"
      )
    );
  }

  useEffect(() => {
    if (documentCoresystem != null && documentCoresystem != undefined) {
      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${documentCoresystem}`;
      a.download = "test.pdf";
      //a.click();
      setDocumentToDisplay(documentCoresystem);
      dispatch(setDocumentCoresystem(null));
    }
  }, [documentCoresystem]);

  const generatePdfView = () => {
    function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1];
      try {
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      } catch (e) {
        return new Blob(["No data found"], { type: "text/html" });
      }
    }
    //convert to blob because of Data protocol URL size limitations
    let blob = dataURLtoBlob(
      "data:application/pdf;base64, " + documentToDisplay
    );
    const temp_url = window.URL.createObjectURL(blob);
    return temp_url;
  };

  const [documentSelected, setDocumentSelected] = useState(null);
  const [documentToDisplay, setDocumentToDisplay] = useState(null);
  const [doneSave, setDoneSave] = useState(false);

  const [documentsList, setDocumentsList] = useState([]);
  const [idHeader, setIdHeader] = useState("");
  const [docNameHeader, setDocNameHeader] = useState("");

  var breadcrumbItems = [
    {
      text: "Onboarding",
      link: "uniksystem/onboarding/backoffic-organization/onboarding",
      prevPath: window.location.href,
    },
    {
      text: idHeader,
      link: `uniksystem/onboarding/backoffic-organization/onboarding/${idHeader}`,
      prevPath: window.location.href,
    },
    {
      text: docNameHeader,
      link: "http://localhost/login#/uniksystem/easypay",
      prevPath: window.location.href,
    },
  ];

  const handleSubmit = () => {
    user.account.modifiedDate = new Date();
    dispatch(updateUser({ user: user, pid: user?.account?.pid }));
    setDoneSave(true);
  };

  useEffect(() => {
    if (doneSave) {
      setDoneSave(false);
      setIsModalSaveShowing(true);
    }
  }, [user]);

  const updateUserInput = (value, variable) => {
    const variableName = variable?.split("-");
    if (variableName?.length === 2) {
      user[variableName[0]][variableName[1]] = value;
    }
  };

  function deleteCurrentFile()
  {
    if(documentSelected != null)
    {
      console.log(documentSelected)
      var index = -1
      for(var i = 0;i < user?.documents?.length;i++)
      {
        if(user?.documents[i].id === documentSelected.documentId)
        {
          index = i
          break
        }
      }
      console.log(user)
      user.documents[index].idCoresystem = "";
      user.documents[index].nomeDocumento = "";
      user.documents[index].content = "";
      user.documents[index].submited = "0";
      user.account.modifiedDate = new Date();
      console.log(user.documents[index])
      dispatch(updateUser({ user: user, pid: user?.account?.pid }));
      history.push({
        pathname: `/uniksystem/onboarding/backoffic-organization/documents`,
      });
    }
  }

  return (
    <div className="easypay-backoffice-document-preview-section">
      <Modal
        className="easypay-modal"
        showModal={isModalSaveShowing}
        setShowModal={setIsModalSaveShowing}
        headerContent={"Guardar alterações"}
        bodyContent={createBodySave()}
        key={uuid()}
      ></Modal>
      <div className="easypay-backoffice-document-preview-header">
        <div>
          <Breadcrumb breadcrumbStates={breadcrumbItems} />
        </div>
        <DocumentNavigation
          selected={documentSelected}
          setSelected={setDocumentSelected}
          documentsList={documentsList}
          onClick={changeFile}
        />
      </div>
      <div className="easypay-backoffice-document-preview-section-content-wrapper">
        <div className="easypay-backoffice-document-preview-section-left-content">
          <div className="easypay-backoffice-header">
            {t(`bpm.onboarding.backoffice.administration.headerAquiredData`)}
          </div>
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerName`),
              user?.client?.nome
            )}
            name="client-nome"
            onChange={updateUserInput}
          ></Input>
          <div className="easypay-backoffice-document-preview-input-row">
            <div className="easypay-backoffice-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.headerAddress`),
                  user?.client?.morada
                )}
                name="client-morada"
                onChange={updateUserInput}
              ></Input>
            </div>
            <div className="easypay-backoffice-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(
                    `bpm.onboarding.backoffice.administration.headerDateOfBirth`
                  ),
                  user?.client?.pvDataNascimento
                )}
                name="client-pvDataNascimento"
                onChange={updateUserInput}
              ></Input>
            </div>
          </div>
          <div className="easypay-backoffice-small-input-wrapper">
            <Input
              field={buildInputField(
                t(`bpm.onboarding.backoffice.administration.headerNIF`),
                user?.client?.nif
              )}
              name="client-nif"
              onChange={updateUserInput}
            ></Input>
          </div>
          <div className="easypay-backoffice-document-preview-input-row">
            <div className="easypay-backoffice-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.headerDocNum`),
                  user?.client?.pvNumDocumento
                )}
                name="client-pvNumDocumento"
                onChange={updateUserInput}
              ></Input>
            </div>
            <div className="easypay-backoffice-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.validityHeader`),
                  user?.client?.pvDataExpiracao
                )}
                name="client-pvDataExpiracao"
                onChange={updateUserInput}
              ></Input>
            </div>
          </div>
          <div className="easypay-backoffice-document-preview-buttons-wrapper">
            <div className = "easypay-link-primary" onClick = {() => deleteCurrentFile()}>{t(`bpm.onboarding.backoffice.user.labelRequestAgain`)}</div>
            <div className="easypay-backoffice-button-wrapper">
              <Button
                text={t(`bpm.onboarding.backoffice.user.buttonSave`)}
                variant={"easypay-button-primary"}
                onclick={() => handleSubmit()}
                key={uuid()}
                id={addButtonField.id}
                className="card-button"
                align={"right"}
                disabledColored={false}
                disabled={false}
                field={addButtonField}
              ></Button>
            </div>
          </div>
        </div>
        <iframe
          id="pdf-document"
          title="pdf-document"
          src={generatePdfView()}
          height={800}
          width={700}
        />
      </div>
    </div>
  );
};
export default withNamespaces()(BackOfficePreviewDocumentPageSection);
