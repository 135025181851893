import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Form } from "react-bootstrap";

import { getAllCompanies } from "~/store/ducks/budgetManagement/actionTypes";
import Modal from "react-bootstrap/Modal";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Dropdown from "~/components/Dropdown";

const ModalNewClient = ({
  isClicked,
  isClosed,
  takeNewClient,
  companiesList,
  paymentTypeList,
  clientTypeList,
}) => {
  const [isModalNewClientShowing, setIsModalNewClientShowing] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [dropdownInvalid, setDropdownInvalid] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  useEffect(() => {
    if (isClicked) {
      setIsModalNewClientShowing(true);
    }
  }, [isClicked]);

  const validateForm = () => {
    const {
      nome,
      morada,
      nif,
      orgId,
      prazoPagamento,
      paymentId,
      year,
      tipoCliente,
    } = form;

    const newErrors = {};
    if (!nome || nome === "" || !isNaN(nome)) {
      newErrors.nome = "Por favor preencha o nome";
    }
    if (!morada || morada === "" || !isNaN(morada)) {
      newErrors.morada = "Por favor preencha a morada.";
    }

    if (!nif || nif === "" || isNaN(nif)) {
      newErrors.nif = "Por favor preencha o NIF.";
    }

    if (!orgId || orgId === undefined || orgId === "") {
      newErrors.orgId = "Por favor seleccione o ID da Organização.";
    }

    if (!prazoPagamento || prazoPagamento === "" || isNaN(prazoPagamento)) {
      newErrors.prazoPagamento = "Por favor preencha o prazo de pagamento.";
    }

    if (!paymentId || paymentId === "" || isNaN(paymentId)) {
      newErrors.idTipoPagamento = "Por favor preencha a forma de pagamento.";
    }

    if (!year || year === "" || isNaN(year)) {
      newErrors.year = "Por favor preencha o ano.";
    }

    if (!tipoCliente || tipoCliente === "") {
      newErrors.tipoCliente = "Por favor preencha o tipo de cliente.";
    }

    return newErrors;
  };

  const handleClose = () => setIsModalNewClientShowing(false);

  useEffect(() => {
    isClosed(isModalNewClientShowing);
  }, [isModalNewClientShowing]);

  const handleSaveData = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setDropdownInvalid(true);
    } else {
      setDropdownInvalid(false);
      takeNewClient(form);
      handleClose();
      setForm("");
    }
  };

  const handleDropdownClassName = (varError, boolean) => {
    if (boolean) {
      return (
        "dropdown-margin " +
        (varError === "" || varError === undefined
          ? "dropdown-border-invalid"
          : "")
      );
    }
  };

  return (
    <>
      <Modal show={isModalNewClientShowing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="12" md="12">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  value={form.nome || ""}
                  onChange={(e) => setField("nome", e.target.value)}
                  type="text"
                  isInvalid={!!errors.nome}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome}
                </Form.Control.Feedback>
              </Col>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="12" md="12">
                <Form.Label>Morada</Form.Label>
                <Form.Control
                  value={form.morada || ""}
                  onChange={(e) => setField("morada", e.target.value)}
                  type="text"
                  isInvalid={!!errors.morada}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.morada}
                </Form.Control.Feedback>
              </Col>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="6" md="6">
                <Form.Label>Empresa</Form.Label>

                <Dropdown
                  list={companiesList}
                  handleSubmit={(e) => setField("orgId", e)}
                  className={handleDropdownClassName(
                    form.orgId,
                    dropdownInvalid
                  )}
                />

                <div
                  style={{
                    width: "100%",
                    fontSize: "80%",
                    color: "#dc3545",
                  }}
                >
                  {errors.orgId}
                </div>
              </Col>
              <Col xl="6" md="6">
                <Form.Label>Ano</Form.Label>
                <Form.Control
                  value={form.year || ""}
                  onChange={(e) => setField("year", e.target.value)}
                  type="text"
                  isInvalid={!!errors.year}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.year}
                </Form.Control.Feedback>
              </Col>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="6" md="6">
                <Form.Label>NIF</Form.Label>
                <Form.Control
                  value={form.nif || ""}
                  onChange={(e) => setField("nif", e.target.value)}
                  type="text"
                  isInvalid={!!errors.nif}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nif}
                </Form.Control.Feedback>
              </Col>
              <Col xl="6" md="6">
                <Form.Label>Tipo de cliente</Form.Label>
                <Dropdown
                  list={clientTypeList}
                  handleSubmit={(e) => setField("tipoCliente", e)}
                  className={handleDropdownClassName(
                    form.tipoCliente,
                    dropdownInvalid
                  )}
                />

                <div
                  style={{
                    width: "100%",
                    fontSize: "80%",
                    color: "#dc3545",
                  }}
                >
                  {errors.tipoCliente}
                </div>
              </Col>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Col xl="6" md="6">
                <Form.Label>Forma de Pagamento</Form.Label>
                <Dropdown
                  list={paymentTypeList}
                  handleSubmit={(e) => setField("paymentId", e)}
                  className={handleDropdownClassName(
                    form.paymentId,
                    dropdownInvalid
                  )}
                />

                <div
                  style={{
                    width: "100%",
                    fontSize: "80%",
                    color: "#dc3545",
                  }}
                >
                  {errors.paymentId}
                </div>
              </Col>

              <Col xl="6" md="6">
                <Form.Label>Prazo do Pagamento</Form.Label>
                <Form.Control
                  value={form.prazoPagamento || ""}
                  onChange={(e) => setField("prazoPagamento", e.target.value)}
                  type="text"
                  isInvalid={!!errors.prazoPagamento}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.prazoPagamento}
                </Form.Control.Feedback>
              </Col>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {/* <Col xl="6" md="6">
                <Form.Label>Nº Cliente</Form.Label>
                <Form.Control
                  value={form.numCliente || ""}
                  onChange={(e) => setField("numCliente", e.target.value)}
                  type="text"
                  isInvalid={!!errors.numCliente}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numCliente}
                </Form.Control.Feedback>
              </Col> */}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "15px",
          }}
        >
          <SimpleButton
            text={"Fechar"}
            variant={"liftworld-button-primary"}
            onClick={handleClose}
          />
          <SimpleButton
            text={"Guardar"}
            variant={"liftworld-button-primary"}
            onClick={handleSaveData}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalNewClient;
