import React, { useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import SimpleComponent from "../../../components/SimpleComponent";
import widgetplaceholder from "../../../assets/img/widgets/widget-placeholder.jpg";
import ConfigureServicoGenericoWidget from "./components/ConfigureServicoGenericoWidget";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { getBaseWidgets } from "../SystemSettings/actions";
import ProtectedPage from "~/components/security/ProtectedPage";

import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";

const WidgetSettings = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { baseWidgets } = useSelector((state) => state.adminConfigReducer);

  const configure = (widget) => {
    history.push(`/uniksystem/admin/widgetsettings/${widget.component}`);
  };

  useEffect(() => {
    dispatch(getBaseWidgets());
  }, [dispatch]);

  let widgetList =
    !isEmpty(baseWidgets) &&
    baseWidgets.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <img
              style={{
                borderRadius: "3%",
                width: "100px",
                /*height: "auto",*/
                maxWidth: "100%",
                maxHeight: "100%",
                float: "left",
              }}
              src={widgetplaceholder}
              alt="Widget"
            />
          </td>
          <td>
            <span className="font-weight-bold"> {item.component}</span>
            <p></p>
          </td>
          <td>
            {item.adminConfigNeeded ? (
              <button
                type="button"
                className="btn table-action-icon"
                onClick={() => configure(item)}
              >
                <i className="icon-edit"></i>
              </button>
            ) : null}
          </td>
        </tr>
      );
    });

  return (
    <ProtectedPage roles={[ROLE_ADMIN, ROLE_ORG_ADMIN]}>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-gear mr-2" />
              {t("WidgetSettings.title")}
            </h6>
          </Card.Header>
          <Switch>
            <Route path={`${path}/ServicoGenericoWidget`}>
              <ConfigureServicoGenericoWidget />
            </Route>
            <Route path={`${path}/otherwidget`}>
              <SimpleComponent />
            </Route>
            <Route path={`${path}`}>
              <Card.Body>
                <div className="custom-table">
                  <Table striped bordered hover>
                    <tbody>{widgetList}</tbody>
                  </Table>
                </div>
              </Card.Body>
            </Route>
          </Switch>
        </Card>
      </div>
    </ProtectedPage>
  );
};

export default withNamespaces()(WidgetSettings);
