import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import iconCalendarActive from "../../pages/Liftworld/assets/img/date-icon-active.png";
import iconCalendarDefault from "../../pages/Liftworld/assets/img/date-icon-default.png";
import iconCalendarDisabled from "../../pages/Liftworld/assets/img/date-icon-disabled.png";
import DateRange from "./DateRange";
import HourRange from "./hourRange";

import "./inputDate.scss";

/**
 *
 * @param rangeName check DateRange.js for range details
 */
const InputDate = ({
  id,
  placeholder,
  disabled,
  text,
  rangeName,
  handleChange,
  dates = [new Date(), new Date()],
  time = [
    ["", ""],
    ["", ""],
  ],
  isRange,
  hasHours,
  verticalText = false,
  className,
  name,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendar, setCalendar] = useState(dates);
  const calendarId = useRef(uuid());

  const onChangeHour = (hours) => {
    if (handleChange)
      handleChange({
        type: "hour",
        data:
          hours.type == "start" ? [hours.data, time[1]] : [time[0], hours.data],
      });
  };

  const clearHandler = () => {
    setCalendar([undefined, undefined]);
    setShowCalendar(false);
    if (handleChange) {
      handleChange({ type: "date", data: [undefined, undefined] });
      handleChange({ type: "hour", data: [undefined, undefined] });
    }
  };

  const onDateChange = () => {
    if (handleChange) handleChange({ type: "date", data: calendar });
    setShowCalendar(false);
  };

  return (
    <div
      className={
        "element-input-date-wrapper " +
        className +
        (verticalText ? " element-input-date-wrapper-vertical" : "")
      }
    >
      {text && <div className="element-input-date-label">{text}</div>}
      <Form.Group
        controlId={`formText-field`}
        style={{ width: "100%", marginBottom: "0px" }}
      >
        <div
          id={`input-date-${calendarId.current}`}
          disabled={disabled}
          className={`element-input-wrapper-date ${disabled ? "disable" : ""} ${
            showCalendar ? "active" : ""
          } `}
        >
          <input
            name={name}
            readOnly
            className="element-input-field-date left"
            placeholder={placeholder ? placeholder : ""}
            disabled={disabled}
            type="date"
            value={dates[0] ? dates[0].toISOString().split("T")[0] : ""}
          />
          {hasHours && (
            <HourRange
              time={time[0]}
              onChange={(v) => {
                onChangeHour({ type: "start", data: v });
              }}
            />
          )}
          {isRange && (
            <>
              <div className="element-date-separator-wrap">
                <div
                  disabled={disabled}
                  className="element-date-separator"
                ></div>
              </div>
              <input
                name={name}
                readOnly
                className="element-input-field-date"
                placeholder={placeholder ? placeholder : ""}
                disabled={disabled}
                type="date"
                value={dates[1] ? dates[1].toISOString().split("T")[0] : ""}
              />
              {hasHours && (
                <HourRange
                  time={time[1]}
                  onChange={(v) => {
                    onChangeHour({ type: "end", data: v });
                  }}
                />
              )}
            </>
          )}
          {!disabled && (
            <div
              className="element-input-calendar-icon-wrapper"
              onClick={() => !disabled && setShowCalendar(true)}
            >
              <img
                className="element-input-calendar-icon"
                src={
                  disabled
                    ? iconCalendarDisabled
                    : showCalendar
                    ? iconCalendarActive
                    : iconCalendarDefault
                }
                alt="Icon calendar"
              />
            </div>
          )}
          {showCalendar && (
            <DateRange
              isRange={isRange ? isRange : false}
              id={calendarId.current}
              dates={calendar}
              rangeName={rangeName}
              setDates={setCalendar}
              setShowCalendar={setShowCalendar}
              submit={onDateChange}
              clear={clearHandler}
            />
          )}
        </div>
      </Form.Group>
    </div>
  );
};

export default withNamespaces()(InputDate);
