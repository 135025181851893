import { put } from "redux-saga/effects";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import http from "~/utils/config/http";
import {
  setAusenciasByEmail,
  setFeriasByEmail,
  setLoginsByUsername,
  setAutorizacoesByUsername,
} from "./actionTypes";

export function* getAusenciasByEmail({ username }) {
  try {
    const resp = yield http.get(
      `/api/innux/ausencias-by-email/${username}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setAusenciasByEmail(resp.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getFeriasByEmail({ username }) {
  try {
    const resp = yield http.get(
      `/api/innux/ferias-by-email/${username}`,
      PORTALRH_MIDDLEWARE
    );

    yield put(setFeriasByEmail(resp.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getLoginsByUsername({ username, redirect, page, admin }) {
  try {
    const resp = yield http.get(
      `/api/innux/login-innux?username=${username}&redirect=${redirect}&page=${page}&admin=${admin}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setLoginsByUsername(resp.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getAutorizacoesByUsername({ username }) {
  try {
    const resp = yield http.get(
      `/api/innux/get-autorizacoes-by-username?username=${username}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setAutorizacoesByUsername(resp.data));
  } catch (error) {
    console.log(error);
  }
}
