import React from "react";
import oauth from "~/utils/oauth";

const CreateFileAsLink = (props) => {
  const { field } = props;
  return (
    <div>
      {field?.file && field?.file?.link_text && field?.file?.link_url && (
        <a
          download={field?.file?.link_text || "sample.pdf"}
          href={
            field?.file?.link_url + "&Authorization=" + oauth.getAccessToken()
          }
        >
          {field?.file?.link_text || "file"}
        </a>
      )}
    </div>
  );
};
export default CreateFileAsLink;
