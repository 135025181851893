import React from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { easyPaySSO } from "~/store/ducks/udw/actionTypes";

const EasyPaySSO = ({ t })  => {
  const dispatch = useDispatch();
  let payload;
  try{
    let easyPayCksLOCALE;
      easyPayCksLOCALE = document.cookie
      .split('; ')
      .find(row => row.startsWith('locale='))
      .split('=')[1];
      
      console.log(easyPayCksLOCALE)
      //TEST COOKIE
      //let easyPayCksID = 1;
      //let easyPayCksLOCALE = 'US';

      payload = {
         "locale":easyPayCksLOCALE
      }
      dispatch(easyPaySSO(payload));
  } catch(error){
    console.log("Cookies not present")
  }
    return ("Cookies confirmed");
};


export default withNamespaces()(EasyPaySSO);