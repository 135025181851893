import React from "react";
import { useSelector } from "react-redux";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import WidgetContainer from "~/components/WidgetContainer";
import ContentContainer from "~/containers/ContentContainer";
import { withNamespaces } from "react-i18next";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";

const Dashboard = (props) => {
  let { path } = useRouteMatch();
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const dashboardPages = Number.isInteger(configuration?.dashboard) && configuration?.dashboard != 0
    ? configuration.dashboard
    : 10;

  return (
    <>
      <ApplicationMenu showMenu={true} />
      <Switch>
        <Route exact path={`${path}`}>
          <div></div>
        </Route>
        {Array.from({ length: dashboardPages }).map((_, index) => (
          <Route
            key={index}
            exact
            path={`${path}/page/${index + 1}`}
          >
            <ContentContainer>
              <WidgetContainer />
            </ContentContainer>
          </Route>
        ))}
      </Switch>
    </>
  );
};

export default withNamespaces()(Dashboard);
