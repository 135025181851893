import { takeLatest } from "redux-saga/effects";

import {
  FIND_FOLDERS,
  SAVE_FOLDER,
  UPDATE_FOLDERS,
  DELETE_FOLDER,
  EDIT_ACTIVITY_FOLDER,
} from "../ducks/taskFolders/actionTypes";

import {
  findFolders,
  saveFolder,
  updateFolders,
  deleteFolder,
  editActivityFolder,
} from "../ducks/taskFolders/sagas";

export const taskFoldersSagas = [
  takeLatest(FIND_FOLDERS, findFolders),
  takeLatest(SAVE_FOLDER, saveFolder),
  takeLatest(UPDATE_FOLDERS, updateFolders),
  takeLatest(DELETE_FOLDER, deleteFolder),
  takeLatest(EDIT_ACTIVITY_FOLDER, editActivityFolder),
];
