import React, { useEffect, useState } from "react";
import { Card, Jumbotron, Col, Row } from "react-bootstrap";
import "~/assets/css/icons.css";
import "../AutomatedDataCapture.css";
import { withNamespaces } from "react-i18next";
import {
  toLocaleDateString,
  buildDocNumberColumn,
  buildVendorNameColum,
  buildTotalAmoutColumn,
} from "~/utils";

const MobileCardNonFinDataCapture = ({
  row,
  cell,
  rowIndex,
  formatExtraData,
  t,
  buildStatusColumn,
  buildApprovedColumn,
  buildActionsColumn,
  buildDocTypeColumn,
}) => {
  return (
    <>
      <Card
        bsPrefix="card-mini"
        key={rowIndex}
        style={{
          margin: "0 0 15px 0",
          textAlign: "center",
          borderRadius: "25px",
        }}
      >
        <Card.Body>
          <div className="card_container">
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.numero_documento")}:</h6>
              </div>
              <Card.Text>
                {row && row?.metadata?.numero_documento
                  ? row?.metadata?.numero_documento
                  : null}
              </Card.Text>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.reclamacao_data")}:</h6>
              </div>
              <Card.Text>
                {row && row?.metadata?.data
                  ? new Date(row.metadata.data)
                      .toLocaleString()
                      .slice(0, 10)
                      .replace(/,/g, "")
                  : null}
              </Card.Text>
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.nome_fornecedor")}:</h6>
              </div>
              {/* <Card.Text>
                {" "}
                {row && row.metadata.1_nome
                  ? row.metadata.1_nome
                  : null}
              </Card.Text> */}
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.nome_localidade")}: </h6>
              </div>
              {/* <Card.Text>
                {row && row?.metadata?.1_localidade
                  ? row?.metadata.1_localidade
                  : null}
              </Card.Text> */}
            </div>
            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.nome_reclamante")}: </h6>
              </div>
              {/* <Card.Text>
                {row && row?.metadata?.2_nome
                  ? row?.metadata?.2_nome
                  : null}
              </Card.Text> */}
            </div>

            <div className="card_group">
              <div className="card_label">
                <h6>{t("datacapture.general.telemovel_reclamante")}:</h6>
              </div>
              {/* <Card.Text>
                {row && row?.metadata?.2_tel
                  ? row?.metadata?.2_tel
                  : null}
              </Card.Text> */}
            </div>
            <div className="card_group2">
              <div className="card_label">
                <h6>{t("datacapture.general.status")}:</h6>
              </div>
              <div>
                <Card.Text>
                  {row && row?.statusV1
                    ? buildStatusColumn(cell, row)
                    : buildStatusColumn(cell, row)}
                </Card.Text>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default withNamespaces()(MobileCardNonFinDataCapture);
