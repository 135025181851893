import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import windows from "~/assets/img/icons/windows.png";

const WindowsAuthentication = (props)  => {

  const { click, auto } = props; 

  useEffect(() => {
    if(auto) {
      click();
    }
  }, [auto])

  return (
    <button className="btn-windows" onClick={() => click()}>
      <div style={{ display: "flex" }}>
      <div>
        <img className="logo-windows" alt="windows" src={windows}/>
      </div>
        <div style={{flexGrow: "1", justifyContent: "center", alignItems: "center"}}>
          Windows
        </div>
      </div>
    </button>
);
};


export default withNamespaces()(WindowsAuthentication);