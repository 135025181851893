import React, { useState, useEffect } from "react";

export const useLabelWidth = (field) => {
  const [labelWidthClass, setLabelWidthClass] = useState("");
  const [labelWidth, setLabelWidth] = useState("");
  const [labelAlign, setLabelAlign] = useState("");
  const [labelFlexGrow, setLabelFlexGrow] = useState("");

  useEffect(() => {
    if (typeof field?.text === "string") {
      if (field?.text?.includes("::labelWidth")) {
        let myStr = field?.text?.split("::labelWidth")[1];

        let width = myStr?.substring(
          myStr?.indexOf("=") + 1, 
          myStr?.lastIndexOf("p")
        );
        if (width?.match(/^[0-9]+$/)) {
          setLabelWidthClass("iflow-form-label-fix-cma");
          setLabelWidth(`${width}px`);
        }
      }
      if (field?.text?.includes("::labelNB")) {
        let myStr = field?.text?.split("::labelWidth")[1];

          setLabelWidthClass("iflow-form-label-fix-nb");
          setLabelWidth(`235px`);
          setLabelFlexGrow("0")
          setLabelAlign('center')
      }
    }
    if (typeof field?.text === "object") {
      Object.keys(field?.text).forEach((key) => {
        if (field?.text[key].includes("::labelWidth")) {
          let myStr = field?.text[key]?.split("::labelWidth")[1];

          let width = myStr?.substring(
            myStr?.indexOf("=") + 1,
            myStr?.lastIndexOf("p")
          );
          if (width?.match(/^[0-9]+$/)) {
            setLabelWidthClass("iflow-form-label-fix-cma");
            setLabelWidth(`${width}px`);
          }
        }
      });
    }
  }, [field]);

  return { labelWidthClass, labelWidth, labelAlign, labelFlexGrow };
};
