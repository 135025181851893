import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";

import { toLocaleString } from "~/utils";
import { withNamespaces } from "react-i18next";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { getColorByStatus, translateStatus } from "./utils";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { getProcesses } from "./actions";

import FlowModal from "./FlowModal";
import ProcessFilters from "./ProcessFilters";
import PreviewModal from "./PreviewModal";
import Messages from "~/components/Messages";
import CustomDropdown from "../CustomDropdown";
import { updateUserWidget } from '~/pages/User/actions';

import "~/assets/css/icons.css";

const ProcessesWidget = ({ apagarWidget, widget,handleMouseDown,isResizing,textMove, textClick, ...props }) => {
  const { t } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { processes } = useSelector((state) => state.processesReducer);
  const [selectedProcess, setSelectedProcess] = useState(undefined);
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const getUsername = (legacyUsers) => {
    let userBPM = legacyUsers.find(
      (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
    );
    return userBPM ? userBPM.username : null;
  };

  useEffect(() => {
    if (user.legacyUsers) {
      dispatch(getProcesses(getUsername(user.legacyUsers), "datacapture"));
    }
  }, [user.legacyUsers]);

  useEffect(() => {
    if (widget.userConfiguration == null) {
       const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "12"
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  const handleOpenFlowModal = (row) => {
    setShowFlowModal(true);
    setSelectedProcess(row);
  };
  const handleCloseFlowModal = () => {
    setShowFlowModal(false);
  };

  const handleOpenPreviewModal = (row) => {
    setShowPreviewModal(true);
    setSelectedProcess(row);
  };
  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const buildDescriptionColumn = (_, row, index) => {
    return (
      <OverlayTrigger
        key={index}
        placement="right"
        overlay={<Tooltip id={`tooltip-${index}`}>{row.description}</Tooltip>}
      >
        <span>
          {row.description && row.description.length > 30
            ? row.description.slice(0, 25) + "..."
            : row.description}
        </span>
      </OverlayTrigger>
    );
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const buildActionsColumn = (_, row, {}) => (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("ProcessesWidget.preview"))}
      >
        <i
          className="icon-detail table-action-icon"
          onClick={() => handleOpenPreviewModal(row)}
        />
      </OverlayTrigger>

      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("ProcessesWidget.proceed"))}
      >
        <i
          className="icon-edit table-action-icon"
          onClick={() => handleOpenFlowModal(row)}
        />
      </OverlayTrigger>
    </>
  );

  const buildStatusColumn = (_, row, index) => {
    if (row.PROCESS_STATUS) {
      const variant = getColorByStatus(row.PROCESS_STATUS);
      const translatedStatus = translateStatus(row.PROCESS_STATUS);
      return (
        <Badge pill variant={variant}>
          {t(translatedStatus)}
        </Badge>
      );
    }
    return "";
  };

  const columns = [
    {
      dataField: "pnumber",
      text: t("ProcessesWidget.processNumber"),
      sort: true,
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "fluxo",
      text: t("ProcessesWidget.flow"),
      sort: true,
    },
    {
      dataField: "description",
      text: t("ProcessesWidget.description"),
      formatter: buildDescriptionColumn,
      sort: true,
      style: {
        width: "100px",
      },
    },
    {
      dataField: "created",
      text: t("ProcessesWidget.sendDate"),
      sort: true,
      formatter: (_, row, {}) => toLocaleString(row.created),
    },
    {
      dataField: "status",
      text: t("ProcessesWidget.status"),
      sort: true,
      formatter: buildStatusColumn,
    },
    {
      dataField: "actions",
      text: t("ProcessesWidget.action"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
    },
  ];

  return (
    <>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-bell mr-2" />
              {t("ProcessesWidget.title")}
            </h6>
            <h6>
              {textMove && t("widgets.resizeMove")}
              {textClick && t("widgets.resizeClick")}
            </h6>
            <CustomDropdown
              apagarWidget={apagarWidget}
              handleMouseDown={handleMouseDown}
            />
          </Card.Header>
          {!isResizing && (
            <Card.Body>
              <ProcessFilters />
              <>
                <br />
                <h5>{t("ProcessesWidget.processes")}</h5>
                <hr />

                <BootstrapTable
                  keyField="pnumber"
                  data={processes}
                  columns={columns}
                  bootstrap4
                  pagination={paginationFactory()}
                  noDataIndication={t("ProcessesWidget.processesNotFound")}
                  striped
                  hover
                />
              </>
            </Card.Body>
          )}
        </Card>
      </div>

      <FlowModal
        showModal={showFlowModal}
        closeModal={handleCloseFlowModal}
        process={selectedProcess}
      />
      <PreviewModal
        showModal={showPreviewModal}
        closeModal={handleClosePreviewModal}
        process={selectedProcess}
      />
      <Messages />
    </>
  );
};
export default withNamespaces()(ProcessesWidget);
