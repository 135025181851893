import React from "react";
import { getApplication } from "../utils";
import { useHistory } from "react-router-dom";

const CreateCloseProcessButton = ({ className }) => {
  const history = useHistory();
  const handleCloseModal = () => {
    //find app from url
    const app = getApplication(window.location.href);

    if (app === "portalrh") {
      history.push(`/uniksystem/${app}/tasks`);
    } else {
      history.push(`/uniksystem/${app}/tarefas`);
    }
  };

  return (
    <div
      className={className ? className : "close-button_default"}
      onClick={handleCloseModal}
    >
      <i className="icon-remove mr-4" />
    </div>
  );
};

export default CreateCloseProcessButton;
