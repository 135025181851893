export const TOAST_ACTIVE_SUCCESS = 'TOAST@ACTIVE_SUCCESS';
export const TOAST_ACTIVE_WARNING = 'TOAST@ACTIVE_WARNING';
export const TOAST_ACTIVE_ERROR = 'TOAST@ACTIVE_ERROR';
export const TOAST_HIDE_SUCCESS = 'TOAST@HIDE_SUCCESS';
export const TOAST_HIDE_WARNING = 'TOAST@HIDE_WARNING';
export const TOAST_HIDE_ERROR = 'TOAST@HIDE_ERROR';
export const TOAST_INITIAL_STATE = 'TOAST@INITIAL_STATE';

export const toastActiveSuccess = (message) => ({ type: TOAST_ACTIVE_SUCCESS, message })
export const toastActiveWarning = (message) => ({ type: TOAST_ACTIVE_WARNING, message })
export const toastActiveError = (message) => ({ type: TOAST_ACTIVE_ERROR, message })
export const toastHideSuccess = () => ({ type: TOAST_HIDE_SUCCESS })
export const toastHideWarning = () => ({ type: TOAST_HIDE_WARNING })
export const toastHideError = () => ({ type: TOAST_HIDE_ERROR })

export const toastInititalState = () => ({ type: TOAST_INITIAL_STATE })
