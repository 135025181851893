import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import '~/assets/css/icons.css';

const TextFilter = ({ ...props }) => {
  const { t,
    filter,
    value,
    onChange
  } = props;

  return (
    <Col lg="3">
      <Form.Label>{t(`taskPage.general.${filter.variable}`)}</Form.Label>
      <Form.Control
        type="text"
        value={value}
        onChange={e => onChange(filter.variable, e.target.value)}
      />
    </Col>
  )
}

export default withNamespaces()(TextFilter);