import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";
import "~/pages/Easypay/assets/css/styles.css";
import iconGreenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import Header from "~/pages/Easypay/components/Header";
import Carta from "~/pages/Onboarding/testDocuments/cartaconducao.jpg";
import Cartao from "~/pages/Onboarding/testDocuments/cartaocidadao.jpg";
import KMSSet from "~/pages/Onboarding/testDocuments/sample.pdf";
import FR from "~/pages/Onboarding/testDocuments/sample2.pdf";
import "~/pages/Easypay/assets/css/styles.css";
import "~/pages/Easypay/assets/scss/_backOfficePreviewDocumentPageSection.scss";
import Breadcrumb from "~/pages/Easypay/components/Breadcrumb";
import Button from "~/pages/Easypay/components/Button";
import DocumentNavigation from "~/pages/Easypay/components/DocumentNavigation";
import Input from "~/pages/Easypay/components/Input";
import Link from "~/pages/Easypay/components/Link";

const DocumentPreview = ({ application }) => {
  const buildInputField = (text, value) => {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  };

  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const breadcrumbItems = [
    { text: "Onboarding", link: "http://localhost/login#/uniksystem/easypay" },
    { text: "HORT080622", link: "http://localhost/login#/uniksystem/easypay" },
    {
      text: "Cartão do Cidadão",
      link: "http://localhost/login#/uniksystem/easypay",
    },
  ];

  const documentsList = [
    { documentName: "Cartão de cidadão", holderName: "António Marques" },
    { documentName: "Carta de condução", holderName: "Francisco Carvalho" },
    { documentName: "Boletim", holderName: "" },
    { documentName: "Fatura da luz", holderName: "Manuel Santos" },
  ];

  const [documentSelected, setDocumentSelected] = useState(documentsList[0]);
  const [documentToDisplay, setDocumentToDisplay] = useState(Cartao);

  const getDocument = () => {
    switch (documentSelected.documentName) {
      case "Cartão de cidadão":
        setDocumentToDisplay(Cartao);
        break;
      case "Carta de condução":
        setDocumentToDisplay(Carta);
        break;
      case "Boletim":
        setDocumentToDisplay(FR);
        break;
      default:
        setDocumentToDisplay(KMSSet);
        break;
    }
  };

  useEffect(() => {
    getDocument();
  });

  return (
    <div
      className="easypay-backoffice-document-preview-page-main-wrapper"
      style={{ overflow: "auto", width: "100%", height: "100%" }}
    >
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
      />
      <div className="easypay-backoffice-document-preview-section">
        <div className="easypay-backoffice-document-preview-header">
          <div>
            <Breadcrumb breadcrumbStates={breadcrumbItems} />
            <div className="easypay-backoffice-text-icon-wrapper">
              <div className="easypay-text-icon-check">
                <img src={iconGreenCheckmark} alt="Icon Check" />
              </div>
              Verificado
            </div>
          </div>
          <DocumentNavigation
            selected={documentSelected}
            setSelected={setDocumentSelected}
            documentsList={documentsList}
          />
        </div>
        <div className="easypay-backoffice-document-preview-section-content-wrapper">
          <div className="easypay-backoffice-document-preview-section-left-content">
            <div className="easypay-backoffice-header">Dados recolhidos</div>
            <Input field={buildInputField("Nome", "António Marques")} />
            <div className="easypay-backoffice-document-preview-input-row">
              <div className="easypay-backoffice-small-input-wrapper">
                <Input field={buildInputField("Nacionalidade", "Portuguesa")} />
              </div>
              <div className="easypay-backoffice-small-input-wrapper">
                <Input
                  field={buildInputField("Data de Nascimento", "23/10/1960")}
                />
              </div>
            </div>
            <div className="easypay-backoffice-small-input-wrapper">
              <Input
                field={buildInputField("NIF(N-º Contribuinte)", "123456789")}
              />
            </div>
            <div className="easypay-backoffice-document-preview-input-row">
              <div className="easypay-backoffice-small-input-wrapper">
                <Input
                  field={buildInputField("N.º Documento", "214324241-YX1")}
                />
              </div>
              <div className="easypay-backoffice-small-input-wrapper">
                <Input field={buildInputField("Validade", "09/07/2022")} />
              </div>
            </div>
            <div className="easypay-backoffice-document-preview-buttons-wrapper">
              <Link
                text="Solicitar novamente"
                link="https://unikint.uniksystem.pt/#/"
              />
              <div className="easypay-backoffice-button-wrapper">
                <Button
                  text={"Guardar"}
                  variant={"easypay-button-primary"}
                  onclick={eval(addButtonField.onclick)}
                  key={uuid()}
                  id={addButtonField.id}
                  className="card-button"
                  align={"right"}
                  disabledColored={false}
                  disabled={false}
                  field={addButtonField}
                />
              </div>
            </div>
          </div>
          <iframe src={documentToDisplay} height={800} width={700} />
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(DocumentPreview);
