import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { withNamespaces } from "react-i18next";
import { isEmpty } from "lodash";

import { findOnlineUsers } from "./actions";
import { abbreviateString } from "../../utils";
import "./scss/_chat-list.scss";
import Spinner from "../Spinner";

const ChatList = ({ setShowChatRoom, setReceiver, t }) => {
  const dispatch = useDispatch();

  const { onlineUsers, isLoadingUsers } = useSelector(
    (state) => state.chatReducer
  );

  useEffect(() => {
    dispatch(findOnlineUsers());
  }, [dispatch]);

  const handleShowChatRoom = (receiver) => {
    setShowChatRoom(true);
    setReceiver(receiver);
  };

  return (
    <aside className="right-sidebar">
      <div className="sidebar-chat mr-t-30" data-plugin="chat-sidebar">
        <div className="sidebar-chat-info">
          <h6 className="sidebar-title mt-0 mr-l-20">{t("chat.list")}</h6>
          <form className="mr-t-10">
            <div className="form-group">
              <input
                type="search"
                className="form-control form-control-rounded fs-12 heading-font-family pd-l-20 pd-r-30"
                placeholder={t("chat.searchForFriends")}
              />
              <i className="feather feather-search post-absolute pos-right vertical-center mr-3 text-muted"></i>
            </div>
          </form>
        </div>

        <div className="chat-list">
          <div className="list-group row">
            <Spinner spinning={isLoadingUsers} />
            {!isLoadingUsers && isEmpty(onlineUsers) && (
              <label className="empty-label">{t("chat.noUsersOnline")}</label>
            )}
            {!isLoadingUsers &&
              onlineUsers.map((user) => (
                <div
                  key={user.id}
                  className="list-group-item"
                  onClick={() => handleShowChatRoom(user)}
                >
                  <figure className="thumb-xs user--online mr-3 mr-0-rtl ml-3-rtl">
                    <img
                      src={`data:image/png;base64, ${user.detail.picture}`}
                      className="user-rounded-circle"
                      alt=""
                    />
                  </figure>
                  <span>
                    <span className="name">
                      {abbreviateString(
                        `${user.detail.firstName} ${user.detail.lastName}`,
                        15
                      )}
                    </span>
                    <span className="username">
                      @{abbreviateString(user.username)}
                    </span>
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default withNamespaces()(ChatList);
