export const defaultApps = [
  {
    description: "Uniksystem",
    pathname: "/uniksystem",
    toggle: false,
  },
  {
    description: "BPM",
    pathname: "/uniksystem/bpm",
    toggle: false,
  },
  {
    description: "Compliance",
    pathname: "/uniksystem/compliance",
    toggle: false,
  },
  {
    description: "GDPR",
    pathname: "/uniksystem/gdpr",
    toggle: false,
  },
  {
    description: "Avaliação de Imóveis",
    pathname: "/uniksystem/gai",
    toggle: false,
  },
  {
    description: "RH",
    pathname: "/uniksystem/rh",
    toggle: false,
  },
  {
    description: "Portal RH",
    pathname: "/uniksystem/portalrh",
    toggle: false,
  },
  {
    description: "ePública Financeira",
    pathname: "/uniksystem/financeira",
    toggle: false,
  },
  {
    description: "DataCapture",
    pathname: "/uniksystem/datacapture",
    toggle: false,
  },
  {
    description: "Validação de Processos",
    pathname: "/uniksystem/validprocs",
    toggle: false,
  },
  {
    description: "Dashboard",
    pathname: "/uniksystem/dashboard",
    toggle: false,
  },
];