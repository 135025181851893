import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { Card } from "react-bootstrap";

import { getRoles, clearRoles, findDefaultRoles } from "./actions";
import RoleTable from "./components/RoleTable";
import ProtectedPage from "~/components/security/ProtectedPage";

import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";

const Roles = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { roles, defaultRoles, isUpdatedDefaultRoles } = useSelector(
    (state) => state.roleReducer
  );

  useEffect(() => {
    dispatch(getRoles());
    dispatch(findDefaultRoles());
    return () => {
      dispatch(clearRoles());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isUpdatedDefaultRoles) dispatch(findDefaultRoles());
  }, [dispatch, isUpdatedDefaultRoles]);

  const persistRole = () => {
    history.push("/uniksystem/admin/role/create");
  };

  return (
    <ProtectedPage roles={[ROLE_ADMIN, ROLE_ORG_ADMIN]}>
      <div className="main-card-v2" style={{ height: "fit-content" }}>
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>{t("admin.role.list.cardTitle")}</h6>
          </Card.Header>
          <Card.Body>
            <RoleTable
              roles={roles}
              defaultRoles={defaultRoles}
              persistRole={persistRole}
            />
          </Card.Body>
        </Card>
      </div>
    </ProtectedPage>
  );
};

export default withNamespaces()(Roles);
