import React, { useState, useEffect } from "react";
import { Col, Form, Row, Alert } from "react-bootstrap";
import CreateTextArea from "../CreateTextArea";
import CreateDateInput from "../CreateDateInput";
import CreateTextInput from "../CreateTextInput";
import CreateButtonsFromSelection from "../CreateButtonsFromSelection";
import CreateSearchableDropdown from "../CreateSearchableDropdown";
import uuid from "uuid/v1";

const CreateCalculosCustoConsulta = (props) => {
  const { blockdivision, styleSheet } = props;

  const [dtValidadeLicRep, setFormData2] = useState("");
  const [mccr_observacoes, setFormData3] = useState("");
  const [orcClienteRep, setFormData4] = useState("");
  const [orcAceiteRep, setFormData5] = useState("");
  const [numLicRep, setFormData6] = useState("");
  const [empreiteiroRep, setFormData7] = useState("");
  const [seccao, setFormData10] = useState("");
  const [fracaoRai, setFormData9] = useState("");
  const [areaBruta, setFormData11] = useState("");
  const [mccr_percE, setFormData12] = useState("");
  const [mccr_percD, setFormData13] = useState("");
  const [mccr_percConstReal, setFormData14] = useState("");
  const [mccr_percL, setFormData15] = useState("");
  const [tTerreno, setFormData16] = useState("");
  const [mccr_valorTotalCC, setFormData17] = useState("");
  const [mccr_valorE, setFormData18] = useState("");
  const [mccr_valorD, setFormData19] = useState("");
  const [mccr_valorCCE, setFormData20] = useState("");
  const [mccr_valorCt, setFormData21] = useState("");
  const [mccr_valorAtual, setFormData22] = useState("");
  const [mccr_valorL, setFormData23] = useState("");
  const [mccr_valorSeguro, setFormData24] = useState("");
  const [mccr_valorNovo, setFormData25] = useState("");
  const [mccr_valorMetodoCusto, setFormData26] = useState("");
  const [ivadoptado, setFormData27] = useState("");
  const [ltv, setFormData28] = useState("");
  const [obrasArealizar, setFormData29] = useState("");

  const [valorMetComp, setvalorMetComp] = useState("");
  const [valorMetCustos, setvalorMetCustos] = useState("");
  const [tableCustoTerreno, setTable1] = useState("");
  const [tableConstrucao, setTable2] = useState("");
  const [warningRight, setWarningRight] = useState("");
  const [textSize, setTextSize] = useState("")
  const [tableSize, setTableSize] = useState("")
  useEffect(() => {
    if (
      styleSheet?.zoomIn &&
      styleSheet?.zoomInTextSize
    ) {
      setTextSize(`${styleSheet?.zoomInTextSize}`)
    }
    if (
      styleSheet?.zoomIn &&
      styleSheet?.tablefontsize
    ) {
      setTableSize(`${styleSheet?.tablefontsize}`)
    }
    
  }, [styleSheet]);
  useEffect(() => {
    if (blockdivision) {
      //find form data
      let dtValidadeLicRep;
      let mccr_observacoes;
      let orcClienteRep;
      let orcAceiteRep;
      let numLicRep;
      let empreiteiroRep;
      let seccao;
      let tableCustoTerreno;
      let tableConstrucao;
      let areaRep;
      let mccr_percE;
      let mccr_percD;
      let mccr_percConstReal;
      let mccr_percL;
      let tTerreno;
      let mccr_valorTotalCC;
      let mccr_valorE;
      let mccr_valorD;
      let mccr_valorCCE;
      let mccr_valorCt;
      let mccr_valorAtual;
      let mccr_valorL;
      let mccr_valorSeguro;
      let mccr_valorNovo;
      let mccr_valorMetodoCusto;
      let ivadoptado;
      let ltv;
      let valorMetCustos;
      let obrasArealizar;
      let warning_right;
      let fracaoRai;

      let valorMetComp;

      const setValue = (f) => {
        if (f?.variable == "dtValidadeLicRep") dtValidadeLicRep = f;
        if (f?.variable == "mccr_observacoes") mccr_observacoes = f;
        if (f?.variable == "orcClienteRep") orcClienteRep = f;
        if (f?.variable == "orcAceiteRep") orcAceiteRep = f;
        if (f?.variable == "numLicRep") numLicRep = f;
        if (f?.variable == "empreiteiroRep") empreiteiroRep = f;
        if (f?.variable == "seccao") seccao = f;
        if (f?.variable == "fracaoRai") fracaoRai = f;
        if (f?.variable == "areaRep") areaRep = f;
        if (f?.variable == "mccr_percE") mccr_percE = f.value;
        if (f?.variable == "mccr_percD") mccr_percD = f.value;
        if (f?.variable == "mccr_percConstReal") mccr_percConstReal = f.value;
        if (f?.variable == "mccr_percL") mccr_percL = f.value;
        if (f?.variable == "mccr_valorTotalCC") mccr_valorTotalCC = f.value;
        if (f?.variable == "mccr_valorE") mccr_valorE = f.value;
        if (f?.variable == "mccr_valorD") mccr_valorD = f.value;
        if (f?.variable == "mccr_valorCCE") mccr_valorCCE = f.value;
        if (f?.variable == "mccr_valorCt") mccr_valorCt = f.value;
        if (f?.variable == "mccr_valorAtual") mccr_valorAtual = f.value;
        if (f?.variable == "mccr_valorL") mccr_valorL = f.value;
        if (f?.variable == "mccr_valorSeguro") mccr_valorSeguro = f.value;
        if (f?.variable == "mccr_valorNovo") mccr_valorNovo = f.value;
        if (f?.variable == "mccr_valorMetodoCusto")
          mccr_valorMetodoCusto = f.value;

        if (f?.text == "Valor Met Comp") valorMetComp = f.value;
        if(f?.text == "Rever valor Método Comparativo / Método dos Custos" && f?.type =="textmessage") warning_right = f.text
        if (f?.text == "T - Terreno =") tTerreno = f.value;
        if (f?.text == "IV adoptado") ivadoptado = f.value;
        if (f?.text == "LTV") ltv = f.value;
        if (f?.text == "Valor Met Custos") valorMetCustos = f.value;
        if (f?.text == "Obras a realizar necessitam de Licença Camararia")
          obrasArealizar = f.value;

        if (f?.type == "arraytable") {
          if (f?.row[0]?.col[0]?.value === "Custo do Terreno (T)") {
            tableCustoTerreno = f;
          }
          if (
            f?.row[0]?.col[0]?.value === "Custos Directos de construção (CC)"
          ) {
            tableConstrucao = f;
          }
        }
      };
      let blockdivision_ = JSON.parse(JSON.stringify(blockdivision));
      if (!Array.isArray(blockdivision_)) {
        blockdivision_ = [blockdivision_];
      }
      blockdivision.forEach((b) => {
        if (Array.isArray(b.columndivision)) {
          b.columndivision.forEach((c) => {
            if (Array.isArray(c.field)) {
              c.field.forEach((f) => {
                setValue(f);
              });
            } else {
              setValue(c.field);
            }
          });
        } else {
          if (Array.isArray(b.columndivision?.field)) {
            b.columndivision.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(b.columndivision.field);
          }
        }
      });

      setFormData2(dtValidadeLicRep);
      setFormData3(mccr_observacoes);
      setFormData4(orcClienteRep);
      setFormData5(orcAceiteRep);
      setFormData6(numLicRep);
      setFormData7(empreiteiroRep);
      setFormData10(seccao);
      setFormData9(fracaoRai);
      setFormData11(areaRep);
      setFormData12(mccr_percE);
      setFormData13(mccr_percD);
      setFormData14(mccr_percConstReal);
      setFormData15(mccr_percL);
      setFormData16(tTerreno);
      setFormData17(mccr_valorTotalCC);
      setFormData18(mccr_valorE);
      setFormData19(mccr_valorD);
      setFormData20(mccr_valorCCE);
      setFormData21(mccr_valorCt);
      setFormData22(mccr_valorAtual);
      setFormData23(mccr_valorL);
      setFormData24(mccr_valorSeguro);
      setFormData25(mccr_valorNovo);
      setFormData26(mccr_valorMetodoCusto);
      setFormData27(ivadoptado);
      setFormData28(ltv);
      setFormData29(obrasArealizar);

      setvalorMetComp(valorMetComp);
      setvalorMetCustos(valorMetCustos);
      setTable1(tableCustoTerreno);
      setTable2(tableConstrucao);
      setWarningRight(warning_right)
    }
  }, [blockdivision]);

  function parseCurrency(arg) {
    return arg.replace(/[^\d,]/g, "").replace(/,/g, ".");
  }

  function parseNumber(arg) {
    if(arg != undefined) return Number(arg.replace(/[,]/g, ""));
  }

  function updateWarning() {
    let comparativo = "";
    if (
      document.getElementById("valorMetComp")?.textContent &&
      document.getElementById("valorMetCustos")?.textContent
    ) {
      let valorMetComp = Number(
        parseCurrency(
          document.getElementById("valorMetComp").textContent.split("€")[0]
        )
      );
      let valorComercial = Number(
        parseCurrency(
          document.getElementById("valorMetCustos").textContent.split("€")[0]
        )
      );

      if (valorMetComp > valorComercial) {
        comparativo = ">";
      } else if (valorMetComp < valorComercial) {
        comparativo = "<";
      } else {
        comparativo = "=";
      }
    }
    return comparativo;
  }

  /*First render*/
  useEffect(() => {
    const comparativo = updateWarning();
    document.getElementById("comparativo").textContent = comparativo;
    if (comparativo == '<' || comparativo == '=') {
      document.getElementById("warningRight").style.display = "block";
  } else  {
      document.getElementById("warningRight").style.display = "none";
  }
  }, [tableCustoTerreno, tableConstrucao]);

  const renderTable = (table) => {
    if (table && Array.isArray(table.row)) {
      return table.row.map((r, index) => {
        if (index != 0) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  let style =
                    i !== 0 ? { textAlign: "end" } : { textAlign: "start" };

                  if (i == 3) {
                    return (
                      <td key={i} style={{ ...style, fontSize: `${textSize}px` }}>
                        {c.value ? `${c.value}€` : ""}
                      </td>
                    );
                  } else
                    return (
                      <td key={i} style={{ ...style, fontSize: `${textSize}px` }}>
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };


  const style1 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
    flexGrow: "1",
  };

  const style2 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
    justifyContent: "flex-end",
  };

  const style3 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style4 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    flexGrow: "1",
  };

  const style5 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    width: "30%",
  };

  const style6 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    width: "20%",
  };

  const style7 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    width: "50%",
  };

  const style8 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style9 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "end",
    fontSize: "1rem",
  };

  const style10 = {
    padding: "5px 0px 10px 0px",
    fontSize: "1rem",
  };

  const style11 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "start",
    paddingLeft: "0px",
  };

  const renderColumnContent = () => {
    return (
      <>
        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                Método
              </Form.Label>
              <Form.Label column style={{ ...style11, fontSize: `${textSize}px` }}>
                Comparativo,Rendimento,Reposição
              </Form.Label>
            </Form.Group>
          </Col>
        </Form.Group>
        {seccao && <CreateButtonsFromSelection field={seccao} />}
        {fracaoRai && (
          <CreateSearchableDropdown
            field={fracaoRai}
            key={"fracaoRai"}
            fontSize={textSize}
            styleSheet={styleSheet}
          />
        )}
        <Form.Group key={uuid()} style={style1}>
          <Form.Label column style={{ flexGrow: "1" }}>
            <h4 style={{ fontSize: `${textSize}px` }}>Método do custo de construção ou reposição</h4> <hr />
          </Form.Label>
        </Form.Group>
        <Alert variant="info">
          O método do custo de reposição / substituição é utilizado na
          determinação do valor de um imóvel através da soma entre o valor de
          mercado do terreno e de todos os custos necessários para a construção
          de um imóvel com as mesmas características físicas e funcionais. Deve
          ser tido em conta no valor final do imóvel, a sua depreciação em
          função da antiguidade, estado de conservação, estimativa de vida útil
          e margens de lucro requeridas.
        </Alert>
        <table style={{ width: "100%" }} className="bordertableMCCR">
          <thead>
            <tr>
              <th
                id="64_1_1"
                style={{ ...style10, width: "25%", textAlign: "start" }}
              >
                Custo do Terreno (T)
              </th>
              <th
                id="64_1_2"
                style={{ ...style10, width: "20%", textAlign: "end" }}
              >
                Área (m2)
              </th>
              <th
                id="64_1_3"
                style={{ ...style10, width: "25%", textAlign: "end" }}
              >
                Valor (€/m2)
              </th>
              <th
                id="64_1_4"
                style={{ ...style10, width: "30%", textAlign: "end" }}
              >
                Valor Total
              </th>
            </tr>
          </thead>

          <tbody>{renderTable(tableCustoTerreno)}</tbody>
        </table>
        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.30", padding: "0" }}
          >
            <Form.Group as={Row} style={style1}>
              <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
                T - Terreno =
              </Form.Label>
              <Form.Label
                id="totalTerreno"
                column
                style={{ ...style9, paddingRight: "0px" }}
              >
                {tTerreno ? `${tTerreno}€` : ""}
              </Form.Label>
            </Form.Group>
            <hr />
          </Col>
        </Form.Group>
        <table style={{ width: "100%" }} className="bordertableMCCR">
          <thead>
            <tr>
              <th
                id="72_1_1"
                style={{ ...style10, textAlign: "start", width: "25%" }}
              >
                Custos Directos de construção (CC)
              </th>
              <th
                id="72_1_2"
                style={{ ...style10, width: "20%", textAlign: "end" }}
              >
                Área (m2)
              </th>
              <th
                id="72_1_3"
                style={{ ...style10, width: "25%", textAlign: "end" }}
              >
                Custo (€/m2)
              </th>
              <th
                id="72_1_4"
                style={{ ...style10, width: "30%", textAlign: "end" }}
              >
                Valor Total
              </th>
            </tr>
          </thead>

          <tbody>{renderTable(tableConstrucao)}</tbody>
        </table>
        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.3", padding: "0" }}
          >
            <Form.Group as={Row} style={style3}>
              <Form.Label column style={{ flexGrow: "1" }}>
                Custos Directos de construção (CC)
              </Form.Label>
              <Form.Label
                id="custosConstrucao"
                column
                style={{ ...style9, paddingRight: "0px" }}
              >
                {mccr_valorTotalCC ? `${mccr_valorTotalCC}€` : ""}
              </Form.Label>
            </Form.Group>
            <hr />
          </Col>
        </Form.Group>
        <br />
        <Form.Group as={Row} style={style4}>
          <Form.Group as={Row} style={style5}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              Encargos Indiretos (% de CC)
            </Form.Label>
            <Form.Label column style={style9}>
              {mccr_percE ? `${mccr_percE}%` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              E =
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorE ? `${mccr_valorE}€` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              flexWrap: "nowrap",
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "5px",

              width: "30%",
            }}
          >
            <Form.Label
              id="label_mccr_percD"
              column
              style={{ flexGrow: "1", fontSize: `${textSize}px` }}
            >
              Depreciação Física, Funcional e Económica (CC+E+L)*D
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_percD ? `${mccr_percD}%` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              D =
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorD ? `${mccr_valorD}€` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} style={style4}>
          <Form.Group as={Row} style={style5}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              Custos de construção + Encargos indiretos
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              CC + E =
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorCCE ? `${mccr_valorCCE}€` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group id="perConstrucao" as={Row} style={style5}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              % de construção realizada
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_percConstReal ? `${mccr_percConstReal}%` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} style={style4}>
          <Form.Group as={Row} style={style5}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              Custo Total (CC+E+T)
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              Ct =
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorCt ? `${mccr_valorCt}€` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style7}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              Valor Actual de acordo com % de construção realizada
              (T+%construção*(CC+E+L)-D
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorAtual ? `${mccr_valorAtual}€` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} style={style4}>
          <Form.Group id="margemLucro" as={Row} style={style5}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              Margem de Lucro do Promotor (% de Ct)
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_percL ? `${mccr_percL}%` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              L =
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorL ? `${mccr_valorL}€` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style7}>
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              Valor para efeitos de Seguro (CC+E)
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorSeguro ? `${mccr_valorSeguro}€` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} style={style4}>
          <Form.Group
            as={Row}
            style={{
              flexWrap: "nowrap",
              margin: "0px",
              width: "50%",
            }}
          >
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              Valor Novo (Ct + L)
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorNovo ? `${mccr_valorNovo}€` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} style={style4}>
          <Form.Group
            as={Row}
            style={{
              flexWrap: "nowrap",
              paddingRight: "0px",
              paddingLeft: "0px",
              margin: "0px",
              width: "100%",
            }}
          >
            <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
              V - Valor Comercial
            </Form.Label>
            <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
              {mccr_valorMetodoCusto ? `${mccr_valorMetodoCusto}€` : ""}
            </Form.Label>
            <hr />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {areaBruta && (
              <CreateTextInput
                id="areaBruta"
                field={areaBruta}
                key={areaBruta.variable}
                fontSize={textSize}
              />
            )}

            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                IV adoptado
              </Form.Label>
              <Form.Label column style={{ ...style11, fontSize: `${textSize}px` }}>
                {ivadoptado}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                LTV
              </Form.Label>
              <Form.Label column style={{ ...style11, fontSize: `${textSize}px` }}>
                {ltv ? `${ltv}%` : ""}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} style={{ ...style3, paddingLeft: "15px" }}>
              <Alert variant="info">Montante de Crédito</Alert>
            </Form.Group>
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`}>
            <Alert variant="info">
              Comparativo de Valores (Método Comparativo / Método Custos de
              construção Reposição)
            </Alert>
          </Col>
        </Form.Group>
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            width: "70%",
          }}
        >
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
                Valor Met Comp
              </Form.Label>
              <Form.Label
                id="valorMetComp"
                column
                style={{
                  flexGrow: "1",
                  fontWeight: "bold",
                  fontSize: `${textSize}px`,
                }}
              >
                {valorMetComp ? `${valorMetComp}€` : `0,00€`}
              </Form.Label>
            </Form.Group>
          </Col>

          <Form.Label
            id="comparativo"
            column
            style={{ flexGrow: "0.2", fontSize: `${textSize}px` }}
          ></Form.Label>

          <Col key={`columndivision_3}`} style={{ padding: "0px" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
                Valor Met Custos
              </Form.Label>
              <Form.Label
                id="valorMetCustos"
                column
                style={{
                  flexGrow: "1",
                  fontWeight: "bold",
                  fontSize: `${textSize}px`,
                }}
              >
                {valorMetCustos ? `${valorMetCustos}€` : `0,00€`}
              </Form.Label>
            </Form.Group>
          </Col>
          <Form.Label
            id="warningRight"
            column
            style={{
              flexGrow: "1",
              fontSize: `${textSize}px`,
              color: "red",
            }}
          >
            {warningRight}
          </Form.Label>
        </div>
        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {orcClienteRep && (
              <CreateTextInput
                field={orcClienteRep}
                key={orcClienteRep.variable}
                fontSize={textSize}
              />
            )}
            {orcAceiteRep && (
              <CreateTextInput
                field={orcAceiteRep}
                key={orcAceiteRep.variable}
                fontSize={textSize}
              />
            )}

            {empreiteiroRep && (
              <CreateTextInput field={empreiteiroRep} key={"empreiteiroRep"} fontSize={textSize}/>
            )}
            <Form.Group as={Row} style={style8}>
              <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
                <Form.Group as={Row} style={style3}>
                  <Form.Label
                    column
                    style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
                  >
                    Obras a realizar necessitam de Licença Camararia
                  </Form.Label>
                  <Form.Label column style={{ ...style11, fontSize: `${textSize}px` }}>
                    {obrasArealizar}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Form.Group>

            {numLicRep && (
              <CreateTextInput field={numLicRep} key={"numLicRep"} fontSize={textSize}/>
            )}

            {dtValidadeLicRep && (
              <CreateDateInput
                field={dtValidadeLicRep}
                key={"dtValidadeLicRep"}
                fontSize={textSize}
              />
            )}

            {mccr_observacoes && (
              <CreateTextArea
                field={mccr_observacoes}
                key={"mccr_observacoes"}
                fontSize={textSize}
              />
            )}
          </Col>
        </Form.Group>
      </>
    );
  };

  return (
    <Row
      key={`blockdivision_comparativo`}
      style={{ width: "100%", flexWrap: "nowrap", margin: "0px" }}
    >
      <Col key={`columndivision_comparativo}`} style={{ flexGrow: "1" }}>
        {renderColumnContent()}
      </Col>
    </Row>
  );
};
export default CreateCalculosCustoConsulta;
