import { call, put } from "redux-saga/effects";
import http from "~/utils/config/http";
import oauth from "~/utils/oauth";
import { IFLOW_APP } from "~/utils/constants";

import {
  setBatchs,
  setBatchsWithBinary,
  setBatchById,
  addDocumentToBatch,
  successSaveDocumentIntoBatch,
  failSaveDocumentIntoBatch,
  setDeletedBatch,
  setDeletedDocument,
  setDeletedDocuments,
  removeDocument,
  removeDocuments,
  setRefreshedDocument,
  clearIsRefreshed,
  setMetadataDocument,
  setSavedMetadataDocument,
  setUpdatedBatch,
  setIflowConfiguration,
  setUpdatedDocuments,
  setUpdatedDocument,
  setDocument,
  approvedChanged,
  setCustomBatchs
} from "../actions";

import { activeError, activeSuccess } from "~/components/Messages/actions";
import { batch } from "react-redux";

export function* findAllBatchs() {
  try {
    const resp = yield http.get("/api/datacapture/batch/all");
    yield put(setBatchs(resp.data));
  } catch (e) {
    yield put(setBatchs([]));
    //yield put(activeError("datacapture.general.errorMessage"));
    yield put(activeError("It was not possible to get documents"));
  }
}

export function* findAllBatchsWithBinary() {
  try {
    const resp = yield http.get("/api/datacapture/batch/all-with-binary");
    yield put(setBatchsWithBinary(resp.data));
  } catch (e) {
    yield put(setBatchsWithBinary([]));
    yield put(activeError("datacapture.general.getErrorMessage"));
  }
}

export function* findAllCustomBinaryBatchs() {
  try {
    const resp = yield http.get("/api/datacapture/batch/custom-all-with-binary");
    yield put(setBatchsWithBinary(resp.data));
  } catch (e) {
    yield put(setBatchsWithBinary([]));
    yield put(activeError("datacapture.general.getErrorMessage"));
  }
}

export function* findAllCustomBatchs(filteredDocuments) {
  const docs = filteredDocuments?.filteredDocuments
  const url = window.location.origin;
  const docsWithUrl = docs?.map(doc => ({
    ...doc,
    urlfordownload: url
  }));
  try {
    const resp = yield http.post("/api/datacapture/checkIfCustomAndReturn", docsWithUrl);
    if (resp.data != null) {
      const content = JSON.stringify(resp.data, null, 4);
      const a = document.createElement("a");
      const file = new Blob([content], { type: "text/plain" });
      a.href = URL.createObjectURL(file);
      a.download = "custom_dataCapture.json";
      a.click();
    }
    yield put(setCustomBatchs(true))
  } catch (e) {
    yield put(setCustomBatchs(false));
    yield put(activeError("datacapture.general.getErrorMessage"));
  }
}

export function* findBatchByFilters({ filters }) {
  try {
    const resp = yield http.post("/api/datacapture/batch/filters", filters);
    yield put(setBatchs(resp.data));
  } catch (e) {
    yield put(activeError("datacapture.general.errorMessage"));
  }
}

export function* findBatchById({ id }) {
  try {
    const resp = yield http.get(`/api/datacapture/batch/find/${id}`);
    yield put(setBatchById(resp.data));
  } catch (e) {
    yield put(activeError("datacapture.general.errorMessage"));
  }
}

export function* saveDocumentIntoBatch({ batchDTO }) {
  //one file at a time
  yield put(addDocumentToBatch(batchDTO.document));
  try {
    const data = new FormData();
    data.append("file", batchDTO.document.file);
    data.append(
      "batch",
      new Blob([JSON.stringify(batchDTO)], { type: "application/json" })
    );
    const resp = yield http.post("/api/datacapture/batch/document/save", data);
    yield put(successSaveDocumentIntoBatch(resp.data));
  } catch (e) {
    const { response } = e;
    if (response && response.status === 418) {
      yield put(activeError("datacapture.general.418ErrorMessage"));
    } else if (response && response.status === 502) {
      yield put(activeError("datacapture.general.502ErrorMessage"));
    } else {
      yield put(activeError("datacapture.general.saveErrorMessage"));
    }
    yield put(
      failSaveDocumentIntoBatch("datacapture.general.saveErrorMessage")
    );
  }
}

export function* saveDocumentListIntoBatch({ docList }) {
  //multiple files

  for (let i = 0; i < docList.length; i++) {
    yield put(addDocumentToBatch(docList[i].document));
  }
  const firstElement = docList[0];
  try {
    const data = new FormData();
    data.append("file", firstElement.document.file);
    data.append(
      "batch",
      new Blob([JSON.stringify(firstElement)], { type: "application/json" })
    );
    const resp = yield http.post("/api/datacapture/batch/document/save", data);
    if (docList.length <= 1) yield put(successSaveDocumentIntoBatch(resp.data));
    const savedBatchId = resp.data.id;
    let lastResponse;
    for (let i = 1; i < docList.length; i++) {
      docList[i].id = savedBatchId;
      const data = new FormData();
      data.append("file", docList[i].document.file);
      data.append(
        "batch",
        new Blob([JSON.stringify(docList[i])], { type: "application/json" })
      );
      lastResponse = yield http.post(
        "/api/datacapture/batch/document/save",
        data
      );
    }
    if (lastResponse)
      yield put(successSaveDocumentIntoBatch(lastResponse.data));
  } catch (e) {
    const { response } = e;
    if (response && response.status === 418) {
      yield put(activeError("datacapture.general.418ErrorMessage"));
    } else if (response && response.status === 502) {
      yield put(activeError("datacapture.general.502ErrorMessage"));
    } else {
      yield put(activeError("datacapture.general.saveErrorMessage"));
    }
    yield put(
      failSaveDocumentIntoBatch("datacapture.general.saveErrorMessage")
    );
  }
}

export function* deleteBatch({ id }) {
  try {
    yield http.delete(`/api/datacapture/batch/delete/${id}`);
    yield put(setDeletedBatch(true));
    yield put(activeSuccess("datacapture.general.deleteSuccessMessage"));
  } catch (e) {
    yield put(setDeletedBatch(false));
    yield put(activeError("datacapture.general.deleteErrorMessage"));
  }
}

export function* deleteDocument({ document }) {
  try {
    const resp = yield http.delete(
      `/api/datacapture/document/delete/${document}`
    );
    yield put(setDeletedDocument(true));
    yield put(removeDocument(document));
    if (resp.status === 200) {
      try {
        const resp = yield http.get("/api/datacapture/batch/all");
        yield put(setBatchs(resp.data));
      } catch (e) {
        yield put(setBatchs([]));
        yield put(activeError("datacapture.general.errorMessage"));
      }
    }
  } catch (e) {
    yield put(setDeletedDocument(false));
  }
}

export function* deleteDocuments({ username }) {
  try {
    yield http.delete(`/api/datacapture/batch/deleteAll/${username}`);
    yield put(setDeletedDocuments(true));
    yield put(removeDocuments());
  } catch (e) {
    yield put(setDeletedDocuments(false));
  }
}

export function* refreshApproved({ batchId, documentId }) {
  try {
    const resp = yield http.get(`/api/datacapture/approve/${batchId}/${documentId}`);
    yield put(approvedChanged())
  }
  catch(e) {

  }
}

export function* refreshApprovedDate({ batchId, documentId, newDate }) {
  console.log("comeca " , newDate, batchId, documentId + " acaba")
  try {

    let data = {
      date: newDate
    };

    const resp = yield http.post(`/api/datacapture/approve/${batchId}/${documentId}`, data);
    yield put(approvedChanged())
  }
  catch(e) {

  }
}

export function* refreshDocument({ document }) {
  if (document.length === 0) {
    yield put(clearIsRefreshed());
    return;
  }
  if (Array.isArray(document)) {
    for (const doc of document) {
      try {
        const resp = yield http.post("/api/datacapture/document/refresh", doc);
        yield put(setRefreshedDocument(resp.data));
      } catch (e) {
        yield put(clearIsRefreshed());
      }
    }
  } else {
    try {
      const resp = yield http.post(
        "/api/datacapture/document/refresh",
        document
      );

      yield put(setRefreshedDocument(resp.data));
    } catch (e) {
      yield put(clearIsRefreshed());
    }
  }
}

export function* getMetadataDocument({ document }) {
  try {
    const resp = yield http.get(`/api/datacapture/document/find/${document}`);
    yield put(setMetadataDocument(resp.data));
  } catch (e) {
    yield put(setMetadataDocument(undefined));
  }
}


export function* getMetadataDocumentFilename({ document }) {
  try {
    const resp = yield http.get(`/api/datacapture/document/find/filename/${document}`);
    yield put(setMetadataDocument(resp.data));
  } catch (e) {
    yield put(setMetadataDocument(undefined));
  }
}

export function* getDocument({ document }) {
  try {
    const resp = yield http.get(`/api/datacapture/document/find/${document}`);
    yield put(setDocument(resp.data));
  } catch (e) {
    yield put(setDocument(undefined));
  }
}

export function* saveMetadataDocument({ metadataDocument }) {
  try {
    const resp = yield http.post(
      "/api/datacapture/metadata/save",
      metadataDocument
    );
    yield put(setSavedMetadataDocument(resp.data));
  } catch (e) {
    yield put(setSavedMetadataDocument());
  }
}

export function* submitToiFlow({ doc, flowId }) {
  const token = oauth.getAccessToken();
  const authtoken = "&Authorization=" + token;
  const url = `/inicio_flow.jsp?flowid=${flowId}&attr_lote=${doc.batchId}${authtoken}`;
  try {
    const iflowResp = yield http.get(url, IFLOW_APP);
    if (
      iflowResp.data &&
      iflowResp.data.includes(
        `<form enctype="multipart/form-data" method="post" name="dados"`
      )
    ) {
      const resp = http.put("/api/datacapture/batch/terminated", doc.batchId);
      if (resp) {
        const resp = yield http.get("/api/datacapture/batch/all");
        yield put(setBatchs(resp.data));
      }
      yield put(activeSuccess("datacapture.general.iFlowSuccess"));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* updateBatch({ payload }) {
  try {
    yield http.post("/api/datacapture/batch/update", payload);
    yield put(setUpdatedBatch(true));
  } catch (e) {
    yield put(setUpdatedBatch(false));
  }
}

export function* updateDocuments({ documents }) {
  try {
    const resp = http.post("/api/datacapture/document/update", documents);
    yield put(setUpdatedDocuments(resp.data));
  } catch (e) {
    yield; //TODO !
  }
}

export function* acceptDuplicate({ document }) {
  try {
    const resp = http.post(
      "/api/datacapture/document/acceptDuplicate",
      document
    );
    yield put(setUpdatedDocument(resp.data));
  } catch (e) {
    yield; //TODO !
  }
}

export function* getIflowConfiguration({ application }) {
  try {
    const resp = yield http.get(`/api/iflow-configuration/${application}`);
    yield put(setIflowConfiguration(resp.data.configuration));
  } catch (e) {}
}

export function* savePoNumber({ document }) {
  try {
    const resp = yield http.post(
      `/api/datacapture/metadata/save`,
      document
    );
    if (resp.status === 200) {
      try {
        const resp = yield http.get("/api/datacapture/batch/all");
        yield put(setBatchs(resp.data));
      } catch (e) {
        yield put(setBatchs([]));
        yield put(activeError("datacapture.general.errorMessage"));
      }
    }
  } catch (e) {
    yield put(setDeletedDocument(false));
  }
}

