import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import RhTableList from "./RhTableList";
import Dropdown from "~/components/Dropdown";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaymentsData,
  getPaymentsDropdown,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import ButtonsUpdate from "./ButtonsUpdate";

const RhPaymentsData = ({ rowCollaborator, t }) => {
  const [formOfPaymentList, setFormOfPaymentList] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState("");
  const [iban, setIban] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getPaymentsDropdown());
      dispatch(
        getPaymentsData(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
    }
  }, [rowCollaborator]);

  const { paymentsData, paymentsDropdown } = useSelector(
    (state) => state.personalReducer
  );

  const handleFormOfPayment = (e) => {
    setPaymentSelected(e);
  };

  const handleIban = (e) => {
    setIban(e.target.value);
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (
        data.length > 0 &&
        paymentsDropdown &&
        Object.keys(paymentsDropdown).length > 0
      ) {
        setFormOfPaymentList(paymentsDropdown.formOfPayment);
        setPaymentSelected(data[0].formOfPayment);
        setIban(data[0].iban);
      }
    };

    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(paymentsData);
    }
  }, [paymentsData, paymentsDropdown, rowClicked]);

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const columns = [
    {
      dataField: "formOfPaymentDesing",
      text: t(`portalrh.paymentsData.FormofPayment`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "iban",
      text: t(`portalrh.paymentsData.Iban`),
      sort: true,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  return (
    <div className="collaboratoRH__view">
      <ButtonsUpdate OnTakingBolean={OnTakingBolean} />
      <Form>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.paymentsData.FormofPayment`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={formOfPaymentList}
              handleSubmit={handleFormOfPayment}
              defaultValue={paymentSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="6">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.paymentsData.FormofPayment`)}
            </Form.Label>
            <Form.Control
              value={iban == null ? "" : iban}
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              type="text"
              onChange={handleIban}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <RhTableList
          tableId={"35"}
          columns={columns}
          data={paymentsData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhPaymentsData);
