import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getCollaborators,
  getScheduler,
} from "~/store/ducks/portalrh/actionTypes";
import Sidebar from "../RhCollaborator/Sidebar";
import Dropdown from "~/components/Dropdown";
import Spinner from "~/components/Spinner";
import { Button, Stack } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { Card, Row, Col, Tabs, Tab, Form } from "react-bootstrap";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { FaSearchMinus, FaPrint } from "react-icons/fa";
import {
  getRHBolsaHorasUsersGroup,
  getRHSaldoBolsaHoras,
  getRHSaldoInicialFinalBolsaHoras,
} from "~/store/ducks/rhadminConfiguration/actionTypes";
import {
  clearRHSaldoBolsaHoras,
  clearRHSaldoInicialFinalBolsaHoras,
} from "~/store/ducks/rhadminConfiguration/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { toLocaleDateString } from "~/utils";
import FlowModal from "~/components/FlowModal";

const BolsaHoras = ({
  portalRhMenus,
  designacao,
  path,
  showDrop,
  t,
  handleOpenFlowModal,
  handleCloseFlowModal,
  selectedProcess,
  showFlowModal,
}) => {
  const {
    rhbolsaHorasUsersGroup,
    saldoBolsa,
    saldoBolsaInicialFinal,
    isLoadingSaldo,
  } = useSelector((state) => state.rhadminConfigurationReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { collaborators } = useSelector((state) => state.portalrhReducer);

  const dispatch = useDispatch();

  const [menuSideBar, setMenuSideBar] = useState(null);
  const [grupos, setgrupos] = useState("");
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [activeButton, setActiveButton] = useState("");
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [saldosHours_inicialFinal, setSaldosHours_inicialFinal] = useState("");
  const [saldos_hours, setSaldos_hours] = useState([]);
  const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);
  const [field, setField] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [userNamePortalRH, setuserNamePortalRH] = useState(undefined);

  useEffect(() => {
    dispatch(getCollaborators());
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        setMenuSideBar(menu);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const userRH = collaborators.find((collaborator) => {
      return user.legacyUsers.some((legacyUser) => {
        return (
          legacyUser.username === collaborator.username &&
          legacyUser.legacyApplication.label === "portalrh"
        );
      });
    });
    setuserNamePortalRH(userRH);
  }, [collaborators]);

  useEffect(() => {
    if (showDrop) {
      dispatch(clearRHSaldoBolsaHoras());
      dispatch(clearRHSaldoInicialFinalBolsaHoras());
      dispatch(getRHBolsaHorasUsersGroup());
    }
    setSelectedUser(undefined);
    setgrupos("");
    setActiveButton("");
  }, []);

  useEffect(() => {
    if (!showDrop) {
      if (userNamePortalRH != undefined) {
        dispatch(getRHSaldoBolsaHoras(userNamePortalRH.id));
        dispatch(
          getRHSaldoInicialFinalBolsaHoras(
            userNamePortalRH.id,
            createDatetoDispatch()
          )
        );
      }
    }
  }, [userNamePortalRH]);

  useEffect(() => {
    setSaldos_hours(saldoBolsa);
    setSaldosHours_inicialFinal(saldoBolsaInicialFinal);
  }, [saldoBolsa, saldoBolsaInicialFinal]);

  useEffect(() => {
    if (selectedUser != undefined && grupos != "") {
      dispatch(getRHSaldoBolsaHoras(selectedUser.id));
      dispatch(
        getRHSaldoInicialFinalBolsaHoras(
          selectedUser.id,
          createDatetoDispatch()
        )
      );
    }
  }, [selectedUser]);

  const createDatetoDispatch = () => {
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    let mes = (dataAtual.getMonth() + 1).toString().padStart(2, "0"); // +1 porque os meses começam em zero
    let dia = dataAtual.getDate().toString().padStart(2, "0");

    const novaDataFormatada = `${ano}-${mes}-${dia}`;

    return novaDataFormatada;
  };

  const ClearColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: "#32058b",
    padding: "0px 22px",
    minWidth: "132px",
    borderRadius: "23px",
    height: "45px",
    "&:hover": {
      backgroundColor: "#230361",
    },
  }));

  const UsersButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: "#32058b",
    padding: "0px 22px",
    minWidth: "132px",
    borderRadius: "23px",
    height: "45px",
    "&:hover": {
      backgroundColor: "#230361",
    },
  }));

  const handleClearFilter = () => {
    setSelectedUser(undefined);
    setgrupos("");
    setActiveButton("");
    setCleanDropdown(true);
    dispatch(clearRHSaldoBolsaHoras());
    dispatch(clearRHSaldoInicialFinalBolsaHoras());
  };

  const handleChooseUser = (user) => {
    setSelectedUser(user);
    setActiveButton(user.nomeCurto);
  };

  const handleStatusSort = (field) => {
    setOrder((order) => (order === "desc" ? "asc" : "desc"));
    setField(field);
  };

  const isHidden = (column) => {
    if (hiddenDefaultColumns) {
      return hiddenDefaultColumns.some((c) => c === column) ? true : false;
    } else return false;
  };

  const ChecksFormatter = (cell, row, rowIndex, formatExtraData) => {
    let value = undefined;
    if (row[formatExtraData.value] == null) {
      value = "00:00";
    } else {
      value = row[formatExtraData.value];
    }
    return value;
  };

  const columns = [
    {
      dataField: "timeSheetWeekId",
      formatter: (_, row, rowIndex) => `${row.timeSheetWeekId}`,
      text: "ID Semana",
      hidden: true,
    },
    {
      dataField: "dataCriacao",
      text: t("portalrh.scheduler.day"),
      sort: true,
      onSort: () => handleStatusSort("dataCriacao"),
      hidden: isHidden("dataCriacao"),
      formatter: (_, row, {}) => {
        if (row.dataCriacao !== null && row.dataCriacao !== undefined)
          //return toLocaleString(row.created);
          return toLocaleDateString(row.dataCriacao);
      },
      style: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "nome",
      text: t("portalrh.scheduler.name"),
      sort: true,
      onSort: () => handleStatusSort("name"),
      hidden: isHidden("name"),
      formatter: (_, row, {}) => {
        return row.nome;
      },
      style: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "username",
      text: t("portalrh.scheduler.username"),
      sort: true,
      onSort: () => handleStatusSort("username"),
      hidden: isHidden("username"),
      formatter: (_, row, {}) => {
        return row.username;
      },
      style: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "saldoInicial",
      text: t("portalrh.scheduler.saldoinicial"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("saldoInicial"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "saldoInicial",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "numHorasFormatted",
      text: t("portalrh.scheduler.numHoras"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("numHorasFormatted"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "numHorasFormatted",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "saldoFinal",
      text: t("portalrh.scheduler.saldofinal"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("saldoFinal"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "saldoFinal",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
  ];

  const dropdownListgrupos = rhbolsaHorasUsersGroup.map((item) => {
    return {
      label: item.grupo.designacao,
      value: item.grupo.designacao,
      selected: item.grupo.designacao === grupos,
    };
  });

  const handleSubmitGrupos = (e) => {
    if (grupos != e) {
      setSelectedUser(undefined);
      setActiveButton("");
    }
    setgrupos(e);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();
      e.preventDefault();
      handleOpenFlowModal(row);
    },
  };

  const { SearchBar } = Search;

  const tableContrutor = () => {
    return (
      <>
        {showDrop ? (
          <Row style={{ alignItems: "center" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Form.Label style={{ marginRight: "10px" }}>
                  {t("portalrh.scheduler.groups")}
                </Form.Label>
                <Dropdown
                  haveWidth={true}
                  list={dropdownListgrupos}
                  handleSubmit={handleSubmitGrupos}
                  emptyDefault={true}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                />
              </div>
            </Col>
            {grupos !== "" && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                margin="0px"
              >
                <ClearColorButton
                  size="small"
                  variant="text"
                  className="btnstocleanandprint"
                  startIcon={<FaSearchMinus />}
                  onClick={handleClearFilter}
                >
                  {t("portalrh.scheduler.clean")}
                </ClearColorButton>
              </Stack>
            )}
            {grupos !== "" && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                margin="0px"
              >
                {rhbolsaHorasUsersGroup
                  .filter((group) => group.grupo.designacao === grupos)
                  .map((group) =>
                    group.userList.map((user) => (
                      <UsersButton
                        size="x-small"
                        variant="text"
                        key={user.id}
                        className={`button-scheduler ${
                          activeButton === user.nomeCurto ? "active" : ""
                        }`}
                        onClick={() => handleChooseUser(user)}
                      >
                        {user.nomeCurto}
                      </UsersButton>
                    ))
                  )}
              </Stack>
            )}
          </Row>
        ) : null}
        <div className="custom-table task-table">
          <ToolkitProvider
            keyField="timeSheetWeekId"
            data={saldos_hours != undefined ? saldos_hours : []}
            columns={columns}
            search
          >
            {(props) => (
              <div style={{ paddingBottom: "15px", marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <SearchBar
                    {...props.searchProps}
                    style={{ paddingLeft: "20px" }}
                    placeholder={t("general.search")}
                  />
                </div>
                <div>
                  <Row style={{ paddingRight: "0px" }}>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      {saldosHours_inicialFinal != "" && (
                        <p>
                          {" "}
                          {t("portalrh.scheduler.horaTotal")}{" "}
                          <b>{saldosHours_inicialFinal}</b>
                        </p>
                      )}
                    </Col>
                  </Row>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    noDataIndication={t("portalrh.scheduler.noHoursfound")}
                    sort={{
                      dataField: field,
                      order: order,
                    }}
                    pagination={paginationFactory(pagination)}
                    striped={true}
                    hover={true}
                    rowEvents={rowEvents}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  };

  return (
    <>
      <Sidebar menuSideBar={menuSideBar} path={path} />
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Body>
            {isLoadingSaldo ? (
              <Spinner spinning={isLoadingSaldo} wrapper />
            ) : (
              <>
                <>{tableContrutor()}</>
              </>
            )}
          </Card.Body>
        </Card>
      </div>
      <FlowModal
        showModal={showFlowModal}
        closeModal={handleCloseFlowModal}
        process={selectedProcess}
        isPortal={true}
      />
    </>
  );
};

export default withNamespaces()(BolsaHoras);
