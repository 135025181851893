import {
  SET_AUSENCIAS_BY_EMAIL,
  GET_AUSENCIAS_BY_EMAIL,
  GET_FERIAS_BY_EMAIL,
  SET_FERIAS_BY_EMAIL,
  GET_LOGINS_BY_USERNAME,
  SET_LOGINS_BY_USERNAME,
  GET_AUTORIZACOES_BY_USERNAME,
  SET_AUTORIZACOES_BY_USERNAME,
} from "./actionTypes";

const initialState = {
  ausenciasByEmail: [],
  feriasByEmail: [],
  autorizacoesByUsername: [],
  logins: "",
  isLoadingAusenciasByEmail: false,
  isLoadingAutorizacoesByUsername: false,
  isLoadingLogin: false,
};

export const webTimeDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUSENCIAS_BY_EMAIL:
      return {
        ...state,
        isLoadingAusenciasByEmail: true,
      };
    case SET_AUSENCIAS_BY_EMAIL:
      return {
        ...state,
        isLoadingAusenciasByEmail: false,
        ausenciasByEmail: action.data,
      };
    case GET_FERIAS_BY_EMAIL:
      return {
        ...state,
      };
    case SET_FERIAS_BY_EMAIL:
      return {
        ...state,
        feriasByEmail: action.data,
      };
    case GET_LOGINS_BY_USERNAME:
      return {
        ...state,
        isLoadingLogin: true,
      };
    case SET_LOGINS_BY_USERNAME:
      return {
        ...state,
        logins: action.data,
        isLoadingLogin: false,
      };
    case GET_AUTORIZACOES_BY_USERNAME:
      return {
        ...state,
        isLoadingAutorizacoesByUsername: true,
      };
    case SET_AUTORIZACOES_BY_USERNAME:
      return {
        ...state,
        autorizacoesByUsername: action.data,
        isLoadingAutorizacoesByUsername: false,
      };
    default:
      return state;
  }
};
