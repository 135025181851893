import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { withNamespaces } from "react-i18next";
import Link from "~/pages/Easypay/components/Link";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import "~/pages/Easypay/assets/scss/_dateRangePicker.scss";


/**
 * @param rangeName[] \{ name: string, onClick: () => { start: Date, end: Date } }
 */
const DateRangeSmall = ({
  id,
  dates,
  setDates,
  setShowCalendar,
  clear,
  submit,
  rangeName,
  isRange,
  t
}) => {
  const [state, setState] = useState({items:
    {
      startDate: dates[0],
      endDate: dates[1],
      key: "selection",
    }, done: false});
  
  //stuff for getting language
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const language = userSettings.language;

  useEffect(() => {
    document.addEventListener("click", (e) => {
      handleCloseCalendar(e);
    });

    return () => {
      document.removeEventListener("click", (e) => {
        handleCloseCalendar(e);
      });
    };
  }, []);

  const handleCloseCalendar = (e) => {
    const calendar = document.getElementById(`date-${id}`);
    const input = document.getElementById(`input-date-${id}`);
    if (calendar) {
      if (!calendar.contains(e.target) && !input.contains(e.target)) {
        setShowCalendar(false);
      }
    }
  };

  useEffect(() => setDates([state.items.startDate, state.items.endDate]), [state.items])

  useEffect(() => {
    if (!isRange && state.done) {
      submit();
      setShowCalendar(false);
    }
  }, [dates])

  function printDates() {

    return (
      ( state.items.startDate ? ("0" + state.items.startDate.getDate()).slice(-2) +
      "-" +
      (state.items.startDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      state.items.startDate.getFullYear() : "dd-mm-aaaa") +
      ( isRange ?
        " - " + ( state.items.endDate ?
        ("0" + state.items.endDate.getDate()).slice(-2) +
        "-" + (state.items.endDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" + state.items.endDate.getFullYear(): "dd-mm-aaaa") : "")
    );
  }

  const handleRange = (e) => {
    const date = rangeName[e].onClick()
    setState({ items: { startDate: date.start, endDate: date.end, key: 'selection' } })
  };

  return (
    <div className="liftworld-date-range" id={"date-" + id}>
      <div className="liftworld-date-top">
        { rangeName && rangeName.length != 0 ? 
          <div className="liftworld-date-predefined-ranges">
            {rangeName.map((r, i) => (
              <table key={i}>
                <tbody>
                  <tr className="liftworld-date-predefined-options">
                    <td className="liftworld-date-predefined-option">
                      <button
                        className="liftworld-date-predefined-button"
                        value={r.name}
                        onClick={() => handleRange(i)}
                      >
                        {r.name}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>:<></>
        }
        <div className="liftworld-date-main-calendar">
          <DateRange
            locale={locales[language]}
            editableDateInputs={true}
            onChange={(item) => { item.selection && setState(isRange ? { items: { startDate: item.selection.startDate, endDate: item.selection.endDate, key: "selection" } } : { items: {startDate: item.selection.startDate, endDate: item.selection.startDate, key: "selection" }, done: true }) }}
            moveRangeOnFirstSelection={false}
            dragSelectionEnabled={isRange}
            ranges={[state.items]}
          />
        </div>
      </div>
      <div className="liftworld-date-bottom">
        <div className="liftworld-selected-dates">
          <span className="liftworld-span-selected-dates">{printDates()}</span>
        </div>
        <div className="liftworld-date-buttons">
          <div className="liftworld-date-link">
            <Link
              text={"Cancelar"}
              onClick={() => clear()}
            />
          </div>
          <div className="liftworld-date-select-button">
            <SimpleButton
              variant={"liftworld-button-primary"}
              text={"Selecionar"}
              onClick={() => submit()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(DateRangeSmall);
