import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { Modal, Button, Form } from "react-bootstrap";
import Spinner from "../../../../components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";

import { deleteUser, requestUsers, clearDeleteUser } from "../actions";

const UnikUserTable = ({ users, t, addUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [activeInativeUsers, setActiveInativeUsers] = useState(users);
  const [selectedUser, setSelectedUser] = useState(undefined);

  const { isLoadingUsers, successDeleteUser } = useSelector(
    (state) => state.userManagementReducer
  );

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (successDeleteUser) {
      dispatch(requestUsers({ username: "" }));
      dispatch(clearDeleteUser());
    }
  }, [successDeleteUser]);

  useEffect(()=>{
    if(!checkbox){
      setActiveInativeUsers(users)
    } else{
      setActiveInativeUsers(users.filter(item => item.enable == false))
    }
  },[checkbox, users])

  const apagarUtilizador = () => {
    const payload = {
      id: selectedUser.id,
    };
    dispatch(deleteUser(payload));
    handleClose();
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setShow(true);
  };

  const buildActionsColumn = (_, row, {}) => (
    <>
      <i
        className={`icon-trash ${
          !row.enable ? "table-unikuser-action-icon" : "table-action-icon"
        } `}
        onClick={() => openModal(row)}
      />
      <i
        className={`icon-edit-user ${
          !row.enable ? "table-unikuser-action-icon" : "table-action-icon"
        }`}
        onClick={() =>
          history.push(`/uniksystem/admin/edit-unikuser/${row.id}`)
        }
      />
    </>
  );

  const { SearchBar } = Search;
  //TODO: validar o enabled
  const columns = [
    {
      dataField: "username",
      text: t("admin.userManagement.list.username"),
      sort: true,
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          !row.enable && `#AD3E3E${Math.floor(opacity * 255).toString(16)}`;
        const color = !row.enable && "#AD3E3E";
        return {
          textAlignLast: "center",
          backgroundColor,
          color,
        };
      },
    },
    {
      dataField: "firstName",
      text: t("admin.userManagement.list.name"),
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => (
        <> {`${row.firstName} ${row.lastName}`} </>
      ),
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          !row.enable && `#AD3E3E${Math.floor(opacity * 255).toString(16)}`;
        const color = !row.enable && "#AD3E3E";
        return {
          textAlignLast: "center",
          backgroundColor,
          color,
        };
      },
    },
    {
      dataField: "lastName",
      hidden: true,
    },
    {
      dataField: "organizationName",
      text: t("admin.userManagement.list.organization"),
      formatter: (cell, row, rowIndex, extraData) => (
        <> {`${row?.organizationName || ""}`} </>
      ),
      sort: true,
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          !row.enable && `#AD3E3E${Math.floor(opacity * 255).toString(16)}`;
        const color = !row.enable && "#AD3E3E";
        return {
          textAlignLast: "center",
          backgroundColor,
          color,
        };
      },
    },
    {
      dataField: "accao",
      text: t("admin.userManagement.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (column, columnIndex) => {
        return { width: "80px", textAlign: "center" };
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          !row.enable && `#AD3E3E${Math.floor(opacity * 255).toString(16)}`;
        const color = !row.enable && "#AD3E3E";
        return {
          textAlignLast: "center",
          backgroundColor,
          color,
        };
      },
    },
  ];

  const filterUsers = ()=>{
    setCheckbox(!checkbox)
  }

  return (
    <>
      <div className="custom-table custom-table-small">
        <ToolkitProvider
          keyField="id"
          data={activeInativeUsers}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ paddingBottom: "20px", display: "flex", alignItems:"center" }}>
                <SearchBar
                  {...props.searchProps}
                  placeholder={t("admin.userManagement.list.searchInput")}
                />
                <div style={{ paddingLeft: "30px", marginBottom:'0.5rem' }}>
                  <ButtonAdd text={t("general.add")} onClick={addUser} />
                </div>
                <div style={{ paddingLeft: "30px", marginBottom:'0.5rem' }}>
                  <Form.Check
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={t("admin.userManagement.list.checkbox")}
                    value={checkbox}
                    onChange={filterUsers}
                  />
                </div>
              </div>
              <Spinner spinning={isLoadingUsers} />
              {!isLoadingUsers && (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("admin.userManagement.list.emptyTable")}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        {selectedUser !== undefined ? (
          <Modal.Body>
            {`Apagar utilizador: ${selectedUser.firstName} ${selectedUser.lastName}?`}
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("general.back")}
          </Button>
          <Button variant="danger" onClick={() => apagarUtilizador()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withNamespaces()(UnikUserTable);
