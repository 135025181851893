import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import { withNamespaces } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { getCollaboratorData } from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { getRHAdminConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";
import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { Button } from "react-bootstrap";
import oauth from "~/utils/oauth";
import { getIflowFormdata } from "store/ducks/processes/actionTypes";
import FlowModal from "~/components/FlowModal";
import AddButton from "~/components/Buttons/AddButton";

function TableRhCollaborator({ onTakeNavOnBolean, t }) {
  const [collaboratorsFiltered, setCollaboratorsFiltered] = useState([]);
  const [rhadminConfig, setrhadminConfig] = useState({});
  const [showFlowModal, setShowFlowModal] = useState(false);

  const dispatch = useDispatch();
  const { collaborators } = useSelector((state) => state.portalrhReducer);
  const { collaboratorData } = useSelector((state) => state.personalReducer);
  const { organization } = useSelector((state) => state.organizationsReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { rhadminConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOrganizationById(user?.organization?.id));
    }
    dispatch(getCollaborators());
    dispatch(getCollaboratorData());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(organization).length > 0)
      dispatch(getRHAdminConfiguration(organization?.id));
  }, [organization]);

  useEffect(() => {
    if (Object.keys(rhadminConfiguration).length > 0) {
      setrhadminConfig(rhadminConfiguration.body);
    }
  }, [rhadminConfiguration]);

  //adding new propertie lepPssNum and filtering
  useEffect(() => {
    if (collaboratorData.length > 0 && collaborators.length > 0) {
      for (let i = 0; i < collaboratorData.length; i++) {
        for (let k = 0; k < collaborators.length; k++) {
          if (collaboratorData[i].lepNum === collaborators[k].funcionarioNum) {
            collaborators[k].pssNum = collaboratorData[i].lepPssNum;
            let newCollab = collaborators.filter((e) => e.funcionarioNum);
            setCollaboratorsFiltered(newCollab);
          }
        }
      }
    }
  }, [collaboratorData, collaborators]);

  // useEffect(() => {
  //   if (collaborators.length > 0) {
  //     let newCollab = collaborators.filter((e) => e.funcionarioNum);
  //     setCollaboratorsFiltered(newCollab);
  //   }
  // }, [collaborators]);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "username",
      text: "Username",
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "funcionarioNum",
      text: t(`portalrh.NavBar.collaboratorNum`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "nome",
      text: t(`portalrh.NavBar.Name`),
      sort: true,
      style: { fontSize: "12px" },
    },
  ];

  const rowEventEditMod = {
    onClick: (e, row, rowIndex) => {
      onTakeNavOnBolean(true, row);
    },
  };

  const closeModal = () => {
    setShowFlowModal(false);
  };

  const handleClickStartFlow = () => {
    const payload = {
      url:
        "/inicio_flow.jsp?" +
        "flowid=" +
        rhadminConfig.flowId +
        "&Authorization=" +
        oauth.getAccessToken(),
    };
    dispatch(getIflowFormdata(payload));
    setShowFlowModal(true);
  };

  const rowStyle = { cursor: "pointer" };
  return (
    <div className="custom-table custom-table-small cursor-pointer">
      {collaboratorsFiltered.length > 0 ? (
        <ToolkitProvider
          keyField="id"
          data={collaboratorsFiltered}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ display: "flex" }}>
                <SearchBar {...props.searchProps} />
                {rhadminConfig && rhadminConfig != null && (
                  
                  <div style={{marginLeft:'20px'}}>
                  <AddButton text={t("general.add")} onClick={() => handleClickStartFlow()} />
                </div>
                )}
              </div>
              <hr />
              <BootstrapTable
                {...props.baseProps}
                rowEvents={rowEventEditMod}
                striped
                hover
                condensed
                bootstrap4
                noDataIndication="Data not found"
                rowStyle={rowStyle}
                pagination={
                  collaborators.length > 10
                    ? paginationFactory(pagination)
                    : null
                }
              />
            </div>
          )}
        </ToolkitProvider>
      ) : null}
      <FlowModal
        showModal={showFlowModal}
        closeModal={closeModal}
        isPortal={true}
      />
    </div>
  );
}

export default withNamespaces()(TableRhCollaborator);
