import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Dropdown,
  ButtonGroup,
  Tooltip,
  OverlayTrigger,
  Badge,
} from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import SourceHandler from "./SourceHandler";
import { updateUserWidget } from "~/pages/User/actions";

import widgetplaceholder from "~/assets/img/widgets/widget-placeholder.jpg";
import powerBiError from "~/assets/img/widgets/NoAccess_PowerBI.png";
import "~/assets/css/icons.css";
import "~/assets/css/styles.css";
import {FaColumns} from "react-icons/fa"

const ServicoGenericoWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  index,
  height,
  width,
  ...props
}) => {
  const { t } = props;
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);
  
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  let itemsToshow = [];

  const isConfigAllowed = (config) => {
    if (user.username === "admin") return true;
    if (user.roles.some((role) => role.label === "ADMIN")) return true;
    if (
      config.roles === undefined ||
      config.roles === null ||
      config.roles.length === 0
    )
      return true;
    else {
      let isAllowed = false;
      config.roles.forEach((configRoleId) => {
        if (user && user.roles && user.roles.some((r) => r.id === configRoleId))
          isAllowed = true;
      });
      return isAllowed;
    }
  };

  if (user && user.roles) {
    const allConfigurations = widget.widget.configurations
      ? widget.widget.configurations
      : [];
    const configurationsByApp = allConfigurations.filter((item) =>
      item.apps.some((app) => app.pathname === path)
    );
    configurationsByApp.forEach((config) => {
      if (isConfigAllowed(config)) itemsToshow.push(config);
    });
  }

  const [source, setSource] = useState(undefined);
  const [isConfigNeeded, setIsConfigNeeded] = useState(true);
  const [isConfiguring, setIsConfiguring] = useState(false);

  const [errorMessage, setErrorMessage] = useState([]);
  const [widgetTitle, setwidgetTitle] = useState("");

  useEffect(() => {
    if (source != undefined) {
      const languageSystem = userSettings?.language.trim();
      if (languageSystem) {
        if (source.languages) {
          const userLanguage = source.languages.find(
            (lang) => lang.language === languageSystem
          );
          if(userLanguage == undefined){
            setwidgetTitle(source.name);
          } else{
            setwidgetTitle(userLanguage.translation);
          }
          
        } else {
          setwidgetTitle(source.name);
        }
      } else {
        setwidgetTitle(source.name);
      }
    }
  }, [source, userSettings]);

  useEffect(() => {
    setSource(undefined); //clean state
    setErrorMessage([]); //clean state
    if (widget.userConfiguration && widget.userConfiguration.configurationId) {
      const configId = widget.userConfiguration.configurationId;
      const source_ = widget.widget.configurations.find(
        (item) => item.id === configId
      );
      if (isConfigAllowed(source_)) {
        setSource(source_);
        setIsConfiguring(false); //clean state
        setIsConfigNeeded(false);
      } else setIsConfigNeeded(true);
    } else setIsConfigNeeded(true);
  }, [widget]);

  const configurar = () => {
    setIsConfiguring(true);
  };

  const cancel = () => {
    setIsConfiguring(false);
    if (widget.userConfiguration && widget.userConfiguration.configurationId) {
      const configId = widget.userConfiguration.configurationId;
      const source_ = widget.widget.configurations.find(
        (item) => item.id === configId
      );
      setSource(source_);
      setIsConfigNeeded(false);
    } else {
      setIsConfigNeeded(true);
      setSource(undefined);
    }
  };

  const save = () => {
    const payload = {
      id: widget.id,
      widgetId: widget.widgetId,
      userConfiguration: {
        type: ".PowerBIUserConfiguration",
        configurationId: source ? source.id : null,
        widthSize: width,
      },
    };
    dispatch(updateUserWidget(payload));
    setIsConfiguring(false);
    source ? setIsConfigNeeded(false) : setIsConfigNeeded(true);
  };

  const ListForm = () => {
    const { SearchBar } = Search;

    const buildApplicationColumn = (_, row) => {
      const dashboardApps = row.apps.filter((app) =>
        app.description.includes("Dashboard")
      );
      const otherApps = row.apps.filter(
        (app) => !app.description.includes("Dashboard")
      );

      return (
        <>
          {otherApps.map((app, index) => (
            <Badge
              pill
              key={index}
              variant="info"
              className="pointer lg-badge mr-2"
            >
              {app.description}
            </Badge>
          ))}
          {dashboardApps.length > 0 && (
            <Badge pill variant="info" className="pointer lg-badge mr-2">
              Dashboard
            </Badge>
          )}
        </>
      );
    };
    const columns = [
      {
        dataField: "name",
        text: t("ServicoGenericoWidget.sourceName"),
        sort: true,
      },
      {
        dataField: "apps",
        sort: true,
        text: t("ServicoGenericoWidget.application"),
        formatter: buildApplicationColumn,
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        setSource(row);
      },
    };

    return (
      <div style={{ overflowY: "scroll", height: "100%", padding: "10px" }}>
        {source ? (
          <span style={{ color: "#1f841f" }}>
            {t("ServicoGenericoWidget.selectedSource")}: &nbsp;
            <strong>{widgetTitle}</strong>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="favoritos_tooltip">
                  {t("ServicoGenericoWidget.removeSource")}
                </Tooltip>
              }
            >
              <i
                className="icon-trash table-action-icon ml-5"
                onClick={() => {
                  setSource(undefined);
                }}
              ></i>
            </OverlayTrigger>
          </span>
        ) : (
          <span style={{ color: "#ca1717" }}>
            {t("ServicoGenericoWidget.noSourceSelected")}
          </span>
        )}
        <hr />
        <ToolkitProvider
          keyField="name"
          data={itemsToshow}
          columns={columns}
          search={{
            defaultSearch: source ? widgetTitle : "",
          }}
        >
          {(props) => (
            <div style={{ width: "97%" }}>
              <SearchBar
                {...props.searchProps}
                placeholder={t("ServicoGenericoWidget.search")}
              />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory()}
                noDataIndication={t("ServicoGenericoWidget.sourceNotFound")}
                striped
                hover
                rowEvents={rowEvents}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  };

  return (
    <div className="main-card-v2" id="powerBI">
      <Card
        bsPrefix="card-flat"
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Card.Header
          className="justify-content-between"
          style={{ paddingBottom: "20px" }}
        >
          <h6>
            <i className="icon-bell mr-2" />
            {/*widget.id*/}
            {isConfiguring
              ? t("ServicoGenericoWidget.configuration")
              : source
              ? widgetTitle
              : t("ServicoGenericoWidget.title")}
          </h6>
          <Dropdown as={ButtonGroup} alignRight>
            <Dropdown.Toggle variant="link">
              <i className="icon-gear" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Dropdown.Item onClick={apagarWidget}>
                {t("widgets.remove")}
              </Dropdown.Item>
              <Dropdown.Item onClick={configurar}>
                {t("widgets.configure")}
              </Dropdown.Item>
              <Dropdown.Divider /> {/* Adiciona um separador */}
              <Dropdown>
                <Dropdown.Toggle variant="link">
                  {t("widgets.resize")}
                  <FaColumns style={{ marginLeft: "10px" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: width=="4" ? '150px' : '', overflowY: 'scroll' }} className="scrollBar-visible-dropdown">
                  <Dropdown.Item
                    onClick={() => handleMouseDown(4)}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {t("widgets.resizeSmall")} {" "}
                    <div
                      style={{
                        justifyContent: "flex-end",
                        width: "10px",
                        borderRadius: "5px",
                        background: "black",
                      }}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleMouseDown(5)}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                   {t("widgets.resizeHalfPage")} {" "}
                    <div
                      style={{
                        justifyContent: "flex-end",
                        width: "15px",
                        borderRadius: "5px",
                        background: "black",
                      }}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleMouseDown(6)}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {t("widgets.resizeSemiMedium")} {" "}
                    <div
                      style={{
                        justifyContent: "flex-end",
                        width: "15px",
                        borderRadius: "5px",
                        background: "black",
                      }}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleMouseDown(8)}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {t("widgets.resizeMedium")}{" "}
                    <div
                      style={{
                        justifyContent: "flex-end",
                        width: "20px",
                        borderRadius: "5px",
                        background: "black",
                      }}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleMouseDown(12)}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {t("widgets.resizeGigant")}{" "}
                    <div
                      style={{
                        justifyContent: "flex-end",
                        width: "30px",
                        borderRadius: "5px",
                        background: "black",
                      }}
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Dropdown>
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ padding: "10px" }}>
            {isConfiguring ? (
              <ListForm />
            ) : isConfigNeeded ? (
              <div
                onClick={configurar}
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h3>{t("widgets.configure")}</h3>
                <img
                  style={{
                    borderRadius: "3%",
                    width: "300px",
                    height: "auto",
                    float: "left",
                  }}
                  src={widgetplaceholder}
                  alt="Widget"
                />
              </div>
            ) : (
              <>
                {errorMessage.length !== 0 ? (
                  <div
                    id={`reportContainerError${widget.id}`}
                    className="reportContainer"
                  >
                    {errorMessage.some((e) => e === "401") ? (
                      <img src={powerBiError} alt="powerbi-error" />
                    ) : (
                      errorMessage
                    )}
                  </div>
                ) : (
                  <SourceHandler
                    widget={widget}
                    setErrorMessage={setErrorMessage}
                    height={height}
                  />
                )}
              </>
            )}
          </Card.Body>
        )}
        {isConfiguring ? (
          <Card.Footer>
            <Button variant="danger" onClick={cancel}>
              {t("general.cancel")}
            </Button>

            <Button
              className="card-button"
              style={{ marginLeft: "10px" }}
              onClick={save}
            >
              {t("ServicoGenericoWidget.save")}
            </Button>
          </Card.Footer>
        ) : null}
      </Card>
    </div>
  );
};
export default withNamespaces()(ServicoGenericoWidget);
