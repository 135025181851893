import React, { useState, useEffect } from "react";
import CreateEndProcessBadge from "./CreateEndProcessBadge";
import CreateCloseProcessButton from "./CreateCloseProcessButton";
import { withNamespaces } from "react-i18next";
import { parseHtmlText } from "../utils";
import CreateAlert from "./CreateAlert";

const CreateEndPage = (props) => {
  const { t, isModal, jsonData, styleSheet} = props;
  const [badgeText, setBadgeText] = useState("");

  useEffect(() => {
    if (jsonData && jsonData.responseMessage) {
      let text = "";
      if (jsonData.responseMessage.includes('<div class="alert alert-info">')) {
        text = parseHtmlText(
          jsonData.responseMessage,
          '<div class="alert alert-info">',
          "</div>"
        );
      }

      if (text) {
        setBadgeText(text);
      }
    }
  }, [jsonData]);

  let field = {
    text: badgeText ? badgeText : t("taskPage.processCompleted"),
  };

  return (
    <>
      {!isModal && styleSheet?.processCloseButton && (
        <CreateCloseProcessButton className="close-button_custom" />
      )}
      <div
        style={{
          marginTop: "0",
        }}
      >
        {styleSheet?.badgesMessage ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CreateEndProcessBadge
              badgeText={badgeText}
              badgeTextDefault={t("taskforward.processCompleted")}
              className="badge-success"
            />
          </div>
        ) : (
          <CreateAlert field={field} variant="info" />
        )}
        <br />
      </div>
    </>
  );
};

export default withNamespaces()(CreateEndPage);
