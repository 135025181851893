export const docs = [
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 1,
  },
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 0,
  },
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 3,
  },
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 0,
  },
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 5,
  },
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 5,
  },
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 5,
  },
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 5,
  },
  {
    statusIcon: true,
    idInfo: "12345",
    info1: "Name 1",
    info2: "Name 2",
    info3: "Info 3",
    info4: "Info 4",
    info5: "Info 5",
    date: "27-05-2021",
    info6: "Info 6",
    info7: "Info 7",
    info8: "Info 8",
    info9: { number: "000", rejected: true },
    status: 5,
  },
];

export let list = [
  {
    value: "drop1",
    label: "Dropdown item 1",
    id: "dropdown1",
    selected: false,
  },
  {
    value: "drop2",
    label: "Dropdown item 2",
    id: "dropdown2",
    selected: false,
  },
  {
    value: "drop3",
    label: "Dropdown item 3",
    id: "dropdown3",
    selected: false,
  },
  {
    value: "drop4",
    label: "Dropdown item 4",
    id: "dropdown4",
    selected: false,
  },
  {
    value: "drop5",
    label: "Dropdown item 5",
    id: "dropdown5",
    selected: false,
  },
  {
    value: "drop6",
    label: "Dropdown item 6",
    id: "dropdown6",
    selected: false,
  },
];

export const columns = [
  {
    dataField: "statusIcon",
  },
  {
    dataField: "info1",
    text: "ID Info",
    sort: true,
  },
  {
    dataField: "info2",
    text: "Info 2",
    sort: true,
  },
  {
    dataField: "info3",
    text: "Info 3",
    sort: true,
  },
  {
    dataField: "info4",
    text: "Info 4",
    sort: true,
  },
  {
    dataField: "info5",
    text: "Info 5",
    sort: true,
  },
  {
    dataField: "date",
    text: "Date",
    sort: true,
  },
  {
    dataField: "info6",
    text: "Info 6",
    sort: true,
  },
  {
    dataField: "info7",
    text: "Info 7",
    sort: true,
  },
  {
    dataField: "info8",
    text: "Info 8",
    sort: true,
  },
  {
    dataField: "info9",
    text: "Info 9",
    formatter: (cell, row) => {
      return "blue";
    },
  },

  {
    dataField: "status",
    hidden: true,
  },
];

export const documentsList = [
  { documentName: "Cartão de cidadão", holderName: "António Marques" },
  { documentName: "Carta de condução", holderName: "Francisco Carvalho" },
  { documentName: "Boletim", holderName: "" },
  { documentName: "Fatura da luz", holderName: "Manuel Santos" },
];

export const accordions = [
  {
    badge: "A",
    title: "Title of A",
    content: "",
    role: "Gerente",
    submitted: true,
    open: false,
  },
  {
    badge: "B",
    title: "Title of B",
    content: "3",
    role: "Estagiário",
    submitted: false,
    open: false,
  },
  {
    badge: "C",
    title: "Title of C",
    content: "1",
    role: "Aprendiz",
    submitted: false,
    open: false,
  },
  {
    badge: "D",
    title: "Title of D",
    content: "checked",
    role: "Gerente",
    submitted: true,
    open: false,
  },
];

export const verticalItems = [
  { name: "item1", text: "Active Item", badge: "", content: "Content 1" },
  { name: "item2", text: "Item w/badge", badge: "3", content: "Content 1" },
  { name: "item3", text: "Item w/badge", badge: "1", content: "Content 1" },
  {
    name: "item4",
    text: "Checked Item",
    badge: "easypay-checked",
    content: "Content 1",
  },
];

export const breadcrumbItems = [
  { text: "Level 01", disabled: false },
  { text: "Level 02", disabled: false },
  { text: "Level 03", disabled: true },
];

export const bulletList = ["false", "false", "true"];

export const jsonData = require("./get_all_merchants.json").data;

export default {
  docs,
  list,
  columns,
  documentsList,
  accordions,
  verticalItems,
  breadcrumbItems,
  bulletList,
  jsonData,
};
