import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { IconContext } from "react-icons";
import { IoMdHelpCircle } from "react-icons/io";

const Helper = ({ data }) => {
  return (
    <IconContext.Provider value={{ size: "1.4em" }}>
      <div style={{ margin: "0 0 0 10px" }}>
        <OverlayTrigger
          placement="right"
          overlay={
            <Popover id="popover-basic">
              <Popover.Content style={{ whiteSpace: "pre-line" }}>
                {<div>{data.value}</div>}
              </Popover.Content>
            </Popover>
          }
        >
          <IoMdHelpCircle />
        </OverlayTrigger>
      </div>
    </IconContext.Provider>
  );
};

export default Helper;
