import { takeLatest } from "redux-saga/effects";

import {
  GET_RHADMIN_CONFIGURATION,
  PERSIST_RHADMIN_CONFIGURATION,
  GET_RH_BOLSA_HORAS_CONFIGURATION,
  PERSIST_RH_BOLSA_HORAS_CONFIGURATION,
  GET_RH_BOLSA_HORAS_USERS_GROUP,
  GET_RH_SALDO_BOLSA_HORAS,
  GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS
} from "../ducks/rhadminConfiguration/actionTypes";

import {
  getRHAdminConfiguration,
  persistRHAdminConfiguration,
  getRHBolsaHorasConfiguration,
  persistRHBolsaHorasConfiguration,
  getRHBolsaHorasUsersGroup,
  getRHSaldoBolsaHoras,
  getRHSaldoInicialFinalBolsaHoras
} from "../ducks/rhadminConfiguration/sagas";

export const rhadminConfigurationSagas = [
  takeLatest(GET_RHADMIN_CONFIGURATION, getRHAdminConfiguration),
  takeLatest(PERSIST_RHADMIN_CONFIGURATION, persistRHAdminConfiguration),
  //BOLSA DE HORAS
  takeLatest(GET_RH_BOLSA_HORAS_CONFIGURATION, getRHBolsaHorasConfiguration),
  takeLatest(GET_RH_BOLSA_HORAS_USERS_GROUP, getRHBolsaHorasUsersGroup),
  takeLatest(GET_RH_SALDO_BOLSA_HORAS, getRHSaldoBolsaHoras),
  takeLatest(GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS, getRHSaldoInicialFinalBolsaHoras),
  takeLatest(
    PERSIST_RH_BOLSA_HORAS_CONFIGURATION,
    persistRHBolsaHorasConfiguration
  ),
];
