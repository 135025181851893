import React, { useState, useEffect } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_item.scss";
import TextAreaInput from "../components/TextAreaInput";
import uuid from "uuid/v1";
import ItemComponent from "./ItemComponent";
import DocumentUploadList from "./DocumentUploadList";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";
import Checkbox from "./Checkbox";
import { useDispatch, useSelector } from "react-redux";

const Item = ({ list, workplanIndex }) => {

  const dispatch = useDispatch();

  const [isOpenList, setIsOpenList] = useState([]);

  const { workplans } = useSelector(
    (state) => state.budgetManagementReducer
  );

  useEffect(() => {
    var newList = isOpenList;
    for (var i = 0; i < list.length; i++) {
      if (!(newList[i] != null && newList[i] != undefined)) {
        newList.push(1);
      }
    }
    setIsOpenList(newList);
  }, []);

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  function checkForMoreThanOneOption()
  {
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        if(workplans[workplanIndex].items[i].atividades[j].opcoes.length > 1)
        {
          return false
        }
      }
    }
    return true
  }

  return (
    <div className="liftworld-item-main-wrapper">
      <div style={{ width: "100%", padding: "30px" }}>
        <TextAreaInput
          field={buildInputField("Descritivo do Projeto/Objetivo Geral (Não se reflete no Orçamento)",workplans[workplanIndex].descricaoResumoFinanceiro)}
          key={uuid()}
          name={"descricaoResumoFinanceiro"}
          workplanIndex={workplanIndex}
          size = {"normal"}
          color = {"color"}
        />
        <div style={{ paddingTop: 15 }} />
        <div className="liftworld-image-text-wrapper">
          <div className="liftworld-text-wrapper">Documentos</div>
        </div>
        <DocumentUploadList workplanIndex = {workplanIndex}></DocumentUploadList>
      </div>
      {checkForMoreThanOneOption() && <div className="liftworld-item-option-row-wrapper-end">
        <div style={{ display: "flex" }}>
          <div className="liftworld-text-wrapper">Não Imprimir</div>{" "}
          <Checkbox
            value={""}
            name={"option"}
            checked={workplans[workplanIndex].imprimir}
            workplanIndex={workplanIndex}
            isPrint={true}
          />
        </div>
      </div>}
      {list &&
        Object.keys(list).length !== 0 &&
        list.map((item, index) => {
          return (
            <>
              <ItemComponent item = {item} index = {index} workplanIndex = {workplanIndex}/>
            </>
          );
        })}
    </div>
  );
};
export default Item;
