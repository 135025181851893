import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { pingMiddlewareApplication } from "~/store/ducks/systemSettings/applications/actionTypes";
import { FIN_MIDDLEWARE } from '~/utils/constants';
import { TabsKey } from '../../utils/TabsEnum';
import ApplicationCard from "./ApplicationCard";


const FinanceiraComponent = ({ tabKey, t }) => {

  const dispatch = useDispatch();

  const {
    isFinanceiraON,
    loadingFinanceira
  } = useSelector(state => state.systemSettingsApplicationsReducer);

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATIONS) {
      dispatch(pingMiddlewareApplication(FIN_MIDDLEWARE));
    }
  }, [tabKey])

  return (
    <ApplicationCard
      name="Financeira"
      status={isFinanceiraON}
      loading={loadingFinanceira}
      showMW={true}
    />
  )
}

export default withNamespaces()(FinanceiraComponent);
