import React from "react";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { pagination } from "~/components/pagination/currentSizePerPage/8";

const UserTable = (props) => {
  const { isLoadingUsers } = useSelector((state) => state.roleReducer);

  const { t, users = [], action, actionIcon } = props;

  const buildActionsColumn = (_, row, {}) => (
    <i
      className={`${actionIcon} table-action-icon`}
      onClick={() => action(row)}
    />
  );
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "username",
      text: t("admin.userManagement.list.username"),
      sort: true,
      style: {
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "detail.firstName",
      text: t("admin.userManagement.list.name"),
      sort: true,
      formatter: (cell, row) => (
        <> {`${row.detail?.firstName} ${row.detail?.lastName}`} </>
      ),
      style: {
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "detail.lastName",
      hidden: true,
    },
    {
      dataField: "accao",
      text: t("admin.role.list.action"),
      formatter: buildActionsColumn,
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  const users_ = users.filter((user) => user.username !== "admin");

  return (
    <div className="custom-table">
      <ToolkitProvider keyField="id" data={users_} columns={columns} search>
        {(props) => (
          <div>
            <SearchBar
              {...props.searchProps}
              placeholder={t("general.search")}
            />
            <hr />
            {!isLoadingUsers && (
              <BootstrapTable
                key={uuid()}
                {...props.baseProps}
                bootstrap4
                noDataIndication={t("admin.role.usermodal.emptyTable")}
                pagination={paginationFactory(pagination)}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};
export default withNamespaces()(UserTable);
