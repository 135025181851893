import { put } from 'redux-saga/effects';
import http from '~/utils/config/http';

import { setMenuOnlyApps, setLogoApplication } from './actionTypes';
import { activeError } from '~/components/Messages/actions';

export function* loadMenuOnlyApps() {
  try {
    const resp = yield http.get('/api/application/menu-only/find');
    yield put(setMenuOnlyApps(resp.data));
  } catch (error) {
    yield put(activeError('error'));
  }
}

export function* changeLogoApplication({ payload }) {
  const applicationJson = JSON.stringify(payload.application);
  const applicationBlog = new Blob([applicationJson], {
    type: 'application/json'
  });
  const _data = new FormData();
  _data.append("logo", payload.logo);
  _data.append("application", applicationBlog);
  try {
    const resp = yield http.put('/api/application/change-logo', _data);
    yield put(setLogoApplication(resp.data));
  } catch (error) {
    yield put(activeError('error'));
  }
}
