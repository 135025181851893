import React, { useEffect } from "react";
import { withNamespaces } from 'react-i18next';
import "../assets/img/icons/flags/flags.css";
import "../assets/scss/_typography.scss";

const CountrySelected = ({ icon, label, setShowCountryModal, t }) => {
  useEffect(() => {
    if (document.dados.paisOrganizacao) {
      document.dados.paisOrganizacao.value = icon.replace(/ /g, "");
    }
  }, [icon]);

  return (
    <>
      <div className="easypay-country-selected-wrapper">
        <div className="easypay-text-wrapper">
          <div
            className={`easypay-country-flag easypay-${icon.replace(/ /g, "")}`}
          />
          <div className={`easypay-body`}>{label}</div>
        </div>

        <button
          className="easypay-link-primary"
          onClick={() => setShowCountryModal(true)}
          type="button"
        >
          {t("bpm.onboarding.user.linkChange")}
        </button>
      </div>
      <div style={{ height: "0px" }}>
        <input
          style={{
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
            width: "1px",
            height: "1px",
          }}
          type="text"
          name="paisOrganizacao"
          id=""
        />
      </div>
    </>
  );
};

export default withNamespaces()(CountrySelected);
