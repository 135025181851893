import Chart from "chart.js";
import React, { useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import "~/assets/css/icons.css";
import { toLocaleDateString } from "~/utils";
import { barChart } from "./chartDetail.js";

const Digest = ({ apagarWidget, reviewedDocs, ...props }) => {
  const { t } = props;
  const myRef = useRef(null);

  function formatDate(input) {
    const datePart = input.match(/\d+/g),
      year = datePart[0], 
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  useEffect(() => {
    const myChartRef = myRef.current.getContext("2d");
    new Chart(myChartRef, barChart);
  }, []);

  useEffect(() => {
    const info = [];

    for (let i = 6; i >= 0; i--) {
      const date = toLocaleDateString(
        new Date(Date.now() - i * 24 * 60 * 60 * 1000)
      );
      info.push({ date: date, n: 0 });
      reviewedDocs.forEach((doc) => {
        if (formatDate(doc.createdDate.split("T")[0]) === date) {
          info[6 - i].n++;
        }
      });
    }

    const data = info.map((e) => {
      return e.n;
    });

    const labels = info.map((e) => {
      return e.date;
    });

    const maxValue = Math.max(...data);
    if (maxValue <= 1) barChart.options.scales.yAxes[0].ticks.maxTicksLimit = 1;
    if (maxValue < 10)
      barChart.options.scales.yAxes[0].ticks.maxTicksLimit = maxValue;
    barChart.data.labels = labels;
    barChart.data.datasets[0].data = data;
    barChart.data.datasets[0].label = "Processed";
    const myChartRef = myRef.current.getContext("2d");
    new Chart(myChartRef, barChart);
  });

  return <canvas id="myAreaChart" ref={myRef}></canvas>;
};
export default withNamespaces()(Digest);
