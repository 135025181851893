import React, { useState, useEffect } from "react";
import "../assets/scss/_liftworldTable.scss";
import LiftworldTableInput from "./LiftworldTableInput";
import Dropdown from "./TableInputDropdown";
import TableTextbox from "./TableTextbox";
import { useDispatch, useSelector } from "react-redux";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import uuid from "uuid/v1";
import {
  setAllWorkplans,
  saveProvider,
  getAllProviders,
  setProvider
} from "~/store/ducks/budgetManagement/actionTypes";
import iconMinus from "~/pages/Liftworld/assets/img/minus-icon.png";
import InputSearch from "~/pages/Liftworld/components/InputSearch";
import Input from "./Input";
import Modal from "~/pages/Easypay/components/Modal";

const LiftworldCostsTable = ({
  list,
  typeList,
  itemIndex,
  activityIndex,
  optionIndex,
  workplanIndex,
}) => {
  const dispatch = useDispatch();

  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [newClientName, setNewClientName] = useState("");
  const [newClientAddress, setNewClientAddress] = useState("");
  const [newClientPayment, setNewClientPayment] = useState("");

  const [currentSupplierIndex, setCurrentSupplierIndex] = useState();

  const { workplans, providers, departments, expenditureTypes, provider } =
    useSelector((state) => state.budgetManagementReducer);

  useEffect(() => {
    dispatch(getAllProviders(workplans[workplanIndex].organizationId));
    if (
      provider.numFornecedor != undefined &&
      provider.idFornecedor != undefined &&
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].despesas[currentSupplierIndex]
    ) {
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].despesas[currentSupplierIndex].fornecedorDesp =
        provider.idFornecedor;
      dispatch(setAllWorkplans(workplans));
      setProvider({})
    }
  }, [provider]);

  function addNewProvider() {
    dispatch(
      saveProvider({
        nome: newClientName,
        prazoPagamento: newClientPayment,
        morada: newClientAddress,
        orgId: workplans[workplanIndex].organizationId,
      })
    );
    setNewClientName("");
    setNewClientAddress("");
    setNewClientPayment("");
    setIsModalErrorShowing(false);
  }

  function buildNameArray(info) {
    var newArray = [];
    for (var i = 0; i < info.length; i++) {
      newArray.push({
        label: info[i].nome,
        value: info[i].nome,
        selected: false,
      });
    }
    return newArray;
  }
  function buildInputField(text) {
    return {
      value: text,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: "",
    };
  }

  function removeRow(index) {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    var newArray =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].despesas;
    newArray.splice(index, 1);
    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].despesas = newArray;
    dispatch(setAllWorkplans(workplans));
  }

  function handleTableChange(e, currentIndex, name) {
    if (e === "A designar" && name === "fornecedorDesp") {
      setIsModalErrorShowing(true);
      return;
    }
    if (itemIndex != null && activityIndex != null && optionIndex != null) {
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].despesas[currentIndex][name] = getIdFromName(
        e,
        name
      );
      const currentVendaDesp =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].despesas[currentIndex].precoVendaDesp;
      if (
        name === "tipoDespesa" &&
        (currentVendaDesp === undefined ||
          currentVendaDesp === "" ||
          currentVendaDesp === 0)
      ) {
        const expType = expenditureTypes.find(
          (x) => x.idTipoDespesa === parseInt(getIdFromName(e, name))
        );
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].despesas[currentIndex].margemDesp =
          expType.percentagem;
      }
      dispatch(setAllWorkplans(workplans));
    }
  }
  function getPosition(index) {
    if (index === 0) return "start";
    if (index === list.length - 1) return "end";
  }

  function getIdFromName(e, name) {
    if (name === "fornecedorDesp") {
      const prov = providers.find((x) => x.nome === e);
      if (prov != null) return prov?.idFornecedor;
    } else if (name === "tipoDespesa") {
      const exp = expenditureTypes.find((x) => x.nome === e);
      if (exp != null) return exp?.idTipoDespesa;
    } else if (name === "departamentoDesp") {
      const dep = departments.find((x) => x.nome === e);
      if (dep != null) return dep?.idDepartamento;
    }
  }

  function convertIdToName(id, name) {
    if (name === "providers") {
      const prov = providers.find((x) => x.idFornecedor === parseInt(id));
      if (prov != null) return prov?.nome;
    } else if (name === "expenditureTypes") {
      const exp = expenditureTypes.find(
        (x) => x.idTipoDespesa === parseInt(id)
      );
      if (exp != null) return exp?.nome;
    } else if (name === "departments") {
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    }
  }

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper">
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="liftworld-label-wrapper">Nome</div>
            <div style={{ width: "82%" }}>
              <Input
                field={buildInputField(newClientName)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientNameAdd"}
                setNewClientName={setNewClientName}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <div className="liftworld-label-wrapper">Morada</div>
            <div style={{ width: "82%" }}>
              <Input
                field={buildInputField(newClientAddress)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientAddressAdd"}
                setNewClientAddress={setNewClientAddress}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <div className="liftworld-label-wrapper">Prazo</div>
            <div style={{ width: "82%" }}>
              <Input
                field={buildInputField(newClientPayment)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientPaymentAdd"}
                setNewClientPayment={setNewClientPayment}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "30px",
            }}
          >
            {" "}
            <SimpleButton
              text={"Adicionar"}
              variant={"liftworld-button-primary"}
              onClick={() => addNewProvider()}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="lifworld-table-wrapper">
      <Modal
        className="easypay-modal"
        showModal={isModalErrorShowing}
        setShowModal={setIsModalErrorShowing}
        headerContent={"Adicionar novo fornecedor"}
        bodyContent={createBody()}
        key={uuid()}
      ></Modal>
      <div className="liftworld-table-header-wrapper">
        {list &&
          Object.keys(list).length !== 0 &&
          list.map((item, index) => {
            return (
              <div
                className={
                  index === 0 || index === 2 || index === 3
                    ? "liftworld-table-13-wrapper"
                    : index === 1
                    ? "liftworld-table-12-wrapper"
                    : index === 4
                    ? "liftworld-table-115-wrapper"
                    : index === 5 || index === 8
                    ? "liftworld-table-7-wrapper"
                    : "liftworld-costs-table-9-wrapper"
                }
              >
                <div
                  className={
                    `${"liftworld-table-header-element"} ` + getPosition(index)
                  }
                >
                  {item}
                </div>
              </div>
            );
          })}
      </div>
      <div>
        {typeList &&
          Object.keys(typeList).length !== 0 &&
          typeList.map((item, index) => {
            return (
              <div className="liftworld-table-header-wrapper">
                <div className="liftworld-table-13-wrapper">
                  <Dropdown
                    text={""}
                    required={true}
                    list={buildNameArray(expenditureTypes)}
                    initialSelected={convertIdToName(
                      item.tipoDespesa,
                      "expenditureTypes"
                    )}
                    handleSubmit={(e) =>
                      handleTableChange(e, index, "tipoDespesa")
                    }
                    position={index === typeList.length - 1 ? "start" : ""}
                    size={"normal"}
                  />
                </div>
                <div className="liftworld-table-12-wrapper"
                 onClick={() => setCurrentSupplierIndex(index)}>
                  <InputSearch
                    text={""}
                    required={true}
                    list={buildNameArray(providers)}
                    handleSubmit={(e) =>
                      handleTableChange(e, index, "fornecedorDesp")
                    }
                    initialSelected={convertIdToName(
                      item.fornecedorDesp,
                      "providers"
                    )}
                    position={"middle"}
                    size={"normal"}
                  />
                </div>{" "}
                <div className="liftworld-table-13-wrapper">
                  <Dropdown
                    text={""}
                    required={true}
                    list={buildNameArray(departments)}
                    handleSubmit={(e) =>
                      handleTableChange(e, index, "departamentoDesp")
                    }
                    initialSelected={convertIdToName(
                      item.departamentoDesp,
                      "departments"
                    )}
                    position={"middle"}
                    size={"normal"}
                  />
                </div>
                <div className="liftworld-table-13-wrapper">
                  <LiftworldTableInput
                    field={buildInputField(item.descricao)}
                    position={"middle"}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"descricao"}
                    tableType={"despesas"}
                    workplanIndex={workplanIndex}
                  />
                </div>
                <div className="liftworld-table-115-wrapper">
                  <LiftworldTableInput
                    field={buildInputField(item.precoCompraForn)}
                    position={"middle"}
                    pattern={"[0-9]*.?[0-9]"}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"precoCompraForn"}
                    tableType={"despesas"}
                    workplanIndex={workplanIndex}
                    number={"number"}
                  />
                </div>
                <div className="liftworld-table-7-wrapper">
                  <LiftworldTableInput
                    field={buildInputField(item.margemSemPrecoVendaDesp)}
                    position={"middle"}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"margemSemPrecoVendaDesp"}
                    tableType={"despesas"}
                    workplanIndex={workplanIndex}
                    number={"number"}
                  />
                </div>
                <div className="liftworld-costs-table-9-wrapper">
                  <LiftworldTableInput
                    field={buildInputField(item.precoVendaDesp)}
                    position={"middle"}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"precoVendaDesp"}
                    tableType={"despesas"}
                    workplanIndex={workplanIndex}
                    number={"number"}
                  />
                </div>
                <div className="liftworld-costs-table-9-wrapper">
                  <TableTextbox
                    value={
                      isNaN(parseFloat(item.valorVendaDesp)) ||
                      item.valorVendaDesp === undefined
                        ? ""
                        : numberWithCommas(
                            parseFloat(item.valorVendaDesp).toFixed(2)
                          ) + " €"
                    }
                    position={"middle"}
                    color={"colored"}
                    number={"number"}
                  />
                </div>
                <div className="liftworld-table-7-wrapper">
                  <TableTextbox
                    value={
                      isNaN(parseFloat(item.margemDesp)) ||
                      item.margemDesp === undefined
                        ? ""
                        : numberWithCommas(
                            parseFloat(item.margemDesp).toFixed(2)
                          ) + "%"
                    }
                    position={index === typeList.length - 1 ? "end" : ""}
                    color={"colored"}
                    number={"number"}
                  />
                </div>
                <div className="liftworld-minus-icon">
                  <img
                    src={iconMinus}
                    alt="Icon Check"
                    className={"liftworld-minus-icon"}
                    onClick={() => removeRow(index)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default LiftworldCostsTable;
