import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_tabButton.scss";

const TabButton = ({
  variant,
  name,
  text,
  setTheActiveTab,
  activeTab,
  tabsNumber,
  action,
  t
}) => {
  useEffect(() => {
    if (action) {
      const variable = action?.split("::")[0];
      const value = action?.split("::")[1];
      if (document.dados[variable]) {
        if (activeTab === name) {
          if (document.dados[variable]?.value !== value) {
            document.dados[variable].value = value;
          }
        }
      }
    }
  }, [activeTab]);

  return (
    <>
      <div
        className={
          variant &&
          `easypay-tab-separator ${name === activeTab && "easypay-active-tab"}`
        }
      >
        <button
          id={name}
          className={`easypay-tab-button ${
            name === activeTab && "easypay-active"
          } ${variant} ${tabsNumber > 2 ? "easypay-separator" : ""}`}
          onClick={() => {
            setTheActiveTab(name);
          }}
          type="button"
          disabled={text.split("::")[1] === "disabled"}
        >
          {text.split("::")[1] === "disabled"
            ? t(text.split("::")[0])
            : text.split("::")[1] === "active"
            ? t(text.split("::")[0])
            : t(text)}
        </button>
      </div>
    </>
  );
};

export default withNamespaces()(TabButton);
