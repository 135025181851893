import React from "react";
import "../assets/scss/_modalContainer.scss";

const Modal = ({
  showModal,
  setShowModal,
  setShowPreviousModal,
  headerContent,
  bodyContent,
  footerContent,
  nomax,
  showPrevious
}) => {

  function showPreviousModal()
  {
    if(showPrevious === true)
    {
      setShowPreviousModal(true)
    }
  } 

  return (
    showModal && (
      <div className="easypay-back-shed">
        <div className={`easypay-modal-wrapper ${nomax ? "nomax" : ""}`}>
          {headerContent && (
            <div className="easypay-modal-header" >
              <div className="easypay-modal-header-title">{headerContent}</div>
              <span
                className="easypay-close-modal-btn"
                onClick={() => {
                  setShowModal(false);
                  showPreviousModal();
                }}
              >
                ×
              </span>
            </div>
          )}
          {bodyContent && (
            <div className="easypay-modal-body" >
              <div className="easypay-modal-content" >
                {bodyContent}
              </div>
            </div>
          )}
          {footerContent && (
            <div className="easypay-modal-footer">
              {footerContent}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Modal;
