import React from "react";
import { withNamespaces } from "react-i18next";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";
import Header from "~/pages/Easypay/components/Header";
import "~/pages/Easypay/assets/css/styles.css";
import BackOfficeEasypaySubHeader from "~pages/Easypay/components/BackOfficeEasypaySubHeader.js";
import BackOfficeVerticalButtons from "~pages/Easypay/components/BackOfficeVerticalButtons.js";
import BackOfficeOnboardingSections from "./OnboardingSections.js";

const Onboarding = ({ application }) => {
  const handleClick = (id) => {
    //Dynamic offSet that calculates header and subheader height
    const offSet =
      document?.getElementById("easypay-header")?.offsetHeight +
      document?.getElementById("easypay-backoffice-subheader")?.offsetHeight;
    const element = document.getElementById(id);
    const elementPosition = element.offsetTop;
    const offsetPosition = elementPosition - offSet;

    document.documentElement.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    //For Safari
    document.body.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const buttonsList = [
    { name: "Cliente", text: "Cliente" },
    { name: "Documentos", text: "Documentos" },
    { name: "Representantes Legais", text: "Representantes Legais" },
    { name: "Beneficiários Efetivos", text: "Beneficiários Efetivos" },
    { name: "Assinaturas", text: "Assinaturas" },
  ];

  const breadcrumbItems = [
    {
      text: "Onboarding",
      link: "/#/uniksystem/onboarding/backoffic-organization/onboarding",
    },
    {
      text: "ANT03213",
      link: "/#/uniksystem/onboarding/backoffic-organization/onboarding",
    },
  ];

  return (
    <>
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
        addDocument={true}
      />
      <BackOfficeEasypaySubHeader breadcrumbItems={breadcrumbItems} />
      <BackOfficeVerticalButtons
        navItems={buttonsList}
        handleClick={handleClick}
      />
      <BackOfficeOnboardingSections />
    </>
  );
};
export default withNamespaces()(Onboarding);
