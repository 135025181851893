import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";

const CreateTableTextInput = (props) => {
  const { field, className } = props;
  const [textInputValue, setTextInputValue] = useState("");
  const [required, setRequired] = useState(false);

  useEffect(() => {
    if (field) {
      if (field.value) setTextInputValue(field.value);
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
    }
  }, [field]);

  return (
    <Form.Group
      controlId={`formText-${field.variable}`}
      key={field.variable}
      style={{ marginBottom: "0px" }}
      className={"create-table-text-input " + className}
    >
      <input
        className="form-control form-table"
        type="text"
        name={field.variable}
        placeholder=""
        value={textInputValue}
        autoComplete="off"
        style={{
          width: "100%",
          fontSize: "0.9rem",
          paddingTop: "0px",
          paddingBottom: "0px",
          lineHeight: "1",
        }}
        maxLength={field.maxlength}
        onChange={(e) => setTextInputValue(e.target.value)}
        required={required}
        disabled={field.disabled === "true" || field.readonly === "true"}
      />
      {field.suffix !== "" && field.suffix !== undefined && (
        <InputGroup.Append>
          <InputGroup.Text id={`append-${field.variable}`}>
            {field.suffix}
          </InputGroup.Text>
        </InputGroup.Append>
      )}
    </Form.Group>
  );
};

export default CreateTableTextInput;
