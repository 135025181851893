import { takeLatest } from 'redux-saga/effects';

import {
  FIND_PORTALRH_MENU
} from "../ducks/portalRhMenu/actionTypes";

import {
  findPortalRhMenu
} from "../ducks/portalRhMenu/sagas";

export const portalRhMenuSagas = [
  takeLatest(FIND_PORTALRH_MENU, findPortalRhMenu)
]
