import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import infoIcon from "~/assets/img/icons/info-icon.png";

export const useLabelFormated = (field,translations, setCurrentInfo) => {
  const [labelFormated, setLabelFormated] = useState("");
  const [imageFormated, setImageFormated] = useState("");
  const [textFormated, setTextFormated] = useState("");
  const { userSettings } = useSelector((state) => state.userSettingsReducer);

  let language = userSettings?.language ? userSettings.language : "pt";

  useEffect(() => {
    if (typeof field?.text === "string") {
      if (field?.text?.includes(":::")) {
        setLabelFormated(field?.text?.split("::")[0] + ":");
      } else if (field?.text?.includes("::i::")) {
        setLabelFormated(field?.text?.split("::i::")[0]);
        setImageFormated(
          <img
            style={{ cursor: "pointer" }}
            className="info-icon"
            src={infoIcon}
          ></img>
        );
        setTextFormated(field?.text?.split("::i::")[1]);
      } else if (field?.text?.includes("::t::")) {
        let lingua = language.trim();
        let splittext = field.text.split("::t::");
        let texttoshow = splittext[0];
        let texttoadd = splittext[1];
        if(translations)
        setLabelFormated(translations?.[lingua][texttoshow] + texttoadd);
      } else if (field?.text?.includes("::")) {
        setLabelFormated(field?.text?.split("::")[0]);
      }
    }
    if (
      typeof field?.text === "object" &&
      Object.keys(field?.text).length > 0
    ) {
      Object.keys(field?.text).forEach((key) => {
        if (field?.text[key]?.includes(":::")) {
          setLabelFormated(field?.text[key]?.split("::")[0] + ":");
        }  else if (field?.text[key]?.includes("::i::")) {
          setLabelFormated(field?.text[key]?.split("::i::")[0]);
          setImageFormated(
            <img
              style={{ cursor: "pointer" }}
              className="info-icon"
              src={infoIcon}
            ></img>
          );
          setTextFormated(field?.text[key]?.split("::i::")[1])
        } else if (field?.text[key]?.includes("::t::")) {
          let lingua = language.trim();
          let splittext = field?.text[key]?.split("::t::");
          let texttoshow = splittext[0];
          let texttoadd = splittext[1];
          setLabelFormated(translations?.[lingua][texttoshow] + texttoadd);
        }else if (field?.text[key]?.includes("::")) {
          setLabelFormated(field?.text[key]?.split("::")[0]);
        }
      });
    }
  }, [field]);

  return { labelFormated, imageFormated, textFormated, language };
};
