import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import { getMerchant } from "~/store/ducks/onboarding/actionTypes";

/**
 * CoreSystem Client Consult
 *
 * This service consults a client from Easypay with the parameter TIN (Tax Identification Number)
 *
 */
const Test = ({ t }) => {
  const dispatch = useDispatch();

  const { isLoadingGetMerchant, merchant } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    merchant &&
      Object.keys(merchant).length !== 0 &&
      setOutput(JSON.stringify(merchant, null, 2));
  }, [merchant]);

  const [tinNumber, setTinNumber] = useState("");
  const [validated, setValidated] = useState(false);
  const [output, setOutput] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testGetMerchant();
    }
  };

  const testGetMerchant = () => {
    const payload = {
      information: tinNumber,
    };
    dispatch(getMerchant(payload, apiSettings.id));
  };

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Form.Row>
          <Col sm="12" md="8" lg="6">
            <Form.Group controlId="validationNameTest">
              <Form.Label>TIN</Form.Label>
              <Form.Control
                autoComplete="off"
                value={tinNumber}
                onChange={(e) => {
                  setTinNumber(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredTinNumber")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          disabled={
            !user?.organization?.id || Object.keys(apiSettings).length === 0
          }
          className="card-button onboarding-button"
          type="submit"
        >
          {isLoadingGetMerchant ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            t("onboarding.general.test")
          )}
        </Button>
      </Form>
      <Output output={output} />
    </div>
  );
};
export default withNamespaces()(Test);
