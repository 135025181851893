import { takeLatest } from 'redux-saga/effects';

import {
  CHECK_TOKEN
} from "../ducks/oauth/actionTypes";

import {
  checkToken
} from "../ducks/oauth/sagas";

export const oauthSagas = [
  takeLatest(CHECK_TOKEN, checkToken)
]
