import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import {
  getDelegationsFromUserToUser,
  setUserFlow,
} from "~/store/ducks/delegacoes/actionTypes";
import DelegacoesEditor from "./DelegacoesEditor";
import Spinner from "~/components/Spinner";

const Reatribuir = ({ t }) => {
  const [state, setState] = React.useState({ rows: [], selFlow: undefined });

  const history = useHistory();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { user } = useSelector((selectorState) => selectorState.globalReducer);
  const { flows, isLoadingUserFlow, isLoadingSuccess } = useSelector(
    (selectorState) => selectorState.delegacoesReducer
  );

  const getLegacyUser = () => {
    if (!user || !user.id) return;
    return user.legacyUsers.find((u) => u.legacyApplication.label == "bpm");
  };

  const onRowSelect = (row, isSelect) => {
    if (isSelect) setState({ ...state, rows: [...state.rows, row.id] });
    else setState({ ...state, rows: state.rows.filter((x) => x !== row.id) });
  };

  const onRowSelectAll = (isSelect, rows, e) => {
    const newRows = isSelect ? rows.map((e) => e.id) : [];
    setState({ ...state, rows: newRows });
    return rows;
  };

  const selectRow = {
    mode: "checkbox",
    selected: state.rows,
    onSelect: onRowSelect,
    onSelectAll: onRowSelectAll,
  };

  useEffect(() => {
    if (flows && flows.length != 0 && !flows[0].action)
      dispatch(
        setUserFlow(
          flows.map((v, i) => ({
            ...v,
            id: i,
            ownerid: getLegacyUser().username,
            action: (
              <span
                className="btn-link"
                onClick={() => setState({ ...state, selFlow: v })}
              >
                {t("bpm.delegacoes.table.action.reassign")}
              </span>
            ),
          }))
        )
      );
  }, [flows]);

  useEffect(() => {
    if (state.selFlow) history.push(path + "/editor");
    else history.push(path);
  }, [state.selFlow, isLoadingSuccess]);

  useEffect(() => {
    if (user && user.id)
      dispatch(
        getDelegationsFromUserToUser({ username: getLegacyUser().username })
      );
  }, [user, isLoadingSuccess]);

  const column = [
    {
      dataField: "flowname",
      text: t("bpm.delegacoes.table.flow"),
    },
    {
      dataField: "action",
      text: "",
    },
  ];

  const { SearchBar } = Search;

  return (
    <Switch>
      <Route path={path + "/editor"}>
        {state.selFlow ? (
          <DelegacoesEditor
            onCancel={() => setState({ ...state, selFlow: undefined })}
            data={state.selFlow}
            supervisor={true}
          />
        ) : (
          <></>
        )}
      </Route>
      <Route path={path}>
        <div className="custom-table" style={{ paddingTop: "10px" }}>
          <button
            className="card-button btn btn-primary"
            onClick={() => {
              if (state.rows.length == 0) return;
              setState({
                ...state,
                selFlow: state.rows.map((e, i) => flows[e]),
              });
            }}
          >
            {t("bpm.delegacoes.table.action.reassignSelected")}
          </button>
          <div className="custom-table">
            <Spinner spinning={isLoadingUserFlow || isLoadingSuccess} />
            <ToolkitProvider keyField="id" data={flows} columns={column} search>
              {(props) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SearchBar
                    style={{ marginTop: 10, float: "left" }}
                    {...props.searchProps}
                    placeholder={t("general.search")}
                  />
                  <BootstrapTable
                    selectRow={selectRow}
                    classes="table table-striped table-hover table-bordered"
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Route>
    </Switch>
  );
};

export default withNamespaces()(Reatribuir);
