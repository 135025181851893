export const GET_DELEGACOES = "delegacoes@GET_DELEGACOES";
export const SET_DELEGACOES = "delegacoes@SET_DELEGACOES";
export const GET_DELEGACOES_BY_USERID = "delegacoes@GET_DELEGACOES_BY_USERID";
export const SET_DELEGACOES_BY_USERID = "delegacoes@SET_DELEGACOES_BY_USERID";
export const GET_USER_FLOWS = "delegacoes@GET_USER_FLOWS";
export const SET_USER_FLOWS = "delegacoes@SET_USER_FLOWS";
export const GET_USER_TO_USER_FLOWS = "delegacoes@GET_USER_TO_USER_FLOWS";
export const GET_APROVAL_DELEGACOES = "delegacoes@GET_APROVAL_DELEGACOES";
export const SET_APROVAL_DELEGACOES = "delegacoes@SET_APROVAL_DELEGACOES";
export const APROVE_DELEGATION = "delegacoes@APROVE_DELEGATION";
export const SET_SUCCESS = "delegacoes@SET_SUCCESS";
export const GET_USERS = "delegacoes@GET_USERS";
export const SET_USERS = "delegacoes@SET_USERS";
export const DEL_DELEGACAO = "delegacoes@DEL_DELEGACAO";
export const SET_NOTIFICATION = "delegacoes@SET_NOTIFICATION";
export const POST_DELEGATION = "delegacoes@POST_DELEGATION";
export const GET_OWNERS = "delegacoes@GET_OWNERS";
export const SET_OWNERS = "delegacoes@SET_OWNERS";
export const GET_REQUESTED_DELEGATION = "delegacoes@GET_REQUESTED_DELEGATION";
export const SET_REQUESTED_DELEGATION = "delegacoes@SET_REQUESTED_DELEGATION";

export const postInsertDelegation = (payload) => {
  return { type: POST_DELEGATION, data: payload };
};

export const getDelegacoes = (payload) => {
  return { type: GET_DELEGACOES, data: payload };
};

export const getAprovalDelegation = (payload) => {
  return { type: GET_APROVAL_DELEGACOES, data: payload };
};

export const deleteDelegacao = (payload) => {
  return { type: DEL_DELEGACAO, data: payload };
};

export const setNotification = (payload) => {
  return { type: SET_NOTIFICATION, data: payload };
};

export const aproveDelegation = (payload) => {
  return { type: APROVE_DELEGATION, data: payload };
};

export const setSuccess = (payload) => {
  return { type: SET_SUCCESS, data: payload };
};

export const setAprovalDelegacoes = (payload) => {
  return { type: SET_APROVAL_DELEGACOES, data: payload };
};

export const setDelegacoes = (payload) => {
  return { type: SET_DELEGACOES, data: payload };
};

export const getFlowFromUser = (payload) => {
  return { type: GET_USER_FLOWS, data: payload };
};

export const getDelegationsFromUserToUser = (payload) => {
  return { type: GET_USER_TO_USER_FLOWS, data: payload };
};

export const setUserFlow = (payload) => {
  return { type: SET_USER_FLOWS, data: payload };
};

export const getUsers = (payload) => {
  return { type: GET_USERS, data: payload };
};

export const setUsers = (payload) => {
  return { type: SET_USERS, data: payload };
};

export const getOwners = (payload) => {
  return { type: GET_OWNERS, data: payload };
};

export const setOwners = (payload) => {
  return { type: SET_OWNERS, data: payload };
};

export const getDelegacoesByUserid = (payload) => {
  return { type: GET_DELEGACOES_BY_USERID, data: payload };
};

export const setDelegacoesByUserid = (payload) => {
  return { type: SET_DELEGACOES_BY_USERID, data: payload };
};

export const getRequestedDelegation = (username, flowid) => {
  return { type: GET_REQUESTED_DELEGATION, username, flowid };
};

export const setRequestedDelegation = (payload) => {
  return { type: SET_REQUESTED_DELEGATION, data: payload };
};
