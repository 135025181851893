import { put } from "redux-saga/effects";
import http from "../../../utils/config/http";
import {
  setUserSettings,
  setUserWidgets,
  setLastAddedWidget,
  setIsUpdatedWidget,
  setUnikUserSettings,
  setisfirstlogin,
  setpostisfirstlogin
} from "../actions";

export function* createUserSettings({ user }) {
  yield http.post("/api/mongo/user/create", user);
}

export function* updateUserSettings({ user }) {
  try {
    yield put(setLastAddedWidget(null));
    const resp = yield http.put("/api/mongo/user/update", user);
    yield put(setUserSettings(resp.data));
  } catch (e) {}
}

/*unikUserSettings start. Language update.
Alternative way of updating userSettings from deep component.
Because updateUserSettings provokes all the parent components re-render.
1st findUnikUserSettings, then 2nd updateUnikUserSettings
*/
export function* findUnikUserSettings({ username }) {
  try {
    const resp = yield http.get(`/api/mongo/user/${username}`);
    yield put(setUnikUserSettings(resp.data));
  } catch (e) {}
}

export function* updateUnikUserSettings({ unikUserSettings }) {
  try {
    const resp = yield http.put("/api/mongo/user/update", unikUserSettings);
    yield put(setUnikUserSettings(resp.data));
  } catch (e) {}
}

export function* updateSignatureReduced({ unikUserSettings }) {
  try {
    yield http.put("/api/mongo/user/update", unikUserSettings);
  } catch (e) {}
}
/*unikUserSettings end*/

export function* findWidgetById({ id }) {
  try {
    yield http.get(`/api/mongo/widget/user/find/by/${id}`);
  } catch (e) {}
}

export function* findAllWidgets() {
  try {
    const resp = yield http.get(`/api/mongo/widget/user/find/all`);
    yield put(setUserWidgets(resp.data));
  } catch (e) {}
}

export function* findWidgetsByApplication({ app }) {
  try {
    const resp = yield http.get(
      `/api/mongo/widget/user/find-by-application?application=${app}`
    );
    yield put(setUserWidgets(resp.data));
  } catch (e) {}
}

export function* saveUserWidget({ widget }) {
  try {
    yield put(setLastAddedWidget(null));
    const resp = yield http.post("/api/mongo/widget/user/save", widget);
    yield put(setLastAddedWidget(resp.data));
  } catch (e) {
    yield put(setLastAddedWidget(null));
  }
}

export function* updateUserWidget({ widget }) {
  try {
    yield put(setIsUpdatedWidget(false));
    yield http.put("/api/mongo/widget/user/update", widget);
    yield put(setIsUpdatedWidget(true));
  } catch (e) {
    yield put(setIsUpdatedWidget(false));
  }
}

export function* deleteUserWidget({ id }) {
  try {
    yield http.delete(`/api/mongo/widget/user/delete/${id}`);
  } catch (e) {}
}

export function* getIsFirstLogin(){
  try {
    const resp = yield http.get('/api/user/check-if-first-login');
    yield put(setisfirstlogin(resp?.data));
  } catch (e) {
    yield put(setisfirstlogin(false))
  }
}

export function* postIsFirstLogin(payload){
  try {
    const resp = yield http.post(
      "/api/user/change-first-time-password",
      payload.data
    );
    yield put(setpostisfirstlogin(resp?.data));
  } catch (e) {
    yield put(setpostisfirstlogin(false))
  }
}
