import { ADocumentStatus } from './ADatacaptureEnum';

export const getColorByApprovedDocument = (status) => {
  switch (status) {
    case ADocumentStatus.PROCESSED:
      return 'success'
    case ADocumentStatus.REJECTED_IFLOW:
      return 'danger'
    default:
      return ''
  }
}

export const getColorByStatusDocument = (status) => {
  switch (status) {
    case ADocumentStatus.PROCESSED:
      return 'success'
    case ADocumentStatus.TERMINATED:
      return 'success'
    case ADocumentStatus.PROCESSING:
      return 'warning'
    case ADocumentStatus.PENDING_IFLOW:
      return 'warning'
    case ADocumentStatus.PENDING_REJ_IFLOW:
      return 'warning'
    case ADocumentStatus.REJECTED_IFLOW:
      return 'warning'
    case ADocumentStatus.ERROR:
      return 'danger'
    case ADocumentStatus.DUPLICATED:
      return 'primary'
    default:
      return ''
  }
}

export const translateApprovedDocument = (status) => {
  switch (status) {
    case ADocumentStatus.PROCESSED:
      return 'Sim'
    case ADocumentStatus.REJECTED_IFLOW:
      return 'Não'
    default:
      return ''
  }
}

export const translateStatusDocument = (status) => {
  switch (status) {
    case ADocumentStatus.PROCESSED:
      return 'datacapture.automated.document_status.processed'
    case ADocumentStatus.TERMINATED:
      return 'datacapture.automated.document_status.terminated'
    case ADocumentStatus.PROCESSING:
      return 'datacapture.automated.document_status.processing'
    case ADocumentStatus.PENDING_IFLOW:
      return 'datacapture.automated.document_status.processing'
    case ADocumentStatus.PENDING_REJ_IFLOW:
      return 'datacapture.automated.document_status.processing'
    case ADocumentStatus.ERROR:
      return 'datacapture.automated.document_status.error'
    case ADocumentStatus.DUPLICATED:
      return 'datacapture.automated.document_status.duplicated'
    case ADocumentStatus.REJECTED_IFLOW:
      return 'datacapture.automated.document_status.rejectedIflow'
    default:
      return ''
  }
}