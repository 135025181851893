import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";

import HelperContent from "./HelperContent";
import { findAllBatchs } from "~/pages/DataCapture/actions";

const HelperModal = (props) => {
  const { t, showHelperModal, handleCloseHelperModal } = props;
  const dispatch = useDispatch();

  return (
    <Modal
      show={showHelperModal}
      dialogClassName="modal-datacapture-80w"
      backdrop="static"
      onHide={() => handleCloseHelperModal()}
    >
      <Modal.Body style={{ padding: "0px" }}>
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "10px",
            zIndex: "2",
          }}
        ></div>
        <HelperContent experiencedUser={false} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-submit-button"
          onClick={() => {
            dispatch(findAllBatchs());
            handleCloseHelperModal();
          }}
        >
          {t("datacapture.general.next")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withNamespaces()(HelperModal);
