/* Regular User Reset Password */
export const RESET_PASSWORD = 'resetPassword@RESET_PASSWORD';
export const DONE_RESET_PASSWORD = 'resetPassword@DONE_RESET_PASSWORD';
export const CLEAR_RESET_PASSWORD = 'resetPassword@CLEAR_RESET_PASSWORD';

export const VALIDATE_TOKEN = 'resetPassword@VALIDATE_TOKEN';
export const SET_PASSWORD_TOKEN_STATUS = 'resetPassword@SET_PASSWORD_TOKEN_STATUS';
export const CLEAR_TOKEN_STATUS = 'resetPassword@CLEAR_TOKEN_STATUS';

export const SAVE_PASSWORD = 'resetPassword@SAVE_PASSWORD';

export const resetPassword = payload => ({ type: RESET_PASSWORD, payload });
export const doneResetPassword = payload => ({ type: DONE_RESET_PASSWORD, payload });
export const clearResetPassword = () => ({ type: CLEAR_RESET_PASSWORD });

export const validateToken = token => ({ type: VALIDATE_TOKEN, token });
export const setPasswordTokenStatus = tokenStatus => ({ type: SET_PASSWORD_TOKEN_STATUS, tokenStatus });
export const clearTokenStatus = () => ({ type: CLEAR_TOKEN_STATUS });

export const savePassword = password => ({ type: SAVE_PASSWORD, password });

/* Admin Reset Password */
export const VALIDATE_ADMIN_CODE = 'resetPassword@VALIDATE_ADMIN_CODE';
export const DONE_VALIDATE_ADMIN_CODE = 'resetPassword@DONE_VALIDATE_ADMIN_CODE';

export const CLEAR_ADMIN_CODE_STATUS = 'resetPassword@CLEAR_ADMIN_CODE_STATUS';

export const REQUEST_RESET_ADMIN_PASSWORD = 'resetPassword@REQUEST_RESET_ADMIN_PASSWORD'; 
export const DONE_REQUEST_RESET_ADMIN_PASSWORD = 'resetPassword@DONE_REQUEST_RESET_ADMIN_PASSWORD';

export const RESET_ADMIN_PASSWORD = 'resetPassword@RESET_ADMIN_PASSWORD';
export const DONE_RESET_ADMIN_PASSWORD = 'resetPassword@DONE_RESET_ADMIN_PASSWORD';
export const CLEAR_SUCCESS_REQUEST_RESET_ADMIN = 'resetPassword@CLEAR_SUCCESS_REQUEST_RESET_ADMIN';

export const VALIDATE_ADMIN_RESET = 'resetPassword@VALIDATE_ADMIN_RESET';
export const DONE_VALIDATE_ADMIN_RESET = 'resetPassword@DONE_VALIDATE_ADMIN_RESET';

export const validateAdminCode = (code) => ({ type: VALIDATE_ADMIN_CODE, code });
export const doneValidateAdminCode = (status) => ({ type: DONE_VALIDATE_ADMIN_CODE, status });
export const clearAdminCodeStatus = () => ({ type: CLEAR_ADMIN_CODE_STATUS });

export const requestResetAdminPassword = (payload) => ({ type: REQUEST_RESET_ADMIN_PASSWORD, payload });
export const doneRequestResetAdminPassword = (status) => ({ type: DONE_REQUEST_RESET_ADMIN_PASSWORD, status });
export const clearSuccessRequestResetAdmin = () => ({ type: CLEAR_SUCCESS_REQUEST_RESET_ADMIN });

export const validateAdminReset = (code, redirect) => ({ type: VALIDATE_ADMIN_RESET, code , redirect });
export const doneValidateAdminReset = (status) => ({ type: DONE_VALIDATE_ADMIN_RESET, status });

