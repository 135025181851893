import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Modal } from "react-bootstrap";
import { invertColor } from "./utils";
import { editActivityFolder } from "~/store/ducks/taskFolders/actionTypes";
import { updateReadFlag } from "~/store/ducks/tasks/actionTypes";

const EditActivityFolder = (props) => {
  const { t, folders, show, handleClose, row, userBPM, styleSheetReadFlag } =
    props;

  const [isRead, setIsRead] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (row?.read_flag === 0) {
      setIsRead(false);
    }
    if (row?.read_flag === 1) {
      setIsRead(true);
    }
  }, [row?.read_flag]);

  const handleReadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let read_flag = row.read_flag === 1 ? 0 : 1;
    let flowid = row.flowid;
    let flowname = userBPM;
    let pnumber = row.pnumber;
    let subpid = row.subpid;
    dispatch(updateReadFlag(read_flag, flowid, flowname, pnumber, subpid));
    handleClose();
  };

  const handleEditActivity = (folder) => {
    if (folder && folder.id) {
      const payload = {
        username: userBPM,
        flowid: row.flowid,
        pid: row.pnumber,
        subpid: row.subpid,
        folderid: folder.id,
      };
      dispatch(editActivityFolder(payload));
      handleClose();
    }
  };

  const resetActivityFolder = () => {
    const payload = {
      username: userBPM,
      flowid: row.flowid,
      pid: row.pnumber,
      subpid: row.subpid,
      folderid: null,
    };
    dispatch(editActivityFolder(payload));
    handleClose();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      dialogClassName="modal-30w custom-modal"
      centered
    >
      <Modal.Header closeButton>
        {row.pnumber && (
          <div className="custom-title">
            {row && row.flowname} &nbsp;{row?.pnumber}
          </div>
        )}
      </Modal.Header>

      <Modal.Body style={{ scrollY: "auto" }}>
        {folders && folders.length > 0 && (
          <div className="tasks-badges">
            <span className="tasks-badge-header">
              {t("taskPage.categories.editActivityCategory")}
            </span>
            <div className="tasks-badges-wrapper">
              {folders.map((c, i) => {
                return (
                  <div
                    key={i}
                    className="tasks-badge"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleEditActivity(c);
                    }}
                    style={{
                      backgroundColor: c.color,
                      color: invertColor(c.color),
                    }}
                  >
                    <div className="tasks-badge-content">
                      {row && row.folderid === c.id && (
                        <i className="icon-check mr-2 mt-1" />
                      )}
                      {c.name}
                    </div>
                  </div>
                );
              })}
            </div>
            <br />
            {row && row.folderid && (
              <a href={""} onClick={() => resetActivityFolder()}>
                {t("taskPage.categories.removeActivityFolder")}
              </a>
            )}
          </div>
        )}
      </Modal.Body>

      {styleSheetReadFlag ? (
        <a
          href={""}
          onClick={(e) => handleReadClick(e)}
          style={{
            paddingLeft: "16px",
            paddingBottom: "10px",
            textDecoration: "none",
          }}
        >
          {isRead ? "Marcar como não lido" : "Marcar como lido"}
        </a>
      ) : null}

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default withNamespaces()(EditActivityFolder);
