import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { transformFormData } from "../utils";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";
import oauth from "~/utils/oauth";
import DatePicker from "~/components/DatePicker";

import { useLabelWidth } from "../utils/useLabelWidth";
import { useLabelFormated } from "../utils/useLabelFormated";

import { useAutoAdjust } from "../utils/useAutoAdjust";

const CreateDateInput = (props) => {
  const { field, isChild, children, justifyContent, styleSheet, fontSize } = props;
  const dispatch = useDispatch();

  const [dateValue, setDateValue] = useState(undefined);
  const [required, setRequired] = useState(false);

  const { labelWidthClass, labelWidth } = useLabelWidth(field);
  const { labelFormated } = useLabelFormated(field);

  const { autoAdjust } = useAutoAdjust(field);

  useEffect(() => {
    if (field) {
      if (field.value) {
        //transform date string into Date
        let dateArray;

        if (field.value.includes("/")) {
          dateArray = field.value.split("/");
        } else if (field.value.includes("-")) {
          dateArray = field.value.split("-");
        }

        let time = "";
        let timeArray = [];
        try {
          time = dateArray[2].split(" ")[1];
          timeArray = time.split(":");
        } catch (e) {
          //TODO
        }
        let month = dateArray[1];
        if (month === "10" || month === "11" || month === "12") {
          try {
            month = Number.parseInt(month) - 1;
          } catch (e) {
            month = 0;
          }
        } else if (month && month.includes("0")) {
          month = month.split("0");
          try {
            month = Number.parseInt(month[1]) - 1;
          } catch (e) {
            month = 0;
          }
        } else {
          try {
            month = Number.parseInt(month) - 1;
          } catch (e) {
            month = 0;
          }
        }
        const hour = timeArray[0] || 0;
        const minute = timeArray[1] || 0;
        let date;

        if (
          field.dateformattext === "yyyy/MM/dd HH:mm" ||
          field.dateformattext === "yyyy/MM/dd"
        ) {
          const day = dateArray[2]?.split(" ")[0];
          const year = dateArray[0];
          date = new Date(year, month || 0, day, hour || 0, minute || 0);
        } else if (
          field.dateformattext === "dd/MM/yyyy HH:mm" ||
          (field.dateformattext === "dd/MM/yyyy" && dateArray.length >= 3)
        ) {
          const year = dateArray[2]?.split(" ")[0];
          const day = dateArray[0];
          date = new Date(year, month || 0, day, hour || 0, minute || 0);
        }
        if (field.value && date) setDateValue(date);
      }

      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
    }
  }, [field]);

  const handleDateChange = (value) => {
    if (field.onchange_submit && field.onchange_submit !== "") {
      if (field.onchange_submit.includes("document.dados.op.value='")) {
        const array = field.onchange_submit.split("document.dados.op.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.op.value = array2[0];
          }
        }
      }
      if (field.onchange_submit.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postIflowFormData(formdata, url));
      }
    } else {
      setDateValue(value);
    }
  };

  return (
    <>
      <Form.Group
        controlId={`formDate-${field.variable}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          marginBottom: styleSheet?.compact ? "5px" : "10px",
          flexGrow: "1",
        }}
      >
        {!isChild ? (
          <Form.Label
            column
            className={
              labelWidthClass ? labelWidthClass : "iflow-form-label-fix"
            }
            style={
              labelWidth
                ? {
                    width: labelWidth,
                    minWidth: labelWidth,
                    flexBasis: labelWidth,
                  }
                : {}
            }
          >
            {labelFormated
              ? labelFormated
              : field.text && field.text.b
              ? field.text.b
              : field.text}
          </Form.Label>
        ) : (
          <Form.Label
            column
            style={{
              flexGrow: "1",
              //paddingTop: field.disabled === "true" ? "0" : "0.375rem",
              //paddingBottom: field.disabled === "true" ? "0" : "0.375rem",
              paddingTop: "0.375rem",
              paddingBottom: "0.375rem",
            }}
          >
            {field.text && field.text.b ? field.text.b : field.text}
          </Form.Label>
        )}
        <Col
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            flexGrow: "2",
            minWidth: "fit-content",
          }}
        >
          {field.disabled === "true" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: isChild ? "5px" : "0",
                paddingRight: isChild ? "5px" : "0",
                justifyContent: justifyContent ? justifyContent : "",
              }}
            >
              {field.value !== "" && (
                <DatePicker
                  value={dateValue}
                  name={`DateToTransform?dateformattext=${field.dateformattext}&variable=${field.variable}`}
                  format={
                    field.dateformattext
                      ? field.dateformattext
                      : "dd/MM/yyyy HH:mm:ss"
                  }
                  fontSize={fontSize}
                  className={"date-picker-selector_rightSide"}
                  autoAdjust={autoAdjust}
                  style={{ flexGrow: "1", flexBasis: "0", widht: "100%" }}
                  disabled
                />
              )}
              {children && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: "1",
                  }}
                >
                  {children}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: isChild ? "5px" : "0",
                paddingRight: isChild ? "5px" : "0",
                justifyContent: justifyContent ? justifyContent : "",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "auto",
                }}
              >
                <DatePicker
                  value={dateValue}
                  onChange={(value) => handleDateChange(value)}
                  name={`DateToTransform?dateformattext=${field.dateformattext}&variable=${field.variable}`}
                  format={
                    field.dateformattext ? field.dateformattext : "dd/MM/yyyy "
                  }
                  className={"date-picker-selector_rightSide"}
                  boolean={autoAdjust}
                  fontSize={fontSize}
                  style={{
                    flexGrow: "1",
                    flexBasis: "0",
                    right: "15px",
                    width: "100%",
                  }}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {`O campo ${
                  field.text && field.text.b ? field.text.b : field.text
                } é de preenchimento obrigatório`}
              </Form.Control.Feedback>
              {children && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: "1",
                    width: "100%",
                  }}
                >
                  {children}
                </div>
              )}
            </div>
          )}
        </Col>
      </Form.Group>
      {styleSheet?.highLightRows && !isChild && <hr />}
    </>
  );
};
export default CreateDateInput;
