import React, { useState } from "react";
import iconDelete from "../assets/img/icons/icon-close.png";
import "../assets/scss/_tag.scss";


function Chip({ text , onClick}) {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      {isActive && (
        <div className={"easypay-chip"}>
          <section
            className={"easypay-chip-text"}
          >
            {text}
          </section>

            <section
              className="easypay-chip-img-section"
              onClick={() => {
                onClick();
              }}
            >
              <img
                className="easypay-chip-img"
                src={iconDelete}
                height="14px"
                width="14px"
              ></img>
            </section>
        </div>
      )}
    </>
  );
}

export default Chip;
