import React, { useEffect, useState } from "react";
import "../assets/scss/_proposal.scss";
import "../assets/scss/_option.scss";
import { useDispatch, useSelector } from "react-redux";
import ProposalHoursTable from "./ProposalHoursTable";
import ProposalHoursSimulatorData from "./ProposalHoursSimulatorData";
import ProposalSuppliersTable from "./ProposalSuppliersTable";
import ProposalCostsTable from "./ProposalCostsTable";
import ProposalSuppliersCostSimulatorTable from "./ProposalSuppliersCostSimulatorTable";
import ProposalResumeSection from "./ProposalResumeSection";
const Proposal = ({ info , workplanIndex}) => {
  const dispatch = useDispatch();

  const { workplans } = useSelector((state) => state.budgetManagementReducer);
  var [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    var newArray = [];
    for (var i = 0; i < workplans[0].items.length; i++) {
      for (var j = 0; j < workplans[0].items[i].atividades.length; j++) {
        for (
          var k = 0;
          k < workplans[0].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (workplans[0].items[i].atividades[j].opcoes[k].selected) {
            newArray.push(workplans[0].items[i].atividades[j].opcoes[k]);
          }
        }
      }
    }
    if (newArray.length === 0) {
      for (var i = 0; i < workplans[0].items.length; i++) {
        for (var j = 0; j < workplans[0].items[i].atividades.length; j++) {
          for (
            var k = 0;
            k < workplans[0].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              newArray.push(workplans[0].items[i].atividades[j].opcoes[k]);
            }
          }
        }
      }
    }

    setSelectedOptions(newArray);
  }, [selectedOptions.length === 0]);

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".")
    const finalString = newArray[0].replace(",",".") + "," + newArray[1]
    return finalString;
  }

  return (
    <div>
      <div className="liftworld-proposal-wrapper">
        <div className="liftworld-proposal-main-tables-wrapper">
          <div className="liftworld-proposal-hours-wrapper">
            <div className="liftworld-table-title-wrapper">HORAS</div>
            <ProposalHoursTable selectedOptions={selectedOptions} workplanIndex = {workplanIndex}/>
          </div>
          <div className="liftworld-proposal-hours-wrapper">
            <div className="liftworld-table-title-wrapper">FORNECEDORES</div>
            <ProposalSuppliersTable workplanIndex = {workplanIndex}/>
          </div>
          <div className="liftworld-proposal-hours-wrapper">
            <div className="liftworld-table-title-wrapper">
              CUSTOS E DESPESAS
            </div>
            <ProposalCostsTable workplanIndex = {workplanIndex}/>
          </div>
        </div>
        {false && (
          <div className="liftworld-simulator-data-wrapper">
            <div className="liftworld-proposal-hours-wrapper">
              <div className="liftworld-table-title-wrapper">
                DADOS DO SIMULADOR
              </div>
              <ProposalHoursSimulatorData />
              <div className="liftowrld-simulator-table-wrapper">
                <ProposalSuppliersCostSimulatorTable />
              </div>
              <div className="liftowrld-simulator-table-wrapper">
                <ProposalSuppliersCostSimulatorTable />
              </div>
            </div>
          </div>
        )}
      </div>
      <ProposalResumeSection workplanIndex = {workplanIndex} />
    </div>
  );
};
export default Proposal;
