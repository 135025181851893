import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Button } from "react-bootstrap";

const EnabledContainer = ({ enabled, setEnabled, enabledUser, t }) => {
  const [btnColor, setBtnColor] = useState(true);
  const [textSave, setTextSave] = useState("");

  useEffect(() => {
    setTextSave("");
    setEnabled(enabledUser);
    setBtnColor(enabledUser);
  }, []);

  const handleChangeStates = () => {
    setEnabled(!enabled);
    setBtnColor(!enabled);
    if (enabledUser === enabled) {
      setTextSave(t("admin.userManagement.persist.msgInactive"));
      return;
    }
    setTextSave("");
  };

  return (
    <>
      <div style={{ display: "flex", flexWrap: "nowrap" }}>
        <Button
          style={{ width: "50%", marginTop:"0.5rem" }}
          className={btnColor ? "btn btn-secondary" : "btn btn-success"}
          onClick={() => handleChangeStates()}
        >
          {enabled
            ? t("admin.userManagement.persist.setInactive")
            : t("admin.userManagement.persist.setActive")}
        </Button>
      </div>
      <p style={{ paddingTop: "0.5rem" }}>{textSave}</p>
    </>
  );
};
export default withNamespaces()(EnabledContainer);
