import React, { useState, useEffect } from "react";
import RadioButtonCard from "~/pages/Easypay/components/RadioButtonCard";

import "../assets/scss/_radioButton.scss";

const RadioButtonCardWrapper = ({ field }) => {
  const [variableValue, setVariableValue] = useState([]);

  useEffect(() => {
    let stateArray = [];
    for (let i = 0; i < field?.row.length - 1; i++) {
      stateArray[i] = field.row[i + 1].col[1].value === 1 ? 1 : 0;
    }
    setVariableValue(stateArray);
  }, []);

  const handleVariableValue = (value) => {
    document.dados[field?.row[0]?.col[1]?.value].value = value;
  };

  return (
    <>
      <div className="easypay-radio-card-wrapper">
        {field?.row?.map(
          (item, i) =>
            i > 0 &&
            item?.col[0]?.value && (
              <RadioButtonCard
                setVariableValue={handleVariableValue}
                variableValue={variableValue}
                field={item}
                defaultChecked={item?.col[1]?.value === 1 ? true : false}
                index={i - 1}
              />
            )
        )}
      </div>
      <input
        type="hidden"
        name={field?.row[0]?.col[1]?.value}
        style={{ height: "0px", width: "0px", border: "none" }}
        value={variableValue}
      />
    </>
  );
};

export default RadioButtonCardWrapper;
