import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import "~/pages/DataCapture/scss/datacapture.scss";
import "~/pages/ADataCapture/components/AutomatedDatacapture/AutomatedDataCapture.css";
import "~pages/Anacom/assets/scss/_anacomTable.scss";
import Pagination from "~/pages/Easypay/components/Pagination";
import greenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import Dropdown from "~/components/Dropdown";

const PopupTable = ({
  anacomList,
  assuntosList,
  unikList,
  anacomPagination,
  assuntosPagination,
  unikPagination,
  isNaoClassificadas,
}) => {
  const [unikPage, setUnikPage] = useState(1);
  const [assuntosPage, setAssuntosPage] = useState(1);
  const [anacomPage, setAnacomPage] = useState(1);
  const [unikCounter, setUnikCounter] = useState(5);
  const [anacomCounter, setAnacomCounter] = useState(5);
  const [assuntosCounter, setAssuntosCounter] = useState(5);
  const [listAssuntos, setListAssuntos] = useState([]);
  const [assuntoSelected, setAssuntoSelected] = useState("");
  const [unikListFiltered, setUnikListFiltered] = useState([]);
  const [arrUnik, setArrUnik] = useState([]);
  const [pageList, setPageList] = useState([]);

  const { complaints } = useSelector((state) => state.claimsManagementReducer);

  useEffect(() => {
    if (assuntosList.length > 0) {
      let newArr = JSON.parse(JSON.stringify(assuntosList));

      for (let i = 0; i < newArr.length; i++) {
        delete newArr[i]["percentagem"];

        newArr[i]["label"] = newArr[i]["assunto"];
      }
      newArr.unshift({ label: "Todos" });
      for (let k = 0; k < newArr.length; k++) {
        delete newArr[k]["assunto"];
        newArr[k]["value"] = k.toString();
      }

      setListAssuntos(newArr);
    }
  }, [assuntosList]);

  const handleAssuntos = (e) => {
    setAssuntoSelected(e);
    unikListfilteredByDropdown();
  };

  const unikListfilteredByDropdown = () => {
    const assuntoByDropdown = listAssuntos.filter(
      (e) => e.value === assuntoSelected
    );

    const arrFiltered = unikList.filter((e) =>
      assuntoByDropdown[0]?.label === "Todos"
        ? e
        : e.assunto === assuntoByDropdown[0]?.label
        ? e
        : null
    );

    setUnikListFiltered(arrFiltered);
  };

  useEffect(() => {
    if (unikListFiltered.length > 0) {
      let counter = 0;
      let newArr = [];
      counter = unikListFiltered.length / 5;
      counter = Math.round(counter);

      for (let i = 0; i < counter; i++) {
        newArr.push(i + 1);
      }

      setPageList(newArr);
    } else {
      setPageList(unikPagination);
    }
  }, [unikListFiltered, unikPagination]);

  function checkFormatter(cell) {
    if (cell === "t") {
      return <img className="anacom-checkmark-img" src={greenCheckmark} />;
    } else return "";
  }

  function getMotivoDescricao(id) {
    return complaints?.idsValues?.filter((elem) => elem.id === parseInt(id))[0]
      ?.label;
  }

  function formatPercentagem(percentagem) {
    var currentPercentagem = "";
    if (percentagem?.length > 4) {
      currentPercentagem = percentagem?.slice(0, 4);
      if (currentPercentagem === "100.") {
        return "100";
      } else {
        return currentPercentagem;
      }
    }
  }

  useEffect(() => {
    if (unikListFiltered.length > 0) {
      setArrUnik(unikListFiltered);
    } else {
      setArrUnik(unikList);
    }
  }, [unikList, unikListFiltered]);

  const filteredAnacomList = anacomList.reduce((acc, current) => {
    const x = acc.find((item) => item.assunto === current.assunto);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return (
    <>
      {isNaoClassificadas ? null : (
        <div>
          <div style={{ width: "100%" }}>
            <div style={{ paddingBottom: "10px" }}>Classificação ANACOM</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div className={`anacom-table-medium ${"bold"}`}>Nº</div>
              <div className={`anacom-table-bigger ${"bold"}`}>
                Motivo ou Indicio
              </div>
              <div className={`anacom-table-normal ${"bold"}`}>Assunto</div>
              <div className={`anacom-table-small ${"bold"}`}></div>
            </div>
          </div>
          {anacomList &&
            Object.keys(anacomList).length !== 0 &&
            anacomList.map((item, index) => {
              return (
                <>
                  {index < anacomPage * 5 && index >= anacomPage * 5 - 5 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        className={`anacom-table-medium ${
                          item?.motivoIndicio?.length <= 3 ? "color" : ""
                        }`}
                      >
                        {item.motivoIndicio}
                      </div>
                      <div
                        className={`anacom-table-bigger ${
                          item?.motivoIndicio?.length <= 3 ? "color" : ""
                        }`}
                      >
                        {getMotivoDescricao(item.motivoIndicio)}
                      </div>
                      <div
                        className={`anacom-table-normal ${
                          item?.motivoIndicio?.length <= 3 ? "color" : ""
                        }`}
                      >
                        {item.assunto}
                      </div>
                      <div
                        className={`anacom-table-small ${
                          item?.motivoIndicio?.length <= 3 ? "color" : ""
                        }`}
                      ></div>
                    </div>
                  )}
                </>
              );
            })}
          {
            <Pagination
              list={anacomPagination}
              pageSelected={anacomPage}
              setPageSelected={setAnacomPage}
              counter={anacomCounter}
              setCounter={setAnacomCounter}
            />
          }
        </div>
      )}
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>Assuntos</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div className={`anacom-table-assunto ${"bold"}`}>Assunto</div>
        <div className={`anacom-table-percentagem ${"bold"}`}>%</div>
        {isNaoClassificadas ? null : (
          <div className={`anacom-table-small`}></div>
        )}
      </div>
      {assuntosList &&
        Object.keys(assuntosList).length !== 0 &&
        assuntosList.map((item, index) => {
          return (
            <>
              {index < assuntosPage * 5 && index >= assuntosPage * 5 - 5 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div className={`anacom-table-assunto`}>{item.assunto}</div>
                  <div className={`anacom-table-percentagem`}>
                    {formatPercentagem(item?.percentagem)}
                  </div>
                  {isNaoClassificadas ? null : (
                    <div
                      className={`anacom-table-small ${
                        item?.motivoIndicioId?.length <= 3 ? "color" : ""
                      }`}
                    >
                      {filteredAnacomList.map((element) =>
                        element?.assunto?.toLowerCase() ===
                        item?.assunto?.toLowerCase() ? (
                          <img
                            className="anacom-checkmark-img"
                            src={greenCheckmark}
                            alt="mark"
                          />
                        ) : null
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      {
        <Pagination
          list={assuntosPagination}
          pageSelected={assuntosPage}
          setPageSelected={setAssuntosPage}
          counter={assuntosCounter}
          setCounter={setAssuntosCounter}
        />
      }

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          Classificação UNIK
        </div>

        <Dropdown
          className="dropdownPopUp"
          list={listAssuntos}
          handleSubmit={handleAssuntos}
          defaultValue={
            assuntoSelected ? assuntoSelected : listAssuntos[0]?.value
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div className={`anacom-table-medium ${"bold"}`}>Nº</div>
        <div className={`anacom-table-big ${"bold"}`}>Motivo ou Indicio</div>
        <div className={`anacom-table-small ${"bold"}`}>%</div>
        <div className={`anacom-table-normal ${"bold"}`}>Assunto</div>
        {isNaoClassificadas ? null : (
          <div className={`anacom-table-small`}></div>
        )}
      </div>
      {arrUnik.length > 0 &&
        arrUnik &&
        arrUnik.map((item, index) => {
          return (
            <>
              {index < unikPage * 5 && index >= unikPage * 5 - 5 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    className={`anacom-table-medium ${
                      item?.motivoIndicioId?.length <= 3 ? "color" : ""
                    }`}
                  >
                    {item.motivoIndicioId}
                  </div>
                  <div
                    className={`anacom-table-big ${
                      item?.motivoIndicioId?.length <= 3 ? "color" : ""
                    }`}
                  >
                    {item.motivoIndicio}
                  </div>
                  <div
                    className={`anacom-table-small ${
                      item?.motivoIndicioId?.length <= 3 ? "color" : ""
                    }`}
                  >
                    {item?.scoreMotivoIndicio?.toString().length > 4
                      ? item?.scoreMotivoIndicio?.toString().slice(0, 4)
                      : item?.scoreMotivoIndicio}
                  </div>
                  <div
                    className={`anacom-table-normal ${
                      item?.motivoIndicioId?.length <= 3 ? "color" : ""
                    }`}
                  >
                    {item.assunto}
                  </div>
                  {isNaoClassificadas ? null : (
                    <div
                      className={`anacom-table-small ${
                        item?.motivoIndicioId?.length <= 3 ? "color" : ""
                      }`}
                    >
                      {checkFormatter(item.check)}
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      {
        <Pagination
          list={pageList}
          pageSelected={unikPage}
          setPageSelected={setUnikPage}
          counter={unikCounter}
          setCounter={setUnikCounter}
        />
      }
    </>
  );
};
export default withNamespaces()(PopupTable);
