import React from "react";
import { Button } from "react-bootstrap";

const AdicionarBtn = ({ disabled, text, onClick, type }) => {
  return (
    <Button
      disabled={disabled}
      variant="outline-primary"
      onClick={onClick}
      className="custom-add-button"
      style={{ display: "flex", alignItems: "center" }}
      type={type ? type : "button"}
    >
      <i
        className="icon-add"
        style={{ fontSize: "40px", paddingRight: "10px" }}
      />
      {text}
    </Button>
  );
};

export default AdicionarBtn;
