import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import { Card } from "react-bootstrap";

import "~/assets/css/icons.css";

import BatchTable from "./components/BatchTable";
import CustomDropdown from "../CustomDropdown";
import Spinner from "~/components/Spinner";
import Messages from "~/components/Messages";

import { updateUserWidget } from "~/pages/User/actions";

const DataCaptureWidget = ({
  t,
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
}) => {
  const { isLoadingBatchs, isLoadingUpdateBatch, isLoadingDeleteBatch } =
    useSelector((state) => state.dataCaptureReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "12",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  return (
    <>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-tarefas2 mr-2" />
              {t("DataCaptureWidget.title")}
            </h6>
            <h6>
              {textMove && t("widgets.resizeMove")}
              {textClick && t("widgets.resizeClick")}
            </h6>
            <CustomDropdown
              apagarWidget={apagarWidget}
              handleMouseDown={handleMouseDown}
            />
          </Card.Header>
          {!isResizing && (
            <Card.Body>
              <BatchTable widget={widget} />
            </Card.Body>
          )}
        </Card>
      </div>

      <Spinner
        spinning={
          isLoadingBatchs || isLoadingDeleteBatch || isLoadingUpdateBatch
        }
        wrapper
      />
      <Messages />
    </>
  );
};
export default withNamespaces()(DataCaptureWidget);
