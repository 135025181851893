import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Header from "~/containers/Layout/components/Header";
import Puzzle from "~/components/Puzzle";

import IframeHandler from "~/components/IframeHandler";
import Admin from "../../pages/Admin";
import AboutComponent from "~/components/AboutComponent";
import Account from "~/pages/Account";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "~/assets/css/styles.css";

import { requestUser, clearUser } from "./actions";

import {
  updateUserSettings,
  clearUserSettings,
  getIsFirstLogin,
  postIsFirstLogin
} from "../../pages/User/actions";
import {
  getPuzzleItems,
  requestConfiguration,
} from "../../pages/Admin/SystemSettings/actions";
import { findUDWSettings } from "~/store/ducks/udw/actionTypes";

import i18n from "~/i18n";
import IdleTimer from "react-idle-timer";
import oauth from "~/utils/oauth";
import { logout } from "~/pages/Login/actions";
import { isEmpty } from "lodash";

import Spinner from "~/components/Spinner";
import Chat from "~/components/chat";

import chatBot from "~/assets/img/chat/Chatbot.jpg";
import darkchatBot from "~/assets/img/chat/Chatbot2.jpg";
import finAssistant from "~/assets/img/chat/finAssistant.png";
import hrAssistant from "~/assets/img/chat/hrAssistant.png";
import bpmAssistant from "~/assets/img/chat/bpmAssistant.png";
import complianceAssistant from "~/assets/img/chat/complianceAssistant.png";
import gaiAssistant from "~/assets/img/chat/gaiAssistant.png";

import Uniksystem from "~/containers/BaseApplication/Uniksystem";

import GDPR from "~/containers/BaseApplication/GDPR";
import Compliance from "~/containers/BaseApplication/Compliance";
import Error404 from "~/components/Exception/Error404";

import GlobalSolver from "~/containers/BaseApplication/GlobalSolver";
import DataCapture from "../../pages/DataCapture";
import ADataCapture from "~/pages/ADataCapture";
import ProcessValidation from "../../pages/ProcessValidation";
import Financeira from "~/pages/Financeira";
import GAI from "../../pages/GAI";
import RH from "../../pages/RH";
import PortalRH from "../../pages/PortalRH";
import Dashboard from "../../pages/Dashboards";
import BPM from "../../pages/BPM";
import Onboarding from "~/pages/Onboarding";
import Easypay from "~/pages/Easypay";
import EasypayComponents from "~/pages/Easypay/pages/AllComponents";
import LiftWorldComponents from "~/pages/Liftworld/pages/AllComponents";
import EasypayBackOffice from "~/pages/Easypay/pages/BackofficeUser/EasypayBackOfficePage";
import BackOfficeEasypay from "~/pages/Easypay/pages/BackofficeOrganization";
import BackOfficeEasypayLeadsPage from "~/pages/Easypay/pages/BackofficeOrganization/Leads/index.js";
import BackOfficeEasypayOnbardingPage from "~/pages/Easypay/pages/BackofficeOrganization/Onboarding";
import BackOfficePreviewDocumentPage from "~/pages/Easypay/pages/BackofficeOrganization/RequestNewDoc/BackOfficePreviewDocumentPage";
import BackOfficeRequestDocumentPage from "~/pages/Easypay/pages/BackofficeOrganization/RequestNewDoc/BackOfficeRequestDocumentPage";
import WorkPlanPage from "~/pages/Liftworld/pages/WorkPlanPage";
import Budgetmgr from "~/pages/Budgetmgr";
import Tools from "~/components/ToolsUnik/index";
import { Modal, Col } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { GLOBAL_SOLVER_MIDDLEWARE } from "~/utils/constants";
import {Form} from "react-bootstrap";
import Anacom from "~/pages/Anacom";

const Layout = (props) => {
  const { keycloak, t } = props;
  const { path } = useRouteMatch();

  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const isProductionMode = configuration ? configuration.productionMode : true;

  const dispatch = useDispatch();
  let location = useLocation();

  const { user, isLoadingUser } = useSelector((state) => state.globalReducer);
  const { userSettings, isLoadingUserSettings, isfirstlogin, post_isfirstlogin} = useSelector(
    (state) => state.userSettingsReducer
  );
  const { udwSettings } = useSelector((state) => state.udwReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const [showChat, setShowChat] = useState(false);
  const [styleSheet, setFormStyleSheet] = useState("");
  const [translations, setTranslations] = useState("");

  const [showModalFirstLogin, setshowModalFirstLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const getUserTheme = () => {
    let config = configuration?.loginPage;
    if (config === "nblp") {
      return "nblp"
    }
    return userSettings.theme ? userSettings.theme : "default";
  };

  useEffect(() => {
    dispatch(requestConfiguration());
    dispatch(findUDWSettings());
    dispatch(requestUser());
    dispatch(getIsFirstLogin())
    document.getElementsByTagName("body")[0].className = getUserTheme();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingUser || !isLoadingUserSettings) {
      if (isfirstlogin == true) setshowModalFirstLogin(isfirstlogin);
    }
  }, [isfirstlogin, post_isfirstlogin]);

  useEffect(() => {
    if (user.username && oauth.getAccessToken()) {
      //dispatch(findUserSettings(user.username));
      //socket.emit('userIsOnline', user);
    }
    dispatch(getPuzzleItems());
  }, [dispatch, user]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = getUserTheme();
    if (userSettings.language)
      if (
        window.localStorage.getItem("lng") !==
        userSettings.language.toLowerCase()
      ) {
        window.localStorage.setItem("lng", userSettings.language.toLowerCase());
        i18n.changeLanguage(userSettings.language.toLowerCase());
      }
  }, [userSettings]);

  const [isMongoOn, setIsMongoOn] = useState(undefined);
  useEffect(() => {
    if (udwSettings && udwSettings.mongoHost) {
      /*TODO change for boolean */
      if (udwSettings.mongoHost === "MongoDB not configured")
        setIsMongoOn(false);
      else setIsMongoOn(true);
    }
  }, [udwSettings]);

  useEffect(() => {
    if (configuration && configuration.browserTabName) {
      document.title = configuration.browserTabName || "";
    }
  }, [configuration]);

  const saveHistory = () => {
    if (!isEmpty(userSettings)) {
      if (location.pathname === undefined) return;
      if (location.state === undefined) return;
      if (location.state && location.state.name === "Uniksystem") return;
      if (
        userSettings.history &&
        userSettings.history.find((item) => item.pathname === location.pathname)
      )
        return;
      let newHistory;
      newHistory = Array.from(userSettings.history ? userSettings.history : []);
      if (newHistory.length >= 10) {
        newHistory.splice(0, 1);
        newHistory.push(location);
      } else {
        newHistory.push(location);
      }
      const payload = userSettings;
      payload.history = newHistory;
      dispatch(updateUserSettings(payload));
    }
  };

  const ChatBot = ({ location }) => {
    const [showA, setShowA] = useState(false);

    const toggleShowA = () => setShowA(!showA);
    let url;
    if (location && location.pathname === "/uniksystem/hr") url = hrAssistant;
    else if (location && location.pathname === "/uniksystem/bpm")
      url = bpmAssistant;
    else if (
      location &&
      (location.pathname === "/uniksystem/compliance" ||
        location.pathname === "/uniksystem/gdpr")
    )
      url = complianceAssistant;
    else if (location && location.pathname === "/uniksystem/gai")
      url = gaiAssistant;
    else url = finAssistant;

    return (
      <>
        {showA && (
          <div className="chat-panel">
            <div
              style={{
                position: "fixed",
                bottom: "60px",
                right: "5px",
                width: "400px",
                zIndex: "9999",
              }}
            >
              <img src={url} width="400px" alt="" />
            </div>
          </div>
        )}
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="tooltipChatBot">Precisa de ajuda?</Tooltip>}
        >
          <img
            className="chat-bot img-fluid float-right"
            src={getUserTheme() === "dark" ? darkchatBot : chatBot}
            alt="chatBot"
            onClick={toggleShowA}
          />
        </OverlayTrigger>
      </>
    );
  };

  useEffect(() => {
    saveHistory();
  }, [location]);

  function handleChangeTheme(theme) {
    const payload = userSettings;
    payload.theme = theme;
    dispatch(updateUserSettings(payload));
  }

  function handleChangeLanguage(language) {
    const payload = userSettings;
    payload.language = language;
    dispatch(updateUserSettings(payload));
  }

  const TIMEOUT = 604800 * 1000;
  const _history = useHistory();

  const onIdle = (_) => {
    handleLogout();
  };

  useEffect(() => {
    /*
    if (showChat) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    else {
      document.getElementsByTagName('body')[0].style.overflow = 'visible';
    }
    */
  }, [showChat]);

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  /*Get extraconfiguration*/
  useEffect(() => {
    if (menu) {
      //find app from url
      const arr1 =
        window.location.href.includes("/uniksystem/") &&
        window.location.href.split("/uniksystem/");
      if (arr1.length > 1) {
        const arr2 = arr1[1];
        const arr3 = arr2.split("/"); 
        const app = arr3[0];

        const menuItem = menu.find(
          (item) =>
            item.route === `${app}/processos` ||
            item.route === `${app}/processes`
        );
        if (menuItem) {
          const extraConfiguration_ = JSON.parse(
            menuItem.extraConfiguration || false
          );
          const styleSheet_ =
            extraConfiguration_ && extraConfiguration_.styleSheet;
            const translations_ =
          extraConfiguration_ && extraConfiguration_?.translations;
          setFormStyleSheet(styleSheet_); 
          setTranslations(translations_)
        }
      }
    }
  }, [menu]);

  function handleLogout() {
    dispatch(logout(user.username));
    dispatch(clearUserSettings());
    dispatch(clearUser());
    oauth.clearAppStorage()

    if (document.getElementById(GLOBAL_SOLVER_MIDDLEWARE.context))
      document.getElementById(GLOBAL_SOLVER_MIDDLEWARE.context).src =
        GLOBAL_SOLVER_MIDDLEWARE.iframe;
    oauth.logout();

    setTimeout(function () {
      _history.push("/");
      window.location.reload();
      }, 300);
  }

  const handleDrag = (e) => {
    e.preventDefault();
  };

  const isMobile = window.innerWidth < 992 ? true : false;

  const asterisk = <i style={{ color: "#dc3545" }}>*</i>;

  const handleSendNewPassword = (e) => {
    e.preventDefault();
    if (password == "" || confirmPassword == "") {
      setError(t("layout.notEmpty"));
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (password === confirmPassword) {
      saveNewPassword();
    } else {
      setError(t("layout.equals"));
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
  };

  function saveNewPassword() {
    const payload = {
      username: user?.username,
      newPassword: password
    };
    dispatch(postIsFirstLogin(payload));
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const renderFirstLoginBody = () => {
    return (
      <>
        <div className="d-flex justify-content-between">
          {/* New Password */}
          <Col sm="6" md="6" lg="6">
            <Form.Group controlId="validationCustomPassword">
              <Form.Label>{t("layout.newPassword")} {" "} {asterisk}</Form.Label>
              <div style={{ display: "inline-flex" }}>
                <Form.Control
                  required
                  disabled={false}
                  type={showPassword ? "text" : "password"}
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError("");
                  }}
                  value={password}
                  autoComplete="new-password"
                />
                <button
                  onClick={togglePasswordVisibility}
                  style={{
                    all: "unset",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </Form.Group>
          </Col>
          <Col sm="6" md="6" lg="6">
            <Form.Group controlId="validationConfirmPassword">
              <Form.Label>
                {t("layout.confirmNewPassword")} {" "} {asterisk}
              </Form.Label>
              <div style={{ display: "inline-flex" }}>
                <Form.Control
                  required
                  disabled={false}
                  type={showConfirmPassword ? "text" : "password"}
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setError("");
                  }}
                  value={confirmPassword}
                  autoComplete="new-password"
                />
                <button
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    all: "unset",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </Form.Group>
          </Col>
        </div>
        <div
          style={{
            float: "right",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {error && (
            <Form.Text style={{ color: "#dc3545", paddingRight: "20px" }}>
              {error}
            </Form.Text>
          )}
          <button
            className="card-button btn btn-primary mt-auto"
            onClick={(e) => {
              handleSendNewPassword(e);
            }}
          >
            {t("layout.save")}
          </button>
        </div>
      </>
    );
  };

  const renderPostFirstLoginBody = () => {
    const labelColor = post_isfirstlogin ? "#006400" : "#dc3545";
    const labelText = post_isfirstlogin
      ? t("layout.isfirstLoginTrue")
      : t("layout.isfirstLoginFalse")
  
    return (
      <>
        <div className="d-flex justify-content-between">
          <Form.Group controlId="validationCustomPassword">
            <Form.Label style={{ color: labelColor, fontWeight: "600" }}>
              {labelText}
            </Form.Label>
          </Form.Group>
        </div>
        <div style={{ float: "right", marginTop: "20px" }}>
          <button className="card-button btn btn-primary mt-auto" onClick={() => handleLogout()}>
            {t("layout.close")}
          </button>
        </div>
      </>
    );
  };
  

  return (
    <>
      <Spinner
        spinning={isLoadingUser || isLoadingUserSettings}
        wrapper={true}
      />

      <IdleTimer
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={TIMEOUT}
      />

      {!location.pathname.includes("uniksystem/easypay") &&
        !location.pathname.includes("uniksystem/onboarding/user") &&
        !location.pathname.includes("uniksystem/onboarding/backoffic-user") &&
        !location.pathname.includes(
          "uniksystem/onboarding/backoffic-organization"
        ) && (
          <Header
            keycloak={keycloak}
            theme={getUserTheme()}
            handleChangeTheme={handleChangeTheme}
            handleChangeLanguage={handleChangeLanguage}
            toggleChat={() => setShowChat(!showChat)}
            styleSheet={styleSheet}
          />
        )}

      <Chat showChat={showChat} />

      <GlobalSolver />
      <Modal
        show={showModalFirstLogin}
        dialogClassName="custom-modal"
        centered
        backdrop="static"
      >
        {post_isfirstlogin != null ? (
          ""
        ) : (
          <Modal.Header>
            <Modal.Title>{t("layout.changePassword")}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>
          {post_isfirstlogin != null
            ? renderPostFirstLoginBody()
            : renderFirstLoginBody()}
        </Modal.Body>
      </Modal>
      {!showModalFirstLogin && <div
        className="wrapper"
        onDrop={(e) => handleDrag(e)}
        onDragOver={(e) => handleDrag(e)}
      >
        <DndProvider backend={HTML5Backend}>
          <div id="appwrapper" style={{ display: "flex" }}>
            <Switch>
              <Route path={`${path}/bpm`}>
                <BPM isMongoOn={isMongoOn} styleSheet={styleSheet} />
              </Route>

              <Route
                path={`${path}/${GLOBAL_SOLVER_MIDDLEWARE.context}`}
                render={() => (
                  <IframeHandler name={GLOBAL_SOLVER_MIDDLEWARE.context} />
                )}
              />

              <Route path={`${path}/rh`}>
                <RH isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/portalrh`}>
                <PortalRH isMongoOn={isMongoOn} styleSheet={styleSheet} translations={translations} />
              </Route>
              
              <Route path={`${path}/dashboard`}>
                <Dashboard isMongoOn={isMongoOn} styleSheet={styleSheet} />
              </Route>

              <Route path={`${path}/gdpr`}>
                <GDPR isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/compliance`}>
                <Compliance isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/gai`}>
                <GAI isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/financeira`}>
                <Financeira isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/datacapture`}>
                <DataCapture isMongoOn={isMongoOn} />
              </Route>
              <Route path={`${path}/adatacapture`}>
                <ADataCapture isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/validprocs`}>
                <ProcessValidation isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/onboarding`}>
                <Onboarding isMongoOn={isMongoOn} />
              </Route>

              <Route exact path={`${path}/easypay`}>
                <Easypay isMongoOn={isMongoOn} />
              </Route>
              <Route exact path={`${path}/easypay/componentes`}>
                <EasypayComponents isMongoOn={isMongoOn} />
              </Route>
              <Route exact path={`${path}/liftworld/componentes`}>
                <LiftWorldComponents isMongoOn={isMongoOn} />
              </Route>
              <Route exact path={`${path}/liftworld/workplan`}>
                <WorkPlanPage isMongoOn={isMongoOn} />
              </Route>
              <Route path={`${path}/budgetmgr`}>
                <Budgetmgr isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/claimsmgt`}>
                <Anacom isMongoOn={isMongoOn} />
              </Route>
              <Route exact path={`${path}/easypay/backoffice`}>
                <EasypayBackOffice isMongoOn={isMongoOn} />
              </Route>
              <Route exact path={`${path}/easypay/backoffice-leads`}>
                <BackOfficeEasypay isMongoOn={isMongoOn} />
              </Route>
              <Route
                exact
                path={`${path}/easypay/backoffice-onboarding-page/:token`}
              >
                <BackOfficeEasypayOnbardingPage isMongoOn={isMongoOn} />
              </Route>
              <Route
                exact
                path={`${path}/easypay/backoffice-leads-page/:token`}
              >
                <BackOfficeEasypayLeadsPage isMongoOn={isMongoOn} />
              </Route>

              <Route
                exact
                path={`${path}/easypay/backoffice-document-preview-page/:id`}
                component={BackOfficePreviewDocumentPage}
              />

              <Route
                exact
                path={`${path}/easypay/backoffice-request-document-page/:id`}
              >
                <BackOfficeRequestDocumentPage isMongoOn={isMongoOn} />
              </Route>

              <Route path={`${path}/admin`}>
                <Admin />
              </Route>
              <Route path={`${path}/about`}>
                <AboutComponent />
              </Route>
              <Route exact path={`${path}/tools`}>
                <Tools />
              </Route>
              <Route path={`${path}/account`}>
                <Account />
              </Route>
              <Route exact path={`${path}`}>
                <Uniksystem isMongoOn={isMongoOn} />
              </Route>
              <Route path={`${path}/*`}>
                <Error404 />
              </Route>
            </Switch>
          </div>
        </DndProvider>
        {!isProductionMode && <ChatBot location={location} />}
        {!isMobile && <Puzzle />}
      </div>}
    </>
  );
};
export default withNamespaces()(Layout);
