import React, { useEffect, useState } from "react";
import arrowGrey from "../assets/img/icons/arrow-down-grey.png";
import arrowLightGrey from "../assets/img/icons/arrow-left-light-grey.png";
import "../assets/scss/_pagination.scss";
import { setUnikPage } from "~/store/ducks/claimsManagement/actionTypes";
function Pagination({
  list,
  pageSelected,
  setPageSelected,
  counter,
  setCounter,
}) {
  const [leftDisabled, setLeftDisabled] = useState(true);
  const [rightDisabled, setRightDisabled] = useState(false);

  useEffect(() => {
    if (list.length <= 5) {
      setRightDisabled(true);
    } else {
      if (pageSelected === list.length) {
        setRightDisabled(true);
      } else {
        setRightDisabled(false);
      }
      if (counter === list.length) {
        setRightDisabled(true);
      } else {
        setRightDisabled(false);
      }
    }
    counter > 5 ? setLeftDisabled(false) : setLeftDisabled(true);
    pageSelected < counter - 4
      ? setPageSelected(pageSelected + 1)
      : pageSelected > counter
      ? setPageSelected(pageSelected - 1)
      : setPageSelected(pageSelected);
  });

  return (
    <>
      <div className="easypay-pagination-container">
        <div
          className={
            leftDisabled
              ? `${"easypay-pagination-left-img-container"} disabled`
              : "easypay-pagination-left-img-container"
          }
          onClick={
            leftDisabled
              ? null
              : () => {
                  setCounter(counter - 1);
                }
          }
        >
          <img
            className={
              leftDisabled
                ? `${"easypay-pagination-left-arrow"} disabled`
                : `${"easypay-pagination-left-arrow"} active`
            }
            src={leftDisabled ? arrowLightGrey : arrowGrey}
          ></img>
        </div>
        {counter - 4 > 1 && (
          <div className="easypay-right-pagination-ellipsis">...</div>
        )}
        {list &&
          Object.keys(list).length !== 0 &&
          list.map((item, index) => {
            return (
              <>
                {index >= counter - 5 && index <= counter - 1 && (
                  <div
                    className={
                      counter - index > 5 || counter - index <= 0
                        ? null
                        : pageSelected - 1 === index
                        ? `${"easypay-pagination-badge"} selected`
                        : `${"easypay-pagination-badge"} not-selected`
                    }
                    onClick={(e) => {
                      setPageSelected(index + 1);
                    }}
                  >
                    {!(counter - index > 5 || counter - index <= 0) && (
                      <div className="badge-number">{item}</div>
                    )}
                  </div>
                )}
              </>
            );
          })}
        {counter < list.length && (
          <div className="easypay-right-pagination-ellipsis">...</div>
        )}
        <div
          className={
            rightDisabled
              ? `${"easypay-pagination-right-img-container"} disabled`
              : "easypay-pagination-right-img-container"
          }
          onClick={
            rightDisabled
              ? null
              : () => {
                  setCounter(counter + 1);
                }
          }
        >
          <img
            className={
              rightDisabled
                ? `${"easypay-pagination-right-arrow"} disabled`
                : `${"easypay-pagination-right-arrow"} active`
            }
            src={rightDisabled ? arrowLightGrey : arrowGrey}
          ></img>
        </div>
      </div>
    </>
  );
}

export default Pagination;
