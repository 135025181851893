import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Button } from "react-bootstrap";
import Textarea from "react-expanding-textarea";
import CustomDropdown from "../CustomDropdown";
import "../../../assets/css/icons.css";
import "../../../assets/css/styles.css";
import { updateUserWidget } from '~/pages/User/actions';

import { withNamespaces } from "react-i18next";

const NotesWidget = ({ apagarWidget,widget,handleMouseDown,isResizing,textMove, textClick, ...props }) => {
  const { t } = props;
  const [notes, setNotes] = useState(
    JSON.parse(localStorage.getItem("notes")) || []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("notes", JSON.stringify(notes));
  }, [notes]);

  useEffect(() => {
    if (widget.userConfiguration == null) {
       const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6"
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  const cards = notes.map((note) => (
    <div className="notes-wrapper" key={note.id}>
      <h2>
        <input
          className="note-title"
          value={note.title}
          placeholder={t("NotesWidget.noteTitle")}
          onChange={(event) => {
            note.title = event.target.value;
            updateNote(note);
          }}
        />
      </h2>
      <Textarea
        className="note-content"
        defaultValue={note.content}
        placeholder={t("NotesWidget.typeHere")}
        onChange={(event) => {
          note.content = event.target.value;
          updateNote(note);
        }}
      />
      <div
        style={{
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <a role="button" tabIndex="0" onClick={() => deleteNote(note)}>
          <i className="icon-trash"></i>
        </a>
      </div>
    </div>
  ));

  let nextID = localStorage.getItem("nextID") || 0;
  const newID = () => {
    localStorage.setItem("nextID", nextID + 1);
    return nextID++;
  };

  const createNote = () => {
    setNotes((oldNotes) => [
      ...oldNotes,
      {
        id: newID(),
        title: "",
        content: "",
      },
    ]);
  };

  const updateNote = (note) => {
    setNotes((oldNotes) =>
      [...oldNotes.filter((n) => n.id !== note.id), note].sort(
        (a, b) => a.id - b.id
      )
    );
  };

  const deleteNote = (note) => {
    setNotes((oldNotes) => oldNotes.filter((n) => n.id !== note.id));
  };

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-bell mr-2" />
            {t("NotesWidget.title")}
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown apagarWidget={apagarWidget} handleMouseDown={handleMouseDown} />
        </Card.Header>
        {!isResizing && (<Card.Body>
          {cards}
          <div>
            <a
              role="button"
              tabIndex="0"
              onClick={() => createNote()}
              className="btn text-success"
            >
              <i className="icon-add"></i>
            </a>
            <Button className="card-button" onClick={() => setNotes([])}>
              {t("NotesWidget.reset")}
            </Button>
          </div>
        </Card.Body>)}
      </Card>
    </div>
  );
};
export default withNamespaces()(NotesWidget);
