import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getHouseHold,
  getHouseHoldDropdown,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import ButtonsUpdate from "./ButtonsUpdate";
import DateTimePicker from "react-datetime-picker";

const RhHousehold = ({ rowCollaborator, t }) => {
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comments, setComments] = useState("");
  const [startDate, setStartDate] = useState("");
  const [documentNum, setDocumentNum] = useState("");
  const [rowClicked, setRowClicked] = useState();
  const [kinshipList, setKinshipList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [kinshipSelected, setKinshipSelected] = useState("");
  const [deficientSelected, setDeficientSelected] = useState("");
  const [genderSelected, setGenderSelected] = useState("");
  const [documentSelected, setDocumentSelected] = useState("");
  const [echelonSelected, setEchelonSelected] = useState("");
  const [checkFamilyAllo, setCheckFamilyAllo] = useState(false);
  const [checkSingleParent, setCheckSingleParent] = useState(false);
  const [checkPreNatal, setCheckPreNatal] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getHouseHoldDropdown());
      dispatch(
        getHouseHold(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
    }
  }, [rowCollaborator]);

  const { houseHold, houseHoldDropdown } = useSelector(
    (state) => state.personalReducer
  );

  let listDeficient = [
    { label: "Sim", value: "1" },
    { label: "Não", value: "2" },
  ];

  let listEchelon = [
    { label: "Escalão I", value: "1" },
    { label: "Escalão II", value: "2" },
    { label: "Escalão III", value: "3" },
    { label: "Escalão IV", value: "4" },
    { label: "Escalão V", value: "5" },
    { label: "Escalão VI", value: "6" },
  ];

  const handleKinship = (e) => {
    setKinshipSelected(e);
  };

  const handleGender = (e) => {
    setGenderSelected(e);
  };

  const handleDocument = (e) => {
    setDocumentSelected(e);
  };

  const handleDeficient = (e) => {
    setDeficientSelected(e);
  };

  const handleEchelon = (e) => {
    setEchelonSelected(e);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDocumentNum = (e) => {
    setDocumentNum(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleComments = (e) => {
    setComments(e.target.value);
  };

  const handleBirthDate = (e) => {
    setBirthDate(e);
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (
        data.length > 0 &&
        houseHoldDropdown &&
        Object.keys(houseHoldDropdown).length > 0
      ) {
        setName(data[0].name);
        setDocumentNum(data[0].documentNumber);
        setComments(data[0].comments);

        //lists
        setKinshipList(houseHoldDropdown.kinShips);
        setGenderList(houseHoldDropdown.gender);
        setDocumentList(houseHoldDropdown.document);

        setKinshipSelected(data[0].kinship);
        setGenderSelected(data[0].gender);
        setDocumentSelected(data[0].document);
        setDeficientSelected(data[0].deficient);
        setEchelonSelected(data[0].echelon);

        //dates
        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          }
        };
        checkDate(data[0].birthDate, setBirthDate);
        checkDate(data[0].endDate, setEndDate);
        checkDate(data[0].startDate, setStartDate);

        if (data[0].socialBenefitBeneficiary == "1") {
          setCheckFamilyAllo(true);
        } else {
          setCheckFamilyAllo(false);
        }

        if (data[0].singleParent == "1") {
          setCheckSingleParent(true);
        } else {
          setCheckSingleParent(false);
        }

        if (data[0].prenatalAllowance == "1") {
          setCheckPreNatal(true);
        } else {
          setCheckPreNatal(false);
        }
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(houseHold);
    }
  }, [houseHoldDropdown, rowClicked, houseHold]);

  const formatWithCheckIcon = (cell, row) => {
    if (cell == "1") {
      return <p>&#10003;</p>;
    }
  };

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const columns = [
    {
      dataField: "name",
      text: t(`portalrh.HouseHold.Name`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "parentescoDesign",
      text: t(`portalrh.HouseHold.Kinship`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "prenatalAllowance",
      text: t(`portalrh.HouseHold.PrenatalAllowance`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  return (
    <div className="collaboratoRH__view">
      <ButtonsUpdate OnTakingBolean={OnTakingBolean} />
      <Form>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="8" md="8">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Name`)}
            </Form.Label>
            <Form.Control
              value={name === null ? "" : name}
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              type="text"
              onChange={handleName}
              disabled={isClicked ? false : true}
            />
          </Col>

          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.dateBirth`)}
            </Form.Label>
            <DateTimePicker
              value={birthDate}
              onChange={handleBirthDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Kinship`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={kinshipList}
              handleSubmit={handleKinship}
              defaultValue={kinshipSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Gender`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={genderList}
              handleSubmit={handleGender}
              defaultValue={genderSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Handicapped`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={listDeficient}
              handleSubmit={handleDeficient}
              defaultValue={deficientSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Document`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={documentList}
              handleSubmit={handleDocument}
              defaultValue={documentSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.DocumentNumber`)}
            </Form.Label>
            <Form.Control
              value={documentNum === null ? "" : documentNum}
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              type="text"
              onChange={handleDocumentNum}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Step`)}
            </Form.Label>
            <Dropdown
              className={
                isClicked
                  ? "collaboratoRH__Dropdown2"
                  : "collaboratoRH__Dropdown"
              }
              list={listEchelon}
              handleSubmit={handleEchelon}
              defaultValue={echelonSelected}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.StartDate`)}
            </Form.Label>
            <DateTimePicker
              value={startDate}
              onChange={handleStartDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.EndDate`)}
            </Form.Label>
            <DateTimePicker
              value={endDate}
              onChange={handleEndDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <Form.Check
          className="rhcollaborator__labels__"
          inline
          type="checkbox"
          label={t(`portalrh.HouseHold.FamilyAllowanceBenefit`)}
          checked={checkFamilyAllo}
          style={{ padding: 10 }}
          onChange={() =>
            setCheckFamilyAllo((checkFamilyAllo) => !checkFamilyAllo)
          }
          disabled={isClicked ? false : true}
        />
        <Form.Check
          className="rhcollaborator__labels__"
          inline
          type="checkbox"
          label={t(`portalrh.HouseHold.PrenatalAllowance`)}
          checked={checkPreNatal}
          style={{ padding: 10 }}
          onChange={() => setCheckPreNatal((checkPreNatal) => !checkPreNatal)}
          disabled={isClicked ? false : true}
        />
        <Row>
          <Col>
            <Form.Check
              className="rhcollaborator__labels__"
              inline
              type="checkbox"
              label={t(`portalrh.HouseHold.SingleParentFamily`)}
              checked={checkSingleParent}
              style={{ paddingLeft: 10 }}
              onChange={() =>
                setCheckSingleParent((checkSingleParent) => !checkSingleParent)
              }
              disabled={isClicked ? false : true}
            />
          </Col>
        </Row>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col>
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.HouseHold.Comments`)}
            </Form.Label>
            <Form.Control
              value={comments === null ? "" : comments}
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              type="text"
              onChange={handleComments}
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <RhTableList
          tableId={"33"}
          columns={columns}
          data={houseHold}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhHousehold);
