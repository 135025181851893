import { takeLatest } from "redux-saga/effects";

import {
  GET_BUDGETMGR_CONFIGURATION,
  PERSIST_BUDGETMGR_CONFIGURATION,
  DELETE_BUDGETMGR_CONFIGURATION,
} from "../ducks/budgetmgrConfiguration/actionTypes";

import {
  getBudgetmgrConfiguration,
  persistBudgetmgrConfiguration,
  deleteBudgetmgrConfiguration,
} from "../ducks/budgetmgrConfiguration/sagas";

export const budgetmgrConfigurationSagas = [
  takeLatest(GET_BUDGETMGR_CONFIGURATION, getBudgetmgrConfiguration),
  takeLatest(PERSIST_BUDGETMGR_CONFIGURATION, persistBudgetmgrConfiguration),
  takeLatest(DELETE_BUDGETMGR_CONFIGURATION, deleteBudgetmgrConfiguration),
];
