import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Messages from "~/components/Messages";
import Spinner from "~/components/Spinner";
import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { TabsKey } from "../../utils/TabsEnum";
import ApplicationCard from "./ApplicationCard";


const BPMComponent = ({ tabKey, t }) => {
  const dispatch = useDispatch();

  const { pullingBPMData, loadingBPM, isBPMON } = useSelector(
    (state) => state.systemSettingsApplicationsReducer
  );

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATIONS) {
      dispatch(actions.pingMiddlewareApplication(BPM_MIDDLEWARE));
      const payloadIFlow = {
        url: "/main.jsp",
        application: { context: "iFlow" },
      };
      dispatch(actions.pingIFlowBasedApplication(payloadIFlow));
    }
  }, [tabKey]);

  const handlePullDataFromBPM = () => {
    dispatch(actions.pullDataFromBPM());
  };

  const handlePullDataAllMiddleware = () => {
    dispatch(actions.pullDataAllMiddleware());
  };

  const bpmCardFooter = () => (
    <>
      <Button
        className="card-button"
        variant="primary"
        disabled={!isBPMON}
        onClick={() => handlePullDataFromBPM()}
      >
        {t("admin.applications.bpm.buttonPullDataLabel")}
      </Button>
      <Button
        className="card-button"
        variant="primary"
        disabled={!isBPMON}
        onClick={() => handlePullDataAllMiddleware()}
        style={{ marginTop: "10px" }}
      >
        {t("admin.applications.bpm.buttonSynchronizeAllLabel")}
      </Button>
    </>
  );

  return (
    <>
      <ApplicationCard
        name="BPM"
        status={isBPMON}
        loading={loadingBPM}
        showIFlow={true}
        showMW={true}
        cardFooter={bpmCardFooter()}
      />
      <Spinner spinning={pullingBPMData} wrapper />
      <Messages />
    </>
  );
};

export default withNamespaces()(BPMComponent);
