export const FIND_PORTALRH_MENU = "portalRhMenu@FIND_PORTALRH_MENU";
export const SET_PORTALRH_MENU = "portalRhMenu@SET_PORTALRH_MENU";
export const SET_PORTALRH_ALL_MENUS = "portalRhMenu@SET_PORTALRH_ALL_MENUS";

export const findPortalRhMenu = () => {
  return { type: FIND_PORTALRH_MENU };
};
export const setPortalRhMenu = (menu) => {
  return { type: SET_PORTALRH_MENU, menu };
};
export const setAllPortalRhMenus = (allMenus) => {
  return { type: SET_PORTALRH_ALL_MENUS, allMenus };
};
