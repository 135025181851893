import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useLabelWidth } from "../utils/useLabelWidth";
import { useLabelFormated } from "../utils/useLabelFormated";
import { useAutoAdjust } from "../utils/useAutoAdjust";
import { withNamespaces } from "react-i18next";
const CreateTextInput = (props) => {
  const {
    field,
    isChild,
    children,
    justifyContent,
    id,
    onBlur,
    styleSheet,
    fontSize,
    setIsModalShowing,
    setCurrentInfo,
    maxWidth,
    marginRight,
    translations,
    t
  } = props;
  const [textInputValue, setTextInputValue] = useState("");
  const [required, setRequired] = useState(false);
  const [inputMask, setInputMask] = useState(false);
  const [outputMask, setOutputMask] = useState(false);
  const [formatTextInput, setFormatTextinput] = useState("");
  const [formatTextOutput, setFormatTextOutput] = useState("");
  const [emailError, setEmailError] = useState(false);

  const { labelWidthClass, labelWidth } = useLabelWidth(field);
  const { labelFormated, imageFormated, textFormated } =
  useLabelFormated(field, translations);

  const { autoAdjust } = useAutoAdjust(field);

  useEffect(() => {
    if (field) {
      let value_ = field.value;

      if (typeof field.text === "string") {
        if (field?.text?.includes("::inputMask")) {
          if (field.text?.match(/inputMask=(\d{2}:\d{2})/i)[1]) {
            if (value_) {
              //parse value
              const str = value_.slice(0, 2) + ":" + value_.slice(4, 6);

              if (str.match(/\d{2}:\d{2}/i)) {
                value_ = str;
                setFormatTextinput("00:00");
                setInputMask(true);

                if (field?.text?.includes("::outputMask")) {
                  if (field.text?.match(/outputMask=(\d{2}h:\d{2}m)/i)[1]) {
                    setFormatTextOutput("00h:00m");
                    setOutputMask(true);
                  }
                }
              } else {
                //could not parse value
                setInputMask(false);
                setFormatTextinput("");
                setOutputMask(false);
                setFormatTextOutput("");
              }
            } else {
              //value empty
              setFormatTextinput("00:00");
              setInputMask(true);
              if (field?.text?.includes("::outputMask")) {
                if (field.text?.match(/outputMask=(\d{2}h:\d{2}m)/i)[1]) {
                  setFormatTextOutput("00h:00m");
                  setOutputMask(true);
                }
              }
            }
          }
        }
      }
      if (value_) setTextInputValue(value_);

      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
    }
  }, [field]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const maskTimer = (value) => {
    const valueReplaced = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1:$2")
      .replace(/(\d{2})(\d)/);

    return valueReplaced;
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const handleBlur = () => {
    if (field.variable === "userEmail") {
      if (!isEmailValid(textInputValue)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
  };

  return (
    <>
      <Form.Group
        controlId={`formText-${field.variable}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: marginRight ? marginRight : "0px",
          marginBottom: styleSheet?.compact ? "5px" : "10px",
          flexGrow: "1",
          maxWidth: maxWidth ? maxWidth : ""
        }}
      >
        {!isChild ? (
            <Form.Label
              column
              className={
                labelWidthClass ? labelWidthClass : "iflow-form-label-fix"
              }
              style={
                labelWidth
                  ? {
                        width: labelWidth,
                        minWidth: labelWidth,
                        flexBasis: labelWidth,
                      }
                  : {}
              }
            >
              {ReactHtmlParser(
                labelFormated
                  ? labelFormated
                  : field.text && field.text.b
                  ? field.text.b
                  : field.text
              )}
              {imageFormated && (
              <img
                style={{ width: "20px", height: "20px", cursor: "pointer", marginLeft:'10px' }}
                src={imageFormated.props.src}
                className={imageFormated.props.className}
                alt="..."
                onClick={() => {
                  setIsModalShowing(true);
                  setCurrentInfo(textFormated);
                }}
              />
            )}
            </Form.Label>
            
        ) : (
          <Form.Label
            column
            className={
              labelWidthClass ? labelWidthClass : "iflow-form-label-fix"
            }
            style={
              labelWidth
                ? {
                    width: labelWidth,
                    minWidth: labelWidth,
                    flexBasis: labelWidth,
                    paddingTop: field.disabled === "true" ? "0" : "0.375rem",
                    paddingBottom: field.disabled === "true" ? "0" : "0.375rem",
                  }
                : {
                    paddingTop: field.disabled === "true" ? "0" : "0.375rem",
                    paddingBottom: field.disabled === "true" ? "0" : "0.375rem",
                  }
            }
          >
            {ReactHtmlParser(
              field.text && field.text.b ? field.text.b : field.text
            )}
          </Form.Label>
        )}
        <Col
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            flexGrow: "2",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: justifyContent ? justifyContent : "",
            width: autoAdjust ? "100%" : "",
          }}
        >
          {field.disabled === "true" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: isChild ? "5px" : "0",
                paddingRight: isChild ? "5px" : "0",
                paddingTop: isChild ? "" : "0.375rem",
                paddingBottom: isChild ? "" : "0.375rem",
                width: autoAdjust ? "100%" : "",
              }}
            >
              <span style={{ fontWeight: "500" }} id={id ? id : ""}>
                {textInputValue}
              </span>
              {field.suffix !== "" && field.suffix !== undefined && (
                <InputGroup.Append>
                  <InputGroup.Text
                    id={`append-${field.variable}`}
                    className="create-text-input-suffix-hidden"
                  >
                    {field.suffix}
                  </InputGroup.Text>
                </InputGroup.Append>
              )}
              {children && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: "1",
                  }}
                >
                  {children}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: isChild ? "5px" : "0",
                paddingRight: isChild ? "5px" : "0",
                width: autoAdjust ? "100%" : "",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: "1",
                }}
              >
                <input
                  type={inputMask ? "time" : "text"}
                  id={id ? id : ""}
                  className={
                    "form-control" +
                    (field.suffix !== undefined && field.suffix !== ""
                      ? " create-text-input-suffix-fix"
                      : "")
                  }
                  style={{
                    width: autoAdjust ? "100%" : field.size ? "auto" : "100%",
                    cursor: "text",
                    fontSize: `${fontSize}px`,
                    border: emailError ? "1px solid red": ""
                  }}
                  size={field.size}
                  name={
                    inputMask || outputMask
                      ? `TextBoxToTransform?outputFormat=${formatTextOutput}&variable=${field.variable}`
                      : field.variable
                  }
                  placeholder={
                    inputMask
                      ? formatTextInput
                      : isChild
                      ? field.text && field.text.b
                        ? field.text.b
                        : field.text
                      : ""
                  }
                  value={textInputValue}
                  maxLength={field.maxlength}
                  onChange={
                    inputMask
                      ? (e) => setTextInputValue(maskTimer(e.target.value))
                      : (e) => setTextInputValue(e.target.value)
                  }
                  required={required}
                  onBlur={onBlur == undefined ? handleBlur : onBlur}
                  onKeyDown={handleKeyDown}
                />
                {emailError  && <div style={{margin:'10px'}} className="error-message">{t("admin.userManagement.persist.requiredEmail")}</div>}
                {field.suffix !== "" && field.suffix !== undefined && (
                  <InputGroup.Append>
                    <InputGroup.Text
                      className={"create-text-input-suffix"}
                      id={`append-${field.variable}`}
                    >
                      {field.suffix}
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </div>
              <Form.Control.Feedback type="invalid">
                {`O campo ${
                  field.text && field.text.b ? field.text.b : field.text
                } é de preenchimento obrigatório`}
              </Form.Control.Feedback>
              {children && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: "1",
                  }}
                >
                  {children}
                </div>
              )}
            </div>
          )}
        </Col>
      </Form.Group>
      {styleSheet?.highLightRows && !isChild && <hr />}
    </>
  );
};
export default withNamespaces()(CreateTextInput);
