import {
  SET_BUDGETMGR_CONFIGURATION,
  CLEAR_BUDGETMGR_CONFIGURATION,
  PERSIST_BUDGETMGR_CONFIGURATION,
  SUCCESS_PERSIST_BUDGETMGR_CONFIGURATION,
  DELETE_BUDGETMGR_CONFIGURATION,
} from "./actionTypes";

const initialState = {
  budgetmgrConfiguration: [],
  successPersistBudgetmgrConfiguration: false,
};

export const budgetmgrConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERSIST_BUDGETMGR_CONFIGURATION:
      return {
        ...state,
        successPersistBudgetmgrConfiguration: false,
      };
    case SUCCESS_PERSIST_BUDGETMGR_CONFIGURATION:
      return {
        ...state,
        successPersistBudgetmgrConfiguration: true,
      };
    case SET_BUDGETMGR_CONFIGURATION:
      return {
        ...state,
        budgetmgrConfiguration: action.configuration,
      };
    case DELETE_BUDGETMGR_CONFIGURATION:
      return {
        ...state,
        successPersistBudgetmgrConfiguration: false,
      };
    case CLEAR_BUDGETMGR_CONFIGURATION:
      return {
        ...state,
        budgetmgrConfiguration: initialState.budgetmgrConfiguration,
      };
    default:
      return state;
  }
};
