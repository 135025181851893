import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Modal, Button, Form, Col } from "react-bootstrap";

const PreviewMultiple = ({ t, handleCloseSignModal, testSignPdf }) => {
  const [coordinates, setCoordinates] = useState({ x: 0.1, y: 0.1 });
  const [reduced, setReduced] = useState(false);
  const [givenLocation, setGivenLocation] = useState("");
  const [givenReason, setGivenReason] = useState("");

  return (
    <>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px",
          }}
        >
          <Col sm="12" md="8" lg="8">
            <Form.Row>
              <Form.Check
                type="switch"
                id="reduced"
                label={t("signature.reduced")}
                style={{ marginBottom: "10px" }}
                checked={reduced}
                onChange={() => setReduced((reduced) => !reduced)}
              />
            </Form.Row>
          </Col>
          <Col sm="12" md="8" lg="8">
            <Form.Row>
              <Form.Label>{t("signature.givenReason")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                type="text"
                value={givenReason}
                onChange={(e) => {
                  setGivenReason(e.target.value);
                }}
              />
            </Form.Row>
          </Col>
          <br />
          <Col sm="12" md="8" lg="8">
            <Form.Row>
              <Form.Label>{t("signature.givenLocation")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                type="text"
                style={{ marginBottom: "10px" }}
                value={givenLocation}
                onChange={(e) => {
                  setGivenLocation(e.target.value);
                }}
              />
            </Form.Row>
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="card-button onboarding-button"
          style={{
            backgroundColor: "#33058D",
            border: "none",
            minHeight: "42px",
          }}
          onClick={() => {
            handleCloseSignModal(false);
            testSignPdf(
              coordinates,
              1,
              false,
              reduced,
              givenLocation,
              givenReason
            );
          }}
        >
          {t("signature.signPdf")}
        </Button>
      </Modal.Footer>
    </>
  );
};
export default withNamespaces()(PreviewMultiple);
