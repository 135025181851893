export const GET_ALL_CATEGORIES = "claimsManagement@GET_ALL_CATEGORIES";
export const DONE_GET_ALL_CATEGORIES =
  "claimsManagement@DONE_GET_ALL_CATEGORIES";
export const SET_ALL_CATEGORIES = "claimsManagement@SET_ALL_CATEGORIES";
export const GET_MANUAL_CLAIM = "claimsManagement@GET_MANUAL_CLAIM";
export const SET_MANUAL_CLAIM = "claimsManagement@SET_MANUAL_CLAIM";
export const GET_COMPLAINTS = "claimsManagement@GET_ALL_COMPLAINTS";
export const DONE_GET_COMPLAINTS = "claimsManagement@DONE_GET_ALL_COMPLAINTS";
export const SET_COMPLAINTS = "claimsManagement@SET_ALL_COMPLAINTS";
export const EXPORT_CLAIMS = "claimsManagment@EXPORT_cLAIMS";

export const GET_SUBCLAIMS = "claimsManagement@GET_ALL_SUBCLAIMS";
export const DONE_GET_SUBCLAIMS = "claimsManagement@DONE_GET_ALL_SUBCLAIMS";
export const SET_SUBCLAIMS = "claimsManagement@SET_ALL_SUBCLAIMS";

export const SET_UNIKPAGE = "claimsManagement@SET_UNIKPAGE";

export const GET_ALL_RESULTS_CATEGORIES =
  "claimsManagement@GET_ALL_RESULTS_CATEGORIES";
export const DONE_ALL_RESULTS_CATEGORIES =
  "claimsManagement@SET_ALL_CATEGODONE_ALL_RESULTS_CATEGORIESRIES";
export const SET_ALL_RESULTS_CATEGORIES =
  "claimsManagement@SET_ALL_RESULTS_CATEGORIES";

export const GET_DATA_RESULTS = "claimsManagement@GET_DATA_RESULTS";
export const DONE_DATA_RESULTS = "claimsManagement@DONE_DATA_RESULTS";
export const SET_DATA_RESULTS = "claimsManagement@SET_DATA_RESULTS";

export const GET_COLUMNS_RESULTS = "claimsManagement@GET_COLUMNS_RESULTS";
export const DONE_COLUMNS_RESULTS = "claimsManagement@DONE_COLUMNS_RESULTS";
export const SET_COLUMNS_RESULTS = "claimsManagement@SET_COLUMNS_RESULTS";

export const GET_SUBDATA_RESULTS = "claimsManagement@GET_SUBDATA_RESULTS";
export const DONE_SUBDATA_RESULTS = "claimsManagement@DONE_SUBDATA_RESULTS";
export const SET_SUBDATA_RESULTS = "claimsManagement@SET_SUBDATA_RESULTS";

export const GET_DATA_TOPS = "claimsManagement@GET_DATA_TOPS";
export const DONE_DATA_TOPS = "claimsManagement@DONE_DATA_TOPS";
export const SET_DATA_TOPS = "claimsManagement@SET_DATA_TOPS";

export const classifyManualClaim = (claims) => ({
  type: GET_MANUAL_CLAIM,
  claims,
});
export const exportClaims = (exportTypeId, categoryId, claim) => ({
  type: EXPORT_CLAIMS,
  exportTypeId,
  categoryId,
  claim,
});
export const setManualClaim = (result) => ({ type: SET_MANUAL_CLAIM, result });
export const getAllCategories = () => ({ type: GET_ALL_CATEGORIES });
export const doneGetAllCategories = () => ({ type: DONE_GET_ALL_CATEGORIES });
export const setAllCategories = (categories) => ({
  type: SET_ALL_CATEGORIES,
  categories,
});

export const getComplaints = (id, beginIndex, endIndex) => ({
  type: GET_COMPLAINTS,
  id,
  beginIndex,
  endIndex,
});
export const doneGetComplaints = () => ({ type: DONE_GET_COMPLAINTS });
export const setComplaints = (complaints) => ({
  type: SET_COMPLAINTS,
  complaints,
});

export const getSubClaims = (id, beginIndex, endIndex, claimId) => ({
  type: GET_SUBCLAIMS,
  id,
  beginIndex,
  endIndex,
  claimId,
});
export const doneGetSubClaims = () => ({ type: DONE_GET_SUBCLAIMS });
export const setSubClaims = (subClaims) => ({
  type: SET_SUBCLAIMS,
  subClaims,
});

export const setUnikPage = (unikPag) => ({
  type: SET_UNIKPAGE,
  unikPag,
});

export const getAllResultsCategories = () => ({
  type: GET_ALL_RESULTS_CATEGORIES,
});
export const doneGetAllResultCategories = () => ({
  type: DONE_ALL_RESULTS_CATEGORIES,
});
export const setAllResultsCategories = (resultsCategories) => ({
  type: SET_ALL_RESULTS_CATEGORIES,
  resultsCategories,
});

export const getDataResults = (id) => ({
  type: GET_DATA_RESULTS,
  id,
});
export const doneDataResults = () => ({
  type: DONE_DATA_RESULTS,
});
export const setDataResults = (data) => ({
  type: SET_DATA_RESULTS,
  data,
});

export const getColumnsResults = () => ({
  type: GET_COLUMNS_RESULTS,
});
export const doneColumnsResults = () => ({
  type: DONE_COLUMNS_RESULTS,
});
export const setColumnsResults = (columns) => ({
  type: SET_COLUMNS_RESULTS,
  columns,
});

export const getSubDataResults = (claimId, categoryId) => ({
  type: GET_SUBDATA_RESULTS,
  claimId,
  categoryId,
});
export const doneSubDataResults = () => ({
  type: DONE_SUBDATA_RESULTS,
});
export const setSubDataResults = (subData) => ({
  type: SET_SUBDATA_RESULTS,
  subData,
});

export const getDataTops = (obj) => ({
  type: GET_DATA_TOPS,
  obj,
});
export const doneDataTops = () => ({
  type: DONE_DATA_TOPS,
});
export const setDataTops = (dataTops) => ({
  type: SET_DATA_TOPS,
  dataTops,
});
