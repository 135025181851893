import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "fast-text-encoding/text.min";

import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import "./i18n";
import App from "./containers/App";
import "./assets/scss/layout.scss"; //SHolanda
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./store/store";
import Keycloak from "keycloak-js";

//TODO enquanto nao se resolve o problema abaixo fazer o codigo que tá descomentado
//com o fetch o código fica a fazer o mesmo pedido eternamente... a app funciona na mesma mas fica muito lenta
/* fetch(process.env.PUBLIC_URL + '/keycloak.json').then((res)=>res.json()).then((data)=>{
  render(
    <Provider store={store}>
      <App keycloak={new Keycloak(data)}/>
    </Provider>,
    document.getElementById("root")
  );
}) */

render(
  <Provider store={store}>
    <App
      isCma={false}
      keycloak={
        new Keycloak({
          url: "https://dummy-url.pt",
          realm: "dummyRealm",
          clientId: "dummyClientId",
        })
      }
    />
  </Provider>,
  document.getElementById("root")
);
