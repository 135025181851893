import React from "react";
import { withNamespaces } from "react-i18next";
import { Form } from "react-bootstrap";

const ColorList = (props) => {
  const { t, color, setColor } = props;

  const colors = [
    "#61bd4f",
    "#f2d600",
    "#ff9f1a",
    "#eb5a46",
    "#c377e0",
    "#0079bf",
    "#00c2e0",
    "#51e898",
    "#ff78cb",
    "#344563",
  ];

  const renderColor = () => {
    return colors.map((c, i) => {
      return (
        <div
          key={i}
          className="tasks-color-item"
          onClick={() => {
            setColor(c);
          }}
        >
          <div
            className="tasks-color-item-inner"
            style={{
              backgroundColor: c,
            }}
          >
            {color && color === c && <i className="icon-check" />}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div style={{ padding: "15px 5px" }}>
        {" "}
        {t("taskPage.categories.selectColor")}
      </div>
      <div className="tasks-color-wrapper">{renderColor()}</div>
      <div style={{ display: "flex" }}>
        <Form.Control
          bsPrefix="color-control"
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          title="Choose your color"
        />
        <div style={{ padding: "0px 10px" }}>
          <span className="tasks-text-bold">
            {t("taskPage.categories.customColor")}
          </span>
          <br />
          {t("taskPage.categories.clickToSelect")}
        </div>
      </div>
    </>
  );
};
export default withNamespaces()(ColorList);
