import qs from "qs";
import http from "~/utils/config/http";
import { put } from "redux-saga/effects";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { activeError } from "~/components/Messages/actions";
import { setDocument } from "./actionTypes";

export function* findDocument({ docid }) {
  try {
    console.log(docid);
    const resp = yield http.get(
      `/document/findById?docid=${docid}`,
      BPM_MIDDLEWARE
    );
    //console.log(resp.data)
    yield put(setDocument(resp.data));
  } catch (e) {
    console.log(e);
    yield put(setDocument(null));
    yield put(activeError("general.error"));
  }
}
