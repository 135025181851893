import { takeLatest } from "redux-saga/effects";

import {
  GET_ALL_WORKPLANS,
  GET_ALL_WORKPLANS_BY_ORG_GROUP,
  UPDATE_WORKPLAN,
  GET_ALL_COLLABORATORS,
  GET_ALL_DEPARTMENTS,
  GET_ALL_PROVIDERS,
  GET_ALL_PROVIDERTYPES,
  GET_ALL_EXPENDITURETYPES,
  GET_ALL_FUNCTIONS,
  GET_ALL_EXPENSES,
  GET_ALL_CATEGORIES,
  GET_ALL_BUSINESSES,
  GET_ALL_PROJECT_TYPES,
  GET_ALL_PROBABILITIES,
  GET_ALL_PROPOSALS,
  GET_ALL_STATES,
  MAKE_NEW_VERSION,
  MAKE_WORKPLAN_ACTIVE,
  SAVE_CHANGES_WORKPLAN,
  DELETE_WORKPLAN,
  DELETE_DOCUMENT,
  GET_ALL_TEAMS,
  GET_ALL_PAYMENT_TYPES,
  GET_ALL_PARTNERS,
  GET_DOCUMENT_INFO,
  GET_DOCUMENT,
  GET_PROPOSAL,
  GET_ALL_CLIENT_DIRECTORS,
  GET_ALL_CLIENTS,
  GET_ALL_FACTORING,
  GET_ALL_INVOICING_MODE,
  GET_ALL_PROPOSAL_TYPES,
  GET_ALL_BY_CLIENT_DIRECTOR,
  SAVE_CONTACT,
  SAVE_PROVIDER,
  GET_ALL_COMPANIES,
  SAVE_DOCUMENT,
  GET_CLIENTS,
  GET_PROVIDERS,
  UPDATE_CLIENT_ID,
  UPDATE_PROVIDER_ID,
  SAVE_CLIENTS,
  SAVE_PROVIDERS,
  DELETE_CLIENT_DATA,
  DELETE_PROVIDER_DATA,
} from "../ducks/budgetManagement/actionTypes";

import {
  getAllWorkplans,
  getAllWorkplansByOrgGroup,
  updateWorkplan,
  makeNewVersion,
  getAllCollaborators,
  getAllDepartments,
  getAllProviderTypes,
  getAllProviders,
  getAllExpenditureTypes,
  getAllExpenses,
  getAllFunctions,
  getAllCategories,
  getAllBusinesses,
  getAllProbabilities,
  getAllProjectTypes,
  getAllProposals,
  getAllStates,
  makeWorkplanActive,
  saveChangesWorkplan,
  deleteWorkplan,
  deleteDocument,
  getAllClientDirectors,
  getAllPartners,
  getAllPaymentTypes,
  getAllTeams,
  getAllByClientDirector,
  getDocumentByWorkplan,
  getProposalByWorkplan,
  getAllClients,
  getAllFactoring,
  getAllInvoicingMode,
  getAllProposalTypes,
  saveContact,
  saveProvider,
  getAllCompanies,
  getDocument,
  saveDocument,
  getClients,
  getProviders,
  updateClientId,
  updateProviderId,
  saveClientsData,
  saveProvidersData,
  deleteClientData,
  deleteProviderData,
} from "../ducks/budgetManagement/sagas";

export const budgetManagementSagas = [
  takeLatest(GET_ALL_WORKPLANS, getAllWorkplans),
  takeLatest(GET_ALL_WORKPLANS_BY_ORG_GROUP, getAllWorkplansByOrgGroup),
  takeLatest(UPDATE_WORKPLAN, updateWorkplan),
  takeLatest(GET_ALL_COLLABORATORS, getAllCollaborators),
  takeLatest(GET_ALL_DEPARTMENTS, getAllDepartments),
  takeLatest(GET_ALL_PROVIDERS, getAllProviders),
  takeLatest(GET_ALL_PROVIDERTYPES, getAllProviderTypes),
  takeLatest(GET_ALL_EXPENDITURETYPES, getAllExpenditureTypes),
  takeLatest(GET_ALL_EXPENSES, getAllExpenses),
  takeLatest(GET_ALL_FUNCTIONS, getAllFunctions),
  takeLatest(GET_ALL_CATEGORIES, getAllCategories),
  takeLatest(GET_ALL_BUSINESSES, getAllBusinesses),
  takeLatest(GET_ALL_PROBABILITIES, getAllProbabilities),
  takeLatest(GET_ALL_PROJECT_TYPES, getAllProjectTypes),
  takeLatest(GET_ALL_PROPOSALS, getAllProposals),
  takeLatest(GET_ALL_STATES, getAllStates),
  takeLatest(MAKE_NEW_VERSION, makeNewVersion),
  takeLatest(MAKE_WORKPLAN_ACTIVE, makeWorkplanActive),
  takeLatest(SAVE_CHANGES_WORKPLAN, saveChangesWorkplan),
  takeLatest(DELETE_WORKPLAN, deleteWorkplan),
  takeLatest(GET_ALL_TEAMS, getAllTeams),
  takeLatest(GET_ALL_CLIENT_DIRECTORS, getAllClientDirectors),
  takeLatest(GET_ALL_PARTNERS, getAllPartners),
  takeLatest(GET_ALL_PAYMENT_TYPES, getAllPaymentTypes),
  takeLatest(GET_DOCUMENT_INFO, getDocumentByWorkplan),
  takeLatest(GET_PROPOSAL, getProposalByWorkplan),
  takeLatest(GET_ALL_CLIENTS, getAllClients),
  takeLatest(GET_ALL_FACTORING, getAllFactoring),
  takeLatest(GET_ALL_INVOICING_MODE, getAllInvoicingMode),
  takeLatest(GET_ALL_PROPOSAL_TYPES, getAllProposalTypes),
  takeLatest(SAVE_CONTACT, saveContact),
  takeLatest(SAVE_PROVIDER, saveProvider),
  takeLatest(GET_ALL_COMPANIES, getAllCompanies),
  takeLatest(GET_DOCUMENT, getDocument),
  takeLatest(SAVE_DOCUMENT, saveDocument),
  takeLatest(DELETE_DOCUMENT, deleteDocument),
  takeLatest(GET_CLIENTS, getClients),
  takeLatest(GET_PROVIDERS, getProviders),
  takeLatest(UPDATE_CLIENT_ID, updateClientId),
  takeLatest(UPDATE_PROVIDER_ID, updateProviderId),
  takeLatest(SAVE_CLIENTS, saveClientsData),
  takeLatest(SAVE_PROVIDERS, saveProvidersData),
  takeLatest(DELETE_CLIENT_DATA, deleteClientData),
  takeLatest(DELETE_PROVIDER_DATA, deleteProviderData),
  takeLatest(GET_ALL_BY_CLIENT_DIRECTOR, getAllByClientDirector),
];
