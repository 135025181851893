import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Messages from "~/components/Messages";
import SearchableDropDown from "~/components/SearchableDropDown";
import { hasRoles } from "~/components/security/utils";
import Spinner from "~/components/Spinner";
import {
    clearOrganizations, getOrganizations
} from "~/store/ducks/organizations/actionTypes";
import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";
import { getPuzzleItemsByOrgId, savePuzzleItems } from "../actions";
import PuzzleCard from "./PuzzleCard";




const AdminSettingsPuzzle = ({ ...props }) => {
  const { t } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { puzzleItems, puzzleItemsByOrgId, isSavingPuzzleItems } = useSelector(
    (state) => state.adminConfigReducer
  );
  const { organizations } = useSelector((state) => state.organizationsReducer);

  const [orgAdminCards, setOrgAdminCards] = useState(undefined); //User Cards (USED IN ORG ADMIN)
  const [adminCards, setAdminCards] = useState(undefined); //Organization Cards (USED IN ADMIN)
  const [organizationId, setOrganizationId] = useState(""); //Organization's ID (USED IN ADMIN)

  const sortCards = (initialArray) => {
    const arr1 = initialArray.filter((card) => card.active === true);
    const arr2 = initialArray.filter((card) => card.active === false);
    return arr1.concat(arr2);
  };

  /**
   * Gets organizations so that user can save puzzle
   * Either it's an Admin or Orgadmin
   */
  useEffect(() => {
    dispatch(getOrganizations());
    return () => {
      dispatch(clearOrganizations());
    };
  }, [dispatch]);

  /**
   * Changes puzzle when selecting different organizations in Dropdown
   * Used in Admin user
   */
  useEffect(() => {
    if (hasRoles(user, [ROLE_ADMIN], true) && organizationId) {
      dispatch(getPuzzleItemsByOrgId(organizationId));
    }
  }, [organizationId]);

  /**
   * Gets Puzzle items by provided organization ID
   * Used in Admin user
   */
  useEffect(() => {
    const array = puzzleItems.map((item) => {
      if (item.active === null) {
        item.active = false;
        return item;
      } else return item;
    });
    array.sort((a, b) => a.nrOrder - b.nrOrder);
    setOrgAdminCards(array);
  }, [puzzleItems]);

  /**
   * Gets Puzzle items by User's organization ID
   * Used in OrgAdmin user
   */
  useEffect(() => {
    const array = puzzleItemsByOrgId.map((item) => {
      if (item.active === null) {
        item.active = false;
        return item;
      } else return item;
    });
    array.sort((a, b) => a.nrOrder - b.nrOrder);
    hasRoles(user, [ROLE_ADMIN], true)
      ? setAdminCards(array)
      : setOrgAdminCards(array);
  }, [puzzleItemsByOrgId]);

  /**
   * Gets user's organization ID to show puzzle cards
   * Used in Admin user
   */
  useEffect(() => {
    if (user) {
      if (user.organization && user.organization.id) {
        setOrganizationId(user.organization.id);
      }
    }
  }, [user]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = hasRoles(user, [ROLE_ADMIN], true)
        ? adminCards[dragIndex]
        : orgAdminCards[dragIndex];
      const newCards = update(
        hasRoles(user, [ROLE_ADMIN], true) ? adminCards : orgAdminCards,
        {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }
      );
      hasRoles(user, [ROLE_ADMIN], true)
        ? setAdminCards(sortCards(newCards))
        : setOrgAdminCards(sortCards(newCards));
    },
    [adminCards, orgAdminCards]
  );

  const toggleActive = (index) => {
    const newCards = adminCards.map((card, i) => {
      if (i === index)
        return {
          ...card,
          active: !card.active,
        };
      else return card;
    });
    hasRoles(user, [ROLE_ADMIN], true)
      ? setAdminCards(sortCards(newCards))
      : setOrgAdminCards(sortCards(newCards));
  };

  /**
   * When the user is an Org Admin renderCard only renders the active orgAdminCards and disables toggle
   */
  const renderCard = (card, index) => {
    if (
      (hasRoles(user, [ROLE_ORG_ADMIN], true) && card.active) ||
      hasRoles(user, [ROLE_ADMIN], true)
    ) {
      return (
        <PuzzleCard
          disabled={!hasRoles(user, [ROLE_ADMIN], true)}
          key={index}
          index={index}
          card={card}
          toggleActive={toggleActive}
          moveCard={moveCard}
        />
      );
    }
  };

  const savePuzzle = () => {
    const orderedPuzzleItems = hasRoles(user, [ROLE_ADMIN], true)
      ? adminCards.map((card, i) => {
          card.nrOrder = i;
          return card;
        })
      : orgAdminCards.map((card, i) => {
          card.nrOrder = i;
          return card;
        });

    dispatch(savePuzzleItems(orderedPuzzleItems, organizationId));
  };

  //Converts list used in SearchableDropdown to the correct format
  const adaptListOfOrganizations = (list) => {
    const org = list.find((item) => item.id == organizationId);
    let list_ = [...organizations];
    if (org) {
      list_ = list.map((organization) => {
        if (org.id === organization.id) {
          return {
            value: organization.id.toString(),
            label: organization.description,
            selected: true,
          };
        } else
          return {
            value: organization.id.toString(),
            label: organization.description,
          };
      });
    } else {
      list_ = list.map((organization) => {
        if (organization.label === "uniksystem") {
          return {
            value: organization.id.toString(),
            label: organization.description,
            selected: true,
          };
        } else
          return {
            value: organization.id.toString(),
            label: organization.description,
          };
      });
    }
    return list_;
  };

  return (
    <>
      <Form.Row>
        {hasRoles(user, [ROLE_ADMIN], true) && (
          <Col lg="7">
            <Form.Group>
              <Form.Label>
                {t("admin.userManagement.persist.organization")}
              </Form.Label>
              <div
                style={{ marginRight: "80px", display: "flex", width: "100%" }}
              >
                <div style={{ marginRight: "20px", width: "70%" }}>
                  <SearchableDropDown
                    name={"Empresa"}
                    required={true}
                    placeholder="Selecionar empresa"
                    list={adaptListOfOrganizations(organizations)}
                    handleSubmit={(value) => setOrganizationId(value)}
                  />
                </div>
                <div>
                  <Button className="card-button" onClick={() => savePuzzle()}>
                    {t("general.save")} puzzle
                  </Button>
                </div>
              </div>
            </Form.Group>
          </Col>
        )}
        {hasRoles(user, [ROLE_ADMIN], true)
          ? adminCards && (
              <Col lg={4} sm={10} style={{ paddingTop: "32px" }}>
                {adminCards.map((card, i) => renderCard(card, i))}
                <Messages />
              </Col>
            )
          : orgAdminCards && (
              <Col lg={4} sm={10}  style={{ paddingTop: "32px" }}>
                {orgAdminCards.map((card, i) => renderCard(card, i))}
                <Messages />
              </Col>
            )}
      </Form.Row>
      <Spinner spinning={isSavingPuzzleItems} wrapper />
    </>
  );
};

export default withNamespaces()(AdminSettingsPuzzle);
