import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { find } from "lodash";
import CreateCalculosCusto from "./CreateCalculosCusto";
import CreateCalculosComparativo from "./CreateCalculosComparativo";
import CreateCalculosRendimento from "./CreateCalculosRendimento";
import CreateCalculosVistorias from "./CreateCalculosVistorias";
import { handleAccrodionKey } from "../utils";
import CreateCalculosProspecao from "./CreateCalculosProspecao/Prospecao";
import CreateCalculosCustoConsulta from "./CreateCalculosCustoConsulta";
import CreateCalculosComparativoConsulta from "./CreateCalculosComparativoConsulta";
import CreateCalculosRendimentoConsulta from "./CreateCalculosRendimentoConsulta";
import CreateCalculosProspecaoConsulta from "./CreateCalculosProspecaoConsulta";

const CreateAccordion = (props) => {
  const {
    tabArray,
    renderBlockDivision,
    activeKey,
    tabDiv,
    renderColumnContent,
    header,
    flowId,
    styleSheet,
    style,
    translations,
    language
  } = props;

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const [closedAccordion, setClosedAccordion] = useState(false);

  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.bpm.processos" });
    if (processosMenu) {
      const extraConfiguration_ = JSON.parse(
        processosMenu.extraConfiguration || false
      );
      const closedAccordion = extraConfiguration_.closedAccordion
        ? extraConfiguration_.closedAccordion
        : [];
      if (closedAccordion.some((i) => i == flowId)) {
        setClosedAccordion(true);
      }
    }
  }, [flowId]);

  const handleRAIaccordion = (tab) => {
    const fields = tab?.blockdivision[0]?.columndivision?.field || [];
    const foundItem = fields.find((f) => f.variable === "seccao");
    if (foundItem && header?.text !== "RAI - Detalhe") {
      const foundSelection = foundItem.option.find(
        (op) => op.selected === "yes"
      );
      if (foundSelection) {
        if (
          foundSelection.text === "MÉTODO DO CUSTO DE CONSTRUÇÃO OU REPOSIÇÃO"
        )
          return <CreateCalculosCusto blockdivision={tab.blockdivision} styleSheet={styleSheet} />;
        if (foundSelection.text === "MÉTODO COMPARATIVO DE MERCADO")
          return (
            <CreateCalculosComparativo blockdivision={tab.blockdivision} styleSheet={styleSheet} />
          );
        if (
          foundSelection.text ===
          "MÉTODO DO RENDIMENTO OU CAPITALIZAÇÃO DAS RENDAS"
        )
          return <CreateCalculosRendimento blockdivision={tab.blockdivision} styleSheet={styleSheet} />;
        if (foundSelection.text === "PROSPEÇÃO")
          return <CreateCalculosProspecao blockdivision={tab.blockdivision} />;
      }
    } else if (foundItem && header?.text === "RAI - Detalhe") {
      const foundSelection = foundItem.option.find(
        (op) => op.selected === "yes"
      );
      if (foundSelection) {
        if (
          foundSelection.text === "MÉTODO DO CUSTO DE CONSTRUÇÃO OU REPOSIÇÃO"
        )
          return (
            <CreateCalculosCustoConsulta blockdivision={tab.blockdivision} styleSheet={styleSheet}/>
          );

        if (foundSelection.text === "MÉTODO COMPARATIVO DE MERCADO")
          return (
            <CreateCalculosComparativoConsulta
              blockdivision={tab.blockdivision}
            />
          );
        if (
          foundSelection.text ===
          "MÉTODO DO RENDIMENTO OU CAPITALIZAÇÃO DAS RENDAS"
        )
          return (
            <CreateCalculosRendimentoConsulta
              blockdivision={tab.blockdivision} styleSheet={styleSheet}
            />
          );

        if (foundSelection.text === "PROSPEÇÃO")
          return (
            <CreateCalculosProspecaoConsulta
              blockdivision={tab.blockdivision}
            />
          );
      }
    }
    return renderBlockDivision(tab.blockdivision);
  };

  const AccordionWrapper = (props) => {
    const { closedAccordion, children, tabId} = props;
    return closedAccordion ? (
      <Accordion>{children}</Accordion>
    ) : (
      <Accordion style={style} defaultActiveKey={tabId}>{children}</Accordion>
    );
  };

  const translateText=(name)=>{
    let text = undefined;
    if (name.includes("::t::")){
      let lingua = language.trim();
      let splittext = name.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if(translations){
        text = translations?.[lingua][texttoshow] + texttoadd
      }
    } else {
      text = name
    }
    return text;
  }

  return (
    <>
      {tabArray.map((tab, index) => {
        return (
          <AccordionWrapper closedAccordion={closedAccordion} tabId={tab.id} >
          <Card
            key={index}
            style={{
              overflow: "visible",
              marginTop: styleSheet?.compact ? "0px" : "20px",
            }}
          >
            <Accordion.Toggle
              as={Card.Header}
              eventKey={tab.id}
              onClick={() => {
                handleAccrodionKey(tab.id, tabDiv);
              }}
              className="card-header-toggle"
            >
              {translateText(tab.name)}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={tab.id}>
              <Card.Body
                style={{
                  marginBottom: "5px",
                  padding: styleSheet?.compact ? "0px" : "15px",
                }}
              >
                <br />
                {tab.name === "RAI" ? (
                  handleRAIaccordion(tab)
                ) : tab.name === "Dados da Vistoria" ? (
                  <CreateCalculosVistorias
                    blockdivision={tab.blockdivision}
                    renderColumnContent={renderColumnContent}
                    styleSheet={styleSheet}
                  />
                ) : (
                  renderBlockDivision(tab.blockdivision)
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          </AccordionWrapper>
        );
      })}
    </>
  );
};
export default CreateAccordion;
