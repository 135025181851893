import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { Modal, Button, Alert, Form } from "react-bootstrap";
import Spinner from "~/components/Spinner";
import Messages from "~/components/Messages";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import UserModal from "./UserModal";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import {
  getRoles,
  deleteRole,
  findUsersByRole,
  addDefaultRole,
  deleteDefaultRole,
} from "../actions";
import uuid from "uuid/v1";

const RolesTable = ({ roles, defaultRoles, t, persistRole }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [showUsers, setShowUsers] = useState(false);

  const { isLoadingRoles, isDeleting, isPersisting, users, isLoadingUsers } =
    useSelector((state) => state.roleReducer);

  const handleDefaultProfile = (profile, isChecked) => {
    if (isChecked) dispatch(deleteDefaultRole(profile.id));
    else dispatch(addDefaultRole(profile.id));
  };

  const buildActionsColumn = (_, row, {}) => (
    <>
      <i
        className="icon-trash table-action-icon"
        onClick={() => openModal(row)}
      />
      <i
        className="icon-edit-user table-action-icon"
        onClick={() => history.push(`/uniksystem/admin/role/update/${row.id}`)}
      />
      <i
        className="icon-hr table-action-icon"
        onClick={() => openModalUsers(row)}
      />
    </>
  );

  const buildDefaultProfileColumn = (cell, row, rowIndex, formatExtraData) => {
    const isDefaultProfile = (row) => {
      return formatExtraData.some((role) => role.id === row.id);
    };
    return (
      <>
        {isDefaultProfile(row) ? (
          <Form.Check
            type="switch"
            id={uuid()}
            label=""
            checked={true}
            onChange={(e) => handleDefaultProfile(row, true)}
          />
        ) : (
          <Form.Check
            type="switch"
            id={uuid()}
            label=""
            checked={false}
            onChange={(e) => handleDefaultProfile(row, false)}
          />
        )}
      </>
    );
  };

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      },
    },
    {
      dataField: "description",
      text: t("admin.role.list.description"),
      sort: true,
    },
    {
      dataField: "label",
      text: t("admin.role.list.label"),
      sort: true,
    },
    {
      dataField: "defaultProfile",
      text: t("admin.role.list.defaultProfile"),
      formatter: buildDefaultProfileColumn,
      formatExtraData: defaultRoles,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
    {
      dataField: "accao",
      text: t("admin.role.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
  ];

  const openModal = (row) => {
    setSelectedRole(row);
    dispatch(findUsersByRole(row.id));
    setShowDeleteModal(true);
  };

  const openModalUsers = (row) => {
    setSelectedRole(row);
    setShowUsers(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const handleCloseUsers = () => {
    setSelectedRole(undefined);
    setShowUsers(false);
  };

  const handleDeleteRole = () => {
    dispatch(deleteRole(selectedRole.id));
    handleClose();
  };

  return (
    <div className="custom-table">
      <ToolkitProvider keyField="id" data={roles} columns={columns} search>
        {(props) => (
          <div>
            <div style={{ paddingBottom: "20px", display: "flex" }}>
              <SearchBar
                {...props.searchProps}
                placeholder={t("admin.role.list.searchInput")}
              />
              <div style={{ paddingLeft: "30px" }}>
                <ButtonAdd text={t("general.add")} onClick={persistRole} />
              </div>
            </div>

            <Spinner spinning={isLoadingRoles || isPersisting} />
            {!isLoadingRoles && (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("admin.role.list.emptyTable")}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>

      <Spinner spinning={isDeleting} wrapper />
      <Messages afterHideSuccess={() => dispatch(getRoles())} />

      <Modal show={showDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!isLoadingUsers && users.length > 0 && (
            <Alert variant="warning">
              {t("admin.role.delete.existsUsers")}
            </Alert>
          )}
          {!isLoadingUsers &&
            `${t("general.remove")}: ${selectedRole?.description}?`}
          <Spinner spinning={isLoadingUsers} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("general.back")}
          </Button>
          <Button variant="danger" onClick={() => handleDeleteRole()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>

      <UserModal
        showUsers={showUsers}
        handleCloseUsers={handleCloseUsers}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />
    </div>
  );
};

export default withNamespaces()(RolesTable);
