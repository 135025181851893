import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";

import { Card, Row, Col, Button, Modal, Alert } from "react-bootstrap";
import { isEmpty } from "lodash";
import Spinner from "~/components/Spinner";
import Messages from "~/components/Messages";

import {
  findBatchById,
  saveDocumentIntoBatch,
  clearSavedBatch,
  deleteBatch,
  //submitToiFlow,
  getIflowConfiguration,
} from "../actions";

import { findUDWSettings } from "~/store/ducks/udw/actionTypes";
import FilePreviewCard from "./FilePreviewCard";
import {
  DocumentStatus,
  //BatchStatus
} from "../utils/DataCaptureEnum";
import { activeSuccess } from "~/components/Messages/actions";

const SUPPORTED_FILES_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  "application/x-zip-compressed",
];

const FileUpload = ({ t, docType, batchId, menuId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    batch,
    isLoadingBatch,
    isLoadingDeleteBatch,
    isDeletingDocument,
    //iflowConfiguration
  } = useSelector((state) => state.dataCaptureReducer);
  //const { udwSettings } = useSelector(state => state.udwReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const [warningMessage, setWarningMessage] = useState("");
  const [onDragOver, setOnDragOver] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const inputFile = useRef(null);
  const { documents } = batch || [];

  useEffect(() => {
    if (batchId) {
      dispatch(findBatchById(batchId));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getIflowConfiguration("datacapture"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findUDWSettings());
  }, [dispatch]);

  const [maxFileSize, setMaxFileSize] = useState(1024 * 1024 * 1); //1 Mb
  const [maxFiles, setMaxFiles] = useState(1);
  /*Get max file size  and max files defined in Extra Configuration*/
  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) => item.route === "datacapture/submissaodespesas"
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(menuItem.extraConfiguration);
        if (extraConfiguration_) {
          const maxFileSize_ = extraConfiguration_.maxFileSize
            ? extraConfiguration_.maxFileSize
            : undefined;
          const maxFiles_ = extraConfiguration_.maxFiles
            ? extraConfiguration_.maxFiles
            : undefined;
          setMaxFileSize(
            maxFileSize_ ? maxFileSize_ * 1024 * 1024 : 1024 * 1024 * 1
          ); //1 Mb
          setMaxFiles(maxFiles_ ? maxFiles_ : 1);
        }
      }
    }
  }, [menu]);

  const addFiles = (file) => {
    if (documents.length < maxFiles) {
      if (SUPPORTED_FILES_TYPES.includes(file.type)) {
        if (file.size < maxFileSize) uploadFile(file);
        else {
          setWarningMessage(
            file.name + ": " + t("datacapture.general.largeFile")
          );
          setShowWarning(true);
          setTimeout(() => setShowWarning(false), 10000);
        }
      }
    } else {
      setWarningMessage(t("datacapture.general.tooManyFiles"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  const isUpdating = () => {
    return !!batchId;
  };

  function uploadFile(file) {
    if (menuId) {
      const id = batch ? batch.id : null;
      const flow = docType.toUpperCase();
      const batchDTO = {
        id,
        menuId,
        flow,
        document: {
          file,
          status: DocumentStatus.UPLOADING,
          manualRevision: true,
        },
      };
      dispatch(saveDocumentIntoBatch(batchDTO));
    } else {
      setWarningMessage("Menu id is needed");
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  }

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    addFiles(event.dataTransfer.files[0]);
    event.preventDefault();
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleDeleteBatch = () => {
    dispatch(deleteBatch(batch.id));
    setShowDeleteModal(false);
    setWarningMessage("");
    history.push("/uniksystem/datacapture/revisaodespesas");
  };

  const handleSaveToRevision = () => {
    dispatch(clearSavedBatch());
    dispatch(
      activeSuccess(
        'O documento foi carregado. Para rever a tarefa consulte "Revisão de Despesas".'
      )
    );
  };

  const clearUploadedFiles = () => {
    dispatch(clearSavedBatch());
    setWarningMessage("");
  };

  function createTitle() {
    let output = `${t("datacapture.general.upload")} `;
    output += `${t("datacapture.general." + docType)} `;
    output += `${t("datacapture.general.finalUploadTitle")}`;
    return output;
  }

  const getCurrentBatchId = () => (isUpdating() ? batchId : batch.id);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-gear mr-2" />{" "}
            {t("datacapture.general.fileUploadTitle")}
          </h6>
        </Card.Header>
        <Card.Body style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="display-4">{createTitle()}</h1>
          {showWarning && (
            <Alert
              variant="warning"
              onClose={() => setShowWarning(false)}
              dismissible
            >
              <strong>Alert: </strong>
              {warningMessage}
            </Alert>
          )}
          <hr />
          {!isLoadingBatch && (
            <Row className="justify-content-md-center">
              {documents.length < maxFiles && (
                <Col sm={isEmpty(documents) ? "6" : "4"} className="uploadArea">
                  <div
                    className="dropArea"
                    onDrop={(e) => handleOnDrop(e)}
                    onClick={() => handleClick()}
                    onDragEnter={() => setOnDragOver(true)}
                    onDragLeave={() => setOnDragOver(false)}
                    style={
                      onDragOver
                        ? { background: "#fff" }
                        : { background: "#efefef" }
                    }
                  >
                    <span>
                      <i className="icon-submit dc-image-upload-icon" />
                      <div className="dc-label-main">
                        {t("datacapture.general.dropToUpload")}
                      </div>
                    </span>
                    <input
                      id="file"
                      type="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      onChange={(e) => addFiles(e.target.files[0])}
                    />
                  </div>
                  <div className="dc-label">
                    {t("datacapture.general.maxFileSize") +
                      " " +
                      maxFileSize / (1024 * 1024) +
                      " Mb, " +
                      t("datacapture.general.maxFiles") +
                      " " +
                      maxFiles}
                  </div>
                  <small>{t("datacapture.general.supportedFiles")}</small>
                </Col>
              )}
              {!isEmpty(documents) && (
                <Col sm="8" className="selectedFiles">
                  {getCurrentBatchId() && (
                    <h1 className="display-4">{`Batch: ${getCurrentBatchId()}`}</h1>
                  )}
                  <Row>
                    {documents.map((document, index) => {
                      return (
                        <Col lg="2" key={uuid()} style={{ marginTop: 20 }}>
                          <FilePreviewCard document={document} index={index} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              )}
            </Row>
          )}
        </Card.Body>
        <Card.Footer>
          {batch && batch.id && (
            <>
              {!isUpdating() && (
                <Button
                  variant="danger"
                  onClick={() => setShowDeleteModal(true)}
                >
                  {t("general.cancel")}
                </Button>
              )}
              <Button
                className="card-button"
                style={{ marginLeft: "10px" }}
                onClick={() => handleSaveToRevision()}
              >
                Guardar para Revisão
              </Button>
            </>
          )}
        </Card.Footer>
      </Card>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        {batch !== undefined ? (
          <Modal.Body>
            {`${t("datacapture.general.deleteBatch")}: ${batch.id} ?`}
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDeleteBatch()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Spinner
        spinning={isLoadingBatch || isLoadingDeleteBatch || isDeletingDocument}
        wrapper
      />
      <Messages
        afterHideSuccess={clearUploadedFiles}
        afterHideError={clearUploadedFiles}
      />
    </div>
  );
};
export default withNamespaces()(FileUpload);
