export const PERSIST_DATACAPTURE_CONFIGURATION = 'datacaptureConfiguration@PERSIST_DATACAPTURE_CONFIGURATION';
export const GET_DATACAPTURE_CONFIGURATION = 'datacaptureConfiguration@GET_DATACAPTURE_CONFIGURATION';
export const SET_DATACAPTURE_CONFIGURATION = 'datacaptureConfiguration@SET_DATACAPTURE_CONFIGURATION';
export const DELETE_DATACAPTURE_CONFIGURATION = 'datacaptureConfiguration@DELETE_DATACAPTURE_CONFIGURATION';
export const CLEAR_DATACAPTURE_CONFIGURATION = 'datacaptureConfiguration@CLEAR_DATACAPTURE_CONFIGURATION';
export const SUCCESS_PERSIST_DATACAPTURE_CONFIGURATION = 'datacaptureConfiguration@SUCCESS_PERSIST_DATACAPTURE_CONFIGURATION';


export const persistDatacaptureConfiguration = (configuration) => {
  return { type: PERSIST_DATACAPTURE_CONFIGURATION, configuration }
}

export const getDatacaptureConfiguration = (organizationId) => {
  return { type: GET_DATACAPTURE_CONFIGURATION, organizationId }
}

export const setDatacaptureConfiguration = (datacaptureConfiguration) => {
  return { type: SET_DATACAPTURE_CONFIGURATION, datacaptureConfiguration:datacaptureConfiguration }
}

export const deleteDatacaptureConfiguration = (datacaptureConfigurationId) => {
  return { type: DELETE_DATACAPTURE_CONFIGURATION, datacaptureConfigurationId }
}

export const clearDatacaptureConfiguration = () => {
  return { type: CLEAR_DATACAPTURE_CONFIGURATION }
}

export const sucessesPersistDatacaptureConfiguration = () => {
  return { type: SUCCESS_PERSIST_DATACAPTURE_CONFIGURATION }
}
