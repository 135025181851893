import React, { useState } from "react";
import DocumentNavigation from "~/pages/Easypay/components/DocumentNavigation";

const CreateDocumentNavigation = ({ documentlist }) => {
  const [documentSelected, setDocumentSelected] = useState(documentlist[0]);

  return (
    <>
      <DocumentNavigation
        documentsList={documentlist}
        selected={documentSelected}
        setSelected={setDocumentSelected}
      ></DocumentNavigation>
    </>
  );
};
export default CreateDocumentNavigation;
