import React, { useState, useEffect } from 'react';
import Document from "../Document";
import { useSelector } from "react-redux";

const CitizenCard = () => {

  const [menuId, setMenuId] = useState(undefined);
  const { menu } = useSelector(state => state.applicationMenuReducer);

  useEffect(() => {
    const menuItem = menu.find(item => item.route === "datacapture/onboarding")
    if (menuItem && menuItem.id)
      setMenuId(menuItem.id);
  }, [menu])

  return (
    <Document docType="citizen_card" menuId={menuId} />
  )
}

export default CitizenCard;
