import React from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import GoogleLogin from "react-google-login";
import { GOOGLE_CLIENT_ID } from "~/utils/constants";

import { googleSignIn } from "../actions";

const GoogleAuthentication = () => {
  const dispatch = useDispatch();

  const successResponseGoogle = (response) => {
    //setUsername(response.profileObj.email.split("@")[0]);
    dispatch(googleSignIn(response));
  };

  return (
    <div id="third-auth-button">
       <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Google"
          onSuccess={successResponseGoogle}
          cookiePolicy={"single_host_origin"}
        />
    </div>  
  );
};

export default withNamespaces()(GoogleAuthentication);
