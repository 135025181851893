import React from "react";
import { Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";

const SimpleComponent = (props) => {
  const { t } = props;
  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-gear mr-2" />
            {t("general.underConstruction")}
          </h6>
        </Card.Header>
        <Card.Body></Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(SimpleComponent);
