import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from 'react-i18next';
import { capitalize, cloneDeep } from 'lodash';
import DateTimePicker from 'react-datetime-picker';

import { Badge, Modal, Button, Row, Col, Form } from 'react-bootstrap';
import '~/assets/css/styles.css';
import '~/assets/css/icons.css';
import Spinner from '~/components/Spinner';

import { saveMetadataDocument } from '~/pages/DataCapture/actions';

import { toLocaleString, toLocaleDateString } from '~/utils';
import {
  getColorByStatusDocument,
  translateStatusDocument
} from '~/pages/DataCapture/utils';

import { DocumentStatus } from '~/pages/DataCapture/utils/DataCaptureEnum';

const MetadataModal = props => {

  const { t, isEditing, showModal, closeModal, document } = props;

  const dispatch = useDispatch();

  const {
    metadata,
    isLoadingMetadata,
    isSavingMetadataDocument,

    iflowConfiguration
  } = useSelector(state => state.dataCaptureReducer);

  const [vendorName, setVendorName] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [emissionDate, setEmissionDate] = useState("");
  const [vendorTaxId, setVendorTaxId] = useState("");
  const [clientTaxId, setClientTaxId] = useState("");
  const [currency, setCurrency] = useState("");
  const [baseAmount, setBaseAmount] = useState("");
  const [vatAmount, setVatAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");

  const [revised, setRevised] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (metadata) {
      setVendorName(nullValueFormatter(metadata.vendorName, ''));
      setInvoiceNumber(nullValueFormatter(metadata.invoiceNumber, ''));
      //setEmissionDate(metadata.emissionDate ? metadata.emissionDate.slice(0, 10) : '');
      setEmissionDate(metadata.emissionDate ? new Date(metadata.emissionDate).toISOString().slice(0, 10) : '');
      setVendorTaxId(nullValueFormatter(metadata.vendorTaxId, ''));
      setClientTaxId(nullValueFormatter(metadata.clientTaxId, ''));
      setCurrency(nullValueFormatter(metadata.currency, ''));
      setBaseAmount(nullValueFormatter(metadata.baseAmount, ''));
      setVatAmount(nullValueFormatter(metadata.vatAmount, ''));
      setTotalAmount(nullValueFormatter(metadata.totalAmount, ''));
      setClientName(nullValueFormatter(metadata.clientName, ''));
      setClientAddress(nullValueFormatter(metadata.clientAddress, ''));
      setClientPhone(nullValueFormatter(metadata.clientPhone, ''));
      setVendorAddress(nullValueFormatter(metadata.vendorAddress, ''));
      setVendorPhone(nullValueFormatter(metadata.vendorPhone, ''));
    }
  }, [metadata])


  const nullValueFormatter = (value, text) => value ? value : text;

  const asterisk = <i style={{ color: "#dc3545" }}>*</i>

  const createTextInput = (value, _onChange, required) => (
    isEditing ?
      <Form.Control
        type="text"
        value={value}
        onChange={e => _onChange(e.target.value)}
        required={required}
      />
      :
      <small>{nullValueFormatter(value, 'Não informado')}</small>
  );

  const createNumberInput = (value, _onChange, required) => (
    isEditing ?
      <Form.Control
        type="number"
        value={value}
        step="0.01"
        onChange={e => _onChange(e.target.value)}
        required={required}
      />
      :
      <small>{nullValueFormatter(value, 'Não informado')}</small>
  );

  const getRequired = (fieldname) => {
    if (iflowConfiguration && iflowConfiguration.mandatoryfields) {
      if (iflowConfiguration.mandatoryfields.some(field => field === fieldname))
        return true;
      else return false;
    }
    return true;
  }

  //TODO change class to invoice ou citizen_card
  const handleSaveMetadataDocument = () => {
    //let _document = cloneDeep(document);
    if (revised) {
      if (document.statusV1 === DocumentStatus.LOADED)
        //_document.statusV1 = DocumentStatus.REVIEWED;
        document.statusV1 = DocumentStatus.REVIEWED;
      else
        //_document.statusV1 = DocumentStatus.REVISED;
        document.statusV1 = DocumentStatus.REVISED;
    }
    const emissionDate_ = emissionDate !== "" ? new Date(emissionDate).getTime() : "";
    //_document.metadata = {
    document.metadata = {
      'document:class': 'invoice',
      invoiceNumber, clientName, clientAddress, clientPhone,
      vendorName, vendorAddress, vendorPhone,
      totalAmount, currency, vatAmount, baseAmount,
      emissionDate: emissionDate_, vendorTaxId, clientTaxId
    }
    //dispatch(saveMetadataDocument(_document));
    dispatch(saveMetadataDocument(document));
    closeModal();
  }

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    }
    else {
      handleSaveMetadataDocument();
    }
  };

  const generateImageView = () => (
    document ? 'data:image/jpg;base64, ' + document.data : ''
  )

  const generatePdfView = () => (
    document ? 'data:application/pdf;base64, ' + document.data : 'about:blank'
  )

  return (
    <Modal
      dialogClassName="modal-datacapture-detail"
      show={showModal}
      onHide={() => closeModal()}
      centered
      backdrop='static'
    >
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        method="post"
        encType="multipart/form-data"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {capitalize(t('datacapture.general.invoice'))}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            !isLoadingMetadata &&
            <>
              <Row>
                <Col lg="5">
                  <Row>
                    <Col sm="6">
                      {
                        document &&
                        <Badge
                          pill
                          variant={getColorByStatusDocument(document.status)}
                          style={{ marginBottom: 15 }}
                        >
                          {t(translateStatusDocument(document.status))}
                        </Badge>
                      }
                      <h1 className="display-4">
                        {
                          vendorName && <strong>{`${vendorName} - `}</strong>
                        }
                        <span>{`${totalAmount} ${currency}`}</span>
                      </h1>
                      {
                        document &&
                        <small>{t('datacapture.metadata.uploaded_at')} &nbsp;{`${toLocaleString(document.createdDate)}`}</small>
                      }
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ marginTop: 20 }}>
                    <Col lg="3">
                      <strong>{t('datacapture.metadata.invoice_number')}:</strong>
                      {getRequired("invoiceNumber") && asterisk}
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="validationInvoiceNumber">
                        {createTextInput(invoiceNumber, setInvoiceNumber, getRequired("invoiceNumber"))}
                        <Form.Control.Feedback type="invalid">
                          {t('datacapture.persist.required_invoice_number')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <strong>{t('datacapture.metadata.emission_date')}:</strong>
                      {getRequired("emissionDate") && asterisk}
                    </Col>
                    <Col sm="8">
                      {isEditing ?
                        <Form.Group controlId="validationEmissionDate">
                          <Form.Control
                            required={getRequired("emissionDate")}
                            type="date"
                            value={emissionDate}
                            onChange={e => setEmissionDate(e.target.value)}
                          />
                          {/*<DateTimePicker
                            value={emissionDate}
                            onChange={e => setEmissionDate(e)}
                            locale="pt-PT"
                            //format="dd/MM/yyyy HH:mm:ss"
                            format="dd/MM/yyyy"
                            className="form-control"
                            disableClock={true}
                            required
                          />*/}
                          <Form.Control.Feedback type="invalid">
                            {t('datacapture.persist.required_emission_date')}
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        <small>{nullValueFormatter(toLocaleDateString(emissionDate), 'Não informado')}</small>
                      }
                    </Col>
                  </Row>

                  <hr />

                  <Row style={{ marginTop: 20 }}>
                    <Col lg="3">
                      <strong>{t('datacapture.metadata.vendor_name')}:</strong>
                      {getRequired("vendorName") && asterisk}
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="validationVendorName">
                        {createTextInput(vendorName, setVendorName, getRequired("vendorName"))}
                        <Form.Control.Feedback type="invalid">
                          {t('datacapture.persist.required_vendor_name')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <strong>{t('datacapture.metadata.vendor_tax')}:</strong>
                      {getRequired("vendorTaxId") && asterisk}
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="validationVendorTaxId">
                        {createTextInput(vendorTaxId, setVendorTaxId, getRequired("vendorTaxId"))}
                        <Form.Control.Feedback type="invalid">
                          {t('datacapture.persist.required_vendor_taxId')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <strong>{t('datacapture.metadata.client_tax')}:</strong>
                      {getRequired("clientTaxId") && asterisk}
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="validationClientTaxId">
                        {createTextInput(clientTaxId, setClientTaxId, getRequired("clientTaxId"))}
                        <Form.Control.Feedback type="invalid">
                          {t('datacapture.persist.required_client_taxId')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <hr />

                  <Row style={{ marginTop: 20 }}>
                    <Col lg="3">
                      <strong>{t('datacapture.metadata.currency')}:</strong>
                      {getRequired("currency") && asterisk}
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="validationCurrency">
                        {createTextInput(currency, setCurrency, getRequired("currency"))}
                        <Form.Control.Feedback type="invalid">
                          {t('datacapture.persist.required_currency')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <strong>{t('datacapture.metadata.base_amount')}:</strong>
                      {getRequired("baseAmount") && asterisk}
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="validationBaseAmount">
                        {createNumberInput(baseAmount, setBaseAmount, getRequired("baseAmount"))}
                        <Form.Control.Feedback type="invalid">
                          {t('datacapture.persist.required_base_amount')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <strong>{t('datacapture.metadata.vat_amount')}:</strong>
                      {getRequired("vatAmount") && asterisk}
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="validationVatAmount">
                        {createNumberInput(vatAmount, setVatAmount, getRequired("vatAmount"))}
                        <Form.Control.Feedback type="invalid">
                          {t('datacapture.persist.required_vat_amount')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <hr />

                  <Row style={{ marginTop: 20 }}>
                    <Col lg="3">
                      <h4>Total: {getRequired("totalAmount") && asterisk}</h4>

                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="validationTotalAmount">
                        {createNumberInput(totalAmount, setTotalAmount, getRequired("totalAmount"))}
                        <Form.Control.Feedback type="invalid">
                          {t('datacapture.persist.required_total_amount')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {isEditing &&
                    <Row style={{ marginTop: 20 }}>
                      <Col lg="12">
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            label={
                              document && document.statusV1 === DocumentStatus.LOADED ?
                                t('datacapture.general.flagReviewed')
                                : t('datacapture.general.flagCorrected')
                            }
                            value={revised}
                            onChange={e => setRevised(e.target.checked)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  }
                </Col>
                <Col lg="7">
                  {
                    (document && document.filetype === 'application/pdf') &&
                    <iframe
                      id="pdf-document"
                      title="pdf-document"
                      src={generatePdfView()}
                      style={{ height: '100%', width: '100%' }}
                    />
                  }
                  {
                    (document && document.filetype !== 'application/pdf') &&
                    <img src={generateImageView()} className="document-preview" alt="document-preview" />
                  }
                </Col>
              </Row>
            </>
          }

          <Spinner spinning={isLoadingMetadata} />
          <Spinner spinning={isSavingMetadataDocument} wrapper />
        </Modal.Body >

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => closeModal()}
          >
            {t('general.close')}
          </Button>
          {
            isEditing &&
            <Button
              className="modal-submit-button"
              style={{ marginLeft: "10px" }}
              //onClick={() => handleSaveMetadataDocument()}
              type="submit"
            >
              {t('general.save')}
            </Button>
          }
        </Modal.Footer>
      </Form>
    </Modal >
  )

}

export default withNamespaces()(MetadataModal);
