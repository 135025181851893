import React from "react";
import LoadingIndicator from "react-loading-indicator";
import "~/pages/Easypay/assets/scss/_spinner.scss";

const Spinner = ({ size, spinning }) => {
  const defaultSize = "medium";

  const sizeList = {
    extraLarge: {
      segmentLength: 15,
      segmentWidth: 10,
      spacing: 8,
    },
    large: {
      segmentLength: 13,
      segmentWidth: 8,
      spacing: 6,
    },
    medium: {
      segmentLength: 8,
      segmentWidth: 4,
      spacing: 4,
    },
    small: {
      segmentLength: 5,
      segmentWidth: 3,
      spacing: 2,
    },
  };

  return (
    spinning && (
      <div className="easypay-spinner-wrap">
        <LoadingIndicator
          fadeTo={0.08}
          segments={8}
          segmentLength={
            sizeList.hasOwnProperty(size)
              ? sizeList[size].segmentLength
              : sizeList[defaultSize].segmentLength
          }
          segmentWidth={
            sizeList.hasOwnProperty(size)
              ? sizeList[size].segmentWidth
              : sizeList[defaultSize].segmentWidth
          }
          spacing={
            sizeList.hasOwnProperty(size)
              ? sizeList[size].spacing
              : sizeList[defaultSize].spacing
          }
        />
      </div>
    )
  );
};

export default Spinner;
