import { isEmpty, find } from "lodash";

export const generateTreeMenu = (menu) => {
  if (isEmpty(menu)) return [];
  const treeMenu = {};
  menu.forEach(item => {
    if (treeMenu[item.id] && treeMenu[item.id].childrens) {
      item.childrens = treeMenu[item.id] && treeMenu[item.id].childrens;
    }
    treeMenu[item.id] = item;
    treeMenu[item.menuParent] = treeMenu[item.menuParent] || {};
    treeMenu[item.menuParent].childrens = treeMenu[item.menuParent].childrens || [];
    if (!find(treeMenu[item.menuParent].childrens, { name: item.name })) {
      treeMenu[item.menuParent].childrens.push(item);
    }
  });
  //console.log(treeMenu.null.childrens);
  return treeMenu.null.childrens;
}
