import React from "react";
import { Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import GerirPage from "./gerir";
import Requesitar from "./requisitar";
import { useSelector, useDispatch } from "react-redux";

import "./scss/delegacoes.scss";
import Reatribuir from "./reatribuir";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import { PERMISSION_BPM_ADMIN } from "~/utils/constants/permissions";

const Delegacoes = ({ app, className, ...props }) => {
  const { t } = props;

  const history = useHistory();
  let { path } = useRouteMatch();

  const { legacyPermissions } = useSelector(
    (state) => state.legacyPermissionReducer
  );

  return (
    <div
      className={(className ? className : "main-card-v2") + " delegacoes-fix"}
      style={{ height: "auto" }}
    >
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <div className="custom-title">{t("bpm.delegacoes.menu.title")}</div>
        </Card.Header>
        <Card.Body>
          <Switch>
            <Route exact path={[path, path + "/gerir"]}>
              <GerirPage />
            </Route>
            <Route path={path + "/requisitar"}>
              <Requesitar />
            </Route>
            <ProtectedComponent permissions={[{ label: PERMISSION_BPM_ADMIN }]}>
              <Route path={path + "/reatribuir"}>
                <Reatribuir />
              </Route>
            </ProtectedComponent>
          </Switch>
        </Card.Body>
      </Card>
    </div>
  );
};
export default withNamespaces()(Delegacoes);
