import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_card.scss";
import "../assets/scss/_tabSeparatorWrapper.scss";

const TabSeparatorWrapper = (props) => {
  const { children, defaultActive } = props;
  const [activeTab, setActivetab] = useState(
    defaultActive ? defaultActive : children[0].props.name
  );

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      activeTab,
      setTheActiveTab: (name) => {
        setActivetab(name);
      },
      tabsNumber: children.length,
      index,
    });
  });

  const getChildrenContent = () =>
    children.filter((child) => child.props.name === activeTab)[0]?.props
      ?.children;

  return (
    <div>
      <div className={"easypay-tab-button-wrapper-separator"}>
        {childrenWithProps}
      </div>
      <div className="easypay-card separator-card">{getChildrenContent()}</div>
    </div>
  );
};

export default withNamespaces()(TabSeparatorWrapper);
