import React, { useState, useImperativeHandle } from "react";
import { Modal, Button } from "react-bootstrap";

const CreateAlertModal = React.forwardRef((props, ref) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  useImperativeHandle(ref, () => ({
    handleShowModal(msg) {
      setConfirmMessage(msg);
      setShowModal(true);
    },
  }));

  const handleConfirm = () => {
    setShowModal(false);
    setConfirmMessage("");
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setConfirmMessage("");
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmMessage}</Modal.Body>
      <Modal.Footer>
        <Button className="modal-submit-button" onClick={() => handleConfirm()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default CreateAlertModal;
