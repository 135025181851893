import React from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { loginADFS } from "~/store/ducks/udw/actionTypes";

const UdwAdfsSignIn = ({ t })  => {
  const dispatch = useDispatch();
  const handleLogin = (e) => {
    const payload = {entityProvider : "1"}
    console.log(payload)
    dispatch(loginADFS(payload));
  };
  return (
    <button className="btn-adfs" onClick={() => handleLogin()}>
      <div style={{ display: "flex" }}>
        <div style={{flexGrow: "1", justifyContent: "center", alignItems: "center"}}>
          Login Integrado 
        </div>
      </div>
    </button>
);
};


export default withNamespaces()(UdwAdfsSignIn);