import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProcessosIframe from "~/components/ProcessosIframe";
import { getIflowFormdata } from "~/store/ducks/processes/actionTypes";
import { setProcessFormMenuItemClicked } from "~/containers/Layout/actions";
import NativeProcessesWrapper from "./NativeProcessesWrapper";
import oauth from "~/utils/oauth";

const ProcessosFormHandler = (props) => {
  const { app, className } = props;
  const dispatch = useDispatch();
  const { currentFormUrl, currentFormMenuItemClicked } = useSelector(
    (state) => state.globalReducer
  );
  const { formdata } = useSelector((state) => state.processesReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const renderProcesses = () => {
    let isJson = undefined;
    if (currentFormUrl !== "") {
      if (formdata) {
        if (typeof formdata === "object" && formdata !== null) {
          if(formdata.responseMessage){
            if(!formdata.responseMessage.includes('<meta formType="HTML">')){
              console.log("25")
              return (
                <NativeProcessesWrapper className={className}
              />
            );
            }
            console.log("31")
            return <ProcessosIframe urlFormData={formdata.url} className={className} />;
          }
          else{
            console.log("35")
            return (
                <NativeProcessesWrapper className={className}
                formFromProcess={formdata}
              />
            );
          }
        } else if ( formdata?.includes(`{"form" :`)) {
          let arr = formdata.split(`{"form" :`);
            let re = /\"\" style=\"/g;
            let transformedString = (`{"form" :` + arr[1]).replace(re, '" style=');
            //Remove All Line Breaks from a String
            transformedString = transformedString
              .replace(/\\/g, "\\\\")
              .replace(/(\r\n|\n|\r|\t)/gm, "");
            isJson = JSON.parse(transformedString);
        }
        if (isJson != undefined) {
          console.log("53")
          return <NativeProcessesWrapper className={className} formFromProcess={isJson} />
        } else {console.log("55");return <ProcessosIframe className={className} urlFormData={currentFormUrl} />;}
      }
    }
  };

  useEffect(() => {
    if (menu && currentFormUrl !== "" && currentFormMenuItemClicked === true) {
      const menuItem = menu.find((item) => item.route === `${app}/processos`);
      if (menuItem) {
        if ( currentFormUrl) {
          const payload = {
            url: currentFormUrl + "&Authorization=" + oauth.getAccessToken(),
          };
          const isNewForm = true
          dispatch(getIflowFormdata(payload, isNewForm));
          dispatch(setProcessFormMenuItemClicked(false));
        }
      }
    }

    return () => {
      //clear accordions state
      localStorage.removeItem("iflowAccordion");
    };
  }, [menu, currentFormUrl, currentFormMenuItemClicked]);

  return <>{renderProcesses()}</>;
};
export default ProcessosFormHandler;
