import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import TableList from "./TableList";
import "~/assets/css/icons.css";
import Simulator from "../Simulator";
import "../Simulator.css";
import { withNamespaces } from "react-i18next";
import ModalCollaborators from "./ModalCollaborators";
import { useDispatch, useSelector } from "react-redux";
import { getCollaboratorBoolean } from "~/store/ducks/simulator/actionTypes";
import EditedSimulator from "./EditedSimulator";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import { getCollaboratorData } from "~/store/ducks/simulator/actionTypes";

const Simulators = ({ t }) => {
  const [startSimulation, setStartSimulation] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [collaboratorSavedData, setCollaboratorSavedData] = useState();
  const [collaboratorProperties, setCollaboratorProperties] = useState();
  const [rowData, setRowData] = useState();
  const [isContinueButton, setIsContinueButton] = useState(false);
  const [collaboratorContents, setCollaboratorContents] = useState();

  const { collaboratorBoolean } = useSelector(
    (state) => state.simulatorReducer
  );

  const dispatch = useDispatch();

  // taking the data from the simulator component and putting in the object
  const getStartSimulator = (start) => {
    setIsContinueButton(start);
  };

  const handleNemSimulation = () => {
    dispatch(getCollaborators());
    // setStartSimulation(!startSimulation);
    setModalShow(true);
  };

  const handleBack = () => {
    if (collaboratorBoolean) {
      dispatch(getCollaboratorBoolean());
    }
    setStartSimulation(!startSimulation);
    setIsContinueButton(false);
  };

  //taking the CollaboratorData from ModalCollaborators component
  const saveCollaboratorData = (data, properties) => {
    setCollaboratorSavedData(data);
    setCollaboratorProperties(properties);
  };

  useEffect(() => {
    if (collaboratorBoolean) {
      setStartSimulation(!startSimulation);
    }

    if (isContinueButton) {
      setModalShow(false);
      setStartSimulation(!startSimulation);
    }
  }, [collaboratorBoolean, isContinueButton]);

  const getRowSavedCollaboratorData = (data) => {
    setRowData(data);
  };

  //lifting up, taking boolean from Simulator's component ( New Simulation's buttons)
  const getBooleanShowModal = (boolean) => {
    setModalShow(boolean);
  };

  const onSaveCollaboratorContent = (data) => {
    setCollaboratorContents(data);
  };

  return (
    <div className="simulators">
      {startSimulation ? null : (
        <div className="simulator_NewSimulationButton">
          {" "}
          <Button className="card-button" onClick={handleNemSimulation}>
            {t(`simulatorMainPage.table.newSimulation`)}
          </Button>
        </div>
      )}
      {startSimulation ? (
        <div className="simulators_buttons">
          <Button className="card-button" onClick={handleBack}>
            {t(`simulatorMainPage.table.back`)}
          </Button>
        </div>
      ) : null}

      {modalShow ? (
        <ModalCollaborators
          onHide={() => setModalShow(false)}
          show={modalShow}
          onSaveCollaboratorData={saveCollaboratorData}
          onStartSimulator={getStartSimulator}
          onSaveCollaboratorContent={onSaveCollaboratorContent}
        />
      ) : null}

      {startSimulation ? null : (
        <TableList onGetCollaboratorData={getRowSavedCollaboratorData} />
      )}
      {startSimulation && !collaboratorBoolean ? (
        <Simulator
          showButtonSave={true}
          collaboratorSavedData={collaboratorSavedData}
          collaboratorProperties={collaboratorProperties}
          onBooleanShowModal={getBooleanShowModal}
        />
      ) : null}
      {/* when the user clicks  in the row will appear the simulator with the values saved from DB */}
      {collaboratorBoolean ? (
        <EditedSimulator showButtonSave={true} rowData={rowData} />
      ) : null}
    </div>
  );
};

export default withNamespaces()(Simulators);
