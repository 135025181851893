export const SET_BASE_WIDGETS = "SET_BASE_WIDGETS";
export const CREATE_BASE_WIDGET = "CREATE_BASE_WIDGET";
export const UPDATE_BASE_WIDGET = "UPDATE_BASE_WIDGET";
export const GET_BASE_WIDGETS = "GET_BASE_WIDGETS";
export const DELETE_BASE_WIDGET = "DELETE_BASE_WIDGET";
export const SET_UPDATED = "SET_UPDATED";

export const GET_PUZZLE_ITEMS = "GET_PUZZLE_ITEMS";
export const GET_PUZZLE_ITEMS_BY_ORG_ID = "GET_PUZZLE_ITEMS_BY_ORG_ID";
export const SET_PUZZLE_ITEMS = "SET_PUZZLE_ITEMS";
export const SET_PUZZLE_ITEMS_BY_ORG_ID = "SET_PUZZLE_ITEMS_BY_ORG_ID";
export const CLEAR_PUZZLE_ITEMS = "CLEAR_PUZZLE_ITEMS";
export const SAVE_PUZZLE_ITEMS = "SAVE_PUZZLE_ITEMS";

export const REQUEST_CONFIGURATION = "REQUEST_CONFIGURATION";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const SET_AUTH_MODES = "SET_AUTH_MODES";
export const GET_AUTH_MODES = "GET_AUTH_MODES";

export const createBaseWidget = (payload) => ({ type: CREATE_BASE_WIDGET, widget: payload });
export const updateBaseWidget = (payload) => ({ type: UPDATE_BASE_WIDGET, widget: payload });
export const getBaseWidgets = () => ({ type: GET_BASE_WIDGETS });
export const deleteBaseWidget = (payload) => ({type: DELETE_BASE_WIDGET, id: payload });
export const setBaseWidgets = (payload) => ({ type: SET_BASE_WIDGETS, data: payload });
export const setUpdated = (updated) => ({ type: SET_UPDATED, updated });

export const getPuzzleItems = () => ({ type: GET_PUZZLE_ITEMS });
export const getPuzzleItemsByOrgId = (organizationId) => ({ type: GET_PUZZLE_ITEMS_BY_ORG_ID, organizationId });
export const setPuzzleItems = (payload) => ({ type: SET_PUZZLE_ITEMS, data: payload });
export const setPuzzleItemsByOrgId = (payload) => ({ type: SET_PUZZLE_ITEMS_BY_ORG_ID, data: payload });
export const clearPuzzleItems = () => ({ type: CLEAR_PUZZLE_ITEMS });
export const savePuzzleItems = (payload, organizationId) => ({ type: SAVE_PUZZLE_ITEMS, data: payload, organizationId });

export const setAuthModes = (authModes) => ({ type: SET_AUTH_MODES, authModes });
export const getAuthModes = () => ({ type: GET_AUTH_MODES });
export const requestConfiguration = () => ({ type: REQUEST_CONFIGURATION });
export const setConfiguration = (data) => ({ type: SET_CONFIGURATION, data });
export const updateConfiguration = (configuration) => ({ type: UPDATE_CONFIGURATION, configuration });
