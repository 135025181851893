export const buildExpiredLicensePeriod = (endDate) => {
  const result = {
    message: false,
    days: "",
    months: "",
    years: "",
  };

  const endYear = parseInt(endDate.split("-")[0]);
  const endMonth = parseInt(endDate.split("-")[1]);
  const endDay = parseInt(endDate.split("-")[2]);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();

  if (
    currentYear === endYear &&
    endMonth === currentMonth &&
    endDay === currentDay
  ) {
    result["message"] = 1;
    return result;
  }

  if (currentYear > endYear) {
    result["message"] = 2;
    return result;
  }

  if (currentYear >= endYear && currentMonth > endMonth) {
    result["message"] = 2;
    return result;
  }

  if (
    currentYear >= endYear &&
    currentMonth >= endMonth &&
    currentDay > endDay
  ) {
    result["message"] = 2;
    return result;
  }

  if (
    currentYear === endYear &&
    currentMonth === endMonth &&
    currentDay < endDay
  ) {
    result["days"] = endDay - currentDay;
    return result;
  }

  if (
    currentYear === endYear &&
    currentMonth < endMonth &&
    currentDay === endDay
  ) {
    result["months"] = endMonth - currentMonth;
    return result;
  }

  if (
    currentYear === endYear &&
    currentMonth < endMonth &&
    currentDay > endDay
  ) {
    result["days"] =
      new Date(currentYear, currentMonth + 1, -1).getDate() -
      currentDay +
      endDay;
    return result;
  }

  if (
    currentYear < endYear &&
    currentMonth === endMonth &&
    currentDay === endDay
  ) {
    result["years"] = endYear - currentYear;
    return result;
  }

  if (
    currentYear === endYear &&
    currentMonth < endMonth &&
    currentDay < endDay
  ) {
    result["months"] = endMonth - currentMonth;
    result["days"] = endDay - currentDay;
    return result;
  }

  if (
    currentYear < endYear &&
    currentMonth < endMonth &&
    currentDay === endDay
  ) {
    result["months"] = endMonth - currentMonth;
    result["years"] = endYear - currentYear;
    return result;
  }

  if (
    currentYear < endYear &&
    currentMonth === endMonth &&
    currentDay < endDay
  ) {
    result["days"] = endDay - currentDay;
    result["years"] = endYear - currentYear;
    return result;
  }

  if (
    currentYear < endYear &&
    currentMonth === endMonth &&
    currentDay > endDay
  ) {
    result["months"] = endMonth - currentMonth + 11;
    result["years"] = endYear - currentYear;
    return result;
  }

  if (currentYear < endYear && currentMonth < endMonth && currentDay < endDay) {
    result["years"] = endYear - currentYear;
    result["months"] = endMonth - currentMonth;
    result["days"] = endDay - currentDay;
    return result;
  }

  if (currentYear < endYear && currentMonth > endMonth && currentDay > endDay) {
    result["months"] = 12 - currentMonth + endMonth - 1;
    result["days"] =
      new Date(endYear, currentMonth + 1, -1).getDate() - currentDay + endDay;
    return result;
  }

  if (
    currentYear < endYear &&
    currentMonth > endMonth &&
    currentDay === endDay
  ) {
    result["months"] = 12 - currentMonth + endMonth - 1;
    return result;
  }

  if (currentYear < endYear && currentMonth > endMonth && currentDay < endDay) {
    result["months"] = 12 - currentMonth + endMonth;
    result["days"] = endDay - currentDay;
    return result;
  }
  if (currentYear < endYear && currentMonth > endMonth && currentDay < endDay) {
    result["months"] = 12 - currentMonth + endMonth;
    return result;
  }
};
