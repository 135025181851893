import React, { useState, useEffect } from "react";

const CreateTableTextInput = (props) => {
  const { id, name, value, linha, onBlur, onChildRender, disabled } = props;

  const [inputValue, setValue] = useState("");
  const [isValueSet, setIsValueSet] = useState(false);

  useEffect(() => {
    if (value) {
      setValue(value);
      setIsValueSet(true);
    }
  }, [value]);

  useEffect(() => {
    if (isValueSet) {
      onChildRender();
    }
  }, [isValueSet]);

  return (
    <td id={id}>
      {disabled ? (
        <div style={{ fontWeight: "500" }}>
          {inputValue}
          <input type="hidden" name={name} value={inputValue} />
        </div>
      ) : (
        <input
          className="form-control"
          name={name}
          type="text"
          size={5}
          style={{ width: "90%", maxHeight: "35px" }}
          maxLength={20}
          value={inputValue}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={() => {
            onBlur(linha, 13);
          }}
        />
      )}
    </td>
  );
};
export default CreateTableTextInput;
