export const SET_TASKS = "tasks@SET_TASKS";
export const FIND_IFLOW_TASKS_BY_FILTERS = "tasks@FIND_IFLOW_TASKS_BY_FILTERS";
export const UPDATE_READFLAG = "tasks@UPDATE_READFLAG";
export const SET_UPDATE_READFLAG = "taskFolders@SET_UPDATE_READFLAG";
export const FIND_IFLOW_TASKS_COUNT = "task@FIND_IFLOW_TASKS_COUNT";
export const SET_TASKS_COUNT_TOTAL = "task@SET_TASKS_COUNT_TOTAL";
export const SET_TASKS_COUNT = "task@SET_TASKS_COUNT";

export const setTasks = (tasks) => {
  return { type: SET_TASKS, data: tasks };
};

export const findIflowTasksByFilters = (payload) => ({
  type: FIND_IFLOW_TASKS_BY_FILTERS,
  payload,
});

export const updateReadFlag = (
  read_flag,
  flowid,
  flowname,
  pnumber,
  subpid
) => {
  return {
    type: UPDATE_READFLAG,
    data: { read_flag, flowid, flowname, pnumber, subpid },
  };
};

export const setupdateReadFlag = (result) => {
  return { type: SET_UPDATE_READFLAG, data: result };
};

export const findIflowTasksCount = (payload) => ({
  type: FIND_IFLOW_TASKS_COUNT,
  payload,
});

export const setTasksCountTotal = (tasks) => {
  return { type: SET_TASKS_COUNT_TOTAL, data: tasks };
};
export const setTasksCount = (tasks) => {
  return { type: SET_TASKS_COUNT, data: tasks };
};
