let configuration = undefined;

export const setConfiguration = (configuration_) => {
  configuration = configuration_;
};

const UNIKSYSTEM = 1;
const GLOBAL_SOLVER = 2;
const GOOGLE = 3;
const UDW_GOOGLE = 4;
const LDAP = 5;
const IFLOW_SAML = 6;
const UDW_SAML = 7;
const MICROSOFT = 8;
const ADFS = 9;
const WINDOWS = 10;
const KEYCLOAK = 11;
const ADUNIK =12;

export const isUnikSystem = () => verifyAuthDomain(UNIKSYSTEM);
export const isGlobalSolver = () => verifyAuthDomain(GLOBAL_SOLVER);
export const isGoogle = () => verifyAuthDomain(GOOGLE);
export const isUDWGoogle = () => verifyAuthDomain(UDW_GOOGLE);
export const isLDAP = () => verifyAuthDomain(LDAP);
export const isIFlowSaml = () => verifyAuthDomain(IFLOW_SAML);
export const isUDWSaml = () => verifyAuthDomain(UDW_SAML);
export const isMicrosoftAuthentication =() =>verifyAuthDomain(MICROSOFT);
export const isADFSAuthentication =() =>verifyAuthDomain(ADFS);
export const isKeycloak = () => verifyAuthDomain(KEYCLOAK);
export const isWindows = () => verifyAuthDomain(WINDOWS);
export const isADUniksystem = () => verifyAuthDomain(ADUNIK);

export const isRegularAuthentication = () =>
  isUnikSystem() ||
  isGlobalSolver() ||
  isUDWGoogle() ||
  isIFlowSaml() ||
  isLDAP() ||
  isUDWSaml() ||
  isIFlowSaml() || 
  isKeycloak() || 
  isWindows()||
  isADUniksystem();

export const isGoogleAuthentication = () => isGoogle() || isUDWGoogle();

export const isWindowsAuthentication = () => isWindows();
export const isKeycloakAuthentication = () => isKeycloak();

export const isSAMLAuthentication = () =>
  isIFlowSaml() || isUDWSaml() || isIFlowSaml();

export const isAutoLoginKeycloak = () => {
  let keycloakAuthDomain = configuration.authDomains.filter((item) => item.applicationName === 'Keycloak')[0];
  return keycloakAuthDomain?.auto;
}

export const isAutoLoginWindows = () => {
  let windowsAuthDomain = configuration.authDomains.filter((item) => item.applicationName === 'Windows')[0];
  return windowsAuthDomain?.auto;
}

export const verifyAuthDomain = (authDomain) =>{
  if(configuration && configuration.authDomains){
    return configuration.authDomains.some(i=>i.id === authDomain);
  }else return false;
}