import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";


/**
 * Sms OTP
 *
 * This service tests an API from easyPay
 *
 * It allows to the user to send an Sms through the easyPay network.
 *
 */
const Test = ({ t }) => {

  const { isLoadingSendSmsOTP } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  /* 
  useEffect(() => {
    sendSmsOtpResponse !== output && setOutput(sendSmsOtpResponse);
  }, [sendSmsOtpResponse]);
 */

  const [validated, setValidated] = useState(false);
  const [output, setOutput] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testeValidateNif();
    }
  };

  const testeValidateNif = () => {
    /* const payload = {
      phone: nif,
      message,
      username: user,
      password,
    };
    dispatch(sendSmsOTP(payload)); */
  };

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Form.Row>
          <Col sm="12" md="8" lg="6">
            <Form.Group controlId="validationSSOLogin">
              <Button
                disabled={
                  !user?.organization?.id ||
                  Object.keys(apiSettings).length === 0
                }
                className="card-button onboarding-button"
                type="submit"
              >
                {isLoadingSendSmsOTP ? (
                  <>
                    {t("onboarding.general.loading")}
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                ) : (
                  t("onboarding.general.login")
                )}
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
      <Output output={output} />
    </div>
  );
};
export default withNamespaces()(Test);
