import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";
import { GAI_MIDDLEWARE } from "~/utils/constants";
import { TabsKey } from "../../utils/TabsEnum";
import ApplicationCard from "./ApplicationCard";


const GAIComponent = ({ tabKey }) => {
  const dispatch = useDispatch();

  const { isGAION, loadingGAI } = useSelector(
    (state) => state.systemSettingsApplicationsReducer
  );

  useEffect(() => {
    if (tabKey === TabsKey.APPLICATIONS) {
      dispatch(actions.pingMiddlewareApplication(GAI_MIDDLEWARE));
      const payloadIFlow = {
        url: "/main.jsp",
        application: {
          context: "GAI",
        },
      };
      dispatch(actions.pingIFlowBasedApplication(payloadIFlow));
    }
  }, [tabKey]);

  return (
    <ApplicationCard
      name="GAI"
      status={isGAION}
      loading={loadingGAI}
      showMW={true}
    />
  );
};

export default GAIComponent;
