export const PULL_DATA_FROM_BPM = 'systemSettings/applications@PULL_DATA_FROM_BPM';
export const PULL_DATA_ALL_MIDDLEWARE = 'systemSettings/applications@PULL_DATA_ALL_MIDDLEWARE';
export const SET_PULLED_DATA_FROM_BPM = 'systemSettings/applications@SET_PULLED_DATA_FROM_BPM';
export const SET_PULLED_ALL_MIDDLEWARE = 'systemSettings/applications@SET_PULLED_ALL_MIDDLEWARE';
export const pullDataFromBPM = () => ({ type: PULL_DATA_FROM_BPM });
export const pullDataAllMiddleware = () => ({ type: PULL_DATA_ALL_MIDDLEWARE });
export const setPulledDataFromBPM = success => ({ type: SET_PULLED_DATA_FROM_BPM, success });
export const setPulledAllMiddleware = success => ({ type: SET_PULLED_ALL_MIDDLEWARE, success });

export const PING_MIDDLEWARE_APPLICATION = 'systemSettings/applications@PING_MIDDLEWARE_APPLICATION';
export const SET_PING_MIDDLEWARE_APPLICATION = 'systemSettings/applications@SET_PING_MIDDLEWARE_APPLICATION';
export const pingMiddlewareApplication = middleware => ({ type: PING_MIDDLEWARE_APPLICATION, middleware });
export const setPingMiddlewareApplication = payload => ({ type: SET_PING_MIDDLEWARE_APPLICATION, payload });

export const PING_IFLOW_BASED_APPLICATION = 'systemSettings/applications@PING_IFLOW_BASED_APPLICATION';
export const SET_PING_IFLOW_BASED_APPLICATION = 'systemSettings/applications@SET_PING_IFLOW_BASED_APPLICATION';
export const pingIFlowBasedApplication = payload => ({ type: PING_IFLOW_BASED_APPLICATION, payload });
export const setPingIFlowBasedApplication = payload => ({ type: SET_PING_IFLOW_BASED_APPLICATION, payload });



 

export const GET_SYNC_LIST_MW = "systemSettings/Sync@GET_SYNC_LIST_MW";
export const GET_SYNC_ENCRYPT = "systemSettings/Sync@GET_SYNC_ENCRYPT";
export const SET_SYNC_LIST_MW = "systemSettings/Sync@SET_SYNC_LIST_MW";
export const POST_SYNC_LIST_MW = "systemSettings/Sync@POST_SYNC_LIST_MW";
export const SET_MESSAGE_SYNC_LIST_MW = "systemSettings/Sync@SET_MESSAGE_SYNC_LIST_MW";
export const SET_MESSAGE_ENCRYPT = "systemSettings/Sync@SET_MESSAGE_ENCRYPT";
export const getSyncListMW = () => ({ type: GET_SYNC_LIST_MW });
export const getSyncEncrypt = (encrypt) => ({ type: GET_SYNC_ENCRYPT, encrypt });
export const setSyncListMW = (syncMWList) => ({ type: SET_SYNC_LIST_MW, syncMWList});
export const setMessagePostMWSync = (resSyncMWList) => ({ type: SET_MESSAGE_SYNC_LIST_MW, resSyncMWList });
export const setMessagePostMWEncrypt = (resEncyptMWList) => ({ type: SET_MESSAGE_ENCRYPT, resEncyptMWList });
export const postSyncListMW = (payload) => ({
    type: POST_SYNC_LIST_MW,
    payload,
  });
  