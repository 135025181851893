import qs from 'qs'
import { put, call } from 'redux-saga/effects';
import http from '~/utils/config/http';

import {
  setMenuApplication,
  setMenuApplicationName,
  setSettingsMenuApplication,
  setSaveApplicationMenu,
  setRawMenus,
  setRawFlows,
  doneEditApplicationMenu,
  doneDeleteApplicationMenu
} from './actionTypes';

import { activeError } from '~/components/Messages/actions';

import { BPM_MIDDLEWARE } from '~/utils/constants';

export function* findMenuByApplication({ application }) {
  try {
    const resp = yield call(findApplicationMenu, application);
    yield put(setMenuApplication(resp.data));
  } catch (e) {
    yield put(activeError());
  }
}

export function* findMenuByApplicationName({ applicationName }) {
  try {
    const resp = yield http.get(`/api/application-menu/find/name/${applicationName}`);
    yield put(setMenuApplicationName(resp.data));
  } catch (e) {
    yield put(activeError());
  }
}

export function* findSettingsMenuByApplication({ application }) {
  try {
    const resp = yield call(findApplicationMenu, application);
    yield put(setSettingsMenuApplication(resp.data));
  } catch (e) {
    yield put(activeError());
  }
}

export function* findApplicationMenu(application) {
  return yield http.get(`/api/application-menu/find/${application}`);
}

export function* saveApplicationMenu({ applicationMenu }) {
  try {
    const resp = yield http.post('/api/application-menu/save', applicationMenu);
    yield put(setSaveApplicationMenu(resp.data));
  } catch (e) {
    yield put(activeError());
  }
}

//Old
export function* getMenusByApplication({ data }) {
  try {
    yield put(setRawMenus([]));//clear 
    const resp = yield http.get(`/fin/user-menu?${qs.stringify(data)}`, BPM_MIDDLEWARE);
    //console.log('getMenusByApplication', resp.data)
    yield put(setRawMenus(resp.data));
  } catch (exception) {
    //TODO
  }
}
export function* getMenusByFlows({ data }) {
  try {
    yield put(setRawFlows([]));//clear 
    const resp = yield http.get(`/fin/user-flows?${qs.stringify(data)}`, BPM_MIDDLEWARE);
    //console.log('getMenusByApplication', resp.data)
    yield put(setRawFlows(resp.data));
  } catch (exception) {
    //TODO
  }
}

export function* editApplicationMenu({ applicationMenu }) {
  try {
    yield http.put('/api/application-menu/update', applicationMenu);
    yield put(doneEditApplicationMenu(true));
  } catch (exception) {
    yield put(doneEditApplicationMenu(false));
  }
}

export function* deleteApplicationMenu({ id }) {
  try {
    yield http.delete(`/api/application-menu/delete/${id}`);
    yield put(doneDeleteApplicationMenu(true));
  } catch (exception) {
    yield put(doneDeleteApplicationMenu(false));
  }
}

