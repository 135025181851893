import React from "react";
import { withNamespaces } from "react-i18next";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import {
  adaptListForDocType,
  adaptListForOperations
} from "~pages/PortalRH/components/DocumentManagement/handleDropdownList";

const CreateModal = (props) => {
  const {
    //General modal funcionalities
    createMessageVariableButtons,
    setFocus,
    //Specific modal functionalities
    showCreateModal,
    closeCreateModal,
    //Email template data
    operations,
    selectedOperationId,
    setSelectedDocTypeId,
    subject,
    setSubject,
    message,
    setMessage,
    //Validation Funcionalities
    handleDropdownClassName,
    handleSubmit,
    handleSetSelectedOperationId,
    //validation data
    validated,
    operationError,
    docTypeError,
    //Reset docType dropdown when needed
    resetDocTypeDropdown,
    setResetDocTypeDropdown,
    //track cursor position in textareas
    subjectRef,
    messageRef,
    t,
  } = props;

  const asterisk = <i style={{ color: "#dc3545" }}>*</i>;

  return (
    <>
      <Modal show={showCreateModal} onHide={closeCreateModal} size="lg">
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title className="default-modal-title">{t("portalrh.emailTemplate.newTemplate")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Col sm={3}>
                <Form.Label style={{ marginTop: "7px" }} className="default-label">
                  {t("portalrh.emailTemplate.operation")} {asterisk}
                </Form.Label>
              </Col>
              <Col sm={9}>
                <Dropdown
                  list={adaptListForOperations(
                    operations,
                    t("portalrh.documentManagement.selectOperationPlaceholder")
                  )}
                  className={handleDropdownClassName(operationError)}
                  handleSubmit={handleSetSelectedOperationId}
                  required
                />
                {(operationError && validated) ? <p className="dropdown-text-invalid">{t("portalrh.emailTemplate.warnnings.operationMissing")}</p> : <p></p>}
              </Col>
            </Form.Row>
            <Form.Row>
              {selectedOperationId ? (
                <>
                  <Col sm={3}>
                    <Form.Label style={{ marginTop: "7px" }} className="default-label">
                      {t("portalrh.emailTemplate.docType")} {asterisk}
                    </Form.Label>
                  </Col>
                  <Col sm={9}>
                    <Dropdown
                      list={adaptListForDocType(
                        operations, selectedOperationId,
                        t("portalrh.documentManagement.selectOperationPlaceholder")
                      )}
                      className={handleDropdownClassName(docTypeError)}
                      handleSubmit={setSelectedDocTypeId}
                      resetDropdown={resetDocTypeDropdown}
                      setResetDropdown={setResetDocTypeDropdown}
                      required
                    />
                    {(docTypeError && validated) ? <p className="dropdown-text-invalid">{t("portalrh.emailTemplate.warnnings.documentTypeMissing")}</p> : null}
                  </Col>
                </>
              ) : ""}
            </Form.Row>
            <Form.Row>
              <Form.Label className="default-label">
                {t("portalrh.emailTemplate.subject")}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder={t("portalrh.emailTemplate.subject")}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                onFocus={() => setFocus("subject")}
                ref={subjectRef}
              />
            </Form.Row>
            <Form.Row>
              <Form.Label className="default-label">
                {t("portalrh.emailTemplate.message")} {asterisk}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder={t("portalrh.emailTemplate.message")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onFocus={() => setFocus("message")}
                ref={messageRef}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("portalrh.emailTemplate.warnnings.textMessageMissing")}
              </Form.Control.Feedback>
            </Form.Row>
            <br></br>
            <Form.Label className="default-label">
              {t("portalrh.emailTemplate.messageVariables.title")}
            </Form.Label>
            <Form.Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {createMessageVariableButtons(subjectRef, messageRef)}
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="btn" onClick={closeCreateModal}>
              {t("general.cancel")}
            </Button>
            <Button
              className="card-button"
              type="submit"
            >
              {t("general.add")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default withNamespaces()(CreateModal);