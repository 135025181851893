import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { Card, Button } from "react-bootstrap";
import "~/assets/css/icons.css";

import UnikUserTable from "./components/UnikUserTable";
import ProtectedPage from "~/components/security/ProtectedPage";
import Helper from "~/components/Helper";

import { requestUsers, clearUsers } from "./actions";
import { hasRoles } from "~/components/security/utils";

import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";

const UserList = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector((state) => state.globalReducer);
  const { users } = useSelector((state) => state.userManagementReducer);

  useEffect(() => {
    dispatch(requestUsers({ username: "" }));
    return () => {
      dispatch(clearUsers());
    };
  }, []);

  const addUser = () => {
    history.push("/uniksystem/admin/new-unikuser");
  };

  const data = [
    { key: "Name", value: "menu.admin.userManagement" },
    { key: "Icon", value: "icon-admin mr-2" },
    { key: "Order", value: "2" },
    { key: "Main menu", value: "false" },
    { key: "Application", value: "System Administrator" },
  ];

  const filteredList = (users) =>
    users.filter(
      (listUser) =>
        listUser?.organizationId === user?.organization?.id &&
        listUser?.organizationName
    );

  const filterUsersByRoles = () => {
    return hasRoles(user, ROLE_ADMIN, true) ? users : filteredList(users);
  };

  return (
    <ProtectedPage roles={[ROLE_ADMIN, ROLE_ORG_ADMIN]}>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-hr mr-2" />
              {t("admin.userManagement.list.cardTitle")}
            </h6>
            <Helper data={data} />
          </Card.Header>

          <Card.Body>
            <UnikUserTable users={filterUsersByRoles()} addUser={addUser} />
          </Card.Body>
        </Card>
      </div>
    </ProtectedPage>
  );
};
export default withNamespaces()(UserList);
