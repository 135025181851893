import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { find } from "lodash";

import WidgetContainer from "~/components/WidgetContainer";
import ProtectedPage from "~/components/security/ProtectedPage";
import ProcessosFormHandler from "~/components/ProcessosFormHandler";

import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import { BPM_MIDDLEWARE, VALIDPROCS_APP } from "~/utils/constants";
import ContentContainer from "~/containers/ContentContainer";

import {
  getMenusByApplication,
  addItemsToMenu,
} from "~/store/ducks/applicationMenu/actionTypes";

import Tasks from "~/components/Tasks";
import Processes from "~/components/Processes";

const ProcessValidation = (props) => {
  const { isMongoOn } = props;
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { rawMenus, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );

  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      dispatch(getMenusByApplication(username, VALIDPROCS_APP));
    }
  }, [user, dispatch]);

  /*
  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.validprocs.processos" });
    const items = rawMenus.map((item) => ({
      name: item.label,
      url: item.url,
      route: `${VALIDPROCS_APP}/processos/form/${item.label}`,
      menuParent: processosMenu ? processosMenu.id : null,
    }));
    dispatch(addItemsToMenu(items));
  }, [rawMenus, menu, dispatch]);
  */

  useEffect(() => {
    const processosMenu = find(menu, { name: "menu.validprocs.processos" });
    let items = [];
    let submenus;
    if (processosMenu) {
      const extraConfiguration_ = JSON.parse(
        processosMenu.extraConfiguration || false
      );
      submenus = extraConfiguration_.submenus
        ? extraConfiguration_.submenus
        : null;
      if (submenus) {
        Object.keys(submenus).forEach((key) => {
          //check if submenu has children
          let hasChildren = false;
          if (submenus[key].length == 0) hasChildren = false;
          else {
            submenus[key].every((flowid) => {
              const res = rawMenus.every((rm) => {
                //find flowid
                if (rm.url) {
                  let arr = rm.url.split("flowid=");
                  const flowid_ = arr[1];
                  if (flowid_ == flowid) {
                    hasChildren = true;
                    return false;
                  }
                  return true;
                }
                return true;
              });
              return res;
            });
          }
          if (hasChildren) {
            items.push({
              id: key,
              name: key,
              submenu: true,
              route: `${VALIDPROCS_APP}/processos/submenu/${key}`,
              menuParent: processosMenu ? processosMenu.id : null,
            });
          }
        });
      }
      const getProcessosMenuId = () =>
        processosMenu ? processosMenu.id : null;
      const getSubMenuParentId = (url) => {
        if (url) {
          let arr = url.split("flowid=");
          const flowId = arr[1];
          let id = getProcessosMenuId();
          Object.keys(submenus).forEach((key) => {
            if (submenus[key].some((i) => i == flowId)) {
              id = key;
            }
          });
          return id;
        } else return getProcessosMenuId();
      };
      rawMenus.forEach((item) => {
        items.push({
          name: item.label,
          url: item.url,
          route: `${VALIDPROCS_APP}/processos/form/${item.label}`,
          menuParent: submenus
            ? getSubMenuParentId(item.url)
            : getProcessosMenuId(),
        });
      });
      dispatch(addItemsToMenu(items));
    }
  }, [rawMenus, menu, dispatch]);

  return (
    <>
      <ApplicationMenu applicationName={VALIDPROCS_APP} />
      <Switch>
        <Route path={`${path}/tarefas`} exact>
          <ProtectedPage
            roles={["ADMIN", "ValidProcsJurista", "ValidProcsQAOCR"]}
          >
            <ContentContainer>
              <Tasks app={"validprocs"} className="main-card-v2" />
            </ContentContainer>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/pesquisar`} exact>
          <ProtectedPage roles={["ADMIN", "ValidProcsAuditor"]}>
            <ContentContainer>
              <Processes app={"validprocs"} className="main-card-v2" />
            </ContentContainer>
          </ProtectedPage>
        </Route>

        <Route exact path={`${path}/processos`}>
          <ProtectedPage roles={["ADMIN", "ValidProcsAuditor"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/submenu`}>
          <ProtectedPage roles={["ADMIN", "ValidProcsAuditor"]}>
            <div></div>
          </ProtectedPage>
        </Route>
        <Route path={`${path}/processos/form`}>
          <ProtectedPage roles={["ADMIN", "ValidProcsAuditor"]}>
            <ProcessosFormHandler app={"validprocs"} className="main-card-v2" />
          </ProtectedPage>
        </Route>

        <Route path={path} exact>
          {isMongoOn && (
            <ProtectedPage
              roles={[
                "ADMIN",
                "ValidProcsAuditor",
                "ValidProcsJurista",
                "ValidProcsQAOCR",
              ]}
            >
              <ContentContainer>
                <WidgetContainer />
              </ContentContainer>
            </ProtectedPage>
          )}{" "}
        </Route>
      </Switch>
    </>
  );
};
export default ProcessValidation;
