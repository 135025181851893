import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { withNamespaces } from "react-i18next";
import { useDispatch } from "react-redux";
import "~/assets/css/icons.css";
import Spinner from "~/components/Spinner";
import { saveDocumentList, saveDocumentListWithUsername,getDocumentsbyUsername } from "~/store/ducks/portalrh/actionTypes";
import FilePreviewCard from "./FilePreviewCard";
import SelectFilters from "./SelectFilters";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

const SUPPORTED_FILES_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  "application/x-zip-compressed",
  "text/plain",
];

const UploadModal = (props) => {
  const {
    showUploadModal,
    handleCloseUploadModal,
    manager,
    collaborators,
    isLoadingCollaborators,
    operations,
    isLoadingOperations,
    uploadEmMassa,
    emailTemplates,
    user,
    udwUsers,
    findUDWUsers,
    legacyUsers,
    isLoadingLegacyUsers,
    sendEmail,
    t,
    colaboradorSelected
  } = props;

  const dispatch = useDispatch();

  const [warningMessage, setWarningMessage] = useState("");
  const [onDragOver, setOnDragOver] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState("");
  const [docType, setDoctype] = useState("");
  const [selectedCollaborator, setSelectedCollaborator] = useState("");
  const [iconClassName, setIconClassName] = useState("icon-maximize mr-4");
  const [modalClassName, setModalClassName] = useState("modal-datacapture-80w");

  const inputFile = useRef(null);

  const [showExtraFields, setShowExtraFields] = useState(false);
  const [maxFiles, setMaxFiles] = useState(1);
  const [documents, setDocuments] = useState([]);
  const [comment, setComment] = useState("");
  const [expirationDate, setExpirationDate] = useState("");

  const [employeeNums, setEmployeeNums] = useState([]);
  const [collabReceivers, setCollabReceivers] = useState([]);
  const [legacyReceivers, setLegacyReceivers] = useState([]);

  const [sendEmailNotification, setSendEmailNotification] = useState(false);
  const [notificationOption, setNotificationOption] = useState("");
  const [checkForAll, setCheckForAll] = useState(false)

  const maxFileSize = 1024 * 1024 * 15; //15 Mb

  const portalSender = collaborators.filter(
    (el) =>
      el.username ===
      user.legacyUsers.filter(
        (el) => el.legacyApplication.label === PORTALRH_MIDDLEWARE.context
      )[0].username
  )[0];
  let to; //email component on submission if email is sent

  useEffect(() => {
    if (uploadEmMassa) setMaxFiles(100);
    else {
      setMaxFiles(1);
    }
  }, [uploadEmMassa]);

  useEffect(() => {
    if (showUploadModal && !uploadEmMassa) {
      let foundCollaborator;
      if (selectedCollaborator) {
        foundCollaborator = collaborators.find(
          (c) => c.id === selectedCollaborator
        );
      }
      setEmployeeNums(
        foundCollaborator
          ? [foundCollaborator.funcionarioNum]
          : [portalSender.funcionarioNum]
      );
    }
  }, [selectedCollaborator]);

  useEffect(() => {
    if (documents.length > 0 && documents !== undefined && uploadEmMassa) {
      setEmployeeNums(
        documents.map((doc) => doc.file.name.split("_")[1].split(".")[0])
      );
    }
  }, [documents, collaborators, legacyUsers]);

  useEffect(() => {
    if (
      employeeNums.length > 0 &&
      JSON.stringify(employeeNums) !==
        JSON.stringify([portalSender.funcionarioNum])
    ) {
      setCollabReceivers(
        collaborators.filter((el) =>
          employeeNums.find((num) => num === el.funcionarioNum)
        )
      );
    }
  }, [employeeNums]);

  useEffect(() => {
    if (collabReceivers.length > 0 && collabReceivers !== undefined) {
      setLegacyReceivers(
        legacyUsers.filter((lu) =>
          collabReceivers.find((cr) => cr.username === lu.username)
        )
      );
    }
  }, [collabReceivers]);

  useEffect(() => {
    if (legacyReceivers.length > 0 && legacyReceivers !== undefined) {
      dispatch(findUDWUsers(legacyReceivers));
    }
  }, [legacyReceivers]);

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    const files_ = event.dataTransfer.files;
    const files = [];
    for (let i = 0; i < files_.length; i++) {
      files.push(files_.item(i));
    }
    handleMultipleFiles(files);
    event.preventDefault();
  };

  const handleOnChoose = (event) => {
    if (event.target.multiple === true) {
      const files_ = event.target.files;
      const files = [];
      for (let i = 0; i < files_.length; i++) {
        files.push(files_.item(i));
      }
      handleMultipleFiles(files);
    } else {
      handleMultipleFiles([event.target.files[0]]);
    }
  };

  const resetStates = () => {
    setShowExtraFields(false);
    setSelectedCollaborator("");
    setSelectedOperation("");
    setDoctype("");
    setDocuments([]);
    setEmployeeNums([]);
    setCollabReceivers([]);
    setLegacyReceivers([]);
    setSendEmailNotification(false);
    setNotificationOption("");
    setCheckForAll(false)
  };

  const handleSaveDocs = () => {
    const docList = [];
    let isForAll = false;
    let foundCollaborator = null;
    if (documents.length == 1 && !uploadEmMassa) {
      documents.forEach((document) => {
        let codEntidade_ = null;
        let numFuncionario_ = null;
        if (selectedCollaborator) {
          foundCollaborator = collaborators.find(
            (c) => c.id === selectedCollaborator
          );
          if (foundCollaborator && foundCollaborator.codEntidade)
            codEntidade_ = foundCollaborator.codEntidade;
          if (foundCollaborator && foundCollaborator.funcionarioNum)
            numFuncionario_ = foundCollaborator.funcionarioNum;
        }
        docList.push({
          file: document.file,
          comment,
          expirationDate,
          classId: docType,
          operationId: selectedOperation,
          targetUserIds: [],
          notificationId: 0,
          requestId: 0,
          numFuncionario: numFuncionario_,
          codEntidade: codEntidade_,
          seen: false,
        });
      });
    } else if (documents.length > 0 && uploadEmMassa) {
      documents.forEach((document) => {
        docList.push({
          file: document.file,
          classId: docType,
          operationId: selectedOperation,
          targetUserIds: [],
          notificationId: 0,
          requestId: 0,
          seen: false,
        });
      });
      isForAll = checkForAll;
    }

    if (docList.length > 0) {
      if (colaboradorSelected != undefined) {
        dispatch(
          saveDocumentListWithUsername(docList, uploadEmMassa, colaboradorSelected.username, isForAll)
        );
      } else {
        dispatch(saveDocumentList(docList, uploadEmMassa, isForAll));
      }
      handleEmailNotifications(foundCollaborator, docList);
    }
    resetStates();
  };

  const handleEmailNotifications = (foundCollaborator, docList) => {
    if (sendEmailNotification) {
      const emailTemplate = emailTemplates.find(
        (el) => el.operationId == selectedOperation && el.docTypeId == docType
      );

      const senderName = user.detail.firstName + " " + user.detail.lastName;

      let greet, receiversNames;

      //Greetings Setup
      const today = new Date();
      if (today.getHours() > 6 && today.getHours() <= 12) {
        greet = t("portalrh.emailTemplate.messageVariables.greet.goodMorning");
      } else if (today.getHours() > 12 && today.getHours() <= 19) {
        greet = t(
          "portalrh.emailTemplate.messageVariables.greet.goodAfternoon"
        );
      } else if (today.getHours() > 19 || today.getHours() <= 6) {
        greet = t("portalrh.emailTemplate.messageVariables.greet.goodNight");
      }

      //Singular Uploads
      if (!uploadEmMassa) {
        receiversNames = foundCollaborator
          ? [foundCollaborator.nome]
          : [senderName];
      }
      //Mass Uploads
      else {
        receiversNames = collabReceivers.map((el) => el.nome);
      }

      const msgVariables = {
        sender: senderName,
        receiver: receiversNames,
        timeSensitiveGreetings: greet,
        documentTitle: documents.map((doc) => doc.file.name),
        documentType: emailTemplate.docType,
        operation: emailTemplate.operation,
        date:
          today.getDate() + "-" + today.getMonth() + "-" + today.getFullYear(),
        employeeNum:
          employeeNums.length > 0
            ? employeeNums
            : [portalSender.funcionarioNum],
      };

      if (udwUsers) {
        const receiversEmails = udwUsers
          .filter((el) => el.email && el.email !== "")
          .map((el) => el.email);
          const getTo = collaborators.filter((el) => el.id === selectedCollaborator);
        to = receiversEmails.concat(getTo[0].email); 
      } else {
        const getTo = collaborators.filter((el) => el.id === selectedCollaborator);
        to = getTo[0].email;
      }

      const email = {
        to: to,
        subject: emailTemplate.subject,
        message: emailTemplate.message,
        url: window.location.href,
      };

      let files = [];
      if (notificationOption === "0") {
        files = docList.map((el) => el.file);
      } //Annex Document

      dispatch(sendEmail(email, files, msgVariables));
    }
  };

  const handleMultipleFiles = (files) => {
    if (files.length <= maxFiles) {
      if (files.some((file) => !SUPPORTED_FILES_TYPES.includes(file.type))) {
        setWarningMessage(t("portalrh.documentManagement.checkFileType"));
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), 10000);
      } else {
        let totalfilesize = files.reduce(
          (accumulator, currentValue) => accumulator + currentValue.size,
          0
        );
        if (totalfilesize > maxFileSize) {
          setWarningMessage(t("portalrh.documentManagement.exceedFileSize"));
          setShowWarning(true);
          setTimeout(() => setShowWarning(false), 10000);
        } else {
          setShowExtraFields(true);
          const docs = [];
          files.forEach((f) => {
            docs.push({
              file: f,
            });
          });
          setDocuments(docs);
        }
      }
    } else {
      setWarningMessage(t("portalrh.documentManagement.tooManyFiles"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleDeleteDocument = (filename) => {
    if (documents.length == 1) {
      setDocuments([]);
      setShowExtraFields(false);
      setDoctype("");
      setComment("");
      setExpirationDate("");
    } else {
      if (filename) {
        setDocuments((docs) => docs.filter((d) => d?.file?.name !== filename));
      }
    }
  };

  const handleClickModal = () => {
    modalClassName === "modal-datacapture-80w"
      ? setModalClassName("modal-datacapture-100w")
      : setModalClassName("modal-datacapture-80w");
    iconClassName === "icon-maximize mr-4"
      ? setIconClassName("icon-restore mr-4")
      : setIconClassName("icon-maximize mr-4");
  };

  const handleCloseModal = () => {
    setModalClassName("modal-datacapture-80w");
    setIconClassName("icon-maximize mr-4");
    resetStates();
    handleCloseUploadModal();
  };

  return (
    <Modal
      show={showUploadModal}
      dialogClassName={modalClassName}
      backdrop="static"
      onHide={() => {
        resetStates();
        handleCloseUploadModal();
      }}
    >
      <Modal.Body style={{ padding: "0px" }}>
        <div className="main-card-v2" style={{ padding: "0px" }}>
          <Card bsPrefix="card-flat">
            <Card.Header
              className="justify-content-between"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ margin: "0" }}>
                {t("portalrh.documentManagement.fileUploadTitle")}{" "}
              </h4>
              <div>
                <i
                  className={iconClassName}
                  onClick={() => handleClickModal()}
                />
                <i
                  className="icon-remove mr-4"
                  onClick={() => handleCloseModal()}
                />
              </div>
            </Card.Header>

            <Card.Body style={{ display: "flex", flexDirection: "column" }}>
              {showWarning && (
                <Alert
                  variant="warning"
                  onClose={() => setShowWarning(false)}
                  dismissible
                >
                  <strong>Alert: </strong>
                  {warningMessage}
                </Alert>
              )}

              <Row className="justify-content-md-center">
                {documents && documents.length <= maxFiles && (
                  <Col sm="6" className="uploadArea">
                    <div
                      style={{
                        paddingTop: "32px",
                        paddingBottom: "10px",
                        alignSelf: "flex-start",
                      }}
                    >
                      {manager && !uploadEmMassa
                        ? selectedCollaborator &&
                          docType &&
                          !showExtraFields && (
                            <h5>
                              {t("portalrh.documentManagement.uploadToProceed")}
                            </h5>
                          )
                        : docType &&
                          !showExtraFields && (
                            <h5>
                              {t("portalrh.documentManagement.uploadToProceed")}
                            </h5>
                          )}
                    </div>

                    <SelectFilters
                      operations={operations}
                      manager={manager}
                      collaborators={collaborators}
                      showExtraFields={showExtraFields}
                      selectedOperation={selectedOperation}
                      setSelectedOperation={setSelectedOperation}
                      docType={docType}
                      setDoctype={setDoctype}
                      selectedCollaborator={selectedCollaborator}
                      setSelectedCollaborator={setSelectedCollaborator}
                      uploadEmMassa={uploadEmMassa}
                      emailTemplates={emailTemplates}
                      sendEmailNotification={sendEmailNotification}
                      setSendEmailNotification={setSendEmailNotification}
                      notificationOption={notificationOption}
                      setNotificationOption={setNotificationOption}
                      setCheckForAll={setCheckForAll}
                      checkForAll={checkForAll}
                    />

                    {!showExtraFields ? (
                      <>
                        <div
                          className="dropArea"
                          onDrop={(e) =>
                            manager && !uploadEmMassa
                              ? docType &&
                                selectedCollaborator &&
                                handleOnDrop(e)
                              : docType && handleOnDrop(e)
                          }
                          onClick={() =>
                            manager && !uploadEmMassa
                              ? docType && selectedCollaborator && handleClick()
                              : docType && handleClick()
                          }
                          onDragEnter={() => setOnDragOver(true)}
                          onDragLeave={() => setOnDragOver(false)}
                          style={
                            onDragOver
                              ? { background: "#fff" }
                              : { background: "#efefef" }
                          }
                        >
                          <span>
                            <i
                              className={
                                manager && !uploadEmMassa
                                  ? docType && selectedCollaborator
                                    ? "icon-submit portal-image-upload-icon-active"
                                    : "icon-submit portal-image-upload-icon"
                                  : docType
                                  ? "icon-submit portal-image-upload-icon-active"
                                  : "icon-submit portal-image-upload-icon"
                              }
                            />
                            <div
                              className={
                                manager && !uploadEmMassa
                                  ? docType && selectedCollaborator
                                    ? "portal-label-main-active slide-in-bottom"
                                    : "portal-label-main"
                                  : docType
                                  ? "portal-label-main-active slide-in-bottom"
                                  : "portal-label-main"
                              }
                            >
                              {t("portalrh.documentManagement.dropToUpload")}
                            </div>
                          </span>
                          <input
                            multiple={maxFiles > 1 ? true : false}
                            id="file"
                            type="file"
                            ref={inputFile}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              manager && !uploadEmMassa
                                ? docType &&
                                  selectedCollaborator &&
                                  handleOnChoose(e)
                                : docType && handleOnChoose(e)
                            }
                          />
                        </div>
                        <div className="dc-label">
                          {t("portalrh.documentManagement.totalFileSize") +
                            " 15 Mb, " +
                            t("portalrh.documentManagement.maxFiles") +
                            " " +
                            maxFiles}
                        </div>
                        <small>
                          {t("portalrh.documentManagement.supportedFiles")}
                        </small>
                      </>
                    ) : (
                      <div
                        style={{
                          height: "40vh",
                          caretColor: "transparent",
                          overflowY: "scroll",
                        }}
                      >
                        <br />
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {documents.map((doc, i) => {
                            return (
                              <FilePreviewCard
                                key={i}
                                document={doc}
                                handleDeleteDocument={handleDeleteDocument}
                                uploadEmMassa={uploadEmMassa}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Col>
                )}

                {showExtraFields && !uploadEmMassa && (
                  <Col sm="6">
                    <div className=" slide-in-bottom">
                      <div
                        style={{
                          paddingBottom: "10px",
                          alignSelf: "flex-start",
                        }}
                      >
                        <h5>
                          {t("portalrh.documentManagement.optioanlFields")}
                        </h5>
                      </div>

                      <Form.Label>
                        {t("portalrh.documentManagement.expirationDate")}
                      </Form.Label>
                      <DateTimePicker
                        value={expirationDate}
                        onChange={(e) => setExpirationDate(e)}
                        locale="pt-PT"
                        format="dd/MM/yyyy"
                        className="form-control"
                      />
                      <br />
                      <Form.Label>
                        {t(`portalrh.documentManagement.comment`)}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="2"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Spinner
                spinning={isLoadingOperations || isLoadingCollaborators}
                wrapper
              />
            </Card.Body>
            <Card.Footer style={{ minHeight: "50px" }}>
              <Button
                variant="danger"
                onClick={() => {
                  resetStates();
                  handleCloseUploadModal();
                }}
              >
                {t("general.cancel")}
              </Button>
              <Button
                className="card-button"
                disabled={
                  documents.length > 0 && !isLoadingLegacyUsers ? false : true
                }
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  documents.length > 0 && handleSaveDocs();
                }}
              >
                {t("general.save")}
              </Button>
            </Card.Footer>
          </Card>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default withNamespaces()(UploadModal);
