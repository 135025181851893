import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";

import Spinner from "~/components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { useInterval } from "~/components/Notifications/utils/useInterval.js";

import {
  getUserSynchronizationLog,
} from "~/store/ducks/portalrh/actionTypes";

const ErrorLogsTable = ({ t }) => {
  const { userSynchronizationLog, isLoadingUserSynchronizationLog } = useSelector(
    (state) => state.portalrhReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserSynchronizationLog());
  }, []);

  useInterval(() => {
    dispatch(getUserSynchronizationLog());
  }, 1000 * 60 * 3);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: t("portalrh.errorLogs.id"),
      sort: true,
    },
    {
      dataField: "numeroFuncionario",
      text: t("portalrh.errorLogs.employeeNum"),
      sort: true,
    },
    {
      dataField: "lotNumber",
      text: t("portalrh.errorLogs.lotNumber"),
      sort: true,
    },
    {
      dataField: "data",
      text: t("portalrh.errorLogs.data"),
      sort: true,
    },
    {
      dataField: "razaoErro",
      text: t("portalrh.errorLogs.errorDesc"),
      sort: true,
    },
  ];

  return (
    <>
      <div className="custom-table custom-table-small cursor-pointer">
            <ToolkitProvider
              keyField="id"
              data={userSynchronizationLog}
              columns={columns}
              search
              >
              {(props) => (
                <div>
                  <div style={{ paddingBottom: "10px", paddingTop: "10px", display: "flex" }}>
                    <SearchBar {...props.searchProps} placeholder={t("Search")} />
                  </div>

                  <Spinner spinning={isLoadingUserSynchronizationLog} />
                  {!isLoadingUserSynchronizationLog && (
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      pagination={paginationFactory(pagination)}
                      noDataIndication={t("No Errors Found")}
                      striped
                      hover
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
      </div>
    </>
  );
};
export default withNamespaces()(ErrorLogsTable);