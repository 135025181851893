import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import iconCheckmark from "../assets/img/icons/icon-checkmark-border.png";
import "../assets/scss/_verticalNav.scss";
import {
  setCurrentVertical
} from "~/store/ducks/onboarding/actionTypes";
import { useDispatch ,useSelector} from "react-redux";

const VerticalNavItem = ({
  variant,
  name,
  text,
  setTheActiveTab,
  activeTab,
  disabled,
  action,
  t,
}) => {
  const [tabOpLeft, setTabOpLeft] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (action) {
      const variable = action?.split("::")[0];
      const value = action?.split("::")[1];
      if (document.dados[variable]) {
        if (activeTab === name) {
          if (document.dados[variable]?.value !== value) {
            document.dados[variable].value = value;
          }
        }
      }
      
      if(isFirstTime)
      {
        setIsFirstTime(false)
      if (
        document?.dados["num_repleg_to_submit"] &&
        name === t(`bpm.onboarding.backoffice.user.verticalTabRepLeg`)
      ) {



        setTabOpLeft((document?.dados["num_repleg_to_submit"].value));
      }
      if (
        document?.dados["num_docs_to_submit"] &&
        name === t(`bpm.onboarding.backoffice.user.verticalTabDocuments`)
      ) {

        setTabOpLeft(document?.dados["num_docs_to_submit"].value);
      }
      if (
        document?.dados["num_benef_to_submit"] &&
        name === t(`bpm.onboarding.backoffice.user.verticalTabBenef`)
      ) {
        setTabOpLeft(document?.dados["num_benef_to_submit"].value);
      }
    }
    }
  }, [activeTab]);

  return (
    <>
      <>
        <div
          className={
            variant &&
            `easypay-tab-separator ${
              name === activeTab && "easypay-active-tab"
            }`
          }
        >
          <button
            id={name}
            className={`easypay-vertical-nav-item ${
              name === activeTab && "easypay-active"
            } ${variant}`}
            onClick={() => {
              setTheActiveTab(name);
              dispatch(setCurrentVertical(name));
            }}
            type="button"
            disabled={disabled}
          >
            {t(text)}
            {tabOpLeft === "" ? null : tabOpLeft === "0" ? (
              <img src={iconCheckmark} alt="check icon" />
            ) : (
              tabOpLeft !== ",," && ( //Bug that couldn't show in console logging tabOpLeft
                <div className="easypay-nav-badge">{tabOpLeft}</div>
              )
            )}
          </button>
        </div>
      </>
    </>
  );
};

export default withNamespaces()(VerticalNavItem);
