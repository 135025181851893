import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useRouteMatch } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import update from "react-addons-update";
import uuid from "uuid/v1";
import { isEmpty, some } from "lodash";

import { Accordion, Card, Spinner } from "react-bootstrap";

import { generateTreeMenu } from "../../Menus/utils";
import BackofficeMenuItem from "./BackofficeMenuItem";
import ProtectedComponent from "~/components/security/ProtectedComponent";

import { closeSideBar } from "~/store/ducks/applicationMenu/actionTypes";
import { updateUserSettings } from "~/pages/User/actions";
import { hasRoles } from "~/components/security/utils";

import { UNIKSYSTEM_BACKEND } from "~/utils/constants";
import { ROLE_ADMIN } from "~/utils/constants/roles";

import {
  setProcessFormUrl,
  setProcessFormMenuItemClicked,
} from "~/containers/Layout/actions";

const ApplicationMenuItem = ({
  t,
  showMenu,
  setMainActiveKey,
  mainActiveKey,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const [activekey, setActivekey] = useState(-1);

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const treeMenu = generateTreeMenu(menu);
  const [subMenuKey, setSubMenuActiveKey] = useState(-1);
  const [subMenuStyleKey, setSubMenuStyleKey] = useState(-1);

  const accordionKeys = [];
  /*Toggle Accordion and Style Active Menu Item*/
  useEffect(() => {
    treeMenu.forEach((item, index) => {
      accordionKeys.push({
        route: item.route ? item.route : item.name,
        styleKey: index,
        toggleKey: item.menuParent ? item.menuParent : item.id,
      });
      if (item.childrens) {
        item.childrens.forEach((child, index) => {
          accordionKeys.push({
            route: child.route ? child.route : child.name,
            styleKey: index + treeMenu.length,
            toggleKey: child.menuParent,
            isChild: true,
          });
          if (child.childrens) {
            child.childrens.forEach((child_, index) => {
              accordionKeys.push({
                route: child_.route ? child_.route : child_.name,
                //styleKey: index + child.childrens.length,
                styleKey: index + treeMenu.length,
                toggleKey: item.menuParent ? item.menuParent : item.id,
                subMenuKey: child.id,
                isChild: true,
              });
            });
          }
        });
      }
    });

    const pathname = window.location.href.split("#/uniksystem/")[1];
    const object = accordionKeys.find(
      (obj) => obj.route === decodeURI(pathname)
    );
    if (object) {
      if (object.isChild) {
        //used after page refresh
        setMainActiveKey(object.toggleKey); //toggle Main Accordion
      }
      if (object.subMenuKey) {
        setSubMenuActiveKey(object.subMenuKey); //toggle Sub Accordion
        setSubMenuStyleKey(object.styleKey); //add styles to Sub Item
        setActivekey(-1); //clear state
      } else {
        setActivekey(object.styleKey); //style selected Item
      }
    }
    //console.log(accordionKeys)
  }, [menu, treeMenu, mainActiveKey]);

  const uniksystem = UNIKSYSTEM_BACKEND.context;
  const isFavourite = (item) => {
    const { favourites } = userSettings;
    if (favourites) {
      return some(favourites, { pathname: `${uniksystem}/${item.route}` });
    }
  };

  const handlePinClick = (item) => {
    const { favourites } = userSettings;
    let newFavs;
    if (isFavourite(item)) {
      //Remove favourite
      const index = favourites.findIndex(
        (obj) => obj.pathname === `${uniksystem}/${item.route}`
      );
      newFavs = update(favourites, { $splice: [[index, 1]] });
      const payload = userSettings;
      payload.favourites = newFavs;
      dispatch(updateUserSettings(payload));
    } else {
      //Add favourite
      newFavs = isEmpty(favourites) ? [] : favourites;
      newFavs.push(createFav(item));
      const payload = userSettings;
      payload.favourites = newFavs;
      dispatch(updateUserSettings(payload));
    }
  };

  const createFav = (item) => ({
    pathname: `${uniksystem}/${item.route}`,
    state: {
      name: item.name,
      icon: item.icon,
    },
  });

  const handleRoute = (route) => {
    if (route) {
      history.push(`/uniksystem/${route}`);
    }
  };

  const handleRegularItemClick = (index, item, parentId, level3) => {
    if (level3) {
      setSubMenuStyleKey(index);
      setActivekey(-1); //clear state
    } else {
      setActivekey(index);
      setSubMenuActiveKey(-1); //clear state
      setSubMenuStyleKey(-1); //clear state
    }
    handleRoute(item.route);
    parentId ? setMainActiveKey(parentId) : setMainActiveKey(item.id);
    dispatch(setProcessFormUrl(item.url)); //used by ProcessosFormHandler
    dispatch(setProcessFormMenuItemClicked(true)); //used by ProcessosFormHandler

    const location = {
      pathname: `${url}/${item.route}`,
      state: { name: item.name },
    };
    //console.log(location);
    /*Send Context to iframe*/
    const sendUserContext = () => {
      let config = configuration?.loginPage;
      const context = {
        theme: config === "nblp" ? "nblp" : userSettings.theme ? userSettings.theme : "default",
        language: userSettings.language ? userSettings.language : "EN",
        location: location,
      };

      let event = new CustomEvent("UserContextEvent", { detail: context });

      if (document.getElementById("bpm")) {
        if (document.getElementById("bpm").contentWindow) {
          document.getElementById("bpm").contentWindow.dispatchEvent(event);
          console.log("after disptaching to bpm");
        }
      }

      if (document.getElementById("gdpr")) {
        if (document.getElementById("gdpr").contentWindow) {
          document.getElementById("gdpr").contentWindow.dispatchEvent(event);
          console.log("after disptaching to gdpr");
        }
      }
      if (document.getElementById("compliance")) {
        if (document.getElementById("compliance").contentWindow) {
          document
            .getElementById("compliance")
            .contentWindow.dispatchEvent(event);
          console.log("after disptaching to compliance");
        }
      }

      if (document.getElementById("gai")) {
        if (document.getElementById("gai").contentWindow) {
          document.getElementById("gai").contentWindow.dispatchEvent(event);
          console.log("after disptaching to gai");
        }
      }

      if (document.getElementById("rh")) {
        if (document.getElementById("rh").contentWindow) {
          document.getElementById("rh").contentWindow.dispatchEvent(event);
          console.log("after disptaching to rh");
        }
      }

      if (document.getElementById("portalrh")) {
        if (document.getElementById("portalrh").contentWindow) {
          document
            .getElementById("portalrh")
            .contentWindow.dispatchEvent(event);
          console.log("UDW: 4 after disptaching to portalrh");
        }
      }
    };
    sendUserContext();

    if (window.innerWidth < 992) dispatch(closeSideBar());
  };

  const getAccordionClass = (index, isChildren, level3) => {
    let className;
    if (level3) {
      className = "menu-toggle-lvl3";
      className +=
        index === subMenuStyleKey ? " menu-toggle-lvl3-active-div" : "";
    } else {
      className = isChildren ? "menu-toggle-lvl2" : "menu-toggle-lvl1";
      className += index === activekey ? " menu-toggle-lvl2-active-div" : "";
    }
    return className;
  };

  const getSubAccordionClass = (index, level3) => {
    if (level3) {
      return index === subMenuStyleKey ? "menu-toggle-lvl3-active" : "";
    } else {
      return index === activekey ? "menu-toggle-lvl2-active" : "";
    }
  };

  const buildMenu = (
    index,
    item,
    isChildren = false,
    parentId = undefined,
    level3 = false
  ) => {
    /*Menu Item with children */
    if (!isEmpty(item.childrens)) {
      const className_ = getAccordionClass(
        index,
        (isChildren = item.submenu ? true : false)
      );
      return !item?.route?.includes("orgadmin") ? (
        <ProtectedComponent permissions={item.permissions} key={uuid()}>
          <Card className="menu-item">
            <Accordion.Toggle
              as={Card.Header}
              eventKey={item.id}
              className={className_}
              onClick={() => {
                if (!item.submenu) {
                  setActivekey((activeKey) =>
                    activeKey === index ? -1 : index
                  );
                  setMainActiveKey((key) => (key === item.id ? "" : item.id));
                  setSubMenuActiveKey(-1); //clear state
                  setSubMenuStyleKey(-1); //clear state
                  handleRoute(item.route);
                } else {
                  setActivekey((activeKey) =>
                    activeKey === index ? -1 : index
                  ); //for level2 style
                  setSubMenuActiveKey((key) =>
                    key === item.id ? "" : item.id
                  ); //for toggle
                  setSubMenuStyleKey(-1);
                  handleRoute(item.route);
                }
              }}
            >
              <span
                className={index === activekey ? "menu-toggle-lvl2-active" : ""}
              >
                <i className={item.icon}></i>
                {t(item.name)}
              </span>
            </Accordion.Toggle>
            {item.childrens.map((children, index) => (
              <Accordion.Collapse key={uuid()} eventKey={item.id}>
                <Accordion activeKey={subMenuKey}>
                  {buildMenu(
                    index + treeMenu.length,
                    children,
                    true,
                    (parentId = item.id),
                    (level3 = item.submenu ? true : false)
                  )}
                </Accordion>
              </Accordion.Collapse>
            ))}
          </Card>
        </ProtectedComponent>
      ) : null;
    } else if (isEmpty(item.childrens) && item.mainMenu) {
      return MainMenuItem(index, item);
    } else if (isEmpty(item.childrens) && !item.mainMenu) {
      if (item.route && item.route.includes("/backoffice"))
        return (
          <ProtectedComponent permissions={item.permissions} key={uuid()}>
            <BackofficeMenuItem item={item} />
          </ProtectedComponent>
        );
      else {
        /*Menu Item without children */
        return RegularMenuItem(index, item, isChildren, parentId, level3);
      }
    }
  };

  const MainMenuItem = (index, item) => (
    <ProtectedComponent permissions={item.permissions} key={uuid()}>
      <Card className="menu-item">
        <Accordion.Toggle
          as={Card.Header}
          eventKey={item.id}
          className="menu-toggle-main"
          onClick={() => {
            setActivekey(index);
            history.push(item.route ? item.route : `/uniksystem/${item.name}`);
            setMainActiveKey(item.id);
          }}
        >
          <div
            to={item.route ? item.route : `/uniksystem/${item.name}`}
            className="menu-link-main"
          >
            <i className={item.icon}></i>
            <span>{t(`menu.mainItems.${item.name}`)}</span>
          </div>
          <span
            className="close-sidebar-icon float-right"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(closeSideBar());
            }}
          >
            &times;
          </span>
        </Accordion.Toggle>
      </Card>
    </ProtectedComponent>
  );

  const RegularMenuItem = (index, item, isChildren, parentId, level3) => {
    if (showMenu) {
      return (
        //Only renders routes with "orgadmin" when it's not a user with ROLE_ADMIN
        !item?.route?.includes("orgadmin") || !hasRoles(user, [ROLE_ADMIN]) ? (
        <ProtectedComponent permissions={item.permissions} key={uuid()}>
          <Card className="menu-item">
            <Accordion.Toggle
              as={Card.Header}
              eventKey={item.id}
              className={getAccordionClass(index, isChildren, level3)}
              onClick={() =>
                handleRegularItemClick(index, item, parentId, level3)
              }
            >
              {isChildren && (
                <span className={getSubAccordionClass(index, level3)}>
                  <i className={item.icon} />
                  {t(item.name)}
                </span>
              )}
              {!isChildren && (
                <>
                  <Link
                    to={item.route ? item.route : url}
                    className="menu-link"
                  >
                    <span
                      className={
                        index === activekey ? "menu-toggle-lvl2-active" : ""
                      }
                    >
                      <i className={item.icon}></i>
                      {t(item.name)}
                    </span>
                  </Link>
                  <i
                    style={
                      index === activekey
                        ? { marginTop: 12, marginRight: 16 }
                        : {}
                    }
                    className={
                      isFavourite(item) ? "icon-pin-active" : "icon-pin"
                    }
                    onClick={() => handlePinClick(item)}
                  />
                </>
              )}
            </Accordion.Toggle>
          </Card>
        </ProtectedComponent>) : null
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px",
          }}
          key={index}
        >
          <Spinner animation="grow" variant="secondary" size="sm" />
        </div>
      );
    }
  };

  return (
    <>
      {treeMenu.map((itemMenu, index) => buildMenu(index, itemMenu, false))}
      {/* OLD way of using BackOffice
        backOfficeMenuItem &&
        <BackofficeMenuItem item={backOfficeMenuItem} />
      */}
    </>
  );
};

export default withNamespaces()(ApplicationMenuItem);
