import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Alert } from "react-bootstrap";
import { updateUserSettingsStamp } from "~/store/ducks/processesSignature/actionTypes";
import { createBase64StringFromBinary } from "~/utils";

const StampComponent = (props) => {
  const { t, setStampImage, username } = props;

  const { userSettingsStamp } = useSelector(
    (state) => state.processesSignatureReducer
  );

  const dispatch = useDispatch();
  const maxFileSize = 100000; //bytes
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [urlPicture, setUrlPicture] = useState(null);

  useEffect(() => {
    if (userSettingsStamp && userSettingsStamp.stamp) {
      const createdPicture = createBase64StringFromBinary(
        userSettingsStamp.stamp
      );
      if (createdPicture) {
        setUrlPicture(createdPicture);
      }
      setStampImage(createdPicture);
    }
  }, [userSettingsStamp]);

  const handleFile = (file) => {
    if (file.size < maxFileSize) {
      dispatch(updateUserSettingsStamp(username, file));
      if (file && file["type"].split("/")[0] === "image") {
        setStampImage(file);
        setUrlPicture(URL.createObjectURL(file));
      }
    } else {
      setWarningMessage(file.name + ": " + t("general.largeFile"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  return (
    <>
      {showWarning && (
        <>
          <Alert
            variant="warning"
            onClose={() => setShowWarning(false)}
            dismissible
          >
            <strong>Alert: </strong>
            {warningMessage}
          </Alert>
          <Form.Text className="text-muted">
            {t("general.maxFileSize") +
              " " +
              Math.round(maxFileSize / 1024) +
              "kb"}
          </Form.Text>
          <br />
        </>
      )}
      {userSettingsStamp && userSettingsStamp.stamp ? (
        <div className="stamp-upload">
          <>
            <div className="stamp-edit">
              <input
                type="file"
                id="imageUpload"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => handleFile(e.target.files[0])}
              />
              <label htmlFor="imageUpload">
                <i className="icon-add add-picture" />
              </label>
            </div>
            <Form.Label style={{ marginLeft: "10px" }}>
              {t("signature.stampUpload")}
            </Form.Label>
          </>

          {/* TODO implement later userSettingsStamp && userSettingsStamp.stamp && (
        <div className="avatar-delete">
          <i
            className="icon-trash add-picture"
            onClick={() => {
              //setShowDeleteModal(true);
            }}
          />
        </div>
          )*/}

          <div className="stamp-preview-fit-inside">
            <div
              style={{
                backgroundImage: "url(" + urlPicture + ")",
              }}
            />
          </div>
        </div>
      ) : (
        <Col sm="12" md="12" lg="12" style={{ marginBottom: "10px" }}>
          <Form.Label>{t("signature.stampUpload")}</Form.Label>
          <Form.Row>
            <Form.Control
              type="file"
              multiple={false}
              name="stampImage"
              onChange={(e) => {
                handleFile(e.target.files[0]);
              }}
            />
          </Form.Row>
        </Col>
      )}
    </>
  );
};
export default withNamespaces()(StampComponent);
