import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const CreateConfirmationModal = (props) => {
  const { t, handleCancelSubmit, handleConfirm, language, translations } =
    props;
  const [showModal, setShowModal] = useState(false);

  const { confirmMessage } = useSelector((state) => state.iflowReducer);

  useEffect(() => {
    if (confirmMessage) setShowModal(true);
  }, [confirmMessage]);

  const handleCancelSubmit_ = () => {
    handleCancelSubmit();
    setShowModal(false);
  };

  const handleConfirm_ = () => {
    handleConfirm();
    setShowModal(false);
  };
  const createMessage = (confirmMessage) => {
    let text = undefined;
    if (confirmMessage.includes("::t::")) {
      let lingua = language.trim();
      let splittext = confirmMessage.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if (translations) {
        text = translations?.[lingua][texttoshow] + texttoadd;
      }
    } else {
      text = confirmMessage;
    }
    return text;
  };

  return confirmMessage ? (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>{createMessage(confirmMessage)}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleCancelSubmit_()}>
          {t("general.no")}
        </Button>
        <Button
          className="modal-submit-button"
          onClick={() => handleConfirm_()}
        >
          {t("general.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default withNamespaces()(CreateConfirmationModal);
