import React, { useState, useEffect } from 'react';
import PowerBIComponent from '../PowerBIComponent';
import Iframe from 'react-iframe';

const SourceHandler = (props) => {

  const {
    widget,
    setErrorMessage,
    height
  } = props;

  const [isAuthenticationFrame, setIsAuthenticationFrame] = useState(false);
  const [url, setUrl] = useState(undefined);

  useEffect(() => {
    if (widget && widget.userConfiguration && widget.userConfiguration.configurationId) {
      const configId = widget.userConfiguration.configurationId;
      const source = widget.widget.configurations.find(item => item.id === configId);
      if (source?.url) {
        setUrl(source.url)
      }
      if (source?.authentication)
        setIsAuthenticationFrame(true)
      else
        setIsAuthenticationFrame(false)
    }
  }, [widget, widget.userConfiguration])

  return (
    isAuthenticationFrame ?
      <PowerBIComponent
        widgetId={widget.id}
        widget={widget}
        setErrorMessage={setErrorMessage}
      /> :
      url ? <Iframe allow="fullscreen"
        url={url}
        width="100%"
        height={`${height}px`}
        id="myId"
        className="powerBI-iframe"
        display="block"
        position="relative"
      /> :
        <div>Sem fonte de dados</div>
  )
}
export default SourceHandler
