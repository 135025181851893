import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_toggleAccordion.scss";
import Toggle from "./Toggle";

const ToggleCertidao = ({ children, t }) => {
  const [toggleActive, setToggleActive] = useState(false);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
          }}
        >
          <div className="easypay-header-7">
            {t("bpm.onboarding.user.toggleCprc")}
          </div>
          <Toggle
            id="test-lsbrl"
            onClick={() => {
              setToggleActive(!toggleActive);
            }}
          />
        </div>
        <div>{toggleActive ? children[0][1] : children[0][0]}</div>
      </div>
    </>
  );
};

export default withNamespaces()(ToggleCertidao);
