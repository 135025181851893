import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_IFLOW_DOCUMENT_LINK_COMPONENT,
  FIND_USERSETTINGS_STAMP,
  UPDATE_USERSETTINGS_STAMP,
  GET_SIGNATURE_COORDINATES,
  GET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT,
} from "../ducks/processesSignature/actionTypes";

import {
  getIflowDocumentLinkComponent,
  findUserSettingsStamp,
  updateUserSettingsStamp,
  getSignatureCoordinates,
  getIflowDocumentTableFileComponent,
} from "../ducks/processesSignature/sagas";

export const processesSignatureSagas = [
  takeEvery(GET_IFLOW_DOCUMENT_LINK_COMPONENT, getIflowDocumentLinkComponent),
  takeLatest(FIND_USERSETTINGS_STAMP, findUserSettingsStamp),
  takeLatest(UPDATE_USERSETTINGS_STAMP, updateUserSettingsStamp),
  takeEvery(GET_SIGNATURE_COORDINATES, getSignatureCoordinates),
  takeEvery(
    GET_IFLOW_DOCUMENT_TABLE_FILE_COMPONENT,
    getIflowDocumentTableFileComponent
  ),
];
