import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { AiFillLock } from "react-icons/ai";

const CreateTableSignCheck = (props) => {
  const { t, field, signRef } = props;
  const [signedIds, setSignedIds] = useState([]);

  //console.log("signedIds", signedIds, "signRef", signRef);

  //set initial states
  useEffect(() => {
    if (field) {
      if (field.file) {
        if (field.file.asSignatures === "true") {
          setSignedIds((signedIds) => [...signedIds, { docid: field.file.id }]);
        }
        if (Array.isArray(field.file)) {
          let arr = [];
          field.file.forEach((i) => {
            if (i.asSignatures === "true") {
              arr.push({ docid: i.id });
            }
          });
          setSignedIds(arr);
        }
      }
      /*TODO get signed from Ref
      if (signRef?.current !== undefined && signRef.current.length > 0) {
        setSignedIds((signedIds) => [...signedIds, ...signRef.current]);
      }*/
    }
  }, [field]);

  const findDocId = () => {
    const id = field?.file?.id;
    if (id && id.includes("hdoc=")) {
      const arr = id.split("hdoc=");
      return arr[1];
    } else {
      return id;
    }
  };

  const fileId = findDocId();

  const renderFileList = () => {
    let array = field.file;
    if (!Array.isArray(field.file)) {
      array = [field.file];
    }
    const renderItems = () => {
      return array.map((f, index) => {
        const extension = f?.name?.split(".").pop().toLocaleLowerCase();
        return (
          <div
            style={{
              display: "flex",
              justifyContent: array.length > 1 ? "space-between" : "start",
              flexWrap: "nowrap",
            }}
            key={index}
          >
            {f?.tosign === "true" && extension === "pdf" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  marginLeft: "0",
                }}
              >
                {signRef.current.some((i) => i.docid === fileId) ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip`}>{t("fileInput.signed")}</Tooltip>
                    }
                  >
                    <AiFillLock
                      style={{
                        height: "20px",
                        width: "20px",
                        margin: "5px 10px",
                        cursor: "pointer",
                        color: "#24579e",
                      }}
                    />
                  </OverlayTrigger>
                ) : signedIds.some((i) => i.docid === f.id) ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip`}>{t("fileInput.signed")}</Tooltip>
                    }
                  >
                    <i
                      className="icon-check"
                      style={{
                        margin: "5px 10px",
                        color: "#28a745",
                      }}
                    ></i>
                  </OverlayTrigger>
                ) : null}
              </div>
            )}
          </div>
        );
      });
    };

    return (
      <div
        style={{
          width: array.length > 1 ? "500px" : "auto",
          padding: "1px 0px",
        }}
      >
        {renderItems()}
      </div>
    );
  };

  return !field?.file ? (
    <div></div>
  ) : (
    <>
      <Form.Group
        controlId={`formFile-${field.variable}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          flexGrow: "1",
        }}
      >
        <Col
          style={{
            padding: "0px",
            flexGrow: "2",
          }}
        >
          {renderFileList()}
        </Col>
      </Form.Group>
    </>
  );
};
export default withNamespaces()(CreateTableSignCheck);
