import qs from "qs";
import http from "~/utils/config/http";
import { put } from "redux-saga/effects";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import { activeError } from "~/components/Messages/actions";
import { setUpdateAnnotation, setCommentHistory } from "./actionTypes";

export function* updateAnnotation({ annotation, username }) {
  yield put(setUpdateAnnotation(false));
  try {
    yield http.post(
      `/annotation/update?username=${username}`,
      annotation,
      BPM_MIDDLEWARE
    );
    yield put(setUpdateAnnotation(true));
  } catch (e) {
    yield put(setUpdateAnnotation(false));
    yield put(activeError("general.error"));
  }
}

export function* findCommentHistory({ data }) {
  try {
    const resp = yield http.get(
      `annotation/comment-history/find?${qs.stringify(data)}`,
      BPM_MIDDLEWARE
    );
    yield put(setCommentHistory(Array.isArray(resp.data) ? resp.data : []));
  } catch (e) {
    yield put(setCommentHistory([]));
  }
}
