import React, { useState, useEffect, useRef, useMemo } from "react";
import "../assets/scss/_accordion.scss";
import "../assets/scss/_signaturesAccordion.scss";
import arrowUpBlue from "../assets/img/icons/arrow-up-blue.png";
import arrowDownGrey from "../assets/img/icons/arrow-down-grey.png";
import greenCheckmark from "../assets/img/icons/green-checkmark.png";
import waitingIcon from "../assets/img/icons/icon-waiting.png";
import { withNamespaces } from "react-i18next";
import Text from "./Text";
import uuid from "uuid/v1";
import Link from "./Link";
import Bullet from "~/pages/Easypay/components/Bullet";
import RadioButtonItem from "~/pages/Easypay/components/RadioButtonItem";
import BenefModalRadioButtonWrapper from "~/pages/Easypay/components/BenefModalRadioButtonWrapper";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import RadioButtonWrapper from "~/pages/Easypay/components/RadioButtonWrapper";
import CustomButton from "~/pages/Easypay/components/Button";
import LineBreak from "~/pages/Easypay/components/LineBreak";
import { Badge } from "react-bootstrap";
import ButtonUpload from "~/pages/Easypay/components/ButtonUpload";
import BulletWithLinks from "./BulletWithLinks";
import { useDispatch, useSelector } from "react-redux";
import { transformFormData } from "~/components/CreateForm/utils";
import oauth from "~/utils/oauth";
import { postEasypayFormData } from "~/store/ducks/easypay/actionTypes";
//import {fileMetadata} from 'file-metadata';
import {
  getIflowFormdata,
  setIflowTriggerLoading,
} from "~/store/ducks/processes/actionTypes";
import { findDocument, setDocument } from "store/ducks/iflow/actionTypes";
import {
  setCurrentSignature,
  setDocumentPdaInfo,
} from "~/store/ducks/onboarding/actionTypes";

import {
  getDocumentByPid,
  getAllUsers,
  getTinkId,
} from "~/store/ducks/onboarding/actionTypes";

const SignaturesAaccordion = ({
  variant,
  list,
  typeAndStateList,
  t,
  canDownloadedFile,
  setCanDownloadedFile,
}) => {
  const [currentSignatureType, setCurrentSignatureType] = useState(
    t(`bpm.onboarding.backoffice.user.labelCMD`)
  );

  const dispatch = useDispatch();

  console.log(list);

  const { iflowTriggerLoading, formdata } = useSelector(
    (state) => state.processesReducer
  );
  const { currentSignature } = useSelector((state) => state.onboardingReducer);

  const { currentDocument } = useSelector((state) => state.iflowReducer);
  const { documentPdaInfo } = useSelector((state) => state.onboardingReducer);

  const [count, setCount] = useState(0);
  const [currentDocIndex, setCurrentDocIndex] = useState("123456789");
  //const [canDownloadedFile, setCanDownloadedFile] = useState(true);
  const [currentMetadata, setCurrentMetadata] = useState("");
  const [currentEmittedDate, setCurrentEmittedDate] = useState("");
  const [currentExpiredDate, setCurrentExpiredDate] = useState("");
  const [currentExtension, setCurrentExtension] = useState("");
  const [currentMimeType, setCurrentMimeType] = useState("");
  const [currentIsPrivate, setCurrentIsPrivate] = useState(true);
  const [currentTopology, setCurrentTopology] = useState(true);

  useEffect(() => {
    if (document?.dados["replegindex"]) {
      if (parseInt(document?.dados["replegindex"].value) >= 0) {
        if (currentSignature === null && currentSignature === undefined) {
          setTabSelected(parseInt(document?.dados["replegindex"].value));
        }
        //dispatch(setCurrentSignature(parseInt(document?.dados["replegindex"].value)))
      }
    }

    const currentFormData = transformFormData(
      new FormData(document.getElementsByName("dados")[0])
    );

    if (count != 0) {
      dispatch(
        getDocumentByPid({
          pid: currentFormData.get("pid"),
        })
      );
      dispatch(setIflowTriggerLoading(false));
      setCount(0);
    }
    setCount(1);
  }, [formdata]);

  useEffect(() => {
    if (documentPdaInfo?.pdaLink)
      dispatch(findDocument(documentPdaInfo.pdaLink));
  }, []);

  useEffect(() => {
    if (
      currentDocument &&
      currentDocument.filename &&
      canDownloadedFile &&
      currentSignature >= 0
    ) {
      if (list[currentSignature].content[3].value.split("::")[1] != "0") return;

      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${currentDocument.datadoc}`;
      a.download = currentDocument.filename;
      a.click();
      setCanDownloadedFile(false);
      dispatch(setDocumentPdaInfo({}));
      dispatch(setDocument({}));
    }
  }, [currentDocument]);
  const [test, setTest] = useState(-1);
  const [tabSelected, setTabSelected] = useState(
    currentSignature != null && currentSignature != undefined
      ? currentSignature
      : -1
  );
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  var currentReplegsToSign = [];

  var badge = "@";
  var badgeRepleg = "@";
  const inputFile = useRef(null);
  var isPersonal = false;
  var currentInfo = [];
  var typeArray = [];
  var stateArray = [];

  var deleteText = "";

  function onButtonClick(index) {
    // `current` points to the mounted file input element
    //currentIndex = index;
    inputFile.current.click();
  }

  function getBase64(file, index) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setFile(base64String);
    };
    reader.readAsDataURL(file);
  }

  const field = {
    type: "link",
    text: "Adicionar::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='changeSignatureMethod';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  function handleChangeMethod() {
    if (field.href.includes("document.dados.op.value='")) {
      const array = field.href.split("document.dados.op.value='");
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados.op.value = array2[0];
        }
      }
    }
    if (field.href.includes("document.dados.toggleCodigoCertidao.value='")) {
      const array = field.href.split(
        "document.dados.toggleCodigoCertidao.value='"
      );
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados.toggleCodigoCertidao.value = array2[0];
        }
      }
    }
    if (field.href.includes("document.dados.submit()")) {
      const formdata = transformFormData(
        new FormData(document.getElementsByName("dados")[0])
      );
      const url =
        "/Form/form.jsp?" +
        "flowid=" +
        formdata.get("flowid") +
        "&pid=" +
        formdata.get("pid") +
        "&subpid=" +
        formdata.get("subpid") +
        "&Authorization=" +
        oauth.getAccessToken();
      dispatch(postEasypayFormData(formdata, url));
    }
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];
    setFileName(currentFile.name);
    setFile(currentFile);

    //console.log(await fileMetadata(currentFile));

    const topology = "identification_card";
    // Not supported in Firefox for Android or Opera for Android.
    const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";
    // Unknown cross-browser support.
    const size = currentFile.size ? currentFile.size : "NOT SUPPORTED";

    const emitted_at = currentFile.lastModifiedDate;
    const expires_at = new Date(
      new Date(currentFile.lastModifiedDate).setFullYear(
        new Date().getFullYear() + 1
      )
    );
    const is_private = true;
    const extension = "." + currentFile.name.split(".")[1];
    var newExpires = expires_at.toISOString().split(".")[0] + "Z";
    var newEmitted = emitted_at.toISOString().split(".")[0] + "Z";
    const newMetadata = {
      topology: topology,
      is_private: true,
      metadata: {},
      emitted_at: newEmitted,
      expires_at: newExpires,
      mime_type: type,
      extension: extension,
    };

    var myJSONString = JSON.stringify(newMetadata);
    var myEscapedJSONString = myJSONString
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");

    setCurrentMetadata(myEscapedJSONString);
    setCurrentMimeType(type);
    setCurrentIsPrivate(is_private);
    setCurrentTopology(topology);
    setCurrentExpiredDate(newExpires);
    setCurrentEmittedDate(newEmitted);
    setCurrentExtension(extension);

    getBase64(currentFile);
  };

  if (variant === "accordion-table") {
    deleteText = "'deleteRepLeg'";
  } else {
    deleteText = "'deleteBenef'";
  }

  function downloadPda(index) {
    const filename = list[index].content[4].value;
    const fileContent = list[index].content[5].value;

    const a = document.createElement("a");
    a.href = `data:${"pdf"};base64,${fileContent}`;
    a.download = filename;
    a.click();
  }

  const linkPda = (
    <div className="easypay-sig-link" onClick={() => downloadFile(0)}>
      {t(`bpm.onboarding.backoffice.user.labelPropostaAdesao`)}
    </div>
  );

  const linkPa = (
    <div className="easypay-sig-link" onClick={() => downloadFile(1)}>
      {t(`bpm.onboarding.backoffice.user.labelPropostaAlteracao`)}
    </div>
  );

  const linkAutGov = (
    <Link
      text={" autenticacao.gov.pt"}
      link={"https://www.autenticacao.gov.pt"}
    />
  );
  const smsBulletList = [
    <div>
      {t(`bpm.onboarding.backoffice.user.labelRepGetsSMS`)}
      {linkPda}
    </div>,
    <div>{t(`bpm.onboarding.backoffice.user.labelAnswerYes`)}</div>,
  ];

  const amBulletList = [
    <div style={{ display: "flex" }}>
      {t(`bpm.onboarding.backoffice.user.labelDownloadThe`)}
      {linkPda}
    </div>,
    <div>{t(`bpm.onboarding.backoffice.user.labelAfterAllSignatures`)}</div>,
  ];

  const amBulletListPa = [
    <div style={{ display: "flex" }}>
      {t(`bpm.onboarding.backoffice.user.labelDownloadThe`)}
      {linkPa}
    </div>,
    <div>{t(`bpm.onboarding.backoffice.user.labelAfterAllSignatures`)}</div>,
  ];

  const cmdBulletList2 = [
    <div>{t(`bpm.onboarding.backoffice.user.labelGetSignaturesSubmit`)}</div>,
  ];

  const cmdBulletList2Pa = [
    <div>
      {t(`bpm.onboarding.backoffice.user.labelGetSignaturesSubmitAlteration`)}
    </div>,
  ];

  const cmdBulletList1 = [
    <div style={{ display: "flex" }}>
      {t(`bpm.onboarding.backoffice.user.labelDownloadThe`)}
      {linkPda}
    </div>,
    <div>
      {t(`bpm.onboarding.backoffice.user.labelGetTo`)}
      {linkAutGov} {t(`bpm.onboarding.backoffice.user.labeFollowInstructions`)}
    </div>,
  ];

  const cmdBulletList1Pa = [
    <div style={{ display: "flex" }}>
      {t(`bpm.onboarding.backoffice.user.labelDownloadThe`)}
      {linkPa}
    </div>,
    <div>
      {t(`bpm.onboarding.backoffice.user.labelGetTo`)}
      {linkAutGov} {t(`bpm.onboarding.backoffice.user.labeFollowInstructions`)}
    </div>,
  ];
  useEffect(() => {
    if (document.dados["currentDocIndex"] && currentDocIndex != null) {
      document.dados["currentDocIndex"].value = "4444";
    }
    if (document.dados["signaturesTypeAndStateEdit"]) {
      document.dados["signaturesTypeAndStateEdit"].value =
        getSignatureType() + "::" + nextChar(stateArray[currentSignature]);
    }
    if (document.dados["signaturesFileNameEdit"]) {
      document.dados["signaturesFileNameEdit"].value = fileName;
    }
    if (document.dados["signaturesFileContentEdit"]) {
      document.dados["signaturesFileContentEdit"].value = file;
    }
    if (document.dados["pdaTopology"] && document.dados.replegindex.value) {
      document.dados["pdaTopology"].value = currentTopology;
    }
    if (document.dados["pdaMetadata"] && document.dados.replegindex.value) {
      document.dados["pdaMetadata"].value = currentMetadata;
    }
    if (document.dados["pdaEmitted"] && document.dados.replegindex.value) {
      document.dados["pdaEmitted"].value = currentEmittedDate;
    }
    if (document.dados["pdaExpires"] && document.dados.replegindex.value) {
      document.dados["pdaExpires"].value = currentExpiredDate;
    }
    if (document.dados["pdaMime"] && document.dados.replegindex.value) {
      document.dados["pdaMime"].value = currentMimeType;
    }
    if (document.dados["pdaExtension"] && document.dados.replegindex.value) {
      document.dados["pdaExtension"].value = currentExtension;
    }

    if (document.dados["replegindex"]) {
      document.dados["replegindex"].value = currentSignature;
    }
  });

  useEffect(() => {}, [test]);

  function nextChar(c) {
    if (c === null || c === "" || c === undefined) return;
    return (badge = String.fromCharCode(c.charCodeAt(0) + 1));
  }

  function nextCharRepleg(c) {
    return (badgeRepleg = String.fromCharCode(c.charCodeAt(0) + 1));
  }
  function getSignatureType() {
    if (currentSignatureType === t(`bpm.onboarding.backoffice.user.labelSMS`)) return "SMS";
    else if (currentSignatureType === t(`bpm.onboarding.backoffice.user.labelCMD`)) return "CMD";
    else if (currentSignatureType === t(`bpm.onboarding.backoffice.user.labelManualSignature`)) return "AM";
  }

  function handleHeaderOnClick(index) {
    if (typeArray[index] === "0" || typeArray[index] === "SMS")
      setCurrentSignatureType(t(`bpm.onboarding.backoffice.user.labelCMD`));
    else if (typeArray[index] === "CMD")
      setCurrentSignatureType(t(`bpm.onboarding.backoffice.user.labelCMD`));
    else if (
      typeArray[index] === "AM" ||
      list[index].content[3].value.split("::")[0] === "AM"
    )
      setCurrentSignatureType(t(`bpm.onboarding.backoffice.user.labelManualSignature`));

    if (tabSelected === index) {
      setTabSelected(-1);
      dispatch(setCurrentSignature(-1));
    } else if (tabSelected != index) {
      // document.dados["currentDocIndex"].value = list[index].content[6].value
      setTabSelected(index);
      dispatch(setCurrentSignature(index));
      if (document.dados.replegindex != null) {
        document.dados.replegindex.value = index;
      }
    }
  }

  function handleRemoveFile() {
    setFileName("");
  }

  function downloadFile(index) {
    dispatch(setDocumentPdaInfo({}));
    setCanDownloadedFile(true);
    const formdata = transformFormData(
      new FormData(document.getElementsByName("dados")[0])
    );
    var fileType;

    if (tabSelected === 0) {
      fileType = 1;
    } else {
      fileType = 0;
    }

    const payload = {
      url:
        "/inicio_flow.jsp?" +
        "flowid=" +
        "9" +
        "&attr_pidConta=" +
        formdata.get("pid") +
        "&attr_idioma=" +
        "pt" +
        "&attr_isPDA=" +
        fileType +
        "&attr_Authorization=" +
        oauth.getAccessToken() +
        "&Authorization=" +
        oauth.getAccessToken(),
    };
    dispatch(getIflowFormdata(payload));
  }

  function isSubmitButtonDisabled() {
    var currentState;
    var currentType;
    if (tabSelected >= 0) {
      currentState = typeAndStateList[tabSelected].split("::")[1];
      currentType = typeAndStateList[tabSelected].split("::")[0];
    }
    if (tabSelected < 0) return true;
    if (currentType === "SMS" && currentState === "1") {
      return true;
    }
    if (currentType === "CMD" && currentState === "1") {
      if (fileName === "") return true;
    }
    if (currentType === "CMD" && currentState === "2") {
      return true;
    }
    if (currentType === "AM" && currentState === "1") {
      return true;
    }
    return false;
  }

  const addButtonField = {
    type: "link",
    text: "Adicionar::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='submitSignature';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const replegSubHeaderText =
    t(`bpm.onboarding.backoffice.user.subHeaderRepLeg`) +
    "::card-text-light-small-padding";

  return (
    <>
      {variant === "accordion-table" && (
        <Text text={replegSubHeaderText}></Text>
      )}
      {list &&
        Object.keys(list).length !== 0 &&
        list.map((item, index) => {
          currentReplegsToSign = item.content[2].value.split("::");
          currentInfo = item.content[3].value.split("::");
          typeArray.push(currentInfo[0]);
          stateArray.push(currentInfo[1]);
          return (
            <>
              {(index === 0 ||
                (index === 1 && list[0].content[3].value === "AM::1") ||
                list[0].content[3].value === "CMD::2") && (
                <div className="easypay-accordion" key={index}>
                  <div
                    className="easypay-accordion-header"
                    onClick={() => {
                      handleHeaderOnClick(index, isPersonal);
                    }}
                  >
                    <div className="easypay-accordion-top-content-container">
                      <div className="easypay-id">{nextChar(badge)}</div>
                      <div className="easypay-title">
                        {t(item.content[1].value)}
                      </div>
                    </div>
                    <div className="easypay-image-container">
                      {false === "true" && (
                        <img
                          className="easypay-accordion-checkmark-img"
                          src={greenCheckmark}
                        />
                      )}
                      <img
                        className={
                          tabSelected === index
                            ? `${"easypay-accordion-btn-img"} active`
                            : "easypay-accordion-btn-img"
                        }
                        src={
                          tabSelected === index ? arrowUpBlue : arrowDownGrey
                        }
                      />
                    </div>
                  </div>
                  {tabSelected === index && (
                    <div style={{ width: "100%" }}>
                      <div className="easypay-accordion-content-card">
                        <Text
                          text={
                            t(
                              `bpm.onboarding.backoffice.user.labelSignaturesMethod`
                            ) + "::card-text-blue-small-padding"
                          }
                        />
                        <div className="easypay-accordion-radio-button-wrapper">
                          <BenefModalRadioButtonWrapper
                            key={uuid()}
                            activeTab={
                              stateArray[index] === "0"
                                ? currentSignatureType
                                : typeArray[index] === "AM"
                                ? t(`bpm.onboarding.backoffice.user.labelManualSignature`)
                                : t(`bpm.onboarding.backoffice.user.labelCMD`)
                            }
                            setActivetab={setCurrentSignatureType}
                          >
                            <RadioButtonItem
                              key={uuid()}
                              text={t(`bpm.onboarding.backoffice.user.labelSMS`)}
                              uniqueName={"signatureType" + index}
                              index={0}
                              name={t(`bpm.onboarding.backoffice.user.labelSMS`)}
                              subHeader={""}
                              radioButtonLength={3}
                              disabled={true}
                            >
                              <div style={{ width: "100%" }}>
                                {stateArray[index] === "0" ? (
                                  <div>
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelTwoStep`
                                        ) + "::card-text-light-small-padding"
                                      }
                                    />
                                    <BulletWithLinks list={smsBulletList} />
                                  </div>
                                ) : (
                                  <div className="easypay-signatures-repleg-wraper">
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelSignatures`
                                        ) + "::card-text-light-small-padding"
                                      }
                                    />
                                    {currentReplegsToSign &&
                                      Object.keys(currentReplegsToSign)
                                        .length !== 0 &&
                                      currentReplegsToSign.map(
                                        (item, index) => {
                                          return (
                                            <div style={{ width: "100%" }}>
                                              <div
                                                className={
                                                  index ===
                                                  currentReplegsToSign.length -
                                                    1
                                                    ? "easypay-signatures-wrapper"
                                                    : "easypay-signatures-wrapper-no-padding"
                                                }
                                              >
                                                <div className="easypay-signatures-name-wrapper">
                                                  <div className="easypay-bullet-number-with-padding">
                                                    {nextCharRepleg(
                                                      badgeRepleg
                                                    )}
                                                  </div>
                                                  <Text
                                                    text={
                                                      item +
                                                      "::card-text-no-padding"
                                                    }
                                                  />
                                                </div>
                                                <div className="easypay-signatures-name-wrapper">
                                                  <div className="easypay-text-icon-warning">
                                                    <img
                                                      className="easypay-accordion-warning-img"
                                                      src={waitingIcon}
                                                    />
                                                  </div>
                                                  <Text
                                                    text={
                                                      t(
                                                        `bpm.onboarding.backoffice.user.labelPendingSignature`
                                                      ) +
                                                      "::card-text-warning-no-padding"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {index !=
                                                currentReplegsToSign.length -
                                                  1 && (
                                                <LineBreak text={"none"} />
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                )}
                              </div>
                            </RadioButtonItem>
                            <RadioButtonItem
                              key={uuid()}
                              text={t(`bpm.onboarding.backoffice.user.labelCMD`)}
                              uniqueName={"signatureType" + index}
                              index={1}
                              name={t(`bpm.onboarding.backoffice.user.labelCMD`)}
                              radioButtonLength={3}
                              subHeader={""}
                              disabled={stateArray[index] != "0"}
                            >
                              <div style={{ width: "100%" }}>
                                {stateArray[index] === "0" ? (
                                  <div>
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelThreeStep`
                                        ) + "::card-text-light-small-padding"
                                      }
                                    />
                                    {index === 0 ? (
                                      <BulletWithLinks list={cmdBulletList1} />
                                    ) : (
                                      <BulletWithLinks
                                        list={cmdBulletList1Pa}
                                      />
                                    )}
                                    <div className="easypay-signature-bullet-item">
                                      <Text
                                        text={
                                          t(
                                            `bpm.onboarding.backoffice.user.labelMultipleSignaturesText`
                                          ) +
                                          "::card-text-light-no-padding-italic"
                                        }
                                      />
                                    </div>
                                    {index === 0 ? (
                                      <BulletWithLinks
                                        list={cmdBulletList2}
                                        startNumber={3}
                                      />
                                    ) : (
                                      <BulletWithLinks
                                        list={cmdBulletList2Pa}
                                      />
                                    )}
                                  </div>
                                ) : stateArray[index] === "1" ? (
                                  <div className="easypay-signatures-wrapper">
                                    <div className="easypay-pda-text-wrapper">
                                      {index === 0 ? (
                                        <Text
                                          text={
                                            t(
                                              `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                            ) + "::card-text-blue"
                                          }
                                        />
                                      ) : (
                                        <Text
                                          text={
                                            t(
                                              `bpm.onboarding.backoffice.user.labelPropostaAlteracao`
                                            ) + "::card-text-blue"
                                          }
                                        />
                                      )}
                                      <Text
                                        text={
                                          t(
                                            `bpm.onboarding.backoffice.user.labelInsertDoc`
                                          ) + "::card-text-light-no-padding"
                                        }
                                      />
                                    </div>
                                    {fileName === "" ? (
                                      <ButtonUpload
                                        text={t(
                                          `bpm.onboarding.backoffice.user.buttonUploadDocument`
                                        )}
                                        variant={
                                          "easypay-button-secondary-icon"
                                        }
                                        onclick={() => onButtonClick(index)}
                                        onChange={handleChange}
                                        key={uuid()}
                                        inputFile={inputFile}
                                        id={"id1"}
                                        className="card-button"
                                        align={"right"}
                                        disabledColored={false}
                                        disabled={false}
                                      />
                                    ) : (
                                      <div className="easypay-signature-document-submitted-wrapper">
                                        <div className="easypay-text-icon">
                                          <img
                                            src={iconDocument}
                                            alt="Icon Check"
                                          />
                                        </div>
                                        <Text
                                          text={fileName + "::card-text-blue"}
                                        />
                                        <div
                                          className="easypay-x-icon"
                                          onClick={() => handleRemoveFile()}
                                        >
                                          X
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="easypay-signatures-wrapper">
                                    <div className="easypay-pda-text-wrapper">
                                      {index === 0 ? (
                                        <Text
                                          text={
                                            t(
                                              `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                            ) + "::card-text-blue"
                                          }
                                        />
                                      ) : (
                                        <Text
                                          text={
                                            t(
                                              `bpm.onboarding.backoffice.user.labelPropostaAlteracao`
                                            ) + "::card-text-blue"
                                          }
                                        />
                                      )}
                                      <Text
                                        text={
                                          t(
                                            `bpm.onboarding.backoffice.user.labelInsertDoc`
                                          ) + "::card-text-light-no-padding"
                                        }
                                      />
                                    </div>
                                    <div className="easypay-signature-document-submitted-wrapper">
                                      <div className="easypay-text-icon">
                                        <img
                                          src={iconDocument}
                                          alt="Icon Check"
                                        />
                                      </div>
                                      <div onClick={() => downloadPda(index)}>
                                        <Text
                                          text={
                                            item.content[4].value +
                                            "::card-text-blue"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </RadioButtonItem>
                            <RadioButtonItem
                              key={uuid()}
                              text={t(`bpm.onboarding.backoffice.user.labelManualSignature`)}
                              index={2}
                              uniqueName={"signatureType" + index}
                              name={t(`bpm.onboarding.backoffice.user.labelManualSignature`)}
                              subHeader={""}
                              disabled={stateArray[index] != "0"}
                              radioButtonLength={3}
                            >
                              {item.content[4].value === "" ? (
                                <div>
                                  <Text
                                    text={
                                      t(
                                        `bpm.onboarding.backoffice.user.labelTwoStep`
                                      ) + "::card-text-light-small-padding"
                                    }
                                  />
                                  <BulletWithLinks
                                    list={
                                      index === 0
                                        ? amBulletList
                                        : amBulletListPa
                                    }
                                  />
                                  <div className="easypay-signature-bullet-item">
                                    <Text
                                      text={
                                        "Rua Soares de Passos, 14 B 1300-537 Lisboa Portugal::card-text-light-small-padding"
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="easypay-signatures-wrapper">
                                  <div className="easypay-pda-text-wrapper">
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                        ) + "::card-text-blue"
                                      }
                                    />
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelInsertDoc`
                                        ) + "::card-text-light-no-padding"
                                      }
                                    />
                                  </div>
                                  <div className="easypay-signature-document-submitted-wrapper">
                                    <div className="easypay-text-icon">
                                      <img
                                        src={iconDocument}
                                        alt="Icon Check"
                                      />
                                    </div>
                                    <div onClick={() => downloadPda(index)}>
                                      <Text
                                        text={
                                          item.content[4].value +
                                          "::card-text-blue"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </RadioButtonItem>
                          </BenefModalRadioButtonWrapper>
                        </div>
                      </div>
                      <div className="easypay-accordion-bottom-button-wrapper">
                        {false && stateArray[index] != "0" && (
                          <div
                            className="easypay-accordion-add-link-wrapper"
                            style={{ paddingLeft: "20px" }}
                            onClick={(event) => {
                              event.preventDefault();
                              handleChangeMethod();
                            }}
                          >
                            <Link
                              text={t(
                                `bpm.onboarding.backoffice.user.labelChangeMethod`
                              )}
                              disabledColored={false}
                              variant={"easypay-edit"}
                              disabled={false}
                              link=""
                              key={uuid()}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      <CustomButton
        text={t(`bpm.onboarding.backoffice.user.buttonSubmit`)}
        variant={"easypay-button-primary-large"}
        onclick={addButtonField.onclick}
        key={uuid()}
        id={addButtonField.id}
        className="card-button"
        align={"center"}
        disabledColored={false}
        disabled={isSubmitButtonDisabled()}
        field={addButtonField}
      />
    </>
  );
};

export default withNamespaces()(SignaturesAaccordion);
