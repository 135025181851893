import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import arrowUpBlue from "../assets/img/icons/arrow-up-blue.png";
import arrowDownGrey from "../assets/img/icons/arrow-down-grey.png";
import greenCheckmark from "../assets/img/icons/green-checkmark.png";
import RadioButtonItem from "~/pages/Easypay/components/RadioButtonItem";
import BenefModalRadioButtonWrapper from "~/pages/Easypay/components/BenefModalRadioButtonWrapper";
import waitingIcon from "../assets/img/icons/icon-waiting.png";
import ButtonUpload from "~/pages/Easypay/components/ButtonUpload";
import Text from "./Text";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import "../assets/css/styles.css";
import LineBreak from "~/pages/Easypay/components/LineBreak";
import uuid from "uuid/v1";
import "../assets/scss/_backofficeRegistrySection.scss";
import {
  getUser,
  updateUser,
  getDocumentCoresystem,
  setDocumentCoresystem,
  createDocumentCoresystem,
  addDocumentCoresystem,
  setCreateDocumentCoresystem,
} from "~/store/ducks/onboarding/actionTypes";

const BackOfficeSignaturesSection = ({ data, pid, t }) => {
  const inputFile = useRef(null);
  var badge = "@";
  var badgeRepleg = "@";

  const testIndex = useRef(null);
  const dispatch = useDispatch();

  const [currentSignatureType, setCurrentSignatureType] = useState(
    t(`bpm.onboarding.backoffice.user.labelCMD`)
  );

  const { user } = useSelector((state) => state.onboardingReducer);

  const { documentCoresystem, createDocumentCoresystemId } = useSelector(
    (state) => state.onboardingReducer
  );

  function repeat() {
    var arr = [];
    for (var i = 0; i < signaturesList.length; i++) {
      arr.push("");
    }
    return arr;
  }
  var signaturesList = [
    {
      name: t("bpm.onboarding.backoffice.user.labelPropostaAdesao"),
      repLegArray: ["Carlos Manuel", "João Silva"],
      document: {},
      mode: "",
    },
    {
      name: t("bpm.onboarding.backoffice.user.labelPropostaAlteracao"),
      repLegArray: ["Francisco Monteiro", "Maria Silva"],
      document: {},
      mode: "",
    },
  ];

  useEffect(() => {
    if (createDocumentCoresystemId != null && file != null) {
 
      const type = file.type ? file.type : "NOT SUPPORTED";

      data.documents[testIndex.current].createdDate = new Date();
      data.documents[testIndex.current].modifiedDate = new Date();
      data.documents[testIndex.current].nomeDocumento = file.name;
      data.documents[testIndex.current].idCoresystem =
        createDocumentCoresystemId.id;
      data.documents[testIndex.current].content = fileContent;
      data.documents[testIndex.current].submited = "1";
      data.documents[testIndex.current].mimeType = file.name;
      data.documents[testIndex.current].emitted = file.name;
      data.documents[testIndex.current].expired = file.name;

      if (
        currentSignatureType === t(`bpm.onboarding.backoffice.user.labelCMD`)
      ) {
        data.documents[testIndex.current].descDocumento = "CMD::2";
      } else {
        data.documents[testIndex.current].descDocumento = "AM::1";
      }
      dispatch(
        addDocumentCoresystem(
          {
            id: createDocumentCoresystemId.id,
            fileName: file.name,
            applicationType: type,
            content: fileContent,
          },
          "61798db3a0ac1d6257017e87"
        )
      );
      dispatch(setCreateDocumentCoresystem(null));
      dispatch(updateUser({ user: data, pid: pid }));
    }
  }, [createDocumentCoresystemId, file]);


  useEffect(() => {
    if (data === undefined || data === null) return;
    var newDocList = data?.documents?.filter((doc) => doc.isPDA === 1);

    if (newDocList === undefined) return;

    if (newDocList.length === 0) {
      setMainSignaturesArray(signaturesList);
      return;
    }

    if (newDocList.length > 2) {
      var newArray = [];
      newArray.push(newDocList[0]);
      newArray.push(newDocList[newDocList.length - 1]);
      newDocList = newArray;
      signaturesList[0].document = newDocList[0];
      signaturesList[0].mode = newDocList[0].descDocumento.split("::")[0];
      signaturesList[1].document = newDocList[1];
      signaturesList[1].mode = newDocList[1].descDocumento.split("::")[0];
    } else if (newDocList.length === 1) {
      signaturesList[0].document = newDocList[0];
      signaturesList[0].mode = newDocList[0].descDocumento.split("::")[0];
    } else {
      signaturesList[0].document = newDocList[0];
      signaturesList[0].mode = newDocList[0].descDocumento.split("::")[0];
      signaturesList[1].document = newDocList[1];
      signaturesList[1].mode = newDocList[1].descDocumento.split("::")[0];
    }
    setMainSignaturesArray(signaturesList);
  }, [user]);

  const emptyArray = repeat();
  const [documentsCMDNameList, setDocumentsCMDNameList] = useState(emptyArray);
  const [fileContent, setFileContent] = useState(null);
  const [documentsAMNameList, setDocumentsAMNameList] = useState(emptyArray);
  const [file, setFile] = useState(null);
  const [mainSignaturesArray, setMainSignaturesArray] = useState(emptyArray);

  function onButtonClick(id) {
    // `current` points to the mounted file input element
    var currentInd = -1;
    for (var i = 0; i < data?.documents?.length; i++) {
      if (data?.documents[i]?.id === id) {
        currentInd = i;
        break;
      }
    }

    testIndex.current = currentInd;
    inputFile.current.click();
  }

  function getBase64(file, index) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setFileContent(base64String);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var currentFile;
    var newArray;
    currentFile = event.target.files[0];

    setFile(event.target.files[0]);
    if (
      currentSignatureType ===
      t(`bpm.onboarding.backoffice.user.labelManualSignature`)
    ) {
      newArray = [...documentsAMNameList];
      newArray[tabSelected] = currentFile.name;
      setDocumentsAMNameList(newArray);
    } else {
      newArray = [...documentsCMDNameList];
      newArray[tabSelected] = currentFile.name;
      setDocumentsCMDNameList(newArray);
    }

    const emitted_at = currentFile.lastModifiedDate;
    const expires_at = new Date(
      new Date(currentFile.lastModifiedDate).setFullYear(
        new Date().getFullYear() + 1
      )
    );

    const extension = "." + currentFile.name.split(".")[1];
    var newExpires = expires_at.toISOString().split(".")[0] + "Z";
    var newEmitted = emitted_at.toISOString().split(".")[0] + "Z";
    const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";
    dispatch(
      createDocumentCoresystem(
        {
          topology: "identification_card",
          is_private: true,
          metadata: `{\n  \"topology\": \"identification_card\",\n  \"is_private\": true,\n  \"metadata\": {},\n  \"emitted_at\": \"${newEmitted}\",\n  \"expires_at\": \"${newExpires}\",\n  \"mime_type\": \"${type}\",\n  \"extension\": \"${extension}\"\n}`,
          emitted_at: newEmitted,
          expires_at: newExpires,
          mime_type: type,
          extension: extension,
        },
        "61798db3a0ac1d6257017e87"
      )
    );

    getBase64(currentFile);
  };

  const [tabSelected, setTabSelected] = useState(-1);

  function nextChar(c) {
    return (badge = String.fromCharCode(c.charCodeAt(0) + 1));
  }

  function handleHeaderOnClick(index) {
    if (tabSelected === index) {
      setTabSelected(-1);
    } else if (tabSelected != index) {
      setTabSelected(index);
      if (mainSignaturesArray[index].mode != "") {
        if (mainSignaturesArray[index].mode === "CMD") {
          setCurrentSignatureType(t(`bpm.onboarding.backoffice.user.labelCMD`));
        } else if (mainSignaturesArray[index].mode === "AM") {
          setCurrentSignatureType(
            t(`bpm.onboarding.backoffice.user.labelManualSignature`)
          );
        }
      } else {
        setCurrentSignatureType(t(`bpm.onboarding.backoffice.user.labelCMD`));
      }
    }
  }
  function nextCharRepleg(c) {
    return (badgeRepleg = String.fromCharCode(c.charCodeAt(0) + 1));
  }

  function isVisible(index) {
    if (index === 1) {
      if (
        mainSignaturesArray[0]?.document?.nomeDocumento != "" &&
        mainSignaturesArray[0]?.document?.nomeDocumento != null &&
        mainSignaturesArray[0]?.document?.nomeDocumento != undefined
      ) {
        return true;
      } else return false;
    }
    return true;
  }

  return (
    <div className="easypay-backoffice-sections-main-wrapper">
      <div className="easypay-backoffice-registry-section-header">
        {t(`bpm.onboarding.backoffice.user.labelSignatures`)}
      </div>
      <>
        {mainSignaturesArray &&
          Object.keys(mainSignaturesArray).length !== 0 &&
          mainSignaturesArray.map((item, index) => {
            return (
              <>
                {isVisible(index) && (
                  <div className="easypay-accordion-backoffice" key={index}>
                    <div
                      className="easypay-accordion-header-backoffice"
                      onClick={() => {
                        handleHeaderOnClick(index);
                      }}
                    >
                      <div className="easypay-accordion-top-content-container">
                        <div className="easypay-id">{nextChar(badge)}</div>
                        <div className="easypay-title">{item.name}</div>
                      </div>
                      <div className="easypay-image-container">
                        {false === "true" && (
                          <img
                            className="easypay-accordion-checkmark-img"
                            src={greenCheckmark}
                          />
                        )}
                        <img
                          className={
                            tabSelected === index
                              ? `${"easypay-accordion-btn-img"} active`
                              : "easypay-accordion-btn-img"
                          }
                          src={
                            tabSelected === index ? arrowUpBlue : arrowDownGrey
                          }
                        />
                      </div>
                    </div>
                    {tabSelected === index && (
                      <div>
                        <div className="easypay-accordion-content-card-backoffice">
                          <Text
                            text={
                              t(
                                `bpm.onboarding.backoffice.user.labelSignaturesMethod`
                              ) + "::card-text"
                            }
                          />
                          <div className="easypay-accordion-radio-button-wrapper">
                            <BenefModalRadioButtonWrapper
                              key={uuid()}
                              activeTab={currentSignatureType}
                              setActivetab={setCurrentSignatureType}
                              variant={"easypay-radio-button-row"}
                            >
                              <RadioButtonItem
                                key={uuid()}
                                text={t(`bpm.onboarding.backoffice.user.labelSMS`)}
                                uniqueName={"signatureType" + index}
                                index={0}
                                name={t(`bpm.onboarding.backoffice.user.labelSMS`)}
                                subHeader={""}
                                radioButtonLength={3}
                                disabled={true}
                              ></RadioButtonItem>
                              <RadioButtonItem
                                key={uuid()}
                                text={t(
                                  `bpm.onboarding.backoffice.user.labelCMD`
                                )}
                                uniqueName={"signatureType" + index}
                                index={1}
                                name={t(
                                  `bpm.onboarding.backoffice.user.labelCMD`
                                )}
                                radioButtonLength={3}
                                subHeader={""}
                                disabled={item.mode != "0" ? true : false}
                              ></RadioButtonItem>
                              <RadioButtonItem
                                key={uuid()}
                                text={t(
                                  `bpm.onboarding.backoffice.user.labelManualSignature`
                                )}
                                index={2}
                                uniqueName={"signatureType" + index}
                                name={t(
                                  `bpm.onboarding.backoffice.user.labelManualSignature`
                                )}
                                subHeader={""}
                                disabled={item.mode != "0" ? true : false}
                                radioButtonLength={3}
                              ></RadioButtonItem>
                            </BenefModalRadioButtonWrapper>
                          </div>
                          <div>
                            {currentSignatureType === t(`bpm.onboarding.backoffice.user.labelSMS`) ? (
                              <div>
                                <div className="easypay-backoffice-text-button-wrapper">
                                  <div className="easypay-backoffice-signatures-text-wrapper">
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                        ) + "::card-text-blue"
                                      }
                                    />
                                    <Text
                                      text={
                                        "Informação sobre o documento::card-text-light"
                                      }
                                    />
                                  </div>
                                  <div className="easypay-document-submitted-wrapper">
                                    <div className="easypay-text-icon">
                                      <img
                                        src={iconDocument}
                                        alt="Icon Check"
                                      />
                                    </div>
                                    <Text
                                      text={"PDA12345678.pdf::card-text-blue"}
                                    />
                                  </div>
                                </div>
                                {item.repLegArray &&
                                  Object.keys(item.repLegArray).length !== 0 &&
                                  item.repLegArray.map((repleg, index) => {
                                    return (
                                      <div>
                                        <div
                                          className={
                                            index ===
                                            item.repLegArray.length - 1
                                              ? "easypay-signatures-wrapper"
                                              : "easypay-signatures-wrapper-no-padding"
                                          }
                                        >
                                          <div className="easypay-signatures-name-wrapper">
                                            <div className="easypay-bullet-number-with-padding">
                                              {nextCharRepleg(badgeRepleg)}
                                            </div>
                                            <Text
                                              text={
                                                repleg +
                                                "::card-text-no-padding"
                                              }
                                            />
                                          </div>
                                          <div className="easypay-signatures-name-wrapper">
                                            <div className="easypay-text-icon-warning">
                                              <img
                                                className="easypay-accordion-warning-img"
                                                src={waitingIcon}
                                              />
                                            </div>
                                            <Text
                                              text={
                                                t(
                                                  `bpm.onboarding.backoffice.user.labelPendingSignature`
                                                ) +
                                                "::card-text-warning-no-padding"
                                              }
                                            />
                                          </div>
                                        </div>
                                        {index !=
                                          item.repLegArray.length - 1 && (
                                          <LineBreak text={"none"} />
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            ) : currentSignatureType ===
                              t(`bpm.onboarding.backoffice.user.labelCMD`) ? (
                              <div>
                                <div className="easypay-backoffice-text-button-wrapper">
                                  <div className="easypay-backoffice-signatures-text-wrapper">
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                        ) + "::card-text-blue"
                                      }
                                    />
                                    <Text
                                      text={
                                        "Informação sobre o documento::card-text-light"
                                      }
                                    />
                                  </div>
                                  {item?.document === {} ||
                                  item.document?.nomeDocumento === undefined ||
                                  item.document?.nomeDocumento === null ||
                                  item.document?.nomeDocumento === "" ? (
                                    <ButtonUpload
                                      text={t(
                                        `bpm.onboarding.backoffice.user.buttonUploadDocument`
                                      )}
                                      variant={"easypay-button-secondary-icon"}
                                      onclick={() =>
                                        onButtonClick(item.document?.id)
                                      }
                                      onChange={handleChange}
                                      key={uuid()}
                                      inputFile={inputFile}
                                      id={"id1"}
                                      className="card-button"
                                      align={"right"}
                                      disabledColored={false}
                                      disabled={false}
                                    />
                                  ) : (
                                    <div className="easypay-document-submitted-wrapper">
                                      <div className="easypay-text-icon">
                                        <img
                                          src={iconDocument}
                                          alt="Icon Check"
                                        />
                                      </div>
                                      <Text
                                        text={
                                          item.document.nomeDocumento +
                                          "::card-text-blue"
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="easypay-backoffice-text-button-wrapper">
                                  <div className="easypay-backoffice-signatures-text-wrapper">
                                    <Text
                                      text={
                                        t(
                                          `bpm.onboarding.backoffice.user.labelPropostaAdesao`
                                        ) + "::card-text-blue"
                                      }
                                    />
                                    <Text
                                      text={
                                        "Informação sobre o documento::card-text-light"
                                      }
                                    />
                                  </div>
                                  {item?.document === {} ||
                                  item.document?.nomeDocumento === undefined ||
                                  item.document?.nomeDocumento === null ||
                                  item.document?.nomeDocumento === "" ? (
                                    <ButtonUpload
                                      text={t(
                                        `bpm.onboarding.backoffice.user.buttonUploadDocument`
                                      )}
                                      variant={"easypay-button-secondary-icon"}
                                      onclick={() =>
                                        onButtonClick(item.document?.id)
                                      }
                                      onChange={handleChange}
                                      key={uuid()}
                                      inputFile={inputFile}
                                      id={"id1"}
                                      className="card-button"
                                      align={"right"}
                                      disabledColored={false}
                                      disabled={false}
                                    />
                                  ) : (
                                    <div className="easypay-document-submitted-wrapper">
                                      <div className="easypay-text-icon">
                                        <img
                                          src={iconDocument}
                                          alt="Icon Check"
                                        />
                                      </div>
                                      <Text
                                        text={
                                          item.document?.nomeDocumento +
                                          "::card-text-blue"
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
      </>
    </div>
  );
};
export default withNamespaces()(BackOfficeSignaturesSection);
