import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  ContentState
} from "draft-js";
import "draft-js/dist/Draft.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { GrBlockQuote, GrMonospace } from "react-icons/gr";
import { GoListOrdered, GoListUnordered } from "react-icons/go";
import { BiCodeBlock, BiUnderline, BiItalic, BiBold } from "react-icons/bi";

import { useLabelWidth } from "../utils/useLabelWidth";
import { useLabelFormated } from "../utils/useLabelFormated";

/* 
- to save into database replace " with udwaspas
- to read from database replace udwaspas with "
*/

const CreateRichText = (props) => {
  const { field, styleSheet, isChild } = props;
  const [textAreaValue, setTextAreaValue] = useState("");
  const [required, setRequired] = useState(false);

  const { labelWidthClass, labelWidth } = useLabelWidth(field);
  const { labelFormated } = useLabelFormated(field);

  const calcState = (value) => {
    /*replace udwaspas with " for rich text Editor*/
    const transformedValue = value.replaceAll("udwaspas", '"');
    // console.log(transformedValue);
    let value_ = transformedValue;
    if (transformedValue) {
      const initialState = {
        blocks: [
          {
            key: "a9erb",
            text: "",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      };
      if (!transformedValue.includes('{"blocks":[{"key":')) {
        initialState.blocks[0].text = value;
        value_ = JSON.stringify(initialState);
      }

      try {
        return EditorState.createWithContent(
          convertFromRaw(JSON.parse(value_))
        );
      } catch (e) {
        console.log(e, value_);
        return EditorState.createEmpty();
      }
    } else return EditorState.createEmpty();
  };

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(blocksFromHtml);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
   }
  const [editorState, setEditorState] = useState(htmlToDraftBlocks(field.value));


  useEffect(() => {
    const markupmodified = field?.value.replace(/u&/g, "ins&")
    const decodedHtml = htmlDecode(markupmodified);
    setEditorState(htmlToDraftBlocks(decodedHtml));
    if (field?.obligatory) {
      if (field?.obligatory === "true") setRequired(true);
      else setRequired(false);
    }
  }, [field]);

  useEffect(() => {
    if (editorState) {
      const raw = convertToRaw(editorState.getCurrentContent());
      const modifiedDataBlock = {
        ...raw,
        blocks: raw.blocks.map(block => {
            if (block.type === "code-block") {
                return { ...block, type: "code" };
            }
            return block;
        })
    };

      const markup = draftToHtml(
        modifiedDataBlock
      );
      const markupmodified = markup.replace(/ins>/g, "u>")
      const encodedHtml = htmlEncode(markupmodified);

      var regExp = /[a-z0-9]/g;
      if (raw?.blocks?.some((block) => regExp.test(block?.text))) {
        const cleanedEncodedHtml = encodedHtml.replace(/nbsp;/g, '').replace(/&amp;/g, '');
        setTextAreaValue(cleanedEncodedHtml);
      } else setTextAreaValue("");
    }
  }, [editorState]);

  const htmlEncode = (text) => {
    return text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
  }
  const htmlDecode = (encodedText) => {
    return encodedText.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#39;/g, "'");
  }


  const getIcon = (label) => {
    switch (label) {
      case "Blockquote":
        return <GrBlockQuote />;
      case "Monospace":
        return <GrMonospace />;
      case "Bold":
        return <BiBold />;
      case "Italic":
        return <BiItalic />;
      case "Underline":
        return <BiUnderline />;
      case "OL":
        return <GoListOrdered />;
      case "UL":
        return <GoListUnordered />;
      case "Code Block":
        return <BiCodeBlock />;

      default:
        return label;
    }
  };

  const StyleButton = (props) => {
    let onClickButton = (e) => {
      e.preventDefault();
      props.onToggle(props.style);
    };

    let className = "richtext-control";
    if (props.active) {
      className += " rtx-active";
    }
    return (
      <button className={className} onMouseDown={onClickButton}>
        {getIcon(props.label)}
      </button>
    );
  };

  const StyleOption = (props) => {
    let className = "richtext-select-control";
    return (
      <option className={className} value={props.style}>
        {getIcon(props.label)}
      </option>
    );
  };

  const BLOCK_TYPES = [
    { label: "Blockquote", style: "blockquote" },
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Code Block", style: "code-block" },
  ];

  const BlockStyleControls = (props) => {
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return (
      <div style={{ display: "flex" }}>
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            label={type.label}
            active={type.style === blockType}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
    );
  };

  const BLOCK_TYPES_HEADERS = [
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
  ];

  const BlockHeadersStyleControls = (props) => {
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    const active = BLOCK_TYPES_HEADERS.find((i) => i.style === blockType);
    const handleHeading = (e) => {
      //e.preventDefault();
      props.onToggle(e?.target?.value);
    };
    return (
      <select
        onChange={(e) => handleHeading(e)}
        value={active?.style || "placeholder"}
      >
        <option value="placeholder" className="richtext-select-control">
          P
        </option>
        {BLOCK_TYPES_HEADERS.map((type) => (
          <StyleOption
            key={type.label}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </select>
    );
  };

  const INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
    { label: "Monospace", style: "CODE" },
  ];

  const InlineStyleControls = (props) => {
    var currentStyle = editorState.getCurrentInlineStyle();
    return (
      <div style={{ display: "flex" }}>
        {INLINE_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            label={type.label}
            active={currentStyle.has(type.style)}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
    );
  };

  const onInlineClick = (e) => {
    let nextState = RichUtils.toggleInlineStyle(editorState, e);
    setEditorState(nextState);
  };

  const onBlockClick = (e) => {
    let nextState = RichUtils.toggleBlockType(editorState, e);
    setEditorState(nextState);
  };

  return (
    <>
      <Form.Group
        controlId={`formTextarea-${field.variable}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          flexGrow: "1",
        }}
      >
        <Form.Label
          column
          className={labelWidthClass ? labelWidthClass : "iflow-form-label-fix"}
          style={
            labelWidth
              ? {
                  width: labelWidth,
                  minWidth: labelWidth,
                  flexBasis: labelWidth,
                }
              : {}
          }
        >
          {labelFormated
            ? labelFormated
            : field?.text?.b
            ? field?.text?.b.split("::richText")[0]
            : field.text.split("::richText")[0]}
        </Form.Label>
        <Col
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            flexGrow: "2",
          }}
        >
          {field.disabled === "true" ? (
            <div className="richtext-wrapper">
              <div>
                <Editor editorState={editorState} readOnly={true} />
                <input
                  style={{ opacity: "0" }}
                  name={field.variable}
                  value={textAreaValue}
                  readOnly
                />
              </div>
            </div>
          ) : (
            <>
              <div className="richtext-wrapper">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <BlockHeadersStyleControls onToggle={onBlockClick} />
                  <BlockStyleControls onToggle={onBlockClick} />
                  <InlineStyleControls onToggle={onInlineClick} />
                </div>
                <hr />
                <div>
                  <Editor
                    editorState={editorState}
                    onChange={(editorState) => setEditorState(editorState)}
                  />
                </div>
              </div>
              <input
                style={{ opacity: "0" }}
                name={field.variable}
                //required={required}//todo implement validation
                value={textAreaValue}
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${field.text} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </>
          )}
        </Col>
      </Form.Group>
      {styleSheet?.highLightRows && !isChild && <hr />}
    </>
  );
};
export default CreateRichText;
