import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_radioButtonWrapper.scss";
import RadioButton from "./RadioButton";

const RadioButtonItem = ({
  name,
  text,
  setTheActiveTab,
  activeTab,
  disabled,
  child,
  subHeader,
  action,
  uniqueName,
  radioButtonLength,
  checked,
  displayType
}) => {
  const activeChildren = () => {
    return <>{child?.props?.children}</>;
  };

  useEffect(() => {
    if (action) {
      const variable = action?.split("::")[0];
      const value = action?.split("::")[1];
      if (document.dados[variable]) {
        if (activeTab === name) {
          if (document.dados[variable]?.value !== value) {
            document.dados[variable].value = value;
          }
        }
      }
    }
  }, [activeTab]);

  return (
    <>
      <RadioButton
        text="Radio2"
        value={text}
        subHeader={subHeader}
        name={uniqueName ? uniqueName : "grupo"}
        id={name}
        checked={name === activeTab || checked === true}
        onClick={() => {
          setTheActiveTab(name);
        }}
        disabled={disabled}
        displayType = {displayType}
      />
      {child?.props?.children &&
        (child?.props?.children[0]?.length > 0 ||
          child?.props?.children?.props?.children?.length > 0 ||
          child?.props?.children.props?.children?.props?.children.length >
            0) && (
          <>
            <div
              className={`easypay-radio-button-content ${
                name.includes("Other")
                  ? ""
                  : !uniqueName?.includes("signatureType")
                  ? "easypay-content-padding-left"
                  : ""
              }`}
            >
              {activeTab === name && activeChildren()}
            </div>
            {radioButtonLength !== child?.props?.index + 1 ? (
              activeTab === name ? (
                <hr
                  style={{
                    width: "100%",
                    margin: "0px",
                    paddingBottom: "10px",
                    paddingTop: "20px",
                  }}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </>
        )}
    </>
  );
};

export default withNamespaces()(RadioButtonItem);
