import React, { useState, useEffect } from 'react';
import Document from "../Document";
import { useSelector } from "react-redux";

const CitizenCardUpload = props => {

  const { batchId } = props.match.params;

  const [menuId, setMenuId] = useState(undefined);
  const { menu } = useSelector(state => state.applicationMenuReducer);

  useEffect(() => {
    const menuItem = menu.find(item => item.route === "datacapture/onboarding")
    if (menuItem)
      setMenuId(menuItem.id);
  }, [menu])

  return (
    <Document docType="citizen_card" batchId={batchId} menuId={menuId} />
  )
}

export default CitizenCardUpload;
