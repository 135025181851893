import React from "react";
import uuid from "uuid/v1";
import Bullet from "~/pages/Easypay/components/Bullet";
import CustomButton from "~/pages/Easypay/components/Button";
import ButtonUploadDocument from "~/pages/Easypay/components/ButtonUploadDocument";
import CountrySelected from "~/pages/Easypay/components/CountrySelected";
import Input from "~/pages/Easypay/components/Input";
import Link from "~/pages/Easypay/components/Link";
import RadioButton from "~/pages/Easypay/components/RadioButton";
import SingleDocument from "~/pages/Easypay/components/SingleDocument";
import Text from "~/pages/Easypay/components/Text";
import iconEmailSent from "../../assets/img/icons/icon-email-sent.png";
import SingleButtonUpload from "../../components/SingleButtonUpload";
import CreateInputPassword from "./CreateInputPassword";
import CreateInputSearch from "./CreateInputSearch";

const CreateRowFromColumns = ({ field, columns, handleSubmitWrapperLink }) => {
  const addColumns = () => {
    let content = [];
    columns.map((column, i) => {
      let fieldToAdd;
      if (Array.isArray(column.field)) {
        fieldToAdd =
          column.field[0]?.type === "textlabel"
            ? column.field[0]?.text
            : column.field[0]?.variable;
      } else {
        fieldToAdd =
          column.field?.type === "textlabel"
            ? column.field?.text
            : column.field?.variable;
      }
      if (column !== "" && column.field) {
        let fieldArray = column.field ? column.field : [];

        if (!Array.isArray(fieldArray)) {
          fieldArray = [fieldArray];
        }
        return fieldArray.map((field, index) => {
          if (index > 0) return;
          else if (field.type === "textbox") {
            content.push(
              <div
                style={{
                  width: column.width + "%",
                  paddingRight: `${
                    columns.length === 3 && i === 1 ? "10px" : ""
                  }`,
                  paddingLeft: `${
                    columns.length === 3 && i === 2 ? "10px" : ""
                  }`,
                }}
                key={uuid()}
              >
                <Input field={field} key={field.variable} />
              </div>
            );
          } else if (field.type === "link") {
            if (field.text.includes("button")) {
              if (field.text.split("::")[1] != "easypay-button-single-upload")
                content.push(
                  <div
                    style={{
                      justifyContent: field.align,
                      width: column.width + "%",
                    }}
                  >
                    <CustomButton
                      text={field.text.split("::")[0]}
                      variant={field.text.split("::")[1]}
                      onclick={eval(field.onclick)}
                      key={uuid()}
                      id={field.id}
                      className="card-button"
                      align={field.align}
                      disabledColored={
                        field.text.split("::")[2] === "disabledColored"
                          ? true
                          : false
                      }
                      disabled={
                        field.text.split("::")[2] === "disabled" ? true : false
                      }
                      field={field}
                    />
                  </div>
                );
              else
                content.push(
                  <div
                    style={{
                      justifyContent: field.align,
                      width: column.width + "%",
                    }}
                  >
                    <SingleButtonUpload
                      text={field.text.split("::")[0]}
                      key={uuid()}
                      id={field.id}
                      className="card-button"
                      align={field.align}
                      disabledColored={false}
                      disabled={false}
                      field={field}
                    />
                  </div>
                );
            } else {
              content.push(
                <div
                  className="easypay-link-wrapper"
                  key={uuid()}
                  style={{ textAlign: field.align, width: column.width + "%" }}
                >
                  <Link
                    text={
                      field.text.includes("::disabled")
                        ? field.text.split("::")[0]
                        : field.text
                    }
                    disabledColored={
                      field.text.split("::")[1] === "disabledColored"
                        ? "true"
                        : false
                    }
                    disabled={
                      field.text.split("::")[1] === "disabled" ? "true" : false
                    }
                    link=""
                    key={uuid()}
                  />
                </div>
              );
            }
          } else if (field.type === "arraytable") {
            if (field?.row?.col[0]?.value === "number") {
              const bulletList = field.row.col
                .filter((column, i) => {
                  if (i > 0) return column;
                })
                .map((column) => {
                  return column.value;
                });
              content.push(<Bullet key={uuid()} list={bulletList} />);
            }
            if (field?.row?.col[0]?.value.split("::")[0] === "badge") {
              content.push(
                <Text
                  text={field?.row?.col[1].value}
                  icon={field?.row?.col[0].value.split("::")[1]}
                  key={uuid()}
                />
              );
            }

            if (field?.row?.col[0]?.value.split("::")[0] === "icon") {
              content.push(
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "40px",
                  }}
                >
                  <img src={iconEmailSent} alt="email sent" />
                </div>
              );
            }
            if (field?.row?.col[0]?.value === "single-document-upload") {
              content.push(
                <ButtonUploadDocument
                  key={uuid()}
                  variables={field?.row?.col}
                  submitForm={handleSubmitWrapperLink}
                />
              );
            }
          } else if (field?.type === "textlabel") {
            if (field.text.includes("single-document-link")) {
              content.push(
                <SingleDocument
                  key={uuid()}
                  name={field.value}
                  variable={field.text.split("::")[1]}
                  variableValue={field.text.split("::")[2]}
                  submitForm={handleSubmitWrapperLink}
                  variant={
                    field.text.split("::").length === 4
                      ? field.text.split("::")[3]
                      : ""
                  }
                />
              );
              return;
            }
            if (field.text === "single-document-component") {
              content.push(<SingleDocument name={field?.value} key={uuid()} />);
            } else
              content.push(
                <div
                  style={{
                    width: column.width + "%",
                  }}
                  key={uuid()}
                >
                  {field?.text === "country" ? (
                    <CountrySelected key={uuid()} field={field} />
                  ) : (
                    <>
                      <Text key={uuid()} text={field?.text} />
                      <Text
                        key={uuid()}
                        text={field?.value + "::card-text-light"}
                      />
                    </>
                  )}
                </div>
              );
          }
          if (field.type === "textmessage") {
            content.push(
              <div
                style={{
                  width: column.width + "%",
                }}
                key={uuid()}
              >
                <div className={`easypay-${field.text.split("::")[1]}`}>
                  {field.text.split("::")[0]}
                </div>
              </div>
            );
          } else if (field.type === "button") {
            content.push(
              <div
                style={{
                  width: column.width + "%",
                }}
                key={uuid()}
              >
                <CustomButton
                  text={field.text.split("::")[0]}
                  variant={field.text.split("::")[1]}
                  key={uuid()}
                  id={field.id}
                  className="card-button"
                  align={field.tooltip}
                />
              </div>
            );
          }

          if (field.type === "password") {
            content.push(
              <div
                style={{
                  width: column.width + "%",
                }}
                key={uuid()}
              >
                <CreateInputPassword
                  label={field.label}
                  hasBar={false}
                  key={uuid()}
                />
              </div>
            );
          } else if (field.type === "selection") {
            content.push(
              <CreateInputSearch
                field={field}
                key={field.variable}
                isChild={true}
              />
            );
          } else if (field.type === "radiobutton") {
            if (field.checked) {
              content.push(
                <div
                  id={`formlabel-${field.type}`}
                  key={uuid()}
                  style={{
                    display: "flex",
                  }}
                >
                  <RadioButton
                    name={field.name}
                    value={field.value}
                    id={field.id}
                    checked
                  />
                </div>
              );
            } else {
              content.push(
                <div
                  id={`formlabel-${field.type}`}
                  key={uuid()}
                  style={{
                    display: "flex",
                  }}
                >
                  <RadioButton
                    name={field.name}
                    value={field.value}
                    id={field.id}
                  />
                </div>
              );
            }
          }
        });
      }
    });
    return content;
  };

  const renderFields = (field) => {
    if (field.type === "textbox") {
      return (
        <div
          id={`formlabel-${field.type}`}
          key={uuid()}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {columns && addColumns()}
        </div>
      );
    }

    if (field.type === "arraytable") {
      return (
        <div
          id={`formlabel-${field.type}`}
          key={uuid()}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {columns && addColumns()}
        </div>
      );
    }
    if (field.type === "link") {
      return (
        <div
          id={`formlabel-${field.type}`}
          key={uuid()}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {columns && addColumns()}
        </div>
      );
    }
    if (field.type === "textlabel") {
      return (
        <div
          id={`formlabel-${field.type}`}
          key={uuid()}
          style={{
            display: "flex",
          }}
        >
          {columns && addColumns()}
        </div>
      );
    }
    if (field.type === "button") {
      return (
        <div
          id={`formlabel-${field.type}`}
          key={uuid()}
          style={{
            display: "flex",
          }}
        >
          {columns && addColumns()}
        </div>
      );
    }
    if (field.type === "textmessage") {
      return (
        <div
          id={`formlabel-${field.type}`}
          key={uuid()}
          style={{
            display: "flex",
          }}
        >
          {columns && addColumns()}
        </div>
      );
    } else if (field.type === "password") {
      return (
        <div
          id={`formlabel-${field.type}`}
          key={uuid()}
          style={{
            display: "flex",
          }}
        >
          {columns && addColumns()}
        </div>
      );
    } else if (field.type === "radiobutton") {
      return (
        <div
          id={`formlabel-${field.type}`}
          key={uuid()}
          style={{
            display: "flex",
          }}
        >
          {columns && addColumns()}
        </div>
      );
    } else if (field.type === "selection") {
      return (
        <CreateInputSearch field={field} key={field.variable}>
          {columns && addColumns()}
        </CreateInputSearch>
      );
    } else return null;
  };
  return field ? renderFields(field) : <div></div>;
};
export default CreateRowFromColumns;
