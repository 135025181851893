import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card, Tabs, Tab } from "react-bootstrap";
import "~/assets/css/icons.css";

import Objectives from "./Objectives";

const Evaluator = ({ t }) => {

  const [key, setKey] = useState('0');

  return (
    <>
      <div className="main-card-v2" style={{ width: "100%" }}>
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          <Card.Header className="justify-content-between">
            <h6>{t("Evaluator")}</h6>
          </Card.Header>
          <Card.Body>
            <Tabs id="authorizationTabs" defaultActiveKey="first" className="custom-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}>
              <Tab eventKey={'0'} title={t("portalrh.performance.evaluator.goals")}><Objectives /></Tab>
              <Tab eventKey={'1'} title={t("portalrh.performance.evaluator.organicUnits")}></Tab>
              <Tab eventKey={'2'} title={t("portalrh.performance.evaluator.evaluated")}></Tab>
              <Tab eventKey={'3'} title={t("portalrh.performance.evaluator.performanceMonitoring")}></Tab>
              <Tab eventKey={'4'} title={t("portalrh.performance.evaluator.followUp")}></Tab>
              <Tab eventKey={'5'} title={t("portalrh.performance.evaluator.evaluation")}></Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withNamespaces()(Evaluator);