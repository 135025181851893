import {
  CREATE_USER_SETTINGS,
  SET_USER_SETTINGS,
  CLEAR_USER_SETTINGS,
  SET_USER_WIDGETS,
  SET_LAST_ADDED_WIDGET,
  SET_IS_UPDATED_WIDGET,
  SET_UNIKUSER_SETTINGS,
  GET_ISFIRSTLOGIN,
  SET_ISFIRSTLOGIN,
  POST_ISFIRSTLOGIN,
  SET_POST_ISFIRSTLOGIN
} from "../actions";

const initialState = {
  userSettings: {},
  isLoadingUserSettings: false,

  userWidgets: [],
  placements: [],
  lastAddedWidget: null,
  isUpdatedWidget: false,

  unikUserSettings: undefined,

  isfirstlogin: false,
  post_isfirstlogin:null
};

export const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_SETTINGS:
      return {
        ...state,
        isLoadingUserSettings: true,
      };
    case SET_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.userSetting,
        isLoadingUserSettings: false,
      };
    case CLEAR_USER_SETTINGS:
      return { ...state, userSettings: initialState.userSettings };

    case SET_USER_WIDGETS:
      return { ...state, userWidgets: action.userWidgets };

    case SET_LAST_ADDED_WIDGET:
      return { ...state, lastAddedWidget: action.widget };
    case SET_IS_UPDATED_WIDGET:
      return { ...state, isUpdatedWidget: action.isUpdated };

    case SET_UNIKUSER_SETTINGS:
      return {
        ...state,
        unikUserSettings: action.unikUserSettings,
      };
    case GET_ISFIRSTLOGIN:
      return { ...state };
    case POST_ISFIRSTLOGIN:
      return { ...state };
    case SET_ISFIRSTLOGIN:
      return {
        ...state,
        isfirstlogin: action.isfirstlogin,
      };
      case SET_POST_ISFIRSTLOGIN:
        return {
          ...state,
          post_isfirstlogin: action.post_isfirstlogin,
        };
    default:
      return state;
  }
};
