
import { put } from 'redux-saga/effects';
import http from '~/utils/config/http';

import {
  setUsageReport,
} from './actionTypes';

import { activeError } from '~/components/Messages/actions';

export function* findUsageReport() {
  try {
    const resp = yield http.get('/api/datacapture/reports/all');
    yield put(setUsageReport(resp.data));
  } catch (e) {
    yield put(setUsageReport([]));
    yield put(activeError(e));
  }
}