import React, { useEffect, useState } from "react";
import AutomatedDocumentTableCustomFilterZone from "../components/AutomatedDocumentTableCustomFilterZone";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Button, Accordion } from "react-bootstrap";
import { findIflowTasksByFilters } from "~/store/ducks/tasks/actionTypes";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import Categories from "~/components/Tasks/Categories";

import DateTimePicker from "react-datetime-picker";
import { subDays } from "date-fns";
import "~/assets/css/icons.css";

import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

const AutomatedDocumentTableFilters = (props) => {
  const { forceFiltersReset, setForceFiltersReset, t, onSearch, onReset, setSuplierSearch, suplierSearch, filterActive } =
    props;
  const [endAt, setEndAt] = useState(undefined);
  const [startAt, setStartAt] = useState(undefined); 
  const [endAtCreated, setEndAtCreated] = useState(new Date());
  const [startAtCreated, setStartAtCreated] = useState(subDays(new Date(), 7));  
  const [endPaymentAt, setEndPaymentAt] = useState(undefined);
  const [startPaymentAt, setStartPaymentAt] = useState(undefined);
  const [fornecedor, setFornecedor] = useState(undefined);
  const [auxFornecedor, setAuxFornecedor] = useState(undefined);
  const [approvedRadioValue, setApprovedRadioValue] = useState("0");
  const [statusRadioValue, setStatusRadioValue] = useState(undefined);
  const [dateRadioValue, setDateRadioValue] = useState(undefined);
  const [checkSwitch, setCheckSwitch] = useState(false);

  useEffect(() => {
    if (forceFiltersReset) {
      handleFornecedor(" ");
      setStartAt(undefined);
      setEndAt(undefined);
      setStartPaymentAt(undefined);
      setEndPaymentAt(undefined);
      setApprovedRadioValue("0");
      setCheckSwitch(false);
      setSuplierSearch("")
      setEndAtCreated(undefined)
      setStartAtCreated(undefined)
    }
    handleApplySearchTable()
    setForceFiltersReset(false);
  }, [forceFiltersReset]);

  const handleStartAt = (date) => {
    setStartAt(date);
  };

  const handleStartPaymentAt = (date) => {
    setStartPaymentAt(date);
  };

  const handleEndAt = (date) => {
    setEndAt(date);
  };

  const handleEndAtCreated = (date) => {
    setEndAtCreated(date);
  };

  const handleStartAtCreated = (date) => {
    setStartAtCreated(date);
  };

  const handleEndPaymentAt = (date) => {
    setEndPaymentAt(date);
  };

  const handleFornecedor = (fornecedor) => {
    setSuplierSearch(fornecedor);
  };

  const handleApplySearchTable = () => {
    let obj = {
      createdStart:startAtCreated,
      createdEnd:endAtCreated,
      dataFaturaIncial: startAt,
      dataFaturaFinal: endAt,
      dataPagamentoInicial: startPaymentAt,
      dataPagamentoFinal: endPaymentAt,
      approvedRadioValue: approvedRadioValue,
      statusRadioValue: statusRadioValue,
      dateRadioValue: dateRadioValue,
    };
    onSearch(obj);
  };

  const handleApplyResetTable = () => {
    onReset();
  };

  return (
    <>
      <AutomatedDocumentTableCustomFilterZone
        startAt={startAt}
        endAt={endAt}
        endAtCreated={endAtCreated}
        startAtCreated={startAtCreated}
        startPaymentAt={startPaymentAt}
        endPaymentAt={endPaymentAt}
        handleStartAt={handleStartAt}
        handleStartPaymentAt={handleStartPaymentAt}
        handleEndPaymentAt={handleEndPaymentAt}
        handleEndAt={handleEndAt}
        handleEndAtCreated={handleEndAtCreated}
        handleStartAtCreated={handleStartAtCreated}
        handleFornecedor={handleFornecedor}
        fornecedor={suplierSearch}
        approvedRadioValue={approvedRadioValue}
        setApprovedRadioValue={setApprovedRadioValue}
        dateRadioValue={dateRadioValue}
        setDateRadioValue={setDateRadioValue}
        forceFiltersReset={forceFiltersReset}
        checkSwitch={checkSwitch}
        setCheckSwitch={setCheckSwitch}
        handleApplySearchTable={handleApplySearchTable}
        handleApplyResetTable={handleApplyResetTable}
        searchProps={props.searchProps}
        filterActive={filterActive}
      />
    </>
  );
};
export default withNamespaces()(AutomatedDocumentTableFilters);
