import * as types from "./actionTypes";
import * as middlewares from "~/utils/constants";

const initialState = {
  //BPM
  pullingBPMData: false,
  successPulledBPMData: undefined,
  loadingBPM: {
    mw: false,
    iflow: false,
  },
  isBPMON: {
    mw: undefined,
    iflow: undefined,
  },
  //Financeira
  loadingFinanceira: false,
  isFinanceiraON: undefined,
  //GlobalSolver
  loadingGlobalSolver: false,
  isGlobalSolverON: undefined,
  //GDPR
  loadingGDPR: {
    mw: false,
    iflow: false,
  },
  isGDPRON: {
    mw: undefined,
    iflow: undefined,
  },
  //Compliance
  loadingCompliance: {
    mw: false,
    iflow: false,
  },
  isComplianceON: {
    mw: undefined,
    iflow: undefined,
  },
  //GAI
  loadingGAI: false,
  isGAION: undefined,
  //Portal RH
  loadingPortalRH: {
    mw: false,
    iflow: false,
  },
  isPortalRHON: {
    mw: undefined,
    iflow: undefined,
  },

  syncMWList: null,
  resSyncMWList: "",
  resEncyptMWList: "",
  isLoadingMWList: false,
  isLoadingEncrypt: false,
};

export const systemSettingsApplicationsReducer = (
  state = initialState,
  action
) => {
  let status = undefined;
  switch (action.type) {
    case types.PULL_DATA_FROM_BPM:
      return { ...state, pullingBPMData: true };
    case types.SET_PULLED_DATA_FROM_BPM:
      return {
        ...state,
        pullingBPMData: false,
        successPulledBPMData: action.success,
      };

    case types.PULL_DATA_ALL_MIDDLEWARE:
      return { ...state, pullingBPMData: true };
    case types.SET_PULLED_ALL_MIDDLEWARE:
      return {
        ...state,
        pullingBPMData: false,
        successPulledBPMData: action.success,
      };

    case types.GET_SYNC_LIST_MW:
      return { ...state, isLoadingMWList: true };
    case types.GET_SYNC_ENCRYPT:
      return { ...state, isLoadingEncrypt: true };
    case types.SET_MESSAGE_ENCRYPT:
      return {
        ...state,
        resEncyptMWList: action.resEncyptMWList,
        isLoadingEncrypt: false,
      };
    case types.SET_SYNC_LIST_MW:
      return { ...state, syncMWList: action.syncMWList, isLoadingMWList: false };
    case types.POST_SYNC_LIST_MW:
      return { ...state, isLoadingMWList: true };
    case types.SET_MESSAGE_SYNC_LIST_MW:
      return { ...state, resSyncMWList: action.resSyncMWList, isLoadingMWList: false };

    case types.PING_MIDDLEWARE_APPLICATION:
      switch (action.middleware) {
        case middlewares.BPM_MIDDLEWARE:
          return { ...state, loadingBPM: { mw: true } };
        case middlewares.FIN_MIDDLEWARE:
          return { ...state, loadingFinanceira: { mw: true } };
        case middlewares.GLOBAL_SOLVER_MIDDLEWARE:
          return { ...state, loadingGlobalSolver: { mw: true } };
        case middlewares.GDPR_MIDDLEWARE:
          return { ...state, loadingGDPR: { mw: true } };
        case middlewares.COMPLIANCE_MIDDLEWARE:
          return { ...state, loadingCompliance: { mw: true } };
        case middlewares.PORTALRH_MIDDLEWARE:
          return { ...state, loadingPortalRH: { mw: true } };
      }
      return { ...state };
    case types.SET_PING_MIDDLEWARE_APPLICATION:
      const { middleware } = action.payload;
      status = action.payload.status;
      switch (middleware) {
        case middlewares.BPM_MIDDLEWARE:
          return {
            ...state,
            loadingBPM: { mw: false },
            isBPMON: { mw: status },
          };
        case middlewares.FIN_MIDDLEWARE:
          return {
            ...state,
            loadingFinanceira: { mw: false },
            isFinanceiraON: { mw: status },
          };
        case middlewares.GLOBAL_SOLVER_MIDDLEWARE:
          return {
            ...state,
            loadingGlobalSolver: { mw: false },
            isGlobalSolverON: { mw: status },
          };
        case middlewares.GDPR_MIDDLEWARE:
          return {
            ...state,
            loadingGDPR: { mw: false },
            isGDPRON: { mw: status },
          };
        case middlewares.COMPLIANCE_MIDDLEWARE:
          return {
            ...state,
            loadingCompliance: { mw: false },
            isComplianceON: { mw: status },
          };
        case middlewares.PORTALRH_MIDDLEWARE:
          return {
            ...state,
            loadingPortalRH: { mw: false },
            isPortalRHON: { mw: status },
          };
      }
      return { ...state };

    case types.PING_IFLOW_BASED_APPLICATION:
      switch (action.payload.application) {
        case middlewares.BPM_MIDDLEWARE.context:
          return { ...state, loadingBPM: { iflow: true } };
        case middlewares.GDPR_MIDDLEWARE.context:
          return { ...state, loadingGDPR: { iflow: true } };
        case middlewares.COMPLIANCE_MIDDLEWARE.context:
          return { ...state, loadingCompliance: { iflow: true } };
        case middlewares.GAI_MIDDLEWARE.context:
          return { ...state, loadingGAI: { iflow: true } };
        case middlewares.PORTALRH_MIDDLEWARE.context:
          return { ...state, loadingPortalRH: { iflow: true } };
      }
      return { ...state };
    case types.SET_PING_IFLOW_BASED_APPLICATION:
      const { application } = action.payload;
      status = action.payload.status;
      switch (application) {
        case middlewares.BPM_MIDDLEWARE:
          return {
            ...state,
            loadingBPM: { iflow: false },
            isBPMON: { iflow: status },
          };
        case middlewares.GDPR_MIDDLEWARE:
          return {
            ...state,
            loadingGDPR: { iflow: false },
            isGDPRON: { iflow: status },
          };
        case middlewares.COMPLIANCE_MIDDLEWARE:
          return {
            ...state,
            loadingCompliance: { iflow: false },
            isComplianceON: { iflow: status },
          };
        case middlewares.GAI_MIDDLEWARE:
          return {
            ...state,
            loadingGAI: { iflow: false },
            isGAION: { iflow: status },
          };
        case middlewares.PORTALRH_MIDDLEWARE:
          return {
            ...state,
            loadingPortalRH: { iflow: false },
            isPortalRHON: { iflow: status },
          };
      }
      return { ...state };
    default:
      return state;
  }
};
