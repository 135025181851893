import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import { getColumnFormatter } from "./columnFormatter";
import { withNamespaces } from "react-i18next";
import { toLocaleStringHistory } from "~/utils";
const ProcessHistoryTable = (props) => {
    const {
        t,
        processHistory,
        flowId,
        app
      } = props;

      const { menu } = useSelector((state) => state.applicationMenuReducer);

      const [processesToRender, setProcessesToRender] = useState(processHistory);
      const [field, setField] = useState(undefined);
      const [order, setOrder] = useState(undefined);
      const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);


      useEffect(() => {
        if (menu) {
          const menuItem = menu.find(
            (item) =>
              item.route === `${app}/pesquisar` || item.route === `${app}/search`
          );
          if (menuItem) {
            const extraConfiguration_ = JSON.parse(
              menuItem.extraConfiguration || false
            );
            let flowColumns_;
            let hiddenDefaults_;
            if (flowId && extraConfiguration_[flowId]) {
              flowColumns_ = extraConfiguration_[flowId].columns; //columns for a specific flow
              hiddenDefaults_ = extraConfiguration_[flowId].hiddenDefaults; //hidden default columns for a specific flow
            } else {
              flowColumns_ = extraConfiguration_.defaultExtraColumns; //extra columns for all flows
              hiddenDefaults_ = extraConfiguration_.hiddenDefaults; //hidden default columns for all flows
            }
            setHiddenDeafultColumns(hiddenDefaults_ ? hiddenDefaults_ : undefined);
          }
        }
      }, [menu, flowId]);

      const isHidden = (column) => {
        if (hiddenDefaultColumns) {
          return hiddenDefaultColumns.some((c) => c === column) ? true : false;
        } else return false;
      };
      const handleStatusSort = (field) => {
        setOrder((order) => (order === "desc" ? "asc" : "desc"));
        setField(field);
      };
      const columns = [
        {
            dataField: "userid",
            text: t("validprocs.general.userid"),
            sort: true,
            formatter: getColumnFormatter,
            formatExtraData: {
              value: "userid",
            },
            onSort: () => handleStatusSort("userid"),
            hidden:isHidden("userid"),
            headerStyle: () => {
              return {
                width: "13%",
              };
            },
            style: {
              overflowWrap: "break-word",
            },
          },
          {
            dataField: "started",
            text: t("validprocs.general.creation_date"),
            sort: true,
            hidden: isHidden("started"),
            formatter: (_, row, {}) => toLocaleStringHistory(row.started),
            headerStyle: () => {
              return { width: "8%", };
            },
          },
          {
            dataField: "archived",
            text: t("validprocs.general.end_date"),
            sort: true,
            hidden: isHidden("archived"),
            formatter: (_, row, {}) => toLocaleStringHistory(row.archived),
            headerStyle: () => {
              return { width: "8%", };
            },
          },
          {
            dataField: "description",
            text: t("validprocs.general.description"),
            formatter: getColumnFormatter,
            formatExtraData: {
              value: "description",
            },
            sort: true,
            onSort: () => handleStatusSort("description"),
            hidden: isHidden("description"),
            headerStyle: () => {
              return {
                width: "auto",
              };
            },
            style: {
              maxWidth: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          },

      ];

      return(
        <div className="custom-table" style={{padding:'30px'}}>
        <ToolkitProvider
          keyField="pid"
          data={processesToRender}
          columns={columns}
        >
          {(props) => (
            <>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("taskPage.general.processesNotFound")}
                sort={{
                  dataField: field,
                  order: order,
                }}
                striped
                hover
              />
            </>
          )}
        </ToolkitProvider>
      </div>
      )
};
export default withNamespaces()(ProcessHistoryTable);
