import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { transformFormData } from "../utils";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";
import oauth from "~/utils/oauth";

const CreateButtonsFromSelection = (props) => {
  const { field, styleSheet } = props;

  const [key, setKey] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (field && field.option) {
      const foundItem = field.option.find((f) => f.selected === "yes");
      if (foundItem && foundItem.text !== "Selecione") setKey(foundItem.value);
    }
  }, [field]);
  const [textSize, setTextSize] = useState("")
  useEffect(() => {
    if (
      styleSheet?.zoomIn &&
      styleSheet?.zoomInTextSize
    ) {
      setTextSize(`${styleSheet?.zoomInTextSize}`)
    }
  }, [styleSheet]);

  const renderButtons = () => {
    if (field && Array.isArray(field.option)) {
      return field.option.map((f, i) => {
        if (f.text !== "Selecione")
          return (
            <Button
              key={i}
              className={
                f.selected === "yes"
                  ? "card-button-10"
                  : "card-outlined-button-10"
              }
              style={{
                //maxWidth: "160px",
                flexGrow: 1,
                padding: "3px",
                fontSize: "12px",
                margin: "0 1px",
                overflowWrap: "normal",
                fontSize: `${textSize}px`
              }}
              onClick={() => handleSelectChange(f.value)}
            >
              {f.text}
            </Button>
          );
      });
    }
    return <div></div>;
  };

  const handleSelectChange = (value) => {
    if (field.onchange_submit !== "") {
      if (field.onchange_submit.includes("document.dados.op.value='")) {
        const array = field.onchange_submit.split("document.dados.op.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.op.value = array2[0];
          }
        }
      }
      if (value && field.variable) {
        document.dados[field.variable].value = value;
      }
      if (field.onchange_submit.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postIflowFormData(formdata, url));
      }
      setKey(value);
    }
  };

  return (
    <>
      <div
        style={{
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          width: "100%",
          overflow: "hidden",
          fontSize: `${textSize}px`
        }}
      >
        {renderButtons()}
      </div>
      <br />
      <input type="hidden" value={key} name={field.variable} />
    </>
  );
};
export default CreateButtonsFromSelection;
