import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_toggleAccordion.scss";
import Toggle from "./Toggle";

const ToggleAccordion = ({list}) => {

    const [data, setData] = useState(-1);

    function checkToggleIndex(index)
    {
        const indexStr = index.toString();
        if(data === -1 || data === index)
            return false
        else
            return true
    }

    const handleToggleState = (index) => {
        if (data === -1) {
            setData(index)
        }
        else
            setData(-1)
      };

  return (
    <>
        {list &&
        Object.keys(list).length !== 0 &&
        list.map((item, index) => {
          return (
            <>
              <div className="easypay-toggle-accordion-wrapper" key={index}>
                <div className="easypay-toggle-accordion-header">
                 <div className="easypay-toggle-accordion-name">{item.name}</div>
                  <div className="easypay-top-toggle-container">
                    <Toggle id={"toggle-accordion-id" + index} disabled = {checkToggleIndex(index)} onClick = {() => handleToggleState(index)}></Toggle>
                  </div>
                </div>
                {data === index && (
                  <div className="easypay-toggle-accordion-content">
                    {item.content}
                  </div>
                )}
              </div>
            </>
          );
        })}
    </>
  );
};

export default withNamespaces()(ToggleAccordion);