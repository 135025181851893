import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import { TabsKey } from "./utils/TabsEnum";
import BaseWidgetsForm from "./components/BaseWidgetsForm";
import RegisterBaseWidget from "./components/RegisterBaseWidget";
import ProductionMode from "./components/ProductionMode";
import AuthMode from "./components/AuthMode";
import PuzzleSettings from "./components/PuzzleSettings";
import ApplicationMenu from "./components/ApplicationMenu";
import ApplicationLogo from "./components/ApplicationLogo";
import Applications from "./components/Applications";
import ProtectedPage from "~/components/security/ProtectedPage";
import Syncronization from './components/Syncronization'

import { getPuzzleItems, getAuthModes, requestConfiguration } from "./actions";
import { hasRoles } from "~/components/security/utils";

import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";

const SystemSettings = (props) => {
  const { t } = props;
  const dispatch = useDispatch();

  const [key, setKey] = useState(TabsKey.APPLICATION_MENU);

  const { isUpdated } = useSelector((state) => state.adminConfigReducer);
  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(getPuzzleItems());
    dispatch(getAuthModes());
    !hasRoles(user, [ROLE_ADMIN], true) && setKey(TabsKey.PUZZLE);
  }, []);

  useEffect(() => {
    dispatch(requestConfiguration());
  }, [isUpdated]);

  return (
    <ProtectedPage roles={[ROLE_ADMIN, ROLE_ORG_ADMIN]}>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat" style={{ minHeight: "500px" }}>
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-gear mr-2" /> {t("SystemSettings.title")}
            </h6>
          </Card.Header>
          <Card.Body>
            <Tabs
              className="custom-tabs"
              id="adminSettingsTab"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              {hasRoles(user, [ROLE_ADMIN], true) && (
                <Tab
                  eventKey={TabsKey.PRODUCTION_MODE}
                  title={t("SystemSettings.productionMode")}
                >
                  <br />
                  <ProductionMode />
                </Tab>
              )}
              {hasRoles(user, [ROLE_ADMIN], true) && (
                <Tab eventKey={TabsKey.AUTH_MODE} title="Auth Mode">
                  <br />
                  <AuthMode />
                </Tab>
              )}
              {hasRoles(user, [ROLE_ADMIN], true) && (
                <Tab
                  eventKey={TabsKey.BASE_WIDGETS}
                  title={t("SystemSettings.configureWidgets")}
                >
                  <BaseWidgetsForm />
                  <hr />
                  <RegisterBaseWidget />
                </Tab>
              )}

              <Tab eventKey={TabsKey.PUZZLE} title="Puzzle">
                <br />
                <PuzzleSettings />
              </Tab>
              {hasRoles(user, [ROLE_ADMIN], true) && (
                <Tab
                  eventKey={TabsKey.APPLICATION_MENU}
                  title={t("SystemSettings.applicationMenu")}
                >
                  <br />
                  <ApplicationMenu tabKey={key} />
                </Tab>
              )}

              {hasRoles(user, [ROLE_ADMIN], true) && (
                <Tab
                  eventKey={TabsKey.APPLICATION_LOGO}
                  title={t("SystemSettings.applicationLogo")}
                >
                  <br />
                  <ApplicationLogo tabKey={key} />
                </Tab>
              )}
              {hasRoles(user, [ROLE_ADMIN], true) && (
                <Tab
                  eventKey={TabsKey.APPLICATIONS}
                  title={t("admin.applications.title")}
                >
                  <br />
                  <Applications tabKey={key} />
                </Tab>
              )}
              {hasRoles(user, [ROLE_ADMIN], true) && (
                <Tab eventKey={TabsKey.SYNCRONIZATION} title="Sincronização">
                  <br />
                  <Syncronization tabKey={key} sync={true} />
                </Tab>
              )}
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </ProtectedPage>
  );
};

export default withNamespaces()(SystemSettings);
