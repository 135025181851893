export const PERSIST_ORG_ADMIN = "registerOrgAdmin@PERSIST_ORG_ADMIN";
export const PERSIST_ORG_ADMIN_DONE = "registerOrgAdmin@PERSIST_ORG_ADMIN_DONE";
export const SET_TEMPORARY_USER = "registerOrgAdmin@SET_TEMPORARY_USER";

export const VALIDATE_ORG_ADMIN = "registerOrgAdmin@VALIDATE_ORG_ADMIN";
export const VALIDATE_ORG_ADMIN_DONE = "registerOrgAdmin@VALIDATE_ORG_ADMIN_DONE";

export const persistOrgAdmin = (payload) => ({ type: PERSIST_ORG_ADMIN, payload });
export const persistOrgAdminDone = (redirect) => ({ type: PERSIST_ORG_ADMIN_DONE, redirect });
export const setTemporaryUser = (user) => ({ type: SET_TEMPORARY_USER, user });

export const validateOrgAdmin = (payload, redirect) => ({ type: VALIDATE_ORG_ADMIN, payload, redirect});
export const validateOrgAdminDone = (redirect) => ({ type: VALIDATE_ORG_ADMIN_DONE, redirect });