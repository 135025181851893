import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  FIND_IFLOW_PROCESSES_BY_FILTERS,
  FIND_IFLOW_PROCESSES_HISTORY,
  FIND_IFLOW_PROCESSES_COUNT,
  GET_IFLOW_FORMDATA,
  POST_IFLOW_FORMDATA,
  GET_IFLOW_DOCUMENT,
  SUBSTITUTE_IFLOW_DOCUMENT,
  GET_IFLOW_METADATA_PREVIEW,
  POST_IFLOW_FORM,
} from "../ducks/processes/actionTypes";

import {
  findIflowProcessesByFilters,
  findIflowProcessesHistory,
  findIflowProcessesCount,
  getIflowFormdata,
  postIflowFormData,
  getIflowDocument,
  substituteIflowDocument,
  getIflowMetadataPreview,
  postIflowForm,
} from "../ducks/processes/sagas";

export const processesSagas = [
  takeLatest(FIND_IFLOW_PROCESSES_BY_FILTERS, findIflowProcessesByFilters),
  takeLatest(FIND_IFLOW_PROCESSES_HISTORY, findIflowProcessesHistory),
  takeLatest(FIND_IFLOW_PROCESSES_COUNT, findIflowProcessesCount),
  takeLatest(GET_IFLOW_FORMDATA, getIflowFormdata),
  takeLatest(POST_IFLOW_FORMDATA, postIflowFormData),
  takeLatest(POST_IFLOW_FORM, postIflowForm),
  takeEvery(GET_IFLOW_DOCUMENT, getIflowDocument),
  takeEvery(SUBSTITUTE_IFLOW_DOCUMENT, substituteIflowDocument),
  takeLatest(GET_IFLOW_METADATA_PREVIEW, getIflowMetadataPreview),
];
