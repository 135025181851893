import React, { useEffect, useState } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_option.scss";
import { useDispatch, useSelector } from "react-redux";
import TableTextbox from "./TableTextbox";

const ProposalCostsTable = ({ info, workplanIndex ,percentage,initialCosts,newTotalCosts}) => {
  const dispatch = useDispatch();

  const { workplans, providers, departments, expenditureTypes, triggerLoading, } = useSelector(
    (state) => state.budgetManagementReducer
  );
  var [currentCostsList, setCurrentCostsList] = useState([]);

  var [totalPC, setTotalPC] = useState(0);
  var [totalMargemSem, setTotalMargemSem] = useState(0);
  var [totalPV, setTotalPV] = useState(0);
  var [totalVV, setTotalVV] = useState(0);
  var [totalMargem, setTotalMargem] = useState(0);

  var currentPrecoCompra = 0;
  var currentMargemSem = 0;
  var currentPrecoVenda = 0;
  var currentValorVenda = 0;
  var currentMargem = 0;

  function convertIdToName(id, name) {
    if (name === "providers") {
      if (
        providers?.length === 0 ||
        providers === null ||
        providers === undefined
      )
        return "";
      const prov = providers.find((x) => x.idFornecedor === parseInt(id));
      if (prov != null) return prov?.nome;
    } else if (name === "expenditureTypes") {
      const exp = expenditureTypes.find(
        (x) => x.idTipoDespesa === parseInt(id)
      );
      if (exp != null) return exp?.nome;
    } else if (name === "departments") {
      if (
        departments?.length === 0 ||
        departments === null ||
        departments === undefined
      )
        return "";
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    }
  }

  useEffect(() => {
    var newCostsArray = [];

    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].despesas
                .length;
              m++
            ) {
              var currentCosts =
              global.structuredClone( workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .despesas[m]);
              currentCosts.item = i + 1;
              currentCosts.activity = j + 1;
              currentCosts.option = k + 1;
              if(newTotalCosts != null && newTotalCosts != 0 && newTotalCosts != "" && newTotalCosts != "0")
              {
                const newPercentage = newTotalCosts/initialCosts
                currentCosts.valorVendaDesp = currentCosts.valorVendaDesp * newPercentage
                currentCosts.precoVendaDesp = currentCosts.valorVendaDesp
                currentCosts.precoCompraForn = currentCosts.valorVendaDesp * (1 - (currentCosts.margemDesp/100))
              }
              else if(percentage != null && percentage != 0 && percentage != "" && percentage != "0")
              {
                currentCosts.valorVendaDesp = currentCosts.valorVendaDesp * percentage
                currentCosts.precoVendaDesp = currentCosts.precoVendaDesp
                currentCosts.precoCompraForn = currentCosts.precoVendaDesp * (1 - (currentCosts.margemDesp/100))
              }
              newCostsArray.push(currentCosts);
              currentPrecoCompra += parseFloat(
                currentCosts.precoCompraForn
              );
              currentMargemSem += parseFloat(
                currentCosts.margemSemPrecoVendaDesp
              );
              currentPrecoVenda += parseFloat(
                currentCosts.precoVendaDesp
              );
              currentValorVenda += parseFloat(
                currentCosts.valorVendaDesp
              );
              currentMargem += parseFloat(
                currentCosts.margemDesp
              );
            }
          }
        }
      }
    }
    if (newCostsArray.length === 0) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .despesas.length;
                m++
              ) {
                var currentCosts =
                global.structuredClone( workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .despesas[m]);
                currentCosts.item = i + 1;
                currentCosts.activity = j + 1;
                currentCosts.option = k + 1;
                if(newTotalCosts != null && newTotalCosts != 0 && newTotalCosts != "" && newTotalCosts != "0")
                {
                  const newPercentage = newTotalCosts/initialCosts
                  currentCosts.valorVendaDesp = currentCosts.valorVendaDesp * newPercentage
                  currentCosts.precoVendaDesp = currentCosts.valorVendaDesp
                  currentCosts.precoCompraForn = currentCosts.valorVendaDesp * (1 - (currentCosts.margemDesp/100))
                }
                else if(percentage != null && percentage != 0 && percentage != "" && percentage != "0")
                {
                  currentCosts.valorVendaDesp = currentCosts.valorVendaDesp * percentage
                  currentCosts.precoVendaDesp = currentCosts.valorVendaDesp
                  currentCosts.precoCompraForn = currentCosts.valorVendaDesp * (1 - (currentCosts.margemDesp/100))
                }
                newCostsArray.push(currentCosts);
                currentPrecoCompra += parseFloat(
                  currentCosts.precoCompraForn
                );
                currentMargemSem += parseFloat(
                  currentCosts.margemSemPrecoVendaDesp
                );
                currentPrecoVenda += parseFloat(
                  currentCosts.precoVendaDesp
                );
                currentValorVenda += parseFloat(
                  currentCosts.valorVendaDesp
                );
                currentMargem += parseFloat(
                  currentCosts.margemDesp
                );
              }
            }
          }
        }
      }
    }

    setTotalPC(currentPrecoCompra);
    setTotalMargemSem("");
    setTotalPV(currentPrecoVenda);
    setTotalVV(currentValorVenda);
    setTotalMargem((((currentValorVenda - currentPrecoCompra)/currentValorVenda)*100).toFixed(2));

    setCurrentCostsList(newCostsArray);
  }, [currentCostsList.length === 0,workplans,triggerLoading]);

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".")
    const finalString = newArray[0].replace(",",".") + "," + newArray[1]
    return finalString;
  }

  function getPosition(index) {
    if (index === 0) return "start";
    if (index === supplierList.length - 1) return "end";
  }

  const supplierList = [
    "Atividade Principal",
    "Atividade Secundária",
    "Opção",
    "Tipo de Despesa",
    "Fronecedor",
    "Departamento",
    "Descrição",
    "Preço de Compra",
    "Margem S/ Preço Venda",
    "Preço de Venda",
    "Valor de Venda",
    "Margem",
  ];

  return (
    <div className="liftworld-proposal-tables-wrapper">
      <div className="lifworld-table-wrapper">
        <div className="liftworld-table-header-wrapper">
          {supplierList &&
            Object.keys(supplierList).length !== 0 &&
            supplierList.map((item, index) => {
              return (
                <>
                  {index < 3 ? (
                    <div className="liftworld-table-3-wrapper">
                      <div
                        className={
                          `${"liftworld-table-header-element"} ` +
                          getPosition(index)
                        }
                      >
                        {item}
                      </div>
                    </div>
                  ) : index === 4 || index === 6 ? (
                    <div className="liftworld-table-13-wrapper">
                      <div
                        className={
                          `${"liftworld-table-header-element"} ` +
                          getPosition(index)
                        }
                      >
                        {item}
                      </div>
                    </div>
                  ) : (
                    <div className="liftworld-table-9-wrapper">
                      <div
                        className={
                          `${"liftworld-table-header-element"} ` +
                          getPosition(index)
                        }
                      >
                        {item}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
        <div>
          {currentCostsList &&
            Object.keys(currentCostsList).length !== 0 &&
            currentCostsList.map((item, index) => {
              return (
                <div className="liftworld-table-header-wrapper">
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.item}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.activity}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-3-wrapper">
                    <TableTextbox
                      value={item?.option}
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={convertIdToName(
                        item?.tipoDespesa,
                        "expenditureTypes"
                      )}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-13-wrapper">
                    <TableTextbox
                      value={convertIdToName(item?.fornecedorDesp, "providers")}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={convertIdToName(
                        item?.departamentoDesp,
                        "departments"
                      )}
                      position={"middle"}
                    />
                  </div>
                  <div className="liftworld-table-13-wrapper">
                    <TableTextbox value={item?.descricao} position={"middle"} />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.precoCompraForn)) ||
                        item?.precoCompraForn === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.precoCompraForn).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.margemSemPrecoVendaDesp)) ||
                        item?.margemSemPrecoVendaDesp === undefined
                          ? "0,00 %"
                          : numberWithCommas(parseFloat(item?.margemSemPrecoVendaDesp).toFixed(
                              2
                            )) + "%"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.precoVendaDesp)) ||
                        item?.precoVendaDesp === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.precoVendaDesp).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.valorVendaDesp)) ||
                        item?.valorVendaDesp === undefined
                          ? "0,00 €"
                          : numberWithCommas(
                              parseFloat(item?.valorVendaDesp).toFixed(2)
                            ) + "€"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                  <div className="liftworld-table-9-wrapper">
                    <TableTextbox
                      value={
                        isNaN(parseFloat(item?.margemDesp)) ||
                        item?.margemDesp === undefined
                          ? "0,00 %"
                          : numberWithCommas(parseFloat(item?.margemDesp).toFixed(2)) + "%"
                      }
                      position={"middle"}
                      number={"number"}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"start-end"} color={"colored"} />
          </div>
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-3-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-13-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-13-wrapper">
            <TableTextbox value={""} position={"middle"} color={"colored"} />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalPC)) || totalPC === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(totalPC)?.toFixed(2)) + "€"
              }
              position={"middle"}
              color={"colored"}
              number={"number"}
            />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={""}
              position={"middle"}
              color={"colored"}
              number={"number"}
            />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalPV)) || totalPV === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(totalPV)?.toFixed(2)) + "€"
              }
              position={"middle"}
              color={"colored"}
              number={"number"}
            />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalVV)) || totalVV === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(totalVV)?.toFixed(2)) + "€"
              }
              position={"middle"}
              color={"colored"}
              number={"number"}
            />
          </div>
          <div className="liftworld-table-9-wrapper">
            <TableTextbox
              value={
                isNaN(parseFloat(totalMargem)) || totalMargem === undefined
                  ? "0,00 %"
                  : numberWithCommas(parseFloat(totalMargem)?.toFixed(2)) + "%"
              }
              position={"end"}
              color={"colored"}
              number={"number"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProposalCostsTable;
