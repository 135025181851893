import React, { useState, useEffect } from "react";
import { Col, Form, Row, Alert } from "react-bootstrap";
import CreateSearchableDropdown from "../CreateSearchableDropdown";
import CreateTextArea from "../CreateTextArea";
import CreateDateInput from "../CreateDateInput";
import CreateTextInput from "../CreateTextInput";
import CreateButtonsFromSelection from "../CreateButtonsFromSelection";
import uuid from "uuid/v1";

const CreateCalculosComparativoConsulta = (props) => {
  const { blockdivision,styleSheet } = props;

  const [dtValidadeLicComp, setFormData2] = useState("");
  const [ma_observacoes, setFormData3] = useState("");
  const [orcClienteComp, setFormData4] = useState("");
  const [orcAceiteComp, setFormData5] = useState("");
  const [numLicComp, setFormData6] = useState("");
  const [empreiteiroComp, setFormData7] = useState("");
  const [fracaoRai, setFormData9] = useState("");
  const [seccao, setFormData10] = useState("");
  const [valorAntesObrasComp, setFormData11] = useState(0);
  const [percObraRealizadaComp, setFormData12] = useState(0);
  const [areaBruta, setFormData13] = useState("");
  const [SeguroEncargoPerc, setFormData14] = useState("");
  const [totalParcial, setFormData15] = useState("");
  const [totalCustoConstrucao, setFormData16] = useState("");
  const [totalTerreno, setFormData17] = useState("");
  const [vValorComercial, setFormData18] = useState("");
  const [vSeguro, setFormData19] = useState("");
  const [ivadoptado, setFormData20] = useState("");
  const [ltv, setFormData21] = useState("");
  const [obrasArealizar, setFormData22] = useState("");
  const [valorActual, setFormData23] = useState("");

  const [tableComparativo, setTable1] = useState("");
  const [tableTerreno, setTable2] = useState("");
  const [textSize, setTextSize] = useState("")
  const [tableSize, setTableSize] = useState("")
  useEffect(() => {
    if (
      styleSheet?.zoomIn &&
      styleSheet?.zoomInTextSize
    ) {
      setTextSize(`${styleSheet?.zoomInTextSize}`)
    }
    if (
      styleSheet?.zoomIn &&
      styleSheet?.tablefontsize
    ) {
      setTableSize(`${styleSheet?.tablefontsize}`)
    }
    
  }, [styleSheet]);
  useEffect(() => {
    if (blockdivision) {
      //find form data
      let dtValidadeLicComp;
      let orcClienteComp;
      let orcAceiteComp;
      let numLicComp;
      let empreiteiroComp;
      let aplicarTodas;
      let fracaoRai;
      let seccao;
      let tableComparativo;
      let tableTerreno;
      let valorAntesObrasComp;
      let percObraRealizadaComp;
      let ma_observacoes;
      let areaComp;
      let SeguroEncargoPerc;
      let totalParcial;
      let totalCustoConstrucao;
      let totalTerreno;
      let vValorComercial;
      let vSeguro;
      let ivadoptado;
      let ltv;
      let obrasArealizar;
      let valorActual;

      const setValue = (f) => {
        if (f?.variable == "dtValidadeLicComp") dtValidadeLicComp = f;
        if (f?.variable == "orcClienteComp") orcClienteComp = f;
        if (f?.variable == "orcAceiteComp") orcAceiteComp = f;
        if (f?.variable == "numLicComp") numLicComp = f;
        if (f?.variable == "empreiteiroComp") empreiteiroComp = f;
        if (f?.variable == "valorAntesObrasComp") {
          valorAntesObrasComp = f;
        }
        if (f?.variable == "percObraRealizadaComp") percObraRealizadaComp = f;
        if (f?.variable == "ma_observacoes") ma_observacoes = f;
        if (f?.variable == "areaComp") areaComp = f;
        if (f?.variable == "SeguroEncargoPerc") SeguroEncargoPerc = f.value;

        if (f?.variable == "aplicarTodas") aplicarTodas = f;
        if (f?.variable == "fracaoRai") fracaoRai = f;
        if (f?.variable == "seccao") seccao = f;

        if (f?.text == "Total Parcial") totalParcial = f.value;
        if (f?.text == "CC – Total Custo de construção")
          totalCustoConstrucao = f.value;
        if (f?.text == "Total Terreno") totalTerreno = f.value;
        if (f?.text == "V – Valor Comercial") vValorComercial = f.value;
        if (f?.text == "Valor de Seguro (€) (CC+E)") vSeguro = f.value;
        if (f?.text == "IV adoptado") ivadoptado = f.value;
        if (f?.text == "LTV") ltv = f.value;
        if (f?.text == "Obras a realizar necessitam de Licença Camararia")
          obrasArealizar = f.value;
        if (f?.text == "Valor Actual ( xx% de obra )") valorActual = f.value;

        if (f?.type == "arraytable") {
          if (f?.row[0]?.col[0]?.value === "Estimativa do valor de mercado") {
            tableComparativo = f;
          }
          if (f?.row[0]?.col[0]?.value === "Terreno ( T )") {
            tableTerreno = f;
          }
        }
      };
      let blockdivision_ = JSON.parse(JSON.stringify(blockdivision));
      if (!Array.isArray(blockdivision_)) {
        blockdivision_ = [blockdivision_];
      }
      blockdivision.forEach((b) => {
        if (Array.isArray(b.columndivision)) {
          b.columndivision.forEach((c) => {
            if (Array.isArray(c.field)) {
              c.field.forEach((f) => {
                setValue(f);
              });
            } else {
              setValue(c.field);
            }
          });
        } else {
          if (Array.isArray(b.columndivision?.field)) {
            b.columndivision.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(b.columndivision.field);
          }
        }
      });

      setFormData2(dtValidadeLicComp);
      setFormData3(ma_observacoes);
      setFormData4(orcClienteComp);
      setFormData5(orcAceiteComp);
      setFormData6(numLicComp);
      setFormData7(empreiteiroComp);
      setFormData9(fracaoRai);
      setFormData10(seccao);
      setFormData11(valorAntesObrasComp);
      setFormData12(percObraRealizadaComp);
      setFormData13(areaComp);
      setFormData14(SeguroEncargoPerc);
      setFormData15(totalParcial);
      setFormData16(totalCustoConstrucao);
      setFormData17(totalTerreno);
      setFormData18(vValorComercial);
      setFormData19(vSeguro);
      setFormData20(ivadoptado);
      setFormData21(ltv);
      setFormData22(obrasArealizar);
      setFormData23(valorActual);

      setTable1(tableComparativo);
      setTable2(tableTerreno);
    }
  }, [blockdivision]);

  const renderTableComparativo = () => {
    if (tableComparativo && Array.isArray(tableComparativo.row)) {
      return tableComparativo.row.map((r, index) => {
        if (index != 0 && index != 1) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  let style =
                    i !== 0 ? { textAlign: "end" } : { textAlign: "start" };
                  if (i == 4 || i == 6) {
                    return (
                      <td key={i} style={{ ...style, fontSize: `${textSize}px` }}>
                        {c.value ? `${c.value}€` : ""}
                      </td>
                    );
                  } else
                    return (
                      <td key={i} style={{ ...style, fontSize: `${textSize}px` }}>
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };

  const renderTableTerrenos = () => {
    if (tableTerreno && Array.isArray(tableTerreno.row)) {
      return tableTerreno.row.map((r, index) => {
        if (index != 0) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  let style =
                    i !== 0 ? { textAlign: "end" } : { textAlign: "start" };
                  if (i == 3) {
                    return (
                      <td key={i} style={{ ...style, fontSize: `${textSize}px` }}>
                        {c.value ? `${c.value}€` : ""}
                      </td>
                    );
                  } else
                    return (
                      <td key={i} style={{ ...style, fontSize: `${textSize}px` }}>
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };

  const fontSize = "1rem";
  const style1 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
    flexGrow: "1",
  };

  const style2 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
    justifyContent: "flex-end",
  };

  const style3 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style4 = {
    padding: "5px 5px 10px 0px",
    overflowWrap: "break-word",
    verticalAlign: "baseline",
    fontSize: `${textSize}px`,
  };

  const style5 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "start",
    paddingLeft: "0px",
  };

  const style6 = {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  };

  const style7 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "start",
    paddingLeft: "0px",
  };

  const style8 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style9 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "end",
    paddingRight: "0px",
    paddingLeft: "0px",
  };

  const style10 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
  };

  const style11 = {
    width: "34%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
  };

  const style12 = {
    width: "66%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
  };

  const renderLine = () => {
    return (
      <div style={{ width: "90%" }}>
        <hr />
      </div>
    );
  };

  const renderColumnContent = () => {
    return (
      <>
        <Form.Group as={Row} style={style1}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                Método
              </Form.Label>
              <Form.Label column style={{ ...style5, fontSize: `${textSize}px` }}>
                Comparativo,Rendimento,Reposição
              </Form.Label>
            </Form.Group>
          </Col>
        </Form.Group>

        {seccao && <CreateButtonsFromSelection field={seccao} fontSize={textSize} styleSheet={styleSheet} />}

        {fracaoRai && (
          <CreateSearchableDropdown field={fracaoRai} key={"fracaoRai"} fontSize={textSize} styleSheet={styleSheet} />
        )}

        <Form.Group key={uuid()} style={style1}>
          <Form.Label column style={{ flexGrow: "1" }}>
            <h4 style={{ fontSize: `${textSize}px` }}>Método comparativo de mercado</h4> <hr />
          </Form.Label>
        </Form.Group>
        <Alert variant="info">
          O método comparativo de mercado tem por referência os valores de
          transacção de imóveis semelhantes e comparáveis ao imóvel objecto de
          análise, obtidos através de prospecção de mercado realizada na mesma
          localização. Após a recolha da informação que sustentará a estimativa
          de valor, terá de existir uma classificação da mesma de acordo com os
          itens (localização, estado de conservação, nível de acabamentos,
          áreas, ...) que se coadunam com o tipo de propriedade a avaliar.
        </Alert>

        <table style={{ width: "100%" }} className="bordertableMCCR">
          <thead>
            <tr style={{ fontWeight: "bold" }}>
              <td
                id="66_1_1"
                colSpan="5"
                style={{
                  fontSize: `${textSize}px`,
                  paddingBottom: "10px",
                  textAlign: "center",
                }}
              >
                Estimativa do valor de mercado
              </td>
              <td
                id="66_1_2"
                colSpan="2"
                style={{
                  fontSize: `${textSize}px`,
                  paddingBottom: "10px",
                  textAlign: "center",
                }}
              >
                Valor para Seguro
              </td>
            </tr>
            <tr style={{ paddingBottom: "10px" }}>
              <th
                id="66_2_1"
                style={{ ...style4, textAlign: "start", width: "12%" }}
              >
                Designação
              </th>
              <th
                id="66_2_2"
                style={{ ...style4, textAlign: "end", width: "14%" }}
              >
                Área (m2)
              </th>
              <th
                id="66_2_3"
                style={{ ...style4, textAlign: "end", width: "16%" }}
              >
                Valor Unitário (€/m2)
              </th>
              <th
                id="66_2_4"
                style={{ ...style4, textAlign: "end", width: "14%" }}
              >
                Coef. Depreciativo
              </th>
              <th
                id="66_2_5"
                style={{ ...style4, textAlign: "end", width: "14%" }}
              >
                Totais Parciais (€)
              </th>
              <th
                id="66_2_6"
                style={{ ...style4, textAlign: "end", width: "15%" }}
              >
                Custo Unitário (€/m2)
              </th>
              <th
                id="66_2_7"
                style={{ ...style4, textAlign: "end", width: "15%" }}
              >
                Totais Parciais (€)
              </th>
            </tr>
          </thead>

          <tbody>{renderTableComparativo()}</tbody>
        </table>
        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.46", padding: "0" }}
          >
            <Form.Group as={Row} style={style2}>
            <div style={{...style11, marginRight:"5px", paddingRight:"5px"}}>
                <Form.Group as={Row} style={{ ...style10, width: "100%" }}>
                  <Form.Label
                    column
                    style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                  >
                    Total Parcial
                  </Form.Label>
                  <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
                    {totalParcial ? `${totalParcial}€` : ""}
                  </Form.Label>
                </Form.Group>
                {renderLine()}
              </div>

              <div style={style12}>
                <Form.Group
                  as={Row}
                  style={{ ...style10, paddingLeft: "10px", width: "100%" }}
                >
                  <Form.Label
                    column
                    style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                  >
                    CC – Total Custo de construção
                  </Form.Label>
                  <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
                    {totalCustoConstrucao ? `${totalCustoConstrucao}€` : ""}
                  </Form.Label>
                </Form.Group>
                {renderLine()}
              </div>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style3}>
          <table style={{ width: "70%", marginTop: "7px" }} className="bordertableMCCR">
            <thead>
              <tr>
                <th
                  id="83_1_1"
                  style={{ ...style4, width: "17%", textAlign: "start" }}
                >
                  Terreno ( T )
                </th>
                <th
                  id="83_1_2"
                  style={{ ...style4, width: "20%", textAlign: "end" }}
                >
                  Área (m2)
                </th>
                <th
                  id="83_1_3"
                  style={{ ...style4, width: "22%", textAlign: "end" }}
                >
                  Valor Unitário (€/m2)
                </th>
                <th
                  id="83_1_4"
                  style={{ ...style4, width: "40%", textAlign: "end" }}
                >
                  Totais Parciais (€)
                </th>
              </tr>
            </thead>

            <tbody>{renderTableTerrenos()}</tbody>
          </table>

          <div style={style6}>
          <Form.Group as={Row} style={{ width: "100%", margin: "0px", marginLeft: "20px" }}>
              <Form.Group
                as={Row}
                style={{
                  justifyContent: "space-between",
                  flexGrow: "1",
                  margin: "0px",
                }}
              >
                <Form.Label
                  id="label_mccr_percE"
                  column
                  style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                >
                  E - Encargos (%)
                </Form.Label>
                <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
                  {SeguroEncargoPerc ? `${SeguroEncargoPerc}%` : ""}
                </Form.Label>
              </Form.Group>
            </Form.Group>
            {renderLine()}
          </div>
        </Form.Group>

        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.46", padding: "0" }}
          >
            <Form.Group as={Row} style={style2}>
              <div style={style11}>
                <Form.Group as={Row} style={{ ...style10, width:"93%"}}>
                  <Form.Label
                    column
                    style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                  >
                    Total Terreno
                  </Form.Label>
                  <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
                    {totalTerreno ? `${totalTerreno}€` : ""}
                  </Form.Label>
                </Form.Group>
                {renderLine()}
              </div>

              <div style={style12}>
                <Form.Group
                  as={Row}
                  style={{ ...style10, width: "93%", marginLeft:"7px" }}
                >
                  <Form.Label
                    column
                    style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                  >
                    Valor de Seguro (&euro;) (CC+E)
                  </Form.Label>
                  <Form.Label
                    id="valorSeguro"
                    column
                    style={{ ...style9, fontSize: `${textSize}px` }}
                  >
                    {vSeguro ? `${vSeguro}€` : ""}
                  </Form.Label>
                </Form.Group>
                {renderLine()}
              </div>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.46", padding: "0" }}
          >
            <Form.Group
              as={Row}
              style={{ ...style10, width: "33%", marginRight: "-10px"}}
            >
              <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px`, paddingLeft:'28px' }}>
                V &ndash; Valor Comercial
              </Form.Label>
              <Form.Label column style={{ ...style9, fontSize: `${textSize}px` }}>
                {vValorComercial ? `${vValorComercial}€` : ""}
              </Form.Label>
            </Form.Group>
          </Col>
        </Form.Group>
        <br />

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {areaBruta && (
              <CreateTextInput
                id="areaBruta"
                field={areaBruta}
                key={areaBruta.variable}
                fontSize={textSize}
              />
            )}

            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                IV adoptado
              </Form.Label>
              <Form.Label column style={{ ...style7, fontSize: `${textSize}px` }}>
                {ivadoptado}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                LTV
              </Form.Label>
              <Form.Label column style={{ ...style7, fontSize: `${textSize}px` }}>
                {ltv ? `${ltv}%` : ""}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} style={{ ...style3, paddingLeft: "15px" }}>
              <Alert variant="info">Montante de Crédito</Alert>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {orcClienteComp && (
              <CreateTextInput
                field={orcClienteComp}
                key={orcClienteComp.variable}
                fontSize={textSize}
              />
            )}
            {orcAceiteComp && (
              <CreateTextInput
                field={orcAceiteComp}
                key={orcAceiteComp.variable}
                fontSize={textSize}
              />
            )}

            {empreiteiroComp && (
              <CreateTextInput
                field={empreiteiroComp}
                key={"empreiteiroComp"}
                fontSize={textSize}
              />
            )}

            <Form.Group as={Row} style={style1}>
              <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
                <Form.Group as={Row} style={style3}>
                  <Form.Label
                    column
                    style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
                  >
                    Obras a realizar necessitam de Licença Camararia
                  </Form.Label>
                  <Form.Label column style={{ ...style5, fontSize: `${textSize}px` }}>
                    {obrasArealizar}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Form.Group>

            {numLicComp && (
              <CreateTextInput field={numLicComp} key={"numLicComp"} fontSize={textSize}/>
            )}

            {dtValidadeLicComp && (
              <CreateDateInput
                field={dtValidadeLicComp}
                key={"dtValidadeLicComp"}
                fontSize={textSize}
              />
            )}

            {valorAntesObrasComp && (
              <CreateTextInput
                id={"valorAntesObrasComp"}
                field={valorAntesObrasComp}
                key={"valorAntesObrasComp"}
                fontSize={textSize}
              />
            )}

            {percObraRealizadaComp && (
              <CreateTextInput
                id={"percObraRealizadaComp"}
                field={percObraRealizadaComp}
                key={"percObraRealizadaComp"}
                fontSize={textSize}
              />
            )}

            <Form.Group
              as={Row}
              style={{
                flexWrap: "nowrap",
                margin: "0px",
              }}
            >
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                Valor Actual ( xx% de obra )
              </Form.Label>
              <Form.Label column style={{ ...style7, fontSize: `${textSize}px` }}>
                {valorActual ? `${valorActual}€` : ""}
              </Form.Label>
            </Form.Group>

            {ma_observacoes && (
              <CreateTextArea field={ma_observacoes} key={"ma_observacoes"} />
            )}
          </Col>
        </Form.Group>
      </>
    );
  };

  return (
    <Row
      key={`blockdivision_comparativo`}
      style={{ width: "100%", flexWrap: "nowrap", margin: "0px" }}
    >
      <Col key={`columndivision_comparativo`} style={{ flexGrow: "1" }}>
        {renderColumnContent()}
      </Col>
    </Row>
  );
};
export default CreateCalculosComparativoConsulta;
