import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import uuid from "uuid/v1";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import { hasRoles } from "~/components/security/utils";
import { Accordion } from "./Accordion";
import {
  setProcessFormMenuItemClicked,
  setProcessFormUrl,
} from "~/containers/Layout/actions";
import { getLoginsByUsername } from "~/store/ducks/webTimeWidget/actionTypes";
import { generateTreeMenu } from "~/containers/Menus_v2/utils";
import { closeSideBar } from "~/store/ducks/applicationMenu/actionTypes";
import { ROLE_ADMIN } from "~/utils/constants/roles";
import BackofficeMenuItem from "./BackofficeMenuItem";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { FaTasks, FaProjectDiagram, FaUsers, FaTools } from "react-icons/fa";
import { PERMISSION_BPM_ADMIN } from "~/utils/constants/permissions";

const ApplicationMenuItem = ({ t, showMenu, isExternalPage = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const treeMenu = generateTreeMenu(menu);
  const { user } = useSelector((state) => state.globalReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const [selected, setSelected] = useState("");
  const [subMenusIcon, setSubMenusIcon] = useState({});

  const [showSpinner, setShowSpinner] = useState(true);

  const [styleSheet, setFormStyleSheet] = useState("");
  const [iflowtranslations, setIflowTranslations] = useState("");
  const [translationsSubMenus, settranslationsSubMenus] = useState("");
  const [iconArrow, seticonArrow] = useState(false);

  const usernamePortalRh = user?.legacyUsers?.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  )?.username;

  useEffect(() => {
    dispatch(getLoginsByUsername(usernamePortalRh, false, "", "0"));
  }, []);

  const { logins, isLoadingLogin } = useSelector(
    (state) => state.webTimeDataReducer
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setSelected(history.location.pathname.split("/uniksystem/").pop());
  }, []);

  useEffect(() => {
    setSelected(decodeURI(window.location.href.split("/uniksystem/").pop()));
  }, [window.location.href]);

  useEffect(() => {
    if (menu) {
      let app = "";
      if (isExternalPage) {
        app = "bpm";
      } else {
        //find app from url
        const arr1 =
          window.location.href.includes("/uniksystem/") &&
          window.location.href.split("/uniksystem/");
        if (arr1.length > 1) {
          const arr2 = arr1[1];
          const arr3 = arr2.split("/");
          app = arr3[0];
        }
      }

      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      const menuItemDashboard = menu.find((item) => item.name === `OnBoarding`);

      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const styleSheet_ =
          extraConfiguration_ && extraConfiguration_.styleSheet;
        const iflowtranslations_ =
          extraConfiguration_ && extraConfiguration_.translations;
        //console.log(styleSheet_)
        setFormStyleSheet(styleSheet_);
        setIflowTranslations(iflowtranslations_);
        seticonArrow(styleSheet_?.collapseSubmenus);
      }
      if (menuItemDashboard) {
        const extraConfiguration_ = JSON.parse(
          menuItemDashboard.extraConfiguration || false
        );
        const styleSheet_ =
          extraConfiguration_ && extraConfiguration_.styleSheet;
        const iflowtranslations_ =
          extraConfiguration_ && extraConfiguration_.translations;
        const translations_ =
          extraConfiguration_ && extraConfiguration_.dashboard_translations;
        //console.log(styleSheet_)
        setFormStyleSheet(styleSheet_);
        settranslationsSubMenus(translations_);
        setIflowTranslations(iflowtranslations_);
        seticonArrow(styleSheet_?.collapseSubmenus);
      }
    }
  }, [menu]);

  const handleRoute = (route) => {
    if (route) {
      history.push(`/uniksystem/${route}`);
    }
  };

  const handleClickItem = (item) => {
    if (item.submenu)
      setSubMenusIcon((subMenusIcon) => ({
        [item.route]: !subMenusIcon[item.route],
      }));
    else setSubMenusIcon({});
    handleRoute(
      item.mainMenu
        ? item.name !== "uniksystem"
          ? item.name
          : null
        : item.route
    );
    dispatch(setProcessFormUrl(item.url)); //used by ProcessosFormHandler
    dispatch(setProcessFormMenuItemClicked(true)); //used by ProcessosFormHandler
    seticonArrow(!iconArrow);
    const location = {
      pathname: `${url}/${item.route}`,
      state: { name: item.name },
    };

    /*Send Context to iframe*/
    const sendUserContext = () => {
      let config = configuration?.loginPage;
      const context = {
        theme:
          config === "nblp"
            ? "nblp"
            : userSettings.theme
            ? userSettings.theme
            : "default",
        language: userSettings.language ? userSettings.language : "EN",
        location: url.includes("portalrh") ? item.url : location,
      };
      if (
        context.location !== undefined &&
        context.location !== null &&
        context.location !== ""
      ) {
        let event = new CustomEvent("UserContextEvent", { detail: context });

        if (document.getElementById("portalrh")) {
          if (document.getElementById("portalrh").contentWindow) {
            try {
              document
                .getElementById("portalrh")
                .contentWindow.dispatchEvent(event);
            } catch (e) {
              console.log(e);
            }
          }
        }
        if (document.getElementById("bpm")) {
          if (document.getElementById("bpm").contentWindow) {
            document.getElementById("bpm").contentWindow.dispatchEvent(event);
            console.log("after disptaching to bpm");
          }
        }

        if (document.getElementById("gdpr")) {
          if (document.getElementById("gdpr").contentWindow) {
            document.getElementById("gdpr").contentWindow.dispatchEvent(event);
            console.log("after disptaching to gdpr");
          }
        }
        if (document.getElementById("compliance")) {
          if (document.getElementById("compliance").contentWindow) {
            document
              .getElementById("compliance")
              .contentWindow.dispatchEvent(event);
            console.log("after disptaching to compliance");
          }
        }

        if (document.getElementById("gai")) {
          if (document.getElementById("gai").contentWindow) {
            document.getElementById("gai").contentWindow.dispatchEvent(event);
            console.log("after disptaching to gai");
          }
        }

        if (document.getElementById("rh")) {
          if (document.getElementById("rh").contentWindow) {
            document.getElementById("rh").contentWindow.dispatchEvent(event);
            console.log("after disptaching to rh");
          }
        }
      }
    };

    sendUserContext();

    if (window.innerWidth < 992) dispatch(closeSideBar());
  };

  const renderSubMenus = () => {
    let foundItem;
    /*Find exact selected menu item */
    treeMenu.forEach((item) => {
      if (item.route === selected) foundItem = item;
      else if (item.childrens) {
        item.childrens.forEach((subitem) => {
          if (subitem.route === selected) foundItem = item;
          else if (subitem.childrens) {
            subitem.childrens.forEach((subitem2) => {
              if (subitem2.route === selected) foundItem = item;
            });
          }
        });
      }
    });
    /*Find closest menu item , used in PortalRH*/
    if (!foundItem) {
      treeMenu.forEach((item) => {
        const sArr = selected.split("/");
        const iArr = item.route ? item.route.split("/") : [];
        if (sArr.length > 1 && iArr.length > 1) {
          const selected_ = sArr[0] + sArr[1];
          const item_ = iArr[0] + iArr[1];
          if (selected_ === item_) {
            foundItem = item;
          }
        }
        /*
        if(selected.includes(item.route)){
          const lastPart=selected.split(`${item.route}/`).pop();
          if (!lastPart.includes("/")) {
            foundItem = item;
          }  
        }*/
      });
    }

    const isSubmenuSelected = (item_, selected) => {
      let isSelected = false;
      treeMenu.forEach((item) => {
        if (item.childrens) {
          item.childrens.forEach((subitem) => {
            if (subitem.childrens && subitem.route === item_.route) {
              subitem.childrens.forEach((subitem2) => {
                if (subitem2.route === selected) isSelected = true;
              });
            }
          });
        }
      });
      return isSelected;
    };

    const translateTextMenu = (textHeader) => {
      let text = undefined;
      const language = userSettings?.language
        ? userSettings.language.trim()
        : "en";
      if (textHeader?.includes("::t::")) {
        let lingua = language;
        let splittext = textHeader.split("::t::");
        let texttoshow = splittext[0];
        let texttoadd = splittext[1];
        if (iflowtranslations) {
          text = iflowtranslations?.[lingua][texttoshow] + texttoadd;
        }
      } else {
        text = textHeader;
      }
      return text;
    };

    const subMenuTranslations = (child) => {
      const name = child.name;
      const language = userSettings?.language
        ? userSettings.language.trim()
        : "en";
      if (child.route.includes("processos")) return name;
      if (child.route.includes("bpm/delegacoes")) return t(name);
      if (child.route.includes("dashboard")) {
        const nameDashboard =
          translationsSubMenus != undefined || translationsSubMenus != ""
            ? translationsSubMenus[language][name]
              ? translationsSubMenus[language][name]
              : name
            : name;
        return nameDashboard;
      }
      return t("menu.portalrh.subMenus." + name);
    };

    if (foundItem && !isEmpty(foundItem.childrens)) {
      return (
        <div className="menu-div-lvl2">
          <div className="title">{t(`${foundItem.name}`)}</div>
          {foundItem.childrens.map((child, index) => {
            return !isEmpty(child.childrens) ? (
              <Accordion
                title={t(child.name)}
                show={styleSheet && styleSheet?.collapseSubmenus}
                icon={iconArrow}
              >
                {child.childrens.map((i, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleClickItem(i)}
                      className={`menu-item-wrap-lvl3 ${
                        i.route === selected && "selected-lvl3"
                      }`}
                    >
                      {i.name}
                    </div>
                  );
                })}
              </Accordion>
            ) : (
              <ProtectedComponent permissions={child.permissions} key={uuid()}>
                <div
                  className={`menu-item-wrap-lvl2 ${
                    (child.route === selected ||
                      ("bpm/delegacoes/gerir" === child.route &&
                        "bpm/delegacoes" === selected)) &&
                    "selected-lvl2"
                  }`}
                  key={index}
                  onClick={() => handleClickItem(child)}
                >
                  {translateTextMenu(subMenuTranslations(child))}
                </div>
              </ProtectedComponent>
            );
          })}
        </div>
      );
    }
  };

  const getSelected1Lvl = (item) => {
    if (item?.name.includes("processos") && !item.childrens) {
      //disable process menu if no children
      return "menu-item disabled";
    } else {
      //normal flow

      if (selected.includes("portalrh") && selected.includes("frame")) {
        if (item.childrens && item.childrens.length > 0) {
          if (item.childrens.some((c) => c.route === selected)) {
            return "menu-item selected";
          } else return "menu-item";
        } else return "menu-item";
      } else {
        if (item.route !== "" && selected !== "") {
          //check if dashboard route
          if (item.route !== "" && selected === item.route) {
            return "menu-item selected";
          } else {
            if (selected.includes(item.route) && item.route.includes("/"))
              return "menu-item selected";
            else if (selected.includes("portalrh")) {
              //check for native menus
              const sArr = selected.split("/");
              const iArr = item.route ? item.route.split("/") : [];
              if (sArr.length > 1 && iArr.length > 1) {
                const selected_ = sArr[0] + sArr[1];
                const item_ = iArr[0] + iArr[1];
                if (selected_ === item_) {
                  return "menu-item selected";
                } else return "menu-item";
              } else return "menu-item";
            } else if (selected.includes("dashboard")) {
              //check for native menus
              const sArr = selected.split("/");
              const iArr = item.route ? item.route.split("/") : [];
              if (sArr.length > 1 && iArr.length > 1) {
                const selected_ = sArr[0] + sArr[1];
                const item_ = iArr[0] + iArr[1];
                if (selected_ === item_) {
                  return "menu-item selected";
                } else return "menu-item";
              } else return "menu-item";
            } else return "menu-item";
          }
        } else return "menu-item";
      }
    }
  };

  /*render 1lvl menu, depends on Icon source*/
  const IconComponents = { FaTasks, FaProjectDiagram, FaUsers, FaTools };
  const render1lvlMenuByIcon = (item) => {
    const { icon } = item;
    const regex = /^Fa\w+/;
    if (regex.test(icon)) {
      let Component = IconComponents[icon];
      if (Component) {
        return (
          <>
            <div style={{ fontSize: "20px" }}>
              <Component />
            </div>
            <span style={{ padding: "0 5px" }}>
              {item.mainMenu
                ? t(`menu.mainItems.${item.name}`)
                : t(`${item.name}`)}
            </span>
          </>
        );
      }
    }
    return (
      <>
        <div className={item.icon} style={{ fontSize: "20px" }}></div>
        <span style={{ padding: "0 5px" }}>
          {item.mainMenu ? t(`menu.mainItems.${item.name}`) : t(`${item.name}`)}
        </span>
      </>
    );
  };

  const buildMenu = (items) => {
    if (showMenu) {
      return (
        <>
          {isLoadingLogin === "true" ? (
            <Spinner spinning={isLoadingLogin === "true"} />
          ) : (
            <>
              <div className="menu-wrapper">
                <div className="menu-div normal-colored">
                  {items.map((item, i) => {
                    if (item.nivel != 2 && item.nivel != 3) {
                      return item.route &&
                        item.route.includes("/backoffice") ? (
                        <ProtectedComponent
                          permissions={item.permissions}
                          key={uuid()}
                        >
                          <BackofficeMenuItem innuxLogin={logins} item={item} />
                        </ProtectedComponent>
                      ) : //Only renders routes with "orgadmin" when it's not a user with ROLE_ADMIN
                      !item?.route?.includes("orgadmin") ||
                        !hasRoles(user, [ROLE_ADMIN]) ? (
                        <ProtectedComponent
                          permissions={item.permissions}
                          key={uuid()}
                        >
                          <div
                            key={uuid()}
                            className={getSelected1Lvl(item)}
                            onClick={() => {
                              handleClickItem(item);
                            }}
                          >
                            <div className="menu-item-wrap">
                              {render1lvlMenuByIcon(item)}
                            </div>
                          </div>
                        </ProtectedComponent>
                      ) : null;
                    }
                  })}
                </div>
                {renderSubMenus()}
              </div>
            </>
          )}
        </>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80px",
            margin: "20px 0px",
          }}
        >
          {showSpinner && (
            <Spinner animation="grow" variant="secondary" size="sm" />
          )}
        </div>
      );
    }
  };

  return <>{treeMenu && buildMenu(treeMenu)}</>;
};

export default withNamespaces()(ApplicationMenuItem);
