import React from 'react';
import { isEmpty } from "lodash";

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { IconContext } from "react-icons";
import { IoMdHelpCircle } from "react-icons/io";
import ProtectedComponent from '../security/ProtectedComponent';
import { ROLE_ADMIN } from '~/utils/constants/roles';

const Helper = ({ data }) => {

  return (
    <ProtectedComponent roles={[ROLE_ADMIN]}>
      <IconContext.Provider value={{ size: '1.4em' }}>
        <div style={{ marginRight: 10, marginTop: 4 }}>
          <OverlayTrigger
            placement="left"
            overlay={
              <Popover id="popover-basic">
                <Popover.Content style={{ whiteSpace: 'pre-line' }}>
                  {
                    !isEmpty(data) && data.map((item, index) =>
                      <div key={index}>
                        <b>{`${item.key}: `}</b> {`${item.value}`}
                      </div>
                    )
                  }
                </Popover.Content>
              </Popover>
            }
          >
            <IoMdHelpCircle />
          </OverlayTrigger >
        </div>
      </IconContext.Provider>
    </ProtectedComponent>
  )
}

export default Helper;
