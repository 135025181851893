export const handleButtonClick = (operation) => {
  if (operation) {
    if (operation.includes("document.dados.buttonResult.value='")) {
      const array = operation.split("document.dados.buttonResult.value='")
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados.buttonResult.value = array2[0];
        }
      }
    }
    if (operation.includes("document.dados.botao.value='")) {
      const array = operation.split("document.dados.botao.value='")
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados.botao.value = array2[0];
        }
      }
    }

    if (operation.includes("document.dados.op.value='")) {
      const array = operation.split("document.dados.op.value='")
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados.op.value = array2[0];
        }
      }
    }

    if (operation.includes("document.dados.op.value=") && !operation.includes("document.dados.op.value='")) {
      const array = operation.split("document.dados.op.value=")
      if (array) {
        const array2 = array[1].split(";");
        if (array2) {
          document.dados.op.value = array2[0];
        }
      }
    }

    if (operation.includes("document.getElementById('_button_clicked_id').value='")) {
      const array = operation.split("document.getElementById('_button_clicked_id').value='")
      if (array) {
        const array2 = array[1].split("'");
        if (array2) {
          document.dados._button_clicked_id.value = array2[0];
        }
      }
    }
  }
};