import React from "react";
import { Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import "~/assets/css/icons.css";
import Dropdown from "~/components/Dropdown";

const SelectFilter = ({ ...props }) => {
  const { t, filter, onChange } = props;

  const options = filter.values.map((option) => {
    return { value: option, label: option, selected: false };
  });

  const handleSubmit = (value) => {
    onChange(filter.variable, value);
  };

  return (
    <Col lg="3">
      <Form.Label>{t(`taskPage.general.${filter.variable}`)}</Form.Label>
      <Dropdown list={options} handleSubmit={handleSubmit} emptyDefault={true} />
    </Col>
  );
};

export default withNamespaces()(SelectFilter);
