import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";

const ButtonsUpdate = ({ OnTakingBolean }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButton = () => {
    if (!isClicked) {
      //Fui ao BE buscar este valor
      const permissionObj = {
        status: "ACCEPTED",
        //ACCEPTED
        //REJECTED
        //PENDING
      };

      if (
        permissionObj !== null &&
        permissionObj !== undefined &&
        permissionObj.status === "ACCEPTED"
      ) {
        setIsClicked(!isClicked);
        OnTakingBolean(isClicked);
      }
    } else {
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
    }
  };

  return (
    <>
      {isClicked ? (
        <div className="UpdateButtons_PersonalInformation">
          <Button className="card-button" style={{ marginLeft: 15 }}>
            Save
          </Button>

          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={handleButton}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={handleButton}
          >
            Change
          </Button>
        </div>
      )}
    </>
  );
};

export default ButtonsUpdate;
