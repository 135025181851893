import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import Simulator from "./Simulators";
import NavTabs from "~/pages/PortalRH/components/NavTabs";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";

const NavBarSimulator = ({ t }) => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
  }, []);
  return (
    <>
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
        <Card.Header className="justify-content-between">
            <NavTabs
              titleMenu={t("simulatorMainPage.navBar.simulatorCompensationTermination")}
            />
          </Card.Header>
          <Card.Body>
                <Simulator />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withNamespaces()(NavBarSimulator);
