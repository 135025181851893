import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import "~/assets/css/icons.css";
import { getDocsDisclaimers, updateDocsDisclaimer } from "~/store/ducks/portalrh/actionTypes";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import Messages from "~/components/Messages";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import NavTabs from "../../../NavTabs";

const DocsDisclaimer = ({ t, portalRhMenus, activeSelected, designacao }) => {
  const { docsDisclaimers, isLoadingDocsDisclaimers, editingDocsDisclaimers } =
    useSelector((state) => state.portalrhReducer);

  const dispatch = useDispatch();

  const [disclaimerText, setDocsDisclaimerText] = useState("");

  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMenus({});
    /*close full screen iframes*/
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
  },[]);

  useEffect(() => {
    if (!editingDocsDisclaimers) {
      dispatch(getDocsDisclaimers());
    }
  }, [editingDocsDisclaimers]);

  useEffect(() => {
    if (docsDisclaimers[0]) {
      setDocsDisclaimerText(docsDisclaimers[0].text);
    }
  }, [docsDisclaimers]);

  const handleSaveDocsDisclaimer = () => {
    const newDocsDisclaimer = {
      id: docsDisclaimers[0].id,
      text: disclaimerText,
    };
    dispatch(updateDocsDisclaimer(newDocsDisclaimer));
  };

  return (
    <>
      {isLoadingDocsDisclaimers ? (
        <Spinner spinning={"true"} />
      ) : (
        <div className="main-card-v2" style={{ width: "100%" }}>
          <Card
            bsPrefix="card-flat"
            style={{ minHeight: "80vh", overflow: "auto" }}
          >
            <Card.Header className="justify-content-between">
              <NavTabs
                  titleMenu={t('portalrh.docsDisclaimer.title')}
                  menus={menus}
                  activeSelected={activeSelected}
                  containMenu={containMenu}
                  setIsLoading={setIsLoading}
                  t={t}
                />
            </Card.Header>
            <Card.Body>
              <Form.Row style={{ marginBottom: "20px" }}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder={t("portalrh.docsDisclaimer.title")}
                  value={disclaimerText}
                  onChange={(e) => setDocsDisclaimerText(e.target.value)}
                />
              </Form.Row>
              <Form.Row>
                <Button
                  variant="secondary"
                  onClick={() => setDocsDisclaimerText(docsDisclaimers[0].text)}
                >
                  {t("general.reset")}
                </Button>
                <Button
                  className="card-button"
                  style={{ marginLeft: "10px", marginRight: "55px" }}
                  onClick={() => handleSaveDocsDisclaimer()}
                >
                  {t("general.save")}
                </Button>
              </Form.Row>
              <Messages />
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};
export default withNamespaces()(DocsDisclaimer);