import React, { useEffect, useState } from "react";
import { Alert, Col, Form } from "react-bootstrap";
import { saveDocumentList } from "~/store/ducks/portalrh/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "~/components/Dropdown";
import { getOperations } from "~/store/ducks/portalrh/actionTypes";
import "../RhCollaborator.scss";
import { Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import FileInput from "~/components/FileInput";

const SUPPORTED_FILES_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  "application/x-zip-compressed",
  "text/plain",
];

const UploadDocuments = ({ isClicked, uploadFile, t, rowCollaborator, id }) => {
  const [documents, setDocuments] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [operationSelected, setOperationSelected] = useState("");
  const [list, setList] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();

  const { operations, isLoadingOperations } = useSelector(
    (state) => state.portalrhReducer
  );

  useEffect(() => {
    dispatch(getOperations());
  }, []);

  useEffect(() => {
    if (isClicked) {
      return setDisabled(false);
    }
    if (!isClicked) {
      return setDisabled(true);
    }
  }, [isClicked]);

  useEffect(() => {
    if (operations?.length > 0 && id) {
      for (let i = 0; i < operations.length; i++) {
        if (operations[i].id === id) {
          const newArray = operations[i].documentClassList.map(function (e) {
            return {
              label: e.name,
              value: e.id.toString(),
            };
          });
          setList(newArray);
        }
      }
    }
  }, [id, operations]);

  const handleOnChoose = (event) => {
    if (event.target.multiple === true) {
      const files_ = event.target.files;
      const files = [];
      for (let i = 0; i < files_.length; i++) {
        files.push(files_.item(i));
      }
      handleMultipleFiles(files);
    } else {
      handleMultipleFiles([event.target.files[0]]);
    }
  };

  const handleMultipleFiles = (files) => {
    if (files.length > 0) {
      if (files.some((file) => !SUPPORTED_FILES_TYPES.includes(file?.type))) {
        //   setWarningMessage(t("portalrh.documentManagement.checkFileType"));
        setWarningMessage("Verificar o tipo de ficheiro");
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), 10000);
      } else {
        const docs = [];
        files.forEach((f) => {
          docs.push({
            file: f,
          });
        });
        setDocuments(docs);
      }
    }
  };

  const docList = [
    {
      file: documents[0]?.file,
      comment: "",
      expirationDate: "",
      classId: operationSelected,
      operationId: id,
      targetUserIds: [],
      notificationId: 0,
      requestId: 0,
      numFuncionario: rowCollaborator.funcionarioNum,
      codEntidade: rowCollaborator.codEntidade,
      seen: false,
    },
  ];
  useEffect(() => {
    if (!uploadFile) {
      //console.log(docList, "documentSaved");
      //dispatch(saveDocumentList(docList, false));
    }
  }, [uploadFile]);

  const handleOperation = (e) => {
    setOperationSelected(e);
  };

  const handleSaveDocument = () => {
    dispatch(saveDocumentList(docList, false));
  };

  return (
    <>
      <div className="uploadDocument">
        <Col xl="3" md="4">
          <Form.Label className="rhcollaborator__labels">
            {t(`portalrh.personalData.DocumentType`)}
          </Form.Label>
          <Dropdown
            className={
              isClicked ? "collaboratoRH__Dropdown2" : "collaboratoRH__Dropdown"
            }
            list={list}
            handleSubmit={handleOperation}
            defaultValue={t(`portalrh.personalData.DocumentType`)}
            disabled={isClicked ? false : true}
          />
        </Col>
        <Col xl="3" md="4">
          <Form.Label className="rhcollaborator__labels">
            {t(`portalrh.personalData.AttachDocument`)}
          </Form.Label>
          <FileInput
            type="file"
            onChange={(e) => handleOnChoose(e)}
            btnText={t(`portalrh.personalData.ChooseFile`)}
            disabled={disabled}
          />
        </Col>

        {showWarning && (
          <Alert
            variant="warning"
            onClose={() => setShowWarning(false)}
            dismissible
          >
            <strong>Alert: </strong>
            {warningMessage}
          </Alert>
        )}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <>
          {documents.length > 0 ? (
            <Button
              type="submit"
              className="card-button"
              onClick={handleSaveDocument}
              style={{ marginLeft: 15 }}
            >
              {t(`portalrh.personalData.SaveDocument`)}
            </Button>
          ) : null}
        </>
      </div>
    </>
  );
};

export default withNamespaces()(UploadDocuments);
