import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ButtonDownload from "~/components/Buttons/DownloadButton";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import { getCrcDocument } from "~/store/ducks/onboarding/actionTypes";

/**
 * CRC Document Test
 *
 * This service tests an API from Infosistema that gets a CRC Document
 *
 * The response is shown in the output text area in JSON format
 * and through a download button to get the document in pdf format
 *
 */
const Test = ({ t }) => {
  const dispatch = useDispatch();

  const { isLoadingGetCrcDocument, getCrcDocumentResponse } = useSelector(
    (state) => state.onboardingReducer
  );

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if (
      Object.keys(getCrcDocumentResponse).length === 0 ||
      getCrcDocumentResponse === ""
    ) {
      setOutput("");
      setCrcDocument("");
      return;
    }
    if (!getCrcDocumentResponse.json) {
      setOutput(getCrcDocumentResponse);
      setCrcDocument("");
      return;
    }
    getCrcDocumentResponse.json !== output &&
      setOutput(JSON.stringify(getCrcDocumentResponse.json, null, 2));
    getCrcDocumentResponse.pdf !== crcDocument &&
      setCrcDocument(getCrcDocumentResponse.pdf);
  }, [getCrcDocumentResponse]);

  const [validated, setValidated] = useState(false);
  const [crcNumber, setCrcNumber] = useState("8456-7032-6204");
  const [clientId, setClientId] = useState("no_client");
  const [output, setOutput] = useState("");
  const [crcDocument, setCrcDocument] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      getCrcDocumentService();
    }
  };

  const getCrcDocumentService = () => {
    const payload = {
      crc: crcNumber,
      client_id: clientId,
    };
    dispatch(getCrcDocument(payload, apiSettings.id));
  };

  const downloadPdf = (filename = "CRC_Document") => {
    const linkSource = `data:application/pdf;base64,${crcDocument}`;
    const downloadLink = document.createElement("a");
    const fileName = `${filename}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Form.Row>
          <Col sm="12" md="8" lg="6">
            <Form.Group controlId="validationCrcNumber">
              <Form.Label>{t("onboarding.persist.crcNumber")}</Form.Label>
              <Form.Control
                type="text"
                autofill="false"
                required
                autoComplete="off"
                value={crcNumber}
                onChange={(e) => setCrcNumber(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requireCrcNumber")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCrcClientId">
              <Form.Label>Client Id</Form.Label>
              <Form.Control
                type="text"
                autofill="false"
                autoComplete="off"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          disabled={
            !user?.organization?.id || Object.keys(apiSettings).length === 0
          }
          className="card-button onboarding-button"
          type="submit"
        >
          {isLoadingGetCrcDocument ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            t("onboarding.general.test")
          )}
        </Button>
      </Form>
      <Output output={output} />
      <ButtonDownload
        text={t("onboarding.general.downloadPdf")}
        onClick={() => downloadPdf()}
        disabled={!crcDocument}
      />
    </div>
  );
};
export default withNamespaces()(Test);
