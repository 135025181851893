import {
  GET_DELEGACOES,
  SET_DELEGACOES,
  GET_USER_FLOWS,
  SET_USER_FLOWS,
  GET_APROVAL_DELEGACOES,
  SET_APROVAL_DELEGACOES,
  GET_SUCCESS,
  SET_SUCCESS,
  SET_USERS,
  SET_OWNERS,
  GET_NOTIFICATION,
  SET_NOTIFICATION,
  POST_DELEGATION,
  APROVE_DELEGATION,
  GET_DELEGACOES_BY_USERID,
  SET_DELEGACOES_BY_USERID,
  GET_REQUESTED_DELEGATION,
  SET_REQUESTED_DELEGATION,
} from "./actionTypes";

const initialState = {
  delegacoes: [],
  flows: [],
  aprovalDelegacoes: [],
  success: { value: false, caller: "" },
  users: [],
  owners: [],
  notification: [],
  isLoadingDelegacoes: false,
  isLoadingAprovalDelegacoes: false,
  isLoadingUserFlow: false,
  isLoadingNotification: false,
  isLoadingSuccess: false,
  isLoadingDelegacoesByUserid: false,
  isLoadingRequestedDelegation: false,
  delegacoesByUserid: [],
  userid: [],
};

export const delegacoesReducer = (state = initialState, action) => {
  switch (action.type) {
    case APROVE_DELEGATION:
      return {
        ...state,
        isLoadingSuccess: true,
      };
    case POST_DELEGATION:
      return {
        ...state,
        isLoadingSuccess: true,
      };
    case GET_DELEGACOES:
      return {
        ...state,
        isLoadingDelegacoes: true,
      };

    case SET_DELEGACOES:
      return {
        ...state,
        isLoadingDelegacoes: false,
        delegacoes: action.data,
      };
    case GET_USER_FLOWS:
      return {
        ...state,
        isLoadingUserFlow: true,
      };
    case SET_USER_FLOWS:
      return {
        ...state,
        isLoadingUserFlow: false,
        flows: action.data,
      };
    case GET_APROVAL_DELEGACOES:
      return {
        ...state,
        isLoadingAprovalDelegacoes: true,
      };
    case SET_APROVAL_DELEGACOES:
      return {
        ...state,
        isLoadingAprovalDelegacoes: false,
        aprovalDelegacoes: action.data,
      };

    case SET_SUCCESS:
      return {
        ...state,
        isLoadingSuccess: false,
        success: action.data,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.data,
      };
    case SET_OWNERS:
      return {
        ...state,
        owners: action.data,
      };
    // case GET_NOTIFICATION:
    //   return {
    //     ...state,
    //     isLoadingNotification: true,
    //   };
    case SET_NOTIFICATION:
      return {
        ...state,
        isLoadingNotification: false,
        notification: action.data,
      };

    case GET_DELEGACOES_BY_USERID:
      return {
        ...state,
        isLoadingDelegacoesByUserid: true,
      };

    case SET_DELEGACOES_BY_USERID:
      return {
        ...state,
        isLoadingDelegacoesByUserid: false,
        delegacoesByUserid: action.data,
      };

    case GET_REQUESTED_DELEGATION:
      return {
        ...state,
        isLoadingRequestedDelegation: true,
      };

    case SET_REQUESTED_DELEGATION:
      return {
        ...state,
        isLoadingRequestedDelegation: false,
        userid: action.data,
      };
    default:
      return state;
  }
};
