import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { differenceWith, isEqual, pull } from "lodash";

import * as actions from "./actions";
import { getPermissions, clearPermissions } from "../Permission/actions";

import { Card, Col, Table, Button, Form } from "react-bootstrap";

import PermissionTable from "~/components/permission/PermissionTable";
import Spinner from "~/components/Spinner";
import Messages from "~/components/Messages";

import LegacyRoleRow from "./components/LegacyRoleRow";
import LegacyRoleModal from "./components/LegacyRoleModal";

const PersistRole = (props) => {
  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState("");
  const [label, setLabel] = useState("");

  const [selectedLegacyApplication, setSelectedLegacyApplication] = useState(
    {}
  );

  const [legacyRoles, setLegacyRoles] = useState([]);
  const [legacyApplications, setLegacyApplications] = useState([]);

  const [showModalLegacyRole, setShowModalLegacyRole] = useState(false);

  const { legacyApps, role, isLoadingRole, isPersisting } = useSelector(
    (state) => state.roleReducer
  );

  const { permissions } = useSelector((state) => state.permissionReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = props.match.params;
  const { t } = props;
  const isEditing = !!id;

  useEffect(() => {
    return () => {
      dispatch(actions.clearRole());
      dispatch(clearPermissions());
    };
  }, []);

  useEffect(() => {
    dispatch(actions.loadLegacyApps());
    if (id) {
      dispatch(actions.getRoleById(id));
    }

    dispatch(getPermissions());
  }, [dispatch, id]);

  useEffect(() => {
    if (Object.keys(role).length > 0) {
      setDescription(String(role.description));
      setLabel(String(role.label));
      setLegacyRoles(role.legacyRoles);
      setLegacyApplications(role.legacyApplications);
    } else {
      setDescription("");
      setLabel("");
      setLegacyRoles([]);
      setLegacyApplications([]);
    }
  }, [role]);

  function openModal(legacyApp) {
    setSelectedLegacyApplication(legacyApp);
    setShowModalLegacyRole(true);
  }

  function closeModal() {
    setShowModalLegacyRole(false);
  }

  function availablePermissions() {
    return differenceWith(permissions, role.permissions, isEqual);
  }

  const addPermission = (permission) => {
    let _role = role;
    if (!_role.permissions) {
      _role.permissions = [];
    }
    _role.permissions.push(permission);
    dispatch(actions.setRole(_role));
  };

  const removePermission = (permission) => {
    let _role = role;
    pull(_role.permissions, permission);
    dispatch(actions.setRole(_role));
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      persist();
    }
  };

  function persist() {
    const payload = {
      description,
      label,
      permissions: role.permissions,
      active: true,
      legacyApplications,
      legacyRoles,
    };
    payload.id = isEditing ? parseInt(id) : null;
    dispatch(actions.persistRole(payload));
  }

  const backToSearch = () => {
    history.push("/uniksystem/admin/role");
  };

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
        >
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-add mr-2" />
              {`${isEditing ? t("general.edit") : t("general.add")}  ${t(
                "admin.general.role"
              )}`}
            </h6>
          </Card.Header>

          <Card.Body style={isLoadingRole ? { display: "none" } : {}}>
            <Form.Row>
              <Col sm="12" md="8" lg="6">
                <Form.Group controlId="description">
                  <Form.Label>{t("admin.role.persist.description")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("admin.role.persist.requiredDescription")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="label">
                  <Form.Label>{t("admin.role.persist.label")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={label}
                    disabled={isEditing}
                    onChange={(e) => setLabel(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("admin.role.persist.requiredLabel")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm="12" md="8" lg="6">
                <Form.Label>
                  {t("admin.role.persist.roleApplicationTable.title")}
                </Form.Label>
                <Table id="role-table" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        {t(
                          "admin.role.persist.roleApplicationTable.applicationColumn"
                        )}
                      </th>
                      <th>
                        {t(
                          "admin.role.persist.roleApplicationTable.accessColumn"
                        )}
                      </th>
                      <th>
                        {t(
                          "admin.role.persist.roleApplicationTable.rolesColumn"
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {legacyApps.map((legacyApp) => (
                      <LegacyRoleRow
                        key={legacyApp.id}
                        description={description}
                        legacyApp={legacyApp}
                        legacyRoles={legacyRoles}
                        legacyApplications={legacyApplications}
                        openModal={openModal}
                        isEditing={isEditing}
                      />
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Form.Row>

            <Form.Row>
              <Col sm="12" md="12" lg="6">
                <Form.Group controlId="label">
                  <Form.Label>{t("admin.role.persist.title")}</Form.Label>
                  <PermissionTable
                    permissions={availablePermissions()}
                    action={addPermission}
                  />
                </Form.Group>
              </Col>

              <Col sm="12" md="12" lg="6">
                <Form.Group controlId="label">
                  <Form.Label>
                    {t("admin.role.persist.titleSelected")}
                  </Form.Label>
                  <PermissionTable
                    permissions={role.permissions}
                    action={removePermission}
                    actionIcon="icon-trash"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Card.Body>

          <Card.Footer>
            <Button variant="danger" onClick={backToSearch}>
              {t("general.back")}
            </Button>
            <Button
              className="card-button"
              type="submit"
              style={{ marginLeft: "10px", marginRight: "55px" }}
            >
              {t("general.save")}
            </Button>
          </Card.Footer>

          <Spinner spinning={isLoadingRole} />
        </Form>
      </Card>

      <LegacyRoleModal
        showModal={showModalLegacyRole}
        closeModal={closeModal}
        legacyRoles={legacyRoles}
        setLegacyRoles={setLegacyRoles}
        legacyApplications={legacyApplications}
        setLegacyApplications={setLegacyApplications}
        selectedLegacyApplication={selectedLegacyApplication}
      />

      <Spinner spinning={isPersisting} wrapper />
      <Messages afterHideSuccess={backToSearch} />
    </div>
  );
};

export default withNamespaces()(PersistRole);
