import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";

import { find, cloneDeep } from "lodash";
import { findChat, initChat, sendMessage } from "./actions";
import { abbreviateString } from "../../utils";

import "./scss/_chat-room.scss";
import chat_user from "../../assets/img/icons/chat-user.svg";
import arrow_right from "../../assets/img/icons/arrow-right.svg";
import Spinner from "../Spinner";

const ChatRoom = (props) => {
  const ref = React.createRef();

  //Received props
  const { setShowChatRoom, receiver, setReceiver, t } = props;

  //Redux props
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);
  const { chat, isLoadingChat } = useSelector((state) => state.chatReducer);

  //Component state and lifecycle
  const [content, setContent] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (receiver && user) {
      const payload = {
        sender: user.username,
        receiver: receiver.username,
      };
      dispatch(findChat(payload));
    }
  }, [receiver, dispatch, user]);

  useEffect(() => {
    if (chat.id) {
      if (chat.history) {
        setMessages(chat.history);
      } else {
        setMessages([]);
      }
    } else {
      setMessages([]);
    }
  }, [chat]);

  useEffect(() => {
    const scrollDown = () => {
      ref.current.scrollIntoView({ block: "end" });
    };
    scrollDown();
  }, [messages, ref]);

  //Component functions
  const handleSendMessage = (e) => {
    if (e.which === "13") {
      e.preventDefault();
    }
    if ((!e || e.key === "Enter") && !!content.trim()) {
      saveMessage(content.trim());
      clearContentField();
    }
  };

  function saveMessage() {
    const message = {
      sender: user.username,
      content,
      type: "text",
      sent: new Date(),
      unread: true,
    };
    let payload = cloneDeep(chat);
    if (chat.id) {
      payload.message = message;
      dispatch(sendMessage(payload));
      setMessages([...messages, message]);
    } else {
      payload = {
        users: [user, receiver],
        message,
      };
      dispatch(initChat(payload));
    }
  }

  function clearContentField() {
    setContent("");
  }

  const handleCloseChatRoom = () => {
    setShowChatRoom(false);
    setReceiver(undefined);
    setMessages([]);
  };

  const getUserImage = (sender) => {
    const user = find(chat.users, { username: sender });
    return user ? "data:image/png;base64, " + user.detail.picture : null;
  };

  return (
    <div className="chat-panel">
      <div className="chat">
        <div className="chat-header d-flex justify-content-between align-items-center">
          <i className="lnr lnr-user text-success mr-3" />
          <img
            src={chat_user}
            alt="chat-user"
            height="30"
            className="img-chat-user"
          />
          <span className="user-name heading-font-family fw-400 flex-1">
            {receiver &&
              abbreviateString(
                `${receiver.detail.firstName} ${receiver.detail.lastName}`,
                18
              )}
          </span>
          <button
            type="button"
            className="close"
            onClick={() => handleCloseChatRoom()}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="chat-body">
          <Spinner spinning={isLoadingChat} />

          <div className="widget-chat-activity flex-1">
            <div
              className="messages scrollbar-enabled suppress-x ps ps--active-y"
              ref={ref}
            >
              {!isLoadingChat &&
                messages.map((msg) => {
                  const username = user.username;
                  const isUserSender = username === msg.sender;
                  return (
                    <div
                      key={uuid()}
                      className={
                        isUserSender ? "message media" : "message media reply"
                      }
                    >
                      {!isUserSender && (
                        <figure className="thumb-xs2 user--online">
                          <img
                            src={getUserImage(msg.sender)}
                            className="rounded-circle"
                            alt=""
                          />
                        </figure>
                      )}
                      <div className="message-body media-body">
                        <p> {msg.content} </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="card-footer">
          <form className="d-flex justify-content-end" name="textform">
            <textarea
              rows="1"
              name="textarea"
              className="border-0 flex-1 no-resize chat-msg-input"
              placeholder={t("chat.placeholderTypeMessage")}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              onKeyPress={(e) => handleSendMessage(e)}
            />
            <img
              src={arrow_right}
              alt="chat-user"
              className="send-button"
              onClick={() => handleSendMessage()}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(ChatRoom);
