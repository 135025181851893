import { takeLatest } from "redux-saga/effects";

import {
  FIND_IFLOW_TASKS_BY_FILTERS,
  UPDATE_READFLAG,
  FIND_IFLOW_TASKS_COUNT
} from "../ducks/tasks/actionTypes";

import { findIflowTasksByFilters, updateReadFlag, findIflowTasksCount } from "../ducks/tasks/sagas";

export const tasksSagas = [
  takeLatest(FIND_IFLOW_TASKS_BY_FILTERS, findIflowTasksByFilters),
  takeLatest(UPDATE_READFLAG, updateReadFlag),
  takeLatest(FIND_IFLOW_TASKS_COUNT, findIflowTasksCount),
];
 