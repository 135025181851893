import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const CustomBreadcrumb = () => {

  const generateBreadCrumb = () => {
    let items = []
    const path = window.location.href.split('#')[1]
    
      const pathArray = path.split('/')
      pathArray.forEach(path => {
        items.push(<Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>)
      })  
    
    return items
  }
  return (
    <Breadcrumb>
      {generateBreadCrumb()}
    </Breadcrumb>
  )
}
export default CustomBreadcrumb
