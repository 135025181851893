import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import i18n from "~/i18n";
import FileUpload from "./FileUpload";
import { withNamespaces } from "react-i18next";

import "~/assets/css/styles.css";
import "~/assets/css/icons.css";

const DigitalSignature = () => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
  }, []);

  useEffect(() => {
    if (window.location.href.includes("/")) {
      const lng = window.location.href.split("/").pop();
      if (lng) {
        window.localStorage.setItem("lng", lng);
        i18n.changeLanguage(lng);
      }
    }
  }, [window.location.href]);

  return (
    <>
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Body>
            <FileUpload />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withNamespaces()(DigitalSignature);
