import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
import "../../assets/css/icons.css";
import "../../assets/css/styles.css";
import CustomDropdown from "./CustomDropdown";
import { updateUserWidget } from "~/pages/User/actions";
import { withNamespaces } from "react-i18next";

const GoogleNewsWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  t
}) => {
  const initialData = () =>
    JSON.parse(localStorage.getItem("googleNews")) || { articles: [] };
  const [data, setData] = useState(initialData);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const url =
        "https://newsapi.org/v2/top-headlines?sources=google-news&apiKey=e8ef4af4f438474e8f2ea02c3516b4d3";
      const response = await fetch(url);
      const result = await response.json();
      if (result.status !== "error") setData(result);
    };
    fetchData();
    localStorage.setItem("googleNews", JSON.stringify(data));
  }, []);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-bell mr-2" /> Google News{" "}
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ overflowY: "scroll" }}>
            <ul className="news-list">
              {data.articles.map((item, index) => {
                return (
                  <li key={index} className="news-item">
                    <img src={item.urlToImage} alt="" className="news-img" />
                    <a href={item.url} className="news-link" target="_blank">
                      {item.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};
export default withNamespaces()(GoogleNewsWidget);
