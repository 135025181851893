import qs from "qs";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";

import {
  doneResetPassword,
  doneRequestResetAdminPassword,
  doneValidateAdminReset,
  setPasswordTokenStatus,
  doneValidateAdminCode,
} from "./actionTypes";
import { activeError, activeSuccess } from "~/components/Messages/actions";

import {
  toastActiveSuccess,
  toastActiveError,
} from "~/components/Toast/actions";

/* Regular User Reset Password */
export function* resetPassword({ payload }) {
  try {
    const resp = yield http.post("/api/open/user/reset_password", payload);
    yield put(doneResetPassword({ success: true, email: resp.data }));
  } catch (e) {
    yield put(doneResetPassword({ success: false }));
    yield put(activeError("resetAdminPassword.status.errorReset"));
  }
}

export function* validateToken({ token }) {
  try {
    const resp = yield http.get(
      `/api/open/user/validate_token?${qs.stringify(token)}`
    );
    yield put(setPasswordTokenStatus(resp.data));
  } catch (e) {
    yield put(setPasswordTokenStatus("INVALID_TOKEN"));
  }
}

export function* savePassword({ password }) {
  try {
    yield http.post("/api/open/user/save_password", password);
    yield put(activeSuccess("resetAdminPassword.status.successChangePassword"));
  } catch (e) {
    yield put(activeError("resetAdminPassword.status.errorChangePassword"));
  }
}

/* Admin Reset Password */
/* Initial trigger for reseting password is made in Login sagas ~/pages/Login/sagas/ request login function */

export function* validateAdminCode({ code }) {
  try {
    yield http.get("/api/open/validate-admin-code", {
      params: { adminKey: code },
    });
    yield put(doneValidateAdminCode(true));
  } catch (e) {
    yield put(toastActiveError("resetAdminPassword.status.errorValidateToken"));
    yield put(doneValidateAdminCode(false));
  }
}

export function* requestResetAdminPassword({ payload }) {
  try {
    yield http.post("/api/open/create-admin", payload);
    yield put(doneRequestResetAdminPassword(true));
  } catch (e) {
    yield put(doneRequestResetAdminPassword(false));
    yield put(toastActiveError("resetAdminPassword.status.errorRequestReset"));
  }
}

export function* validateAdminReset({ code, redirect }) {
  try {
    const resp = yield http.get(`/api/open/validate-admin`, {
      params: { code },
    });
    if (resp.status === 200) {
      yield put(doneValidateAdminReset(true));
      yield put(
        toastActiveSuccess("resetAdminPassword.status.successChangePassword2")
      );
    } else {
      if (redirect) {
        window.location.href = "/#/";
      }
      yield put(doneValidateAdminReset(false));
      yield put(
        toastActiveError("resetAdminPassword.status.errorChangePassword")
      );
    }
  } catch (e) {
    const { response } = e;
    yield put(doneValidateAdminReset(false));
    if (redirect) {
      window.location.href = "/#/";
    }
    if (response.status === 400) {
      yield put(
        toastActiveError("resetAdminPassword.status.errorChangePassword")
      );
    } else {
      yield put(
        toastActiveError(
          response.data.message ? response.data.message : response.data
        )
      );
    }
  }
}
