import React from "react";
import { withNamespaces } from "react-i18next";

const SignInWithSection = ({ t, children }) => {

  return (
      <div id="signInSection" style={{ textAlign: "center" }}>
        <div className="col-md-12">
          <div className="login-or">
            <hr className="hr-or" />
            <small className="span-or">{t("login.signinWith")}</small>
          </div>
        </div>
        {children}
      </div>
  );
};

export default withNamespaces()(SignInWithSection);
