import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import Annotation from "./Annotation";
import { FaEye, FaRegEdit } from "react-icons/fa";
const CreateActionColumn = (props) => {
  const {
    t,
    row,
    app,
    handleOpenFlowModal,
    handleOpenPreviewModal,
    userBPM,
    styleSheet,
  } = props;

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const [showPreview, setShowPreview] = useState(true);

  /*Get flow Columns defined in Extra Configuration*/
  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/tarefas` || item.route === `${app}/tasks`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );

        let showPreview_;
        showPreview_ = extraConfiguration_.preview === "false" ? false : true;

        setShowPreview(showPreview_);
      }
    }
  }, [menu]);

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }
  const getPreviewIcon = () => {
    const icon = styleSheet?.iconPreview ? styleSheet?.iconPreview : "";
    switch (icon) {
      case "FaEye":
        return (
          <div
            className="table-icon-wrapper"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleOpenPreviewModal(row);
            }}
          >
            <FaEye />
          </div>
        );
      default:
        return (
          <i
            className="icon-detail table-action-icon"
            onClick={() => {
              handleOpenPreviewModal(row);
            }}
          />
        );
    }
  };

  const getEditIcon = () => {
    const icon = styleSheet?.iconEdit ? styleSheet?.iconEdit : "";
    switch (icon) {
      case "FaRegEdit":
        return (
          <div
            className="table-icon-wrapper"
            onClick={(e) => {
              handleOpenFlowModal(row);
            }}
          >
            <FaRegEdit />
          </div>
        );
      default:
        return (
          <i
            className="icon-edit table-action-icon"
            onClick={() => {
              handleOpenFlowModal(row);
            }}
          />
        );
    }
  };
  return (
    <div className="action-wrapper">
      <div style={{ height: "100%" }}>
        <Annotation task={row} userBPM={userBPM} styleSheet={styleSheet} />
      </div>

      {showPreview && !styleSheet?.excludeBaseColumns?.includes('metadata') && (
        <div>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(t("taskPage.general.preview"))}
          >
            {getPreviewIcon()}
          </OverlayTrigger>
        </div>
      )}
      <div>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("taskPage.general.proceed"))}
        >
          {getEditIcon()}
        </OverlayTrigger>
      </div>
    </div>
  );
};
export default withNamespaces()(CreateActionColumn);
