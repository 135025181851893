import React, { useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch } from "react-redux";
import uuid from "uuid/v1";
import uploadIconDisabled from "../assets/img/icons/icon-upload-2.png";
import uploadIcon from "../assets/img/icons/icon-upload.png";
import "../assets/scss/_buttons.scss";

const Button = ({
  text,
  variant = "easypay-button-secondary-icon",
  disabled = false,
  disabledColored = false,
  t,
  onclick,
  onChange,
  inputFile,
  align,
}) => {
  return (
    text !== "hidden-button" && (
      <div
        className="easypay-button-wrapper"
        key={uuid()}
        style={{ justifyContent: align }}
      >
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={onChange}
        />
        <button
          className={`${variant} ${
            disabledColored ? "easypay-button-disabledColored" : ""
          } `}
          disabled={disabled || disabledColored}
          onClick={onclick}
          type="button"
        >
          <span
            className={`easypay-button-icon-text ${
              variant.includes("easypay-document") ? "easypay-22px-text" : ""
            } ${
              variant.includes("easypay-button-add") ? "easypay-20px-text" : ""
            }`}
          >
            {t(text)}
            {(variant === "easypay-button-secondary-icon" ||
              variant === "easypay-button-secondary-icon-large" ||
              variant === "easypay-button-secondary-icon-medium") && (
              <img
                className={`easypay-button-upload-img ${
                  disabledColored ? "easypay-button-img-disabledColored" : ""
                }`}
                src={disabled ? uploadIconDisabled : uploadIcon}
                alt="Upload Icon"
              />
            )}
          </span>
        </button>
      </div>
    )
  );
};

export default withNamespaces()(Button);
