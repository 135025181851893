import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as aliceonboarding from "aliceonboarding";
import "aliceonboarding/dist/aliceonboarding.css";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import {
  createAliceUser,
  getAliceReport,
} from "~/store/ducks/onboarding/actionTypes";

/**
 * Alice Biometrics
 *
 * This service tests API from Alice Biometrics
 *
 * It allows to the user to make a life proof
 *
 */
const Test = ({ t }) => {
  const dispatch = useDispatch();

  const { isLoadingCreateAliceUser, aliceUserToken, aliceUserID, aliceReport } =
    useSelector((state) => state.onboardingReducer);

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [output, setOutput] = useState("");

  useEffect(() => {
    if (aliceUserToken && aliceUserID) {
      let config = new aliceonboarding.OnboardingConfig()
        .withUserToken(aliceUserToken)
        .withAddSelfieStage()
        .withAddDocumentStage(aliceonboarding.DocumentType.IDCARD);
      new aliceonboarding.Onboarding("alice-onboarding-mount", config).run(
        onSuccess,
        onFailure,
        onCancel
      );
    }
  }, [aliceUserToken, aliceUserID]);

  useEffect(() => {
    Object.keys(aliceReport).length == !0 &&
      setOutput(JSON.stringify(aliceReport, null, 2));
  }, [aliceReport]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testCreateAliceUser();
    }
  };

  const testCreateAliceUser = () => {
    const payload = {
      firstName,
      lastName,
      email,
    };
    dispatch(createAliceUser(payload, apiSettings.id));
  };

  const onSuccess = () => {
    const payload = { settingsID: apiSettings.id, userID: aliceUserID };
    dispatch(getAliceReport(payload));
  };

  function onFailure(error) {
    console.log("onFailure: " + error);
  }
  function onCancel() {
    console.log("onCancel");
  }

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Form.Row>
          <Col sm="12" md="8" lg="6">
            <Form.Group controlId="validationAliceFirstNameTest">
              <Form.Label>{t("onboarding.persist.firstName")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredFirstName")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationAliceLastNameTest">
              <Form.Label>{t("onboarding.persist.lastName")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredLastName")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationAliceEmailTest">
              <Form.Label>{t("onboarding.persist.email")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredEmail")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          disabled={
            !user?.organization?.id || Object.keys(apiSettings).length === 0
          }
          style={{ marginRight: "10px" }}
          type="submit"
          className="card-button onboarding-button"
        >
          {isLoadingCreateAliceUser ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            t("onboarding.general.test")
          )}
        </Button>
      </Form>
      <div
        style={{
          maxHeight: "50%",
          maxWidth: "50%",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <div id="alice-onboarding-mount" />
      </div>
      <Output output={output} />
    </div>
  );
};
export default withNamespaces()(Test);
