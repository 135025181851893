import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { OverlayTrigger, Tooltip, Badge, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import avatarDefault from "~/assets/img/avatar/avatar-default.png";
import "../notifications.scss";
import "~/assets/css/styles.css";
import "./NotificationDropdownItem.css";
import { buildPendingPeriod } from "../utils";
import { toLocaleString } from "~/utils";
import { getIflowFormdata } from "store/ducks/processes/actionTypes";
import oauth from "~/utils/oauth";
import {
  getIflowMetadataPreview,
  setIflowMetadataPreview,
} from "~/store/ducks/processes/actionTypes";
import FlowModal from "~/components/FlowModal";

const NotificationDropdownItem = ({
  t,
  item,
  handleSeen,
  styleSheet,
  countItemNotSeen,
}) => {
  const maxLetters = 35;
  const [showCompleteMsg, setShowCompleteMsg] = useState(false);
  const [evenRow, setEvenRow] = useState(false);
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(undefined);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [pidSelected, setPidSelected] = useState("");
  const [itemSubject, setItemSubject] = useState("");

  const handleCloseFlowModal = () => {
    setShowFlowModal(false);
  };

  const dispatch = useDispatch();
  const { metadataPreview, isLoadingPreview } = useSelector(
    (state) => state.processesReducer
  );

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSeen(item);
  };

  const showMsgHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowCompleteMsg(!showCompleteMsg);
  };

  const renderPendingPeriod = () => {
    if (item.createdDate)
      return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>{toLocaleString(item.createdDate)}</Tooltip>}
        >
          <div className="pending-period">
            {buildPendingPeriod(item.createdDate)}
          </div>
        </OverlayTrigger>
      );
  };

  useEffect(() => {
    if (!item.seen) {
      if (countItemNotSeen % 2 == 0) {
        setEvenRow(true);
      } else {
        setEvenRow(false);
      }
    }
  }, [item.seen, countItemNotSeen]);

  useEffect(() => {
    if (item?.link && item?.link !== null) {
      let values = item?.link.split(",");
      let pid = parseInt(values[1]);
      setPidSelected(pid);
    }
  });

  const handleClickItem = () => {
    if (item?.link && item?.link !== null) {
      let values = item?.link.split(",");
      let flowid = parseInt(values[0]);
      let pid = parseInt(values[1]);
      let subpid = parseInt(values[2]);

      const url =
        `/Form/detail.jsp?flowid=${flowid}&pid=${pid}&subpid=${subpid}&procStatus=0&fwSearch=true` +
        "&Authorization=" +
        oauth.getAccessToken();

      dispatch(getIflowFormdata({ url }));
      const row = {
        application: "bpm",
        flowid: flowid,
        pid: pid,
        subpid: subpid,
        url: url,
      };

      setShowFlowModal(true);
      setSelectedUrl(url);
      setSelectedProcess(row);
    }
  };

  useEffect(() => {
    if (item?.subject) {
      let process;
      let number;
      if (item?.subject?.includes("process")) {
        if (item?.subject.indexOf("/") !== -1) {
          const splitString = item?.subject?.split(":");
          process = splitString[0]?.split("/")[1];
          number = splitString[1]?.split(",")[0];
        } else if (item?.subject?.indexOf("_") !== -1) {
          const splitString = item?.subject?.split(":");
          process = splitString[0]?.split("_")[1];
          number = splitString[1]?.split(",")[0];
        }
        if (/\d/.test(number)) {
          setItemSubject(`${process}: ${number}`);
          console.log(number, "number");
        } else {
          setItemSubject(`${process}`);
        }
      } else {
        setItemSubject(item?.subject);
      }
    }
  }, [item?.subject]);

  return (
    <>
      <FlowModal
        showModal={showFlowModal}
        closeModal={handleCloseFlowModal}
        process={selectedProcess}
        src={selectedUrl}
        app={"bpm"}
        styleSheetLink={true}
      />
      <Dropdown.Item
        style={
          evenRow && styleSheet?.notificationsCma
            ? { display: "flex", padding: "10px", backgroundColor: "#e7e6e6" }
            : { display: "flex", padding: "10px" }
        }
      >
        <img
          className="img-profile-header"
          src={avatarDefault}
          alt=""
          width="30px"
          height="30px"
        />

        <div className="message-container" style={{ flex: 1 }}>
          <div style={{ display: "flex" }}>
            {item?.link != null && pidSelected !== -10 ? (
              <b
                onClick={handleClickItem}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                {itemSubject.length > maxLetters
                  ? itemSubject?.slice(0, maxLetters) + "..."
                  : itemSubject}
              </b>
            ) : (
              <b>
                {itemSubject.length > maxLetters
                  ? itemSubject?.slice(0, maxLetters) + "..."
                  : itemSubject}
              </b>
            )}{" "}
            &nbsp;
            {renderPendingPeriod()}
          </div>

          <div style={{ paddingRight: "10px" }}>
            {item.message.length > maxLetters && !showCompleteMsg
              ? item.message.slice(0, maxLetters) + "..."
              : item.message}
          </div>
          {styleSheet?.notificationsCma ? (
            <div style={{ display: "flex" }}>
              <div>
                {item.message.length > maxLetters && (
                  <Badge
                    pill
                    className="badge-secondary "
                    style={{
                      fontSize: "10px",
                      marginRight: "15px",
                      backgroundColor: "#24579e",
                    }}
                    onClick={(e) => showMsgHandler(e)}
                  >
                    {showCompleteMsg
                      ? t("notifications.hide")
                      : t("notifications.show")}
                  </Badge>
                )}
              </div>
              <div>
                {!item.seen && (
                  <Badge
                    pill
                    className="badge-secondary "
                    style={{
                      fontSize: "10px",
                      textAlign: "right",
                      backgroundColor: "#f46f70",
                    }}
                    onClick={(e) => handleClick(e)}
                  >
                    {t("notifications.markAsRead")}
                  </Badge>
                )}
              </div>
            </div>
          ) : (
            <Row>
              <Col>
                {item.message.length > maxLetters && (
                  <Badge
                    pill
                    className="badge-secondary showCompleteMsg"
                    style={{ fontSize: "10px" }}
                    onClick={(e) => showMsgHandler(e)}
                  >
                    {showCompleteMsg
                      ? t("notifications.hide")
                      : t("notifications.show")}
                  </Badge>
                )}
              </Col>
              <Col>
                {!item.seen && (
                  <div
                    className="mark-as-read"
                    style={{ textAlign: "right", paddingRight: "10px" }}
                    onClick={(e) => handleClick(e)}
                  >
                    {t("notifications.markAsRead")}
                  </div>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Dropdown.Item>
      <Dropdown.Divider
        style={
          styleSheet?.notificationsCma
            ? { marginBottom: "0px", marginTop: "0px" }
            : {}
        }
      />
    </>
  );
};
export default withNamespaces()(NotificationDropdownItem);
