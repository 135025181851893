import React, { useState, useEffect } from "react";
import Title from "../components/Title";
import "../assets/scss/_initialDataSection.scss";
import "../assets/scss/_contentContainer.scss";
import TableInput from "./TableInput";
import uuid from "uuid/v1";
import Dropdown from "./TableInputDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllWorkplans,
  saveContact,
  getAllClients,
} from "~/store/ducks/budgetManagement/actionTypes";
import Input from "./Input";
import Modal from "~/pages/Easypay/components/Modal";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import InputSearch from "~/pages/Liftworld/components/InputSearch";
import NormalDropdown from "~/pages/Liftworld/components/InputDropdown";

const ClientDataAccordion = ({ items, workplanIndex }) => {
  const dispatch = useDispatch();

  const {
    workplans,
    clientDirectors,
    partners,
    paymentTypes,
    clients,
    contact,
    companies,
  } = useSelector((state) => state.budgetManagementReducer);

  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [newClientName, setNewClientName] = useState("");
  const [newClientAddress, setNewClientAddress] = useState("");
  const [newClientPrazo, setNewClientPrazo] = useState("");
  const [newClientNif, setNewClientNif] = useState("");
  const [newClientForma, setNewClientForma] = useState("");

  useEffect(() => {
    dispatch(getAllClients(workplans[workplanIndex].organizationId));
    if (contact.numCliente != undefined && contact.idCliente != undefined) {
      workplans[workplanIndex].numClienteProjeto = contact.numCliente;
      workplans[workplanIndex].nomeClienteProjeto = contact.idCliente;
      if (contact?.formaPagamento != null) {
        workplans[workplanIndex].formaPagamento = getIdFromName(
          contact?.formaPagamento,
          "formaPagamento"
        ).toString();
      }
      if (contact?.prazoPagamento != null) {
        workplans[workplanIndex].prazoPagamento = contact?.prazoPagamento;
      }
    }
    dispatch(setAllWorkplans(workplans));
  }, [contact]);

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  const clientTypeList = [
    { label: "Cliente Atual", value: "Cliente Atual", selected: false },
    { label: "Novo Cliente", value: "Novo Cliente", selected: false },
  ];

  const yesNoList = [
    { label: "Sim", value: "Sim", selected: false },
    { label: "Não", value: "Não", selected: false },
  ];

  function handleAceitarForma(e, name) {
    if (name === "tipoCliente")
      if (e === "Novo Cliente") setIsModalErrorShowing(true);

    //test it
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;

    workplans[workplanIndex][name] = e;
    dispatch(setAllWorkplans(workplans));
  }

  function addNewClient() {
    dispatch(
      saveContact({
        nome: newClientName,
        morada: newClientAddress,
        orgId: workplans[workplanIndex].organizationId,
        prazoPagamento: parseInt(newClientPrazo),
        formaPagamento: newClientForma,
        nif: newClientNif,
      })
    );
    setNewClientName("");
    setNewClientAddress("");
    setNewClientForma("");
    setNewClientNif("");
    setNewClientPrazo("");
    setIsModalErrorShowing(false);
  }

  function buildNameArray(info) {
    if (info != null) {
      var newArray = [];
      for (var i = 0; i < info.length; i++) {
        newArray.push({
          label: info[i].nome,
          value: info[i].nome,
          selected: false,
        });
      }
      return newArray;
    }
  }

  function buildNumberArray(info) {
    if (info != null) {
      var newArray = [];
      for (var i = 0; i < info.length; i++) {
        newArray.push({
          label: info[i].numCliente.toString(),
          value: info[i].numCliente.toString(),
          selected: false,
        });
      }
      return newArray;
    }
  }

  function getIdFromName(e, name) {
    if (name === "clientPartner" && partners != null) {
      const partner = partners.find((x) => x.nome === e);
      if (partner != null) return partner?.idPartner;
    } else if (name === "formaPagamento" && paymentTypes != null) {
      const payType = paymentTypes.find((x) => x.nome === e);
      if (payType != null) return payType?.idTipoPagamento;
    } else if (
      (name === "nomeClienteFaturacao" || name === "nomeClienteProjeto") &&
      clients != null
    ) {
      const client = clients.find((x) => x.nome === e);
      if (client != null) return client?.idCliente;
    } else if (name === "clientOwner" && clientDirectors != null) {
      const client = clientDirectors.find((x) => x.nome === e);
      if (client != null) return client?.idClientDiretor;
    }
  }

  function handleTableChange(e, name) {
    workplans[workplanIndex][name] = getIdFromName(e, name).toString();
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    dispatch(setAllWorkplans(workplans));
  }

  function handleClientDiretorTableChange(e, name) {
    workplans[workplanIndex][name] = getIdFromName(e, name).toString();
    const clientDirector = clientDirectors.find((x) => x.nome === e);
    workplans[workplanIndex].clientPartner = clientDirector.idPartner;
    dispatch(setAllWorkplans(workplans));
  }

  function handleNumNameFaturacaoChange(e, name) {
    if (name === "nomeClienteFaturacao") {
      workplans[workplanIndex][name] = getIdFromName(e, name).toString();
      const client = clients.find((x) => x.nome === e);
      workplans[workplanIndex].numClienteFaturacao = client.numCliente;
    } else {
      workplans[workplanIndex][name] = e.toString();
      const client = clients.find((x) => x.numCliente === parseInt(e));
      if (client)
        workplans[workplanIndex].nomeClienteFaturacao = getIdFromName(
          client.nome,
          "nomeClienteFaturacao"
        );
    }

    dispatch(setAllWorkplans(workplans));
  }

  function handleNumNameProjetoChange(e, name) {
    if (name === "nomeClienteProjeto") {
      workplans[workplanIndex][name] = getIdFromName(e, name).toString();
      const client = clients.find((x) => x.nome === e);
      workplans[workplanIndex].numClienteProjeto = client.numCliente;
      if (client?.formaPagamento != null) {
        workplans[workplanIndex].formaPagamento = getIdFromName(
          client?.formaPagamento,
          "formaPagamento"
        ).toString();
      }
      if (client?.prazoPagamento != null) {
        workplans[workplanIndex].prazoPagamento = client?.prazoPagamento;
      }
    } else {
      workplans[workplanIndex][name] = e.toString();
      const client = clients.find((x) => x.numCliente === parseInt(e));
      if (client) {
        workplans[workplanIndex].nomeClienteProjeto = getIdFromName(
          client.nome,
          "nomeClienteProjeto"
        );
        if (client?.formaPagamento != null) {
          workplans[workplanIndex].formaPagamento = getIdFromName(
            client?.formaPagamento,
            "formaPagamento"
          ).toString();
        }
        if (client?.prazoPagamento != null) {
          workplans[workplanIndex].prazoPagamento = client?.prazoPagamento;
        }
      }
    }

    dispatch(setAllWorkplans(workplans));
  }

  function convertIdToName(id, name) {
    if (name === "partners" && partners != null) {
      const partner = partners.find((x) => x.idPartner === parseInt(id));
      if (partner != null) return partner?.nome;
    } else if (name === "paymentTypes" && paymentTypes != null) {
      const payType = paymentTypes.find(
        (x) => x.idTipoPagamento === parseInt(id)
      );
      if (payType != null) return payType?.nome;
    } else if (name === "clients" && clients != null) {
      const client = clients.find((x) => x.idCliente === parseInt(id));
      if (client != null) return client?.nome;
    } else if (name === "clientDirectors" && clientDirectors != null) {
      const client = clientDirectors.find(
        (x) => x.idClientDiretor === parseInt(id)
      );
      if (client != null) return client?.nome;
    }
  }

  function isYesNoDisabled() {
    if (
      workplans[workplanIndex].formaPagamento === null ||
      workplans[workplanIndex].formaPagamento === undefined ||
      workplans[workplanIndex].formaPagamento?.length === 0
    ) {
      return true;
    }
    const name = convertIdToName(
      workplans[workplanIndex].formaPagamento,
      "paymentTypes"
    );
    if (name?.includes("Factoring")) {
      return false;
    }
    return true;
  }

  function handlePaymentType(e) {
    setNewClientForma(e);
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper">
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="liftworld-label-wrapper">Nome</div>
            <div style={{ width: "78%" }}>
              <Input
                field={buildInputField("", newClientName)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientNameAdd"}
                setNewClientName={setNewClientName}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <div className="liftworld-label-wrapper">Morada</div>
            <div style={{ width: "82%" }}>
              <Input
                field={buildInputField("", newClientAddress)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientAddressAdd"}
                setNewClientAddress={setNewClientAddress}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <div className="liftworld-label-wrapper">Prazo</div>
            <div style={{ width: "78%" }}>
              <Input
                field={buildInputField("", newClientPrazo)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientPrazoAdd"}
                setNewClientPrazo={setNewClientPrazo}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <div className="liftworld-label-wrapper">NIF</div>
            <div style={{ width: "78%" }}>
              <Input
                field={buildInputField("", newClientNif)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientNifAdd"}
                setNewClientNif={setNewClientNif}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <div className="liftworld-label-wrapper">Forma Pag.</div>
            <div style={{ width: "68%" }}>
              <Dropdown
                text={""}
                required={true}
                list={buildNameArray(paymentTypes)}
                handleSubmit={(e) => handlePaymentType(e)}
                initialSelected={newClientForma}
                position={"regular"}
                size={"normal"}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "30px",
            }}
          >
            {" "}
            <SimpleButton
              text={"Adicionar"}
              variant={"liftworld-button-primary"}
              onClick={() => addNewClient()}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"liftworld-client-accordion-wrapper"}>
      <Modal
        className="easypay-modal"
        showModal={isModalErrorShowing}
        setShowModal={setIsModalErrorShowing}
        headerContent={"Adicionar novo cliente"}
        bodyContent={createBody()}
        key={uuid()}
      ></Modal>
      <Title text={"DADOS DO CLIENTE"} type={"normal"} />
      <div style={{ paddingTop: 15 }} />
      <div className={"liftworld-single-table"}>
        <div className={"liftworld-table-30-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "start"}>
              Tipo de cliente
            </div>
            <Dropdown
              text={"TIPO DE CLIENTE"}
              required={true}
              list={clientTypeList}
              handleSubmit={(e) => handleAceitarForma(e, "tipoCliente")}
              initialSelected={items.tipoCliente}
              position={"start"}
              size={"normal"}
            />
          </div>
        </div>
        <div className={"liftworld-table-30-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "middle"}>
              Client Owner(Diretor)
            </div>
            <Dropdown
              text={"CLIENT OWNER(DIRECTOR)"}
              required={true}
              list={buildNameArray(clientDirectors)}
              handleSubmit={(e) =>
                handleClientDiretorTableChange(e, "clientOwner")
              }
              initialSelected={convertIdToName(
                items.clientOwner,
                "clientDirectors"
              )}
              position={"middle"}
              size={"normal"}
            />
          </div>
        </div>
        <div className={"liftworld-table-30-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "end"}>
              Partner do Cliente
            </div>
            <Dropdown
              text={""}
              required={true}
              list={buildNameArray(partners)}
              handleSubmit={(e) => handleTableChange(e, "clientPartner")}
              initialSelected={convertIdToName(items.clientPartner, "partners")}
              position={"end"}
              size={"normal"}
            />
          </div>
        </div>
      </div>
      <div className={"liftworld-single-table"}>
        <div className={"liftworld-table-30-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "start"}>
              Nº Cliente Projeto
            </div>
            <InputSearch
              text={""}
              required={true}
              list={buildNumberArray(clients)}
              handleSubmit={(e) =>
                handleNumNameProjetoChange(e, "numClienteProjeto")
              }
              initialSelected={items.numClienteProjeto}
              position={"start"}
              size={"normal"}
            />
          </div>
        </div>
        <div className={"liftworld-table-66-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "end"}>
              Nome Cliente Projeto
            </div>
            <InputSearch
              text={""}
              required={true}
              list={buildNameArray(clients)}
              handleSubmit={(e) =>
                handleNumNameProjetoChange(e, "nomeClienteProjeto")
              }
              initialSelected={convertIdToName(
                items.nomeClienteProjeto,
                "clients"
              )}
              position={"end"}
              size={"normal"}
            />
          </div>
        </div>
      </div>
      <div className={"liftworld-single-table"}>
        <div className={"liftworld-table-30-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "start"}>
              Nº Cliente Faturação
            </div>
            <InputSearch
              text={""}
              required={true}
              list={buildNumberArray(clients)}
              handleSubmit={(e) =>
                handleNumNameFaturacaoChange(e, "numClienteFaturacao")
              }
              initialSelected={items.numClienteFaturacao}
              position={"start"}
              size={"normal"}
            />
          </div>
        </div>
        <div className={"liftworld-table-66-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "end"}>
              Nome Cliente Faturação
            </div>
            <InputSearch
              text={""}
              required={true}
              list={buildNameArray(clients)}
              handleSubmit={(e) =>
                handleNumNameFaturacaoChange(e, "nomeClienteFaturacao")
              }
              initialSelected={convertIdToName(
                items.nomeClienteFaturacao,
                "clients"
              )}
              position={"end"}
              size={"normal"}
            />
          </div>
        </div>
      </div>
      <div className={"liftworld-single-table"}>
        <div className={"liftworld-table-30-wrapper"}>
          <TableInput
            field={buildInputField(
              "Prazo Pagamento (Dias)",
              items.prazoPagamento
            )}
            size={"normal"}
            position={"start"}
            key={uuid()}
            workplanIndex={workplanIndex}
            name={"prazoPagamento"}
          />
        </div>
        <div className={"liftworld-table-30-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "middle"}>
              Forma de Pagamento
            </div>
            <Dropdown
              text={"FORMA DE PAGAMENTO"}
              required={true}
              list={buildNameArray(paymentTypes)}
              handleSubmit={(e) => handleTableChange(e, "formaPagamento")}
              initialSelected={convertIdToName(
                items.formaPagamento,
                "paymentTypes"
              )}
              position={"middle"}
              size={"normal"}
            />
          </div>
        </div>
        <div className={"liftworld-table-30-wrapper"}>
          <div>
            <div className={`${"liftworld-table-header-element"} ` + "end"}>
              Inclui custo do Factoring no Preço de Venda?
            </div>
            <Dropdown
              text={"TIPO DE CLIENTE"}
              required={true}
              list={yesNoList}
              handleSubmit={(e) =>
                handleAceitarForma(e, "aceitarFormaPagamento")
              }
              initialSelected={items.aceitarFormaPagamento}
              position={"end"}
              size={"normal"}
              disabled={isYesNoDisabled()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClientDataAccordion;
