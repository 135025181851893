import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

const CreateTableCheckBoxInput = (props) => {
  const { field, styleSheet} = props;
  const [inputValue, setInputValue] = useState(false);
  const [zoomCheckbox, setZoomCheckbox] = useState('1')
  const myRef = useRef(null);

  useEffect(() => {
    if (field) {
      if (field.checked && field.checked === "true") {
        const component = myRef.current;
        if (component) component.checked = true;
      }
      if (field.value) setInputValue(field.value);
      if(styleSheet?.zoomIn){
        setZoomCheckbox(`${styleSheet?.zoomInCheckboxScaleSize}`)
      }
    }
  }, [field]);

  return (
    <Form.Group
      controlId={`formCheck-${field.variable}`}
      key={field.variable}
      style={{ marginBottom: "0px" }}
    >
      {field.disabled === "true" || field.readonly === "true" ? (
        <input
          ref={myRef}
          type="checkbox"
          name={field.name}
          value={inputValue}
          style={{ paddingTop: "0px", paddingBottom: "0px", transform:`scale(${zoomCheckbox})` }}
          disabled
        />
      ) : (
        <input
          ref={myRef}
          type="checkbox"
          name={field.name}
          value={inputValue}
          style={{ paddingTop: "0px", paddingBottom: "0px", transform:`scale(${zoomCheckbox})` }}
          onChange={(event) => setInputValue(event.target.checked)}
        />
      )}
    </Form.Group>
  );
};

export default CreateTableCheckBoxInput;
