import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { withNamespaces } from "react-i18next";
import CreateTextInput from "./CreateTextInput";
import CreateSearchableDropdown from "./CreateSearchableDropdown";
import CreateTextArea from "./CreateTextArea";
import CreateLink from "./CreateLink";
import CreateTabs from "./CreateTabs";
import CreateAccordion from "./CreateAccordion";
import CreateMultipleFileInput from "./CreateMultipleFileInput";
import CreateCalculosCusto from "./CreateCalculosCusto";
import Messages from "~/components/Messages";
import { handleButtonClick, handleButtonFormdata } from "../utils/FormButtons";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";
import { setConfirmProcessMessage } from "~/store/ducks/iflow/actionTypes";
import { transformFormData, hasLinks } from "../utils";
import uuid from "uuid/v1";
import oauth from "~/utils/oauth";
import CreateRowFromColumns from "./CreateRowFromColumns";
import CreateBootstrapTable from "./CreateBootstrapTable";
import CreateDateInput from "./CreateDateInput";
import CreateButtonsFromSelection from "./CreateButtonsFromSelection";
import CreateRichText from "./CreateRichText";
import CreateAlert from "./CreateAlert";
import CreateTextLabel from "./CreateTextLabel";
import CreateGoogleMap from "./CreateGoogleMap";
import CreateConfirmationModal from "./CreateConfirmationModal";
import CreateCloseProcessButton from "./CreateCloseProcessButton";
import PreviewComponent from "./PreviewComponent";
import { setFocusElement } from "~/store/ducks/iflow/actionTypes";
import { Modal } from "react-bootstrap";

const RenderForm = (props) => {
  const { t } = props;
  const {
    configuration
  } = useSelector((state) => state.adminConfigReducer);

  const dispatch = useDispatch();
  const jsonData = props.data;
  const styleSheet = props.styleSheet;
  const translations = props.translations;
  const buttonOperation = useRef(null);
  const signRef = useRef([]);
  const buttonId = useRef(null);
  const formDataRef = useRef(null);

  const [validated, setValidated] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [firstHeader, setFirstHeader] = useState(true);
  const [textSize, setTextSize] = useState("");
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [currentInfo, setCurrentInfo] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  let language = userSettings?.language ? userSettings.language : "pt";
  const config = configuration?.loginPage;

  useEffect(() => {
    if (styleSheet?.zoomIn && styleSheet?.zoomInTextSize) {
      setTextSize(`${styleSheet?.zoomInTextSize}`);
    }
  }, [styleSheet]);

  const handleButtonDetails = (button) => {
    buttonOperation.current = button.operation;
    buttonId.current = button.id;
  };

  const handleCancelSubmit = () => {
    buttonOperation.current = null;
    buttonId.current = null;
    dispatch(setConfirmProcessMessage(null));
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
    dispatch(setConfirmProcessMessage(null));
  };

  useEffect(() => {
    if (isConfirmed) {
      document.getElementById(buttonId.current).click();
    }
  }, [isConfirmed]);

  const verificarTamanhoTotal = () => {
    var fileInputs = document.querySelectorAll('input[type="file"]');
    if (fileInputs.length === 0) {
      return false;
    }
    var tamanhoTotal = 0;
    const maxFileSize = 1024 * 1024 * 100;

    fileInputs.forEach(function (input) {
      var files = input.files;

      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        tamanhoTotal += file.size;
      }
    });

    if (tamanhoTotal > maxFileSize && config === "nblp") {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmitWrapper = (event, button) => {
    const sizeIsHigher = verificarTamanhoTotal();
    if (!sizeIsHigher) {
      if (isConfirmed) {
        handleSubmit(event);
      } else {
        handleButtonDetails(button);
        if (buttonOperation.current.includes("if (confirm")) {
          const array = buttonOperation.current.split("if (confirm('");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              dispatch(setConfirmProcessMessage(array2[0]));
              const formdata = transformFormData(
                new FormData(document.getElementsByName("dados")[0])
              );
              formDataRef.current = formdata;
            }
          }
        } else {
          handleSubmit(event);
        }
      }
      let formdata = getFormdataVal();
      let errorEmailToSubmit = validateEmailFields(formdata);
      if (!errorEmailToSubmit) dispatch(setFocusElement(null));
    } else {
      setWarningMessage(
        t("portalrh.documentManagement.sizeIsHigherThan100Mb")
      );
      setShowWarning(true);
    }
  };

  useEffect(() => {
    if (showWarning) {
      setTimeout(() => {
        setShowWarning(false);
      }, 10000);
    }
  }, [showWarning]);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getFormdataVal = ()=>{
    let formdata;
    if (formDataRef.current) {
      formdata = formDataRef.current;
      const pairs = handleButtonFormdata(buttonOperation.current);
      pairs.forEach((p) => {
        formdata.delete(p.formkey);
        formdata.append(p.formkey, p.value);
      });
    } else {
      handleButtonClick(buttonOperation.current);
      formdata = transformFormData(
        new FormData(document.getElementsByName("dados")[0])
      );
    }
    return formdata
  }

  const validateEmailFields = (formdata) => {
    let errorEmailToSubmit = false;
    if (config === "nblp") {
      for (var pair of formdata.entries()) {
        if (pair[0].includes("Email") || pair[0].includes("email")) {
          if (!isEmailValid(pair[1])) {
            errorEmailToSubmit = true;
            break;
          }
        }
      }
    }
    return errorEmailToSubmit;
  };

  const handleSubmit = (event) => {
    const form = document.dados;

    const handleDispatch = () => {
      let formdata = getFormdataVal();
      let errorEmailToSubmit = validateEmailFields(formdata)
      let action;
      if (jsonData?.form?.action === "proc_detail.jsp") {
        action = "/proc_detail.jsp?";
      } else {
        action = "/Form/form.jsp?";
      }
      const url =
        `${action}` +
        "flowid=" +
        formdata.get("flowid") +
        "&pid=" +
        formdata.get("pid") +
        "&subpid=" +
        formdata.get("subpid") +
        //+ "&flowExecType=" + formdata.get("flowExecType")
        "&Authorization=" +
        oauth.getAccessToken();
        if(!errorEmailToSubmit) dispatch(postIflowFormData(formdata, url));
      
    };

    if (
      buttonOperation.current &&
      buttonOperation.current.includes("CheckEmptyFields()")
    ) {
      console.log(form.checkValidity());
      if (styleSheet?.skipCheckValidity) {
        handleDispatch();
      } else {
        if (form.checkValidity() === false) {
          setValidated(true);
        } else {
          handleDispatch();
        }
      }
    } else {
      if (buttonOperation.current) {
        handleDispatch();
      }
    }
  };

  const getFlowId = () => {
    return jsonData?.form?.hidden?.find((i) => i.name === "flowid")?.value;
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const headerText =(field)=>{
    let text = undefined;
    if (field?.text?.includes("::t::")){
      let lingua = language.trim();
      let splittext = field.text.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if(translations){
        text = translations?.[lingua][texttoshow] + texttoadd
      }
    } else {
      if(field.text.includes(":")){
        text = field.text
      } else {
        text = t(field.text)
      }
    }
    return text;
  }

  const renderColumnDivisions = (blockdivision, mainindex) => {
    const renderFields = (field, index) => {
      if (field.variable === "googlemapsapi") {
        return <CreateGoogleMap key={index} field={field} />;
      }

      if (field.type === "header") {
        // if (index === 0) {
        //   return <></>;
        // } else {
          return (
            <div
              className="custom-title"
              style={{
                backgroundColor: "#D5E0F3",
                marginBottom: "30px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderRadius: "5px",
                fontSize: `${textSize}px`,
              }}
              key={uuid()}
            >
              {ReactHtmlParser(
                headerText(field)
              )}
            </div>
          );
        //}
      } else if (field.type === "subheader") {
        return (
          <div key={uuid()}>
            <Form.Group
              controlId={`formlabel-${field.type}`}
              // style={{
              //   flexWrap: "nowrap",
              //   marginLeft: "0px",
              //   marginRight: "0px",
              //   marginBottom: "0px",
              //   flexGrow: "1",
              // }}
            >
              <Form.Label column>
                <div
                  style={{
                    backgroundColor: "#F1EBEB",

                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontSize: `${textSize}px`,
                  }}
                  className="custom-title"
                >
                  {ReactHtmlParser(
                   headerText(field)
                  )}
                </div>
              </Form.Label>
            </Form.Group>
            {/* <hr /> */}
          </div>
        );
      } else if (field.type === "arraytable") {
        return (
          <CreateBootstrapTable
            field={field}
            key={index}
            signRef={signRef}
            styleSheet={styleSheet}
            translations={translations}
            language={language}
          />
        );
      } else if (field.type === "textmessage") {
        return (
          <CreateAlert key={index} index={index} field={field} variant="info" translations={translations}
          language={language} />
        );
      } else if (field.type === "textlabel") {
        return (
          <CreateTextLabel key={index} field={field} styleSheet={styleSheet} setIsModalShowing={setIsModalShowing}
          setCurrentInfo={setCurrentInfo} translations={translations} />
        );
      } else if (field.type === "filler") {
        return <div style={{ height: "20px" }}></div>;
      } else if (field.type === "link") {
        return (
          <CreateLink key={field.text} field={field} styleSheet={styleSheet} translations={translations} language={language} />
        );
      } else if (field.type === "textbox") {
        return (
          <CreateTextInput
            key={field.variable}
            field={field}
            styleSheet={styleSheet}
            setIsModalShowing={setIsModalShowing}
            setCurrentInfo={setCurrentInfo}
            translations={translations}
            t={t}
          />
        )
      } else if (field.type === "selection") {
        if (field.variable === "seccao")
          return <CreateButtonsFromSelection key={index} field={field} />;
        else
          return (
            <CreateSearchableDropdown
              key={field.variable}
              field={field}
              styleSheet={styleSheet}
              translations={translations}
              language={language}
            />
          );
      } else if (field.type === "document_preview") {
        return <PreviewComponent type={field.type} file={field.file} />;
      } else if (field.type === "datecal") {
        return (
          <CreateDateInput
            key={field.variable}
            field={field}
            styleSheet={styleSheet}
            fontSize={textSize}
          />
        );
      } else if (field.type === "textarea") {
        if (
          (typeof field?.text == "string" &&
            field?.text?.includes("::richText")) ||
          field?.text?.b?.includes("::richText")
        ) {
          return (
            <CreateRichText
              key={field.variable}
              field={field}
              styleSheet={styleSheet}
            />
          );
        } else
          return (
            <CreateTextArea
              key={field.variable}
              field={field}
              styleSheet={styleSheet}
              setIsModalShowing={setIsModalShowing}
              setCurrentInfo={setCurrentInfo}
              translations={translations}
            />
          );
      } else if (field.type === "file") {
        return (
          <CreateMultipleFileInput
            key={index}
            field={field}
            signRef={signRef}
            styleSheet={styleSheet}
          />
        );
      } else if (field.type === "custo") {
        return <CreateCalculosCusto key={index} field={field} />;
      } else if (field.type === "button") {
        const fitbutton = field.text.includes("::fitbutton") ? true : false;
        return (
          <>
            {styleSheet?.linkAsButtons ? (
              //   <OverlayTrigger
              //   placement="right"
              //   delay={{ show: 250, hide: 400 }}
              //   overlay={renderTooltip(button.tooltip)}
              // >

              //     {button.text}
              //   </Button>

              //   <OverlayTrigger
              //   placement="right"
              //   delay={{ show: 250, hide: 400 }}
              //   overlay={renderTooltip(button.tooltip)}
              // >
              <button
                className="custom-button btn btn-outline-primary"
                style={{
                  margin: "10px",
                  minHeight: "30px",
                  padding: fitbutton ? "auto" : "0 5px 0 5px",
                  marginTop: fitbutton ? "3rem" : "0",
                }}
                type="submit"
                name={field.name}
                onClick={() => handleButtonDetails(field)}
              >
                {buttonText(field)}
              </button>
            ) : (
              // </OverlayTrigger>
              <Form.Group
                as={Row}
                key={uuid()}
                style={{ marginRight: "0px", marginLeft: "0px" }}
              >
                <Form.Label
                  column
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: fitbutton ? "auto" : "0",
                    marginTop: fitbutton ? "3rem" : "0",
                  }}
                >
                  <Button
                    className="card-button"
                    style={{
                      width: fitbutton ? "100%" : "",
                      fontSize: `${textSize}px`,
                      
                    }}
                    type="submit"
                    name={field.name}
                    onClick={() => handleButtonDetails(field)}
                  >
                    {buttonText(field)}
                  </Button>
                </Form.Label>
              </Form.Group>
            )}
          </>
        );
      }
    };

    const buttonText =(field)=>{
      let text = undefined;
      if (field?.text?.includes("::t::")){
        let lingua = language.trim();
        let splittext = field.text.split("::t::");
        let texttoshow = splittext[0];
        let texttoadd = splittext[1];
        if(translations){
          text = translations?.[lingua][texttoshow] + texttoadd
        }
      } else{
        text = t(field.text.split("::")[0])
      }
      return text
    }

    const renderColumnContent = (columndivision, styleSheet, renderLinks) => {
      const header = blockdivision?.columndivision?.field;
      return Object.keys(columndivision).map((columnKey) => {
        if (columnKey === "field") {
          let fieldArray = columndivision.field ? columndivision.field : [];
          if (!Array.isArray(fieldArray)) {
            fieldArray = [fieldArray];
          }

          if (
            styleSheet &&
            styleSheet?.noTransformColumnsIntoRow &&
            !renderLinks &&
            !styleSheet?.transformLinksIntoWrapper
          ) {
            /*novobanco, finantia*/
            return fieldArray.map((field, index) => {
              return (
                <div
                  key={`col_${uuid()}`}
                  style={{
                    flexBasis: `${
                      columndivision.width ? columndivision.width : "100"
                    }%`,
                  }}
                >
                  {renderFields(field, index)}
                </div>
              );
            });
          } else if (
            styleSheet &&
            styleSheet.applyColumnWidth &&
            !styleSheet.noTransformColumnsIntoRow
          ) {
            /*cma*/

            fieldArray = fieldArray.filter(function (obj) {
              return obj.type !== "filler";
            });

            return fieldArray.map((field, index) => {
              return (
                <div
                  key={`col_${uuid()}`}
                  style={{
                    flexBasis: `${
                      columndivision.width ? columndivision.width : "100"
                    }%`,
                    maxWidth: `${
                      columndivision.width ? columndivision.width : "100"
                    }%`,
                    paddingLeft: field.type === "link" ? "15px" : "",
                  }}
                >
                  {renderFields(field, index)}
                </div>
              );
            });
          } else
          /*default*/
            return fieldArray.map((field, index) => renderFields(field, index));

          /*RENDER tabdivision*/
        } else if (columnKey === "tabdivision") {
          let tabDivisionArray = columndivision.tabdivision
            ? columndivision.tabdivision
            : [];
            let widthDivisionArray = columndivision.width
            ? columndivision.width
            : "auto";
          if (!Array.isArray(tabDivisionArray)) {
            tabDivisionArray = [tabDivisionArray];
          }
          let accordionStyle = "DEFAULT";

          const accordion = tabDivisionArray.map((tabDiv) => {
            let tabArray = tabDiv?.tab ? tabDiv.tab : [];
            if (!Array.isArray(tabArray)) tabArray = [tabArray];
            tabArray.forEach((tab) => {
              if (tab.name.includes("::tab")) accordionStyle = "TAB";
            });

            if (accordionStyle === "TAB") {
              return (
                <CreateTabs
                  key={uuid()}
                  tabDiv={tabDiv}
                  tabArray={tabArray}
                  renderBlockDivision={renderBlockDivision}
                />
              );
            } else {
              let activeKey = 0;
              //find active tab 
              let arrays =
                JSON.parse(localStorage.getItem("iflowAccordion")) || [];
              //find config for this tabDiv
              let found = arrays.find((a) => a.id === tabDiv.id);
              if (found) {
                tabArray.forEach((tab, index) => {
                  if (tab.id === found.key) {
                    activeKey = found.key;
                  }
                });
              }
              return (
                <CreateAccordion
                  key={uuid()}
                  tabDiv={tabDiv}
                  activeKey={activeKey}
                  tabArray={tabArray}
                  renderBlockDivision={renderBlockDivision}
                  renderColumnContent={renderColumnContent}
                  header={header}
                  flowId={getFlowId()}
                  styleSheet={styleSheet}
                  style={{
                    width: `${widthDivisionArray ? widthDivisionArray : ""}%`,
                    paddingRight: `${widthDivisionArray ? "15px" : ""}`,
                  }}
                  translations={translations}
                  language={language}
                />
              );
            }
          });
          return accordion;
        }
      });
    };

    const transformColumnsIntoRow = (columns_) => {
      let colIndex = 0;
      colIndex = columns_.findIndex(
        (col) =>
          col && //null and undefined check
          col !== "" &&
          Object.keys(col).length !== 0
      );
      return Object.keys(columns_[colIndex]).map((columnKey) => {
        if (columnKey === "field") {
          let fieldArray = columns_[colIndex].field
            ? columns_[colIndex].field
            : [];
          if (!Array.isArray(fieldArray)) {
            fieldArray = [fieldArray];
          }

          return fieldArray.map((field, index) => {
            if (index > 0) return renderFields(field, index);
            else if (index === 0) {
              return (
                <CreateRowFromColumns
                  key={index}
                  field={field}
                  columns={columns_}
                  styleSheet={styleSheet}
                />
              );
            }
          });
        }
      });
    };

    let columndivision = blockdivision.columndivision;
    if (!Array.isArray(columndivision)) {
      columndivision = [columndivision];
    }
    let numColumns = columndivision.length;

    if (numColumns === 1) {
      return (
        <Col key={`columndivision_${uuid()}`} style={{ flexGrow: "1" }}>
          {renderColumnContent(
            columndivision[0],
            styleSheet,
            hasLinks(columndivision)
          )}
        </Col>
      );
    } else if (numColumns > 1) {
      if (
        styleSheet &&
        (styleSheet.noTransformColumnsIntoRow ||
          (hasLinks(columndivision) && styleSheet?.transformLinksIntoWrapper))
      ) {
        return (
          <div
            key={`columndivision_${uuid()}`}
            style={{
              display: "flex",
              flexWrap: styleSheet?.transformLinksIntoWrapper
                ? "wrap"
                : "nowrap",
              flexDirection: "row",
              flexGrow: "1",
              padding: "0 15px",
              justifyContent:
                hasLinks(columndivision) &&
                styleSheet?.transformLinksIntoWrapper
                  ? "center"
                  : "",
            }}
          >
            {columndivision.map((col) => {
              return renderColumnContent(col, styleSheet);
            })}
          </div>
        );
      } else if (
        styleSheet &&
        styleSheet.applyColumnWidth &&
        !styleSheet.noTransformColumnsIntoRow
      ) {
        return (
          <div
            key={`columndivision_${uuid()}`}
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              flexGrow: "1",
              padding: "0 15px",
              maxWidth: "100%",
              // marginTop: "1rem",
              // marginBottom: "1rem",
              // border: "0",
              // borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            {columndivision.map((col) => {
              return renderColumnContent(col, styleSheet);
            })}
          </div>
        );
      } else
        return (
          <Col key={`columndivision_${uuid()}`} style={{ flexGrow: "1" }}>
            {transformColumnsIntoRow(columndivision)}
          </Col>
        );
    }
  };

  const renderBlockDivision = (blockdivision) => {
    let blockdivisionArray = blockdivision;
    if (!Array.isArray(blockdivisionArray)) {
      blockdivisionArray = [blockdivisionArray];
    }
    return blockdivisionArray.map((blockdivision, index) => {
      return (
        <Row
          key={`blockdivision_${index}`}
          style={{
            width: "100%",
            flexWrap: "nowrap",
            margin: "0px",
            padding: styleSheet?.compact
              ? "0px"
              : blockdivision?.columndivision === ""
              ? "0px"
              : Array.isArray(blockdivision?.columndivision)
              ? blockdivision?.columndivision[0]?.size > 0
                ? "5px"
                : "0px"
              : "5px",
            fontSize: `${textSize}px`,
          }}
        >
          {renderColumnDivisions(blockdivision, index)}
        </Row>
      );
    });
  };

  const buttonTextFormKey = (textButton)=>{
    let text = undefined;
    if (textButton.includes("::t::")){
      let lingua = language.trim();
      let splittext = textButton.split("::t::");
      let texttoshow = splittext[0];
      let texttoadd = splittext[1];
      if(translations){
        text = translations?.[lingua][texttoshow] + texttoadd
      }
    } else {
      text = textButton
    }
    return text;
  }

  const showalertextIflowTranslation = (p_) => {
    let text = undefined;
    if (p_.includes("::t::")) {
      let lingua = language.trim();
      let splittext = p_.split("::t::");
      let texttoshow = splittext[0]
      let textToAdd = splittext[1]
      if (translations) {
        text = translations?.[lingua][texttoshow] + t("portalrh.error." + textToAdd);
      }
    } else {
      text = p_;
    }
    if (text != undefined) return text;
  };

  const RenderFormItems = () => {
    if (jsonData) {
      const form = jsonData.form;
      return Object.keys(form).map((formKey) => {
        if (formKey === "blockdivision") {
          return renderBlockDivision(form.blockdivision);
        }
        if (formKey === "error") {
          let text = form.error?.text.split("<br/>");
          return (
            <Row
              key={`blockdivision_error`}
              style={{
                width: "100%",
                flexWrap: "nowrap",
                margin: "0px",
                padding: "5px",
              }}
            >
              <Col key={`columndivision_error`} style={{ flexGrow: "1" }}>
                <Alert key={formKey} variant="danger">
                  {text &&
                    text.map((p_, index) => (
                      <p key={index}>{showalertextIflowTranslation(p_)}</p>
                    ))}
                </Alert>
              </Col>
            </Row>
          );
        }        
        if (formKey === "button") {
          let buttonArray = form.button ? form.button : [];
          if (!Array.isArray(buttonArray)) {
            buttonArray = [buttonArray];
          }
          return (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "65px",
              }}
              key={uuid()}
            >
              {buttonArray.map((button) => {
                if (button.text === "Cancelar" || button.text === "Cancel") {
                  if (button?.zotooltipna) {
                    return (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(buttonTextFormKey(button.tooltip))}
                      >
                        <Button
                          key={uuid()}
                          id={button.id}
                          variant="danger"
                          style={{ margin: "10px", width:'auto' }}
                          onClick={(event) =>
                            handleSubmitWrapper(event, button)
                          }
                        >
                          {buttonTextFormKey(button.text)}
                        </Button>
                      </OverlayTrigger>
                    );
                  } else {
                    return (
                      <Button
                        key={uuid()}
                        id={button.id}
                        variant="danger"
                        style={{ margin: "10px", width:'auto' }}
                        onClick={(event) => handleSubmitWrapper(event, button)}
                      >
                        {buttonTextFormKey(button.text)}
                      </Button>
                    );
                  }
                } else {
                  if (button?.tooltip) {
                    return (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(buttonTextFormKey(button.tooltip))}
                      >
                        <Button
                          key={uuid()}
                          id={button.id}
                          className="card-button"
                          style={{ margin: "10px", fontSize: `${textSize}px` }}
                          onClick={(event) =>
                            handleSubmitWrapper(event, button)
                          }
                        >
                          {buttonTextFormKey(button.text)}
                        </Button>
                      </OverlayTrigger>
                    );
                  } else {
                    return (
                      <Button
                        key={uuid()}
                        id={button.id}
                        className="card-button"
                        style={{ margin: "10px", fontSize: `${textSize}px` }}
                        onClick={(event) => handleSubmitWrapper(event, button)}
                      >
                        {buttonTextFormKey(button.text)}
                      </Button>
                    );
                  }
                }
              })}
            </Row>
          );
        }
        if (formKey === "hidden") {
          const hiddenArray = form.hidden;
          return hiddenArray.map((field, index) => {
            return (
              <input
                key={index}
                type="hidden"
                value={field.value}
                name={field.name}
              />
            );
          });
        }
      });
    } else return null;
  };

  return (
    <>
      {!props.isModal && styleSheet?.processCloseButton && (
        <CreateCloseProcessButton />
      )}
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        name="dados"
        encType="multipart/form-data"
        style={{
          width: "100%",
          margin: "auto",
        }}
        className="process-form"
      >
        <Row>
          <Col>
            {showWarning ? (
              <Alert key={1} variant="danger">
                {warningMessage}
              </Alert>
            ) : null}
          </Col>
        </Row>
        {jsonData && <RenderFormItems />}
      </Form>
      <CreateConfirmationModal
        handleCancelSubmit={handleCancelSubmit}
        handleConfirm={handleConfirm}
        language={language}
        translations={translations}
      />
      <Modal
        show={isModalShowing}
        onHide={() => setIsModalShowing(false)}
        dialogClassName="custom-modal"
        centered
        backdrop="static" 
        
      >
        <Modal.Header closeButton style={{backgroundColor:'white'}}>
        </Modal.Header>
        <Modal.Body>{currentInfo}</Modal.Body>
      </Modal>

      <Messages />
    </>
  );
};

export default withNamespaces()(RenderForm);
