export const SET_FOLDERS = "taskFolders@SET_FOLDERS";
export const FIND_FOLDERS = "taskFolders@FIND_FOLDERS";
export const SAVE_FOLDER = "taskFolders@SAVE_FOLDER";
export const SET_UPDATE_FOLDER = "taskFolders@SET_UPDATE_FOLDER";
export const UPDATE_FOLDERS = "taskFolders@UPDATE_FOLDERS";
export const DELETE_FOLDER = "taskFolders@DELETE_FOLDER";
export const SET_IS_EDITING_ACTIVITY_FOLDER =
  "taskFolders@SET_IS_EDITING_ACTIVITY_FOLDER";

export const SET_SELECTED_ROW = "taskFolders@SET_SELECTED_ROW";

export const EDIT_ACTIVITY_FOLDER = "taskFolders@EDIT_ACTIVITY_FOLDER";

export const setFolders = (folders) => {
  return { type: SET_FOLDERS, data: folders };
};

export const findFolders = (username, application) => ({
  type: FIND_FOLDERS,
  username,
  application,
});

export const saveFolder = (payload) => {
  return { type: SAVE_FOLDER, payload };
};

export const setUpdateFolder = (result) => {
  return { type: SET_UPDATE_FOLDER, data: result };
};

export const updateFolders = (payload) => {
  return { type: UPDATE_FOLDERS, payload };
};

export const deleteFolder = (id) => {
  return { type: DELETE_FOLDER, id };
};

export const setSelectedRow = (row) => {
  return { type: SET_SELECTED_ROW, data: row };
};

export const editActivityFolder = (payload) => {
  return { type: EDIT_ACTIVITY_FOLDER, payload };
};

export const setIsEditingActivityFolder = (isEditing) => {
  return { type: SET_IS_EDITING_ACTIVITY_FOLDER, data: isEditing };
};
