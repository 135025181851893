import React from "react";
import { withNamespaces } from "react-i18next";
import { FaFilter, FaListUl } from "react-icons/fa";

const FilterCategoryToggle = (props) => {
  const { t, handleActiveKey, activeKeys } = props;

  const getIconToDecorate = (iconToDecorate) => {
    switch (iconToDecorate) {
      case "FaFilter":
        return <FaFilter />;
      case "FaListUl":
        return <FaListUl />;
      default:
        return null;
    }
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          className={
            activeKeys?.filter === "0"
              ? "task-filter-category-toggle-wrapper-active"
              : "task-filter-category-toggle-wrapper"
          }
          onClick={() => {
            handleActiveKey("filter");
          }}
        >
          <span className="task-filter-category-toggle">
            {getIconToDecorate("FaFilter")} &nbsp; {t("general.filters")}&nbsp;
          </span>
        </div>

        <div
          onClick={() => {
            handleActiveKey("categories");
          }}
          className={
            activeKeys?.categories === "0"
              ? "task-filter-category-toggle-wrapper-active"
              : "task-filter-category-toggle-wrapper"
          }
          style={{ paddingLeft: "15px" }}
        >
          <span className="task-filter-category-toggle">
            {getIconToDecorate("FaListUl")} &nbsp;{" "}
            {t("taskPage.categories.categories")}&nbsp;
          </span>
        </div>
      </div>
      <hr />
    </>
  );
};

export default withNamespaces()(FilterCategoryToggle);
