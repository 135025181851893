export const closeIframesScreens = () => {
  if (document.getElementById("bpm"))
    document.getElementById("bpm").style.display = "none";
  if (document.getElementById("compliance"))
    document.getElementById("compliance").style.display = "none";
  if (document.getElementById("gdpr"))
    document.getElementById("gdpr").style.display = "none";
  if (document.getElementById("rh"))
    document.getElementById("rh").style.display = "none";
  if (document.getElementById("globalsolver"))
    document.getElementById("globalsolver").style.display = "none";
  if (document.getElementById("portalrh"))
    document.getElementById("portalrh").style.display = "none";
};

export const openIframesScreens = () => {
  if (document.getElementById("bpm"))
    document.getElementById("bpm").style.display = "block";
  if (document.getElementById("compliance"))
    document.getElementById("compliance").style.display = "block";
  if (document.getElementById("gdpr"))
    document.getElementById("gdpr").style.display = "block";
  if (document.getElementById("rh"))
    document.getElementById("rh").style.display = "block";
  if (document.getElementById("globalsolver"))
    document.getElementById("globalsolver").style.display = "block";
  if (document.getElementById("portalrh"))
    document.getElementById("portalrh").style.display = "block";
};
