import React, { useState ,useEffect} from "react";
import { withNamespaces } from "react-i18next";
import "../assets/scss/_card.scss";
import "../assets/scss/_tabButtonWrapper.scss";
import "../assets/scss/_verticalNav.scss";
import {
    setCurrentVertical
} from "~/store/ducks/onboarding/actionTypes";
import { useDispatch ,useSelector} from "react-redux";

const VerticalNavWrapper = ({ children, t }) => {

  const { currentVertical } = useSelector((state) => state.onboardingReducer);

  const [activeTab, setActivetab] = useState(
    children.length <= 0
      ? ""
      : currentVertical != "" && currentVertical != undefined ? currentVertical : children[0][0]?.props?.children[0][0][0]?.props?.children?.props?.defaultValue
      ? t(children[0][0]?.props?.children[0][0][0]?.props?.children?.props?.defaultValue)
      : t(children[0][0]?.props?.text)
  );


  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      activeTab,
      setTheActiveTab: (name) => {
        setActivetab(name);
      },
      tabsNumber: children[0].length,
      index,
    });
  });

  const getChildrenContent = () =>
    children[0].filter((child) => child.props.name === activeTab)[0]?.props
      ?.children;

  return (
    <div className="easypay-vertical-nav-wrapper" id="vertical-nav-kyc">
      <div className={"easypay-vertical-nav"}>{childrenWithProps}</div>
      <div className="easypay-vertical-nav-content">
        <div className="easypay-card">{getChildrenContent()}</div>
      </div>
    </div>
  );
};

export default withNamespaces()(VerticalNavWrapper);
