import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  getOrganizationById,
  clearOrganization,
  updateOrganization,
  removeOrganizationPicture,
} from "~/store/ducks/organizations/actionTypes";

import { Card, Col, Button, Form, Alert } from "react-bootstrap";

import Spinner from "~/components/Spinner";
import ProtectedPage from "~/components/security/ProtectedPage";
import WarningModal from "~/components/WarningModal/WarningModal";

import { createBase64StringFromBinary } from "~/utils";

import avatarDefault from "~/assets/img/notifications/empty_notifications.png";

import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";

const OrganizationSettings = ({ t }) => {
  const maxFileSize = 65536; //bytes
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState("");
  const [label, setLabel] = useState("");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [error, setError] = useState(false);
  const [docDigitizerKey, setDocDigitizerKey] = useState("");
  const [digestState, setDigestState] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [picture, setPicture] = useState(null);
  const [urlPicture, setUrlPicture] = useState(null);

  const { organization, isLoadingOrganization, isPersisting } = useSelector(
    (state) => state.organizationsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOrganizationById(user?.organization?.id));
    }

    return () => {
      dispatch(clearOrganization());
    };
  }, [dispatch, user]);

  useEffect(() => {
    if (Object.keys(organization).length !== 0) {
      setDescription(organization.description);
      setLabel(organization.label);
      setStartAt(organization.start_at && organization.start_at.slice(0, 10));
      setEndAt(organization.end_at && organization.end_at.slice(0, 10));
      setDocDigitizerKey(organization.doc_digitizerapikey);
      setDigestState(organization.emailDigest);
      if (organization.picture !== null) {
        setPicture(organization.picture);
        const createdPicture = createBase64StringFromBinary(
          organization.picture
        );
        if (createdPicture) {
          setUrlPicture(createdPicture);
        }
      } else {
        setUrlPicture(avatarDefault);
      }
    } else {
      setUrlPicture(avatarDefault);
    }
    if (!organization?.picture) {
      setUrlPicture(avatarDefault);
    }
  }, [organization]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      persist();
    }
  };

  const checkError = (startDate, endDate) =>
    endDate > startDate ? false : true;

  function persist() {
    const payload = {
      description,
      label,
      start_at: startAt,
      end_at: endAt,
      active: 1,
      doc_digitizerapikey: docDigitizerKey,
      emailDigest: digestState,
      picture: picture,
    };
    payload.id = parseInt(user?.organization?.id);
    dispatch(updateOrganization(payload));
  }

  //Transforms "Name" into "Description" and "Label"
  const nameToDescriptionAndLabel = (name) => {
    setDescription(name);
    const label = name.replace(/\s+/g, "-").toLowerCase();
    setLabel(label);
  };

  const handleSelectPicture = (e) => {
    const _file = e.target.files[0];
    if (_file.size < maxFileSize) {
      if (_file && _file["type"].split("/")[0] === "image") {
        setPicture(_file);
        setUrlPicture(URL.createObjectURL(_file));
      }
    } else {
      setWarningMessage(_file.name + ": " + t("general.largeFile"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  const handleDeleteOrgPicture = () => {
    dispatch(removeOrganizationPicture(organization.id));
    setUrlPicture(avatarDefault);
    setPicture(null);
  };

  return (
    <ProtectedPage roles={[ROLE_ADMIN, ROLE_ORG_ADMIN]}>
      <div style={{ displa: "flex", flexDirection: "column", width: "100%" }}>
        <div className="main-card-v2">
          <Card bsPrefix="card-flat">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <Card.Header className="justify-content-between">
                <h6>
                  <i className="icon-add mr-2" />
                  {t("admin.general.organizationSettings")}
                </h6>
              </Card.Header>

              <Card.Body
                style={isLoadingOrganization ? { display: "none" } : {}}
              >
                {showWarning && (
                  <Alert
                    variant="warning"
                    onClose={() => setShowWarning(false)}
                    dismissible
                  >
                    <strong>Alert: </strong>
                    {warningMessage}
                  </Alert>
                )}
                <Form.Row>
                  <Col lg="6">
                    <Form.Row>
                      <Col sm="12" md="8" lg="8">
                        <Form.Group controlId="name">
                          <Form.Label>
                            {t("admin.organization.persist.name")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={description || ""}
                            disabled={true}
                            onChange={(e) =>
                              nameToDescriptionAndLabel(e.target.value)
                            }
                            required
                            autoComplete="off"
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(
                              "admin.organization.persist.requiredDescription"
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm="12" md="8" lg="8">
                        <Form.Group controlId="docDigitizerKey">
                          <Form.Label>
                            {t("admin.organization.persist.docDigitizerKey")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={docDigitizerKey || ""}
                            onChange={(e) =>
                              setDocDigitizerKey(e.target.value.trim())
                            }
                            autoComplete="off"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("admin.organization.persist.requiredLabel")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm="12" md="8" lg="8">
                        <Form.Group controlId="startAt">
                          <Form.Label>
                            {t("admin.organization.persist.startAt")}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            disabled
                            value={startAt || ""}
                            onChange={(e) => setStartAt(e.target.value)}
                            required
                            placeholder="yyyy-mm-dd"
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("admin.organization.persist.requiredStartAt")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm="12" md="8" lg="8">
                        <Form.Group controlId="endAt">
                          <Form.Label>
                            {t("admin.organization.persist.endAt")}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            disabled
                            value={endAt || ""}
                            onChange={(e) => {
                              setEndAt(e.target.value);
                              checkError(startAt, e.target.value)
                                ? setError(true)
                                : setError(false);
                            }}
                            required
                            min={startAt}
                          />
                          <Form.Control.Feedback type="invalid">
                            {error
                              ? t("admin.organization.persist.errorEndAt")
                              : t("admin.organization.persist.requiredEndAt")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    {docDigitizerKey && (
                      <Form.Row>
                        <Col sm="12" md="6" lg="5">
                          <Form.Label>
                            {t("admin.organization.persist.digest")}
                          </Form.Label>
                          <Form.Check
                            id="toggle-digest"
                            type="switch"
                            label=""
                            checked={digestState}
                            onChange={() => setDigestState(!digestState)}
                          />
                        </Col>
                      </Form.Row>
                    )}
                  </Col>
                  <Form.Row>
                    <Col sm="12" md="6" lg="6">
                      <Form.Group controlId="validationCustomPhoto">
                        <Form.Label>
                          {t("admin.organization.persist.picture")}
                        </Form.Label>
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) => handleSelectPicture(e)}
                            />
                            <label htmlFor="imageUpload">
                              <i className="icon-add add-picture" />
                            </label>
                          </div>
                          {picture && (
                            <div className="avatar-delete">
                              <i
                                className="icon-trash add-picture"
                                onClick={() => {
                                  setShowDeleteModal(true);
                                }}
                              />
                            </div>
                          )}
                          <div className="avatar-preview-fit-inside">
                            <div
                              style={{
                                backgroundImage: "url(" + urlPicture + ")",
                              }}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Text className="text-muted">
                        {t("general.maxFileSize") +
                          " " +
                          Math.round(maxFileSize / 1024) +
                          "kb"}
                      </Form.Text>
                    </Col>
                  </Form.Row>
                </Form.Row>
              </Card.Body>
              <Card.Footer>
                <Button
                  className="card-button"
                  type="submit"
                  style={{ marginLeft: "10px", marginRight: "55px" }}
                >
                  {t("general.save")}
                </Button>
              </Card.Footer>
              <Spinner spinning={isLoadingOrganization} />
            </Form>
          </Card>
          <Spinner spinning={isPersisting} wrapper />
          <WarningModal
            showDeleteModal={showDeleteModal}
            handleDelete={handleDeleteOrgPicture}
            handleCloseDeleteModal={setShowDeleteModal}
            message={t("admin.organization.persist.warningPicture")}
          />
        </div>
      </div>
    </ProtectedPage>
  );
};

export default withNamespaces()(OrganizationSettings);
