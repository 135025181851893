import { TasksStatus } from "./StatusEnum";
import licZero_ from "~/assets/img/icons/cma/licZero_.png";
import reuniaoAMunicipal_ from "~/assets/img/icons/cma/reuniaoAMunicipal_.png";
import reuniaoCamara_ from "~/assets/img/icons/cma/reuniaoCamara_.png";
import saneamentoLiminar_ from "~/assets/img/icons/cma/saneamentoLiminar_.png";
import viaVerde_ from "~/assets/img/icons/cma/viaVerde_.png";

export const getColorByStatus = (status) => {
  switch (status) {
    case TasksStatus.NOVO:
      return "info";
    case TasksStatus.OCR:
      return "warning";
    case TasksStatus.VALIDAÇÃO_DOCUMENTAL:
      return "warning";
    case TasksStatus.DOCUMENTAÇÃO_INCOMPLETA:
      return "danger";
    case TasksStatus.CONTROLO_DE_QUALIDADE:
      return "warning";
    case TasksStatus.VALIDAÇÃO_CONTRATUAL:
      return "warning";
    case TasksStatus.APROVADO:
      return "success";
    case TasksStatus.REJEITADO:
      return "danger";
    case TasksStatus.VALIDAÇÃO_EM_CURSO:
      return "danger";
    case TasksStatus.REVISÃO_EM_CURSO:
      return "danger";
    /*RH Statuses */
    case TasksStatus.REGISTO_DE_UTILIZADOR:
      return "info";
    case TasksStatus.REGISTO_INICIAL:
      return "info";
    /*RH Statuses end*/
    default:
      return "info";
  }
};

export const translateStatus = (status) => {
  switch (status) {
    case TasksStatus.NOVO:
      return "validprocs.taskStatus.new";
    case TasksStatus.OCR:
      return "validprocs.taskStatus.ocr";
    case TasksStatus.VALIDAÇÃO_DOCUMENTAL:
      return "validprocs.taskStatus.documentValidation";
    case TasksStatus.DOCUMENTAÇÃO_INCOMPLETA:
      return "validprocs.taskStatus.incompleteDocumentation";
    case TasksStatus.CONTROLO_DE_QUALIDADE:
      return "validprocs.taskStatus.qualityControl";
    case TasksStatus.VALIDAÇÃO_CONTRATUAL:
      return "validprocs.taskStatus.contractValidation";
    case TasksStatus.APROVADO:
      return "validprocs.taskStatus.approved";
    case TasksStatus.REJEITADO:
      return "validprocs.taskStatus.rejected";
    case TasksStatus.VALIDAÇÃO_EM_CURSO:
      return "validprocs.taskStatus.validationInProgress";
    case TasksStatus.REVISÃO_EM_CURSO:
      return "validprocs.taskStatus.revisionInProgress";
    default:
      return "";
  }
};

export function timeConversion(millisec) {
  const seconds = (millisec / 1000).toFixed(0);
  const minutes = (millisec / (1000 * 60)).toFixed(0);
  const hours = (millisec / (1000 * 60 * 60)).toFixed(0);
  const days = (millisec / (1000 * 60 * 60 * 24)).toFixed(0);
  if (seconds < 0) return "-";
  else if (seconds < 60) {
    return "menos de um minuto";
  } else if (minutes < 60) {
    return minutes + (minutes > 1 ? " minutos" : " minuto");
  } else if (hours < 24) {
    return hours + (hours > 1 ? " horas" : " hora");
  } else {
    return days + (days > 1 ? " dias" : " dia");
  }
}

export const invertColor = (hex) => {
  if (hex === "inherit") {
    return hex;
  }
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    console.log("Invalid HEX color");
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
};

export const iconmap = (theme) => {
  if (theme === "cma") {
    return [
      {
        id: 0,
        name: "Deadline",
        faIcon: "far fa-clock urgent-label",
      },
      {
        id: 1,
        name: "Urgente",
        faIcon: "fas fa-exclamation-triangle urgent-label",
      },
      {
        id: 2,
        name: "Normal",
        faIcon: "fas fa-exclamation-triangle normal-label",
      },
      {
        id: 3,
        name: "saneamentoLiminar",
        image: saneamentoLiminar_,
      },
      {
        id: 4,
        name: "licZero",
        image: licZero_,
      },
      {
        id: 5,
        name: "viaVerde",
        image: viaVerde_,
      },
      {
        id: 6,
        name: "Importante",
        faIcon: "fas fa-exclamation-triangle important-label",
      },
      /*
      {
        id: 7,
        name: "aMinhaRua",
        faIcon: "icon-bell",
      },
      {
        id: 8,
        name: "souCidadao",
        faIcon: "icon-bell",
      },
      */
      {
        id: 9,
        name: "reuniaoCamara",
        image: reuniaoCamara_,
      },
      {
        id: 10,
        name: "reuniaoAMunicipal",
        image: reuniaoAMunicipal_,
      },
      {
        id: 99999,
        name: "Comment",
        faIcon: "far fa-comment",
      },
    ];
  } else
    return [
      {
        id: 0,
        name: "Deadline",
        faIcon: "far fa-clock urgent-label",
      },
      {
        id: 1,
        name: "Urgente",
        faIcon: "fas fa-exclamation-triangle urgent-label",
      },
      {
        id: 2,
        name: "Importante",
        faIcon: "fas fa-exclamation-triangle important-label",
      },
      {
        id: 3,
        name: "Normal",
        faIcon: "fas fa-exclamation-triangle normal-label",
      },
      {
        id: 99999,
        name: "Comment",
        faIcon: "far fa-comment",
      },
    ];
};
