export const TasksStatus = {
  NOVO: 'NOVO',
  OCR: 'OCR',
  VALIDAÇÃO_DOCUMENTAL: 'VALIDAÇÃO DOCUMENTAL',
  DOCUMENTAÇÃO_INCOMPLETA: 'DOCUMENTAÇÃO INCOMPLETA',
  CONTROLO_DE_QUALIDADE: 'CONTROLO DE QUALIDADE',
  VALIDAÇÃO_CONTRATUAL: 'VALIDAÇÃO CONTRATUAL',
  APROVADO: 'APROVADO',
  REJEITADO: 'REJEITADO'
}
