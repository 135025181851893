import React, { useState } from "react";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card, Tab, Tabs } from "react-bootstrap";

import Spinner from "~/components/Spinner";
import AutomatedDocumentTable from "./components/AutomatedDocumentTable";
import DataCaptureSettingsModal from "./components/DataCaptureSettingsModal";
import "./AutomatedDataCapture.css";
import "~/assets/css/icons.css";
import NonFinAutomatedDocumentTable from "./components/NonFinAutomatedDocumentTable";
import UploadModal from "./components/UploadModal";

const AutomatedDatacapture = ({
  t,
  simpleFlow,
  datacaptureConfiguration,
  showUploadModal,
  setShowUploadModal,
}) => {
  const { isLoadingUpdateBatch, isLoadingDeleteBatch } = useSelector(
    (state) => state.dataCaptureReducer
  );

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [defaultKey, setDefaultKey] = useState("financial-document");
  const [fromTabUploadModal, setFromTabUploadModal] = useState(defaultKey);
  const [docTypeSelected, setDocTypeSelected] = useState("financial-document");

  const openSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const closeSettingsModal = () => {
    setShowSettingsModal(false);
  };

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
    setFromTabUploadModal(docTypeSelected);
  };

  return (
    <>
      <UploadModal
        docTypeSelected={docTypeSelected}
        showUploadModal={
          showUploadModal ? fromTabUploadModal === docTypeSelected : false
        }
        handleCloseUploadModal={handleCloseUploadModal}
        simpleFlow={simpleFlow}
        datacaptureConfiguration={datacaptureConfiguration}
      />
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>{t("DataCaptureWidget.title")}</h6>
            <h6
              className="data-capture-settings"
              onClick={() => {
                openSettingsModal();
              }}
            >
              <i className="icon-gear mr-2" />
            </h6>
          </Card.Header>
          <Tabs
            className="custom-tabs"
            id="automatedDocumentTabs"
            defaultActiveKey={defaultKey}
            onSelect={() => {}}
          >
            {datacaptureConfiguration.map((e, index) => {
              return (
                <Tab
                  eventKey={e.documentType}
                  title={t("datacapture.filter." + e.documentType)}
                  key={index}
                >
                  {e.documentType !== "financial-document" ? (
                    <NonFinAutomatedDocumentTable
                      defaultTab={defaultKey}
                      docTypeSelected={e.documentType}
                      key={index}
                      openSettingsModal={openSettingsModal}
                      simpleFlow={simpleFlow}
                      datacaptureConfiguration={datacaptureConfiguration}
                      showUploadModal={
                        showUploadModal
                          ? fromTabUploadModal === e.documentType
                          : false
                      }
                      setShowUploadModal={setShowUploadModal}
                      setFromTabUploadModal={setFromTabUploadModal}
                      setDocTypeSelected={setDocTypeSelected}
                    />
                  ) : (
                    <AutomatedDocumentTable
                      defaultTab={defaultKey}
                      key={index + "-fin"}
                      openSettingsModal={openSettingsModal}
                      simpleFlow={simpleFlow}
                      datacaptureConfiguration={datacaptureConfiguration}
                      showUploadModal={
                        showUploadModal
                          ? fromTabUploadModal === e.documentType
                          : false
                      }
                      setShowUploadModal={setShowUploadModal}
                      setFromTabUploadModal={setFromTabUploadModal}
                      setDocTypeSelected={setDocTypeSelected}
                    />
                  )}
                </Tab>
              );
            })}
          </Tabs>
        </Card>
      </div>
      <DataCaptureSettingsModal
        closeModal={closeSettingsModal}
        showModal={showSettingsModal}
        simpleFlow={simpleFlow}
      />
      <Spinner
        spinning={isLoadingDeleteBatch || isLoadingUpdateBatch}
        wrapper
      />
    </>
  );
};
export default withNamespaces()(AutomatedDatacapture);
