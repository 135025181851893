import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeSideBar } from "~/store/ducks/applicationMenu/actionTypes";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import "~/assets/css/styles.css";

const ContentContainer = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    /*close full screen iframes*/
    closeIframesScreens();
  });

  const handleClick = (e) => {
    e.stopPropagation();
    //e.preventDefault();
    if (window.innerWidth < 992) dispatch(closeSideBar());
  };

  return (
    <div onClick={(e) => handleClick(e)} className="scrollBar-visible" style={{ width: "100%", overflowY:'scroll', maxHeight:'100vh' }}>
      {children}
    </div>
  );
};

export default ContentContainer;
