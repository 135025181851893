import React from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import "~/pages/Easypay/assets/css/styles.css";
import "~pages/Easypay/assets/scss/_backofficeEasypayLeadsPage.scss";
import BackOfficeBenefSection from "~pages/Easypay/components/BackOfficeBenefSection.js";
import BackOfficeClientSection from "~pages/Easypay/components/BackOfficeClientSection.js";
import BackOfficeDocumentsSection from "~pages/Easypay/components/BackOfficeDocumentsSection.js";
import BackOfficeRepLegSection from "~pages/Easypay/components/BackOfficeRepLegSection.js";
import Button from "~pages/Easypay/components/Button.js";
import LineBreak from "~pages/Easypay/components/LineBreak.js";

const BackOfficeOnboardingSections = ({}) => {
  const addButtonField = {
    type: "link",
    text: "Trocar de conta::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "right",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='editRepLeg';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };
  return (
    <div className="easypay-backoffice-wrapper-leads">
      <div className="easypay-backoffice-leads-dummy-wrapper"></div>
      <div className="easypay-backoffice-leads-sub-wrapper">
        <div className="easypay-backoffice-leads-form-wrapper">
          <div div="easypay-backoffice-section-1" id="registryBackoffice">
            <BackOfficeClientSection />
          </div>
          <div className="easypay-backoffice-line-break-wrapper">
            <LineBreak text="none" />
          </div>
          <BackOfficeDocumentsSection  />
          <div className="easypay-backoffice-line-break-wrapper">
            <LineBreak text="none" />
          </div>
          <BackOfficeRepLegSection />
          <div className="easypay-backoffice-line-break-wrapper">
            <LineBreak text="none" />
          </div>
          <BackOfficeBenefSection />
          <div className="easypay-backoffice-line-break-wrapper">
            <LineBreak text="none" />
          </div>
          <div className="easypay-backoffice-submit-button-wrapper">
            <Button
              text={"Guardar alterações"}
              variant={"easypay-button-primary"}
              onclick={eval(addButtonField.onclick)}
              key={uuid()}
              id={addButtonField.id}
              className="card-button"
              align={"right"}
              disabledColored={false}
              disabled={false}
              field={addButtonField}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(BackOfficeOnboardingSections);
