import React, { useState, useEffect } from "react";
import { Col, Form, Row, Alert } from "react-bootstrap";
import CreateSearchableDropdown from "../CreateSearchableDropdown";
import CreateTextArea from "../CreateTextArea";
import CreateTableTextInput from "./CreateTableTextInput";
import CreateTableSelect from "./CreateTableSelect";
import CreateTableCheckbox from "./CreateTableCheckbox";
import CreateTextInput from "../CreateTextInput";
import CreateTabsFromSelection from "../CreateTabsFromSelection";
import CreateButtonsFromSelection from "../CreateButtonsFromSelection";
import uuid from "uuid/v1";

const CreateCalculosRendimento = (props) => {
  const { blockdivision, styleSheet } = props;

  const [mrcr_observacoes, setFormData1] = useState("");
  const [aplicarTodas, setFormData2] = useState("");
  const [fracaoRai, setFormData3] = useState("");
  const [seccao, setFormData4] = useState("");
  const [tableRendimentos, setTable1] = useState("");
  const [areaBruta, setFormData5] = useState("");
  const [ivAdoptado, setFormData16] = useState("");
  const [textSize, setTextSize] = useState("")
  const [tableSize, setTableSize] = useState("")
  const [zoomCheckbox, setZoomCheckbox] = useState('1')
  useEffect(() => {
    if (
      styleSheet?.zoomIn &&
      styleSheet?.zoomInTextSize
    ) {
      setTextSize(`${styleSheet?.zoomInTextSize}`)
    }
    if (
      styleSheet?.zoomIn &&
      styleSheet?.tablefontsize
    ) {
      setTableSize(`${styleSheet?.tablefontsize}`)
    }
    if(
      styleSheet?.zoomIn &&
      styleSheet?.zoomInCheckboxScaleSize
    ){
      setZoomCheckbox(`${styleSheet?.zoomInCheckboxScaleSize}`)
    }
    
  }, [styleSheet]);
  useEffect(() => {
    if (blockdivision) {
      //find form data
      let mrcr_observacoes;
      let aplicarTodas;
      let fracaoRai;
      let seccao;
      let tableRendimentos;
      let areaRen;
      let ivAdoptado;

      const setValue = (f) => {
        if (f?.variable == "mrcr_observacoes") mrcr_observacoes = f;
        if (f?.variable == "aplicarTodas") aplicarTodas = f;
        if (f?.variable == "fracaoRai") fracaoRai = f;
        if (f?.variable == "seccao") seccao = f;
        if (f?.variable == "areaRen") areaRen = f;
        if (f?.text == "IV adoptado") ivAdoptado = f?.value;
        if (f?.type == "arraytable") {
          if (f?.row[1]?.col[0]?.value === "Designação") {
            tableRendimentos = f;
          }
        }
      };
      let blockdivision_ = JSON.parse(JSON.stringify(blockdivision));
      if (!Array.isArray(blockdivision_)) {
        blockdivision_ = [blockdivision_];
      }
      blockdivision.forEach((b) => {
        if (Array.isArray(b.columndivision)) {
          b.columndivision.forEach((c) => {
            if (Array.isArray(c.field)) {
              c.field.forEach((f) => {
                setValue(f);
              });
            } else {
              setValue(c.field);
            }
          });
        } else {
          if (Array.isArray(b.columndivision?.field)) {
            b.columndivision.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(b.columndivision.field);
          }
        }
      });

      setFormData1(mrcr_observacoes);
      setFormData2(aplicarTodas);
      setFormData3(fracaoRai);
      setFormData4(seccao);
      setFormData5(areaRen);
      setFormData16(ivAdoptado)
      setTable1(tableRendimentos);
    }
  }, [blockdivision]);

  /*First render*/
  useEffect(() => {
    updateTotais(66);
  }, [tableRendimentos]);

  //const formatter = new Intl.NumberFormat("pt-PT", {
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  //const decimalFormatter = new Intl.NumberFormat("pt-PT", {
  const decimalFormatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
  });

  const numberFormatter = new Intl.NumberFormat("de-DE");

  function parseCurrency(arg) {
    return arg.replace(/[^\d,]/g, "").replace(/,/g, ".");
  }

  function parseNumber(arg) {
    if(arg != undefined) return Number(arg.replace(/[,]/g, ""));
  }

  function updateTotais(tabelaRendimento) {
    let colCheck = "9";
    let colRenda = "4";

    let numLinhasRendimento = 3;
    let totRendimento = 0;
    let valorRendaTot = 0;
    while (
      document.getElementById(
        tabelaRendimento + "_" + numLinhasRendimento + "_8"
      ) != null
    ) {
      totRendimento += Number(
        parseCurrency(
          document.getElementById(
            tabelaRendimento + "_" + numLinhasRendimento + "_8"
          ).textContent
        )
      );
      let valorRenda
      
      if(document
        .getElementById(
          tabelaRendimento + "_" + numLinhasRendimento + "_" + colRenda
        )
        .getElementsByTagName("input")[0] == undefined){
          valorRenda = parseNumber(
            document
            .getElementById(
              tabelaRendimento + "_" + numLinhasRendimento + "_" + colRenda
            ).textContent
          );
        } else {
          valorRenda = parseNumber(
            document
              .getElementById(
                tabelaRendimento + "_" + numLinhasRendimento + "_" + colRenda
              )
              .getElementsByTagName("input")[0].value
          );
        }
      
     

      let optionCheck = Number(
        document
          .getElementById(
            tabelaRendimento + "_" + numLinhasRendimento + "_" + colCheck
          )
          .getElementsByTagName("input")[0].checked
      );
      if (valorRenda > 0 && optionCheck == 1) {
        valorRendaTot += valorRenda;
      }

      numLinhasRendimento++;
    }

    document.getElementById("totalRendimento").textContent = formatter.format(
      totRendimento.toFixed(2)
    );

    let valorComercial = totRendimento;

    document.getElementById("valorComercial").textContent = formatter.format(
      valorComercial.toFixed(2)
    );

    let areaBruta;
    if (document.getElementById("areaBruta")) {
      areaBruta = parseNumber(document.getElementById("areaBruta").value);
    }

    let ivAdoptadoVal = 0;
    if (areaBruta > 0) {
      ivAdoptadoVal = valorRendaTot / areaBruta;
    } else {
      if(ivAdoptado === "") ivAdoptadoVal = 0;
      else ivAdoptadoVal = parseFloat(ivAdoptado);
    }

    document.getElementById("ivAdoptado").textContent = decimalFormatter.format(
      ivAdoptadoVal.toFixed(2)
    );
  }

  function updateTabelaRendimento(linha, tabelaRendimento) {
    let tableid = tabelaRendimento;

    let colArea = "3";
    let colRenda = "4";
    let colIndice = "5";
    let colTx = "6";
    let colYeld = "7";
    let colTotParcial = "8";
    let colCheck = "9";

    /*
    let valorArea = parseNumber(
      document.getElementById(tableid + "_" + linha + "_" + colArea).textContent
    );*/

    let valorArea =  document
    .getElementById(tableid + "_" + linha + "_" + colArea) && Number(
      document
        .getElementById(tableid + "_" + linha + "_" + colArea)
        .textContent.replace(/[.]/g, "")
        .replace(/[,]/g, ".")
    );
    let valorRenda
    if(document
      .getElementById(tableid + "_" + linha + "_" + colRenda)
      .getElementsByTagName("input")[0] == undefined){
        valorRenda = parseNumber(
          document
            .getElementById(tableid + "_" + linha + "_" + colRenda).textContent
        );
      } else {
        valorRenda = document
        .getElementById(tableid + "_" + linha + "_" + colRenda) && parseNumber(
          document
            .getElementById(tableid + "_" + linha + "_" + colRenda)
            .getElementsByTagName("input")[0].value
        );
      }
     

    let valorIndice = 0;
    if (valorArea > 0) {
      valorIndice = valorRenda / valorArea;
    } else {
      valorIndice = Number("0");
    }

    document.getElementById(
      tableid + "_" + linha + "_" + colIndice
    ).textContent = formatter.format(valorIndice.toFixed(2));
    let valorTx
    if(document
        .getElementById(tableid + "_" + linha + "_" + colTx)
        .getElementsByTagName("input")[0] == undefined){
          valorTx = parseNumber(
          document
             .getElementById(tableid + "_" + linha + "_" + colTx).textContent
        );
      } else {
        valorTx = parseNumber(
          document
            .getElementById(tableid + "_" + linha + "_" + colTx)
            .getElementsByTagName("input")[0].value
        );
      }

    let valorYeld
    if(document
      .getElementById(tableid + "_" + linha + "_" + colYeld)
      .getElementsByTagName("input")[0] == undefined){
        valorYeld = parseNumber(
          document
          .getElementById(tableid + "_" + linha + "_" + colYeld).textContent
        );
      } else {
        valorYeld = parseNumber(
          document
            .getElementById(tableid + "_" + linha + "_" + colYeld)
            .getElementsByTagName("input")[0].value
        );
      }

      let optionCheck 
      if( document
        .getElementById(tableid + "_" + linha + "_" + colCheck)
        .getElementsByTagName("input")[0] == undefined){
          optionCheck = parseNumber(
            document
            .getElementById(tableid + "_" + linha + "_" + colCheck).textContent
          );
        } else {
          optionCheck = Number(
            document
              .getElementById(tableid + "_" + linha + "_" + colCheck)
              .getElementsByTagName("input")[0].checked
          );
        }


    let valorTotParcial;
    if (valorYeld > 0 && optionCheck == 1) {
      valorTotParcial =
        valorRenda * 12 * ((1 - Number(valorTx) / 100) / (valorYeld / 100));
    } else {
      valorTotParcial = Number("0");
    }

    document.getElementById(
      tableid + "_" + linha + "_" + colTotParcial
    ).textContent = formatter.format(valorTotParcial.toFixed(2));

    updateTotais(tabelaRendimento);
  }

  const renderTableRendimentos = () => {
    if (tableRendimentos && Array.isArray(tableRendimentos.row)) {
      return tableRendimentos.row.map((r, index) => {
        if (index != 0 && index != 1) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  if (i == 1) {
                    return c?.value?.input ? (
                      <CreateTableSelect
                        field={c?.value?.input}
                        key={i}
                        linha={index + 1}
                        onBlur={updateTabelaRendimento}
                        fontSize={textSize}
                      />
                      ): (
                        <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                      )
                  } else if (i == 2) {
                    return (
                      <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ fontSize: `${textSize}px` }}
                      >
                        {
                          /*1,250.08 -> 1.250,08*/
                          numberFormatter.format(c.value.replace(/[,]/g, ""))
                        }
                      </td>
                    );
                  } else if (i == 3) {
                    return c?.value?.input ? (
                      <CreateTableTextInput
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        name={`listaRendaMensalRend[${index - 2}]`}
                        value={c?.value?.input?.value}
                        linha={index + 1}
                        onBlur={updateTabelaRendimento}
                        size={c?.value?.input?.size}
                        maxlength={c?.value?.input?.maxlength}
                        fontSize={textSize}
                      />
                    ): (
                      <td
                      key={i}
                      id={`66_${index + 1}_${i + 1}`}
                      style={{ fontSize: `${textSize}px` }}
                    >
                      {c.value}
                    </td>
                    )
                  }
                  if (i == 5) {
                    return c?.value?.input ? (
                      <CreateTableTextInput
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        name={`listaTxDespRend[${index - 2}]`}
                        value={c?.value?.input?.value}
                        linha={index + 1}
                        onBlur={updateTabelaRendimento}
                        size={c?.value?.input?.size}
                        maxLength={c?.value?.input?.maxLength}
                        fontSize={textSize}
                      />
                    ): (
                      <td
                      key={i}
                      id={`66_${index + 1}_${i + 1}`}
                      style={{ fontSize: `${textSize}px` }}
                    >
                      {c.value}
                    </td>
                    )
                  }
                  if (i == 6) {
                    return c?.value?.input ? (
                      <CreateTableTextInput
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        name={`listaYeldRend[${index - 2}]`}
                        value={c?.value?.input?.value}
                        linha={index + 1}
                        onBlur={updateTabelaRendimento}
                        size={c?.value?.input?.size}
                        maxLength={c?.value?.input?.maxLength}
                        fontSize={textSize}
                      />
                      ): (
                        <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                      )
                  }
                  if (i == 8) {
                    return (
                      <CreateTableCheckbox
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        field={c?.value?.input}
                        linha={index + 1}
                        onBlur={updateTabelaRendimento}
                        zoomcheckbox={zoomCheckbox}
                        fontSize={textSize}
                      />
                    );
                  } else
                    return (
                      <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };

  const renderColumnContent = () => {
    return (
      <>
        {seccao && <CreateButtonsFromSelection field={seccao} styleSheet={styleSheet}/>}
        {fracaoRai && (
          <CreateSearchableDropdown field={fracaoRai} key={"fracaoRai"} fontSize={textSize} styleSheet={styleSheet} />
        )}
        {aplicarTodas && (
          <CreateSearchableDropdown field={aplicarTodas} key={"aplicarTodas"} fontSize={textSize} styleSheet={styleSheet} />
        )}
        <Form.Group key={uuid()} style={style1}>
          <Form.Label column style={{ flexGrow: "1" }}>
            <h4 style={{ fontSize: `${textSize}px` }}>Método do rendimento ou capitalização das rendas</h4> <hr />
          </Form.Label>
        </Form.Group>
        <Alert variant="info">
          O método do rendimento é utilizado para a determinação do valor do
          imóvel a partir do quociente entre a renda anual efectiva ou
          potencial, líquida de encargos de conservação e manutenção, com uma
          taxa de remuneração adequada às suas características e ao nível de
          risco do investimento, face às condições gerais do mercado imobiliário
          no momento da avaliação.
        </Alert>

        <table style={{ width: "100%" }} className="bordertableMCCR">
          <thead>
            <tr style={{ fontWeight: "bold" }}>
              <td
                id="66_1_1"
                colSpan="5"
                style={{
                  paddingBottom: "10px",
                  fontSize: `${textSize}px`,
                }}
              >
                Parâmetros Valorização
              </td>
            </tr>
            <tr style={{ paddingBottom: "10px" }}>
              <th id="66_2_1" style={{ ...style4, fontSize: `${textSize}px` }}>
                Designação
              </th>
              <th
                id="66_2_2"
                style={{ ...style4, width: "140px", fontSize: `${textSize}px` }}
              >
                Tipo de renda
              </th>
              <th id="66_2_3" style={{ ...style4, fontSize: `${textSize}px` }}>
                Área (m2)
              </th>
              <th id="66_2_4" style={{ ...style4, fontSize: `${textSize}px` }}>
                R. Mensal (&euro;/m&ecirc;s)
              </th>
              <th
                id="66_2_5"
                style={{ ...style4, width: "140px", fontSize: `${textSize}px` }}
              >
                &Iacute;ndice Renda Mensal (&euro;/m&ecirc;s/m2)
              </th>
              <th id="66_2_6" style={{ ...style4, fontSize: `${textSize}px` }}>
                tx.desp (%)
              </th>
              <th id="66_2_7" style={{ ...style4, fontSize: `${textSize}px` }}>
                Yeld (%)
              </th>
              <th
                id="66_2_8"
                style={{ ...style4, width: "100px", fontSize: `${textSize}px` }}
              >
                Totais Parciais (&euro;)
              </th>
              <th id="66_2_9" style={{ ...style4, fontSize: `${textSize}px` }}>
                Selecionar
              </th>
            </tr>
          </thead>

          <tbody>{renderTableRendimentos()}</tbody>
        </table>
        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.2", padding: "0" }}
          >
            <Form.Group as={Row} style={style2}>
              <Form.Label column style={{ flexGrow: "1", fontSize: `${textSize}px` }}>
                Total
              </Form.Label>
              <Form.Label
                id="totalRendimento"
                column
                style={{ ...style9, fontSize: `${textSize}px` }}
              >
                {" "}
              </Form.Label>
            </Form.Group>
            <hr />
          </Col>
        </Form.Group>

        <br />

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {areaBruta && (
              <CreateTextInput
                id="areaBruta"
                field={areaBruta}
                key={areaBruta.variable}
                onBlur={() => updateTotais(66)}
                fontSize={textSize}
              />
            )}

            <Form.Group as={Row} style={style3}>
              <Form.Label
                id="label_lvRep"
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                IV adoptado
              </Form.Label>
              <Form.Label
                id="ivAdoptado"
                column
                style={{ ...style7, fontSize: `${textSize}px` }}
              ></Form.Label>
            </Form.Group>
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ maxWidth: "200px", fontSize: `${textSize}px` }}
              >
                V &ndash; Valor Comercial
              </Form.Label>
              <Form.Label
                id="valorComercial"
                column
                style={{ ...style9, fontSize: `${textSize}px` }}
              >
                {" "}
              </Form.Label>
            </Form.Group>
            <hr />
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {mrcr_observacoes && (
              <CreateTextArea
                field={mrcr_observacoes}
                key={"mrcr_observacoes"}
              />
            )}
          </Col>
        </Form.Group>
      </>
    );
  };
  const fontSize = "1rem";
  const style1 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
    flexGrow: "1",
  };

  const style2 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
    justifyContent: "flex-end",
  };

  const style3 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style4 = {
    overflowWrap: "break-word",
    verticalAlign: "baseline",
    padding: "0.75rem 0.25rem 0.5rem 0.25rem",
  };

  const style7 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "start",
  };

  const style8 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style9 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "end",
  };

  return (
    <Row
      key={`blockdivision_comparativo`}
      style={{ width: "100%", flexWrap: "nowrap", margin: "0px" }}
    >
      <Col key={`columndivision_rendimento`} style={{ flexGrow: "1" }}>
        {renderColumnContent()}
      </Col>
    </Row>
  );
};
export default CreateCalculosRendimento;
