import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import { Button } from "react-bootstrap";

import * as actions from "../actions";

const SAMLAuthentication = ({ t }) => {
  const dispatch = useDispatch();

  const { successRetrieveConfiguration } = useSelector(
    (state) => state.adminConfigReducer
  );

  const { isRequestingSAMLUrl, samlRequestUrl, isSignInSAML } = useSelector(
    (state) => state.loginReducer
  );

  useEffect(() => {
    if (samlRequestUrl) {
      window.open(samlRequestUrl, "_self");
      dispatch(actions.clearSamlRequestUrl());
    }
  }, [samlRequestUrl]);

  const handleLoginSaml = () => {
    dispatch(actions.getSAMLRequestURL());
  };

  return (
    <div id="third-auth-button" style={{ textAlign: "center" }}>
      <div className="col-md-12">
        <div className="login-or">
          <hr className="hr-or" />
          <small className="span-or">{t("login.signinWith")}</small>
        </div>
      </div>
      <Button
        variant="outline-light"
        className="login-button"
        style={{ backgroundColor: "#4285f4" }}
        disabled={!successRetrieveConfiguration}
        onClick={handleLoginSaml}
      >
        {isRequestingSAMLUrl || isSignInSAML
          ? t("login.loading")
          : t("login.samlAuth")}
      </Button>
    </div>
  );
};

export default withNamespaces()(SAMLAuthentication);
