import React, { useEffect, useRef } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import logo from '~/assets/img/logos/unik-logo.png';
import Spinner from "~/components/Spinner";
import { getPuzzleItems } from '~/pages/Admin/SystemSettings/actions';
import { changeLogoApplication, resetSavedLogo } from "~/store/ducks/application/actionTypes";



const ApplicationLogoItem = ({ application }) => {

  const inputFile = useRef(null);
  const dispatch = useDispatch();

  const { savedLogo } = useSelector(state => state.applicationReducer);

  const getApplicationLogo = application => {
    return application.logo === null ? logo : 'data:image/png;base64,' + application.logo;
  }

  const handleClick = () => {
    inputFile.current.click();
  }

  const handleChangeApplicationLogo = (application, logo) => {
    dispatch(changeLogoApplication({ application, logo }));
  }

  useEffect(() => {
    if (savedLogo) {
      dispatch(getPuzzleItems());
      dispatch(resetSavedLogo());
    }
  }, [savedLogo]);

  return (
    <Col>
      <Spinner spinning={application.isChanging} size="sm" />
      {
        !application.isChanging &&
        <div onClick={() => handleClick()}>
          <img src={getApplicationLogo(application)} className="dc-image-preview" alt="preview" />
          <span
            className="dc-remove-file"
          >
            <i className="icon-edit" />
          </span>
          <label style={{ textAlign: 'center', display: 'block' }}>{application.description}</label>

          <input
            id='file'
            type='file'
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={e => handleChangeApplicationLogo(application, e.target.files[0])}
          />
        </div>
      }
    </Col>
  )
}

export default ApplicationLogoItem;
